import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api/api.service';
import { BaseResponse } from './models/baseResponse';
@Injectable({
  providedIn: 'root',
})
export class MainService {
  localAvatarUrl: any;
  localAudicenceData: any;
  locallLocation: any;
  localEmail: any;
  localToken: any;

  constructor(private http: HttpClient, private _ApiService: ApiService) { }

  getBandSize(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getBandSize`);
  }

  getEducationList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getEducationList`);
  }

  getInstumentbykeyword(keyword: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/searchInstruments?keyword=${keyword}`
    );
  }

  getOnsiteList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getOnsiteList`);
  }

  getVideoSetupList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getVideoSetupList`);
  }
  getVideoQualityList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getVideoQualityList`
    );
  }
  getAudioSetupList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getAudioSetupList`);
  }
  getPerformanceEnvironmentList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getPerformanceEnvironmentList`
    );
  }

  essentailDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/saveOrUpdateEssentialDetails`,
      body
    );
  }

  uploadCerificateDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/uploadCertificateDetails`,
      body
    );
  }

  backgroundDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/backgroundDetails`,
      body
    );
  }

  saveOrUpdateBackgroundDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/saveOrUpdateBackgroundDetails`,
      body
    );
  }

  saveOrUpdateProductionCapabilities(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/saveOrUpdateProductionCapabilities`,
      body
    );
  }

  generalDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/saveOrUpdateGeneralProfileDetails`,
      body
    );
  }

  saveProductionCapabilities(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/saveProductionCapabilities`,
      body
    );
  }

  getUserDetail(id: number): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`${id}`);
  }

  getAgeList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getAgeList`);
  }

  getDeviceList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getDeviceList`);
  }

  searchGenres(name: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/searchGenres?keyword=${name}`
    );
  }

  searchInstuments(name: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/searchInstruments?keyword=${name}`
    );
  }

  // getUser(id: number): Promise<BaseResponse> {
  //   return this._ApiService.getRequest<BaseResponse>(`${id}`);
  // }

  fileUpload(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`user/fileUpload`, body);
  }

  fileUploadImage(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`user/fileUpload`, body);
  }

  saveMediaDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/saveOrUpdateMediaDetails`,
      body
    );
  }

  saveAudienceProfileDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/saveAudienceProfileDetails`,
      body
    );
  }

  forgotPassword(email: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/forgotPassword?email=${email}`
    );
  }

  verifyOtp(user: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`user/verifyOtp`, user);
  }
  resetPassword(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/resetPassword`,
      body
    );
  }

  getGeneralProfileById(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getGeneralProfileDetails/${userId}`
    );
  }

  getessentailProfileById(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getEssentialDetails/${userId}`
    );
  }

  getAudienceProfileDetails(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getAudienceProfileDetails/${id}`
    );
  }

  getBackgroundDetails(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getBackgroundDetails/${userId}`
    );
  }
  getProductionDetails(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getProductionCapabilities/${userId}`
    );
  }
  getMediaDetails(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getMediaDetails/${userId}`
    );
  }

  getProfilepic(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getProfilepic/${userId}`
    );
  }

  updateProfilePic(profilePicUrl: string): Promise<BaseResponse> {
    return this._ApiService.putRequest<BaseResponse>(
      `user/updateProfilePic?profilePicUrl=${profilePicUrl}`,
      {}
    );
  }

  saveOrUpdateAudienceProfileDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/saveOrUpdateAudienceProfileDetails`,
      body
    );
  }

  saveorUpdateArtistGeneralDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      'user/saveOrUpdateGeneralProfileDetails',
      body
    );
  }

  deleteImage(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(
      `user/deleteImage?imageDetailId=${id}`
    );
  }

  deleteVideo(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(
      `user/deleteVideo?videoDetailId=${id}`
    );
  }
  saveOrUpdateproduction(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/saveOrUpdateProductionCapabilities`,
      body
    );
  }
  saveOrUpdateBackground(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/saveOrUpdateBackgroundDetails`,
      body
    );
  }

  addVideoDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      'user/addVideoDetails',
      body
    );
  }

  addImageDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      'user/addImageDetails',
      body
    );
  }

  saveOrUpdateMediaDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      'user/saveOrUpdateMediaDetails',
      body
    );
  }

  removeCertificateDetails(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(
      `user/removeCertificateDetails?id=${id}`
    );
  }

  editCertificateDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<BaseResponse>(
      `user/editCertificateDetails`,
      body
    );
  }

  getAllNewArtist(page: any, pageSize: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getAllNewArtist?page=${page}&limit=${pageSize}`);
  }

  getAllStaffPicks(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getAllStaffPicks`);
  }

  getArtistDetailsById(id: number): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getArtistProfileDetailsById/${id}`
    );
  }

  addOrRemoveFavouriteArtist(artistId: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `user/addOrRemoveFavouriteArtist/${artistId}`,
      {}
    );
  }

  getAllFavourites(pageNo: number, pageSize: number): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getAllFavourites?page=${pageNo}&limit=${pageSize}`);
  }

  getSearchedDetails(keyword: any, pageNo: number): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getSearchedDetails?keyword=${keyword}&page=${pageNo}&limit=10`
    );
  }

  normalSearch(
    pageNo: number,
    pageSize: number,
    keyword?: string,
    source?: any,
    eventId?: any
  ) {
    let params: string;

    if (keyword) {
      params = `page=${pageNo}&limit=${pageSize}&keyword=${keyword}&source=${source}&eventId=${eventId}`;
    } else {
      params = `page=${pageNo}&limit=${pageSize}`;
    }
    return this._ApiService.getRequest<BaseResponse>(
      `user/getSearchedDetails?${params}`
    );
  }

  advanceSearch(param: any) {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getAdvanceSearchedDetails?${param}`
    );
    // return this._ApiService.getRequest<BaseResponse>(`getAdvanceSearchedDetails?audioSetup=${audio}&feeRangeFrom=null&feeRangeFrom=null`);
  }

  getEventTypeList() {
    return this._ApiService.getRequest<BaseResponse>(`event/getEventTypeList`);
  }

  getActSizeList() {
    return this._ApiService.getRequest<BaseResponse>(`event/getActSizeList`);
  }

  getPreferredDeviceList() {
    return this._ApiService.getRequest<BaseResponse>(
      `event/getPreferredDeviceList`
    );
  }

  saveEventDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/saveEventDetails`, body);
  }

  getEventList(pageNo: number, pageSize: number): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `event/getEventList?page=${pageNo}&limit=${pageSize}`
    );
  }

  getAudienceEventList(
    pageNo: number,
    pageSize: number,
    type: any
  ): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `event/getAudienceEventList?eventType=${type}&page=${pageNo}&limit=${pageSize}`
    );
  }

  getEventDetailById(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `event/getEventDetailById?id=${id}`
    );
  }

  saveDraftDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `event/saveDraftDetails`,
      body
    );
  }

  getDraftList(page = 0): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `event/getEventDraftList?page=${page}&limit=10`
    );
  }
  editDraftDetails(id: any, body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `event/editDraftDetails?eventId=${id}`,
      body
    );
  }

  deleteDrafts(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(
      `event/deleteEventDraft?eventIds=${id}`
    );
  }

  getAllGiglist(pageNo: number, pageSize: number, sortby?: string): Promise<BaseResponse> {
    let params: string;
    if (sortby) {
      params = `page=${pageNo}&limit=${pageSize}&sortBy=${sortby}`;
    } else {
      params = `page=${pageNo}&limit=${pageSize}`;
    }

    return this._ApiService.getRequest<BaseResponse>(
      `event/getAllGigList?${params}`
    );
  }

  getGigSearchedDetails(
    date: number,
    genres: any,
    pageNo: number,
    pageSize: number
  ): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `event/getGigSearchedDetails?startDate=${date}&genres=${genres}&page=${pageNo}&limit=${pageSize}`
    );
  }

  artistInvitation(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `event/artistInvitation`,
      body
    );
  }

  getAllInvitedArtistList(
    pageNo: number,
    pageSize: number,
    eventId: number
  ): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getAllInvitedArtistList?page=${pageNo}&limit=${pageSize}&eventId=${eventId}`
    );
  }

  getAllArtistEventList(
    pageNo: number,
    pageSize: number,
    type: any
  ): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `event/getAllArtistEventList?eventType=${type}&page=${pageNo}&limit=${pageSize}`
    );
  }

  getSearchEventByUser(keyword: any ): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/searchEventByArtistAndAudience?keyword=${keyword}`);
  }

  // getAllInvitedEventList(pageNo: number, pageSize: number): Promise<BaseResponse>{
  //    return this._ApiService.getRequest<BaseResponse>(`event/getAllInvitedEventList?page=${pageNo}&limit=${pageSize}`)
  // }

  getNotification(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`notification`);
  }

  markRead(id: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `notification/markRead/${id}`,
      {}
    );
  }

  markAllRead(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `notification/markAllRead`,
      body
    );
  }

  removeNotification(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(
      `notification/removeNotification/${id}`
    );
  }

  removeAllNotification(): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(
      `notification/removeAllNotification`
    );
  }

  seeAllNotifications(type: any, pageNo: number, pageSize: number): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `notification/seeAllNotifications?type=${type}&page=${pageNo}&limit=${pageSize}`
    );
  }

  getAllCalenderDetails(date: any, type: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `event/getAllCalenderDetails?calenderDate=${date}&calenderFetchFormat=${type}`
    );
  }

  blockCalenderSlot(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `event/blockCalenderSlot`,
      body
    );
  }

  deleteBlockedCalenderSlot(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(
      `event/deleteBlockedCalenderSlot?calenderId=${id}`
    );
  }

  editBlockedCalenderSlot(id: any, body: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<BaseResponse>(
      `event/editBlockedCalenderSlot?calenderId=${id}`,
      body
    );
  }

  submitProposal(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `event/submitProposal`,
      body
    );
  }

  saveProposalAsDraft(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `event/saveProposalDraft`,
      body
    );
  }

  submitEditProposal(id: any, body: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<BaseResponse>(
      `event/editProposal/${id}`,
      body
    );
  }
  EditContract(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `event/editContract`,
      body
    );
  }

  getAllProposalList(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `event/getAllProposalList/${id}`
    );
  }
  // getAllProposedEventList(): Promise<BaseResponse>  {
  //   return this._ApiService.getRequest<BaseResponse>(`event/getAllProposedEvent`)
  // }
  getProposalDraftList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `event/getProposalDraftList`
    );
  }
  getEditProposalDraft(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `event/getProposalDetails/${id}`
    );
  }
  deleteProposalDrafts(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(
      `event/deleteProposalDraft?proposalIds=${id}`
    );
  }
  editProposalproposal(body: any, id: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<BaseResponse>(
      `event/editProposalDraft/${id}`,
      body
    );
  }
  doneHiring(id: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `event/completeEventHiring?eventId=${id}`,
      {}
    );
  }

  acceptOrDeclineProposal(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `event/acceptOrDeclineProposal`,
      body
    );
  }
  acceptOrDeclineContract(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `event/acceptOrDeclineContract`,
      body
    );
  }

  saveChatUuid(uuId: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `event/saveChatUuid?uuId=${uuId}`,
      {}
    );
  }

  saveChatDetail(id: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/saveChatDetail/${id}`, {});
  }

  getAllChatUsers(pageNo: number, pageSize: number, search?: string): Promise<BaseResponse> {
    let params: string;
    if (search) {
      params = `page=${pageNo}&limit=${pageSize}&searchedKeyword=${search}`;
    } else {
      params = `page=${pageNo}&limit=${pageSize}`;
    }
    return this._ApiService.getRequest<BaseResponse>(
      `event/getAllChatUsers?${params}`
    );
  }

  getAllChatList(search?: string): Promise<BaseResponse> {
    let params: string;
    if (search) {
      params = `searchedKeyword=${search}`;
    } else {
      params = ``;
    }
    return this._ApiService.getRequest<BaseResponse>(`event/getAllChatList?${params}`)
  }

  setPaymentStatus(id: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/completePayment?id=${id}`, {})
  }

  createCheckoutSession(amount: number, proposalId: any, eventId: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(
      `event/createCheckoutSession?amount=${amount}&proposalId=${proposalId}&eventId=${eventId}`,
      {}
    );
  }
  getTimeZone(countryName?: any, search?: string): Promise<BaseResponse> {
    let params: string;
    if (search && countryName) {
      params = `countryName=${countryName}&searchKeyword=${search}`;
    } else if (search) {
      params = `searchKeyword=${search}`;
    } else {
      params = `countryName=${countryName}`;
    }
    return this._ApiService.getRequest<BaseResponse>( `user/getTimeZones?${params}`);
  }
  downloadContract(id: any) {
    return this._ApiService.downloadPdf(`event/downloadContractPDFDetails/${id}`);
  }
  getTestStream(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(
      `event/getTestStreamingDetail`
    );
  }

  startLiveStream(id: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/startLiveStreaming?eventId=${id}`, {})
  }

  endLiveStream(id: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/endLiveStreaming?eventId=${id}`, {})
  }
  endLivePerformance(id: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/endLivePerformance?eventId=${id}`, {})
  }

  getStreamingUrl(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getStreamingUrl?eventId=${id}`)
  }

  getEventStreamingDetails(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getEventStreamingDetails?eventId=${id}`)
  }
  getAllSubscriptionPlans(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getAllSubscriptionPlans`)
  }

  addProSongToEvent(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/saveProPlaylist`, body)
  }
  copySongsToEventFromHistory(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/copySongsToEventFromHistory`, body)
  }
  copySongsToEventFromPlaylist(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/copySongsToEvent`, body)
  }
  copySongsToPlaylistFromEvent(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/copySongsToPlaylistFromEvent`, body)
  }
  createNewPlaylist(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/playlist`, body)
  }
  editPlaylist(body: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<BaseResponse>(`event/playlist`, body)
  }
  getProPlaylistDetails(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getProPlaylistDetails?identifierId=${id}`)
  }
  deleteAddedSongOnEvent(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(`event/deleteProPlaylist?proPlaylistIds=${id}`);
  }
  deleteProPlaylist(id: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(`event/playlist?id=${id}`);
  }
  deleteSongOfPlaylist(body: any): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(`event/song?songIds=${body}`);
  }

  updateAddedSongsOrder(body: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<BaseResponse>(`event/updatePROPlaylistOrder`, body);
  }
  updateMyPlaylistSongsOrder(body: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<BaseResponse>(`event/updateOrderOfSongs`, body);
  }
  getProPlaylistHistory(key: any): Promise<BaseResponse> {
    let url = key? `event/getProPlaylistHistory?keyword=${key}`: 'event/getProPlaylistHistory';
    return this._ApiService.getRequest<BaseResponse>(`${url}`);
  }
  getAllPlaylist(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/playlist`);
  }
  getAllSongOfPlaylist(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/song?id=${id}`);
  }
  addSongToPlaylist(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/song`, body)
  }
  changePassword(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`user/changePassword`, body)
  }

  cancelEvent(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/cancelEvent`, body)
  }

  cancelContract(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/cancelContract`, body)
  }

  feedbackSubmission(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/eventRating`, body)
  }

  getUserAccPendingDetails(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getUserAccPendingDetails`)
  }

  deleteUserAccount(remark: string): Promise<BaseResponse> {
    return this._ApiService.deleteRequest<BaseResponse>(`user/deleteUserAccount?remark=${remark}`);
  }
  getAllSubscriptionPlan(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getAllSubscriptionPlan`)
  }
  getAllStripeCustomer(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getAllStripeCustomer`, {})
  }
  createSubscriptionSession(plan: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/createSubscriptionSession?subscriptionPlan=${plan}`, {})
  }
  createCustomerPortalSession(): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/createCustomerPortalSession`, {})
  }

  saveLastChatDetails(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/saveLastChatDetails`, body)
  }

  sendChatNotification(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`notification/sendChatNotification`, body)
  }

  customerRefund(type: any, eventId: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/customerRefund?type=${type}&eventId=${eventId}`, {})
  }
  customerRefundForGig(type: any, eventId: any, proposalId?: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/customerRefund?type=${type}&eventId=${eventId}&proposalId=${proposalId}`, {})
  }
  viewPublicArtistProfile(id: number): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/viewPublicArtistProfile/${id}`);
  }

  getAllInvitedUsers(pageNo: number, pageSize: number): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getAllInvitedUsers?page=${pageNo}&limit=${pageSize}`)
  }

  inviteUser(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`user/inviteUser`, body)
  }
  searchPublicArtistProfile(pageNo: number, pageSize: number, keyword: string) {
    return this._ApiService.getRequest<BaseResponse>(
      `user/getSearchedPublicArtistProfile?$page=${pageNo}&limit=${pageSize}&keyword=${keyword}`
    );
  }

  getUserInviteCount(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`user/getUserInviteCount`)
  }

  markEventHiringInProgress(id: number): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/markEventHiringInProgress?eventId=${id}`, {})
  }
  getAccountId(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getAccountId?userId=${userId}`);
  }
  getAccountLink(id: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getAccountLink?accountId=${id}`);
  }
  // getCustomerAccountDetail(): Promise<BaseResponse> {
  //   return this._ApiService.getRequest<BaseResponse>(`event/getCustomerAccountDetail`);
  // }
  getEarningDashboardLink(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getEarningDashboardLink`);
  }
  getTransactionDetail() {
    return this._ApiService.getRequest<BaseResponse>(`event/getTransactionReceiptUrls`);
  }
  updateCustomer(id: any): Promise<BaseResponse> {
    return this._ApiService.putRequest<BaseResponse>(`event/updateCustomer?accountId=${id}`, {});
  }

  getExistingEventList(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getExistingEventList`)
  }

  startLivePerformance(id: number): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/startLivePerformance?eventId=${id}`, {})
  }

  submitHubspotForm(portalId: any, formId: any, body: any): Promise<BaseResponse> {
    return this._ApiService.hubspotPostRequest<BaseResponse>(`/${portalId}/${formId}`, body)
  }

  getUserPayoutDetail(userId: any): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getUserPayoutDetail?userId=${userId}`);
  }
  // https://backend.dev.virtuosica.com/api/v1/event/getEarningBalance
  getBalanceOfStripeAccount(): Promise<BaseResponse> {
    return this._ApiService.getRequest<BaseResponse>(`event/getEarningBalance`);
  }


  shareLiveEvent(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/shareLiveEvent`, body)
  }

  getSharedLiveEventDetailList(pageNo: any, pageSize: any, id: any) {
    return this._ApiService.getRequest<BaseResponse>(`event/getSharedLiveEventDetailList?page=${pageNo}&limit=${pageSize}&eventId=${id}`);
  }
  deleteSingleUserChat(chName: any, endTime: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`event/deleteUserMessages?channelName=${chName}&endTime=${endTime}`, {})
  }
  regenerateLink(email: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`user/verifyEmail?email=${email}`, {})
  }
}
