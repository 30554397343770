<main>
  <div class="container-fluid px-4 py-3">
    <div class="hgnb bot-m">
      <div class=" nvhi">
        <span><b> Invite Artist </b></span>
      </div>
    </div>
    <div>
      <div class="cr-t">
        <div class="uhgtr">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a class="nav-link" [ngClass]="secOneStatus">Search</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngClass]="secTwoStatus">Invite Artists</a>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="tabName == 'sectionOne'" class="tab-pane active">
        <div class="row py-4">
          <div class="col-md-8">
            <li class="nav-item nbhg active">
              <div class="form-group has-search input-group">
                <input type="text" class="form-control" [(ngModel)]="searchName" placeholder="Search the Artist to invite"
                  (keyup.enter)="dataSearch($event)">
                <div class="input-group-append">
                  <button class="btn btn-secondary aedsr" (click)="dataSearch()" type="button">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </li>
          </div>
          <div class="col-md-4 d-flex justify-content-end pr-4">
            <div>
              <select name="a-z" class="form-control frgt" id="a-z">
                <option value="1" selected>Sort (A - Z)</option>
                <option value="2">A</option>
              </select>
            </div>
            <div class="input-group-append">
              <button class="btn btn-secondary" style="border: none; background: none;" type="button"
                data-toggle="modal" data-target="#inviteSearchModal">
                <img src="assets/Vector8.svg">
              </button>
            </div>
          </div>
        </div>
        <div class="hi-pi">
          <div class="fgrh" *ngIf="userTypeValue">
            <p>{{userTypeValue}}</p>
          </div>
        </div>
        <ng-container *ngIf="!searchList?.length && screen">
          <div class="center-error">
            <div class="error-wrapper">
              <h1 class="text-center" style="color: white;">Oops</h1>
              <p class="text-center" style="color: white;">Search the artists to send Invite. </p>
            </div>
          </div>
        </ng-container>
        <div class="cr-di pb-5">
          <div class="gdtdb" *ngFor="let user of searchList, let i = index">
            <div class="edsaw">
              <img [src]="user.avartarUrl ? user.avartarUrl: 'assets/artist/unsplash_4korPWFLBk0.png'">
            </div>
            <div class="baho">
              <div class="row">
                <div class="col-md-6">
                  <p class="edcv">Recommended</p>
                  <h4>{{user.displayName}} </h4>
                  <span class="olku" *ngFor="let place of user.locations">
                    {{place}}
                  </span>
                </div>
                <div class="col-md-6 d-flex justify-content-end pr-4">
                  <!-- <button [disabled]="userId == user.userId" type="submit" class="btn hftrdd">Hire Now </button> -->
                  <button type="submit" class="btn defrqqa edcae"
                    *ngIf="user.invited === false || user.invited == null "
                    (click)="artistInvitation(i,user.artistProfileId,'invite')">Invite</button>
                  <button type="submit" class="btn invite edcae" *ngIf="user.invited === true"
                    (click)="artistInvitation(i,user.artistProfileId,'cancelInvite')">Invited</button>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-lg-3">
                  <h5>${{user.feeRangeFrom}} - ${{user.feeRangeTo}}</h5>
                </div>
                <div class="col-lg-3">
                  <h5>{{user.events}} events</h5>
                </div>
                <div class="col-lg-3">
                  <span *ngFor="let method of user.genres">
                    {{method}}
                  </span>
                </div>
                <div class="col-lg-3">
                  <div class="ygre ">
                    <!-- <rating class="rating" [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating> -->
                  </div>
                </div>
              </div>
              <div class="defrvg">
                <p> {{user.careerSummary}}</p>
              </div>
            </div>
          </div>
          <button type="submit" class="btn defrqqa edcae" *ngIf="searchList && searchList.length"
            (click)="getAllInvitedArtistList()">Next </button>
        </div>
      </div>
      <div *ngIf="tabName === 'sectionTwo'" class="tab-pane active" style="padding-top: 38px;">
        <ng-container *ngIf="!inviteArtist?.length">
          <div class="center-error">
            <div class="error-wrapper">
              <h1 class="text-center" style="color: white;">Oops</h1>
              <p class="text-center" style="color: white;">No Invite Artist Found </p>
            </div>
          </div>
        </ng-container>

        <!-- Invited Artist Screen -->
        <div class="cr-di pb-5" *ngIf="inviteArtist.length">
          <div class="gdtdb" *ngFor="let user of inviteArtist, let i = index">
            <div class="edsaw">
              <img [src]="user.avartarUrl ? user.avartarUrl: 'assets/artist/unsplash_4korPWFLBk0.png'">
            </div>
            <div class="baho">
              <div class="row">
                <div class="col-md-6">
                  <p class="edcv">Recommended</p>
                  <h4>{{user.displayName}} </h4>
                  <span class="olku" *ngFor="let place of user.locations">
                    {{place}}
                  </span>
                </div>
                <div class="col-md-6 d-flex justify-content-end pr-4">
                  <button type="submit" class="btn invite edcae"
                    (click)="artistInvitation(i,user.artistProfileId,'cancelInvite')">Invited</button>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-lg-3">
                  <h5>${{user.feeRangeFrom}} - ${{user.feeRangeTo}}</h5>
                </div>
                <div class="col-lg-3">
                  <h5>{{user.events}} events</h5>
                </div>
                <div class="col-lg-3">
                  <span *ngFor="let method of user.genres">
                    {{method}}
                  </span>
                </div>
                <div class="col-lg-3">
                  <div class="ygre ">
                    <!-- <rating class="rating" [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating> -->
                  </div>
                </div>
              </div>
              <div class="defrvg">
                <p> {{user.careerSummary}}</p>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="btn hftrdd" (click)="inviteFormSubmit()">Back</button>
        <button type="submit" class="btn defrqqa edcae" *ngIf="inviteArtist && inviteArtist.length"
          [routerLink]="['/audienceDashboard/eventPreview']">Continue </button>
      </div>
    </div>
  </div>
</main>

<!-- functionality for public case -->
<div *ngIf="!screen && !showContent" class="container">
  <ng-container>
    <div class="center-error">
      <div class="error-wrapper">
        <h1 class="text-center" style="color: white;font-size:26px">No Records!</h1>
        <p class="text-center" style="color: white;">Search the artists to send Invite. </p>
      </div>
    </div>
  </ng-container>

  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <button type="submit" class="btn pubBtn" (click)="pubFunctionality('skip')">Skip</button>
      <button type="submit" class="btn pubbtn edcae" (click)="pubFunctionality('next')">Next </button>
    </div>
  </div>
</div>

<!-- Search modal  -->
<div class="modal fade" data-backdrop="false" id="inviteSearchModal" tabindex="-1" role="dialog" aria-labelledby="inviteSearchModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg vehz" role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <h5 class="modal-title wsedv" id="searchModalLabel">Advanced Search</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="advanceSearchForm">
          <div class="row advanceSearch">
            <div class="col-lg-4">
              <div class="">
                <div class="">
                  <label class="f1 required">Experience</label>
                  <input type="text" formControlName="experience" class="form-control amhre"
                    placeholder="Enter your experience">
                </div>
                <div class="gen mt-4">
                  <label class="required pt-3 mb-0">Instruments Played</label>
                  <mat-form-field class="example-chip-list " appearance="fill">
                    <mat-chip-list #chipListinst>
                      <mat-chip *ngFor="let instrument of searchInstuments" [selectable]="selectable"
                        [removable]="removable" (removed)="remove(instrument)">
                        {{instrument}}
                        <mat-icon matChipRemove *ngIf="removable">close
                        </mat-icon>
                      </mat-chip>
                      <input #instInput [formControl]="instumentCtrl" [matAutocomplete]="autoinst"
                      [matChipInputFor]="chipListinst" [matChipInputSeparatorKeyCodes]='separatorKeysCodess'
                        (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                    <mat-autocomplete #autoinst="matAutocomplete" (optionSelected)="selected($event)">
                      <mat-option *ngFor="let instruments of instrumentList" [value]="instruments">
                        {{instruments}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start typing to select your instruments</small>
                </div>
                <div class="">
                  <label class="">Location</label>
                  <mat-form-field class="example-chip-list location" appearance="fill">
                    <mat-chip-list #chipListloc>
                      <mat-chip *ngFor="let address of location" (removed)="removeLocation(address)">
                        {{address}}
                        <button matChipRemove>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip>
                      <input placeholder="&nbsp;" #locInput [formControl]="locationCtrl" [matChipInputFor]="chipListloc"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" ngx-google-places-autocomplete
                        #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)">
                    </mat-chip-list>
                  </mat-form-field>
                </div>
                <div class="gen">
                  <label class="required">Genre</label>
                  <mat-form-field class="example-chip-list" appearance="fill">
                    <mat-chip-list #chipListgen>
                      <mat-chip *ngFor="let genres of searchgen" [selectable]="selectable" [removable]="removable"
                        (removed)="removegen(genres)">
                        {{genres}}
                        <button matChipRemove>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip>
                      <input style="border: none; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                        #genInput [formControl]="genCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipListgen"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addgen($event)">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedgen($event)">
                      <mat-option *ngFor="let gen of genList" [value]="gen">
                        {{gen}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start typing to select your genres</small>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="">
                <div class="mb-3">
                  <div class="form-group">
                    <label class="f1 required">Video Quality</label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="videoQualityDetails" #select2 multiple
                        placeholder="What level of video quality can you provide?">
                        <div class="select-all">
                          <mat-checkbox [(ngModel)]="allSelected2" [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection2()">Select All
                          </mat-checkbox>
                        </div>
                        <mat-option style="z-index: 10000000;" (click)="optionClick2()"
                          *ngFor="let videoquality of videoQualityList" [value]="videoquality">
                          {{videoquality}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="form-group">
                    <label class="required">Onsite</label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="onsiteDetails" #select multiple
                        placeholder="Tell us about your production capabilities">
                        <div class="select-all">
                          <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection()">Select All
                          </mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick()" *ngFor="let onsite of onSiteList" [value]="onsite">
                          {{onsite}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="form-group for-str">
                    <label class="f1 required">Video Setups</label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="videoSetupDetails" #select1 multiple
                        placeholder="Which video setups do you perform with?">
                        <div class="select-all">
                          <mat-checkbox [(ngModel)]="allSelected1" [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection1()">Select All
                          </mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick1()" *ngFor="let setup of videosetList" [value]="setup">
                          {{setup}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="form-group for-str">
                    <label class="f1 required">Audio Setup</label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="audioSetupDetails" #select3 multiple
                        placeholder="Which audio setups do you perform with?">
                        <div class="select-all">
                          <mat-checkbox [(ngModel)]="allSelected3" [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection3()">Select All
                          </mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick3()" *ngFor="let audiosetup of audioSetupList"
                          [value]="audiosetup">
                          {{audiosetup}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="">
                <div class="mb-3">
                  <label class=" required">Band Size</label>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="bandSizes" multiple placeholder="Enter your band size">
                      <mat-option *ngFor="let bandsize of bandSize" [value]="bandsize">
                        {{bandsize}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="mb-3">
                  <div class="form-group for-str">
                    <label class="f1 required">Environment</label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="performanceEnvironmentDetails" #select4 multiple
                        placeholder="Tell us about your performance environment?">
                        <div class="select-all">
                          <mat-checkbox [(ngModel)]="allSelected4" [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection4()">Select All
                          </mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick4()"
                          *ngFor="let performanceenvironment of PerformanceEnvironmentList"
                          [value]="performanceenvironment">
                          {{performanceenvironment}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="mb-3">
                  <label class="f1 required">Fee Range $</label>
                  <div class="row">
                    <div class="col-md-6">
                      <input formControlName="feeRangeFrom" placeholder="From" type="text" class="form-control amhre">
                    </div>
                    <div class="col-md-6 pl-0">
                      <div class="ujbt">
                        <input formControlName="feeRangeTo" placeholder="To" type="text"
                          class="form-control amhre"><span class="hr-j">/hr</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn ytgft" data-dismiss="modal" (click)="advancedSearch()">Apply</button>
        <button type="button" class="btn htgfr" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Invitation Modal -->
<div *ngIf="inviteCancel" data-backdrop="false" class="modal fade show" style="display: block;" id="inviteCancelModal" tabindex="-1"
  role="dialog" aria-labelledby="inviteCancelModalLabel" aria-hidden="true">
  <div class="modal-dialog vehz" role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <h5 class="modal-title wsedv" id="inviteCancelModalLabel">Cancel Invitation</h5>
      </div>
      <div class="modal-body">
        <h6>Do you want to cancel Invitation?</h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn ytgft" data-dismiss="modal"
          (click)="confirmArtistInvitation()">Confirm</button>
        <button type="button" class="btn htgfr" (click)="inviteCancel = false">Back</button>
      </div>
    </div>
  </div>
</div>
