<div class="joinpage">
    <div class="joinhead">
        <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg" routerLink="" style="cursor: pointer;">
        <div class="nav-item dropdown hgne" style="top: -2px;display: flex; float: right;">
            <a class="nav-link dropdown-toggle" (click)="logOut = !logOut" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <img src="assets/dashboard/Mask Group.png">
            </a>
            <div *ngIf="logOut" class="" style="position: absolute;left: -63px;top: 41px;; border: 1px solid white; border-radius: 15px; background: black;" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" style="cursor: pointer;color: white;background: none;" (click)="logoutUser()"  ><i class="fa fa-sign-out"
                  aria-hidden="true" ></i> Log Out</a>
            </div>
          </div>
    </div>

    <div class="container joinbody">
        <div class="row">
            <div class="col-md-6 col-sm-12 col-xl-6">
                <div class="joinleft">
                    <app-slider></app-slider>
                </div>
            </div>

            <div class="col-md-6 col-sm-12 col-xl-6">
                <!-- <div class="joinright"> -->
                    <div class="">
                        <div class="row">
                            <div class="col-md-10">
                        <div class="wi-4">
                        <app-tabsall [activeTab]="4"></app-tabsall>
                        </div>

                        <div class="tab-content" id="pills-background-detail">
                            <!-- <div class="tab-pane fade show active" id="pills-general" role="tabpanel"
                                aria-labelledby="pills-general-tab"> -->
                            <form [formGroup]="productionCapabilitiesForm" class="ge">
                                <div class="form-group for-str">
                                    <label for="username">Onsite<span class="ikju">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <mat-select  formControlName="onsiteDetails" #select multiple placeholder="Tell us about your production capabilities">
                                            <div class="select-all">
                                                <mat-checkbox [(ngModel)]="allSelected"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="toggleAllSelection()">Select All
                                                </mat-checkbox>
                                            </div>
                                            <mat-option (click)="optionClick()" *ngFor="let onsite of onSiteList"
                                                [value]="onsite">
                                                {{onsite}}
                                            </mat-option>
                                            <div class="select-all">
                                              <mat-checkbox [(ngModel)]="allSelectedNone"
                                                  [ngModelOptions]="{standalone: true}"
                                                  (change)="noneAllSelect()">None
                                              </mat-checkbox>
                                          </div>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <input type="text" class="form-control ge-fk" id="my-career" placeholder="Tell us about your production capabilities"> -->
                                </div>

                                <div class="form-group for-str">
                                    <label for="username">Video Setup<span class="ikju">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="videoSetupDetails" #select1 multiple placeholder="Which video setups do you perform with?">
                                            <div class="select-all">
                                                <mat-checkbox [(ngModel)]="allSelected1"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="toggleAllSelection1()">Select All
                                                </mat-checkbox>
                                            </div>
                                            <mat-option (click)="optionClick1()" *ngFor="let setup of videosetList"
                                                [value]="setup">
                                                {{setup}}
                                            </mat-option>
                                            <div class="select-all">
                                              <mat-checkbox  [(ngModel)]="allSelectedNone1"
                                                  [ngModelOptions]="{standalone: true}"
                                                  (change)="noneAllSelect1()">None
                                              </mat-checkbox>
                                          </div>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <input type="text" class="form-control ge-fk" id="my-career" placeholder="Which video setups do you perform with?"> -->
                                </div>

                                <div class="form-group for-str">
                                    <label for="username">Video Quality<span class="ikju">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="videoQualityDetails" #select2 multiple placeholder="What level of video quality can you provide?">
                                            <div class="select-all">
                                                <mat-checkbox [(ngModel)]="allSelected2"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="toggleAllSelection2()">Select All
                                                </mat-checkbox>
                                            </div>
                                            <mat-option (click)="optionClick2()" *ngFor="let videoquality of videoQualityList"
                                                [value]="videoquality">
                                                {{videoquality}}
                                            </mat-option>
                                            <div class="select-all">
                                              <mat-checkbox [(ngModel)]="allSelectedNone2"
                                                  [ngModelOptions]="{standalone: true}"
                                                  (change)="noneAllSelect2()">None
                                              </mat-checkbox>
                                          </div>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <input type="text" class="form-control ge-fk" id="my-career" placeholder="What level of video quality can you provide?"> -->
                                </div>

                                <div class="form-group for-str">
                                    <label for="username">Audio Setup<span class="ikju">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="audioSetupDetails" #select3 multiple placeholder="Which audio setups do you perform with?">
                                            <div class="select-all">
                                                <mat-checkbox [(ngModel)]="allSelected3"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="toggleAllSelection3()">Select All
                                                </mat-checkbox>
                                            </div>
                                            <mat-option (click)="optionClick3()" *ngFor="let audiosetup of audioSetupList"
                                                [value]="audiosetup">
                                                {{audiosetup}}
                                            </mat-option>
                                            <div class="select-all">
                                              <mat-checkbox  [(ngModel)]="allSelectedNone3"
                                                  [ngModelOptions]="{standalone: true}"
                                                  (change)="noneAllSelect3()">None
                                              </mat-checkbox>
                                          </div>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <input type="text" class="form-control ge-fk" id="my-career" placeholder="Which audio setups do you perform with?"> -->
                                </div>

                                <div class="form-group for-str">
                                    <label for="username">Performance Environment<span class="ikju">*</span></label>
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="performanceEnvironmentDetails" #select4 multiple placeholder="Tell us about your performance environment?">
                                            <div class="select-all">
                                                <mat-checkbox  [(ngModel)]="allSelected4"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="toggleAllSelection4()">Select All
                                                </mat-checkbox>
                                            </div>
                                            <mat-option (click)="optionClick4()" *ngFor="let performanceenvironment of PerformanceEnvironmentList"
                                                [value]="performanceenvironment">
                                                {{performanceenvironment}}
                                            </mat-option>
                                            <div class="select-all">
                                              <mat-checkbox  [(ngModel)]="allSelectedNone4"
                                                  [ngModelOptions]="{standalone: true}"
                                                  (change)="noneAllSelect4()">None
                                              </mat-checkbox>
                                          </div>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <input type="text" class="form-control ge-fk" id="my-career" placeholder="Tell us about your performance environment?"> -->
                                </div>

                                <div class="form-group for-str kdjnb">
                                   <button class="form-control yht"  [routerLink]="['/background']">Back</button>
                                   <button type="submit" class="form-control nxt"  (click)="productionCapabilities()">
                                    <ng-container *ngIf="productionFormSubmitting; else productionFormSubmittingDone">
                                      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...
                                    </ng-container>
                                    <ng-template #productionFormSubmittingDone>
                                      Next
                                    </ng-template>
                                  </button>
                                   <!-- <button class="form-control nxt" (click)= "ProductionCapabilities()">Next</button> -->
                                </div>

                            </form>
                         </div>
                            </div>
                        </div>
                </div>

            </div>
        </div>
        <app-footer></app-footer>
        <!-- <div class="joinfoot">
            <div class="row joinf">
                    <h2>Privacy Policy</h2>
                    <h2>Terms and Conditions</h2>
                    <h2>Need Help?</h2>
            </div>
        </div> -->
    </div>
</div>
<!-- contaner end -->
