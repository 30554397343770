import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AudienceSignupComponent } from './audience-signup/audience-signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { GoLiveViaEmailComponent } from './go-live-via-email/go-live-via-email.component';
import { JumpToEventComponent } from './jump-to-event/jump-to-event.component';
import { LoginComponent } from './login.component';
import { LoginComponentResolver } from './login.component.resolver';
import { OpenChatComponent } from './open-chat/open-chat.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SearchPublicUserComponent } from './search-public-user/search-public-user.component';
import { SubmitProposalComponent } from './submit-proposal/submit-proposal.component';
import { UserPublicProfileComponent } from './user-public-profile/user-public-profile.component';
import { WatchSharedEventComponent } from './watch-shared-event/watch-shared-event.component';
import { WatchSoundCheckComponent } from './watch-sound-check/watch-sound-check.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    resolve: { info: LoginComponentResolver },
  },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'searchUser', component: SearchPublicUserComponent },
  { path: 'userProfile', component: UserPublicProfileComponent },
  { path: 'audience-signup', component:AudienceSignupComponent},
  { path: 'watchLiveEvent', component:WatchSharedEventComponent},
  { path: 'watchStreamingEvent', component:WatchSoundCheckComponent},
  { path: 'openEvent', component:JumpToEventComponent},
  { path: 'submitProposal', component: SubmitProposalComponent},
  { path: 'openChat', component: OpenChatComponent},
  { path: 'goLive', component: GoLiveViaEmailComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [LoginComponentResolver]
})
export class LoginRoutingModule {}
