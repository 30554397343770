<!-- Main Screen -->
<div id="layoutSidenav_content" *ngIf="selectTab == 'mainTab'">
  <main>
    <div class="container-fluid px-3 pt-3">
      <div class="setting-content-wrapper">
        <section>
          <div class="hgnb bot-m">
            <div class="nvhi"><span>Events</span>
              <button [disabled]="userStatus == 'PENDING_DELETE'" class="ekuhrte" style="cursor: pointer;"
                (click)="createNewEvent()">Create new event</button>
              <button class="ekuhrte mr-3" [routerLink]="['/ArtistDashboard/artistDraft']">
                <i> <img src="../../../../../../../assets/dashboard/Vector (4).svg" alt="image"> </i>
                Drafts
              </button>
              <button *ngIf="userRole == 'ARTIST'" class="ekuhr mr-3" data-toggle="modal"
                data-target="#soundCheckModal">
                <i> <img src="../../../../../../../assets/soundCheckLogo.png" alt="image"> </i>
                Sound Check
              </button>
              <input type="text" class="searchBox" placeholder="Search Your Event" [(ngModel)]="keyWord"
                (keyup.enter)="searchEvent()">
              <img style="cursor: pointer; width: 28px;" src="assets/dashboard/Vector (1).svg" (click)="searchEvent()">
            </div>
          </div>
          <div class="uthrr">
            <div class="derfrf">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" href="#all" role="tab" data-toggle="tab"
                    (click)="getEventList(0,5,'all')">All</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#upcoming" role="tab" data-toggle="tab"
                    (click)="getEventList(0,5,'upcoming')">Upcoming</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#pending" role="tab" data-toggle="tab"
                    (click)="getEventList(0,5,'pending')">Pending</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#proposed" role="tab" data-toggle="tab"
                    (click)="getEventList(0,5,'proposed')">Proposed</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#cancelled" role="tab" data-toggle="tab"
                    (click)="getEventList(0,5,'cancelled')">Cancelled</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#invited" role="tab" data-toggle="tab"
                    (click)="getEventList(0,5,'invited')">Invited</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#Completed" role="tab" data-toggle="tab"
                    (click)="getEventList(0,5,'completed')">Completed</a>
                </li>
              </ul>
              <!-- Tab panes -->
              <div class="tab-content pt-2">
                <div role="tabpanel" class="tab-pane fade in active" id="all" infiniteScroll
                  [infiniteScrollDistance]="2" (scrolled)="onScroll('all')" [scrollWindow]="false"
                  style="height: 60vh !important; overflow: auto; overflow-x: hidden;">
                  <ng-container *ngIf="!newEvent?.length && !eventsData ">
                    <div class="center-error" style="margin-top: 137px;">
                      <div class="error-wrapper">
                        <h1 class="text-center" style="color: white;">Oops</h1>
                        <p class="text-center" style="color: white;">No Data Found.</p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="eventsData">
                    <div style="text-align: center;">
                      <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
                        secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
                    </div>
                  </ng-container>
                  <ng-container *ngFor="let events of newEvent; let index = index">
                    <div class="meraww" style="cursor: pointer;">
                      <div class="udff mb-2" data-toggle="modal" data-target="#rockband" (click)="openRockBand(events)">
                        <div class="edftg breakLine">
                          <h4 class="mb-1" style="width: 100%;">{{events?.title | titlecase }}</h4>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <p class="mb-1" *ngFor="let genres of events.genres | slice:0:2; let i=index">{{genres}}</p>
                            <!-- <i class="fa fa-heart-o" aria-hidden="true"></i> -->
                          </div>
                        </div>
                      </div>
                      <div class="udff mb-1 " data-toggle="modal" data-target="#rockband"
                        (click)="openRockBand(events)">
                        <div class="edftg">
                          <p class="mb-1">Event Id: {{events?.id}} </p>
                          <p class="mb-1">Start Date: {{events?.startDate}} </p>
                          <p class="mb-1">End Date: {{events?.endDate}} </p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <h5>${{events.budget}}</h5>

                          </div>
                        </div>
                      </div>
                      <div class="udff">
                        <div data-toggle="modal" data-target="#rockband" (click)="openRockBand(events)"
                          class="edftg  loat">
                          <p class="mb-1">{{events.visibility}} <span *ngIf="events.dateStatus == 'Day After'">Created
                              on {{events.createdAt}}</span>
                            <span *ngIf="events.dateStatus == 'Today'">Created Today</span>
                            <span *ngIf="events.dateStatus == 'Yesterday'">Created Yesterday</span>
                          </p>
                          <p class="ml-4 mb-1">Status <span>{{events.status}}</span></p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar dwatr d-flex flex-row-reverse">
                            <p class="mb-1">Proposal</p>
                            <h5 class="mx-3 mb-1">{{events.proposal}}</h5>
                            <div class="buttontext">
                              <button [disabled]="userStatus == 'PENDING_DELETE'"
                                *ngIf="events.status == 'HIRING_COMPLETED' && this.currentUserId != events.eventCreatedBy && events.proposalStatus != 'PERFORMANCE_ENDED'"
                                style="width: 135px;" (click)="getTimeDifference(events, index)" type="submit"
                                class="btn defrq edcae" data-toggle="modal" data-target="#liveStreamModal">
                                <i class="mr-2"> <img src="../../../../../../../assets/goLiveLogo.png" alt="image"> </i>
                                Go Live
                              </button>
                            </div>
                            <div class="buttontext">
                              <button [disabled]="userStatus == 'PENDING_DELETE'"
                                *ngIf="this.currentUserId == events.eventCreatedBy && (events?.status == 'HIRING_COMPLETED' || events?.status == 'HIRING_IN_PROGRESS')"
                                type="submit" class="btn defrq edcae" (click)="liveStream(events.id)">
                                <i class="mr-2"> <img src="../../../../../../../assets/watchLiveLogo.png" alt="image">
                                </i> Watch Live</button>
                            </div>
                            <div class="buttontext">
                              <button
                                *ngIf="this.currentUserId == events.eventCreatedBy && (events?.status == 'HIRING_COMPLETED' || events?.status == 'HIRING_IN_PROGRESS')"
                                type="submit" class="btn defrq edcae mr-1" (click)="shareEvent(events?.id)">
                                <i class="mr-2"> <img src="../../../../../../../assets/watchLiveLogo.png" alt="image">
                                </i> Share Event</button>
                            </div>
                            <div class="buttontext mr-1">
                              <button [disabled]="userStatus == 'PENDING_DELETE'" (click)="doneHiring(events.id)"
                                *ngIf="events.status == 'HIRING_IN_PROGRESS' && this.currentUserId == events.eventCreatedBy"
                                style="width: 135px;" type="submit" class="btn defrq edcae">
                                <i class="pr-0 fa fa-thumbs-o-up"></i> Done Hiring
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div role="tabpanel" class="tab-pane fade" id="upcoming" infiniteScroll [infiniteScrollDistance]="2"
                  (scrolled)="onScroll('upcoming')" [scrollWindow]="false"
                  style="height: 60vh !important; overflow: auto; overflow-x: hidden;">
                  <ng-container *ngIf="!newEvent?.length && !eventsData">
                    <div class="center-error" style="margin-top: 137px;">
                      <div class="error-wrapper">
                        <h1 class="text-center" style="color: white;">Oops</h1>
                        <p class="text-center" style="color: white;">No Data Found.</p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="eventsData">
                    <div style="text-align: center;">
                      <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
                        secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
                    </div>
                  </ng-container>
                  <ng-container *ngFor="let events of newEvent; let index = index">
                    <div class="meraww" style="cursor: pointer;">
                      <div data-toggle="modal" data-target="#rockband" (click)="openRockBand(events)" class="udff mb-2">
                        <div class="edftg breakLine">
                          <h4 class="mb-1" style="width: 100%;">{{events?.title | titlecase }}</h4>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <p class="mb-1" *ngFor="let genres of events.genres | slice:0:2; let i=index">{{genres}}</p>
                            <!-- <i class="fa fa-heart-o" aria-hidden="true"></i> -->
                          </div>
                        </div>
                      </div>
                      <div data-toggle="modal" data-target="#rockband" (click)="openRockBand(events)" class="udff mb-1">
                        <div class="edftg">
                          <p class="mb-1">Event Id: {{events.id}} </p>
                          <p class="mb-1">Start Date: {{events.startDate}} </p>
                          <p class="mb-1">End Date: {{events.endDate}} </p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <h5 class="mb-1">${{events.budget}}</h5>
                          </div>
                        </div>
                      </div>
                      <div class="udff">
                        <div data-toggle="modal" data-target="#rockband" (click)="openRockBand(events)"
                          class="edftg  loat">
                          <p class="mb-1">{{events.visibility}} <span *ngIf="events.dateStatus == 'Day After'">Created
                              on {{events.createdAt }}</span>
                            <span *ngIf="events.dateStatus == 'Today'">Created Today</span>
                            <span *ngIf="events.dateStatus == 'Yesterday'">Created Yesterday</span>
                          </p>
                          <p class="ml-4 mb-1">Status <span>{{events?.status}}</span></p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar dwatr d-flex flex-row-reverse">
                            <p class="mb-1">Proposal</p>
                            <h5 class="mx-3 mb-1">{{events?.proposal}}</h5>
                            <div class="buttontext">
                              <button [disabled]="userStatus == 'PENDING_DELETE'"
                                *ngIf="events.status == 'HIRING_COMPLETED' && this.currentUserId != events.eventCreatedBy"
                                style="width: 135px;" (click)="getTimeDifference(events, index)" type="submit"
                                class="btn defrq edcae" data-toggle="modal" data-target="#liveStreamModal">
                                <i class="mr-2"> <img src="../../../../../../../assets/goLiveLogo.png" alt="image"> </i>
                                Go Live
                              </button>
                            </div>
                            <div class="buttontext">
                              <button [disabled]="userStatus == 'PENDING_DELETE'"
                                *ngIf="this.currentUserId == events.eventCreatedBy && (events?.status == 'HIRING_COMPLETED' || events?.status == 'HIRING_IN_PROGRESS')"
                                type="submit" class="btn defrq edcae" (click)="liveStream(events.id)">
                                <i class="mr-2"> <img src="../../../../../../../assets/watchLiveLogo.png" alt="image">
                                </i>
                                Watch Live</button>
                            </div>
                            <div class="buttontext">
                              <button
                                *ngIf="this.currentUserId == events.eventCreatedBy && (events?.status == 'HIRING_COMPLETED' || events?.status == 'HIRING_IN_PROGRESS')"
                                type="submit" class="btn defrq edcae mr-1" (click)="shareEvent(events?.id)">
                                <i class="mr-2"> <img src="../../../../../../../assets/watchLiveLogo.png" alt="image">
                                </i>
                                Share Event</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div role="tabpanel" class="tab-pane fade" id="pending" infiniteScroll [infiniteScrollDistance]="2"
                  (scrolled)="onScroll('pending')" [scrollWindow]="false"
                  style="height: 60vh !important; overflow: auto; overflow-x: hidden;">
                  <ng-container *ngIf="!newEvent?.length && !eventsData">
                    <div class="center-error" style="margin-top: 137px;">
                      <div class="error-wrapper">
                        <h1 class="text-center" style="color: white;">Oops</h1>
                        <p class="text-center" style="color: white;">No Data Found.</p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="eventsData">
                    <div style="text-align: center;">
                      <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
                        secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
                    </div>
                  </ng-container>
                  <ng-container *ngFor="let events of newEvent">
                    <div class="meraww" data-toggle="modal" data-target="#rockband" (click)="openRockBand(events)"
                      style="cursor: pointer;">
                      <div class="udff mb-2">
                        <div class="edftg breakLine">
                          <h4 class="mb-1" style="width: 100%;">{{events?.title | titlecase }}</h4>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <p class="mb-1" *ngFor="let genres of events.genres | slice:0:2; let i=index">{{genres}}</p>
                            <!-- <i class="fa fa-heart-o" aria-hidden="true"></i> -->
                          </div>
                        </div>
                      </div>
                      <div class="udff mb-1">
                        <div class="edftg">
                          <p class="mb-1">Event Id: {{events.id}} </p>
                          <p class="mb-1">Start Date: {{events.startDate}} </p>
                          <p class="mb-1">End Date: {{events.endDate}} </p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <h5>${{events.budget}}</h5>
                          </div>
                        </div>
                      </div>
                      <div class="udff">
                        <div class="edftg  loat">
                          <p class="mb-1">{{events.visibility}} <span *ngIf="events.dateStatus == 'Day After'">Created
                              on {{events.createdAt }}</span>
                            <span *ngIf="events.dateStatus == 'Today'">Created Today</span>
                            <span *ngIf="events.dateStatus == 'Yesterday'">Created Yesterday</span>
                          </p>
                          <p class="ml-4 mb-1">Status <span>{{events.status}}</span></p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar dwatr d-flex flex-row-reverse">
                            <p class="mb-1">Proposal</p>
                            <h5 class="mx-3 mb-1">{{events.proposal}}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div role="tabpanel" class="tab-pane fade in" id="proposed" infiniteScroll [infiniteScrollDistance]="2"
                  (scrolled)="onScroll('proposed')" [scrollWindow]="false"
                  style="height: 60vh !important; overflow: auto; overflow-x: hidden;">
                  <ng-container *ngIf="!newEvent?.length && !eventsData">
                    <div class="center-error" style="margin-top: 137px;">
                      <div class="error-wrapper">
                        <h1 class="text-center" style="color: white;">Oops</h1>
                        <p class="text-center" style="color: white;">No Data Found.</p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="eventsData">
                    <div style="text-align: center;">
                      <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
                        secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
                    </div>
                  </ng-container>
                  <ng-container *ngFor="let events of newEvent">
                    <div class="meraww" data-toggle="modal" data-target="#rockband" (click)="openRockBand(events)"
                      style="cursor: pointer;">
                      <div class="udff mb-2">
                        <div class="edftg breakLine">
                          <h4 class="mb-1" style="width: 100%;">{{events?.title | titlecase }}</h4>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <p class="mb-1" *ngFor="let genres of events.genres | slice:0:2; let i=index">{{genres}}</p>
                            <!-- <i class="fa fa-heart-o" aria-hidden="true"></i> -->
                          </div>
                        </div>
                      </div>
                      <div class="udff mb-1">
                        <div class="edftg">
                          <p class="mb-1">Event Id: {{events.id}} </p>
                          <p class="mb-1">Start Date: {{events.startDate}} </p>
                          <p class="mb-1">End Date: {{events.endDate}} </p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <h5>${{events.budget}}</h5>
                          </div>
                        </div>
                      </div>
                      <div class="udff">
                        <div class="edftg  loat">
                          <p class="mb-1">{{events.visibility}} <span *ngIf="events.dateStatus == 'Day After'">Created
                              on {{events.createdAt }}</span>
                            <span *ngIf="events.dateStatus == 'Today'">Created Today</span>
                            <span *ngIf="events.dateStatus == 'Yesterday'">Created Yesterday</span>
                          </p>
                          <p class="ml-4 mb-1">Status <span>{{events.status}}</span></p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar dwatr d-flex flex-row-reverse">
                            <p class="mb-1">Proposal</p>
                            <h5 class="mx-3 mb-1">{{events.proposal}}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div role="tabpanel" class="tab-pane fade" id="cancelled" infiniteScroll [infiniteScrollDistance]="2"
                  (scrolled)="onScroll('cancelled')" [scrollWindow]="false"
                  style="height: 60vh !important; overflow: auto; overflow-x: hidden;">
                  <ng-container *ngIf="!newEvent?.length && !eventsData">
                    <div class="center-error" style="margin-top: 137px;">
                      <div class="error-wrapper">
                        <h1 class="text-center" style="color: white;">Oops</h1>
                        <p class="text-center" style="color: white;">No Data Found.</p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="eventsData">
                    <div style="text-align: center;">
                      <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
                        secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
                    </div>
                  </ng-container>
                  <ng-container *ngFor="let events of newEvent">
                    <div class="meraww" data-toggle="modal" data-target="#rockband" (click)="openRockBand(events)"
                      style="cursor: pointer;">
                      <div class="udff mb-2">
                        <div class="edftg breakLine">
                          <h4 class="mb-1" style="width: 100%;">{{events?.title | titlecase }}</h4>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <p class="mb-1" *ngFor="let genres of events.genres | slice:0:2; let i=index">{{genres}}</p>
                            <!-- <i class="fa fa-heart-o" aria-hidden="true"></i> -->
                          </div>
                        </div>
                      </div>
                      <div class="udff mb-1">
                        <div class="edftg">
                          <p class="mb-1">Event Id: {{events.id}} </p>
                          <p class="mb-1">Start Date: {{events.startDate}} </p>
                          <p class="mb-1">End Date: {{events.endDate}} </p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <h5>${{events.budget}}</h5>
                          </div>
                        </div>
                      </div>
                      <div class="udff">
                        <div class="edftg  loat">
                          <p class="mb-1">{{events.visibility}} <span *ngIf="events.dateStatus == 'Day After'">Created
                              on {{events.createdAt }}</span>
                            <span *ngIf="events.dateStatus == 'Today'">Created Today</span>
                            <span *ngIf="events.dateStatus == 'Yesterday'">Created Yesterday</span>
                          </p>
                          <p class="ml-4 mb-1">Status <span>{{events.status}}</span></p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar dwatr d-flex flex-row-reverse">
                            <p class="mb-1">Proposal</p>
                            <h5 class="mx-3 mb-1">{{events.proposal}}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div role="tabpanel" class="tab-pane fade in " id="invited" infiniteScroll [infiniteScrollDistance]="2"
                  (scrolled)="onScroll('invited')" [scrollWindow]="false"
                  style="height: 60vh !important; overflow: auto; overflow-x: hidden;">
                  <ng-container *ngIf="!newEvent?.length && !eventsData">
                    <div class="center-error" style="margin-top: 137px;">
                      <div class="error-wrapper">
                        <h1 class="text-center" style="color: white;">Oops</h1>
                        <p class="text-center" style="color: white;">No Data Found.</p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="eventsData">
                    <div style="text-align: center;">
                      <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
                        secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
                    </div>
                  </ng-container>
                  <ng-container *ngFor="let events of newEvent">
                    <div class="meraww" data-toggle="modal" data-target="#rockband" (click)="openRockBand(events)"
                      style="cursor: pointer;">
                      <div class="udff mb-2">
                        <div class="edftg breakLine">
                          <h4 class="mb-1" style="width: 100%;">{{events?.title | titlecase }}</h4>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <p class="mb-1" *ngFor="let genres of events?.genres | slice:0:2; let i=index">{{genres}}
                            </p>
                            <!-- <i class="fa fa-heart-o" aria-hidden="true"></i> -->
                          </div>
                        </div>
                      </div>
                      <div class="udff mb-1">
                        <div class="edftg">
                          <p class="mb-1">Event Id: {{events?.id}} </p>
                          <p class="mb-1">Start Date: {{events?.startDate }} </p>
                          <p class="mb-1">End Date: {{events?.endDate }} </p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <h5>${{events.budget}}</h5>
                          </div>
                        </div>
                      </div>
                      <div class="udff">
                        <div class="edftg  loat">
                          <p class="mb-1">{{events.visibility}} <span *ngIf="events.dateStatus == 'Day After'">Created
                              on {{events.createdAt }}</span>
                            <span *ngIf="events.dateStatus == 'Today'">Created Today</span>
                            <span *ngIf="events.dateStatus == 'Yesterday'">Created Yesterday</span>
                          </p>
                          <p class="ml-4 mb-1">Status <span>{{events.status}}</span></p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar dwatr d-flex flex-row-reverse">
                            <p class="mb-1">Proposal</p>
                            <h5 class="mx-3 mb-1">{{events.proposal}}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div role="tabpanel" class="tab-pane fade in " id="Completed" infiniteScroll
                  [infiniteScrollDistance]="2" (scrolled)="onScroll('completed')" [scrollWindow]="false"
                  style="height: 60vh !important; overflow: auto; overflow-x: hidden;">
                  <ng-container *ngIf="!newEvent?.length && !eventsData">
                    <div class="center-error" style="margin-top: 137px;">
                      <div class="error-wrapper">
                        <h1 class="text-center" style="color: white;">Oops</h1>
                        <p class="text-center" style="color: white;">No Data Found.</p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="eventsData">
                    <div style="text-align: center;">
                      <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
                        secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
                    </div>
                  </ng-container>
                  <ng-container *ngFor="let events of newEvent">
                    <div class="meraww" style="cursor: pointer;">
                      <div data-toggle="modal" data-target="#rockband" (click)="openRockBand(events)" class="udff mb-2">
                        <div class="edftg breakLine">
                          <h4 class="mb-1" style="width: 100%;">{{events?.title | titlecase }}</h4>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <p class="mb-1" *ngFor="let genres of events?.genres | slice:0:2; let i=index">{{genres}}
                            </p>
                            <!-- <i class="fa fa-heart-o" aria-hidden="true"></i> -->
                          </div>
                        </div>
                      </div>
                      <div data-toggle="modal" data-target="#rockband" (click)="openRockBand(events)" class="udff mb-1">
                        <div class="edftg">
                          <p class="mb-1">Event Id: {{events?.id}} </p>
                          <p class="mb-1">Start Date: {{events?.startDate }} </p>
                          <p class="mb-1">End Date: {{events?.endDate }} </p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar">
                            <h5>${{events.budget}}</h5>
                          </div>
                        </div>
                      </div>
                      <div class="udff">
                        <div data-toggle="modal" data-target="#rockband" (click)="openRockBand(events)"
                          class="edftg  loat">
                          <p class="mb-1">{{events.visibility}} <span *ngIf="events.dateStatus == 'Day After'">Created
                              on {{events.createdAt }}</span>
                            <span *ngIf="events.dateStatus == 'Today'">Created Today</span>
                            <span *ngIf="events.dateStatus == 'Yesterday'">Created Yesterday</span>
                          </p>
                          <p class="ml-4 mb-1">Status <span>{{events.status}}</span></p>
                        </div>
                        <div class="gonet">
                          <div class="qwsar dwatr d-flex flex-row-reverse">
                            <p>Proposal</p>
                            <h5 class="mx-3">{{events.proposal}}</h5>
                            <!-- [routerLink]="['/ArtistDashboard/proReporting' , events.id]" (click)="getProPlaylistDetails(events)"-->
                            <button [disabled]="userStatus == 'PENDING_DELETE'" class="btn hftrdd"
                            [routerLink]="['/ArtistDashboard/proReporting' , events.id]">
                              PRO Playlist
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</div>
<!-- Interactive Pro List -->
<!-- <div *ngIf="selectTab == 'intractivePro'" class="deaa" id="rockband">
  <div class=" modalBg my-0" role="document">
    <div class="judhr">
      <div class="modal-body" style="color: white;">
        <div class="container ">
          <div class="row">
            <div class="col-11 breakLine">
              <h4 class="mb=0">PRO Playlist</h4>
            </div>
            <div class="col-1 text-right">
              <button type="button" class="close">
                <span aria-hidden="true" (click)="selectTab = 'mainTab'" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
          <hr class="mt-0" style="border-bottom: 1px solid white;">
          <div class="row">
            <div class="col-12">
              <div class="dee">
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Select the event ">
                    <mat-option *ngFor="let event of proEventList" [value]="event" (click)="selectedProEvent = event">
                      {{event.title}} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <button type="submit" (click)="getProPlaylistDetails(selectedProEvent)" style="width: 45% !important"
                class="btn defrqqa edcae mt-2">View</button>
            </div>
            <div class="col-12 mb-1 mx-0 mt-3">
              <div class="row m-0" style="color: white;">
                <div class="col-6 d-flex align-items-center justify-content-end px-0">
                  <p class="m-1" style="font-weight:500">Title: {{proEventData.title}} &nbsp; &nbsp; </p>
                </div>
                <div class="col-3 d-flex align-items-center justify-content-end px-0">
                  <p class="m-1">Date: {{proEventData.startDate}} </p>
                </div>
                <div class="col-3 d-flex justify-content-end px-0">
                  <p class="m-1 createPRO" data-toggle="modal" data-target="#createNewPROList">
                    Create New PRO Playlist</p>
                  <p class="m-1 createNewSong" [routerLink]="['/ArtistDashboard/proReporting' , proEventData.id]">
                    Add New Song</p>
                </div>
              </div>
            </div>
            <div class="col-12 mb-1 mx-0">
              <div class="row m-0 tabMenu">
                <div class="col-4 text-center" [ngClass]="proTab=='addedSong'?'tabUI':''">
                  <p class="m-1 pointer" (click)="proTab='addedSong'">Added Songs</p>
                </div>
                <div class="col-4 text-center" [ngClass]="proTab=='myPlaylist'?'tabUI':''">
                  <p class="m-1 pointer" (click)="myPlaylist(); proTab='myPlaylist'">My Playlist</p>
                </div>
                <div class="col-4 text-center" [ngClass]="proTab=='repertoire'?'tabUI':''">
                  <p class="m-1 pointer" (click)="repertoire();proTab='repertoire'">Repertoire</p>
                </div>
              </div>
            </div>
            <div *ngIf="proTab=='addedSong'" class="col-12 mx-0">
              <div class="table-responsive" style="overflow: auto;height: 55vh;">
                <table class="table flatTable">
                  <thead class="tableHeader">
                    <tr class="tableRow">
                      <div class="col-1 px-0 px-md-1">
                        <th>
                          <label class="container1 m-0">
                            <input type="checkbox" (click)="selectAll()">
                            <span class="checkmark"></span>
                          </label>
                        </th>
                      </div>
                      <div class="col-1 px-0 px-md-1">
                        <th>
                          <p> # </p>
                        </th>
                      </div>
                      <div class="col-2 col-md-3 px-0 px-md-1">
                        <th>
                          <p> Title </p>
                        </th>
                      </div>
                      <div class="col-3 col-md-2 px-0 px-md-1">
                        <th>
                          <p> Catagories </p>
                        </th>
                      </div>
                      <div class="col-2 px-0 px-md-1">
                        <th>
                          <p> Artist </p>
                        </th>
                      </div>
                      <div class="col-2 px-0 px-md-1">
                        <th>
                          <p>Type</p>
                        </th>
                      </div>
                      <div class="col-1 px-0 px-md-1">
                        <th class="d-flex">
                          <p> Action </p>
                          <i class="fa fa-ellipsis-v px-2 mt-1 pointer" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="background: #242424; color:white;">
                            <a class="dropdown-item pointer" style="border-bottom: 1px solid white" data-toggle="modal"
                              data-target="#createNewPROList">Create New PRO Playlist</a>
                            <a class="dropdown-item pointer" (click)="existingPlaylist = !existingPlaylist">Add to
                              Existing Playlist</a>
                          </div>
                          <div *ngIf="existingPlaylist" class="existingPlaylist">
                            <p *ngFor="let playlist of myPlaylistDetails" (click)="addSongToExistingPROList()"
                              class="py-2 mb-0 pl-2 pointer" style="border-bottom: 1px solid white">
                              {{playlist.name | titlecase}}
                            </p>
                          </div>

                        </th>
                      </div>
                    </tr>
                  </thead>
                  <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                    <tr class="tableRow" *ngFor="let list of proListData;let i= index" cdkDrag cdkDragLockAxis="y">
                      <div class="col-1 px-0 px-md-1">
                        <td>
                          <label class="container1">
                            <input type="checkbox" [checked]="selectAllproList">
                            <span class="checkmark"></span>
                          </label>
                        </td>
                      </div>
                      <div class="col-1 px-0 px-md-1">
                        <td>
                          <p> {{i+1}} </p>
                        </td>
                      </div>
                      <div class="col-2 col-md-3 breakLine px-0 px-md-1">
                        <td>
                          <p> {{list.title |titlecase}} </p>
                        </td>
                      </div>
                      <div class="col-3 col-md-2 px-0 px-md-1">
                        <td>
                          {{list.categories}}
                        </td>
                      </div>
                      <div class="col-2 breakLine px-0 px-md-1">
                        <td>
                          <p> {{list.artist |titlecase}} </p>
                        </td>
                      </div>
                      <div class="col-2 px-0 px-md-1">
                        <td>
                          <p> {{list.proPlayListType}} </p>
                        </td>
                      </div>
                      <div class="col-1 px-0 px-md-1">
                        <td (click)="deleteProList(list.id)" style="cursor: pointer">
                          <p><i class="fa fa-trash-o"></i></p>
                        </td>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div *ngIf="proTab=='myPlaylist'" class="col-12 mx-0">
              <div class="row ml-0" *ngFor="let playlist of myPlaylistDetails; let i = index" style="width:100%; background:#242424; border-bottom: 1px solid white">
                <div class="col-12 py-2" style="color: white;">
                  <div class="row">
                    <div class="col-2 text-center">
                      <img src="../../../../../../../assets/Property 1=Default.png" height="50px" width="50px" alt="img">
                    </div>
                    <div class="col-8 d-flex align-items-center">
                      <p class="mb-0"> {{playlist.name}} </p>
                    </div>
                    <div class="col-2 d-flex justify-content-center align-items-center">
                      <i class="fa fa-chevron-down mr-3" data-toggle="collapse"
                        [attr.data-target]="'#collapseExample'+i" aria-expanded="false" aria-controls="collapseExample"
                        (click)="getAllSongOfPlaylist(playlist.id)"></i>

                        <i class="fa fa-ellipsis-v px-2 mt-1 pointer" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="background: #242424; color:white;">
                        <a class="dropdown-item pointer" (click)="completedMyPlaylist[i] = true;">Add Playlist to the Completed Events</a>
                      </div>
                      <div *ngIf="completedMyPlaylist[i]" class="existingPlaylist">
                          <p *ngFor="let event of proEventList" (click)="addPlaylistToExistingEvent(i)"
                            class="py-2 mb-0 pl-2 pointer" style="border-bottom: 1px solid white">
                            {{event.title | titlecase}}
                          </p>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="collapse" [id]="'collapseExample'+i" style="width:99%;">
                  <div class="table-responsive" style="overflow: auto;height: 35vh;">
                    <table class="table flatTable">
                      <thead class="tableHeader">
                        <tr class="tableRow">
                          <div class="col-1 px-0 px-md-1">
                            <th>
                              <label class="container1 m-0">
                                <input type="checkbox" (click)="selectAll()">
                                <span class="checkmark"></span>
                              </label>
                            </th>
                          </div>
                          <div class="col-1 px-0 px-md-1">
                            <th>
                              <p> # </p>
                            </th>
                          </div>
                          <div class="col-2 col-md-3 px-0 px-md-1">
                            <th>
                              <p> Title </p>
                            </th>
                          </div>
                          <div class="col-3 col-md-2 px-0 px-md-1">
                            <th>
                              <p> Catagories </p>
                            </th>
                          </div>
                          <div class="col-2 px-0 px-md-1">
                            <th>
                              <p> Artist </p>
                            </th>
                          </div>
                          <div class="col-2 px-0 px-md-1">
                            <th>
                              <p>Type</p>
                            </th>
                          </div>
                          <div class="col-1 px-0 px-md-1">
                            <th>
                              <p> Action </p>
                            </th>
                          </div>
                        </tr>
                      </thead>
                      <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                        <tr class="tableRow" *ngFor="let list of songList;let i= index" cdkDrag cdkDragLockAxis="y">
                          <div class="col-1 px-0 px-md-1">
                            <td>
                              <label class="container1">
                                <input type="checkbox" [checked]="selectAllproList">
                                <span class="checkmark"></span>
                              </label>
                            </td>
                          </div>
                          <div class="col-1 px-0 px-md-1">
                            <td>
                              <p> {{i+1}} </p>
                            </td>
                          </div>
                          <div class="col-2 col-md-3 breakLine px-0 px-md-1">
                            <td>
                              <p> {{list.title |titlecase}} </p>
                            </td>
                          </div>
                          <div class="col-3 col-md-2 px-0 px-md-1">
                            <td>
                              {{list.category}}
                            </td>
                          </div>
                          <div class="col-2 breakLine px-0 px-md-1">
                            <td>
                              <p> {{list.artist |titlecase}} </p>
                            </td>
                          </div>
                          <div class="col-2 px-0 px-md-1">
                            <td>
                              <p> {{list.type}} </p>
                            </td>
                          </div>
                          <div class="col-1 px-0 px-md-1">
                            <td (click)="deleteProList(list.id)" style="cursor: pointer">
                              <p><i class="fa fa-trash-o"></i></p>
                            </td>
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="proTab=='repertoire'" class="col-12 mx-0">
              <div class="table-responsive" style="overflow: auto;height: 55vh;">
                <table class="table flatTable">
                  <thead class="tableHeader">
                    <tr class="tableRow">
                      <div class="col-1 px-0 px-md-1">
                        <th>
                          <label class="container1 m-0">
                            <input type="checkbox" (click)="selectAll()">
                            <span class="checkmark"></span>
                          </label>
                        </th>
                      </div>
                      <div class="col-1 px-0 px-md-1">
                        <th>
                          <p> # </p>
                        </th>
                      </div>
                      <div class="col-2 col-md-3 px-0 px-md-1">
                        <th>
                          <p> Title </p>
                        </th>
                      </div>
                      <div class="col-3 col-md-2 px-0 px-md-1">
                        <th>
                          <p> Catagories </p>
                        </th>
                      </div>
                      <div class="col-2 px-0 px-md-1">
                        <th>
                          <p> Artist </p>
                        </th>
                      </div>
                      <div class="col-2 px-0 px-md-1">
                        <th>
                          <p>Type</p>
                        </th>
                      </div>
                      <div class="col-1 px-0 px-md-1">
                        <th class="d-flex">
                          <p> Action </p>
                        <i class="fa fa-ellipsis-v px-2 mt-1 pointer" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"
                        style="background: #242424; color:white;">
                        <a class="dropdown-item pointer" (click)="completedEventList = true;">Add Song to the Completed Events</a>
                      </div>
                      <div *ngIf="completedEventList" class="existingPlaylist">
                          <p *ngFor="let event of proEventList" (click)="addSongToExistingEvent()"
                            class="py-2 mb-0 pl-2 pointer" style="border-bottom: 1px solid white">
                            {{event.title | titlecase}}
                          </p>
                        </div>
                        </th>
                      </div>

                    </tr>
                  </thead>
                  <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                    <tr class="tableRow" *ngFor="let list of repertoireData;let i= index" cdkDrag cdkDragLockAxis="y">
                      <div class="col-1 px-0 px-md-1">
                        <td>
                          <label class="container1">
                            <input type="checkbox" [checked]="selectAllproList">
                            <span class="checkmark"></span>
                          </label>
                        </td>
                      </div>
                      <div class="col-1 px-0 px-md-1">
                        <td>
                          <p> {{i+1}} </p>
                        </td>
                      </div>
                      <div class="col-2 col-md-3 breakLine px-0 px-md-1">
                        <td>
                          <p> {{list.title |titlecase}} </p>
                        </td>
                      </div>
                      <div class="col-3 col-md-2 px-0 px-md-1">
                        <td>
                          {{list.categories}}
                        </td>
                      </div>
                      <div class="col-2 breakLine px-0 px-md-1">
                        <td>
                          <p> {{list.artist |titlecase}} </p>
                        </td>
                      </div>
                      <div class="col-2 px-0 px-md-1">
                        <td>
                          <p> EVENT </p>
                        </td>
                      </div>
                      <div class="col-1 px-0 px-md-1">
                        <td (click)="deleteProList(list.id)" style="cursor: pointer">
                          <p><i class="fa fa-trash-o"></i></p>
                        </td>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- create new pro playlist modal -->
<!-- <div class="modal fade soundCheck" data-backdrop="false" id="createNewPROList" tabindex="-1" role="dialog"
  aria-labelledby="soundCheckModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg">
      <div class="modal-body" style="color: white;">
        <div class="container mb-1">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container my-1">
          <div class="row">
            <div class="col-12 ">
              <h5>Add Playlist Details</h5>
              <p class="mb-1">Playlist Name</p>
              <input type="text" placeholder="Enter Playlist Name" style="color:white; background:none; width:100%; border-radius:20px; border:1px solid
              white" class="py-2 pl-2">
              <p class="mb-1 mt-3">Description</p>
              <textarea cols="30" rows="3" style="background: none; width:100%; border-radius: 15px; color: white;"
                autocomplete="off" class="pl-2" placeholder="Enter Your Description"></textarea>
            </div>
            <div class="col-12 text-center">
              <button class="btn defrqqa edcae mt-3" data-dismiss="modal">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Feature disabled in mobile , Modal -->
<div class="modal fade" *ngIf="showMessage" data-backdrop="false" id="soundCheckModal" tabindex="-1" role="dialog"
  aria-labelledby="soundCheckModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg">
      <div class="modal-body" style="color: white;">
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container my-5">
          <div class="row">
            <div class="col-12 text-center">
              <h5>This feature is not available on mobile phones.</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Sound Check Modal -->
<div class="modal fade soundCheck" *ngIf="!showMessage" data-backdrop="false" id="soundCheckModal" tabindex="-1"
  role="dialog" aria-labelledby="soundCheckModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg">
      <div class="modal-body" style="color: white;">
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container my-5">
          <div class="row">
            <div class="col-12 text-center">
              <h5>This is for the Testing Environment </h5>
              <button [disabled]="userStatus == 'PENDING_DELETE'" class="btn defrqqa edcae mt-5" data-dismiss="modal"
                (click)="liveStreamingPage('soundCheck')">Sound Check</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade liveStreamPage" *ngIf="showMessage" data-backdrop="false" id="liveStreamModal" tabindex="-1"
  role="dialog" aria-labelledby="liveStreamModalLabel" aria-hidden="true">
  <div class="modal-dialog mt-5 pt-5" role="document">
    <div class="modal-content modalBg">
      <div class="modal-body" style="color: white;">
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container my-5">
          <div class="row">
            <div class="col-12 text-center">
              <h5>This feature is not available on mobile phones.</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Live Stream Modal -->
<div class="modal fade liveStreamPage" *ngIf="!showMessage" data-backdrop="false" id="liveStreamModal" tabindex="-1"
  role="dialog" aria-labelledby="liveStreamModalLabel" aria-hidden="true">
  <div class="modal-dialog mt-5 pt-5" role="document">
    <div class="modal-content modalBg">
      <div class="modal-body" style="color: white;">
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container my-5">
          <div class="row">
            <div class="col-12 text-center">
              <h5>This Live streaming page for this event will be available 30 minutes prior to the event. If you need
                to test your setup please go to Sound Check. </h5>
              <!-- <p>{{goLive[selectedEventNo]}}</p> -->
              <!-- [disabled]="goLive[selectedEventNo] == false ? true : false" -->
              <button [disabled]="userStatus == 'PENDING_DELETE'" class="btn defrqqa edcae mt-5" data-dismiss="modal"
                [disabled]="goLive[selectedEventNo] == false ? true : false"
                (click)="liveStreamingPage('liveStream')">Start Streaming</button>
              <!-- [disabled]="goLive[selectedEventNo] == false ? true : false" -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Event Detail Tab -->
<div *ngIf="selectTab == 'eventDetailTab'" class="deaa ml-5" id="rockband">
  <div class=" modalBg my-0" role="document">
    <div class="judhr">
      <div class="modal-body" style="color: white;">
        <div class="container ">
          <div class="row pb-3">
            <div class="col-9 mt-3 breakLine">
              <h5>{{eventDetails?.title | titlecase}}</h5>
            </div>
            <div class="col-3 mt-3 text-right">
              <button type="button" class="close">
                <span aria-hidden="true" (click)="selectTab='mainTab'" style="color: white;">&times;</span>
              </button>
              <button [disabled]="userStatus == 'PENDING_DELETE'" type="submit"
                *ngIf="eventDetails?.status != 'CANCELLED' && eventDetails?.status != 'COMPLETED'"
                class="btn hftrdd ml-4" data-dismiss="modal" data-toggle="modal" data-target="#cancelEvent">Cancel
                Event</button>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <p><b>Event Status</b></p>
              <p>{{eventDetails?.status}}</p>
            </div>
            <div class="col-3">
              <p><b>Budget</b></p>
              <p>{{eventDetails?.budget}}</p>
            </div>
            <div class="col-3">
              <p><b>Start Date</b></p>
              <p>{{eventDetails?.startDate }}</p>
            </div>
            <div class="col-3">
              <p><b>End Date</b></p>
              <p class="mb-0">{{eventDetails?.endDate }} </p>
            </div>
          </div>
          <div class="row py-3">
            <div class="col-12">
              <button type="submit" class="btn defrqqa edcae" (click)="getAllProposalList()">View Proposal</button>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <div class="row">
                <div class="col-6">
                  <p>Event Type</p>
                  <div class="row text-justify">
                    <ng-container *ngFor="let eventType of eventDetails?.eventTypeDetails;let isLast=last">
                      <span style="font-size: 14px;font-weight: 300;padding-left: 15px;">{{eventType}}{{isLast ? '' :
                        ','}}</span>
                    </ng-container>
                  </div>
                </div>
                <div class="col-6">
                  <p>Preferred Device</p>
                  <div class="row text-justify">
                    <ng-container *ngFor="let device of eventDetails?.preferredDeviceDetails;let isLast=last">
                      <span style="font-size: 14px;font-weight: 300;padding-left: 15px;">{{device}}{{isLast ? '' :
                        ','}}</span>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <p>Size of Act</p>
                  <div class="row">
                    <ng-container *ngFor="let band of eventDetails?.actSizeDetails;let isLast=last">
                      <p style="font-size: 14px;font-weight: 300;padding-left: 15px;">{{band}}{{isLast ? '' : ', '}}</p>
                    </ng-container>
                  </div>
                </div>
                <div class="col-6">
                  <p>Genres</p>
                  <div class="row">
                    <ng-container *ngFor="let geners of eventDetails?.genreDetails;let isLast=last">
                      <span style="font-size: 14px;font-weight: 300;padding-left: 15px;">{{geners}}{{isLast ? '' :
                        ','}}</span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3" style="border-left: 1px dashed white;">
              <div>
                <div class="rtfg mb-3 breakLine"
                  *ngIf="eventDetails?.venue === 'ONSITE' || eventDetails?.venue === 'HYBRID' ">
                  <p>Performance Environment</p>
                  <p>{{eventDetails?.performanceEnvironment ?
                    eventDetails?.performanceEnvironment: 'NA'}}</p>
                </div>
                <div class="rtfg mb-3 breakLine"
                  *ngIf="eventDetails?.venue === 'ONSITE' || eventDetails?.venue === 'HYBRID' ">
                  <p>Street Address</p>
                  <p>{{eventDetails?.streetAddress ? eventDetails?.streetAddress: 'NA'}}
                  </p>
                </div>
                <div class="rtfg mb-3">
                  <p>Venue</p>
                  <p>{{eventDetails?.venue ? eventDetails?.venue: 'NA'}}</p>
                </div>
                <div class="rtfg mb-3">
                  <p>Visibility</p>
                  <p>{{eventDetails?.visibility ? eventDetails?.visibility: 'NA'}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-1">
            <div class="my-4" *ngIf="eventDetails?.description">
              <h5>Event Description</h5>
              <p class="gry breakLine">
                {{eventDetails?.description}}
              </p>
            </div>
            <div class="my-3" *ngIf="eventDetails?.productionCapability">
              <h5>Production Capability</h5>
              <p class="gry breakLine">
                {{eventDetails?.productionCapability ? eventDetails?.productionCapability: 'NA'}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Event Modal -->
<div class="modal fade mt-1 pt-5" data-backdrop="false" id="cancelEvent" tabindex="-1" role="dialog"
  aria-labelledby="cancelEventModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg" style="margin-top: 0px;">
      <div class="modal-body" style="color: white;">
        <div class="container mb-3">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-center">
              <p class="text-justify"> Are you sure you want to cancel this event? You may still be charged for part or
                all of the fees assotiated with this event. Please make sure you understand our cancellation policy
                before canceling this event. For help with canceling this event, please contact Virtuosica Customer
                Service.
              </p>
              <a style="color: white"> Customer Service Contact </a> <br>
              <a style="color: white"> cancellation Policy Article </a>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button type="submit" class="btn hftrdd mt-5 mr-4" style="width: 200px;" data-dismiss="modal"
                aria-label="Close"> Go Back </button>
              <button class="btn defrqqa mt-5" data-dismiss="modal" data-toggle="modal" data-target="#cancelEvemtModal2"
                style="width: 200px;"> Cancel Event </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Event Modal 2 -->
<div class="modal fade mt-1 pt-5" data-backdrop="false" data-backdrop="false" id="cancelEvemtModal2" tabindex="-1"
  role="dialog" aria-labelledby="eventEventModal2Label" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg" style="margin-top: 0px;">
      <div class="modal-body" style="color: white;">
        <div class="container mb-3">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="confirmCancelEvent = false">
                <span aria-hidden="true" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-center">
              <p class="text-justify"> By selecting CONFIRM, You are accepting Virtuosica's cancellation policy and and
                any fees associated with the cancellation of this event. Once completed, this action can't be undone.
              </p>
              <div class="form-check">
                <input class="form-check-input" [(ngModel)]="confirmCancelEvent" (click)="checkEventCondition()"
                  type="checkbox" value="" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  I understand and accept Virtuosica's cancellation policy and wish to cancel this event.
                </label>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button type="submit" class="btn hftrdd mt-5 mr-4" style="width: 200px;" data-dismiss="modal"
                aria-label="Close" (click)="confirmCancelEvent = false"> Go Back </button>
              <button class="btn defrqqa mt-5" [disabled]="!confirmCancelEvent" data-dismiss="modal" data-toggle="modal"
                data-target="#cancelEventReasonModal" style="width: 200px;"> Confirm </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Event Reason Modal -->
<div class="modal fade mt-5 pt-5" data-backdrop="false" id="cancelEventReasonModal" tabindex="-1" role="dialog"
  aria-labelledby="eventEndedModal2Label" aria-hidden="true">
  <div class="modal-dialog mt-0" role="document">
    <div class="modal-content modalBg" style="margin-top: 0px;">
      <div class="modal-body" style="color: white;">
        <div class="container mb-3">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="confirmCancelEvent = false; cancelReason = ''">
                <span aria-hidden="true" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-center">
              <p class="text-justify"> You have canceled this event. Any available refunds will appear in the Billings
                section and may take several days to process. </p>
              <p class="text-justify"> Please provide a reason for canceling this event. This is not a requirement, but
                we'd like your feedback so we can do a better job in assisting you in the future. </p>
              <textarea class="textBox mt-3 pt-2 pl-2" [(ngModel)]="cancelReason" placeholder="Enter your reason here"
                name="" id="" cols="30" rows="4"></textarea>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button type="submit" class="btn hftrdd mt-5 mr-4" style="width: 200px;" data-dismiss="modal"
                aria-label="Close" (click)="confirmCancelEvent = false; cancelReason = ''"> Cancel </button>
              <button [disabled]="userStatus == 'PENDING_DELETE'" class="btn defrqqa mt-5" data-dismiss="modal"
                [disabled]="cancelReason == null || cancelReason == undefined || cancelReason.length == 0"
                (click)="cancelEvent()" style="width: 200px;"> Submit </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Proposals List Tab -->
<div *ngIf="selectTab == 'proposalsListTab'" class="deaa ml-5" id="rockband">
  <div class=" modalBg my-0" role="document">
    <div class="judhr">
      <div class="modal-body" style="color: white;">
        <div class="container ">
          <div class="row">
            <div class="col-11 mt-3 breakLine">
              <h4 class="mb=0">Proposals for {{eventDetails?.title | titlecase}}</h4>
            </div>
            <div class="col-1 text-right">
              <button type="button" class="close">
                <span aria-hidden="true" (click)="selectTab='eventDetailTab'" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
          <hr class="mt-0" style="border-bottom: 1px solid white;">
          <div class="row">
            <div class="col-12">
              <span>
                <p class="py-1 chip"> {{proposalsList?.length}} Proposals</p>
              </span>
            </div>
            <div class="col-12 my-3">
              <div class="container">
                <div *ngFor="let proposal of proposalsList" class="row mb-4 py-3 sthutr"
                  (click)="selectedProposal(proposal.proposalId,'meAsClient')" style="width: 100%; cursor: pointer;">
                  <div class="col-2">
                    <p class="mb-0"><b>{{proposal?.displayName}}</b></p>
                  </div>
                  <div class="col-3">
                    <p class="mb-0"><span *ngFor="let gen of proposal?.genres"><b> {{gen}} </b></span></p>
                  </div>
                  <div class="col-3">
                    <p class="mb-0"><b>{{proposal?.createdAt }}</b></p>
                  </div>
                  <div class="col-2 breakLine">
                    <p class="mb-0"><span *ngFor="let loc of proposal?.locations"><b>{{loc}}</b></span></p>
                  </div>
                  <div class="col-2 pl-0 d-flex justify-content-end">
                    <p class="mr-5"><b>${{proposal?.totalFee}}</b></p>
                    <p class="ml-2 px-1 mt-1" style="height: 8px; border-radius: 10px;" [title]="proposalTitle"
                      [ngClass]="(proposal?.proposalStatus == 'PROPOSAL_OWNER_ACCEPTED' || proposal?.proposalStatus == 'COMPLETED') ? 'green' : (proposal?.proposalStatus == 'DECLINED' || proposal?.proposalStatus == 'CANCELLED') ? 'red': proposal?.proposalStatus == 'EVENT_OWNER_ACCEPTED' ? 'orange' : 'yellow'">
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Individual Proposal View -->
<div *ngIf="selectTab == 'individualProposalView'" class="deaa ml-4" id="rockband">
  <div class="container" style="color: white;">
    <div class="row">
      <div class="col-8">
        <h4>Review Proposal</h4>
      </div>
      <div class="col-4 text-right">
        <button [disabled]="userStatus == 'PENDING_DELETE'"
          *ngIf="selectedProposalData?.proposalStatus != 'DECLINED' && proposalType != 'clientAccepted' && selectedProposalData?.proposalStatus != 'CANCELLED' && eventDetails?.status != 'CANCELLED' && eventDetails?.status != 'COMPLETED'"
          type="submit" class="btn defrqqa edcae"
          (click)="sendMessage(selectedProposalData?.submittedBy,selectedProposalData?.displayName)">Negotiate
          Terms</button>
        <!-- <button [disabled]="userStatus == 'PENDING_DELETE'" *ngIf="this.currentUserId == events.eventCreatedBy && (selectedProposalData?.proposalStatus != 'DECLINED' && proposalType != 'clientAccepted' && eventDetails.status != 'CANCELLED' && eventDetails.status != 'COMPLETED')"  type="submit" class="btn defrqqa edcae"  (click)="sendMessage(selectedProposalData?.submittedBy,selectedProposalData?.displayName)">Negotiate Terms</button> -->
      </div>
    </div>
  </div>
  <hr class="mt-0 mb-4" style="border-bottom: 1px solid white;">
  <div class="" role="document">
    <div class=" modalBg judhr" style="border-radius: 10px !important; margin-top: 0px;">
      <div class="modal-body" style="color: white;">
        <div class="container">
          <div class="row">
            <div class="col-8">
              <h5 class="mb-0">Event Details</h5>
            </div>
            <div class="col-4 d-flex flex-row-reverse">
              <!-- *ngIf="selectedProposalData?.proposalStatus != 'DECLINED' && selectedProposalData?.eventStatus != 'COMPLETED' && selectedProposalData?.proposalStatus != 'CANCELLED'" -->
              <button [disabled]="userStatus == 'PENDING_DELETE'"
                *ngIf="selectedProposalData?.proposalStatus != 'PERFORMANCE_STARTED' && selectedProposalData?.proposalStatus != 'PERFORMANCE_ENDED' && selectedProposalData?.proposalStatus == 'PROPOSAL_OWNER_ACCEPTED' && selectedProposalData?.eventStatus != 'COMPLETED' && selectedProposalData?.eventStatus != 'CANCELLED'"
                type="submit" class="btn hftrdd" data-dismiss="modal" data-toggle="modal"
                data-target="#cancelGig">Cancel Contract</button>
              <button [disabled]="userStatus == 'PENDING_DELETE'"
                *ngIf="selectedProposalData?.proposalStatus != 'PERFORMANCE_STARTED' && selectedProposalData?.proposalStatus != 'PERFORMANCE_ENDED' && selectedProposalData?.proposalStatus != 'DECLINED' && selectedProposalData?.proposalStatus != 'CANCELLED' && proposalType != 'clientAccepted' && selectedProposalData?.proposalStatus != 'COMPLETED' && selectedProposalData?.eventStatus != 'COMPLETED' && selectedProposalData?.eventStatus != 'CANCELLED'"
                type="submit" class="btn hftrdd mr-3" (click)="selectTab = 'editProposal'">Edit Proposal</button>
              <button [disabled]="userStatus == 'PENDING_DELETE'"
                *ngIf="selectedProposalData?.proposalStatus != 'PERFORMANCE_STARTED' && selectedProposalData?.proposalStatus != 'PERFORMANCE_ENDED' && selectedProposalData?.proposalStatus != 'DECLINED' && selectedProposalData?.proposalStatus != 'CANCELLED' && selectedProposalData?.proposalStatus != 'PROPOSAL_OWNER_ACCEPTED' && proposalType == 'clientAccepted' && selectedProposalData?.eventStatus != 'COMPLETED' && selectedProposalData?.eventStatus != 'CANCELLED' && selectedProposalData?.proposalStatus != 'COMPLETED'"
                type="submit" class="btn hftrdd ml-4" (click)="selectTab = 'editProposal'">Edit Proposal</button>
            </div>
          </div>
          <hr class="mt-0 mb-4" style="border-bottom: 1px solid white;">
          <div class="row">
            <div class="col-9 breakLine">
              <p><b> {{selectedProposalData?.title | titlecase}} </b></p>
              <p class="mb-0"><b>Start Date :&nbsp;&nbsp;&nbsp;</b><span>{{selectedProposalData?.startDate }}</span></p>
              <p><b>End Date :&nbsp;&nbsp;&nbsp;</b><span class="mb-0">{{selectedProposalData?.endDate }} </span></p>
              <p> {{selectedProposalData?.description}} </p>
            </div>
            <div class="col-3" style="border-left: 1px solid white;">
              <p><small>${{selectedProposalData?.budget}}</small></p>
              <p><small *ngFor="let gen of selectedProposalData?.genres">{{gen}}</small></p>
              <p><small>{{selectedProposalData?.proposals}} Proposal, {{selectedProposalData?.messages}}
                  Messaged</small></p>
              <p><small>Status : {{selectedProposalData?.eventStatus | titlecase}} </small></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 breakLine">
              <p class="mb-0"><b>Cover Letter</b></p>
              <p>{{selectedProposalData?.coverLetter}}</p>
            </div>
            <div class="col-12">
              <p class="mb-0"><b>How do you want to be paid?</b></p>
              <p *ngIf="selectedProposalData?.paidOption == 'TOTAL_FEE'">Total Fee</p>
              <p *ngIf="selectedProposalData?.paidOption == 'PER_HOUR'">Hourly Fee</p>
            </div>
            <div class="col-6">
              <p class="mb-0"><b>Estimated Hours</b></p>
              <p>{{selectedProposalData?.estimateHours}} Hours</p>
            </div>
            <div *ngIf="selectedProposalData?.paidOption == 'PER_HOUR'" class="col-6">
              <p class="mb-0"><b>Hourly Fee</b></p>
              <p>${{selectedProposalData?.hourlyFee}}</p>
            </div>
            <div class="col-6">
              <p class="mb-0"><b>Total Fee</b></p>
              <p>${{selectedProposalData?.totalFee}}</p>
            </div>
            <div *ngIf="selectedProposalData?.additionalNotes" class="col-12 breakLine">
              <p class="mb-0"><b>Additional Notes</b></p>
              <p>{{selectedProposalData?.additionalNotes}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 my-5">
              <!-- proposalType != 'clientAccepted'? selectTab = 'proposalsListTab': selectTab = 'mainTab'  -->
              <button type="submit" class="btn hftrdd ml-4" (click)="selectTab = 'mainTab' ">Back</button>
              <button [disabled]="userStatus == 'PENDING_DELETE'"
                *ngIf="selectedProposalData?.proposalStatus != 'PERFORMANCE_STARTED' && selectedProposalData?.proposalStatus != 'PERFORMANCE_ENDED' && selectedProposalData?.proposalStatus != 'PENDING' && selectedProposalData?.proposalStatus != 'CANCELLED' && selectedProposalData?.proposalStatus != 'PROPOSAL_OWNER_ACCEPTED' && selectedProposalData?.proposalStatus != 'DECLINED' && selectedProposalData?.proposalStatus != 'COMPLETED' && selectedProposalData?.eventStatus != 'COMPLETED' && selectedProposalData?.eventStatus != 'CANCELLED'"
                type="submit" class="btn hftrdd ml-1" (click)="declineProposalModal=true">Decline</button>
              <button [disabled]="userStatus == 'PENDING_DELETE'"
                *ngIf="selectedProposalData?.proposalStatus != 'PERFORMANCE_STARTED' && selectedProposalData?.proposalStatus != 'PERFORMANCE_ENDED' && selectedProposalData?.proposalStatus != 'PENDING' && selectedProposalData?.proposalStatus != 'CANCELLED' && selectedProposalData?.proposalStatus != 'PROPOSAL_OWNER_ACCEPTED' && selectedProposalData?.proposalStatus != 'DECLINED' && selectedProposalData?.proposalStatus != 'COMPLETED' && selectedProposalData?.eventStatus != 'COMPLETED' && selectedProposalData?.eventStatus != 'CANCELLED'"
                type="submit" class="btn defrqqa edcae" (click)="selectTab = 'TermAndCondition'">Accept</button>
              <button [disabled]="userStatus == 'PENDING_DELETE'"
                *ngIf="selectedProposalData?.proposalStatus != 'PERFORMANCE_STARTED' && selectedProposalData?.proposalStatus != 'PERFORMANCE_ENDED' && acceptDeclineStatus!='PROPOSAL_OWNER_ACCEPTED' && selectedProposalData?.proposalStatus != 'CANCELLED' && selectedProposalData?.proposalStatus != 'PROPOSAL_OWNER_ACCEPTED' && selectedProposalData?.proposalStatus != 'DECLINED' && selectedProposalData?.eventStatus != 'COMPLETED' && selectedProposalData?.eventStatus != 'CANCELLED'"
                type="submit" class="btn hftrdd ml-1" (click)="declineProposalModal=true">Decline</button>
              <button [disabled]="userStatus == 'PENDING_DELETE'"
                *ngIf="selectedProposalData?.proposalStatus != 'PERFORMANCE_STARTED' && selectedProposalData?.proposalStatus != 'PERFORMANCE_ENDED' && acceptDeclineStatus!='PROPOSAL_OWNER_ACCEPTED' && selectedProposalData?.proposalStatus != 'CANCELLED' && selectedProposalData?.proposalStatus != 'PROPOSAL_OWNER_ACCEPTED' && selectedProposalData?.proposalStatus != 'DECLINED' && selectedProposalData?.eventStatus != 'COMPLETED' && selectedProposalData?.eventStatus != 'CANCELLED'"
                type="submit" class="btn defrqqa edcae" (click)="selectTab = 'TermAndCondition'">Accept</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Gig Modal -->
<div class="modal fade mt-5 pt-2" data-backdrop="false" data-backdrop="false" data-backdrop="false" id="cancelGig"
  tabindex="-1" role="dialog" aria-labelledby="eventEndedModal2Label" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg" style="margin-top: 0px;">
      <div class="modal-body" style="color: white;">
        <div class="container mb-3">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-center">
              <p class="text-justify"> Are you sure you want to cancel this gig? If you choose to cancel, you will not
                receive any payouts of fees associated with this event.
                If you cancel multiple event on Virtuosica, some features of your Artist Account may be restricted for
                brief periods or permanently. Please
                make sure you understand our cancellation policy before cancellation gig. If the customer has requested
                that this gig be canceled, please ask
                the customer to cancel the gig from their end, to prevent any penalties being applied to your account.
                For help with canceling this gig, please contact Virtuosica Customer Service.
              </p>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button type="submit" class="btn hftrdd mt-5 mr-4" style="width: 200px;" data-dismiss="modal"
                aria-label="Close"> Go Back </button>
              <button [disabled]="userStatus == 'PENDING_DELETE'" class="btn defrqqa mt-5" data-dismiss="modal"
                data-toggle="modal" data-target="#cancelGigModal2" style="width: 200px;"> Cancel Contract </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Gig Modal 2 -->
<div class="modal fade mt-5 pt-2" data-backdrop="false" id="cancelGigModal2" tabindex="-1" role="dialog"
  aria-labelledby="eventEndedModal2Label" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg" style="margin-top: 0px;">
      <div class="modal-body" style="color: white;">
        <div class="container mb-3">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="confirmCancelProposal = false">
                <span aria-hidden="true" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-center">
              <p class="text-justify"> By selecting CONFIRM, You are accepting Virtuosica's cancellation policy and any
                account restritions assotiated with the cancellation of this gig. Once completed, This action can't be
                undone.
              </p>
              <div class="form-check">
                <input class="form-check-input" id="canGig" type="checkbox" [(ngModel)]="confirmCancelProposal"
                  (click)="checkCondition()">
                <label class="form-check-label" for="canGig">
                  I understand and accept Virtuosica's cancellation policy and wish to cancel this event.
                </label>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button type="submit" class="btn hftrdd mt-5 mr-4" style="width: 200px;" data-dismiss="modal"
                aria-label="Close" (click)="confirmCancelProposal = false"> Go Back </button>
              <button [disabled]="!confirmCancelProposal" class="btn defrqqa mt-5" data-dismiss="modal"
                data-toggle="modal" data-target="#cancelGigReasonModal" style="width: 200px;"> Confirm </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Gig Reason Modal -->
<div class="modal fade mt-5 pt-2" data-backdrop="false" id="cancelGigReasonModal" tabindex="-1" role="dialog"
  aria-labelledby="eventEndedModal2Label" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg" style="margin-top: 0px;">
      <div class="modal-body" style="color: white;">
        <div class="container mb-3">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="cancelReason = '';confirmCancelProposal = false ">
                <span aria-hidden="true" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-center">
              <p class="text-justify"> You have canceled this gig. Please provide a reason for canceling this event.
                This is not a requirement, but will be used to consider whether to restrict features on your Artist
                Account and will help us provide you with better service in the future. </p>
              <textarea class="textBox mt-3 pt-2 pl-2" [(ngModel)]="cancelReason" placeholder="Enter your reason here"
                name="" id="" cols="30" rows="4"></textarea>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button type="submit" class="btn hftrdd mt-5 mr-4" style="width: 200px;" data-dismiss="modal"
                aria-label="Close" (click)="selectTab = 'mainTab'; cancelReason = '';confirmCancelProposal = false ">
                Cancel </button>
              <button class="btn defrqqa mt-5"
                [disabled]="cancelReason == null || cancelReason == undefined || cancelReason.length == 0"
                data-dismiss="modal" style="width: 200px;" (click)="cancelGig()"> Submit </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Edit Proposal View -->
<div *ngIf="selectTab == 'editProposal'" class="deaa ml-4" id="rockband">
  <div class="container" style="color: white;">
    <div class="row">
      <div class="col-12">
        <h4>Edit Proposal</h4>
      </div>
    </div>
  </div>
  <hr class="mt-0 mb-4" style="border-bottom: 1px solid white;">
  <div class="" role="document">
    <div class=" modalBg judhr" style="border-radius: 10px !important; margin-top: 0px;">
      <div class="modal-body" style="color: white;">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h5 class="mb-0">Event Details</h5>
            </div>
          </div>
          <hr class="mt-0 mb-4" style="border-bottom: 1px solid white;">
          <div class="row">
            <div class="col-9 breakLine">
              <p><b> {{selectedProposalData?.title | titlecase}} </b></p>
              <p class="mb-0"><b>Start Date :&nbsp;&nbsp;&nbsp;</b><span>{{selectedProposalData?.startDate }}</span></p>
              <p><b>End Date :&nbsp;&nbsp;&nbsp;</b><span class="mb-0">{{selectedProposalData?.endDate }} </span></p>
              <p> {{selectedProposalData?.description}} </p>
            </div>
            <div class="col-3" style="border-left: 1px solid white;">
              <p><small>${{selectedProposalData?.budget}}</small></p>
              <p><small *ngFor="let gen of selectedProposalData?.genres">{{gen}}</small></p>
              <p><small>{{selectedProposalData?.proposals}} Proposal, {{selectedProposalData?.messages}}
                  Messaged</small></p>
              <p><small>Status : {{selectedProposalData?.eventStatus | titlecase}} </small></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 breakLine">
              <p class="mb-0"><b>Cover Letter</b></p>
              <p>{{selectedProposalData?.coverLetter}} </p>
            </div>
            <div class="col-12">
              <p class="mb-0"><b>Estimated Hours</b></p>
              <input type="number" style="padding-left: 15px;" [(ngModel)]="editProposalValue.estimateHours"
                (keyup)="calculateAmount()" class="inputBox">
            </div>

            <div class="row ml-2 mt-4" style="width: 100%;">
              <div class="col-12 fir-tab">
                <p class="ve-nue mb-1">How do you want to be paid </p>
                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item pr-5">
                    <input type=radio id="rdo1" checked class="radio-input" name="radio-group"
                      (click)="paidOptionSelection('per_hour')">
                    <label for="rdo1" class="radio-label pl-5"> <span class="radio-border"></span> Per Hour </label>
                  </li>
                  <li class="nav-item pl-5">
                    <input type=radio id="rdo2" class="radio-input" name="radio-group"
                      [checked]="tabName == 'total_fee'" (click)="paidOptionSelection('total_fee')">
                    <label for="rdo2" class="radio-label pl-5"><span class="radio-border"></span>Total Fee</label>
                  </li>
                </ul>
                <div class="tab-content mt-2">
                  <div *ngIf="tabName == 'per_hour'">
                    <form [formGroup]="proposalDraftForm" class="d-flex">
                      <div class="form-group" style="width: 48%;">
                        <label for="hourlyFee">Hourly Fee</label><br>
                        <p class="ratoj mb-0">$</p>
                        <input type="number" style="padding-left: 25px;" class="inputBox" formControlName="hourlyFee"
                          (keyup)="calculateAmount()">
                      </div>
                      <div class="form-group" style="width: 48%; margin-left: 25px;">
                        <label for="totalFee">Total Fee</label><br>
                        <p class="ratoj mb-0">$</p>
                        <input type="number" class="inputBox" formControlName="totalFee" style="padding-left: 25px;">
                      </div>
                    </form>
                  </div>
                  <div *ngIf="tabName == 'total_fee'">
                    <form [formGroup]="proposalDraftForm">
                      <div class="form-group">
                        <label for="totalFee">Total Fee</label><br>
                        <p class="ratoj">$</p>
                        <input class="inputBox" style="padding-left: 25px;" formControlName="totalFee"
                          (keyup)="calculateAmount()" type="number">
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedProposalData.additionalNotes" class="col-12 mt-4 breakLine">
              <p class="mb-0"><b>Additional Notes</b></p>
              <p>{{selectedProposalData.additionalNotes}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 my-5">
              <button type="submit" class="btn hftrdd ml-4" (click)="editContract('cancel')">Cancel</button>
              <button [disabled]="userStatus == 'PENDING_DELETE'" type="submit" class="btn defrqqa edcae"
                (click)="editContract('save')">save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Terms And Condition -->
<div *ngIf="selectTab == 'TermAndCondition'" class="deaa ml-5" id="rockband">
  <div class="container" style="color: white;">
    <div class="row">
      <div class="col-12 text-center">
        <h4>CONTRACT TERMS & CONDITIONS</h4>
      </div>
    </div>
  </div>
  <hr class="m-0" style="border-bottom: 1px solid white;">
  <div class=" modalBg mb-4 mt-0" role="document">
    <div class=" modalBg" style="border-radius: 10px !important; margin-top: 0px;">
      <div class="modal-body" style="color: white;">
        <div id="termsCondition" (scroll)="enableClick()" class="container mt-3"
          style="overflow: scroll; height: 420px;">
          <div class="row">
            <div class="col-12 text-center">
              <h5 class="mb-0">VIRTUOSICA STANDARD PERFORMANCE CONTRACT TERMS</h5>
            </div>
            <div class="col-12 text-justify pt-2">
              <p>Users who, through the Platform (as defined in the Virtuosica Terms of Service), enter into a
                Performance Contract with another User are free to agree to these Standard Performance Contract Terms
                (“Standard Terms”), in whole or in part, or to agree to different or additional terms for their
                Performance Contract(s). However, if and to the extent that the Users who are party to a Performance
                Contract have not agreed to different terms, then they agree to incorporate these Standard Terms. Users
                may not, by agreement amongst themselves, alter the rights or responsibilities of Virtuosica or agree to
                any terms that would violate the Virtuosica Terms of Service. Neither Virtuosica, nor any affiliate of
                Virtuosica, is a party to any Performance Contract by or between Users, regardless of whether these
                Standard Terms are incorporated.</p>
              <p class="mb-0">Users acknowledge and agree that Users have discretion regarding whether to contract with
                one another and will negotiate and determine the specific terms of their Performance Contracts with one
                another. The Standard Terms serve only as a sample that may not be appropriate for all Performance
                Contracts and may be adjusted and supplemented as Users deem appropriate, provided that the final terms
                do not alter the rights or responsibilities of Virtuosica or violate the Virtuosica Terms of Service or
                other Virtuosica policies. </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <ol type="1">
                <li>
                  <h5> Parties </h5>
                  <p class="text-justify">The Client and Artist identified on the Platform under the Event or
                    Performance Contract are the parties to the Performance Contract. Virtuosica is not a party to the
                    Performance Contract.</p>
                </li>
                <li>
                  <h5> Events and Performance Services </h5>
                  <ol type="a" class=" text-justify">
                    <li>
                      <p> Client and Artist agree that the Artist will provide Performance Services as an independent
                        contractor and that Artist is not an employee or agent of Client. Artist will render the
                        Performance Services in first-class, professional manner and will be subject to the instructions
                        and direction of Client and its designated representatives. The manner and means of rendering
                        the Performance Services will be determined and controlled solely by Artist, who is engaged by
                        Client as an independent contractor. </p>
                    </li>
                    <li>
                      <p> The terms concerning the services to be performed under the Performance Contract, described on
                        the Platform, are incorporated into the Performance Contract. Users agree that, once accepted,
                        the terms of the Performance Contract cannot be modified by one User without obtaining the
                        consent of the other before making changes to the Performance Contract. If consent of the other
                        User is not first obtained, the other User may reject such changes by terminating the
                        Performance Contract or accept such changes by continuing to render services pursuant to the
                        Performance Contract.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h5> Compensation </h5>
                  <ol type="a" class=" text-justify">
                    <li>
                      <p> Provided that Artist has rendered the Performance Services in accordance with the terms of the
                        Performance Contract and is not in breach thereof, Client agrees to compensate Artist by payment
                        of a fee in such amounts as agreed upon by the parties. Client payments and Artist payouts will
                        be processed and distributed via Virtuosica’s third-party payment processor in accordance with
                        the Virtuosica Terms of Service and any other applicable policies. </p>
                    </li>
                    <li>
                      <p>Artist is responsible for all other expenses incurred in connection with Artist’s performance
                        of the Performance Services, unless otherwise specified by the parties to the Performance
                        Contract. In no event will Client be obligated to compensate Artist, in any form or manner
                        whatsoever, other than as set forth in the Performance Contract or any documents or policies
                        incorporated by reference therein.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h5> Termination </h5>
                  <ol type="a" class=" text-justify">
                    <li>
                      <p>The Performance Contract will be in full force and effect on the date when the Artist is booked
                        for the Event and will continue thereafter until the completion of the Performance Services,
                        unless sooner terminated as provided herein.</p>
                    </li>
                    <li>
                      <p>Artist or Client may cancel the Event, and thereby terminate the Performance Contract, in
                        accordance with Virtuosica’s Refund Policy and Terms of Service. </p>
                    </li>
                    <li>
                      <p>Notwithstanding any other term set forth herein, in the event that the performance of any
                        obligation under the Performance Contract is prevented due to acts of God, wars, hostilities,
                        blockades, civil disturbances, revolutions, strikes, terrorist attacks, exchange controls,
                        export or import controls, or any other government restriction, lockouts, or any other cause
                        beyond the reasonable control of a party, including the cancellation of the Event for any reason
                        related to “Do Not Travel” restrictions from the U.S. Department of State, transportation
                        disruption, pandemics, or outbreak of disease (including, but not limited to, COVID-19), such
                        party will promptly notify the other party of such force majeure conditions. The terms of this
                        section will not absolve, but merely suspend, any party from its duty to perform the obligations
                        under this Agreement until as soon as practical after any force majeure condition ceases to
                        exist. If the Event is not held as planned due to a force majeure event or condition, then
                        Artist and Client may use good faith efforts to reschedule the Event and Performance Services on
                        a mutually-agreeable date.</p>
                    </li>
                    <li>
                      <p>If Artist is unable to perform or deliver the Performance Services pursuant to the Performance
                        Contract—in whole or in part—for any reason other than a force majeure event as discussed
                        herein, Client will have sole discretion in deciding whether to attempt to reschedule the Event
                        or Performance Services at a date and time agreeable to both parties or to cancel the Event or
                        Performance Services. In such a case, the parties’ liability and responsibility for costs is
                        allocated in according Virtuosica’s Cancellation Policy.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h5> Artist Obligations and Responsibilities </h5>
                  <ol type="a" class=" text-justify">
                    <li>
                      <p>Artist will be responsible, at Artist’s expense, for providing all equipment, accessories, or
                        properties necessary for the Event and Performance Services—including, without limitation,
                        strong wired or wireless internet connection, device with a high-quality camera, and
                        microphone(s) with good or excellent audio quality—along with any materials that Artist intends
                        to display in conjunction with the Performance Services. Artist will use their own production
                        facilities for streaming or broadcasting in connection with the Performance Services, subject to
                        guidelines provided by Client. Unless otherwise agreed upon by the parties, Artist will be
                        responsible, at Artist’s expense, for hair, makeup, wardrobe, and any other styling or aesthetic
                        logistics in connection with the Performance Services.</p>
                    </li>
                    <li>
                      <p>Artist must conduct themselves, at all times in the performance of the Performance Services, in
                        accordance with Virtuosica’s Terms of Service. Artist agrees to maintain a professional demeanor
                        during the Event and will refrain from displaying or presenting improper, unprofessional, or
                        offensive materials, language, or conduct. Artist may not render the Performance Services while
                        intoxicated or under the influence of alcoholic beverages or other controlled substances. If,
                        during the Event, Artist is noticeably under the influence of alcohol, narcotics, or other
                        substances, Client may terminate the Performance Contract, at its sole discretion and with no
                        liability whatsoever. </p>
                    </li>
                    <li>
                      <p>Artist must be appropriately dressed during the Event. Artist may not wear any clothing that
                        displays the names or logos of brands or commercial products or any clothing that displays
                        offensive or obscene language, graphics, or other material.</p>
                    </li>
                    <li>
                      <p>Artist agrees to (i) provide the Performance Services with all due care, skill, and ability,
                        (ii) make all reasonable endeavors to promote the interests of both parties in their provision
                        of the Performance Services, and (iii) unless prevented by ill health or accident, devote such
                        time and attention as is required to effectively carry out the Performance Services.</p>
                    </li>
                    <li>
                      <p>Artist may not violate, infringe upon, or misappropriate any right of a third party, promote
                        illegal activities, or publish, perform, or display (i) obscenities or racial or ethnic slurs,
                        (ii) content that is discriminatory, hateful, threatening, or pornographic, or (iii) content
                        that contains nudity or graphic or gratuitous violence.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h5> Client’s Responsibilities and Obligations </h5>
                  <ol type="a" class=" text-justify">
                    <li>
                      <p>Client is solely responsible for providing, maintaining, and ensuring compatibility with the
                        Platform and for supplying all hardware, software, electrical, and other physical requirements
                        for Client’s access to the Platform and viewing of the Event or Performance Services, including,
                        but not limited to, a devices, internet connectivity, audio or video equipment, and other
                        necessary hardware, as well as any programs or services required to access and use the Platform
                        or to access or view the Event or Performance Services. </p>
                    </li>
                    <li>
                      <p>Client is responsible for providing Artist with accurate and thorough details relating to the
                        Event or Performance Services, as well as clearly stating any specifications or requirements for
                        the Event or Performance Services.</p>
                    </li>
                    <li>
                      <p>Client may not make any recordings—audio-only, video-only, or audiovisual—of the Event or
                        Performance Services. Client is also solely responsible for ensuring that any Viewers (as
                        defined in Virtuosica’s Terms of Service) do not record the Event or Performance Services, or
                        otherwise violate Virtuosica’s published policies or applicable law.</p>
                    </li>
                    <li>
                      <p>For Events or Performance Services that are not transmitted or rendered directly through the
                        Platform, Client is responsible for securing any applicable or necessary licenses, permits,
                        insurance coverage, and other permissions. If such an Event or Performance Services involve
                        public performance of a musical composition for which a license is required, Client is
                        responsible for licensing, reporting, and payment of royalties to the applicable rights-holder
                        or performing rights organization.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h5> Proprietary Rights </h5>
                  <ol type="a" class=" text-justify">
                    <li>
                      <p>Artist hereby grants, to Client, the irrevocable worldwide right, in perpetuity, to use—and
                        authorize or permit others to use—Artist’s name(s), voice, image, photograph, personal
                        characteristics, signature, actual or simulated likeness, expressions, performance, attributes,
                        personal experiences and biographical information concerning Artist (collectively, “Artist’s
                        Likeness”), in any format or medium, in connection with the Event or the Performance Services
                        and any promotions and advertisements, marketing, or other publications in connection with the
                        Event, the Performance Services, and Artist’s involvement with the Event. To the extent
                        permitted by the functionality of the Platform and applicable licenses, Artist authorizes Client
                        to broadcast, film or tape, portray, and photograph Artist and record Artist’s Likeness in
                        connection with the production of Event or provision of the Performance Services, including any
                        “behind-the-scenes” or “b-roll.” Artist also consents to use of Artist’s Likeness—in whole or in
                        part—in any transcripts or other printed matter in connection therewith. Any photograph,
                        likenesses, or biographical material submitted or furnished by Artist to Client will be deemed
                        approved for use by Artist.</p>
                    </li>
                    <li>
                      <p>Artist grants, to Client, the right to publicize, advertise, and promote Artist’s association
                        with the Event. Notwithstanding the foregoing, Client may not make any representations of
                        endorsement by Artist of any product, service, or cause without Artist’s express written
                        consent.</p>
                    </li>
                    <li>
                      <p>Client reserves all right, title, and interest in its products, trademarks, promotional
                        materials, and any materials furnished to Artist by Client pursuant to the Performance Contract,
                        and all intellectual property rights in each of the foregoing. No title or ownership of any of
                        the foregoing is transferred to Artist or any other person under the Performance Contract.
                        Except to the extent incorporated into any promotional or other materials furnished to
                        Artist—and then only to the extent such materials are used for purposes of carrying out Artist’s
                        obligations under the terms of the Performance Contract—Artist will not use the name of the
                        Event, Client’s name, or any other designation owned by Client without Client’s prior consent.
                        Artist will not make any representation to any third-party regarding the Event, Client, or any
                        other products or services that may be offered by Client that is inconsistent with, or in
                        addition to, those made in any promotional or other materials provided to Artist by Client.
                        Client will have sole and absolute control, approval, and discretion regarding the design,
                        colors, presentation, depiction, and exhibition of Client’s trademarks or other intellectual
                        properties that are created or relate in any way to the Event or the Performance Services.
                        Client retains all rights to its trade names and trademarks, or any other commercial symbols
                        that it may adopt or use, that are created for or related to the Event or the Performance
                        Services.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h5> Confidentiality </h5>
                  <ol type="a" class=" text-justify">
                    <li>
                      <p>The parties to the Performance Contract (each, a ''Recipient'') will protect and keep
                        confidential all non-public information (''Confidential Information'') disclosed by the other
                        party (each, a ''Discloser''), whether or not it is marked or identified as ''Confidential
                        Information'' by the Discloser, and will not, except for the purposes of performing the Services
                        to be provided hereunder, use or disclose any such Confidential Information. Confidential
                        Information will include, without limitation, data, know-how, contacts or sources, contracts,
                        software, formulae, processes, designs, sketches, photographs, plans, drawings, specifications,
                        samples, reports, information obtained from the parties’ previous or current clients or
                        affiliates, other intellectual property or trade secrets owned by the parties, information
                        relating to parties’ business operations, clients, assets, or transactional procedures, and any
                        other information that would reasonably be understood by the parties as being proprietary or
                        confidential.</p>
                    </li>
                    <li>
                      <p>Upon termination or expiration of the Performance Contract, at Discloser's request, Recipient
                        will return to Discloser, or destroy, all written materials that contain any Confidential
                        Information. The obligations of confidentiality will not apply to any information which: (i) was
                        previously known to Recipient; (ii) is or becomes publicly available, through no fault of
                        Recipient; (iii) is disclosed to Recipient by a third party having no obligation of
                        confidentiality to Discloser; (iv) is independently developed by Recipient; or (v) is required
                        to be disclosed as a matter of law.</p>
                    </li>
                    <li>
                      <p>It is further understood that Confidential Information disclosed under the Performance Contract
                        will not be deemed to be within the foregoing exceptions to the definition of Confidential
                        Information merely because such information is embraced by more general information in the
                        public domain or in Recipient’s possession at the execution of the Performance Contract. The
                        provisions of this clause will survive expiration or termination of the Performance Contract by
                        any party for any reason.</p>
                    </li>
                    <li>
                      <p>The parties acknowledge and agree that the terms of the Performance Contract are confidential
                        and that neither party may disclose any part thereof to any third party, other than the parties’
                        respective professional advisors or as may be required by law.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h5> Representations and Warranties </h5>
                  <ol type="a" class=" text-justify">
                    <li>
                      <p>Artist represents and warrants as follows:</p>
                      <ol type="i">
                        <li>
                          <p>With the exception of any musical compositions licensed by Virtuosica on behalf of Users or
                            any musical compositions that are within the public domain, Artist is the sole owner of any
                            and all materials performed, presented, or displayed during the Event and in the course of
                            the Performance Services, and all the rights herein granted, and has full authority to enter
                            into this Performance Contract and grant rights to Client as set forth herein. </p>
                        </li>
                        <li>
                          <p>Artist has not previously granted rights to any recording or aspect of the Event to another
                            party, Artist has obtained the necessary releases of subjects and locations identified or
                            shown in the Event or any recording thereof, that transmission of the Event will not violate
                            any copyright, proprietary, or personal rights of any third party, and that Artist has not
                            in any manner disposed of any of the rights herein granted to Client or granted any rights
                            adverse to or inconsistent therewith, nor are there any rights outstanding which would
                            diminish, encumber, or impair Client’s full enjoyment or exercise of the rights herein
                            granted to Client.</p>
                        </li>
                        <li>
                          <p>Artist has used reasonable care to ensure that all facts and statements made during the
                            Event are true and accurate, and Artist will not engage in any obscene, offensive, or
                            unlawful behavior or speech, as determined by Client in its sole discretion.</p>
                        </li>
                        <li>
                          <p>Artist is knowingly and voluntarily taking part in activities during which Artist’s name,
                            image, voice, or other likeness may be captured, and Artist understands that, through
                            Client’s use of any materials that include Artist’s name or likeness—as permitted by Client
                            per the terms this Agreement—third parties (i.e., social media platforms) may obtain
                            additional, shared, or joint rights to such materials, according to the required terms and
                            conditions set out by those third parties for use of their platforms, applications, or
                            websites.</p>
                        </li>
                        <li>
                          <p>Artist understands Client’s commitment to treating all people with dignity and respect
                            regardless of the person’s race, sex, ethnicity, nationality, sexual preference, religion,
                            age, or disability, and Artist will not promote the degradation of anyone based on these
                            characteristics in the course of the Performance Services. </p>
                        </li>
                        <li>
                          <p>Artist will not engage in any obscene or offensive behavior or speech, as determined by
                            Client in its sole discretion.</p>
                        </li>
                        <li>
                          <p>Artist is free to perform its obligations under the terms of the Performance Contract and
                            will not be subject to any conflicting obligations or disability that would prevent or
                            interfere with participation in the Event and performance of the Performance Services.</p>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <p>Client represents and warrants as follows:</p>
                      <ol type="i">
                        <li>
                          <p>Client has the authority to enter into the Performance Contract. </p>
                        </li>
                        <li>
                          <p>The Event or Performance Services will be transmitted to a location that is within the
                            United States of America or its territories. </p>
                        </li>
                        <li>
                          <p>For Events or Performance Services that are not transmitted or rendered directly through
                            the Platform, Client has secured all applicable or licenses, permits, insurance coverage,
                            and other permissions that may be required for the Event or Performance Services.</p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <h5> General Provisions </h5>
                  <ol type="a" class=" text-justify">
                    <li>
                      <p>Nothing in the Performance Contract is intended to or will create any form of partnership,
                        joint venture, or any form of co-ownership between the parties. Artist’s relationship with
                        Client will be that of an independent contractor and not an employee. Neither party will have
                        the authority to bind the other to any contracts or commitments without the other party’s
                        written consent.</p>
                    </li>
                    <li>
                      <p>The Performance Contract will be governed and construed in accordance with the laws of the
                        State of California, without regard to its choice of law principles. </p>
                    </li>
                    <li>
                      <p>The recitals to the Performance Contract and communications exchanged between the Artist and
                        the Client, as well as Virtuosica’s Terms of Service, Privacy Policy, DMCA and Infringing
                        Content Policy, Music Guidelines, and other published policies are integral parts of the
                        Performance Contract and are incorporated herein by reference. The Performance Contract, along
                        with all documents and policies referenced herein, constitute the entire agreement between the
                        parties concerning the subject matter thereof and supersede all written or oral prior agreements
                        or understandings with respect thereto. To the extent that there is any inconsistency between
                        the Virtuosica Terms of Service and any other term of the Performance Contract, the Virtuosica
                        Terms of Service will govern.</p>
                    </li>
                    <li>
                      <p>The headings in the Performance Contract are for convenience only and have no legal or
                        contractual effect. To the extent allowed by law, the English version of the Performance
                        Contract is binding, and any translations are provided for convenience only. All pronouns and
                        any variations thereof will be deemed to refer to the masculine, feminine, neuter, singular, or
                        plural as the identity of the subject entities or persons. Any capitalized term not specifically
                        defined herein will be deemed to retain the definition set forth in the Virtuosica Terms of
                        Service.As used herein, “including” and similar expressions are not words of limitation. </p>
                    </li>
                    <li>
                      <p>The Performance Contract will be considered to have been written jointly by the parties such
                        that the rule of construing ambiguities against the drafter will have no force or effect. Each
                        party waives the right to plead such as a defense to enforcement of the Performance Contract.
                        Each party hereby acknowledges that it has had the opportunity to seek the advice of legal
                        counsel before executing the Performance Contract. Unless otherwise specified herein, each party
                        will bear its own costs in connection with the Performance Contract and performance of such
                        party’s obligations under the terms thereof.</p>
                    </li>
                    <li>
                      <p>The Performance Contract may be executed in counterparts, each of which will be deemed an
                        original and together will constitute one and the same document. The Performance Contract may
                        not be amended except by a writing signed by an authorized representative of both parties. The
                        parties agree to execute all documents, and take any other actions, reasonably required to
                        effectuate the purposes of the Performance Contract.</p>
                    </li>
                    <li>
                      <p>Each party agrees that all of its obligations undertaken herein will survive and continue after
                        any termination or conclusion of the Performance Contract, including obligations with respect to
                        the confidentiality and proprietary rights. Should any part of the Performance Contract be held
                        unenforceable or invalid, all other parts will remain valid and enforceable.</p>
                    </li>
                    <li>
                      <p>Neither party’s failure to enforce strict performance of any provision of the Performance
                        Contract will constitute a waiver of a right to subsequently enforce such a provision. No
                        modification, extension, or waiver of the Performance Contract will be valid unless made in
                        writing and signed by an authorized representative of the party to be charged. No written waiver
                        will constitute, or be construed as, a waiver of any other obligation or condition of the
                        Performance Contract.</p>
                    </li>
                    <li>
                      <p>The rights, duties, and privileges of a party to the Performance Contract may not be
                        transferred or assigned, in whole or in part, without the prior written consent of the other
                        party. The parties agree that, in the event that such consent is requested, it will not be
                        unreasonably withheld.</p>
                    </li>
                    <li>
                      <p>All notices made under the Performance Contract must be in writing and delivered electronically
                        through the Virtuosica platform. Notice is effective upon receipt.</p>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="conrainer">
          <div class="row">
            <div class="col-12 mt-3 mb-3">
              <button type="submit" class="btn hftrdd ml-4" (click)="selectTab = 'individualProposalView'">Back</button>
              <button [disabled]="buttonDisable" type="submit" class="btn hftrdd ml-1"
                (click)="declineProposalModal=true">Decline</button>
              <button [disabled]="buttonDisable" type="submit" class="btn defrqqa edcae" data-toggle="modal"
                data-target=".comHirModal" (click)="completeHiring('ACCEPTED')">Accept</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Done Hiring For Event -->
<div *ngIf="completeHiringModal">
  <div class="modal fade comHirModal mt-5 pt-5" data-backdrop="false"
    [ngClass]="completeHiringModal?'d-block':'d-none'">
    <div class="modal-dialog modal-lg px-5 py-5">
      <div class="modal-content modalBg">
        <div class="container">
          <div class="row text-center px-5 pt-5" style="color: white;">
            <div class="col-12">
              <h3><b>Congrats!</b></h3>
            </div>
            <div class="col-10 offset-1">
              <p>Your contract has been sent to the Artist for singing and you'll be notified when it's executed.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5 text-center sendProModalbtn">
              <button [disabled]="userStatus == 'PENDING_DELETE'" type="submit" style="width: 230px;"
                class="btn hftrdd ml-4" data-dismiss="modal" aria-label="Close"
                (click)="doneHiring();getEventList(0,5,'upcoming')">Done Hiring For The Event</button>
              <button [disabled]="userStatus == 'PENDING_DELETE'" type="submit" style="width: 230px;"
                class="btn defrqqa edcae" data-dismiss="modal" aria-label="Close"
                (click)="selectTab = 'mainTab'; completeHiringModal = false;getEventList(0,5,'upcoming')">Need More
                Artist</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Decline Proposal Modal -->
<div *ngIf="declineProposalModal && currentUserId == selectedEventCreatedBy" class="declineModal">
  <div class="px-5">
    <div>
      <div class="container">
        <div class="row text-left pl-3 pt-5" style="color: white;">
          <div class="col-12">
            <h5>Please provide the reason for declining the proposal.</h5>
          </div>
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" checked id="reason1"
                (click)="declineReason('The Fee is out of my budget')">
              <label class="form-check-label" for="reason1"> The Fee is out of my budget. </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="reason2"
                (click)="declineReason('This is not what I requested')">
              <label class="form-check-label" for="reason2">
                This is not what I requested
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="reason3"
                (click)="declineReason('The Proposal is inappropriate or offensive')">
              <label class="form-check-label" for="reason3">
                The Proposal is inappropriate or offensive
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="reason4"
                (click)="declineReason('The Artist is trying to get me to hire them off the Virtuosica Platform')">
              <label class="form-check-label" for="reason4">
                The Artist is trying to get me to hire them off the Virtuosica Platform
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="reason5"
                (click)="declineReason('Other')">
              <label class="form-check-label" for="reason5">
                Other
              </label>
            </div>
            <div *ngIf="otherReason" class="form-check mt-3">
              <input type="text" class="reasonInput" [(ngModel)]="otherReasonText">
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-12 mb-5 text-center sendProModalbtn">
            <button type="submit" class="btn hftrdd ml-4" data-dismiss="modal" aria-label="Close"
              (click)="declineProposalModal = false">Cancel</button>
            <button type="submit" class="btn defrqqa edcae" data-dismiss="modal" aria-label="Close"
              (click)="completeHiring('DECLINED')">Decline</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Pending Proposal Owner Side View -->
<div *ngIf="selectTab == 'pendingProposalOwnerSideView'" class="deaa ml-4" id="rockband">
  <div class=" modalBg my-1" role="document">
    <div class=" modalBg" style="border-radius: 10px !important; margin-top: 0px;">
      <div class="modal-body" style="color: white;">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center" style="color: #FF6720">
              <h4>This Proposal is pending at the Proposal Owner</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <h5 class="mb-0">Event Details</h5>
            </div>
            <div class="col-3">
              <button [disabled]="userStatus == 'PENDING_DELETE'" type="submit" class="btn hftrdd ml-4"
                (click)="selectTab = 'editProposal'">Edit Proposal</button>
            </div>
          </div>
          <hr class="mt-0 mb-4" style="border-bottom: 1px solid white;">
          <div class="row">
            <div class="col-9 breakLine">
              <p><b> {{selectedProposalData?.title | titlecase}} </b></p>
              <p class="mb-0"><b>Start Date :&nbsp;&nbsp;&nbsp;</b><span>{{selectedProposalData?.startDate }}</span></p>
              <p><b>End Date :&nbsp;&nbsp;&nbsp;</b><span class="mb-0">{{selectedProposalData?.endDate }} </span></p>
              <p> {{selectedProposalData?.description}} </p>
            </div>
            <div class="col-3" style="border-left: 1px solid white;">
              <p><small>${{selectedProposalData?.budget}}</small></p>
              <p><small *ngFor="let gen of selectedProposalData?.genres">{{gen}}</small></p>
              <p><small>{{selectedProposalData?.proposals}} Proposal, {{selectedProposalData?.messages}}
                  Messaged</small></p>
              <p><small>Status : {{selectedProposalData?.eventStatus | titlecase}} </small></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 breakLine">
              <p class="mb-0"><b>Cover Letter</b></p>
              <p>{{selectedProposalData?.coverLetter}} </p>
            </div>
            <div class="col-12">
              <p class="mb-0"><b>How do you want to be paid?</b></p>
              <p>{{selectedProposalData?.paidOption | titlecase}}</p>
            </div>
            <div class="col-6">
              <p class="mb-0"><b>Estimated Hours</b></p>
              <p>{{selectedProposalData?.estimateHours}} Hours</p>
            </div>
            <div class="col-6">
              <p class="mb-0"><b>Hourly Fee</b></p>
              <p>${{selectedProposalData?.hourlyFee}}</p>
            </div>
            <div *ngIf="selectedProposalData?.additionalNotess" class="col-6 breakLine">
              <p class="mb-0"><b>Additional Notes</b></p>
              <p>{{selectedProposalData?.additionalNotes}}</p>
            </div>
            <div class="col-6">
              <p class="mb-0"><b>Total Fee</b></p>
              <p>${{selectedProposalData.totalFee}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 my-3">
              <button type="submit" class="btn hftrdd ml-4" (click)="selectTab = 'proposalsListTab'">Back</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Proceed To Payment View -->
<div *ngIf="selectTab == 'proceedToPayment'" class="deaa ml-5" id="rockband">
  <div class="container" style="color: white;">
    <div class="row">
      <div class="col-12">
        <h4>Review Proposal</h4>
      </div>
    </div>
  </div>
  <hr class="mt-0 mb-4" style="border-bottom: 1px solid white;">
  <div class=" modalBg my-4" role="document">
    <div class="judhr" style="border-radius: 10px !important;">
      <div class="modal-body" style="color: white;">
        <div class="container">
          <div class="row">
            <div class="col-9">
              <h5 class="mb-0">Event Details</h5>
            </div>
            <div class="col-3">
              <button *ngIf="selectedProposalData.paymentStatus == 'DONE'" type="submit" class="btn defrqqa edcae"
                style="width: 205px;" (click)="downloadContract()">Download Contract</button>
            </div>
          </div>
          <hr class="mt-0 mb-4" style="border-bottom: 1px solid white;">
          <div class="row">
            <div class="col-9 breakLine">
              <p><b> {{selectedProposalData?.title | titlecase}} </b></p>
              <p class="mb-0"><b>Start Date :&nbsp;&nbsp;&nbsp;</b><span>{{selectedProposalData?.startDate }}</span></p>
              <p><b>End Date :&nbsp;&nbsp;&nbsp;</b><span class="mb-0">{{selectedProposalData?.endDate }} </span></p>
              <p> {{selectedProposalData?.description}} </p>
            </div>
            <div class="col-3" style="border-left: 1px solid white;">
              <p><small>${{selectedProposalData?.budget}}</small></p>
              <p><small *ngFor="let gen of selectedProposalData?.genres">{{gen}}</small></p>
              <p><small>{{selectedProposalData?.proposals}} Proposal, {{selectedProposalData?.messages}}
                  Messaged</small></p>
              <p><small>Status : {{selectedProposalData?.eventStatus | titlecase}} </small></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 breakLine">
              <p class="mb-0"><b>Cover Letter</b></p>
              <p>{{selectedProposalData?.coverLetter}} </p>
            </div>
            <div class="col-12">
              <p class="mb-0"><b>How do you want to be paid?</b></p>
              <p *ngIf="selectedProposalData?.paidOption == 'TOTAL_FEE'">Total Fee</p>
              <p *ngIf="selectedProposalData?.paidOption == 'PER_HOUR'">Hourly Fee</p>
            </div>
            <div class="col-6">
              <p class="mb-0"><b>Estimated Hours</b></p>
              <p>{{selectedProposalData?.estimateHours}} Hours</p>
            </div>
            <div *ngIf="selectedProposalData?.paidOption == 'PER_HOUR'" class="col-6">
              <p class="mb-0"><b>Hourly Fee</b></p>
              <p>${{selectedProposalData?.hourlyFee}}</p>
            </div>
            <div class="col-6">
              <p class="mb-0"><b>Total Fee</b></p>
              <p>${{selectedProposalData?.totalFee}}</p>
            </div>
            <div *ngIf="selectedProposalData.additionalNotes" class="col-12 breakLine">
              <p class="mb-0"><b>Additional Notes</b></p>
              <p>{{selectedProposalData?.additionalNotes}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 my-5">
              <button type="submit" class="btn hftrdd ml-4" style="width: 205px;"
                (click)="selectTab = 'proposalsListTab'">Back</button>
              <button *ngIf="selectedProposalData?.paymentStatus != 'DONE'" type="submit" class="btn defrqqa edcae"
                style="width: 205px;" (click)="checkoutStripe(selectedProposalData?.totalFee)">
                <ng-container *ngIf="processingPayment; else paymentProcess">
                  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Processing...
                </ng-container>
                <ng-template #paymentProcess> Proceed To Payment </ng-template>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Payment Success -->
<div *ngIf="paymentStatus">
  <div class="modal fade comHirModal" data-backdrop="false" [ngClass]="paymentStatus?'d-block':'d-none'">
    <div class="modal-dialog modal-md px-5 py-2">
      <div class="modal-content modalBg">
        <div class="modal-header">
          <button type="button" class="close" (click)="paymentStatus = false" style="color: white;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="container">
          <div class="row text-center px-5 pt-5" style="color: white;">
            <div class="col-12">
              <img src="../../../../../../../assets/Vector (3).png" alt="">
              <h3><b>Congratulations</b></h3>
            </div>
            <div class="col-10 offset-1">
              <p>“You're all set! Enjoy your event!”</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5 text-center sendProModalbtn">
              <button type="submit" style="width: 230px;" class="btn defrqqa edcae" data-dismiss="modal"
                aria-label="Close" (click)="completeHiringModal = true; paymentStatus=false">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Payment Failed -->
<div *ngIf="paymentFailed">
  <div class="modal fade comHirModal" data-backdrop="false" [ngClass]="paymentFailed?'d-block':'d-none'">
    <div class="modal-dialog modal-md px-5 py-2">
      <div class="modal-content modalBg">
        <div class="modal-header">
          <button type="button" class="close" (click)="paymentFailed = false;" style="color: white;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="container">
          <div class="row text-center px-5 pt-5" style="color: white;">
            <div class="col-12">
              <p><i class="fa fa-3x fa-times-circle-o text-danger"></i></p>
              <h3><b>Oops!</b></h3>
            </div>
            <div class="col-10 offset-1">
              <p>“Payment Failed”</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5 text-center sendProModalbtn">
              <button type="submit" style="width: 230px;" class="btn defrqqa edcae" data-dismiss="modal"
                aria-label="Close" (click)="selectTab= 'mainTab'; paymentFailed=false">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Decline Proposal Modal -->
<div *ngIf="declineProposalModal && currentUserId != selectedEventCreatedBy" class="declineModal" style="top: 85px;">
  <div class="px-5">
    <div>
      <div class="container">
        <div class="row text-center" style="color: white;">
          <div class="col-12 pt-5">
            <h5>Are you sure ? You want to decline this contract?</h5>
          </div>
          <div class="col-1"></div>
          <div class="col-10 mt-3">
            <input type="text" class="reasonInput" [(ngModel)]="proposalOwnerDeclineReason">
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row">
          <div class="col-12 mb-5 text-center sendProModalbtn">
            <button type="submit" class="btn hftrdd ml-4" data-dismiss="modal" aria-label="Close"
              (click)="declineProposalModal = false">Cancel</button>
            <button [disabled]="userStatus == 'PENDING_DELETE'" type="submit" class="btn defrqqa edcae"
              data-dismiss="modal" aria-label="Close" (click)="completeHiring('DECLINED')">Decline</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Finish Booking Your Event Modal -->
<div *ngIf="finishBookingModal" class="declineModal">
  <div class="px-5">
    <div>
      <div class="container">
        <div class="row text-center" style="color: white;">
          <div class="col-12 pt-5" style="border-bottom: 1px solid white;">
            <h5>Finish Booking Your Event</h5>
          </div>
          <div class="col-12 mt-3">
            <h6>How It Works?</h6>
            <p class="text-justify">Funds have been reserved for this gig. After you have delivered the performance on
              the agreed date, you and client have a five day period to submit any additional notes or complaints. If no
              complaints have been submitted, you will receive you payout for this gig with in 3 to 5 days after the 5
              day holding period, depending on the payment method selected. All Payments and Payouts are processed
              through Stripe. Make sure you have entered your peferred payout method in the billing section.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-5 text-center sendProModalbtn">
            <button type="submit" class="btn defrqqa edcae" data-dismiss="modal" aria-label="Close"
              (click)="setList = true; finishBookingModal = false">Got It</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Set List Modal -->
<div *ngIf="setList" class="declineModal">
  <div class="px-5">
    <div>
      <div class="container">
        <div class="row text-center" style="color: white;">
          <div class="col-12 pt-5" style="border-bottom: 1px solid white;">
            <h5>Set List</h5>
          </div>
          <div class="col-12 mt-3">
            <p class="text-justify">To receive payout for this gig, you will have to provide a set list. Would you like
              to provide the set list now or after delivering the performance?</p>
          </div>
          <div class="col-12 mt-3">
            <input type="text" class="reasonInput" placeholder="Enter your set list here.">
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-5 text-center sendProModalbtn">
            <button type="submit" class="btn hftrdd ml-4" data-dismiss="modal" aria-label="Close"
              (click)="setList = false; selectTab = 'mainTab'">Skip For Now</button>
            <button [disabled]="userStatus == 'PENDING_DELETE'" type="submit" class="btn defrqqa edcae"
              data-dismiss="modal" aria-label="Close">Submit Set List</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Congrats Gig Booked Modal -->
<div *ngIf="gigBooked" class="gigBooked">
  <div class="px-5">
    <div>
      <div class="container">
        <div class="row text-center" style="color: white;">
          <div class="col-12 pt-5" style="border-bottom: 1px solid white;">
            <h5>Congrats !</h5>
          </div>
          <div class="col-12 mt-3">
            <p class="text-justify">This gig is booked. Let us know if there's anything we can do to help make this gig
              rock!</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-5 text-center sendProModalbtn">
            <button type="submit" class="btn defrqqa edcae" style="width: 230px;" data-dismiss="modal"
              aria-label="Close">Customer Service Button</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
