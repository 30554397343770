<main>
  <div class="container-fluid px-4 pt-3" style="margin-left: 20px;">
    <p [routerLink]="['/ArtistDashboard/pendingArtistHome']">
      <i class="fa fa-angle-left" style="color:white;cursor: pointer;"></i>
    </p>
    <div class="hgnb bot-m">
      <div class=" nvhi">
        <span>New Artists</span>
      </div>
    </div>
    <div class="mb-4 ughne">
      <div class="row  ml-1">
        <div class="col-12 col-lg">
          <div class="row mr-0">
            <ng-container *ngIf="newArtist && newArtist.length">
              <div class="row">
                <ng-container *ngFor="let data of newArtist">
                  <div class="col-12 col-md-2 col-lg-2" data-aos="zoom-in" style="padding-top: 33px;">
                    <div class="card text-left">
                      <img class="card-img-top" [src]="data.avartarUrl" alt="">
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
