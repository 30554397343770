<div class="joinpage">
  <div class="joinhead">
    <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg" routerLink=""
      style="cursor: pointer;">
    <div class="nav-item dropdown hgne" style="top: -2px;display: flex; float: right;">
      <a class="nav-link dropdown-toggle" (click)="logOut = !logOut" id="navbarDropdownMenuLink" role="button"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img src="assets/dashboard/Mask Group.png">
      </a>
      <div *ngIf="logOut" class=""
        style="position: absolute;left: -63px;top: 41px;; border: 1px solid white; border-radius: 15px; background: black;"
        aria-labelledby="navbarDropdownMenuLink">
        <a class="dropdown-item" style="cursor: pointer;color: white;background: none;" (click)="logoutUser()"><i
            class="fa fa-sign-out" aria-hidden="true"></i> Log Out</a>
      </div>
    </div>
  </div>
  <div class="container joinbody">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-xl-6">
        <div class="joinleft">
          <app-slider></app-slider>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 col-xl-6">
        <div class="row">
          <div class="col-md-10">
            <div class="wi-4">
              <div class="">
                <app-tabsall [activeTab]="3"></app-tabsall>
              </div>
              <div class="tab-content" id="pills-background-detail">
                <form [formGroup]="backgroundForm">
                  <div class="form-group for-str">
                    <label for="username">My Career In Summary <span class="ikju">*</span></label>
                    <textarea cols="30" rows="3" style="background: none; border-radius: 15px; color: white;"
                     autocomplete="off" formControlName="careerSummary" class="form-control ge-fk"
                    id="my-career" placeholder="Tell us about your music career in a nutshell"></textarea>
                    <!-- <input type="text" autocomplete="off" formControlName="careerSummary" class="form-control ge-fk"
                      id="my-career" placeholder="Tell us about your music career in a nutshell"> -->
                    <div class="container d-flex mt-3">
                      <p class="dcvf"><img _ngcontent-hrg-c98="" src="assets/a1.png">
                        This is displayed as your bio on your profile</p>
                      <p class="dcvf ml-5 pl-5"> {{ 1500-this.backgroundForm.controls['careerSummary'].value.length }}/1500 </p>
                    </div>
                  </div>
                  <div class="form-group for-str">
                    <label for="username">Most Influential Artists</label>
                    <input type="text" autocomplete="off" class="form-control ge-fk"
                      formControlName="influentialArtists" id="my-career" placeholder="Artists who inspire you">
                  </div>
                  <div class="form-group for-str">
                    <label for="username">Albums Credits</label>
                    <input type="text" autocomplete="off" class="form-control ge-fk" id="my-career"
                      formControlName="albumCredits" placeholder="List any album credits">
                  </div>
                  <div class="form-group for-str">
                    <label for="username">My First Musical Memory</label>
                    <input type="text" autocomplete="off" class="form-control ge-fk" id="my-career"
                      formControlName="firstMusicalMemory" placeholder="Tell us about your first musical memory?">
                  </div>
                  <div class="form-group for-str">
                    <label for="username">What I Love About Music</label>
                    <input type="text" autocomplete="off" class="form-control ge-fk" id="my-career"
                      formControlName="loveAboutMusic" placeholder="Tell us what you love about music.">
                  </div>
                  <div class="form-group for-str kdjnb">
                    <button class="form-control yht" [routerLink]="['/essential']">Back</button>
                    <button type="submit" class="form-control nxt" (click)="backgroundDetails()">
                      <ng-container *ngIf="backgroundFormSubmitting; else backgroundFormSubmittingDone">
                        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...
                      </ng-container>
                      <ng-template #backgroundFormSubmittingDone>
                        Next
                      </ng-template>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
