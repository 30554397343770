import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { AuthServiceService } from '../auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  VirtuosicaSecret = 'zL1l33JaQSdhX4BIbA76pYYmS3wpxgdDO1V0PLM+Oyc=';
  constructor(private _AuthService: AuthServiceService) {}

  encrypt(text: any) {
    return CryptoJS.AES.encrypt(String(text), this._AuthService.secretKey);
  }
  decrypt(text: any) {
    const bytes = CryptoJS.AES.decrypt(text, this._AuthService.secretKey);
    const decryptText = bytes.toString(CryptoJS.enc.Utf8);
    return decryptText;
  }

  encryptHmacSHA256(text: any) {
    let hash = CryptoJS.HmacSHA512(text, this.VirtuosicaSecret);
    const hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
    return hashInBase64;
  }
}
