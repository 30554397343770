import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AudienceModule } from './module/audience/audience/audience.module';
import { ToastrModule } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { JoinUsComponent } from './module/join-us/join-us.component';
import { ArtistSignupComponent } from './module/artist-signup/artist-signup.component';
import { AccountVerifyComponent } from './module/artist-signup/account-verify/account-verify.component';
import { AccountVerifyErrorComponent } from './module/artist-signup/account-verify-error/account-verify-error.component';
import { AuthInterceptor } from './service/interceptor';
import { SwiperModule } from 'swiper/angular';
import { MessagingService } from './service/messaging.service';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { GoogleMapsModule } from '@angular/google-maps';
import { WebcamModule } from 'ngx-webcam';
import { DropDownButtonAllModule } from '@syncfusion/ej2-angular-splitbuttons';
import { DropDownListAllModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { ToolbarAllModule, ContextMenuAllModule } from '@syncfusion/ej2-angular-navigations';
import { ButtonAllModule, CheckBoxAllModule, SwitchAllModule } from '@syncfusion/ej2-angular-buttons';
import { NumericTextBoxAllModule, TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { ScheduleAllModule, RecurrenceEditorAllModule } from '@syncfusion/ej2-angular-schedule';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { CommonModule } from '@angular/common';
import { LoginModule } from './module/login/login.module';
import { SharedModule } from './shared/shared.module';
import { ChangeEmailVerifyComponent } from './module/artist-signup/change-email-verify/change-email-verify.component';

@NgModule({
  declarations: [
    AppComponent,
    JoinUsComponent,
    ArtistSignupComponent,
    AccountVerifyComponent,
    AccountVerifyErrorComponent,
    ChangeEmailVerifyComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AudienceModule,
    MatIconModule, MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SwiperModule,
    WebcamModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    GoogleMapsModule,
    ToastrModule.forRoot({
      timeOut: 6000,
      preventDuplicates: true,
      enableHtml: true,
      closeButton: true,
      progressBar: true
    }),
    ScheduleAllModule,
    RecurrenceEditorAllModule,
    NumericTextBoxAllModule,
    TextBoxAllModule,
    CheckBoxAllModule,
    ToolbarAllModule,
    DropDownListAllModule,
    ContextMenuAllModule,
    MultiSelectAllModule,
    ButtonAllModule,
    DropDownButtonAllModule,
    SwitchAllModule,
    CalendarModule,
    SharedModule,
    LoginModule
    ],

  providers: [
    MessagingService,
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor,multi: true
    },
   ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
