import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { MainService } from 'src/app/service/main.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-public-profile',
  templateUrl: './user-public-profile.component.html',
  styleUrls: ['./user-public-profile.component.scss']
})
export class UserPublicProfileComponent implements OnInit {
  searchBox: boolean = false;
  searchkey: any
  tabName = 'about'
  max = 5;
  rate = 0;
  isReadonly = false;
  userProfileData: any;
  pageSize: number = 10;
  pageNo: number = 0;
  data: any = this.route.queryParams
  userId: any;
  mainTab = 'userProfile';
  userList: any;
  searchKeyword: any;
  shareProfile: boolean = false;
  url = environment.hubspotNavigateUrl;
  constructor(private router: Router, private mainService: MainService, private route: ActivatedRoute,
    private _SessionService: SessionService, private notifire: NotifierService) { }

  ngOnInit(): void {
    this.userId = this.data['_value'].userId
    this.selectedUserData()
  }
  selectedUserData() {
    this.mainService.viewPublicArtistProfile(this.userId).then((res: any) => {
      if (res && res.isSuccess) {
        this.userProfileData = res.data
        this.mainTab = 'userProfile';
      }
    })
  }
  searchBoxController() {
    this.searchBox = !this.searchBox;
  }
  searchPublicArtistProfile(event: any) {
    this._SessionService.setSessions({ keyWord: event.target.value });
    this.router.navigate(['/searchUser']);
    // this.router.navigate(['/searchUser'], {
    //   queryParams: { keyWord: event.target.value },
    // });
  }
  viewProfile(id: any) {
    this.userId = id;
    this.selectedUserData();
    this.router.navigate(['/userProfile'], {
      queryParams: { userId: id },
    });
  }
  linkCopied() {
    this.notifire.showSuccess('Link Copied')
  }


}
