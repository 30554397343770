import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import SwiperCore, { EffectCoverflow, Pagination ,Navigation,Autoplay,SwiperOptions} from "swiper";
import { Router } from '@angular/router';


declare const Swiper: any;
SwiperCore.use([Autoplay, Pagination, Navigation]);

@Component({
  selector: 'app-artist-pre',
  templateUrl: './artist-pre.component.html',
  styleUrls: ['./artist-pre.component.scss']
})
export class ArtistPreComponent implements OnInit {
  newArtist:any;
  staffPicks:any;
  previewCertificate: any;
  showStarIcon:boolean;
 config: SwiperOptions = {
    centeredSlides: false,
     slidesPerView: 5,
     }

    max = 5;
    rate = 0;
    isReadonly = true;
    artistData:any;
    newArtistData: boolean;
    staffPickData: boolean;
    artistShowData:boolean;
    pageNo: number = 0;
    pageSize: number = 12;

  constructor(
    private _MainService:MainService,
    private _NotifierService:NotifierService,
    private router: Router
  ) {
    this.artistShowData = false;
    this.newArtistData = false;
    this.staffPickData = false;
    this.showStarIcon = false;
  }

  ngOnInit(): void {
    this.getAllNewArtist(this.pageNo,this.pageSize);
    this.getAllStaffPicks(this.pageNo,this.pageSize);
  }

  getAllNewArtist(pageNo: number, pageSize: number){
    this.newArtistData = true;
    this._MainService.getAllNewArtist(pageNo,pageSize).then((data)=>{
      if(data && data.isSuccess){
        this.newArtistData = false;
        this.newArtist = data.data.content;
        }else{
          this.newArtistData = false;
          this._NotifierService.showError(data.message);
        }
    })
  }

  getAllStaffPicks(pageNo: number, pageSize: number){
    this.staffPickData = true;
    this._MainService.getAllStaffPicks().then((data)=>{
      if(data && data.isSuccess){
        this.staffPickData = false;
        this.staffPicks =  data.data;
      }else{
        this.staffPickData = false;
        this._NotifierService.showError(data.message);
      }
    })
  }

  getArtistDetailsById(id:any){
    this.artistShowData = true;
    this._MainService.getArtistDetailsById(id).then((data)=>{
        if(data && data.isSuccess){
          this.artistShowData = false;
          this.artistData = data.data;
          // this._NotifierService.showSuccess(data.message);
        }else{
          this.artistShowData = false;
          this._NotifierService.showError(data.message);
        }
    })
  }

  favoriteArtist(id:any){
    this._MainService.addOrRemoveFavouriteArtist(id).then((data)=>{
      if(data && data.isSuccess){
        this.showStarIcon = data.data;
        this.getAllNewArtist(this.pageNo,this.pageSize);
        this.getAllStaffPicks(this.pageNo,this.pageSize);
        this._NotifierService.showSuccess(data.message);
      }else{
          this._NotifierService.showError(data.message);
      }
    })
}

certicatedPreview(id: any) {
  for (let certificate of this.artistData?.certificateDetails) {
    if (id === certificate.id) {
      this.previewCertificate = certificate.certificateUrl;
    }
  }
}

sendMessage(id: any,name:any) {
  this.router.navigate([`/ArtistDashboard/message`], {
    queryParams: {
      'id': id,
      'name': name,
    }
  })
}

}
