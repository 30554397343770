import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRoutingModule } from './login-routing.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginComponent } from './login.component';
import { SendCodeComponent } from './send-code/send-code.component';
import { SearchPublicUserComponent } from './search-public-user/search-public-user.component';
import { UserPublicProfileComponent } from './user-public-profile/user-public-profile.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSocialShareModule } from 'ngx-social-share';
import { MatIconModule } from '@angular/material/icon';
import { ClipboardModule } from 'ngx-clipboard';
import { AudienceSignupComponent } from './audience-signup/audience-signup.component';
import { VerifyComponent } from './send-code/verify/verify.component';
import { ResetDialogComponent } from './reset-password/reset-dialog/reset-dialog.component';
import { WatchSharedEventComponent } from './watch-shared-event/watch-shared-event.component';
import { WatchSoundCheckComponent } from './watch-sound-check/watch-sound-check.component';
import { FooterComponent } from './footer/footer.component';
import { JumpToEventComponent } from './jump-to-event/jump-to-event.component';
import { SubmitProposalComponent } from './submit-proposal/submit-proposal.component';
import { OpenChatComponent } from './open-chat/open-chat.component';
import { GoLiveViaEmailComponent } from './go-live-via-email/go-live-via-email.component';


@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SendCodeComponent,
    SearchPublicUserComponent,
    UserPublicProfileComponent,
    AudienceSignupComponent,
    VerifyComponent,
    ResetDialogComponent,
    WatchSharedEventComponent,
    WatchSoundCheckComponent,
    FooterComponent,
    JumpToEventComponent,
    SubmitProposalComponent,
    OpenChatComponent,
    GoLiveViaEmailComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    LoginRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSocialShareModule,
    MatIconModule,
    ClipboardModule
  ],
  exports: [
    FooterComponent
  ]
})
export class LoginModule { }
