import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { PrivilegesEnum } from 'src/app/models/enums';
import { NotifierService } from '../helper/notifier.service';
import { SessionService } from '../helper/session.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private _SessionService: SessionService,
    private router: Router,
    private _NotifierService: NotifierService,
    private location: Location
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url = state.url;
    const token = this._SessionService.getSession('token');
    const privileges = this._SessionService.getSession('roles');
    const status = this._SessionService.getSession('status');
    if (token) {
      if (status == 'SUSPENDED' && privileges) {
        if (status == 'SUSPENDED' && privileges.includes(PrivilegesEnum.pendingMgmt) && (url == '/ArtistDashboard/pendingArtistHome')) {
          return true
        } else if (status == 'SUSPENDED' && privileges.includes(PrivilegesEnum.pendingMgmt2) && (url == '/ArtistDashboard/pendingArtistHome')) {
          return true
        } else if (status == 'SUSPENDED' && privileges.includes(PrivilegesEnum.artistMgmt) && (url == '/ArtistDashboard/artistHome')) {
          return true
        } else if (status == 'SUSPENDED' && privileges.includes(PrivilegesEnum.audienceMgmt) && (url == '/audienceDashboard/audienceHome')) {
          return true
        }  else if (status == 'SUSPENDED' && privileges.includes(PrivilegesEnum.artistMgmt)) {
          this.router.navigate(['/ArtistDashboard/artistHome']);
          return true
        } else if (status == 'SUSPENDED' && privileges.includes(PrivilegesEnum.audienceMgmt)) {
          this.router.navigate(['/audienceDashboard/audienceHome']);
          return true
        } else
          return false
      }
      else if (privileges && privileges.length > 0) {
        if (
          privileges.includes(PrivilegesEnum.pendingMgmt) &&
          (url === '/general' ||
            url === '/essential' ||
            url === '/ArtistDashboard/pendingArtistHome' ||
            url === '/ArtistDashboard/myProfile' ||
            url === '/ArtistDashboard/artistHome' ||
            url === '/ArtistDashboard/artistSeeAll' ||
            url.includes('/ArtistDashboard/earnings') ||
            url.includes('/ArtistDashboard/edit') ||
            url.includes('/ArtistDashboard/artistHome') ||
            url.includes('/ArtistDashboard/billing') ||
            url.includes('/ArtistDashboard/artistLiveStreaming') ||
            url.includes('/ArtistDashboard/watchLive') ||
            url.includes('/ArtistDashboard/proReporting') ||
            url.includes('/ArtistDashboard/watchTestStream') ||
            url === '/ArtistDashboard/staffSeeAll' ||
            url === '/ArtistDashboard/artistPendingSeeAll' ||
            url === '/ArtistDashboard/artistPendingStaffSeeAll' ||
            url === '/ArtistDashboard/staffSeeAll' ||
            url.includes('/ArtistDashboard/simpleSearch') ||
            url === '/ArtistDashboard/advancedSearch' ||
            url === '/ArtistDashboard/favArtist' ||
            url === '/ArtistDashboard/artistPre' ||
            url === '/ArtistDashboard/myProfile' ||
            url === '/background' ||
            url === '/production' ||
            url === '/media' ||
            url === '/ArtistDashboard/artistSeeAllNotification' ||
            url === '/ArtistDashboard/artistCalender' ||
            url.includes('/ArtistDashboard/message') ||
            url === '/ArtistDashboard/artistInvite')
        ) {
          return true;
        } else if (
          privileges.includes(PrivilegesEnum.pendingMgmt2) &&
          (url === '/general' ||
            url === '/essential' ||
            url === '/ArtistDashboard/pendingArtistHome' ||
            url === '/ArtistDashboard/myProfile' ||
            url.includes('/ArtistDashboard/watchLive') ||
            url.includes('/ArtistDashboard/watchTestStream') ||
            url.includes('/ArtistDashboard/edit') ||
            url.includes('/ArtistDashboard/artistHome') ||
            url.includes('/ArtistDashboard/billing') ||
            url.includes('/ArtistDashboard/artistLiveStreaming') ||
            url.includes('/ArtistDashboard/earnings') ||
            url.includes('/ArtistDashboard/proReporting') ||
            url === '/ArtistDashboard/artistHome' ||
            url === '/ArtistDashboard/artistSeeAll' ||
            url === '/ArtistDashboard/staffSeeAll' ||
            url === '/ArtistDashboard/artistPendingSeeAll' ||
            url === '/ArtistDashboard/artistPendingStaffSeeAll' ||
            url === '/ArtistDashboard/staffSeeAll' ||
            url.includes('/ArtistDashboard/simpleSearch') ||
            url === '/ArtistDashboard/advancedSearch' ||
            url === '/ArtistDashboard/favArtist' ||
            url === '/ArtistDashboard/artistPre' ||
            url === '/ArtistDashboard/myProfile' ||
            url === '/background' ||
            url === '/production' ||
            url === '/media' ||
            url === '/ArtistDashboard/artistSeeAllNotification' ||
            url === '/ArtistDashboard/artistCalender' ||
            url.includes('/ArtistDashboard/message') ||
            url === '/ArtistDashboard/artistLiveStreaming' ||
            url === '/ArtistDashboard/artistInvite')
        ) {
          return true;
        } else if (
          privileges.includes(PrivilegesEnum.artistMgmt) &&
          (url.includes('/ArtistDashboard/artistInvitation') ||
            url.includes('/ArtistDashboard/artistDraft') ||
            url.includes('/ArtistDashboard/event') ||
            url.includes('/ArtistDashboard/artistEventDetail') ||
            url === '/ArtistDashboard/myProfile' ||
            url === '/ArtistDashboard/artistHome' ||
            url === '/ArtistDashboard/artistSeeAll' ||
            url === '/ArtistDashboard/staffSeeAll' ||
            url === '/ArtistDashboard/artistPendingSeeAll' ||
            url === '/ArtistDashboard/artistPendingStaffSeeAll' ||
            url.includes('/ArtistDashboard/earnings') ||
            url.includes('/ArtistDashboard/watchTestStream') ||
            url.includes('/ArtistDashboard/watchLive') ||
            url.includes('/ArtistDashboard/billing') ||
            url.includes('/ArtistDashboard/artistLiveStreaming') ||
            url.includes('/ArtistDashboard/artistHome') ||
            url.includes('/ArtistDashboard/simpleSearch') ||
            url === '/ArtistDashboard/advancedSearch' ||
            url === '/ArtistDashboard/favArtist' ||
            url === '/ArtistDashboard/artistPre' ||
            url === '/ArtistDashboard/myProfile' ||
            url.includes('/ArtistDashboard/gigBoard') ||
            url === '/ArtistDashboard/artistSeeAllNotification' ||
            url === '/ArtistDashboard/artistCalender' ||
            url.includes('/ArtistDashboard/message') ||
            url.includes('/ArtistDashboard/proReporting') ||
            url === '/ArtistDashboard/artistLiveStreaming' ||
            url === '/ArtistDashboard/artistInvite' ||
            url.includes('/ArtistDashboard/edit'))
        ) {
          return true;
        } else if (
          privileges.includes(PrivilegesEnum.audienceMgmt) &&
          (url === '/audienceGeneral' ||
            url === '/completeProfile' ||
            url.includes('/detail') ||
            url.includes('/event') ||
            url === '/audienceDashboard/details' ||
            url === '/audienceDashboard/audienceHome' ||
            url === '/audienceDashboard/seeAllArtist' ||
            url === '/audienceDashboard/audienceAdvancedSearch' ||
            url.includes('/audienceDashboard/audienceSimpleSearch') ||
            url === '/audienceDashboard/audienceFavSearch' ||
            url === '/audienceDashboard/seeAllStaff' ||
            url === '/audienceDashboard/audienceDrafts' ||
            url === '/audienceDashboard/eventPreview' ||
            url === '/audienceDashboard/inviteArtist' ||
            url === '/audienceDashboard/calender' ||
            url === '/audienceDashboard/audienceSeeAllNotification' ||
            url === '/general' ||
            url === '/essential' ||
            url === '/background' ||
            url === '/production' ||
            url === '/media' ||
            url.includes('/audienceDashboard/watchteststream') ||
            url.includes('/audienceDashboard/audienceLiveStreaming') ||
            url.includes('/audienceDashboard/edit') ||
            url.includes('/audienceDashboard/message') ||
            url.includes('/audienceDashboard/billings') ||
            url === '/ArtistDashboard/myProfile' ||
            url === '/audienceDashboard/Invite-Guests')
        ) {
          return true;
        } else {
          this.location.back();
          // this._NotifierService.showError('Access Denied');
          return false;
        }
      } else {
        return false;
      }
    } else {
      this.router.navigate(['/login']);
      // this._NotifierService.showError('Unauthorized');
      return false;
    }
  }

  canActivateChild():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
