<div id="layoutSidenav_content">
  <main>
    <div class="container-fluid px-4 pt-3">
      <div class="setting-content-wrapper">
        <section>
          <div class="hgnb bot-m">
            <div class="nvhi"><span>Calendar</span></div>
          </div>
          <div class="row">
            <div class="col-md-12" style="margin-top: -22px;">
              <div class="ekuhrte dropdown show">
                <a class="nav-link dropdown-toggle ekuhrte" href="#" role="button" id="dropdownMenuLink1"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fa fa-eye"></i>
                </a>
                <div class="calenderList dropdown-menu" aria-labelledby="dropdownMenuLink1">
                  <li style="color: red;"><i class="fa fa-circle colorIcon"></i>BLOCKED</li>
                  <li style="color: yellow;"><i class="fa fa-circle colorIcon"></i>PENDING</li>
                  <li style="color: green;"><i class="fa fa-circle colorIcon"></i>BOOKED</li>
                  <li style="color: grey;"><i class="fa fa-circle colorIcon"></i>CANCELLED</li>
                </div>
              </div>
            </div>
          </div>
          <div class="control-section">
            <div class="col-lg-12 content-wrapper">
              <ejs-schedule #schedule height="650px" [(selectedDate)]="selectedDate" [eventSettings]='eventSettings'
                (eventRendered)="eventRendered($event)" [views]='views' (popupOpen)="onPopupOpen($event)"
                (actionComplete)="onActionComplete($event)" [minDate]="minDate" (renderCell)="onRenderCell($event)"
                (dragStart)="onDragStart($event)" (resizeStart)="onResizeStart($event)">
                <ng-template #editorTemplate>
                  <table class="custom-event-editor" width="100%" cellpadding="5">
                    <tbody>
                      <tr>
                        <td class="e-textlabel">Title</td>
                        <td colspan="4">
                          <input id="Subject" class="e-field e-input" type="text" value="" name="Subject"
                            style="width: 100%" autocomplete="off" />
                        </td>
                      </tr>
                      <tr>
                        <td class="e-textlabel">From</td>
                        <td colspan="4">
                          <input id="StartTime" class="e-field" type="text" name="StartTime" />
                        </td>
                      </tr>
                      <tr>
                        <td class="e-textlabel">To</td>
                        <td colspan="4">
                          <input id="EndTime" class="e-field" type="text" name="EndTime" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-template>
                <ng-template #quickInfoTemplatesFooter let-data>
                  <div style="display: flex;float: right;margin-bottom: 16px;">
                    <div class="left-button">
                      <button class="e-event-details" title="Extra Details" (click)="onDetailsClick()">More
                        Details</button>
                    </div>
                    <div class="right-button">
                      <button class="e-event-create" title="Add" (click)="onAddClick()">Add</button>
                    </div>
                  </div>
                </ng-template>
              </ejs-schedule>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</div>
