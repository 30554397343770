import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { FormControl, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { RefreshService } from 'src/app/service/refresh.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { LoginModal } from 'src/app/service/models/auth.modals';
import { UserService } from 'src/app/service/user.service';
import { AuthServiceService } from 'src/app/service/auth-service.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validator } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { ImageCroppedEvent, ImageTransform, Dimensions, base64ToFile } from 'ngx-image-cropper';


const moment = _rollupMoment || _moment;


export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DetailComponent implements OnInit {
  separatorKeysCodesTag: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  allFruits: string[] = [];
  newToken: any;
  interval: any;
  removable = true;
  toppings = new FormControl();
  showCertificateModal: boolean;
  browser: any;
  imageSrcvid: any;
  login: LoginModal;
  toppingList: string[] = ['Self-Taught', 'A', 'B', 'C', 'D', 'E'];
  @ViewChild('fruitInput') fruitInput!: ElementRef<HTMLInputElement>;
  generalDetails: any;
  essentailsDetails: any;
  backgroundDetails: any;
  productionDetails: any;
  mediaDetails: any;
  videoDetail: any;
  CertificatedDetails: any;
  certificatedDetails: any;
  certificateForm!: FormGroup;
  addOnBlur: boolean = true;
  videoImageModal: boolean;
  profilePicData: any;
  imageUploaded: boolean;
  imageUrl: any;
  imageSrc: any;
  selectable = true;
  location: any;
  showUploaModalvid: boolean;
  imageDetail: any;
  countries: string[] = [];
  videoUploadForm!: FormGroup;
  id: any;
  previewImage: any;
  removableTag = true;
  selectableTag = true;
  showUploaImageModal: boolean;
  videoDetails: any;
  showImage: boolean;
  fileName: any;
  imageUploaded2: boolean;
  Certificated: any;
  previewCertificate: any;
  showImageModal: boolean;
  videoArray: any[] = [];
  certificateUrl: any;
  cerificateSubmitting: boolean;
  showUploaModal: boolean;
  previewVideo: any;
  keywords: string[] = [];
  videoUrl: any;
  showImagevid: boolean;
  videoSubmitting: boolean;
  fileNamevid: any;
  videoUploaded: boolean;
  showIcon: boolean;
  fileData: any;
  imageSpinnerDone: boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  showDragDrop: boolean = false;
  tabname:any = 'General';

  constructor(
    private _mainService: MainService,
    private notifier: NotifierService,
    private _refresh: RefreshService,
    private _SessionService: SessionService,
    private _UserService: UserService,
    private _auth: AuthServiceService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.imageUploaded = false;
    this.showUploaImageModal = false;
    this.showImage = false;
    this.login = new LoginModal();
    this.imageUploaded2 = false;
    this.showCertificateModal = false;
    this.cerificateSubmitting = false;
    this.showUploaModal = false;
    this.videoImageModal = false;
    this.showImagevid = false;
    this.videoSubmitting = false;
    this.videoUploaded = false;
    this.showIcon = false;
    this.showUploaModalvid = false;
    this.showImageModal = false;
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.allFruits.slice()
      )
    );
  }

  ngOnInit(): void {
    this.getGeneralDatails();
    this.getEssentailDetails();
    this.getBackgroundDetails();
    this.getProducionDetails();
    this.getMediaDetials();
    this.getProfilepic();
    let refreshTokenLog: any[] = [];
    this.interval = setInterval(() => {
      this.refreshToken();
      refreshTokenLog.push({
        message: 'refresh token api called',
        dateTime: new Date(),
      });
      localStorage.setItem('refreshTokenLog', JSON.stringify(refreshTokenLog));
    }, 1500000);
    this.certificateForm = this.fb.group({
      certificateName: new FormControl('', Validators.required),
      certificateUrl: new FormControl('', Validators.required),
      issuingOrganization: new FormControl('', Validators.required),
    });

    this.videoUploadForm = this.fb.group({
      videoTitle: ['', []],
      videoTags: ['', []],
    });
  }

  yearFrom = new FormControl(moment());
  yearTo = new FormControl(moment());

  YearFrom(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    const ctrlValue = this.yearFrom.value;
    ctrlValue.year(normalizedYear.year());
    this.yearFrom.setValue(ctrlValue);
  }

  YearTo(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    const ctrlValue = this.yearTo.value;
    ctrlValue.year(normalizedYear.year());
    this.yearTo.setValue(ctrlValue);
  }
  onFileSelectvid(event: any) {

    const file = event.target.files[0];
    if (file.type == 'video/mp4' || file.type == 'video/avi') {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          this.videoSubmitting = true;
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageSrcvid = <string>(<FileReader>event.target).result;
            this.videoArray.push(this.imageSrcvid);
            this.fileNamevid = file.name;
            this.showImagevid = true;
          };
          reader.readAsDataURL(event.target.files[0]);
          this.uploadFile(file);
        }
      }
    } else {
      this.notifier.showError('Only Video Are Allowed');
    }
  }

  uploadFile(file: any) {
    this.videoUploaded = true;
    const fd = new FormData();
    fd.append('multipartFile', file);
    this._mainService.fileUpload(fd).then((data: any) => {
      if (data && data.isSuccess) {
        this.videoUrl = data.data;
        this.videoSubmitting = false;
        this.videoUploaded = false;
        this.showIcon = false;
      } else {
        this.showImagevid = false;
        this.notifier.showError(data.message);
        this.videoUploaded = false;
      }
    });
  }

  addMusic(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;
    if ((value || '').trim()) {
      this.keywords.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
  }

  removeMusic(keyword: string): void {
    let index = this.keywords.indexOf(keyword);

    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }

  onFileSelectCertificate(event: any) {
    const file = event.target.files[0];
    if (
      file.type == 'image/png' ||
      file.type == 'image/jpg' ||
      file.type == 'image/svg' ||
      file.type == 'image/jpeg' ||
      file.type == 'pdf'
    ) {
      if (event.target.files && event.target.files[0]) {
        this.cerificateSubmitting = true;
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageSrc = <string>(<FileReader>event.target).result;
          this.fileName = file.name;
          this.showImage = true;
        };
        reader.readAsDataURL(event.target.files[0]);
        this.imageUploaded = true;
        const fd = new FormData();
        fd.append('multipartFile', file);
        this._mainService.fileUpload(fd).then((data: any) => {
          if (data && data.isSuccess) {
            this.imageUploaded = false;
            this.certificateUrl = data.data;
            this.cerificateSubmitting = false;
            this.notifier.showSuccess(data.message);
          } else {
            this.imageUploaded = false;
            this.notifier.showError(data.message);
          }
        });
      }
    } else {
      this.notifier.showError('Only Images Are Allowed');
    }
  }

  refreshToken() {
    this._refresh.refreshToken().then((data) => {
      if (data && data.isSuccess) {
        this.newToken = data.data;
        this._SessionService.removeSession('token');
        this._SessionService.setSessions({ token: this.newToken });
      } else {
      }
    });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.fruits.push(value);
    }
    event.chipInput!.clear();
    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter((fruit) =>
      fruit.toLowerCase().includes(filterValue)
    );
  }

  getGeneralDatails() {
    let userId = localStorage.getItem('userId');
    this._mainService.getGeneralProfileById(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.generalDetails = data.data;
        for (let location of this.generalDetails.locations) {
          this.countries = location;
        }
        for (let certificate of this.generalDetails?.certificateDetails) {
          this.Certificated = certificate;
        }
        this._SessionService.setSessions({ 'timeZone': this.generalDetails?.timeZone });
      }
    });
  }
  certicatedPreview(id: any) {
    for (let certificate of this.generalDetails?.certificateDetails) {
      if (id === certificate.id) {
        this.previewCertificate = certificate.certificateUrl;
      }
    }
  }
  imagePreview(id: any) {
    this.showImageModal = true;
    for (let image of this.mediaDetails?.imageDetails) {
      if (id === image.id) {
        this.previewImage = image.imageUrl;
      }
    }
  }
  imageRemove(id: any) {
    this._mainService.deleteImage(id).then((data: any) => {
      if (data && data.isSuccess) {
        this.notifier.showSuccess(data.message);
        this.getMediaDetials();
      } else {
        this.notifier.showError(data.message);
      }
    });
  }
  videoRemove(id: any) {
    this._mainService.deleteVideo(id).then((data: any) => {
      if (data && data.isSuccess) {
        this.notifier.showSuccess(data.message);
        this.getMediaDetials();
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  videoPreview(id: any) {
    this.videoImageModal = true;
    for (let media of this.mediaDetails?.videoDetails) {
      if (id === media.id) {
        this.previewVideo = media.videoUrl;
      }
    }
  }

  getEssentailDetails() {
    let userId = localStorage.getItem('userId');
    this._mainService.getessentailProfileById(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.essentailsDetails = data.data;
      } else {
        this.notifier.showError(data.message);
      }
    });
  }
  getBackgroundDetails() {
    let userId = localStorage.getItem('userId');
    this._mainService.getBackgroundDetails(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.backgroundDetails = data.data;
      } else {
        this.notifier.showError(data.message);
      }
    });
  }
  getProducionDetails() {
    let userId = localStorage.getItem('userId');
    this._mainService.getProductionDetails(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.productionDetails = data.data;
      } else {
        this.notifier.showError(data.message);
      }
    });
  }
  getMediaDetials() {
    let userId = localStorage.getItem('userId');
    this._mainService.getMediaDetails(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.mediaDetails = data.data;
      } else {
        this.notifier.showError(data.message);
      }
    });
  }


  removeCertificateDetails(certificate: any) {
    this.id = certificate.id;
    this._mainService.removeCertificateDetails(this.id).then((data: any) => {
      if (data && data.isSuccess) {
        this.notifier.showSuccess(data.message);
        this.getGeneralDatails();
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  getProfilepic() {
    this.imageSpinnerDone = true;
    let userId = localStorage.getItem('userId');
    this._mainService.getProfilepic(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.imageSpinnerDone = false;
        this.profilePicData = data.data;
      } else {
        this.imageSpinnerDone = false;
        this.notifier.showError(data.message);
      }
    });
  }

  onFileSelect(event: any) {
    if (event.target.files[0]) {
      this.showDragDrop = true;
    }
    this.imageChangedEvent = event;
    const file = event.target.files[0];
    if (file) {
      this.imageSrc = <string>(<FileReader>event.target).result;
    }
  }




  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileData = base64ToFile(this.croppedImage);
  }

  cropDone() {
    this.showDragDrop = false;
    this.imageSpinnerDone = true;
    this.uploadProfilePhoto(this.fileData);
  }



  uploadProfilePhoto(file: any) {
    this.imageUploaded = true;
    this.imageSpinnerDone = true;
    const fd = new FormData();
    fd.append('multipartFile', file);
    this._mainService.fileUpload(fd).then((data: any) => {
      if (data && data.isSuccess) {
        this.imageUrl = data.data;
        this._mainService.updateProfilePic(data.data).then((res: any) => {
          if (data && data.isSuccess) {
            this.getProfilepic();
            this._SessionService.setSessions({ avatarUrl: data.data });
            this.showDragDrop = false;
            this.notifier.showSuccess(res.message);
          } else {
            this.notifier.showError(res.message);
          }
        });
      } else {
      }
    });
  }

  logoutUser() {
    clearInterval(this.interval);
    const device = this.browser;
    this._auth.logout(device).then((data) => {
      if (data && data.isSuccess) {
        this._SessionService.removeSessions();
        this._SessionService.removeSession('email');
        this._SessionService.removeSession('refreshToken');
        this._SessionService.removeSession('userId');
        this._SessionService.removeSession('refreshTokenLog');
        this._SessionService.removeSession('token');
        this._SessionService.removeSession('newtoken');
        this._UserService.setLogedUser(null);
        this.router.navigate([`/login`]);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }
  editProfile() {
    this.router.navigate(['ArtistDashboard/edit']);
  }

  addImageDetails() {
    let body = {
      imageUrl: this.videoDetails,
    };
    this._mainService.addImageDetails(body).then((data: any) => {
      if (data && data.isSuccess) {
        this.notifier.showSuccess(data.message);
        this.showUploaImageModal = false;
        this.videoDetails = null;
        this.showImage = false;
        this.getMediaDetials();
      } else {
        this.notifier.showError(data.message);
      }
    });
  }
  onFileSelectImage(event: any) {
    this.imageChangedEvent = event;
    const file = event.target.files[0];
    if (
      file.type == 'image/jpeg' ||
      file.type == 'image/png' ||
      file.type == 'image/svg+xml' ||
      file.type == 'image/webp'
    ) {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageSrc = <string>(<FileReader>event.target).result;
          this.fileName = file.name;
          this.showImage = true;
        };
        reader.readAsDataURL(event.target.files[0]);
        this.uploadFileImage(file);
      }
    } else {
      this.notifier.error('Only images are allowed');
    }
  }

  uploadFileImage(file: any) {
    this.imageUploaded2 = true;
    const fd = new FormData();
    fd.append('multipartFile', file);
    this._mainService.fileUploadImage(fd).then((data: any) => {
      if (data && data.isSuccess) {
        this.imageUploaded2 = false;
        this.videoDetails = data.data;
        this.notifier.showSuccess(data.message);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  certificateDetailsUpload() {
    let yearObtainedFrom = this.yearFrom.value.year();
    let yearObtainedTo = this.yearTo.value.year();
    let certificateName =
      this.certificateForm.controls['certificateName'].value;
    let issuingOrganization =
      this.certificateForm.controls['issuingOrganization'].value;
    if (!certificateName) {
      this.notifier.showError('Please Enter Certificate Name');
    } else if (!issuingOrganization) {
      this.notifier.showError('Please Enter Issuing Organization');
    } else if (yearObtainedFrom >= yearObtainedTo) {
      this.notifier.showError('Please enter correct Year Range ');
    } else if (yearObtainedTo >= 2023) {
      this.notifier.showError('Only current year seleted  ');
    } else if (!this.certificateUrl) {
      this.notifier.showError('Please Upload Certificate');
    } else if (
      certificateName &&
      issuingOrganization &&
      yearObtainedFrom < yearObtainedTo &&
      this.certificateUrl
    ) {
      const body = {
        certificateName: certificateName,
        certificateUrl: this.certificateUrl,
        issuingOrganization: issuingOrganization,
        yearObtainedFrom: this.yearFrom.value.year(),
        yearObtainedTo: this.yearTo.value.year(),
      };
      this._mainService.uploadCerificateDetails(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.notifier.showSuccess(data.message);
          this.showCertificateModal = false;
          this.certificateForm.reset();
          this.getGeneralDatails();
        } else {
          this.notifier.showError(data.message);
        }
      });
    }
  }

  addVideoDetails() {
    let videoTitle = this.videoUploadForm.controls['videoTitle'].value;
    if (!this.keywords) {
      this.notifier.showError('Please Enter Video Tag');
    } else if (!videoTitle) {
      this.notifier.showError('Please Enter Video Title');
    } else if (!this.videoUrl) {
      this.notifier.showError('Please upload Video');
    } else if (this.keywords && videoTitle && this.videoUrl) {
      let body = {
        videoTags: this.keywords,
        videoTitle: this.videoUploadForm.controls['videoTitle'].value,
        videoUrl: this.videoUrl,
      };
      this._mainService.addVideoDetails(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.notifier.showSuccess(data.message);
          this.getMediaDetials();
          this.videoUploadForm.reset();
          this.keywords = [];
          this.videoUrl = null;
          this.showImagevid = false;
        } else {
          this.notifier.showError(data.message);
        }
      });
    }
  }




  imageLoaded() {
    this.showCropper = true;

  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }


}
