<div class="joinpage">
  <div class="joinhead">
    <img src="../../../../assets/Logot.png">
  </div>
  <div class="joinbody">
    <div class="row joinb">
      <div class="col-md-7">
        <div class="joinleft">
          <div class="card">
            <img class="img" src="../../../../assets/mainSliderImg/slide1.webp">
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="f1">
          <img src="../../../../assets/t3.png">
        </div>
        <h1>Your email has been verified.
        </h1>
        <h3>Please login to your account.</h3>
        <button type="button" class="button" routerLink="/login">Continue</button>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
