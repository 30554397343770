import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/service/auth-service.service';

@Component({
  selector: 'app-account-verify',
  templateUrl: './account-verify.component.html',
  styleUrls: ['./account-verify.component.scss']
})
export class AccountVerifyComponent implements OnInit {
  token:any;

  constructor( private auth: AuthServiceService, private ar:ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.ar.params.subscribe((par) => {
      this.token = par.token;
    });
    this.auth.emailVerify(this.token).then((res) =>{
      if(res && res.isSuccess){

      }else{
        this.router.navigate(['/accountVerifyError']);
      }
    })
  }

}
