<div *ngIf="selectTab == 'gigBoard'" id="layoutSidenav_content">
  <main>
    <div class="container-fluid pl-4 pr-4 pt-3">
      <div class="setting-content-wrapper">
        <section>
          <div class="hgnb bot-m">
            <div class="nvhi"><span>Gigboard</span>
              <!-- <button class="ekuhrte" [routerLink]="['/ArtistDashboard/event']">Create new event</button> -->
            </div>
          </div>
          <div class="row" infiniteScroll [infiniteScrollDistance]="2" (scrolled)="onScroll()" [scrollWindow]="false"
            style="height: 77vh !important; overflow: auto; overflow-x: hidden;">
            <div class="col-lg-9 col-xl-9 col-md-9 col-sm-8 pr-0">
              <div class="container-fluid">
                <div class="uthrr">
                  <div class="derfrf mt-5">
                    <div style="float: right; margin-top: -66px;">
                      <li class="nav-item dropdown hgne">
                        <a class="nav-link dropdown-toggle dropdownImage" role="button" (click)="sorting = !sorting">
                          <div style="margin-left: -5px;">
                            <div class="line-1"></div>
                            <div class="line-2"></div>
                            <div class="line-3"></div>
                          </div>
                        </a>
                        <div *ngIf="sorting" class=""
                          style="color: white; position: absolute; background: #111111; right: 0px;">
                          <a class="dropdown-item" (click)="sortGig('budget')" style="cursor: pointer;">By gig
                            amount</a>
                          <a class="dropdown-item" (click)="sortGig('start_date')" style="cursor: pointer;">By gig date
                            posting</a>
                          <a class="dropdown-item" (click)="sortGig('A-Z')" style="cursor: pointer;">A-Z</a>
                          <a class="dropdown-item" (click)="sortGig('Z-A')" style="cursor: pointer;">Z-A</a>
                        </div>
                      </li>
                    </div>
                    <div class="tab-content">
                      <div role="tabpanel" class="tab-pane fade in active" id="all">
                        <ng-container *ngIf="!newEvent?.length && !gigsData">
                          <div class="center-error" style="margin-top: 137px;">
                            <div class="error-wrapper">
                              <h1 class="text-center" style="color: white;">Oops</h1>
                              <p class="text-center" style="color: white;">No Data Found.</p>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="gigsData">
                          <div style="text-align: center;">
                            <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
                              secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
                          </div>
                        </ng-container>
                        <ng-container *ngFor="let events of newEvent">
                          <div class="meraww" (click)="openRockBand(events.id)" style="cursor: pointer;">
                            <div class="udff">
                              <div class="edftg breakLine">
                                <h4 style="width: 100%;">{{events.title | titlecase }}</h4>
                              </div>
                              <div class="gonet">
                                <div class="qwsar">
                                  <p *ngFor="let genres of events.genres | slice:0:2; let i=index">{{genres}}</p>
                                  <!-- <i class="fa fa-heart-o" aria-hidden="true"></i> -->
                                </div>
                              </div>
                            </div>
                            <div class="udff mb-3">
                              <div class="edftg">
                                <p class="mb-1">Event Id: {{events.id}} </p>
                                <p class="mb-1">Start Date: {{events?.startDate }}</p>
                                <p class="mb-1">End Date: {{events?.endDate }}</p>
                              </div>
                              <div class="gonet">
                                <div class="qwsar">
                                  <h5>${{events.budget}}</h5>
                                </div>
                              </div>
                            </div>
                            <div class="udff">
                              <div class="edftg  loat">
                                <p class="mb-0">{{events.visibility}} <span
                                    *ngIf="events.dateStatus == 'Day After'">Created on
                                    {{events.createdAt }}</span>
                                  <span *ngIf="events.dateStatus == 'Today'">Created Today</span>
                                  <span *ngIf="events.dateStatus == 'Yesterday'">Created Yesterday</span>
                                </p>
                                <p class="ml-4 mb-0">Status <span>{{events.status}}</span></p>
                              </div>
                              <div class="gonet">
                                <div class="qwsar dwatr d-flex flex-row-reverse">
                                  <p class="mb-0"> Proposal </p>
                                  <p class="mb-0 pr-3">{{events.proposal}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-xl-3 col-md-3 col-sm-4 pr-0">
              <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                  <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div class="sb-sidenav-menu">
                      <div class="nav edu">
                        <form [formGroup]="searchGigForm">
                          <ul class="ihrds">
                            <li class="nav-link">
                              <div class="form-group w-100 hfdqqa">
                                <label for="genre">Search by genres</label>
                                <mat-form-field class="example-chip-list" appearance="fill">
                                  <mat-chip-list #chipList aria-label="Fruit selection">
                                    <mat-chip *ngFor="let genres of searchgen" (removed)="removegen(genres)">
                                      {{genres}}
                                      <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                      </button>
                                    </mat-chip>
                                    <input
                                      style="border: none; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                                      #fruitInput [formControl]="genCtrl" [matAutocomplete]="auto"
                                      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                      (matChipInputTokenEnd)="addgen($event)">
                                  </mat-chip-list>
                                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedgen($event)">
                                    <mat-option *ngFor="let gen of generList" [value]="gen">
                                      {{gen}}
                                    </mat-option>
                                  </mat-autocomplete>
                                </mat-form-field>
                  <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start typing to select your genres</small>
                </div>
                            </li>
                            <li class="nav-link">
                              <div class="form-group w-100 hfdqqa">
                                <label for="genre">Search by date</label>
                                <input type="date" class="form-control" formControlName="startDate" id="Date"
                                  style="height: 50px;">
                              </div>
                            </li>
                          </ul>
                        </form>
                        <div style="padding: 0px 30px;">
                          <button type="button" class="btn ytgft" data-dismiss="modal"
                            (click)="getGigSearchedDetails()">Search</button>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</div>
<!-- Proposal Modal -->
<div *ngIf="selectTab == 'proposalModal'" class="deaa pl-2" id="rockband">
  <div class=" modalBg my-4" role="document">
    <div class=" modalBg judhr">
      <div class="">
        <div class="container">
          <div class="row pt-3" style="color: white;">
            <div class="col-11 pl-5">
              <h3>Submit a proposal</h3>
            </div>
            <div class="col-1 text-right">
              <button type="button" class="close">
                <span aria-hidden="true" (click)="back('gigBoard')" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body" style="color: white;">
        <div class="container sthutr">
          <div class="row pb-3">
            <div class="col-12 mt-3">
              <h5>Event Details</h5>
              <hr style="border-bottom: 1px solid white;">
            </div>
            <div class="col-9 breakLine">
              <h5> {{eventDetails?.title | titlecase}} </h5>
              <span>{{eventDetails?.visibility}}</span>
              <p class="mb-0">Start Date: {{eventDetails?.startDate }}</p>
              <p class="mb-0">End Date: {{eventDetails?.endDate }}</p>
              <p class="mt-2 d-flex text-justify"> {{eventDetails?.description}} </p>
            </div>
            <div class="col-3" style="border-left: 1px solid white;">
              <p><small>${{eventDetails?.budget}}</small></p>
              <p><small *ngFor="let gen of eventDetails?.genreDetails">{{gen}}</small></p>
              <p><small>{{eventDetails?.proposals}} Proposal, {{eventDetails?.messages}} Messaged</small></p>
              <p><small>Status : {{eventDetails?.status}}</small></p>
            </div>
          </div>
        </div>
        <div class="container" style="width: 90%;">
          <div class="row py-3">
            <div class="col-12 mt-3">
              <div>
                <label for="coverLetter">Cover Letter</label><br>
                <textarea class="textBox" [(ngModel)]="sendProposalValue.coverLetter" name="" id="" cols="30"
                  rows="4"></textarea>
              </div>
              <div class="pt-3">
                <label for="estimatedHours">Estimated Hours</label><br>
                <input type="number" [(ngModel)]="sendProposalValue.estimateHours" (keyup)="calculateAmount()"
                  class="inputBox">
              </div>
            </div>
          </div>
        </div>
        <div class="container" style="width: 90%;">
          <div class="row pt-2">
            <div class="col-12 fir-tab">
              <p class="ve-nue">How do you want to be paid </p>
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item pr-5">
                  <input type=radio id="rdo1" checked class="radio-input" name="radio-group"
                    (click)="paidOptionSelection('per_hour')">
                  <label for="rdo1" class="radio-label" style="padding-left: 30px;"> <span class="radio-border"></span>
                    Per Hour </label>
                </li>
                <li class="nav-item pl-5">
                  <input type=radio id="rdo2" class="radio-input" name="radio-group"
                    (click)="paidOptionSelection('total_fee')">
                  <label for="rdo2" class="radio-label" style="padding-left: 30px;"><span
                      class="radio-border"></span>Total Fee</label>
                </li>
              </ul>
              <div class="tab-content mt-4">
                <div *ngIf="tabName == 'per_hour'">
                  <form [formGroup]="proposalDraftForm">
                    <div class="form-group">
                      <label for="hourlyFee">Hourly Fee</label><br>
                      <p class="ratoj">$</p>
                      <input type="number" class="inputBox" formControlName="hourlyFee" (keyup)="calculateAmount()">
                    </div>
                    <div class="form-group">
                      <label for="totalFee">Total Fee</label><br>
                      <p class="ratoj">$</p>
                      <input type="number" class="inputBox" formControlName="totalFee">
                    </div>
                    <div class="form-group">
                      <label for="additionalNotes">Additional Notes</label>
                      <textarea class="form-control textBox" formControlName="additionalNotes" id="description" rows="4"
                        autocomplete="off"></textarea>
                    </div>
                  </form>
                </div>
                <div *ngIf="tabName == 'total_fee'">
                  <form [formGroup]="proposalDraftForm">
                    <div class="form-group">
                      <label for="totalFee">Total Fee</label><br>
                      <p class="ratoj">$</p>
                      <input class="inputBox" formControlName="totalFee" type="number">
                    </div>
                    <div class="form-group">
                      <label for="additionalNotes">Additional Notes</label>
                      <textarea class="form-control textBox" formControlName="additionalNotes" id="description" rows="4"
                        autocomplete="off"></textarea>
                    </div>
                  </form>
                </div>
                <div class="form-check">
                  <input class="form-check-input" [(ngModel)]="checkBox1" type="checkbox" value="" id="defaultCheck1">
                  <label class="form-check-label" for="defaultCheck1">
                    I have read and understand the requirements of the gig posting that I am responding to in this
                    proposal.
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" [(ngModel)]="checkBox2" type="checkbox" value="" id="defaultCheck2">
                  <label class="form-check-label" for="defaultCheck2">
                    I have read and understand the Virtuosica ToS that are an integral part of any contract that results
                    as an outcome of this proposal.
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 my-5">
              <button [disabled]="userStatus == 'PENDING_DELETE' || submitBtn" type="submit" class="btn hftrdd ml-4"
                (click)="submitProposal('saveForLater')">Save for
                later</button>
              <button [disabled]="userStatus == 'PENDING_DELETE' || submitBtn" type="submit" class="btn defrqqa edcae"
                (click)="submitProposal('submitProposal')" data-toggle="modal" data-target=".sendProModal">Send
                Proposal</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- send Proposal Modal -->
<div *ngIf="sendProposalModal">
  <div class="modal fade sendProModal mt-5 pt-4" [ngClass]="sendProposalModal?'d-block':'d-none'">
    <div class="modal-dialog modal-md px-5 py-5">
      <div class="modal-content modalBg">
        <div class="container">
          <div class="row pt-3" style="color: white;">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="sendProposalModal = false; selectTab = 'gigBoard'"
                style="color: white; font-size: 25px;">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="row text-center px-5 pt-2" style="color: white;">
            <div class="col-12">
              <h3><b>Congrats!</b></h3>
            </div>
            <p>Your proposal has been sent Successfully.</p>
          </div>
          <div class="row">
            <div class="col-12 mb-5 text-center sendProModalbtn">
              <button type="submit" class="btn hftrdd ml-4" style="height: 42px;" data-dismiss="modal" aria-label="Close"
                (click)="selectTab='editProposalModal';sendProposalModal=!sendProposalModal">Edit Proposal</button>
              <button type="submit" class="btn defrqqa edcae" style="height: 42px;" data-dismiss="modal" aria-label="Close"
                (click)="selectedProposal()">Review Proposal</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Review Proposal -->
<div *ngIf="selectTab == 'reviewProposalModal'" class="deaa ml-3" id="rockband">
  <div class=" modalBg my-4" role="document">
    <div class=" modalBg judhr">
      <div class="">
        <div class="container">
          <div class="row pt-3" style="color: white;">
            <div class="col-9">
              <h3>Review Proposal</h3>
            </div>
            <div class="col-3 text-right">
              <button type="submit" class="btn defrqqa edcae mr-3" style="width: 160px;"
                (click)="selectTab='editProposalModal'">Edit Proposal</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body" style="color: white;">
        <div class="container sthutr">
          <div class="row pb-3">
            <div class="col-12 mt-3">
              <h5>Event Details</h5>
              <hr class="pt-0 mt-0" style="border-bottom: 1px solid white;">
            </div>
            <div class="col-9 breakLine">
              <h5> {{selectedProposalData?.title | titlecase}} </h5>
              <span><small>{{selectedProposalData?.visibility}}, {{selectedProposalData?.eventCreatedAt }} </small></span>
              <p class="mt-2 d-flex text-justify"> {{selectedProposalData?.description}} </p>
            </div>
            <div class="col-3" style="border-left: 1px solid white;">
              <p><small>${{selectedProposalData?.budget}}</small></p>
              <p><small *ngFor="let gen of selectedProposalData?.genres">{{gen}}</small></p>
              <p><small>{{selectedProposalData?.proposals}} Proposal, {{selectedProposalData?.messages}}
                  Messaged</small></p>
              <p><small>Status : {{selectedProposalData?.eventStatus}}</small></p>
            </div>
          </div>
        </div>
        <div class="container" style="width: 90%;">
          <div class="row py-3">
            <div class="col-12 mt-3">
              <div>
                <label for="coverLetter"><b>Cover Letter</b></label><br>
                <p class="breakLine"> {{selectedProposalData.coverLetter}} </p>
              </div>
              <div class="pt-3">
                <label for="estimatedHours"><b>Estimated Hours</b></label><br>
                <p> {{selectedProposalData.estimateHours}} </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container" style="width: 90%;">
          <div class="row pt-2">
            <div class="col-12">
              <p><b>How do you want to be paid</b> </p>
              <p *ngIf="selectedProposalData.paidOption == 'PER_HOUR'">Per Hour</p>
              <p *ngIf="selectedProposalData.paidOption == 'TOTAL_FEE'">Total Fee</p>
              <p *ngIf="selectedProposalData.paidOption == 'PER_HOUR'"><b>Hourly Fee</b></p>
              <p *ngIf="selectedProposalData.paidOption == 'PER_HOUR'"> ${{selectedProposalData.hourlyFee}} </p>
              <p><b>Total Fee</b></p>
              <p>${{selectedProposalData.totalFee}} </p>
              <p><b>Additional Notes</b></p>
              <p class="breakLine"> {{selectedProposalData.additionalNotes}} </p>
              <div class="col-12 my-5">
                <button type="submit" (click)="back('gigBoard')" class="btn defrqqa edcae"
                  style="width: 160px;">Back</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Edit Proposal Modal -->
<div *ngIf="selectTab == 'editProposalModal'" class="deaa ml-3" id="rockband">
  <div class=" modalBg my-4" role="document">
    <div class=" modalBg judhr">
      <div class="">
        <div class="container">
          <div class="row pt-3" style="color: white;">
            <div class="col-12">
              <h3>Edit Proposal</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body" style="color: white;">
        <div class="container sthutr">
          <div class="row pb-3">
            <div class="col-12 mt-3">
              <h5>Event Details</h5>
              <hr style="border-bottom: 1px solid white;">
            </div>
            <div class="col-9 breakLine">
              <h5> {{eventDetails?.title | titlecase}} </h5>
              <span>{{eventDetails?.visibility}},</span>
              <p class="mb-0">Start Date: {{eventDetails?.startDate }}</p>
              <p class="mb-0">End Date: {{eventDetails?.endDate }}</p>
              <p class="mt-2 d-flex text-justify"> {{eventDetails?.description}} </p>
            </div>
            <div class="col-3" style="border-left: 1px solid white;">
              <p><small>${{eventDetails?.budget}} | Guitar</small></p>
              <p><small *ngFor="let gen of eventDetails?.genreDetails">{{gen}}</small></p>
              <p><small>0 Proposal, 0 Messaged</small></p>
              <p><small>Status : {{eventDetails?.status}}</small></p>
            </div>
          </div>
        </div>
        <div class="container" style="width: 90%;">
          <div class="row py-3">
            <div class="col-12 mt-3">
              <div>
                <label for="coverLetter">Cover Letter</label><br>
                <textarea class="textBox" [(ngModel)]="sendProposalValue.coverLetter" name="" id="" cols="30"
                  rows="4"></textarea>
              </div>
              <div class="pt-3">
                <label for="estimatedHours">Estimated Hours</label><br>
                <input type="number" [(ngModel)]="sendProposalValue.estimateHours" (keyup)="calculateAmount()"
                  class="inputBox">
              </div>
            </div>
          </div>
        </div>
        <div class="container" style="width: 90%;">
          <div class="row pt-2">
            <div class="col-12 fir-tab">
              <p class="ve-nue">How do you want to be paid </p>
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item pr-5">
                  <input type=radio id="rdo1" checked class="radio-input" name="radio-group"
                    (click)="paidOptionSelection('per_hour')">
                  <label for="rdo1" class="radio-label pl-5"> <span class="radio-border"></span> Per Hour </label>
                </li>
                <li class="nav-item pl-5">
                  <input type=radio id="rdo2" class="radio-input" name="radio-group"
                    (click)="paidOptionSelection('total_fee')">
                  <label for="rdo2" class="radio-label pl-5"><span class="radio-border"></span>Total Fee</label>
                </li>
              </ul>
              <div class="tab-content mt-4">
                <div *ngIf="tabName == 'per_hour'">
                  <form [formGroup]="proposalDraftForm">
                    <div class="form-group">
                      <label for="hourlyFee">Hourly Fee</label><br>
                      <p class="ratoj">$</p>
                      <input type="number" class="inputBox" formControlName="hourlyFee" (keyup)="calculateAmount()">
                    </div>
                    <div class="form-group">
                      <label for="totalFee">Total Fee</label><br>
                      <p class="ratoj">$</p>
                      <input type="number" class="inputBox" formControlName="totalFee">
                    </div>
                    <div class="form-group">
                      <label for="additionalNotes">Additional Notes</label>
                      <textarea class="form-control textBox" formControlName="additionalNotes" id="description" rows="4"
                        autocomplete="off"></textarea>
                    </div>
                  </form>
                </div>
                <div *ngIf="tabName == 'total_fee'">
                  <form [formGroup]="proposalDraftForm">
                    <div class="form-group">
                      <label for="totalFee">Total Fee</label><br>
                      <p class="ratoj">$</p>
                      <input class="inputBox" formControlName="totalFee" type="number">
                    </div>
                    <div class="form-group">
                      <label for="additionalNotes">Additional Notes</label>
                      <textarea class="form-control textBox" formControlName="additionalNotes" id="description" rows="4"
                        autocomplete="off"></textarea>
                    </div>
                  </form>
                </div>
                <div class="form-check">
                  <input class="form-check-input" [(ngModel)]="checkBox1" type="checkbox" value="" id="defaultCheck1">
                  <label class="form-check-label" for="defaultCheck1">
                    I have read and understand the requirements of the gig posting that I am responding to in this
                    proposal.
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" [(ngModel)]="checkBox2" type="checkbox" value="" id="defaultCheck2">
                  <label class="form-check-label" for="defaultCheck2">
                    I have read and understand the Virtuosica ToS that are an integral part of any contract that results
                    as an outcome of this proposal.
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 my-5">
              <button type="submit" class="btn hftrdd ml-4" (click)="back('gigBoard')">Cancel</button>
              <button type="submit" class="btn defrqqa edcae"
                (click)="submitProposal('submitEditProposal')">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
