import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { debounceTime } from 'rxjs/operators';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { SessionService } from 'src/app/service/helper/session.service';
@Component({
  selector: 'app-gig-board',
  templateUrl: './gig-board.component.html',
  styleUrls: ['./gig-board.component.scss']
})
export class GigBoardComponent implements OnInit {
  searchGigForm!: FormGroup;
  proposalDraftForm: FormGroup
  eventList: any;
  eventDetails: any;
  newEvent: any[] = [];
  data = [];
  pageNo: number = 0;
  pageSize: number = 5;
  currentPage = 0;
  sortby: string = '';
  tabName = 'per_hour'
  selectTab = 'gigBoard'
  sendProposalValue: any = {
    additionalNotes: "",
    coverLetter: "",
    estimateHours: "",
    eventId: "",
    hourlyFee: "",
    paidOption: "per_hour",
    totalFee: "",
  }
  editProposalId: any;
  checkBox1: boolean = false
  checkBox2: boolean = false
  sendProposalModal: boolean = false;
  searchgen: string[] = [];
  genCtrl = new FormControl();
  @ViewChild('fruitInput')
  fruitInput!: ElementRef<HTMLInputElement>;
  generList: any;
  keyWord: any = this.route.queryParams
  separatorKeysCodes: number[] = [COMMA];
  selectedProposalData: any;
  navigateToEventsTab: boolean = false;
  gigsData: boolean;
  sorting: boolean = false;
  userStatus: any;
  submitBtn:boolean = false;

  constructor(
    private MainService: MainService,
    private NotifierService: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private sessionStorage: SessionService
  ) {
    this.gigsData = false;
    this.proposalDraftForm = this.fb.group({
      hourlyFee: ['', [Validators.required]],
      totalFee: ['', [Validators.required]],
      additionalNotes: ['', [Validators.required]],
    });
    this.searchGigForm = this.fb.group({
      genres: ['', []],
      instruments: ['', []],
      startDate: ['', []]
    });
    this.genCtrl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      if (value) {
        this.MainService.searchGenres(value).then((res: any) => {
          if (res && res.isSuccess) {
            this.generList = res.data;
          }
        })
      }
    })
  }

  ngOnInit(): void {
    var type = this.keyWord['_value'].eventType
    if (type == 'ARTIST_INVITATION')
      this.openRockBand(this.keyWord['_value'].eventId);
    this.userStatus = this.sessionStorage.getSession('status');
    var eventId = this.keyWord['_value'].eventId;
    if (eventId) {
      this.navigateToEventsTab = true;
      this.openRockBand(eventId)
    }
    if (this.keyWord['_value'].type == 'submitProposal') {
      this.openRockBand(this.keyWord['_value'].eventId);
      this.navigateToEventsTab = false;
    }
    this.getAllGiglist(this.pageNo, this.pageSize);
  }

  addgen(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.searchgen.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.genCtrl.setValue(null);
  }

  selectedgen(event: MatAutocompleteSelectedEvent): void {
    this.searchgen.push(event.option.viewValue);
    this.searchGigForm.controls.genres.setValue(this.searchgen)
    this.fruitInput.nativeElement.value = '';
    this.genCtrl.setValue(null);
  }

  removegen(gen: string): void {
    const index = this.searchgen.indexOf(gen);
    if (index >= 0) {
      this.searchgen.splice(index, 1);
    }
  }

  getAllGiglist(pageNo: number, pageSize: number, sortby?: string) {
    this.gigsData = true;
    this.MainService.getAllGiglist(pageNo, pageSize, sortby).then((data) => {
      if (data && data.isSuccess) {
        this.gigsData = false;
        this.eventList = data.data.content;
        this.newEvent = this.eventList;
        this.newEvent.forEach((item: any) => {
          const day = new Date().toDateString();
          const lastDay = new Date();
          lastDay.setDate(lastDay.getDate() - 1);
          var date = new Date(item.createdAt).toDateString();
          if (date == day) {
            item.dateStatus = "Today";
          } else if (date == lastDay.toDateString()) {
            item.dateStatus = "Yesterday";
          } else {
            item.dateStatus = "Day After";
          }
        })
        // this.NotifierService.showSuccess(data.message);
      } else {
        this.gigsData = false;
        this.NotifierService.showError(data.message);
      }
    })

  }

  onScroll() {
    this.currentPage = this.currentPage + 1;
    this.MainService.getAllGiglist(this.currentPage, this.pageSize, this.sortby).then(data => {
      if (data && data.isSuccess) {
        if (data.data.content.length) {
          this.eventList = [...this.eventList, ...data.data.content];
          this.newEvent = this.eventList;
          this.newEvent.forEach((item: any) => {
            const day = new Date().toDateString();
            const lastDay = new Date();
            lastDay.setDate(lastDay.getDate() - 1);
            var date = new Date(item.createdAt).toDateString();
            if (date == day) {
              item.dateStatus = "Today";
            } else if (date == lastDay.toDateString()) {
              item.dateStatus = "Yesterday";
            } else {
              item.dateStatus = "Day After";
            }
          })
        }
      }
    })
  }

  getGigSearchedDetails() {
    let geners = this.searchGigForm.controls['genres'].value;
    let date = this.searchGigForm.controls['startDate'].value;
    this.MainService.getGigSearchedDetails(date, geners, this.pageNo, this.pageSize).then((data) => {
      if (data && data.isSuccess) {
        this.eventList = data.data.content;
        this.newEvent = this.eventList;
        this.newEvent.forEach((item: any) => {
          const day = new Date().toDateString();
          const lastDay = new Date();
          lastDay.setDate(lastDay.getDate() - 1);
          var date = new Date(item.createdAt).toDateString();
          if (date == day) {
            item.dateStatus = "Today";
          } else if (date == lastDay.toDateString()) {
            item.dateStatus = "Yesterday";
          } else {
            item.dateStatus = "Day After";
          }
        })
        // this.NotifierService.showSuccess(data.message);
      } else {
        this.NotifierService.showError(data.message);
      }
    })
  }

  openRockBand(id: any) {
    this.sendProposalValue.eventId = id
    this.MainService.getEventDetailById(id).then((data) => {
      if (data && data.isSuccess) {
        this.eventDetails = data.data;
        this.selectTab = 'proposalModal'
        // this.NotifierService.showSuccess(data.message);
      } else {
        this.NotifierService.showError(data.message);
      }
    })
  }
  back(type: any) {
    if (this.navigateToEventsTab)
      this.router.navigate(['/ArtistDashboard/artistEventDetail'])
    else
      this.selectTab = type
  }

  sortGig(data: any) {
    this.sortby = data;
    if (this.data) {
      this.getAllGiglist(this.pageNo, this.pageSize, this.sortby);
    } else {
      this.getAllGiglist(this.pageNo, this.pageSize);
    }
  }
  paidOptionSelection(type: string) {
    this.tabName = type
    this.sendProposalValue.paidOption = type
  }

  calculateAmount() {
    if (this.tabName == 'per_hour')
      this.proposalDraftForm.controls.totalFee.setValue(this.proposalDraftForm.controls['hourlyFee'].value * this.sendProposalValue.estimateHours);
    this.sendProposalValue.hourlyFee = this.proposalDraftForm.controls['hourlyFee'].value
    this.sendProposalValue.totalFee = this.proposalDraftForm.controls['totalFee'].value
  }

  submitProposal(type: string) {
    this.sendProposalValue.additionalNotes = this.proposalDraftForm.controls['additionalNotes'].value
    this.sendProposalValue.totalFee = this.proposalDraftForm.controls['totalFee'].value
    if (this.sendProposalValue.paidOption == 'per_hour')
      this.sendProposalValue.totalFee = (this.sendProposalValue.estimateHours * this.sendProposalValue.hourlyFee)
    else if (this.sendProposalValue.paidOption == 'total_fee')
      this.sendProposalValue.hourlyFee = 0
    if (this.checkBox1 && this.checkBox2) {
      if (this.sendProposalValue.coverLetter == "" || this.sendProposalValue.coverLetter == null || this.sendProposalValue.coverLetter == undefined)
        this.NotifierService.showError('Please mention cover letter')
      else if (this.sendProposalValue.estimateHours == "" || this.sendProposalValue.estimateHours == null || this.sendProposalValue.estimateHours == undefined)
        this.NotifierService.showError('Please mention estimate hours')

      else if (this.sendProposalValue.paidOption == 'per_hour' || this.sendProposalValue.paidOption == 'total_fee') {
        if (this.sendProposalValue.paidOption == 'per_hour') {
          if (this.sendProposalValue.hourlyFee == "" || this.sendProposalValue.hourlyFee == null || this.sendProposalValue.hourlyFee == undefined)
            this.NotifierService.showError('Please mention hourly fee')
          else
            this.finalSubmitProposal(type)
        }
        else if (this.sendProposalValue.paidOption == 'total_fee') {
          if (this.sendProposalValue.totalFee == "" || this.sendProposalValue.totalFee == null || this.sendProposalValue.totalFee == undefined)
            this.NotifierService.showError('Please mention total fee')
          else
            this.finalSubmitProposal(type)
        }
      }
    }
    else
      this.NotifierService.showError('Please Select All Terms & Conditions')
  }
  finalSubmitProposal(type: string) {
    this.submitBtn = true;
    let body = this.sendProposalValue
    if (type == 'submitProposal') {
      this.MainService.submitProposal(body).then((data) => {
        if (data && data.isSuccess) {
          this.sendProposalModal = true
          this.editProposalId = data.data
          this.NotifierService.showSuccess(data.message);
          var type = this.keyWord['_value'].eventType
          if (type == 'ARTIST_INVITATION')
            this.router.navigate([`/ArtistDashboard/gigBoard`])
          this.getAllGiglist(this.pageNo, this.pageSize);
          // this.resetProposalDraft()
        } else {
          this.NotifierService.showError(data.message);
          if (data.message == 'User already submitted Proposal for this Event') {
            this.selectTab = 'gigBoard'
            this.resetProposalDraft()
          }
        }
        this.submitBtn = false;
      })
    } else if (type == 'saveForLater') {
      this.MainService.saveProposalAsDraft(body).then((data) => {
        if (data && data.isSuccess) {
          // this.selectTab = 'gigBoard'
          this.NotifierService.showSuccess(data.message);
          this.router.navigate(['/ArtistDashboard/artistDraft'], { queryParams: { type: 'proposal' } })
          this.getAllGiglist(this.pageNo, this.pageSize);
          // this.resetProposalDraft()
        } else {
          this.NotifierService.showError(data.message);
          if (data.message == 'User already saved proposal for this Event Id') {
            this.selectTab = 'gigBoard'
            this.resetProposalDraft()
          }
        }
        this.submitBtn = false;
      })
    } else if (type == 'submitEditProposal') {
      this.MainService.submitEditProposal(this.editProposalId, body).then((data) => {
        if (data && data.isSuccess) {
          this.selectTab = 'gigBoard'
          this.NotifierService.showSuccess(data.message);
          this.getAllGiglist(this.pageNo, this.pageSize);
          this.resetProposalDraft()
        } else
          this.NotifierService.showError(data.message);
        this.submitBtn = false;
      })
    }
  }
  resetProposalDraft() {
    this.sendProposalValue.additionalNotes = "";
    this.sendProposalValue.coverLetter = "";
    this.sendProposalValue.estimateHours = "";
    this.sendProposalValue.eventId = "";
    this.sendProposalValue.hourlyFee = "";
    this.sendProposalValue.paidOption = "per_hour";
    this.sendProposalValue.totalFee = "";
    this.proposalDraftForm.controls.hourlyFee.setValue('');
    this.proposalDraftForm.controls.totalFee.setValue('');
    this.proposalDraftForm.controls.additionalNotes.setValue('');
  }
  selectedProposal() {
    this.sendProposalModal = !this.sendProposalModal
    this.MainService.getEditProposalDraft(this.editProposalId).then((data) => {
      if (data && data.isSuccess) {
        this.selectedProposalData = data.data
      }
    })
    this.selectTab = 'reviewProposalModal';
  }

}
