<div class="joinpage">
  <div class="joinhead">
    <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg" routerLink=""
      style="cursor: pointer;">
  </div>
  <div class="joinbody container">
    <div class="row joinb">
      <div class="col-md-6 col-sm-12 col-xl-6">
        <div class="joinleft">
          <app-slider></app-slider>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 col-xl-6">
        <div class="joinright" style="margin-bottom: 20px;">
          <div class="ujhgy">
            <h5>Create your Audience Account</h5>
            <h3 style="margin-top: 20px;">Register to join Virtuosica</h3>
          </div>
          <div>
            <form style="color: white; font-size: 14px;margin-top: -15px;" [formGroup]="audienceForm">
              <div>
                <div>
                  <label class="f1 required"><b>Full Name</b></label><br>
                  <input class="f2" type="text" formControlName="fullName"><br>
                  <div *ngIf="audienceForm.controls['fullName'].touched && !audienceForm.controls['fullName'].valid">
                    <span *ngIf="audienceForm.controls['fullName'].hasError('required')">Full Name is required</span>
                    <span *ngIf="audienceForm.controls['fullName'].hasError('minlength')">Min length is 3</span>
                    <span *ngIf="audienceForm.controls['fullName'].hasError('maxlength')">Max length is 25</span>
                  </div>
                  <img src="assets/a1.png">
                  <h6>Your full name will not be displayed publicly on Virtuosica</h6>
                </div>
                <div>
                  <label class="f1 required"><b>Email Address</b></label><br>
                  <input class="f2" type="text" formControlName="email">
                  <div *ngIf="audienceForm.controls['email'].touched && !audienceForm.controls['email'].valid">
                    <span *ngIf="audienceForm.controls['email'].hasError('required')">Email is required</span>
                    <span *ngIf="audienceForm.controls['email'].hasError('pattern')">Please Enter Valid Email
                      Address</span>
                  </div>
                </div>
              </div>
              <div class="ey-y">
                <label class="f1 required"><b>Password</b></label><br>
                <input matInput type="password" formControlName="password" class="f2"
                  [type]="hide ? 'password' : 'text'">
                <mat-icon matSuffix (click)="hide = !hide" style="margin-bottom: 21px;">{{ hide ? "visibility_off" :
                  "visibility" }}</mat-icon>
                <div style="width: 86%; padding: 10px; margin: 0px 11px;"
                  *ngIf="audienceForm.controls['password'].touched && !audienceForm.controls['password'].valid">
                  <span *ngIf="audienceForm.controls['password'].hasError('required')">Password is required</span>
                  <span *ngIf="audienceForm.controls['password'].hasError('pattern')">
                    Password must be between 8 and 16 characters.
                  </span>
                </div>
              </div>
              <div>
                <label class="f1 required"><b>Confirm Password</b></label><br>
                <input class="f2" type="password" formControlName="confirmPassword">
                <div
                  *ngIf="audienceForm.controls['confirmPassword'].touched && !audienceForm.controls['confirmPassword'].valid">
                  <span *ngIf="audienceForm.controls['confirmPassword'].hasError('required')">Please confirm password</span>
                  <span *ngIf="audienceForm.hasError('mustMatch', 'confirmPassword')">Password doesn't match.</span>
                </div>
              </div>
              <div class="for-gt">
                <label class="required">
                  <input class="f3" type="checkbox" formControlName="olderThan18"> I am 18 or older
                </label><span style="color: red"><a style="float: right; margin-top: 10px;">*required field</a></span>
                <div
                  *ngIf="audienceForm.controls['olderThan18'].touched && !audienceForm.controls['olderThan18'].valid">
                  <span *ngIf="audienceForm.controls['olderThan18'].hasError('required')">Please check the required
                    field</span>
                </div>
              </div>
              <div>
                <label class="required">
                  <input class="f3" type="checkbox" formControlName="tncAgreed"> I agree to the terms &
                  conditions</label>
                <div *ngIf="audienceForm.controls['tncAgreed'].touched && !audienceForm.controls['tncAgreed'].valid">
                  <span *ngIf="audienceForm.controls['tncAgreed'].hasError('required')">Please check the required
                    field</span>
                </div>
              </div>
              <div>
                <label>
                  <input class="f3" type="checkbox" id="getUpdate" formControlName="getUpdate"> Get updated from
                  Virtuosica</label>
              </div>
              <div>
                <!-- [disabled]="this.audienceForm.controls['password'].invalid || this.audienceForm.controls['email'].invalid || this.audienceForm.controls['password'].value != this.audienceForm.controls['confirmPassword'].value" -->
                <button type="button" [disabled]="this.sendConBtn"
                class="button" (click)="audienceArtist()">Send Confirmation Mail</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
