import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { SessionService } from 'src/app/service/helper/session.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { environment } from 'src/environments/environment';

declare const Swiper: any;
SwiperCore.use([Autoplay, Pagination, Navigation]);
@Component({
  selector: 'app-detail-home',
  templateUrl: './detail-home.component.html',
  styleUrls: ['./detail-home.component.scss']
})
export class DetailHomeComponent implements OnInit {
  newArtist: any;
  staffPicks: any;
  max = 5;
  rate = 0;
  isReadonly = true;
  artistData: any;
  showDropdown: boolean = false;
  newArtistData: boolean;
  staffPickData: boolean;
  artistShowData: boolean;
  previewCertificate: any;
  showStarIcon: boolean;
  hireNow: boolean = false;
  tabName = 'exitingEvent';
  userStatus: any;
  eventForm: FormGroup;
  selectedArtistId: any;
  allSelected2 = false;
  existingEventList: any;
  pageNo: number = 0;
  pageSize: number = 10;
  url = environment.hubspotNavigateUrl;

  constructor(
    private _MainService: MainService,
    private _FormBuilder: FormBuilder,
    private _NotifierService: NotifierService,
    private sessionStorage: SessionService,
    private router: Router
  ) {
    this.artistShowData = false;
    this.newArtistData = false;
    this.staffPickData = false;
    this.showStarIcon = false;
    this.eventForm = this._FormBuilder.group({
      existingEvent: ['', [Validators.required]],
    });
  }
  @ViewChild('select2')
  select2!: MatSelect;

  ngOnInit(): void {
    this.userStatus = this.sessionStorage.getSession('status');
    this.getAllNewArtist(this.pageNo,this.pageSize);
    this.getAllStaffPicks(this.pageNo,this.pageSize);
  }
  getAllNewArtist(pageNo: number, pageSize: number) {
    this.newArtistData = true;
    this._MainService.getAllNewArtist(pageNo,pageSize).then((data) => {
      if (data && data.isSuccess) {
        this.newArtistData = false;
        this.newArtist = data.data.content;
      } else {
        this.newArtistData = false;
        this._NotifierService.showError(data.message);
      }
    })
  }

  vPause() {
    const myVideo: any = document.getElementById("myVideo");
    myVideo.pause();
  }

  getAllStaffPicks(pageNo: number, pageSize: number) {
    this.staffPickData = true;
    this._MainService.getAllStaffPicks().then((data) => {
      if (data && data.isSuccess) {
        this.staffPickData = false;
        this.staffPicks = data.data;
      } else {
        this.staffPickData = false;
        this._NotifierService.showError(data.message);
      }
    })
  }

  getArtistDetailsById(id: any) {
    this.artistShowData = true;
    this._MainService.getArtistDetailsById(id).then((data) => {
      if (data && data.isSuccess) {
        this.artistShowData = false;
        this.artistData = data.data;
      } else {
        this.artistShowData = false;
        this._NotifierService.showError(data.message);
      }
    })
  }

  favoriteArtist(id: any) {
    this._MainService.addOrRemoveFavouriteArtist(id).then((data) => {
      if (data && data.isSuccess) {
        this.showStarIcon = data.data;
        this.getAllNewArtist(this.pageNo,this.pageSize);
        this.getAllStaffPicks(this.pageNo,this.pageSize);
        this._NotifierService.showSuccess(data.message);
      } else
        this._NotifierService.showError(data.message);
    })
  }

  certicatedPreview(id: any) {
    for (let certificate of this.artistData?.certificateDetails) {
      if (id === certificate.id) {
        this.previewCertificate = certificate.certificateUrl;
      }
    }
  }
  report() {
    this.showDropdown = false;
    var _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
    _hsq.push(["identify", {
      type: 'report'
    }]);
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.src = "//js.hs-scripts.com/9406878.js";
    document.body.appendChild(chatScript);
    var _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
    _hsq.push(["identify", {
      type: 'report'
    }]);
    _hsq.push(['trackPageView']);
  }
  shareProfile(id: any) {
    this.showDropdown = false;
    navigator.clipboard.writeText( this.url+'/userProfile?userId='+id)
    this._NotifierService.showSuccess('Link Copied')
  }
  hireArtist() {
    let eventData = this.eventForm.controls['existingEvent'].value
    this.sessionStorage.setSessions({ selectedArtistId: this.selectedArtistId, });
    this.router.navigate(['audienceDashboard/inviteArtist'], { queryParams: { type: 'pub', id: eventData.eventId, hired: true } });
  }
  createNewEvent() {
    this.sessionStorage.setSessions({ selectedArtistId: this.selectedArtistId, });
    this.router.navigate(['audienceDashboard/event'], { queryParams: { createEvent: true, hired: true } });
  }

  getExistingEventList() {
    this._MainService.getExistingEventList().then((data: any) => {
      if (data && data.isSuccess) {
        if (data.message == 'No records found') {
          let data = {
            eventName: 'No Records Found'
          }
          this.existingEventList.push(data)
        } else
          this.existingEventList = data.data;
      } else {
        this._NotifierService.showError(data.message);
      }
    })
  }
  optionClick2() {
    let newStatus = true;
    this.select2.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected2 = newStatus;
  }
  ngOnDestroy() {
    this.showDropdown = false;
  }

  sendMessage(id: any,name:any) {
    this.router.navigate([`/audienceDashboard/message`], {
      queryParams: {
        'id': id,
        'name': name,
      }
    })
  }
}
