import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountVerifyErrorComponent } from './module/artist-signup/account-verify-error/account-verify-error.component';
import { AccountVerifyComponent } from './module/artist-signup/account-verify/account-verify.component';
import { ArtistSignupComponent } from './module/artist-signup/artist-signup.component';
import { ChangeEmailVerifyComponent } from './module/artist-signup/change-email-verify/change-email-verify.component';
import { JoinUsComponent } from './module/join-us/join-us.component';

const routes: Routes = [
  { path: '', component: JoinUsComponent },
  { path: 'artist-signup', component: ArtistSignupComponent },
  { path: 'accountVerify/:token', component: AccountVerifyComponent },
  { path: 'accountVerifyError', component: AccountVerifyErrorComponent },
  { path: 'changeEmailVerification', component: ChangeEmailVerifyComponent },
  {
    path: 'login',
    loadChildren: () =>
      import('./module/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./module/after-login/profile.module').then(
        (m) => m.ProfileModule
      ),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./module/audience/audience/audience.module').then(
        (m) => m.AudienceModule
      ),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
