<!-- main section start here -->
<div id="layoutSidenav_content" class="footer">
  <main class="mb-5 ">
    <div class="container-fluid pr-4 pl-1">
      <div class="setting-content-wrapper">
        <section  *ngIf="eventData && !showBookEvent && !reviewEvent && !editEvent">
          <div class="hgnb bot-m">
            <div class="nvhi"><span>Create Event</span></div>
          </div>
          <div class="">
            <div class="are-you">
              <h4>Are you ready to book your first event?</h4>
              <p>You can create your own events and hire artists.</p>
              <button type="button" [disabled]="userStatus == 'PENDING_DELETE'" class="btn defrqqa"
                (click)="showBookEvent = true">Create Event</button>
            </div>
          </div>
        </section>

        <section *ngIf="showBookEvent && !reviewEvent && !editEvent">
          <!-- Second screen start here -->
          <div class="hgnb bot-m">
            <div class="nvhi"><span>Create Event</span></div>
          </div>
          <div class="mt-5">
            <div class="cr-t">
              <div class="uhgtr">
                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link" [ngClass]="secOneStatus">Section 1 </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" [ngClass]="secTwoStatus">Section 2 </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" [ngClass]="secThreeStatus">Section 3 </a>
                  </li>
                </ul>
              </div>
              <!-- Tab panes -->
              <div class="sthutr mt-5">
                <div class="tab-content">
                  <!-- section 1 -->
                  <div *ngIf="tabName == 'sectionOne'" class="tab-pane active">
                    <div class="vent-deta">
                      <h4 class="enter-event">Enter Event Details</h4>
                      <form [formGroup]="eventForm">
                        <div class="form-group">
                          <label for="EventTitle">Event Title</label>
                          <input type="text" formControlName="eventTitle" class="form-control" autocomplete="off"
                            id="EventTitle">
                        </div>
                        <div class="form-group">
                          <label for="eventtype">Event Type</label>
                          <div>
                            <mat-form-field class="multiSelect">
                              <mat-select class="pl-3" formControlName="eventType" #select2 multiple>
                                <div class="ml-3 pt-1">
                                  <mat-checkbox [(ngModel)]="allSelected2" [ngModelOptions]="{standalone: true}"
                                    (change)="toggleAllSelection2()">Select All
                                  </mat-checkbox>
                                </div>
                                <mat-option style="z-index: 10000000;" (click)="optionClick2()"
                                  *ngFor="let videoquality of eventList" [value]="videoquality">
                                  {{videoquality}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="genres">Genres</label>
                          <mat-form-field class="example-chip-list" appearance="fill">
                            <mat-chip-list #chipList aria-label="Fruit selection">
                              <mat-chip *ngFor="let genres of searchgen" (removed)="removegen(genres)">
                                {{genres}}
                                <button matChipRemove>
                                  <mat-icon>cancel</mat-icon>
                                </button>
                              </mat-chip>
                              <input style="border: none; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                                #fruitInput [formControl]="genCtrl" [matAutocomplete]="auto"
                                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="addgen($event)">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedgen($event)">
                              <mat-option *ngFor="let gen of generList" [value]="gen">
                                {{gen}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                  <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start typing to select your genres</small>
                  <div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="act">Size of Act</label>
                          <div>
                            <mat-form-field class="multiSelect">
                              <mat-select class="pl-3" formControlName="size" #select multiple>
                                <div class="select-all ml-3">
                                  <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                                    (change)="toggleAllSelection()">Select All
                                  </mat-checkbox>
                                </div>
                                <mat-option (click)="optionClick()" *ngFor="let bandsize of actSizeList"
                                  [value]="bandsize">{{bandsize}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <button type="submit" class="btn defrqqa edcae" (click)="eventFormSubmit()">Next</button>
                      </form>
                    </div>
                  </div>
                  <!-- Section 2 -->
                  <div *ngIf="tabName == 'sectionTwo'" class="tab-pane active">
                    <div class="vent-deta">
                      <h4 class="enter-event">Enter Event Details</h4>
                      <form [formGroup]="eventDetailForm">
                        <div class="row mb-4">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="Date">Start Date</label>
                              <input type="date" [min]="today" autocomplete="off" class="form-control"
                                formControlName="startDate" id="Date">
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="Date">End Date</label>
                              <input type="date" [min]="today" autocomplete="off" class="form-control"
                                formControlName="endDate" id="Date">
                            </div>
                          </div>
                        </div>

                        <div class="row mb-4">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="time">Start Time</label>
                              <timepicker [(ngModel)]="startTime" [formControl]="startTimectrl"></timepicker>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="time2">End Time</label>
                              <timepicker [(ngModel)]="endTime" [formControl]="endTimectrl"></timepicker>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-4">
                          <div class="col-lg-6">
                            <div class="form-group pou">
                              <label for="Budget">Budget</label>
                              <p class="ratoj">$</p>
                              <input type="number" autocomplete="off" class="form-control" formControlName="budget"
                                id="Budget" style="padding: 0px 30px;">
                            </div>
                          </div>
                        </div>
                        <button type="submit" class="btn hftrdd" (click)="eventDetailFormSubmit('back')">Back</button>
                        <button type="submit" class="btn defrqqa edcae"
                          (click)="eventDetailFormSubmit('next')">Next</button>
                      </form>
                    </div>
                  </div>

                  <!-- Section 3 -->
                  <div *ngIf="tabName == 'sectionThree'" class="tab-pane active">
                    <div class="vent-deta">
                      <h4 class="enter-event">Event Set Up</h4>
                      <div class="fir-tab">
                        <p class="ve-nue">Venue </p>
                        <ul class="nav nav-tabs" role="tablist">
                          <li class="nav-item" (click)="toggle('live_streamed')">
                            <input type=radio id="rdo1" checked class="radio-input" name="radio-group">
                            <label for="rdo1" class="radio-label"> <span class="radio-border"></span> Live Streamed
                            </label>
                          </li>
                          <li class="nav-item" (click)="toggle('onsite')">
                            <input type=radio id="rdo2" class="radio-input" name="radio-group">
                            <label for="rdo2" class="radio-label"><span class="radio-border"></span>Onsite</label>
                          </li>
                          <li class="nav-item" (click)="toggle('hybrid')">
                            <input type=radio id="rdo3" class="radio-input" name="radio-group">
                            <label for="rdo3" class="radio-label"><span class="radio-border"></span>Hybrid</label>
                          </li>
                        </ul>
                        <div class="tab-content mt-4">
                          <div *ngIf="showLiveSection">
                            <form [formGroup]="eventLiveStreamedForm">
                              <div class="form-group">
                                <label for="preferred">Preferred Devices</label>
                                <div>
                                  <mat-form-field class="multiSelect">
                                    <mat-select class="pl-3" formControlName="preferredDevices" #select3 multiple>
                                      <div class="ml-3 pt-1">
                                        <mat-checkbox [(ngModel)]="allSelected3" [ngModelOptions]="{standalone: true}"
                                          (change)="toggleAllSelection3()">Select All
                                        </mat-checkbox>
                                      </div>
                                      <mat-option (click)="optionClick3()" *ngFor="let item of preferedList"
                                        [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="description">Event Description</label>
                                <textarea class="form-control hgtaa" autocomplete="off" formControlName="eventDescp"
                                  id="description" rows="4"></textarea>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"
                                  formControlName="liveStream">
                                <label class="form-check-label" for="defaultCheck1">
                                  I will not be live streaming or distributing recordings of this event
                                </label>
                              </div>

                            </form>
                          </div>
                          <div *ngIf="onsite && !showLiveSection && !hybrid">
                            <form [formGroup]="eventOnSiteForm">
                              <div class="form-group">
                                <label for="production">Production Capabilities</label>
                                <textarea class="form-control hgtaa" formControlName="production" id="production"
                                  autocomplete="off" rows="4"></textarea>
                              </div>
                              <div class="form-group pou">
                                <label for="street">Street Address</label>
                                <p class="ratoj"><i class="fa fa-map-marker" aria-hidden="true"></i></p>
                                <input type="text" autocomplete="off" class="form-control" id="street"
                                  formControlName="streetAddress" style="padding: 0px 33px;">
                              </div>
                              <div class="form-group">
                                <label for="performance">Performance Environment</label>
                                <input type="text" autocomplete="off" class="form-control" id="performance"
                                  formControlName="performanceEnv">
                              </div>
                              <div class="form-group">
                                <label for="description">Event Description</label>
                                <textarea class="form-control hgtaa" autocomplete="off" id="description"
                                  formControlName="eventDescription" rows="4"></textarea>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"
                                  formControlName="liveStream">
                                <label class="form-check-label" for="defaultCheck1">
                                  I will not be live streaming or distributing recordings of this event
                                </label>
                              </div>
                            </form>
                          </div>
                          <div *ngIf="hybrid && !onsite && !showLiveSection">
                            <form [formGroup]="eventHybridForm">
                              <div class="form-group">
                                <label for="production">Production Capabilities</label>
                                <textarea class="form-control hgtaa" formControlName="prodCapabilities" id="production"
                                  rows="4" autocomplete="off"></textarea>
                              </div>
                              <div class="form-group pou">
                                <label for="street">Street Address</label>
                                <p class="ratoj"><i class="fa fa-map-marker" aria-hidden="true"></i></p>
                                <input type="text" class="form-control" formControlName="streetAddress" id="street"
                                  style="padding: 0px 33px;" autocomplete="off">
                              </div>
                              <div class="form-group">
                                <label for="performance">Performance Environment</label>
                                <input type="text" class="form-control" id="performance"
                                  formControlName="performanceEnv" autocomplete="off">
                              </div>
                              <div class="form-group">
                                <label for="description">Event Description</label>
                                <textarea class="form-control hgtaa" id="description" formControlName="eventDescription"
                                  rows="4" autocomplete="off"></textarea>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"
                                  formControlName="liveStream">
                                <label class="form-check-label" for="defaultCheck1">
                                  I will not be live streaming or distributing recordings of this event
                                </label>
                              </div>
                            </form>
                          </div>
                          <button type="submit" class="btn hftrdd" (click)="eventSetUpSubmit('Back')">Back</button>
                          <button type="submit" class="btn hftrdd" (click)="eventSetUpSubmit('saveDraft')">Save as
                            Draft</button>
                          <button type="submit" class="btn defrqqa edcae"
                            (click)="eventSetUpSubmit('reviewEvent')">Review Event</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        <section *ngIf="reviewEvent == true && !editEvent">
          <div class="hgnb bot-m">
            <div class="nvhi">
              <span class="utg"> Event Preview</span>
              <div class="erfg">
                <a class="eijh" style="cursor: pointer;" (click)="reviewEventSubmit('editEvent')">Edit Event <img
                    src="assets/dashboard/et.png"></a>
              </div>
            </div>
          </div>
          <div class="sthutr mt-5">
            <div class="vent-deta">
              <div class="rujghyt">
                <div class="mb-4 breakLine">
                  <p class="f700 mb-2">Event Title</p>
                  <p>{{eventForm.value.eventTitle}}</p>
                </div>
                <div class="mb-4">
                  <p class="f700 mb-2">Event Type</p>
                  <p>{{eventForm.value.eventType}}</p>
                </div>

                <div class="mb-4 ">
                  <p class="f700 mb-2">Genre</p>
                  <div class="deggt">
                    <p class="mb-2 ml-2">{{eventForm.value.genres}}</p>
                  </div>
                </div>

                <div class="mb-4 ">
                  <p class="f700 mb-2">Size of Act</p>
                  <div class="deggt">
                    <p class="mb-2 ml-2">{{eventForm.value.size}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 mb-4 ">
                    <p class="f700 mb-2"><i class="fa fa-calendar-o rfgtqy mr-2" aria-hidden="true"></i>Start Date</p>
                    <p class="gry">{{eventDetailForm.value.startDate}}</p>
                  </div>
                  <div class="col-6 mb-4 ">
                    <p class="f700 mb-2"><i class="fa fa-calendar-o rfgtqy mr-2" aria-hidden="true"></i>End Date</p>
                    <p class="gry">{{eventDetailForm.value.endDate}}</p>
                  </div>
                </div>
                <div class="gftrhh">
                  <div class="mb-4 fgt1">
                    <p class="f700 mb-2"> <i class="fa fa-clock-o" aria-hidden="true"></i> Start Time</p>
                    <p class="gry">{{startTime2}}</p>
                  </div>
                  <div class="mb-4 ">
                    <p class="f700 mb-2"> <i class="fa fa-clock-o" aria-hidden="true"></i> End Time</p>
                    <p class="gry">{{endTime2}}</p>
                  </div>
                </div>

                <div class="mb-4 ">
                  <p class="f700 mb-2"> <i class="fa fa-usd" aria-hidden="true"></i> Budget</p>
                  <p class="gry">${{eventDetailForm.value.budget}} </p>
                </div>

                <div class="mb-4 jguhhpp"
                  *ngIf="eventOnSiteForm.value.streetAddress || eventHybridForm.value.streetAddress ">
                  <p class="f700 mb-2"> <i class="fa fa-map-marker" aria-hidden="true"></i> Location</p>
                  <p class="gry" *ngIf="eventOnSiteForm.value.streetAddress"> {{ eventOnSiteForm.value.streetAddress}}
                  </p>
                  <p class="gry" *ngIf="eventHybridForm.value.streetAddress"> {{ eventHybridForm.value.streetAddress}}
                  </p>
                </div>

                <div class="mb-4">
                  <p class="f700 mb-2">Venue</p>
                  <p>{{ status }}</p>
                </div>

                <div class="mb-4 breakLine" *ngIf="eventOnSiteForm.value.production || eventHybridForm.value.prodCapabilities">
                  <p class="f700 mb-2">Preferred Production Capabilities</p>
                  <p class="gry" *ngIf="eventOnSiteForm.value.production"> {{
                    eventOnSiteForm.value.production}} </p>
                  <p class="gry" *ngIf="eventHybridForm.value.prodCapabilities"> {{
                    eventHybridForm.value.prodCapabilities}} </p>
                </div>

                <div class="mb-4" *ngIf="eventLiveStreamedForm.value.preferredDevices">
                  <p class="f700 mb-2">Preferred Devices</p>
                  <div class="deggt">
                    <p class="mb-2 ml-2">{{eventLiveStreamedForm.value.preferredDevices}}</p>
                  </div>
                </div>

                <div class="mb-4 breakLine">
                  <p class="f700 mb-2">Event Description</p>
                  <p class="mb-2 ml-2" *ngIf="eventLiveStreamedForm.value.eventDescp">
                    {{eventLiveStreamedForm.value.eventDescp}}</p>
                  <p class="gry" *ngIf="eventOnSiteForm.value.eventDescription">
                    {{eventOnSiteForm.value.eventDescription}}</p>
                  <p class="gry" *ngIf="eventHybridForm.value.eventDescription">
                    {{eventHybridForm.value.eventDescription}}</p>
                </div>
                <div class="mb-4">
                  <h5>Special Requirements</h5>
                  <textarea class="form-control hgtaa" id="special" [(ngModel)]="requirements" rows="4"
                    autocomplete="off"></textarea>
                </div>
                <div class="mb-4">
                  <p class="f700 mb-2">Select Status</p>
                  <p class="gry">{{eventOnSiteForm.value.liveStream}}</p>
                </div>
                <div *ngIf="buttonHandler == false" class="mb-4">
                  <label class='toggle-label'>
                    <input type='checkbox' />
                    <span class='back'>
                      <span class='toggle'></span>
                      <span class='label off' (click)="statusToggle('PRIVATE')">Private</span>
                      <span class='label on' (click)="statusToggle('PUBLIC')">Public</span>
                    </span>
                  </label>
                </div>
                <div *ngIf="buttonHandler" class="mb-4">
                  <label class='toggle-label' style="width: 150px;">
                    <input type='checkbox' />
                    <span class='back'>
                      <span class='toggle' style="width: 100%;"></span>
                      <span class='label on' style="left: 39px;" (click)="statusToggle('PRIVATE')">Private</span>
                    </span>
                  </label>
                </div>
                <button type="submit" class="btn hftrdd" (click)="reviewEventSubmit('back')">Back</button>
                <button [disabled]="this.postcreatebtn" type="submit" class="btn defrqqa edcae" (click)="saveEventDetails()">Post </button>
              </div>
            </div>
          </div>
        </section>

        <!-- Edit screen start here -->
        <section *ngIf="editEvent">
          <div class="hgnb bot-m">
            <div class="nvhi">
              <span class="utg"> Edit Event </span>
            </div>
          </div>
          <div class="sthutr mt-5">
            <div class="vent-deta">
              <div class="rujghyt">
                <form>
                  <div class="form-group mb-4">
                    <h5>Special Requirements</h5>
                    <textarea class="form-control hgtaa" id="special" autocomplete="off" [(ngModel)]="requirements"
                      rows="4"></textarea>
                  </div>
                  <form [formGroup]="eventForm">
                    <div class=" form-group mb-4">
                      <label for="Evetle">Event Title</label>
                      <input type="text" class="form-control" id="Evetle" autocomplete="off"
                        formControlName="eventTitle">
                    </div>

                    <div class="form-group mb-4">
                      <label for="Eveyp">Event Type</label>
                      <div>
                        <mat-form-field class="multiSelect">
                          <mat-select class="pl-3" formControlName="eventType" #select2 multiple>
                            <div class="ml-3 pt-1">
                              <mat-checkbox [(ngModel)]="allSelected2" [ngModelOptions]="{standalone: true}"
                                (change)="toggleAllSelection2()">Select All
                              </mat-checkbox>
                            </div>
                            <mat-option style="z-index: 10000000;" (click)="optionClick2()"
                              *ngFor="let videoquality of eventList" [value]="videoquality">
                              {{videoquality}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="form-group mb-4 ">
                      <label class="f700 mb-2">Genre</label>
                      <mat-form-field class="example-chip-list" appearance="fill">
                        <mat-chip-list #chipList aria-label="Fruit selection">
                          <mat-chip *ngFor="let genres of searchgen" (removed)="removegen(genres)">
                            {{genres}}
                            <button matChipRemove>
                              <mat-icon>cancel</mat-icon>
                            </button>
                          </mat-chip>
                          <input style="border: none; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                            #fruitInput [formControl]="genCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="addgen($event)">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedgen($event)">
                          <mat-option *ngFor="let gen of generList" [value]="gen">
                            {{gen}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                  <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start typing to select your genres</small>
                </div>
                    <div class="form-group mb-4 ">
                      <label class="f700 mb-2 siy">Size of Act</label>
                      <div>
                        <mat-form-field class="multiSelect">
                          <mat-select class="pl-3" formControlName="size" #select multiple>
                            <div class="select-all ml-3">
                              <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                                (change)="toggleAllSelection()">Select All
                              </mat-checkbox>
                            </div>
                            <mat-option (click)="optionClick()" *ngFor="let bandsize of actSizeList" [value]="bandsize">
                              {{bandsize}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </form>

                  <form [formGroup]="eventDetailForm">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="Date">Start Date</label>
                          <input type="date" [min]="today" autocomplete="off" class="form-control"
                            formControlName="startDate" id="Date">
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="Date">End Date</label>
                          <input type="date" [min]="today" autocomplete="off" class="form-control"
                            formControlName="endDate" id="Date">
                        </div>
                      </div>
                    </div>
                    <div class="form-group gftrhh ">
                      <div class="mb-4 fgt1">
                        <label class="f700 mb-2 tnm">Start Time</label>
                        <timepicker [(ngModel)]="startTime" [formControl]="startTimectrl"></timepicker>
                      </div>
                      <div class="mb-4 ml-2 fgt1">
                        <label class="f700 mb-2 edvf">End Time</label>
                        <timepicker [(ngModel)]="endTime" [formControl]="endTimectrl"></timepicker>
                      </div>
                    </div>
                    <div class="form-group gftrhh ">
                      <div class="mb-4 fgt1">
                        <label class="f700 mb-2 nujm">Budget</label>
                        <p class="ratoj"><i class="fa fa-usd" aria-hidden="true"></i></p>
                        <input type="number" autocomplete="off" class="form-control edww" id="nujm"
                          formControlName="budget" style="padding: 0px 30px;">
                      </div>
                    </div>
                  </form>
                  <div class="fir-tab">
                    <p class="ve-nue">Venue </p>
                    <ul class="nav nav-tabs" role="tablist">
                      <li class="nav-item" (click)="toggle('live_streamed')"
                        [ngClass]="status == 'LIVE_STREAMED'? 'active':'' ">
                        <input type=radio id="rdo1" checked class="radio-input" name="radio-group">
                        <label for="rdo1" class="radio-label"> <span class="radio-border"></span> Live Streamed </label>
                      </li>
                      <li class="nav-item" (click)="toggle('onsite')" [ngClass]="status == 'ONSITE'? 'active':'' ">
                        <input type=radio id="rdo2" class="radio-input" name="radio-group">
                        <label for="rdo2" class="radio-label"><span class="radio-border"></span>Onsite</label>
                      </li>
                      <li class="nav-item" (click)="toggle('hybrid')" [ngClass]="status == 'HYBRID'? 'active':'' ">
                        <input type=radio id="rdo3" class="radio-input" name="radio-group">
                        <label for="rdo3" class="radio-label"><span class="radio-border"></span>Hybrid</label>
                      </li>
                    </ul>
                    <div class="tab-content mt-4">
                      <div [ngClass]="status == 'live_streamed'? 'active' : ''" *ngIf="showLiveSection">
                        <form [formGroup]="eventLiveStreamedForm">
                          <div class="form-group">
                            <label for="preferred">Preferred Devices</label>
                            <div>
                              <mat-form-field class="multiSelect">
                                <mat-select class="pl-3" formControlName="preferredDevices" #select3 multiple>
                                  <div class="ml-3 pt-1">
                                    <mat-checkbox [(ngModel)]="allSelected3" [ngModelOptions]="{standalone: true}"
                                      (change)="toggleAllSelection3()">Select All
                                    </mat-checkbox>
                                  </div>
                                  <mat-option (click)="optionClick3()" *ngFor="let item of preferedList" [value]="item">
                                    {{item}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="description">Event Description</label>
                            <textarea class="form-control hgtaa" autocomplete="off" formControlName="eventDescp"
                              id="description" rows="4"></textarea>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"
                              formControlName="liveStream">
                            <label class="form-check-label" for="defaultCheck1">
                              I will not be live streaming or distributing recordings of this event
                            </label>
                          </div>
                        </form>
                      </div>
                      <div [ngClass]="status == 'onsite'? 'active' : ''" *ngIf="onsite && !showLiveSection && !hybrid">
                        <form [formGroup]="eventOnSiteForm">
                          <div class="form-group">
                            <label for="production">Production Capabilities</label>
                            <textarea class="form-control hgtaa" formControlName="production" id="production" rows="4"
                              autocomplete="off"></textarea>
                          </div>
                          <div class="form-group pou">
                            <label for="street">Street Address</label>
                            <p class="ratoj"><i class="fa fa-map-marker" aria-hidden="true"></i></p>
                            <input type="text" class="form-control" id="street" formControlName="streetAddress"
                              autocomplete="off">
                          </div>
                          <div class="form-group">
                            <label for="performance">Performance Environment</label>
                            <input type="text" class="form-control" id="performance" formControlName="performanceEnv"
                              autocomplete="off">
                          </div>
                          <div class="form-group">
                            <label for="description">Event Description</label>
                            <textarea class="form-control hgtaa" id="description" formControlName="eventDescription"
                              rows="4" autocomplete="off"></textarea>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"
                              formControlName="liveStream">
                            <label class="form-check-label" for="defaultCheck1">
                              I will not be live streaming or distributing recordings of this event
                            </label>
                          </div>
                        </form>
                      </div>
                      <div [ngClass]="status == 'hybrid'? 'active' : ''" *ngIf="hybrid && !onsite && !showLiveSection">
                        <form [formGroup]="eventHybridForm">
                          <div class="form-group">
                            <label for="production">Production Capabilities</label>
                            <textarea class="form-control hgtaa" autocomplete="off" formControlName="prodCapabilities"
                              id="production" rows="4"></textarea>
                          </div>
                          <div class="form-group pou">
                            <label for="street">Street Address</label>
                            <p class="ratoj"><i class="fa fa-map-marker" aria-hidden="true"></i></p>
                            <input type="text" class="form-control" formControlName="streetAddress" id="street"
                              autocomplete="off">
                          </div>
                          <div class="form-group">
                            <label for="performance">Performance Environment</label>
                            <input type="text" class="form-control" id="performance" formControlName="performanceEnv"
                              autocomplete="off">
                          </div>
                          <div class="form-group">
                            <label for="description">Event Description</label>
                            <textarea class="form-control hgtaa" id="description" formControlName="eventDescription"
                              rows="4" autocomplete="off"></textarea>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"
                              formControlName="liveStream">
                            <label class="form-check-label" for="defaultCheck1">
                              I will not be live streaming or distributing recordings of this event
                            </label>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <p class="f700 mb-2">Select Status</p>
                  </div>
                  <div *ngIf="buttonHandler == false" class="mb-4">
                    <label class='toggle-label'>
                      <input type='checkbox' />
                      <span class='back'>
                        <span class='toggle'></span>
                        <span class='label off'>Private</span>
                        <span class='label on'>Public</span>
                      </span>
                    </label>
                  </div>
                  <div *ngIf="buttonHandler" class="mb-4">
                    <label class='toggle-label' style="width: 150px;">
                      <input type='checkbox' />
                      <span class='back'>
                        <span class='toggle' style="width: 100%;"></span>
                        <span class='label on' style="left: 39px;" (click)="statusToggle('PRIVATE')">Private</span>
                      </span>
                    </label>
                  </div>
                  <button type="submit" class="btn hftrdd" (click)="editEventSubmit('back')">Back</button>
                  <button [disabled]="this.postcreatebtn" type="submit" class="btn defrqqa edcae" (click)="saveEventDetails()">Post </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</div>
