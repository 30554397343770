import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { MainService } from 'src/app/service/main.service';
import { environment } from 'src/environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
declare const Stripe: any;

@Component({
  selector: 'app-artist-event-details',
  templateUrl: './artist-event-details.component.html',
  styleUrls: ['./artist-event-details.component.scss'],
})
export class ArtistEventDetailsComponent implements OnInit {
  eventList: any;
  // upcomingEventList: any;
  // pendingEventList: any;
  // proposedEventList: any;
  // invitedEventList: any;
  // completedEventList: boolean = false;
  // completedMyPlaylist: any[] = [];
  eventDetails: any;
  pageNo: number = 0;
  pageSize: number = 5;
  newEvent: any[] = [];
  proposal: any = this.route.queryParams;
  currentPage = 0;
  invitedList: any;
  type: any = 'all';
  selectedProposalData: any;
  selectTab: any = 'mainTab';
  declineProposalModal: boolean = false;
  finishBookingModal: boolean = false;
  newFee: any;
  setList: boolean = false;
  gigBooked: boolean = false;
  remark: any = 'The Fee is out of my budget';
  stripe = Stripe(environment.stripePublicKey);
  selectedProposalId: any;
  buttonDisable: boolean = true;
  completeHiringModal: boolean = false;
  selectedEventId: any;
  proposalDraftForm: FormGroup;
  currentUserId: any;
  proposalsList: any;
  tabName = 'per_hour';
  otherReason: boolean = false;
  selectedEventCreatedBy: any;
  editProposalValue: any = {
    estimateHours: '',
    proposalId: '',
    hourlyFee: '',
    paidOption: 'per_hour',
    totalFee: '',
  };
  acceptDeclineStatus: any;
  proposalType: any;
  proposalOwnerDeclineReason: any;
  otherReasonText: any;
  paymentMessage: any;
  paymentStatus: boolean = false;
  paymentFailed: boolean = false;
  eventsData: boolean = false;
  eventId: any;
  goLive: any[] = [];
  selectedEventNo: any;
  confirmCancelProposal: boolean = false;
  cancelReason: any;
  confirmCancelEvent: boolean = false;
  userStatus: any;
  proposalTitle: any = [];
  ownerId: any;
  interval: any;
  hubspotEmail: any;
  showMessage: boolean = false;
  processingPayment: boolean = false;
  TimeZone = this.sessionStorage.getSession('timeZone');
  userRole = this.sessionStorage.getSession('roles');
  // specificEventData: any = this.route.queryParams;
  specificEvent: boolean = false;
  keyWord: any;
  // showProList: boolean = false;
  // selectAllproList: boolean = false;
  // proListIds: any[] = [];
  // proListData: any;
  // proEventData: any;
  // proEventList: any[] = [];
  // repertoireData: any[] = [];
  // myPlaylistDetails: any;
  // songList: any;
  // proTab: string ='addedSong'
  // selectedProEvent: any;
  // existingPlaylist: boolean = false;
  // createNewProPlaylist: boolean = false;
  constructor(
    private MainService: MainService,
    private NotifierService: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private sessionStorage: SessionService,
    private observer: BreakpointObserver
  ) {
    this.eventsData = false;
    this.proposalDraftForm = this.fb.group({
      hourlyFee: ['', [Validators.required]],
      totalFee: ['', [Validators.required]],
      additionalNotes: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.userStatus = this.sessionStorage.getSession('status');
    this.currentUserId = this.sessionStorage.getSession('userId');
    this.paymentMessage = this.route.snapshot.queryParams.success;
    this.hubspotEmail = this.sessionStorage.getSession('email');
    if (this.route.snapshot.queryParams.refresh) {
      this.router.navigate(['/ArtistDashboard/artistEventDetail'])
      this.interval = setInterval(() => {
        location.reload();
      }, 1500);
    }
    if (this.paymentMessage == 'false') {
      this.paymentFailed = true;
    } else if (this.paymentMessage == 'true') {
      this.selectedEventId = this.sessionStorage.getSession('selectedEventId');
      this.selectedProposalId = this.sessionStorage.getSession('selectedProposalId');
      this.paymentStatus = true;
      this.selectTab = 'paymentSuccess';
      this.setPaymentStatus();
      this.markEventHiringInProgress(this.selectedEventId);
    }
    let propDetail = this.proposal['_value'];
    if (propDetail.eventType) {
      var eId = parseInt(this.proposal._value.eventId)
      var pId = parseInt(this.proposal._value.proposalId)
      this.MainService.getEventDetailById(eId).then((data) => {
        if (data && data.isSuccess) {
          this.eventDetails = data.data;
        } else {
          this.NotifierService.showError(data.message);
        }
        if (this.proposal._value.eventId || this.proposal._value.proposalId)
          this.router.navigate(['/ArtistDashboard/artistEventDetail'])
      });
      if (propDetail.eventType == 'PROPOSAL_SUBMITTED' || propDetail.eventType == 'CONTRACT_ACCEPTED' || propDetail.eventType == 'PENDING_PAYMENT'
        || propDetail.eventType == 'CONTRACT_CANCELLED' || propDetail.eventType === "CONTRACT_DECLINED") {
        this.selectedProposal(pId, 'meAsClient');
        this.selectedEventCreatedBy = this.sessionStorage.getSession('userId');
      }
      else if (propDetail.eventType == 'PROPOSAL_ACCEPTED' || propDetail.eventType == 'PROPOSAL_DECLINED' || propDetail.eventType == 'EVENT_CANCELLED') {
        this.acceptDeclineStatus = 'PROPOSAL_OWNER_ACCEPTED';
        this.selectedProposal(pId, 'clientAccepted');
      }
    }



    // if (propDetail.eventType == 'PROPOSAL_ACCEPTED' || propDetail.eventType == 'PROPOSAL_DECLINED') {
    //   this.selectTab = 'individualProposalView';
    //   this.acceptDeclineStatus = 'PROPOSAL_OWNER_ACCEPTED';
    //   this.selectedProposal(propDetail.proposalId, 'clientAccepted');
    // }
    else if (this.proposal && this.proposal._value.eventId && this.proposal._value.proposalId && this.proposal._value.createdBy) {
      this.specificEvent = true
      let body = {
        'id': this.proposal._value.eventId,
        'eventCreatedBy': this.proposal._value.createdBy,
        'proposalId': this.proposal._value.proposalId
      }
      this.openRockBand(body);
      if (this.currentUserId == this.proposal._value.createdBy || this.proposal._value.type != 'CONTRACT_ACCEPTED')
        this.selectedProposal(this.proposal._value.proposalId, 'meAsClient');
      else
        this.selectedProposal(this.proposal._value.proposalId, 'clientAccepted');
      this.router.navigate(['/ArtistDashboard/artistEventDetail'])
    }
    else if (this.proposal && this.proposal._value.eventId && this.proposal._value.goLive == 'true') {
      this.searchEvent(this.proposal._value.eventId);
      this.router.navigate(['/ArtistDashboard/artistEventDetail'])
    }
    else
      this.getEventList(this.pageNo, this.pageSize, this.type);
  }

  ngDoCheck() {
    if (this.proposal._value.eventId || this.proposal._value.proposalId)
      this.ngOnInit()
  }

  ngAfterViewInit() {
    // this.observer.observe(['(max-width: 767px)']).subscribe((res) => {
    //   if (res.matches) {
    //     this.showMessage = true;
    //   } else {
    //     this.showMessage = false;
    //   }
    // });
  }

  checkCondition() {
    this.confirmCancelProposal = !this.confirmCancelProposal;
  }

  enableClick() {
    var box = document.getElementById('termsCondition');
    if (box && box.scrollTop >= 4700) this.buttonDisable = false;
  }

  openRockBand(event: any) {
    this.selectedEventId = event.id;
    this.selectedEventCreatedBy = event.eventCreatedBy;
    if (this.currentUserId == event.eventCreatedBy) {
      this.MainService.getEventDetailById(event.id).then((data) => {
        if (data && data.isSuccess) {
          this.eventDetails = data.data;
          if (this.specificEvent == false)
            this.selectTab = 'eventDetailTab';
          // this.NotifierService.showSuccess(data.message);
        } else {
          this.NotifierService.showError(data.message);
        }
      });
    } else {
      if (event.proposalId)
        this.selectedProposal(event.proposalId, 'clientAccepted');
      else
        this.router.navigate(['/ArtistDashboard/gigBoard'], {
          queryParams: { eventId: event.id },
        });
    }
  }

  selectedProposal(id: any, type?: string) {
    if (this.proposal && this.proposal._value.eventId && this.proposal._value.proposalId) {
      this.selectTab = 'individualProposalView'
    }
    this.proposalType = type;
    this.selectedProposalId = id;
    this.MainService.getEditProposalDraft(id).then((data) => {
      if (data && data.isSuccess) {
        if (type && type == 'clientAccepted') {
          this.acceptDeclineStatus = 'PROPOSAL_OWNER_ACCEPTED';
          this.selectTab = 'individualProposalView';
        } else if (type && type == 'meAsClient') {
          if (data.data.proposalStatus == 'EVENT_OWNER_ACCEPTED')
            this.selectTab = 'pendingProposalOwnerSideView';
          else if (data.data.proposalStatus == 'PROPOSAL_OWNER_ACCEPTED' || data.data.proposalStatus == 'PERFORMANCE_STARTED' || data.data.proposalStatus == 'PERFORMANCE_ENDED')
            this.selectTab = 'proceedToPayment';
          else this.selectTab = 'individualProposalView';
        }
        this.selectedProposalData = data.data;
        this.selectedEventId = this.selectedProposalData.eventId;
        this.editProposalValue.estimateHours = this.selectedProposalData.estimateHours;
        this.proposalDraftForm.controls.hourlyFee.setValue(this.selectedProposalData.hourlyFee);
        this.proposalDraftForm.controls.totalFee.setValue(this.selectedProposalData.totalFee);
      } else {
        this.NotifierService.showError(data.message);
      }
    });
  }

  paidOptionSelection(type: string) {
    this.tabName = type;
    this.editProposalValue.paidOption = type;
  }

  calculateAmount() {
    if (this.tabName == 'per_hour')
      this.proposalDraftForm.controls.totalFee.setValue(
        this.proposalDraftForm.controls['hourlyFee'].value *
        this.editProposalValue.estimateHours
      );
    this.editProposalValue.hourlyFee =
      this.proposalDraftForm.controls['hourlyFee'].value;
    this.editProposalValue.totalFee =
      this.proposalDraftForm.controls['totalFee'].value;
  }
  editContract(type: any) {
    if (type == 'cancel') {
      if (this.selectedProposalData.proposalStatus == 'EVENT_OWNER_ACCEPTED')
        this.selectTab = 'pendingProposalOwnerSideView';
      else this.selectTab = 'individualProposalView';
    } else if (type == 'save') {
      this.editProposalValue.proposalId = this.selectedProposalId;
      this.MainService.EditContract(this.editProposalValue).then((data) => {
        if (data && data.isSuccess) {
          if (
            this.selectedProposalData.proposalStatus == 'EVENT_OWNER_ACCEPTED'
          )
            this.selectTab = 'pendingProposalOwnerSideView';
          else this.selectTab = 'individualProposalView';
          this.selectedProposal(this.selectedProposalId, 'meAsClient');
          this.NotifierService.showSuccess(data.message);
        } else {
          this.NotifierService.showError(data.message);
        }
      });
    }
  }

  declineReason(reason: any) {
    if (reason == 'Other') {
      this.otherReason = true;
      this.remark = '';
    } else if (reason != 'Other') {
      this.remark = reason;
      this.otherReasonText = '';
      this.otherReason = false;
    }
  }


  completeHiring(type: any) {
    let body = {
      eventId: this.selectedEventId,
      proposalId: this.selectedProposalId,
      remark: type == 'ACCEPTED' ? '' : this.acceptDeclineStatus ? this.proposalOwnerDeclineReason : this.otherReason ? this.otherReasonText : this.remark,
      status: type == 'ACCEPTED' ? this.acceptDeclineStatus ? this.acceptDeclineStatus : 'EVENT_OWNER_ACCEPTED' : type,
    };
    if (this.currentUserId != this.selectedEventCreatedBy) {
      this.MainService.acceptOrDeclineContract(body).then((data) => {
        if (data && data.isSuccess) {
          this.selectTab = 'individualProposalView';
          this.declineProposalModal = false;
          this.selectedProposal(this.selectedProposalId, 'clientAccepted');
          this.NotifierService.showSuccess(data.message);
        } else {
          this.NotifierService.showError(data.message);
        }
      });
    } else {
      this.MainService.acceptOrDeclineProposal(body).then((data) => {
        if (data && data.isSuccess) {
          this.declineProposalModal = false;
          this.selectTab = 'proposalsListTab';
          this.getAllProposalList();
          this.NotifierService.showSuccess(data.message);
        } else {
          this.NotifierService.showError(data.message);
        }
      });
    }
  }

  getEventList(pageNo: number, pageSize: number, type: any) {
    this.newEvent = [];
    this.eventsData = true;
    this.MainService.getAllArtistEventList(pageNo, pageSize, type).then(
      (data) => {
        if (data && data.isSuccess) {
          this.goLive = [];
          this.newEvent = [];
          this.eventsData = false;
          this.eventList = data.data.content;
          this.newEvent = this.eventList;
          if (this.newEvent) {
            this.newEvent.forEach((item: any) => {
              const day = new Date().toDateString();
              const lastDay = new Date();
              lastDay.setDate(lastDay.getDate() - 1);
              var date = new Date(item.createdAt).toDateString();
              if (date == day) {
                item.dateStatus = 'Today';
              } else if (date == lastDay.toDateString()) {
                item.dateStatus = 'Yesterday';
              } else {
                item.dateStatus = 'Day After';
              }
            });
          }
        } else {
          this.eventsData = false;
          this.newEvent = [];
        }
      }
    );
  }
  searchEvent(keyWord?: any) {
    if (keyWord == undefined || keyWord == null)
      keyWord = this.keyWord
    if (keyWord.length == 0)
      this.getEventList(this.pageNo, this.pageSize, this.type);
    else {
      this.newEvent = [];
      this.eventsData = true;
      this.MainService.getSearchEventByUser(keyWord).then(
        (data) => {
          if (data && data.isSuccess) {
            this.goLive = [];
            this.newEvent = [];
            this.eventsData = false;
            this.eventList = data.data.content;
            this.newEvent = this.eventList;
            if (this.newEvent) {
              this.newEvent.forEach((item: any) => {
                const day = new Date().toDateString();
                const lastDay = new Date();
                lastDay.setDate(lastDay.getDate() - 1);
                var date = new Date(item.createdAt).toDateString();
                if (date == day) {
                  item.dateStatus = 'Today';
                } else if (date == lastDay.toDateString()) {
                  item.dateStatus = 'Yesterday';
                } else {
                  item.dateStatus = 'Day After';
                }
              });
            }
          } else {
            this.eventsData = false;
            this.newEvent = [];
          }
        }
      );
    }
  }

  onScroll(type: any) {
    this.eventsData = true;
    this.currentPage = this.currentPage + 1;
    this.MainService.getAllArtistEventList(
      this.currentPage,
      this.pageSize,
      type
    ).then((data) => {
      if (data && data.isSuccess) {
        this.eventsData = false;
        if (data.data && data.data.content.length) {
          this.eventList = [...this.eventList, ...data.data.content];
          this.newEvent = this.eventList;
          this.newEvent.forEach((item: any) => {
            const day = new Date().toDateString();
            const lastDay = new Date();
            lastDay.setDate(lastDay.getDate() - 1);
            var date = new Date(item.createdAt).toDateString();
            if (date == day) {
              item.dateStatus = 'Today';
            } else if (date == lastDay.toDateString()) {
              item.dateStatus = 'Yesterday';
            } else {
              item.dateStatus = 'Day After';
            }
          });
        }
      }
    });
  }

  doneHiring(eventId?: any) {
    let id = eventId ? eventId : this.selectedEventId
    this.MainService.doneHiring(id).then((data) => {
      if (data && data.isSuccess) {
        this.completeHiringModal = false;
        this.getEventList(this.pageNo, this.pageSize, this.type);
        this.selectTab = 'mainTab';
        this.NotifierService.showSuccess(data.message);
      }
    });
  }
  checkoutStripe(fee: any) {
    this.processingPayment = true;
    this.sessionStorage.setSessions({ selectedEventId: this.selectedEventId });
    this.sessionStorage.setSessions({
      selectedProposalId: this.selectedProposalId,
    });
    this.newFee = fee * 100;
    this.MainService.createCheckoutSession(
      this.newFee,
      this.selectedProposalId,
      this.selectedEventId
    )
      .then((data) => {
        if (data && data.isSuccess) {
          window.open(data.data, '_blank');
          this.processingPayment = false;
          // return this.stripe.redirectToCheckout({
          //   sessionId: data.data,
          // });
        }
      })
      .then((res) => { });
  }

  markEventHiringInProgress(id: any) {
    this.MainService.markEventHiringInProgress(id).then((data: any) => {
      if (data && data.isSuccess) {
      }
      this.router.navigate(['ArtistDashboard/artistEventDetail'])
    });
  }

  setPaymentStatus() {
    this.MainService.setPaymentStatus(this.selectedProposalId).then((data) => {
      if (data && data.isSuccess) {
      }
      this.router.navigate(['ArtistDashboard/artistEventDetail'])
    });
  }

  getAllProposalList() {
    this.MainService.getAllProposalList(this.selectedEventId).then((data) => {
      if (data && data.isSuccess) {
        this.proposalsList = data?.data?.content;
        for (let i = 0; i < this.proposalsList?.length; i++) {
          this.proposalTitle[i] = this.proposalsList[i]?.proposalStatus;
          if (this.proposalsList[i].proposalStatus == 'PROPOSAL_OWNER_ACCEPTED')
            this.proposalTitle[i] = 'PROPOSAL OWNER ACCEPTED';
          if (this.proposalsList[i].proposalStatus == 'EVENT_OWNER_ACCEPTED')
            this.proposalTitle[i] = 'EVENT OWNER ACCEPTED';
        }
        if (data.message != 'No Proposal List Found') {
          this.selectTab = 'proposalsListTab';
        }
        if (data.message == "No Proposal List Found") {
          this.NotifierService.showSuccess(data.message);
        }
      } else {
        this.NotifierService.showError(data.message);
      }
    });
  }

  createNewEvent() {
    this.router.navigate(['ArtistDashboard/event'], {
      queryParams: { createEvent: true },
    });
  }

  downloadContract() {
    this.MainService.downloadContract(this.selectedProposalId).then(
      async (data) => {
        if (data) {
          const url = window.URL.createObjectURL(data);
          let aTag = document.createElement('a');
          document.body.appendChild(aTag);
          aTag.setAttribute('style', 'display: none');
          aTag.href = url;
          aTag.download = `Event_Contract${this.selectedProposalId
            }_${new Date().getDate()}/${new Date().getMonth() + 1
            }/${new Date().getFullYear()}`;
          aTag.click();
          window.URL.revokeObjectURL(url);
          aTag.remove();
          this.NotifierService.showSuccess(
            'Contract PDF Downloaded Successfully'
          );
        } else {
          this.NotifierService.showError('Download Failed');
        }
      }
    );
  }
  liveStreamingPage(type: any) {
    this.router.navigate(['/ArtistDashboard/artistLiveStreaming'], {
      queryParams: { type: type, id: this.eventId, ownerId: this.ownerId },
    });
  }
  getTimeDifference(event: any, index: any) {
    this.selectedEventNo = index;
    this.eventId = event.id;
    this.ownerId = event.eventCreatedBy;
    let timeCount = {
      day: 0,
      hour: 0,
      minute: 0,
      second: 0,
      timeDifference: 0,
    };
    // let newtimeline = event.startDate.split('T');
    // console.log(newtimeline,'newtimeline');

    var sdate = new Date(event.startDate);
    // var sdate = new Date(newtimeline[0] + ' ' + newtimeline[1]);
    // console.log(sdate,'sdate');

    var smilliseconds = sdate.getTime();
    // console.log(smilliseconds,'smilliseconds');


    // let endtime = event.endDate.split('T');
    // var edate = new Date(endtime[0] + ' ' + endtime[1]);
    var edate = new Date(event.endDate);
    var emilliseconds = edate.getTime();
    let date = new Date();

    if (this.TimeZone) {
      let time = date.toLocaleString("en-US", { timeZone: this.TimeZone });
      let userTimezoneMilis = new Date(time).getTime()
      if (userTimezoneMilis > smilliseconds && userTimezoneMilis < emilliseconds)
        this.goLive[index] = true;
      else {
        timeCount.timeDifference = smilliseconds - userTimezoneMilis;
        timeCount.second = Math.floor(timeCount.timeDifference / (1000 % 60));
        timeCount.minute = Math.floor(
          (timeCount.timeDifference / (1000 * 60)) % 60
        );
        timeCount.hour = Math.floor(
          (timeCount.timeDifference / (1000 * 60 * 60)) % 24
        );
        timeCount.day = Math.floor(
          timeCount.timeDifference / (1000 * 60 * 60 * 24)
        );
        if (
          timeCount.day == 0 &&
          timeCount.hour == 0 &&
          timeCount.minute < 30 &&
          timeCount.minute > -1
        )
          this.goLive[index] = true;
        else this.goLive[index] = false;
      }
    }
  }

  cancelGig() {
    let body = {
      eventId: this.selectedEventId,
      proposalId: this.selectedProposalId,
      remark: this.cancelReason,
    };
    this.MainService.cancelContract(body).then((data) => {
      if (data && data.isSuccess) {
        this.selectTab = 'mainTab';
        this.NotifierService.showSuccess(data.message);
        this.customerRefundForGig();
        this.router.navigate(['/ArtistDashboard/artistEventDetail']);
      } else {
        this.NotifierService.showError(data.message);
      }
      this.cancelReason = '';
      this.confirmCancelProposal = false;
    });
  }

  liveStream(id: any) {
    this.router.navigate(['/ArtistDashboard/watchLive'], {
      queryParams: { id: id },
    });
  }
  checkEventCondition() {
    this.confirmCancelEvent = !this.confirmCancelEvent;
  }

  cancelEvent() {
    let body = {
      eventId: this.selectedEventId,
      remark: this.cancelReason,
    };
    this.MainService.cancelEvent(body).then((data) => {
      if (data && data.isSuccess) {
        this.submitHubspotForm();
        this.selectTab = 'mainTab';
        this.customerRefund();
        this.NotifierService.showSuccess(data.message);
      } else {
        this.NotifierService.showError(data.message);
      }
      this.cancelReason = '';
      this.confirmCancelEvent = false;
    });
  }

  customerRefund() {
    this.MainService.customerRefund('event', this.selectedEventId).then(
      (data: any) => {
        if (data && data.isSuccess) {
          this.NotifierService.showSuccess(data.message);
        } else {
          this.NotifierService.showError(data.message);
        }
      }
    );
  }
  customerRefundForGig() {
    this.MainService.customerRefundForGig(
      'proposal',
      this.selectedEventId,
      this.selectedProposalId
    ).then((data: any) => {
      if (data && data.isSuccess) {
        this.NotifierService.showSuccess(data.message);
      } else {
        this.NotifierService.showError(data.message);
      }
    });
  }

  shareEvent(id: any) {
    this.router.navigate([`/ArtistDashboard/artistInvite`], {
      queryParams: {
        type: 'guests',
        id: id
      },
    });
  }

  submitHubspotForm() {
    var xhr = new XMLHttpRequest();
    var url =
      'https://api.hsforms.com/submissions/v3/integration/submit/9406878/e3e12e0f-1d7e-432e-928e-e8371db56577';
    var data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: this.hubspotEmail,
        },
        {
          name: 'TICKET.subject',
          value: '',
        },
        {
          name: 'TICKET.content',
          value: this.cancelReason,
        },
        {
          name: 'accept_tos',
          value: 1,
        },
      ],
      context: {
        pageUri: environment.hubspotNavigateUrl + '/ArtistDashboard/artistEventDetail',
        pageName: 'Cancel Even',
      },
    };
    var final_data = JSON.stringify(data);
    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
      } else if (xhr.readyState == 4 && xhr.status == 400) {
      } else if (xhr.readyState == 4 && xhr.status == 403) {
      } else if (xhr.readyState == 4 && xhr.status == 404) {
        // alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
      }
    };
    xhr.send(final_data);
  }

  sendMessage(id: any, name: any) {
    this.router.navigate([`/ArtistDashboard/message`], {
      queryParams: {
        'id': id,
        'name': name,
      }
    })
  }
  // getProPlaylistDetails(event: any) {
  //   if (this.proEventList.length == 0) {
  //     this.MainService.getAllArtistEventList(0, 1000, 'completed').then(
  //       (data) => {
  //         if (data && data.isSuccess) {
  //           this.proEventList = data.data.content;
  //         }
  //       }
  //     );
  //     this.myPlaylist();
  //   }
  //   this.selectTab = 'intractivePro';
  //   this.proEventData = event
  //   this.selectedEventId = event.id;
  //   this.MainService.getProPlaylistDetails(event.id).then((data: any) => {
  //     if (data && data.isSuccess) {
  //       this.proListData = data.data;
  //     } else {
  //       this.NotifierService.showError(data.message);
  //     }
  //   })
  // }
  // repertoire(key?: any){
  //   this.MainService.getProPlaylistHistory(key).then((data: any) => {
  //     if (data && data.isSuccess) {
  //       this.repertoireData = data.data;
  //     } else {
  //       this.NotifierService.showError(data.message);
  //     }
  //   })
  // }
  // myPlaylist(){
  //   this.MainService.getAllPlaylist().then((data: any) => {
  //     if (data && data.isSuccess) {
  //       this.myPlaylistDetails = data.data;
  //     } else {
  //       this.NotifierService.showError(data.message);
  //     }
  //   })
  // }
  // getAllSongOfPlaylist(id: any){
  //   this.MainService.getAllSongOfPlaylist(id).then((data: any) => {
  //     if (data && data.isSuccess) {
  //       this.songList = data.data;
  //     } else {
  //       this.NotifierService.showError(data.message);
  //     }
  //   })
  // }
  // selectAll() {
  //   this.selectAllproList = !this.selectAllproList
  //   if (this.selectAllproList) {
  //     for (let i = 0; i < this.proListData.length; i++) {
  //       this.proListIds.push(this.proListData[i].id)
  //     }
  //   } else {
  //     this.proListIds = [];
  //   }
  // }
  // drop(event: CdkDragDrop<string[]>) {
  //   // this.id = [];
  //   // moveItemInArray(this.proListData, event.previousIndex, event.currentIndex);
  //   // for (let i = 0; i < this.proListData.length; i++) {
  //   //   var proId = this.proListData[i].id;
  //   //   this.id.push(proId);
  //   // }
  //   // this.updatePROPlaylistOrder(this.id);
  // }
  // addSongToExistingPROList(){
  //   this.existingPlaylist = false;
  // }
  // addSongToExistingEvent(){
  //   this.completedEventList = false;
  // }
  // addPlaylistToExistingEvent(i:any){
  //   this.completedMyPlaylist[i]= false;
  // }
  ngOnDestroy() {
    clearInterval(this.interval);
  }
  // deleteProList(id: any) {
  //   this.MainService.deleteAddedSongOnEvent(id).then((data: any) => {
  //     if (data && data.isSuccess) {
  //       this.NotifierService.showSuccess(data.message);
  //       this.getProPlaylistDetails(this.proEventData);
  //     } else {
  //       this.NotifierService.showError(data.message);
  //     }
  //   })
  // }
}


