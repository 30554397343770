import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable,Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessionService } from '../service/helper/session.service';


@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  apiRoot: string;

  constructor(  private _HttpClient: HttpClient,private _SessionService: SessionService,  private _Injector: Injector,) {
    this.apiRoot = environment.restrictedUrl.refreshTokenUrl
  }

 refreshToken(): Promise<any> {
    const _SessionService = this._Injector.get(SessionService);
    const refreshToken = _SessionService.getSession('refreshToken');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set("Authorization", "Bearer " + refreshToken)
    return this.postRequest<any>({ apiRoot: environment.restrictedUrl.refreshTokenUrl, headers: headers })
  }



 postRequest<T>( options?: IRequestOptions): Promise<T> {
  const { apiRoot, headers } = this.getApiRootAndHeaders(options);
  return this._HttpClient
    .post(`${apiRoot}`, { headers })
    .toPromise()
    .then(res => res as T)
    .catch(err => this.handleCatchError<any>(err));
  }


   // handle errors
   private handleCatchError<T>(err: HttpErrorResponse) {
    if ((err.status === 404) || (err.status === 0 && err.url === null)) {
      // tslint:disable-next-line: no-console
      return err.error;
    }
    if ((err.status === 401)) {
      // tslint:disable-next-line: no-console
      return err.error;
    }
    if (err.status !== 0 && err.status !== 200 && err.status !== 401 && err.status !== 404) {
      // tslint:disable-next-line: no-console
      return err.error;
    }
    return err.error;
  }

  private getApiRootAndHeaders(options?: IRequestOptions): IRequestOptions {
    return {
      apiRoot: (options && options.apiRoot) ? options.apiRoot : this.apiRoot,
      headers: (options && options.headers) ? options.headers : new HttpHeaders()
    };
  }
}

interface IRequestOptions {
  headers?: HttpHeaders;
  apiRoot?: string;
}
