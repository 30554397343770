import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { ImageCroppedEvent, ImageTransform, Dimensions, base64ToFile } from 'ngx-image-cropper';
import { SessionService } from 'src/app/service/helper/session.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  audienceProfileData: any;
  profilePicData: any;
  imageUrl: any;
  imageUploaded: boolean;
  imageSrc: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  showDragDrop: boolean = false;
  fileData: any;
  imageSpinnerDone: boolean = false;
  tabName: any = 'General'
  constructor(
    private _mainService: MainService,
    private _notifierService: NotifierService,
    private sessionService: SessionService
  ) {
    this.imageUploaded = false;
  }

  ngOnInit(): void {
    this.getProfilepic();
    this.getAudienceProfileDetails();
  }

  getAudienceProfileDetails() {
    let userId = localStorage.getItem('userId');
    this._mainService.getAudienceProfileDetails(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.audienceProfileData = data.data;
      } else {
        this._notifierService.showError(data.message);
      }
    });
  }

  getProfilepic() {
    this.imageSpinnerDone = true;
    let userId = localStorage.getItem('userId');
    this._mainService.getProfilepic(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.imageSpinnerDone = false;
        this.profilePicData = data.data;
      } else
        this._notifierService.showError(data.message);
      this.imageSpinnerDone = false;
    });
  }

  onFileSelect(event: any) {
    if (event.target.files[0]) {
      this.showDragDrop = true;
    }
    this.imageChangedEvent = event;
    const file = event.target.files[0];
    if (file) {
      this.imageSrc = <string>(<FileReader>event.target).result;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileData = base64ToFile(this.croppedImage);
  }

  cropDone() {
    this.showDragDrop = false;
    this.imageSpinnerDone = true;
    this.uploadProfilePhoto(this.fileData);
  }


  uploadProfilePhoto(file: any) {
    this.imageUploaded = true;
    this.imageSpinnerDone = true;
    const fd = new FormData();
    fd.append('multipartFile', file);
    this._mainService.fileUpload(fd).then((data: any) => {
      if (data && data.isSuccess) {
        this.imageUrl = data.data;
        this._mainService.updateProfilePic(data.data).then((res: any) => {
          if (res && res.isSuccess) {
            this.sessionService.setSessions({ avatarUrl: data.data });
            this.getProfilepic();
            this.showDragDrop = false;
            this._notifierService.showSuccess(res.message);
          } else
            this._notifierService.showError(res.message);
        });
      }
    });
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

}
