import {HostListener, Injectable} from "@angular/core";
import {Subject} from "rxjs";
import * as moment from 'moment';
import {SessionService} from "./session.service";
import {AuthServiceService} from "../auth-service.service";

@Injectable({providedIn: 'root'})
export class UtilityService {

    constructor(private _SessionService : SessionService, private authService : AuthServiceService) {}

    exclusive = new Subject<boolean>();

    getTimeDifference(date : any) {
        const currentDate = moment(new Date());
        const nextDate = moment(new Date(date));
        return {
            hour: currentDate.diff(nextDate, 'hour'),
            minute: currentDate.diff(nextDate, 'minute'),
            second: currentDate.diff(nextDate, 'second')
        }
    }


    getMinDate(date : any) {
        return moment(date).subtract(1, 'year').toDate();
    }



  formatDate(date: any) {
    let concertDate = new Date(date).getDate() && new Date(date).getDate() < 10 ? `0${new Date(date).getDate()}` : new Date(date).getDate();
    let month = new Date(date).getMonth() + 1 && new Date(date).getMonth() + 1 < 10 ? `0${new Date(date).getMonth() + 1}` : new Date(date).getMonth() + 1;
    let year = new Date(date).getFullYear();
    var hour = new Date(date).getHours() > 12 ? new Date(date).getHours() - 12 : new Date(date).getHours();
    var suffix = new Date(date).getHours() >= 12 ? "PM" : "AM";
    var hour2 = hour < 10 ? "0"  + hour : hour;
    let min = (new Date(date).getMinutes() < 10 ? '0' : '') && new Date(date).getMinutes().toString().length === 1 ? `0${new Date(date).getMinutes()}` : new Date(date).getMinutes();
    // return `${year}-${month}-${concertDate} ${hour2}:${min} ${suffix}`
    return `${month}/${concertDate}/${year} ${hour2}:${min} ${suffix}`

   }

   formatConcertDate(date: any) {
    let dt = moment.parseZone(date);
    return dt.format('DD MMMM YYYY | hh:mm a')
  }

}
