import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-pro-reporting',
  templateUrl: './pro-reporting.component.html',
  styleUrls: ['./pro-reporting.component.scss']
})
export class ProReportingComponent implements OnInit {
  proReportingForm!: FormGroup;
  tabName = 'ASCAP';
  eventID: any;
  interval: any;
  keyWord: any = this.activatedRoute.queryParams
  proListData: any;
  RepertoireListIds: any[] = [];
  addedSongListIds: any[] = [];
  proListIds: any[] = [];
  selectAllproList: any[] = [];
  selectAllAddedSong: boolean = false;
  selectAllRepertoireSong: boolean = false;
  // link = 'https://www.ascap.com/';
  id: any[] = [];
  updatePlaylistSongsId: any[] = [];
  repertoireData: any[] = [];
  proTab: string = 'addedSong'
  songList: any[] = [];
  myPlaylistDetails: any;
  selectedPlaylist: any;
  proEventList: any;
  proEventData: any;
  existingPlaylist: boolean = false;
  createNewProPlaylist: boolean = false;
  completedEventList: boolean = false;
  editPlaylist: boolean = false;
  completedMyPlaylist: any[] = [];
  selectedProEvent: any;
  playlistTitle: any = "Add Playlist Details";
  playlistName: any;
  playlistDescription: any;
  searchRepertoire: any;
  completedEventListArray: any[] = []
  constructor(
    private _mainService: MainService,
    private _notifierService: NotifierService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.proReportingForm = this.fb.group({
      title: ["", Validators.required],
      link: ["", Validators.required]
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((param: any) => {
      if (param && param.id) {
        this.eventID = param.id;
      }
    })
    this.getProPlaylistDetails(this.eventID);
    if (this.keyWord['_value'].refresh) {
      this.router.navigate(['/ArtistDashboard/proReporting/' + this.eventID])
      this.interval = setInterval(() => {
        location.reload();
      }, 1500);
    }
    this._mainService.getAllArtistEventList(0, 1000, 'completed').then(
      (data) => {
        if (data && data.isSuccess) {
          this.proEventList = data.data.content;
          console.log(this.proEventList, 'response of api');
          this.test(this.eventID)
        }
      });
    this._mainService.getEventDetailById(this.eventID).then((data) => {
      if (data && data.isSuccess) {
        this.proEventData = data.data;
      } else {
        this._notifierService.showError(data.message);
      }
    });
    this.myPlaylist();
    this.repertoire();
  }

  drop(event: CdkDragDrop<string[]>) {
    this.id = [];
    moveItemInArray(this.proListData, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.proListData.length; i++) {
      var proId = this.proListData[i].id;
      this.id.push(proId);
    }
    this.updateAddedSongOrder(this.id);
  }
  dropPlaylistSong(event: CdkDragDrop<string[]>, playlistId: any, index: any) {
    this.updatePlaylistSongsId = [];
    moveItemInArray(this.songList[index], event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.songList[index].length; i++) {
      var proId = this.songList[index][i].id;
      this.updatePlaylistSongsId.push(proId);
    }
    this.updateMyPlaylistOrder(this.updatePlaylistSongsId, playlistId);
  }
  getProPlaylistDetails(id: any) {
    this.proEventData = id.id ? id : this.proEventData
    this._mainService.getProPlaylistDetails(id.id ? id.id : id).then((data: any) => {
      if (data && data.isSuccess) {
        this.proListData = data.data;

        //  this._notifierService.showSuccess(data.message);
      } else {
        this._notifierService.showError(data.message);
      }
    })

  }

  test(id: any) {
    this.completedEventListArray = [];
    let newId = id.id ? id.id : id
    this.completedEventListArray.push(id.id ? id : this.proEventData)
    this.proEventList.filter((item: { [x: string]: any; }) => {
      if (item.id != newId)
        this.completedEventListArray.push(item)
    });

    console.log(this.completedEventListArray, 'new updated array');
  }
  saveProPlaylist() {
    if (this.proTab == 'addedSong') {
      let body = {
        "category": this.tabName,
        "identifierId": this.eventID,
        "artist": this.proReportingForm.controls['link'].value.trim(),
        "proPlaylistType": "EVENT",
        "title": this.proReportingForm.controls['title'].value.trim(),
      }
      this._mainService.addProSongToEvent(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.proReportingForm.reset();
          this.getProPlaylistDetails(this.proEventData);
          this._notifierService.showSuccess(data.message);
        } else {
          this._notifierService.showError(data.message);
        }
      })
    } else {
      let body = {
        "artist": this.proReportingForm.controls['link'].value.trim(),
        "category": this.tabName,
        "id": this.selectedPlaylist.id,
        "title": this.proReportingForm.controls['title'].value.trim(),
        "type": "EVENT"
      }

      this._mainService.addSongToPlaylist(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.proReportingForm.reset();
          this._notifierService.showSuccess(data.message);
        } else {
          this._notifierService.showError(data.message);
        }
      })
    }
  }
  selectAllFromMyPlaylist(index: number) {
    this.selectAllproList[index] = !this.selectAllproList[index]
    if (this.selectAllproList[index]) {
      for (let i = 0; i < this.songList[index]?.length; i++) {
        this.proListIds.push(this.songList[index][i].id)
      }
    } else {
      this.proListIds = [];
    }
  }
  selectFromMyPlaylist(data: any) {
    if (this.proListIds.length == 0)
      this.proListIds.push(data.id);
    else {
      let songAdded = this.proListIds.includes(data.id)
      if (songAdded) {
        this.proListIds = this.proListIds.filter(obj => (
          obj != data.id
        ))
      } else
        this.proListIds.push(data.id);
    }
    console.log(this.proListIds);
  }
  selectAllRepertoire() {
    this.selectAllRepertoireSong = !this.selectAllRepertoireSong
    if (this.selectAllRepertoireSong) {
      this.RepertoireListIds = [];
      for (let i = 0; i < this.repertoireData.length; i++) {
        this.RepertoireListIds.push(this.repertoireData[i].id)
      }
    } else
      this.RepertoireListIds = [];
  }
  selectAllAddedSongOfEvent() {
    this.selectAllAddedSong = !this.selectAllAddedSong
    if (this.selectAllAddedSong) {
      this.addedSongListIds = [];
      for (let i = 0; i < this.proListData.length; i++) {
        this.addedSongListIds.push(this.proListData[i].id)
      }
    } else {
      this.addedSongListIds = [];
    }
  }
  selectRepertoire(data: any) {
    if (this.RepertoireListIds.length == 0)
      this.RepertoireListIds.push(data.id);
    else {
      let songAdded = this.RepertoireListIds.includes(data.id)
      if (songAdded) {
        this.RepertoireListIds = this.RepertoireListIds.filter(obj => (
          obj != data.id
        ))
      } else
        this.RepertoireListIds.push(data.id);
    }
    console.log(this.RepertoireListIds);
  }
  selectAddedSongOfEvent(data: any) {
    if (this.addedSongListIds.length == 0)
      this.addedSongListIds.push(data.id);
    else {
      let songAdded = this.addedSongListIds.includes(data.id)
      if (songAdded) {
        this.addedSongListIds = this.addedSongListIds.filter(obj => (
          obj != data.id
        ))
      } else
        this.addedSongListIds.push(data.id);
    }
    console.log(this.addedSongListIds);
  }
  deleteAddedSong() {
    if (this.addedSongListIds.length > 0) {
      this._mainService.deleteAddedSongOnEvent(this.addedSongListIds).then((data: any) => {
        if (data && data.isSuccess) {
          this.addedSongListIds = [];
          this._notifierService.showSuccess(data.message);
          this.getProPlaylistDetails(this.proEventData);
        } else {
          this._notifierService.showError(data.message);
        }
        this.selectAllAddedSong = false;
        this.addedSongListIds = [];
      })
    } else
      this._notifierService.showError('Please Select Atleast One Song')
  }
  updateAddedSongOrder(id: any) {
    let body = {
      "identifierId": this.eventID,
      "playlistIds": id,
      "proPlaylistType": "EVENT"
    }
    this._mainService.updateAddedSongsOrder(body).then((data: any) => {
      if (data && data.isSuccess) {
      } else { }
    })
  }
  updateMyPlaylistOrder(updateArray: any, id: any) {
    let body = {
      "id": id,
      "songsIds": updateArray,
    }
    this._mainService.updateMyPlaylistSongsOrder(body).then((data: any) => {
      if (data && data.isSuccess) {
      } else { }
    })
  }
  repertoire(key?: any) {
    this._mainService.getProPlaylistHistory(key).then((data: any) => {
      if (data && data.isSuccess) {
        this.repertoireData = data.data;
      } else {
        this._notifierService.showError(data.message);
      }
    })
  }
  getAllSongOfPlaylist(id: any, i: number) {
    this._mainService.getAllSongOfPlaylist(id).then((data: any) => {
      if (data && data.isSuccess) {
        this.songList[i] = data.data;
      } else {
        this._notifierService.showError(data.message);
      }
    })
  }
  myPlaylist() {
    this._mainService.getAllPlaylist().then((data: any) => {
      if (data && data.isSuccess) {
        this.myPlaylistDetails = data.data;
        if (this.playlistTitle == 'Edit Playlist Details') {
          for (let i = 0; i < this.myPlaylistDetails.length; i++) {
            if (this.myPlaylistDetails[i].id == this.selectedPlaylist.id)
              this.selectedPlaylist = this.myPlaylistDetails[i];
          }
        }
      } else {
        this._notifierService.showError(data.message);
      }
    })
  }
  addSongsToPlaylistFromEvent(id: any) {
    if (this.addedSongListIds.length > 0) {
      let body = {
        "id": id,
        "songsIds": this.addedSongListIds,
      }
      this._mainService.copySongsToPlaylistFromEvent(body).then((data: any) => {
        if (data && data.isSuccess) {
          // this.proReportingForm.reset();
          this.getProPlaylistDetails(this.proEventData);
          this._notifierService.showSuccess(data.message);
        } else {
          this._notifierService.showError(data.message);
        }
        this.selectAllAddedSong = false;
        this.addedSongListIds = [];
      })
      this.completedEventList = false;
    } else
      this._notifierService.showError('Please Select Atleast a Song');
  }
  addSongsToEventFromHistory(id: any) {
    if (this.RepertoireListIds.length > 0) {
      let body = {
        "id": id,
        "songsIds": this.RepertoireListIds,
      }
      this._mainService.copySongsToEventFromHistory(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.getProPlaylistDetails(this.proEventData);
          this._notifierService.showSuccess(data.message);
        } else {
          this._notifierService.showError(data.message);
        }
      })
      this.selectAllRepertoireSong = false;
      this.RepertoireListIds = [];
      this.completedEventList = false;
    } else
      this._notifierService.showError('Please Select Atleast a Song');
  }
  addSongsToEventFromPlaylist(id: any) {
    if (this.proListIds.length > 0) {
      let body = {
        "id": id,
        "songsIds": this.proListIds,
      }
      this._mainService.copySongsToEventFromPlaylist(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.getProPlaylistDetails(this.proEventData);
          this._notifierService.showSuccess(data.message);
        } else {
          this._notifierService.showError(data.message);
        }
      })
      for (let i = 0; i < this.myPlaylistDetails.length; i++) {
        this.selectAllproList[i] = false;
      }
      this.proListIds = [];
      this.completedEventList = false;
    } else
      this._notifierService.showError('Please Select Atleast a Song');
  }
  deleteProPlaylist(playlist: any) {
    this._mainService.deleteProPlaylist(playlist.id).then((data: any) => {
      if (data && data.isSuccess) {
        this.myPlaylist();
        this._notifierService.showSuccess(data.message);
      } else {
        this._notifierService.showError(data.message);
      }
      for (let i = 0; i < this.myPlaylistDetails.length; i++) {
        this.selectAllproList[i] = false;
      }
      this.proListIds = [];
    })
  }
  deleteSongOfPlaylist(playlist: any, index: number) {
    if (this.proListIds.length > 0) {
      let body = {
        "songsIds": this.proListIds,
      }
      this._mainService.deleteSongOfPlaylist(this.proListIds).then((data: any) => {
        if (data && data.isSuccess) {
          this.getAllSongOfPlaylist(playlist.id, index);
          this._notifierService.showSuccess(data.message);
        } else {
          this._notifierService.showError(data.message);
        }
      })
      for (let i = 0; i < this.myPlaylistDetails.length; i++) {
        this.selectAllproList[i] = false;
      }
      this.proListIds = [];
      this.completedEventList = false;
    } else
      this._notifierService.showError('Please Select Atleast a Song');
  }
  createOrEditPlaylist() {

    if (this.playlistTitle == 'Add Playlist Details') {
      let body = {
        "description": this.playlistDescription,
        "name": this.playlistName,
      }
      this._mainService.createNewPlaylist(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.myPlaylist();
          this.playlistDescription = ''; this.playlistName = '';
          this._notifierService.showSuccess(data.message);
        } else {
          this._notifierService.showError(data.message);
        }
      })
    } else {
      let body = {
        "description": this.playlistDescription,
        "name": this.playlistName,
        "id": this.selectedPlaylist.id
      }
      this._mainService.editPlaylist(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.myPlaylist();
          this.playlistDescription = ''; this.playlistName = '';
          this._notifierService.showSuccess(data.message);
        } else {
          this._notifierService.showError(data.message);
        }
      })
    }
  }
  editPlaylistDetails() {
    this.playlistTitle = "Edit Playlist Details"
    this.playlistDescription = this.selectedPlaylist?.description;
    this.playlistName = this.selectedPlaylist.name;
  }
  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
