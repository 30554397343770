import { Component, OnInit, TemplateRef } from '@angular/core';
// import { PubNubAngular } from 'pubnub-angular2';
import { UUID } from 'angular2-uuid';
import { UserService } from 'src/app/service/user.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { MainService } from 'src/app/service/main.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import PubNub from 'pubnub';
import { UtilityService } from 'src/app/service/helper/utility.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  modalRef?: BsModalRef;
  uuidValue: any;
  messages: any = [];
  showChatScreen = false;
  profileImage: any;
  userName: any;
  currentUser: any;
  isUserLoged: boolean;
  isAlive = true;
  emojiText: any;
  userPressenceData: any;
  sendMessageData: any;
  file: any;
  uploadDoc: string | any;
  SelectFileType: any;
  pdfSrc: any;
  fileName: any;
  pageNo: number = 0;
  pageSize: number = 10;
  allChatUserList: any;
  search: any;
  currentPage = 0;
  showUploadDocUi: boolean;
  openTopModal: boolean = false;
  newChannelName: any;
  historyUser: any;
  messageHistoryData: any;
  userInfo: any;
  currentUserProfile: any;
  pressenceData: any = [];
  i: any = -1;
  count = 0;
  interval: any;
  searchUser: any;
  videoExts = ['mp4', 'mov', 'Flv', 'Vmw', 'Avchd'];
  imageFileExt = ['jpg', 'jpeg', 'png', 'gif'];
  userMessage: any = [];
  newImage: any;
  nmsg: any = [];
  newMessage: any;
  userUUID: any;
  comingMessage: any = [];
  test: boolean = false;
  pubnub: any;
  userPressence: any;
  showOnline: boolean = false;
  newMessages: any[] = [];
  result: any;
  chatData: boolean = false;
  pressenceArray: any = [];
  userChannels: any;
  allUserChannels: any[] = [];
  timeDataArray: any[] = [];
  indexValue: any;
  messageTime: any;
  lastTimeToken: any[] = [];
  // notificationApiCall: boolean = false;
  receiverId: any;
  comingId: any;
  notFound: boolean = false;
  showAllChatUserModal: boolean = false;
  userFound: boolean = false;
  searchComing: any;
  opponentDp: any;
  // deleteChatTime: any;
  // deleteChat: any;

  constructor(
    private _SessionService: SessionService,
    private _MainService: MainService,
    private modalService: BsModalService,
    private _NotifierService: NotifierService,
    public utilities: UtilityService,
    private activatederoute: ActivatedRoute,
  ) {
    this.emojiText = '';
    this.showUploadDocUi = false;
    this.isUserLoged = false;
    if (this._SessionService.getSession('chatUuId')) {
      this.uuidValue = this._SessionService.getSession('chatUuId');
    } else {
      this.uuidValue = UUID.UUID();
    }
    this.pubnub = new PubNub({
      publishKey: 'pub-c-6ae48ff7-ea5d-4b56-b7ce-a553e0ebc7de',
      subscribeKey: 'sub-c-c9a52fe0-a8ad-11ec-94c0-bed45dbe0fe1',
      ssl: true,
      uuid: this.uuidValue,
    });
  }

  ngOnInit(): void {
    this.comingId = this.activatederoute.snapshot.queryParams.id;
    this.searchComing = this.activatederoute.snapshot.queryParams.name;
    this.getUserHistory();
    this.currentUser = this._SessionService.getSession('userId');
    this.currentUserProfile = this._SessionService.getSession('avatarUrl');
    this.pubnub.addListener({
      status: async (statusEvent: { category: string }) => {
        if (statusEvent.category === 'PNConnectedCategory') {
          try {
            await this.pubnub.setState({
              state: {
                some: 'state',
              },
            });
          } catch (status) { }
        }
      },
      message: (messageEvent: any) => {
        this.messages.push(messageEvent);
        const groups = this.messages.reduce((groups: any, game: any) => {
          const date = game.message.currentDate;
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(game);
          return groups;
        }, {});
        this.newMessages = Object.keys(groups).map((date) => {
          return {
            date,
            mesg: groups[date],
          };
        });
        setTimeout(() => {
          $('#div1').animate({ scrollTop: $('#div1').prop('scrollHeight') }, 1000);
        }, 20);
      },
      presence: (presenceEvent: any) => {
        this.userPressence = presenceEvent;
      },
      file: (event: any) => { },
    });

  }

  ngOnDestroy() {
    this.saveLastChatDetails();
  }

  addEmoji(event: any) {
    this.emojiText = `${this.emojiText}${event.emoji.native}`;
  }

  getUserHistory(search?: string) {
    this._MainService.getAllChatList(search).then((data: any) => {
      if (data && data.isSuccess) {
        this.historyUser = data.data;
        if (this.comingId) {
          this.openComingUserChat();
        }
        this.historyUser.forEach((item: any) => {
          if (this.userPressence) {
            item.action = this.userPressence?.action;
          }
          item.messageTimeNew = new Date(item.messageTime);
          const day = new Date().toDateString();
          const lastDay = new Date();
          lastDay.setDate(lastDay.getDate() - 1);
          if (item.messageTimeNew.toDateString() === day) {
            item.dateStatus = "Today Chat";
          } else if (item.messageTimeNew.toDateString() === lastDay) {
            item.dateStatus = "Yesterday Chat";
          } else {
            item.dateStatus = "Day After";
          }
        });



        this.pubnub.messageCounts({
          channels: ['6249b077-ee40-4d6d-b5c9-60196e8c15b7', '6249b077-ee40-4d6d-b5c9-60196e8c15b7'],
          channelTimetokens: [],
        }, (status: any, results: any) => {
          console.log(status);
          console.log(results, "count");
        });
        for (let j = 0; j < this.pressenceArray; j++) {
          var occupant = this.pressenceArray[j].channels.occupants;
          console.log(occupant, 'occupant')
        }
      } else {
      }
    });
  }

  searchChatUser(event: any) {
    this.searchUser = event.target.value;
    if (this.searchUser) {
      this.getUserHistory(this.searchUser);
    } else {
      this.getUserHistory();
    }
  }

  getAllChatUsers(pageNo: number, pageSize: number, search?: string) {
    this.chatData = true;
    this._MainService.getAllChatUsers(pageNo, pageSize, search).then((data: any) => {
      if (data && data.isSuccess) {
        this.allChatUserList = data.data.content;
        if (this.comingId) {
          this.openUserChat(this.comingId, 0)
        }
        this.chatData = false;
      } else {
        this.chatData = false;
      }
    });
  }

  searchByUser(event: any) {
    this.search = event.target.value;
    if (this.search) {
      this.getAllChatUsers(this.pageNo, this.pageSize, this.search);
    } else {
      this.getAllChatUsers(this.pageNo, this.pageSize);
    }
  }

  onScroll() {
    this.currentPage = this.currentPage + 1;
    this._MainService
      .getAllChatUsers(this.currentPage, this.pageSize)
      .then((data: any) => {
        if (data.isSuccess) {
          if (data.data.content.length) {
            this.allChatUserList = [
              ...this.allChatUserList,
              ...data.data.content,
            ];
          }
        }
      });
  }

  async addComment(event?: any) {
    this.pubnub.subscribe({
      channels: [this.newChannelName],
      withPresence: true,
      presence: function (data: any) { },
      triggerEvents: ['message', 'presence', 'status'],
    });
    let time2 = {
      'timeCount': Date.now(),
      'channel': this.newChannelName,
      'messageType': 'TEXT',
      'message': event ? event.target.value : this.emojiText,
      'senderId': this.currentUser
    }
    this.timeDataArray[this.indexValue].timeCount = time2.timeCount;
    this.timeDataArray[this.indexValue].channel = time2.channel;
    this.timeDataArray[this.indexValue].messageType = time2.messageType;
    this.timeDataArray[this.indexValue].message = time2.message;
    this.timeDataArray[this.indexValue].senderId = time2.senderId;
    let message = event ? event.target.value : this.emojiText;
    let time = Date.now();
    var date = new Date().toDateString();
    let senderId = this.currentUser;
    if (message) {
      const newMessage = {
        messageType: 'TEXT',
        typeMessage: event ? event.target.value : this.emojiText,
        messageTime: time,
        currentUserProfile: this.currentUserProfile,
        currentDate: date,
        senderId: senderId,
      };
      try {
        const result = await this.pubnub.publish({
          message: newMessage,
          channel: this.newChannelName,
          storeInHistory: true,
        });
      } catch (status) { }
      // this.getUserHistory();
    }
    setTimeout(() => {
      $('#div1').animate({ scrollTop: $('#div1').prop('scrollHeight') }, 1000);
    }, 20);
    // if (this.notificationApiCall) {
    this.sendChatNotification(this.emojiText, time, senderId)
    // }
    this.emojiText = '';
  }


  getUserIsTyping() {
    this.pubnub.getState(
      {
        uuid: this.uuidValue,
        channels: [this.newChannelName],
      },
      function (status: any, response: any) { }
    );
  }

  openComingUserChat() {
    this.notFound = false;
    for (var i = 0; i < this.historyUser.length; i++) {
      var id = parseInt(this.historyUser[i].userId);
      if (id == this.comingId) {
        this.userFound = true;
        // this.deleteChatTime == this.historyUser[i].messageEndTime
        this.openUserChatList(id, this.historyUser[i].channelName, i)
      }
    }
    if (!this.userFound) {
      this.getAllChatUsers(this.pageNo, this.pageSize, this.searchComing);
      // this.openUserChat(this.comingId,0)
      // this.showAllChatUserModal = true;
      // this.comingId = null;
    }
  }

  openUserChat(id: any, i: any) {
    console.log(id, i, 'coming')
    this.showAllChatUserModal = false;
    this.indexValue = i;
    this._MainService.saveChatDetail(id).then((data: any) => {
      if (data && data.isSuccess) {
        this.newChannelName = data.data;
        // this.comingId = null;
        let time = {
          'timeCount': Date.now(),
          'channel': this.newChannelName,
        }
        this.timeDataArray[i] = time;
      }
    });
    this.pubnub.hereNow(
      {
        channels: [this.newChannelName],
        includeUUIDs: true,
        includeState: true,
      },
      (status: any, response: any) => {
        this.pressenceData = response;
        if (this.pressenceData) {
          if (this.pressenceData.totalOccupancy == 0 || this.pressenceData.totalOccupancy == 1) {
            // this.notificationApiCall = true;
          }
        }
      }
    );
    this.messages = [];
    this.newMessages = [];
    this.showUploadDocUi = false;
    this.showChatScreen = true;
    this.modalRef?.hide();
    if (this.allChatUserList && this.allChatUserList.length) {
      for (let i = 0; i <= this.allChatUserList.length; i++) {
        if (id == this.allChatUserList[i].userId) {
          this.profileImage = this.allChatUserList[i].avatarUrl;
          this.userName = this.allChatUserList[i].displayName;
          this.userUUID = this.historyUser[i].chatUuId;
        }
      }
    }
  }

  openUserChatList(id: any, name: any, i: any, endTime?: any, userDp?: any) {
    this.opponentDp = userDp;
    // if (!this.userFound)
    // this.deleteChatTime = endTime;
    this.pressenceData = null;
    // this.notificationApiCall = false;
    this.receiverId = id;
    let body = {
      "channelName": this.timeDataArray[this.indexValue] ? this.timeDataArray[this.indexValue].channel : '',
      "message": this.timeDataArray[this.indexValue] ? this.timeDataArray[this.indexValue].message : '',
      "messageTime": new Date(this.timeDataArray[this.indexValue] ? this.timeDataArray[this.indexValue].timeCount : ''),
      "messageType": this.timeDataArray[this.indexValue] ? this.timeDataArray[this.indexValue].messageType : '',
      "senderId": this.timeDataArray[this.indexValue] ? this.timeDataArray[this.indexValue].senderId : '',
    }
    this._MainService.saveLastChatDetails(body).then((data: any) => {
      if (data && data.isSuccess) {
        this.getUserHistory();
      } else { }
    })
    this.pubnub.hereNow(
      {
        channels: [name],
        includeUUIDs: true,
        includeState: true,
      },
      (status: any, response: any) => {
        this.pressenceData = response;
        // if (this.pressenceData.totalOccupancy == 0 || this.pressenceData.totalOccupancy == 1) {
        //   this.notificationApiCall = true;
        // }
      }
    );

    this.newChannelName = name;
    this.indexValue = i;
    let time = {
      'timeCount': Date.now(),
      'channel': this.newChannelName,
    }
    this.timeDataArray[i] = time;
    this.pubnub.subscribe({
      channels: [name],
      withPresence: true,
      presence: function (data: any) { },
      triggerEvents: ['message', 'presence', 'status'],
    });
    this.messages = [];
    this.newMessages = [];
    this.showOnline = true;
    this.showUploadDocUi = false;
    this.showChatScreen = true;
    for (let i = 0; i < this.historyUser.length; i++) {
      if (id === this.historyUser[i].userId) {
        this.profileImage = this.historyUser[i].avatarUrl;
        this.userName = this.historyUser[i].displayName;
        this.userUUID = this.historyUser[i].chatUuId;
      }
    }
    this.getChatHistory(name);
  }


  getChatHistory(name: any) {
    // var a = parseInt(this.deleteChatTime) + 1
    this.pubnub.history({
      channel: [name],
      reverse: false,
      count: 100,
      // end: a,
      stringifiedTimeToken: true,
    })
      .then((response: any) => {
        this.messageHistoryData = response.messages;
        // this.deleteChat = this.messageHistoryData[this.messageHistoryData.length - 1].timetoken;
        for (let mesg of this.messageHistoryData) {
          this.messages.push({
            message: {
              messageTime: mesg.entry.messageTime,
              messageType: mesg.entry.messageType,
              senderId: mesg.entry.senderId,
              currentUserProfile: mesg.entry.currentUserProfile,
              currentDate: mesg.entry.currentDate,
              typeMessage: mesg.entry.typeMessage,
            },
          });
        }
        const groups = this.messages.reduce((groups: any, game: any) => {
          const date = game.message.currentDate;
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(game);
          return groups;
        }, {});
        this.newMessages = Object.keys(groups).map((date) => {
          return {
            date,
            mesg: groups[date],
          };
        });
        setTimeout(() => {
          $('#div1').animate(
            { scrollTop: $('#div1').prop('scrollHeight') },
            1000
          );
        }, 20);
      })
      .catch((error: any) => { }
      );
  }

  openModal() {
    this.comingId = null;
    this.showAllChatUserModal = true;
    this.getAllChatUsers(this.pageNo, this.pageSize);
  }

  getFileType() {
    if (this.videoExts.includes(this.fileName.split('.')[1].toLowerCase())) {
      return 'video';
    } else if (
      this.imageFileExt.includes(this.fileName.split('.')[1].toLowerCase())
    ) {
      return 'image';
    } else {
      return 'doc';
    }
  }

  async onFileSelect(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.showUploadDocUi = true;
          this.uploadDoc = <string>(<FileReader>event.target).result;
          this.SelectFileType = this.file.type;
          this.pdfSrc = reader.result;
        };
        reader.readAsDataURL(event.target.files[i]);
      }
      this.SelectFileType = this.file.type;
      this.fileName = this.file.name;
    }
    var id = "id" + Math.random().toString(16).slice(2)
    this.result = this.pubnub.getFileUrl({
      channel: this.newChannelName,
      id: id,
      name: this.fileName,
    });
    console.log(this.result, 'URL')
  }


  async getUnreadedMessages(channels: any, tokens: any) {
    return new Promise<void>(async resolve => {
      this.pubnub.messageCounts({
        channels: null,
        channelTimetokens: tokens,
      }).then((res: any) => {
        resolve()
      })
        .catch((error: void | PromiseLike<void>) => resolve(error));
    });
  }

  saveLastChatDetails() {
    if (this.timeDataArray.length) {
      for (var i = 1; i < this.timeDataArray.length; i++) {
        let body = {
          "channelName": this.timeDataArray[i] ? this.timeDataArray[i].channel : '',
          "message": this.timeDataArray[i] ? this.timeDataArray[i].message : '',
          "messageTime": new Date(this.timeDataArray[i] ? this.timeDataArray[i].timeCount : ''),
          "messageType": this.timeDataArray[i] ? this.timeDataArray[i].messageType : '',
          "senderId": this.timeDataArray[i] ? this.timeDataArray[i].senderId : '',
        }
        this._MainService.saveLastChatDetails(body).then((data: any) => {
          if (data && data.isSuccess) { }
          else { }
        })
      }
    }
  }

  sendChatNotification(message: any, time: any, senderId: any) {
    let body = {
      "message": message,
      "messageTime": new Date(time),
      "receiverId": this.receiverId,
      "senderId": parseInt(senderId)
    }
    this._MainService.sendChatNotification(body).then((data: any) => {
      if (data && data.isSuccess) { }
      else { }
    })
  }

  // deleteMessage() {
  //   // console.log(Date.now());
  //   // this.deleteChatTime = Date.now();

  //   // this._MainService.deleteSingleUserChat(this.newChannelName, this.deleteChat).then((data: any) => {
  //   //   if (data && data.isSuccess) {
  //   //     this.showChatScreen = false;
  //   //     // this.comingId = this.receiverId;
  //   //     this.getUserHistory();
  //   //   } else { }
  //   // })

  //   this.pubnub.deleteMessages(
  //     {
  //       channel: this.newChannelName,
  //     },
  //     (status: any, response: any) => {
  //       this._NotifierService.showSuccess('Message deleted successfully');
  //       this.getChatHistory(this.newChannelName);
  //     }
  //   );
  // }

}


  //  getChatHistory(name: any){
  //   this.pubnub.fetchMessages(
  //     {
  //         channels: [name],
  //         count: 100,
  //         stringifiedTimeToken: true,
  //         includeMeta: true,
  //         includeMessageActions: true,
  //     },
  //      (status:any, response:any) => {
  //         this.messageHistoryData = response;
  //         for (let mesg of this.messageHistoryData) {
  //                   this.messages.push({
  //                     message: {
  //                       messageTime: mesg.entry.messageTime,
  //                       messageType: mesg.entry.messageType,
  //                       senderId: mesg.entry.senderId,
  //                       currentUserProfile: mesg.entry.currentUserProfile,
  //                       currentDate: mesg.entry.currentDate,
  //                       typeMessage: mesg.entry.typeMessage,
  //                     },
  //                   });
  //                 }
  //                 const groups = this.messages.reduce((groups: any, game: any) => {
  //                   const date = game.message.currentDate;
  //                   if (!groups[date]) {
  //                     groups[date] = [];
  //                   }
  //                   groups[date].push(game);
  //                   return groups;
  //                 }, {});
  //                 this.newMessages = Object.keys(groups).map((date) => {
  //                   return {
  //                     date,
  //                     mesg: groups[date],
  //                   };
  //                 });

  //     }
  // );

  //  }





  // async sendMessage() {
  //   this.pubnub.subscribe({
  //     channels: [this.newChannelName],
  //     withPresence: true,
  //     presence: function (data: any) { },
  //     triggerEvents: ['message', 'presence', 'status'],
  //   });
  //   let time2 = {
  //     'timeCount': Date.now(),
  //     'channel': this.newChannelName,
  //     'messageType': 'TEXT',
  //     'message': this.emojiText,
  //     'senderId': this.currentUser
  //   }
  //   this.timeDataArray[this.indexValue].timeCount = time2.timeCount;
  //   this.timeDataArray[this.indexValue].channel = time2.channel;
  //   this.timeDataArray[this.indexValue].messageType = time2.messageType;
  //   this.timeDataArray[this.indexValue].message = time2.message;
  //   this.timeDataArray[this.indexValue].senderId = time2.senderId;
  //   let message = this.emojiText;
  //   let time = Date.now();
  //   var date = new Date().toDateString();
  //   let senderId = this.currentUser;
  //   if (message) {
  //     const newMessage = {
  //       messageType: 'TEXT',
  //       typeMessage: this.emojiText,
  //       messageTime: time,
  //       currentUserProfile: this.currentUserProfile,
  //       currentDate: date,
  //       senderId: senderId,
  //     };
  //     try {
  //       const result = await this.pubnub.publish({
  //         message: newMessage,
  //         channel: this.newChannelName,
  //         storeInHistory: true,
  //       });
  //     } catch (status) { }
  //     this.getUserHistory();
  //   }
  //   setTimeout(() => {
  //     $('#div1').animate({ scrollTop: $('#div1').prop('scrollHeight') }, 1000);
  //   }, 20);
  //   // if (this.notificationApiCall) {
  //     this.sendChatNotification(this.emojiText, time, senderId)
  //   // }
  //   this.emojiText = '';
  // }



  // for (let i = 0; i < this.historyUser.length; i++) {
        //   this.userChannels = this.historyUser[i].channelName;
        //   this.allUserChannels.push( this.userChannels);
        //   var timeToken =  Date.parse(this.historyUser[i].messageTime);
        //   this.lastTimeToken.push(timeToken);
        //   this.pubnub.hereNow(
        //     {
        //       channels: [this.historyUser[i].channelName],
        //       includeUUIDs: true,
        //       includeState: true,
        //     },
        //     (status: any, response: any) => {
        //       this.pressenceData = response;
        //       this.pressenceArray.push(this.pressenceData)
        //     }
        //   );
        //  }
