import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { Router } from '@angular/router';
import { HomeComponent } from '../home.component';

@Component({
  selector: 'app-artist-see-allnotification',
  templateUrl: './artist-see-allnotification.component.html',
  styleUrls: ['./artist-see-allnotification.component.scss'],
})
export class ArtistSeeALLNotificationComponent implements OnInit {
  notificationContainer: any;
  notification: any;
  activeNotificationTab = 0;
  showAll: boolean;
  individual: boolean;
  group: boolean;
  notificationDetails: any;
  notificationData:boolean;
  pageNo: number = 0;
  pageSize: number = 10;
  currentPage = 0;

  constructor(
    private _MainService: MainService,
    private _NotifierService: NotifierService,
    private router: Router,
    private homeComponent: HomeComponent
  ) {
    this.notificationData = false;
    this.notificationContainer = [];
    this.showAll = false;
    this.individual = false;
    this.group = false;
  }

  ngOnInit(): void {
    this.seeAllNotifications('ALL', this.pageNo, this.pageSize)
  }

  seeAllNotifications(title: any,pageNo: number, pageSize: number) {
    this.notificationData = true;
    this.notificationDetails = null;
    this._MainService.seeAllNotifications(title, pageNo,pageSize).then((data) => {
      if (data && data.isSuccess) {
        this.notificationData = false;
        this.notificationDetails = data.data.notificationDetails;
      } else {
        this.notificationData = false;
        this._NotifierService.showError(data.message);
      }
    });
  }


  onScroll() {
    this.currentPage = this.currentPage + 1;
    this._MainService.seeAllNotifications('ALL', this.currentPage,this.pageSize).then((data) => {
      if (data && data.isSuccess) {
       this.notificationDetails = [...this.notificationDetails, ...data.data.notificationDetails];
       }
    })
  }

  markRead(id: any, read: any, type: any) {
     if (read === false) {
      this._MainService.markRead(id).then((data) => {
        if (data && data.isSuccess) {
          this.seeAllNotifications(type,this.pageNo,this.pageSize);
          this._NotifierService.showSuccess(data.message);
          this.homeComponent.ngOnInit();
        } else {
          this._NotifierService.showError(data.message);
        }
      });
    }
  }

  removeNotification(id: any) {
    this._MainService.removeNotification(id).then((data) => {
      if (data && data.isSuccess) {
        // this.getNotifications();
        this.seeAllNotifications('ALL',this.pageNo,this.pageSize);
        this._NotifierService.showSuccess(data.message);
      } else {
        this._NotifierService.showError(data.message);
      }
    });
  }

  navigateOn(type: any, pId: any, eId: any) {
    if (type === "PROPOSAL_ACCEPTED" || type === "PROPOSAL_DECLINED" || type === "CONTRACT_ACCEPTED" || type === "PROPOSAL_SUBMITTED" || type === "PENDING_PAYMENT"
      || type === "CONTRACT_CANCELLED" || type === "EVENT_CANCELLED" || type === "CONTRACT_DECLINED") {
      this.router.navigate(['/ArtistDashboard/artistEventDetail'], { queryParams: { proposalId: pId, eventId: eId, eventType: type } })
    } else if (type === "HIRING_PENDING" || type === "LIVE_EVENT") {
      this.router.navigate(['/ArtistDashboard/artistEventDetail'], { queryParams: { eventId: eId, goLive: true} })
    } else if (type === "ARTIST_INVITATION") {
      this.router.navigate([`/ArtistDashboard/gigBoard`], { queryParams: { eventId: eId, eventType: type } })
    } else if (type === "ACCOUNT_BAN") {
      this.router.navigate([`/ArtistDashboard/artistHome`])
    } else if (type === "SHARED_LIVE_EVENTS") {
      this.router.navigate(['/ArtistDashboard/watchLive'], { queryParams: { id: eId, type: 'invite' } })
    }
  }
}
