<div class="joinpage">
  <div class="joinhead">
    <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg" routerLink="" style="cursor: pointer;">
    <div class="nav-item dropdown hgne" style="top: -2px;display: flex; float: right;">
      <a class="nav-link dropdown-toggle" (click)="logOut = !logOut" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <img src="assets/dashboard/Mask Group.png">
      </a>
      <div *ngIf="logOut" class="" style="position: absolute;left: -63px;top: 41px;; border: 1px solid white; border-radius: 15px; background: black;" aria-labelledby="navbarDropdownMenuLink">
        <a class="dropdown-item" style="cursor: pointer;color: white;background: none;" (click)="logoutUser()"  ><i class="fa fa-sign-out"
            aria-hidden="true" ></i> Log Out</a>
      </div>
    </div>
  </div>
  <div class="container joinbody">
    <div class="ge">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xl-6">
          <div class="joinleft">
            <app-slider></app-slider>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xl-6 contents">
          <div class="row ">
            <div class="col-md-10">
              <div class="wi-4">
                <app-tabsall [activeTab]="5"></app-tabsall>
                <div class="tab-content" id="pills-tabContent">
                  <form class="oik" [formGroup]="mediaDetailsForm">
                    <div class="form-group ">
                      <div class="row">
                        <div class="col-6 nonRequired">
                          <label for="username">Upload Images</label>
                          <label class="up_i lbf" style="text-align: center; margin: 0px;" (click)="showUploaModal = true;"
                            data-toggle="modal" data-target=".image-modal" data-backdrop="static" data-keyboard="false">
                            <span class="brw" *ngIf="!showImage ">
                              <img src="assets/upload_video_icon.svg" class="img-fluid" height="15" width="15">
                              <span class="fot"> Browse to upload</span>
                            </span>
                            <ng-container *ngIf="imageSubmitting; else imageSubmittingDone">
                              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                              Loading...
                            </ng-container>
                            <ng-template #imageSubmittingDone>
                              <span lass="file-info">{{fileName}}</span>
                            </ng-template>
                          </label>
                          <img src="assets/dashboard/war.svg" (mouseover)="hintImg = true" (mouseleave)="hintImg = false" ><small *ngIf="hintImg" class="pl-2" style="color: white; font-size: 10px;">Image file size under 20Mb</small>
                        </div>
                        <div class="col-6 nonRequired">
                          <label for="password">Upload Videos</label>
                          <label class="up_i lbf" style="text-align: center; margin: 0px;" (click)="videoModal = true; showUploaModalvid = true;"
                            data-backdrop="static" data-keyboard="false">
                            <span class="brw" *ngIf="!showImagevid">
                              <img src="assets/upload_video_icon.svg" class="img-fluid" height="15" width="15">
                              <span class="fot"> Browse to upload</span>
                            </span>
                            <ng-container *ngIf="videoSubmitting; else videoSubmittingDone">
                              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                              Loading...
                            </ng-container>
                            <ng-template #videoSubmittingDone>
                              <span *ngIf="fileNamevid" lass="file-info">{{fileNamevid.slice((fileNamevid.length-25),
                                fileNamevid.length)}}</span>
                            </ng-template>
                          </label>
                           <img src="assets/dashboard/war.svg" (mouseover)="hint = true" (mouseleave)="hint = false" ><small *ngIf="hint" class="pl-2" style="color: white; font-size: 10px;">Video file size limit 1Gb. Recommended size under 250Mb</small>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="Browse to upload">Upload Profile Picture</label>
                      <label class="up_a lbf" style="text-align: center;" (click)="showUploaModalavt = true;"
                        data-toggle="modal" data-target=".avtar-modal" data-backdrop="static" data-keyboard="false">
                        <span class="brw" *ngIf="!showImageavt">
                          <img src="assets/upload_video_icon.svg" class="img-fluid" height="15" width="15">
                          <span class="fot"> Browse to upload</span>
                        </span>
                        <ng-container *ngIf="avatarSubmitting; else avatarSubmittingDone">
                          <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...
                        </ng-container>
                        <ng-template #avatarSubmittingDone>
                          <span lass="file-info" *ngIf="!avatarUploaded">{{fileNameavt}}</span>
                        </ng-template>
                      </label>
                    </div>
                    <input id='browse' type='file' style='width:0px'>
                    <div class="form-group social-links">
                      <label for="password" class="lbf">Links</label>
                      <!-- <div class="form-group row">
                        <label for="inputEmail3" class="col-4 pb-r col-form-label lbf">Public
                          Videos</label>
                        <div class="col-8 pb-l add-label-wrapper">
                          <input type="text" class="ge-fk" formControlName='publicVideos' placeholder="www.sds.com">
                          <span style="color: white;" (click)="socialMedia = true">
                            <a class="cmvk" (click)="showUploaModal = true;" style="cursor: pointer;">+ Add More</a>
                          </span>
                        </div>
                        <small class="text-danger"
                          *ngIf="mediaDetailsForm.controls.publicVideos.invalid &&(mediaFormSubmitting ||mediaDetailsForm.controls.publicVideos.touched)">
                          <span
                            *ngIf="mediaDetailsForm.controls['publicVideos'].hasError('pattern') || mediaFormSubmitting">Please
                            enter valid url</span>
                        </small>
                      </div>
                      <div class="form-group row">
                        <label for="inputPassword3" class="col-4 pb-r col-form-label lbf">Social
                          Videos</label>
                        <div class="col-8 pb-l add-label-wrapper">
                          <input type="text" formControlName="socialVideos" class="ge-fk"
                            placeholder="www.instagram.com">
                          <span style="color: white;" (click)="socialVideosaddmore = true">
                            <a class="cmvk" (click)="socialVideosaddmore = true;" style="cursor: pointer;">+ Add
                              More</a>
                          </span>
                        </div>
                        <small class="text-danger"
                          *ngIf="mediaDetailsForm.controls.socialVideos.invalid &&(mediaFormSubmitting ||mediaDetailsForm.controls.socialVideos.touched)">
                          <span
                            *ngIf="mediaDetailsForm.controls['socialVideos'].hasError('pattern') || mediaFormSubmitting">Please
                            enter valid url</span>
                        </small>
                      </div> -->
                      <div class="form-group row">
                        <label for="inputPassword3" class="col-4 pb-r col-form-label lbf">Your Website</label>
                        <div class="col-8 pb-l add-label-wrapper">
                          <input type="text" formControlName="websites" class="ge-fk" placeholder="www.instagram.com">
                          <span style="color: white;" (click)="yourWebsite = true;">
                            <a class="cmvk" (click)="showUploaModal = true;" style="cursor: pointer;">+ Add More</a>
                          </span>
                        </div>
                        <small class="text-danger"
                          *ngIf="mediaDetailsForm.controls.websites.invalid &&(mediaFormSubmitting ||mediaDetailsForm.controls.websites.touched)">
                          <span *ngIf="mediaDetailsForm.controls['websites'].hasError('pattern') || mediaFormSubmitting">Please
                            enter valid url</span>
                        </small>
                      </div>
                    </div>
                    <div class="form-group row pd_rl">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck" formControlName="iConfirmed"
                          [ngClass]="{ 'is-invalid': submitted && this.mediaDetailsForm.controls.iConfirmed.errors }" style="accent-color: #12AD2B;">
                        <label class="form-check-label" for="gridCheck"> I confirm that I am over 18 years of age</label>
                      </div>
                      <div class="text-danger" *ngIf="mediaDetailsForm.controls['iConfirmed'].hasError('required')"> This field is required</div>
                    </div>
                    <div class="form-group row pd_rl">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck1" formControlName="TermsofService" style="accent-color: #12AD2B;"
                          [ngClass]="{ 'is-invalid': submitted && this.mediaDetailsForm.controls.TermsofService.errors }">
                        <label class="form-check-label" for="gridCheck1">I agree to Virtuosica Terms of Service</label>
                      </div>
                      <div class="text-danger" *ngIf="mediaDetailsForm.controls['TermsofService'].hasError('required')">
                        This field is required</div>
                    </div>
                    <div class="form-group row pd_rl">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck2" formControlName="isCorrect" style="accent-color: #12AD2B;"
                          [ngClass]="{ 'is-invalid': submitted && this.mediaDetailsForm.controls.isCorrect.errors }">
                        <label class="form-check-label" for="gridCheck2"> I confirm that all information submitted here is correct </label>
                      </div>
                      <div class="text-danger" *ngIf="mediaDetailsForm.controls['isCorrect'].hasError('required')">This field is required</div>
                    </div>
                    <div class="form-group for-str kdjnb">
                      <button class="form-control yht" [routerLink]="['/production']">Back</button>
                      <button type="submit" class="form-control nxt" (click)="mediaDetails()">
                        <ng-container *ngIf="mediaFormSubmitting; else mediaFormSubmittingDone">
                          <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...
                        </ng-container>
                        <ng-template #mediaFormSubmittingDone> Next </ng-template>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- image modal -->
<div class="modal fade image-modal ge" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content bg_md">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Upload Image</h5>
        <button type="button" class="close close-btn" (click)="showUploaModal = false;" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true" class="clo">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-0">
        <div class="row">
          <div class="col-md-12">
            <div class="image-upload-wrapper">
              <div class="logo-wrapper">
                <img src="../../../assets/icons/image-upload.png" alt="" (click)="file.click()">
              </div>
              <p> Click to browse </p>
            </div>
            <ng-container *ngIf="imageUploaded; else imageUploadedDone">
              <p style="color: #FF6720;"> <i class="fa fa-spinner" aria-hidden="true"></i> Uploading...</p>
            </ng-container>
            <ng-template #imageUploadedDone>
                <div *ngFor="let image of imageArray let myIndex = index;">
                  <div class="innerModalImg mt-2" >
                    <img *ngIf="showImage" class="certificateImage" [src]="image">
                    <i *ngIf="showImage" class="fa fa-trash-o" aria-hidden="true" style="position: relative; right: 4px; top: -165px; color: red; cursor: pointer;" (click)="removeImage(myIndex)"></i>
                  </div>
              </div>
            </ng-template>
           <input class="img-fluid" type="file" #file style="display: none;" (change)="onFileSelect($event)" accept="image/*">
            <p class="info">
              <img src="../../../assets/icons/info.png" alt="">
              Please upload at least one image. You must own or have rights to use these images.
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer fm-bt border-0 pb_t pt-0">
        <button type="button" class="btn btn-primary col-6" data-dismiss="modal" [disabled]="!this.imageSrc">Submit</button>
      </div>
    </div>
  </div>
</div>
<!-- end of image modal -->

<!-- avtar modal -->
<div class="modal fade avtar-modal ge" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content bg_md upladModal">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Upload Profile Picture</h5>
        <button type="button" class="close close-btn" (click)="showUploaModalavt = false;" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true" class="clo">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-0">
        <div class="row">
          <div class="col-md-12">
            <div>
              <div class="innerModals" *ngIf="!showImageavt" (click)="fileavt.click()">
                <div class="logo-wrapper">
                  <img src="../../../assets/icons/image-upload.png" alt="" >
                </div>
                <p>Click to browse</p>
              </div>
              <div class="image-wrapper">
                <ng-container *ngIf="avatarUploaded; else avatarUploadedDone">
                  <p style="color: #FF6720;"> <i class="fa fa-spinner" aria-hidden="true"></i> Uploading... </p>
                </ng-container>
                <ng-template #avatarUploadedDone>
                  <div *ngIf="showDragDrop" class="ml-5 pl-3">
                    <image-cropper style="max-width: 80%;" [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true" [containWithinAspectRatio]="containWithinAspectRatio"
                    [aspectRatio]="16 / 9" [resizeToWidth]="256" [cropperMinWidth]="169"
                    [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform"
                    [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"
                ></image-cropper>
                </div>
                <div class="innerModalImg pt-1">
                  <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" *ngIf="showDragDrop" class="certificateImage">
                  <img *ngIf="showImageavt && !showDragDrop && !showPreviousImage" [src]="croppedImage" class="certificateImage">
                  <img *ngIf="showPreviousImage" [src]="imageSrcavt" class="certificateImage">
                  <span class="fhnbi-c" style="color: #FF6720;cursor:pointer;" *ngIf="showImageavt"
                    (click)="showImageavt = false"></span>
                  <i *ngIf="showImageavt" class="fa fa-trash-o" aria-hidden="true" style="position: relative; right: 4px; top: -162px; color: red; cursor: pointer;" (click)="showImageavt = false;showDragDrop = false; fileNameavt = '';avatarUrl = ''"></i>
                </div>
              </ng-template>
              </div>
              <input class="img-fluid" type="file" #fileavt style="display: none;" (change)="onFileSelectavt($event)" accept="image/*">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer fm-bt border-0 pb_t pt-1">
        <div class="row" style="width: 100%;">
          <div class="offset-3 col-6">
            <button  type="submit"  class="btn btn-primary" style="width: 100%;" (click)="cropDone()" *ngIf="showDragDrop" >Submit</button>
            <button type="button" class="btn btn-primary" style="width: 100%;" data-dismiss="modal"  *ngIf="!showDragDrop" [disabled]="!this.avatarUrl">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- video modal -->
<div *ngIf="videoModal == true" class="video-modal ge">
  <div class="modal-sm" role="document">
    <div class="modal-content bg_md">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Upload Videos</h5>
        <button type="button" (click)="closeVideoModal()" class="close close-btn">
          <span aria-hidden="true" class="clo">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-1">
        <form [formGroup]="videoUploadForm">
          <div class="form-group ">
            <label for="username">Video Title</label>
            <input type="text" class="ge-fk ti" formControlName="videoTitle"
              placeholder="What is the title of your video">
          </div>
          <div class="form-group batski">
            <label for="username">Add Video Tags</label>
            <mat-form-field class="example-chip-list" appearance="outline">
              <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip *ngFor="let fruit of fruits" (removed)="remove(fruit)">
                  {{fruit}}
                  <mat-icon matChipRemove>close</mat-icon>
                </mat-chip>
                <input #fruitInput [formControl]="fruitCtrl" [matChipInputAddOnBlur]="addOnBlur"
                  [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event)">
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="form-group mb-0">
            <div class="row">
              <div class="col-md-12">
                <div class="image-upload-wrapper" (click)="file3.click()">
                  <div class="logo-wrapper">
                    <img src="../../../assets/icons/image-upload.png"  alt="">
                  </div>
                  <p> Click to browse </p>
                </div>
                <ng-container *ngIf="videoUploaded; else videoUploadedDone">
                  <p style="color: #FF6720;"> <i class="fa fa-spinner" aria-hidden="true"></i>
                    Uploading... </p>
                </ng-container>
                <ng-template #videoUploadedDone>
                  <div class="row" style="width: 100%; margin-bottom: 14px;">
                    <div class="col-12">
                      <div class="mt-2 innerModalImg" >
                        <video *ngIf="showImagevid" class="certificateImage" [src]="imageSrcvid" type="video/mp4" controls></video>
                        <span style="color: #FF6720;cursor:pointer;" *ngIf="showImage" (click)="showImage = false"></span>
                        <i *ngIf="showImagevid" class="fa fa-trash-o" aria-hidden="true" style="position: relative; right: 4px; top: -170px; color: red; cursor: pointer;"
                        (click)="deleteVideo()"></i>
                      </div>
                    </div>
                  </div>
                </ng-template>
                  <input class="img-fluid" type="file" #file3 style="display: none;" accept="video/*" (change)="onFileSelectvid($event)">
                <p class="info m-0">
                  <img src="../../../assets/icons/info.png" alt="">
                  Please upload at least one video. You must own or have rights to use these video.
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer fm-bt border-0 pb_t pt-0">
        <div class="row" style="width: 100%;">
          <div class="offset-3 col-6">
            <button type="button" [disabled]="!this.videoUrl" class="btn btn-primary" style="width: 100%;" (click)="fromSubmit()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end of video modal -->
<div class="modal" [ngClass]="{'d-block': socialMedia, 'd-none': !socialMedia}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <form class="oik">
          <div class="form-group">
            <div class="row">
              <div class="col-8 pb-l add-label-wrapper">
                <input type="text" class="ge-fk2" [(ngModel)]="publicVideo" placeholder="www.sds.com" style="width: 165%; color: black !important;">
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary nxt" (click)="savePublicVideo()" style="height: 40px;">Save changes</button>
        <button type="button" class="btn btn-secondary yht" (click)="socialMedia = false" style="height: 42px;width: 86px;">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': socialVideosaddmore, 'd-none': !socialVideosaddmore}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <form class="oik">
          <div class="form-group">
            <div class="row">
              <div class="col-8 pb-l add-label-wrapper">
                <input type="text" class="ge-fk2" [(ngModel)]="socialVideo" placeholder="www.sds.com"
                  style="width: 165%; color: black !important;">
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary nxt" (click)="saveSocialVideo()" style="height: 40px;">Save
          changes</button>
        <button type="button" class="btn btn-secondary yht" (click)="socialVideosaddmore = false"
          style="height: 42px;width: 86px;">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': yourWebsite, 'd-none': !yourWebsite}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <form class="oik" [formGroup]="WebsiteForm">
          <div class="form-group">
           <div class="row">
              <div class="col-8 pb-l add-label-wrapper">
                <input type="text" class="ge-fk2" formControlName="websiteLink" [(ngModel)]="websiteLink" placeholder="https://www.sds.com"
                  style="width: 165%; color: black !important;">
              </div>
            </div>
            <small class="text-danger" *ngIf="WebsiteForm.controls.websiteLink.invalid &&(WebsiteForm.controls.websiteLink.touched)">
            <span *ngIf="WebsiteForm.controls['websiteLink'].hasError('pattern')">Please enter valid url</span>
          </small>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary nxt" [disabled]="WebsiteForm.invalid" (click)="saveWebsiteLink()" style="height: 40px;">Save changes</button>
        <button type="button" class="btn btn-secondary yht" (click)="yourWebsite = false" style="height: 42px;width: 86px;">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': showLogoutModal, 'd-none': !showLogoutModal}">
  <div class="modal-dialog">
    <div class="modal-content modalBg">
      <div class="modal-body">
        <div class="container">
          <div class="row text-center px-5 pt-5" style="color: white;">
            <div class="col-12">
              <p>You have to log in again because your profile has been changed from audience to artist </p>
              <button type="button" class="btn ytgft sendButton" style="padding: 10px 47px;margin-right:5px !important"
                (click)="logoutUser()">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
