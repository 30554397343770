import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from '../../../service/auth-service.service';
import { MustMatch } from '../../artist-signup/password validation/confirm-validation';
import { NotifierService } from '../../../service/helper/notifier.service';
import { CryptoService } from 'src/app/service/helper/crypto.service';
import { MainService } from 'src/app/service/main.service';
import { environment } from 'src/environments/environment';


declare var hbspt: any
@Component({
  selector: 'app-audience-signup',
  templateUrl: './audience-signup.component.html',
  styleUrls: ['./audience-signup.component.scss']
})
export class AudienceSignupComponent implements OnInit {
  audienceForm: FormGroup;
  hide = true;
  refrelCode: any;
  showModal: boolean = false;
  sendConBtn: boolean = false;


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthServiceService,
    private _NotifierService: NotifierService,
    private _CryptoService: CryptoService,
    private _mainService: MainService,
  ) {
    this.audienceForm = fb.group({
      fullName: [
        '',
        [
          Validators.compose([
            Validators.required,
            Validators.maxLength(25),
            Validators.minLength(3),
          ]),
        ],
      ],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.*\w))$/)])],
      password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)]],
      confirmPassword: ['', Validators.required],
      userType: ['Audience'],
      olderThan18: [false, Validators.requiredTrue],
      tncAgreed: [false, Validators.requiredTrue],
      getUpdate: [false],
    },
      {
        validator: MustMatch('password', 'confirmPassword'),
      });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    hbspt.forms.create({
      portalId: "9406878",
      formId: "8e3e2c75-6d63-40b1-96da-6f8bf68297ab",
      target: "#hubspotForm"
    });
  }



  audienceArtist() {
    let confirmPassword = this.audienceForm.controls['confirmPassword'].value;
    let email = this.audienceForm.controls['email'].value;
    let fullName = this.audienceForm.controls['fullName'].value;
    let getUpdate = this.audienceForm.controls['getUpdate'].value;
    let olderThan18 = this.audienceForm.controls['olderThan18'].value;
    let password = this.audienceForm.controls['password'].value;
    let tncAgreed = this.audienceForm.controls['tncAgreed'].value;
    let userType = this.audienceForm.controls['userType'].value;
    if (!fullName) {
      this._NotifierService.showError("Full Name is required")
    } else if (!email) {
      this._NotifierService.showError("Email is required")
    } else if (!password) {
      this._NotifierService.showError("Password is required")
    } else if (!confirmPassword) {
      this._NotifierService.showError("Please confirm password")
    } else if (!olderThan18) {
      this._NotifierService.showError("Please Select I am 18 or older ")
    } else if (!tncAgreed) {
      this._NotifierService.showError("Please Select  I agree to the terms & conditions")
    } else if (fullName && email && password && confirmPassword && olderThan18 && tncAgreed) {
      this.sendConBtn = true;
      let body = {
        "confirmPassword": confirmPassword,
        "email": email,
        "fullName": fullName,
        "getUpdate": getUpdate,
        "olderThan18": olderThan18,
        "password": password,
        "tncAgreed": tncAgreed,
        "userType": userType
      }
      this.auth.signup(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.submitHubspotForm();
          this._NotifierService.showSuccess(data.message);
          this.audienceForm.reset();
          this.router.navigate([``], {
            queryParams: {
              'showModal': this._CryptoService.encrypt('true'),
            }
          });
        } else
          this._NotifierService.showError(data.message);
        this.sendConBtn = false;
      })
    }
  }

  submitHubspotForm() {
    let email = this.audienceForm.controls['email'].value;
    let fullName = this.audienceForm.controls['fullName'].value;
    let body = {
      "submittedAt": Date.now(),
      "fields": [
        {
          "name": "email",
          "value": email
        },
        {
          "name": "firstname",
          "value": fullName
        }
      ],
      "context": {
        "pageUri": environment.hubspotNavigateUrl + '/audience-signup',
        "pageName": "AUDIENCE User Reg"
      },

    }
    this._mainService.submitHubspotForm('9406878', 'd3aefdd5-5583-4d76-b4e5-967e287b245f', body).then((data) => {
      if (data) {
        console.log(data.inlineMessage, 'hubspot')
      }
    })
  }

}
