import { Component, OnInit, VERSION, ViewChild, ElementRef } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MainService } from 'src/app/service/main.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { RefreshService } from 'src/app/service/refresh.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import SwiperCore, { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { debounceTime } from 'rxjs/operators';
import { AuthServiceService } from 'src/app/service/auth-service.service';
import { UserService } from 'src/app/service/user.service';
import { LoginModal } from 'src/app/service/models/auth.modals';

declare const Swiper: any;
SwiperCore.use([Autoplay, Pagination, Navigation]);

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export interface Fruit {
  name: string;
}


@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class GeneralComponent implements OnInit {
  @ViewChild("placesRef") placesRef!: GooglePlaceDirective;
  certificateForm!: FormGroup;
  generalDetailsForm!: FormGroup;
  visible = true;
  newToken: any;
  interval: any;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [COMMA];
  generalFormSubmitting!: boolean;
  socialVideosaddmore: boolean;
  imageUploaded: boolean;
  previewCertificate: any;
  fruits: string[] = [];
  fruitCtrl = new FormControl();
  options = {
    types: ['address'],
    componentRestrictions: { country: ['US'] }
  };
  addOnBlur = true;
  location: string[] = [];
  locationCtrl = new FormControl();
  timeZoneArray: any = [];
  locationlength: any
  searchTimeZone: string[] = [];
  login: LoginModal;
  browser: any;
  logOut: boolean = false


  locationAddress: any;

  @ViewChild('countryInput')
  countryInput!: ElementRef<HTMLInputElement>;
  @ViewChild('auto')
  matAutocomplete!: MatAutocomplete;

  yearFrom = new FormControl(moment());
  yearTo = new FormControl(moment());
  modal: any;
  cerificateSubmitting: boolean;
  imageSrc!: string;
  fileName: any;
  showImage: boolean;
  imageUrl: any;
  certificateUrl: any;
  showUploaModal: boolean;
  generalDetails: any;
  Certificated: any;
  CertificatedDetails: any;
  educationValue: any;
  preSlectedValues: any;
  @ViewChild('timeZoneInput')
  timeZoneInput!: ElementRef<HTMLInputElement>;
  timeZoneCtrl = new FormControl();


  YearFrom(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    const ctrlValue = this.yearFrom.value;
    ctrlValue.year(normalizedYear.year());
    this.yearFrom.setValue(ctrlValue);
  }

  YearTo(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    const ctrlValue = this.yearTo.value;
    ctrlValue.year(normalizedYear.year());
    this.yearTo.setValue(ctrlValue);
  }

  handleAddressChange(address: Address) {
    this.location.push(address.formatted_address)
    this.locationCtrl.setValue(null);
    this.mainservice.getTimeZone(address.address_components[3].long_name).then((data: any) => {
      if (data && data.isSuccess ) {
        this.timeZoneArray = data.data
      }
    })
  }

  remove(address: string): void {
    const index = this.location.indexOf(address);
    if (index >= 0) {
      this.location.splice(index, 1);
    }
  }

  generalForm!: FormGroup;

  // file upload
  fileInfo!: string;

  /**
   * Called when the value of the file input changes, i.e. when a file has been
   * selected for upload.
   *
   * @param input the file input HTMLElement
   */
  onFileSelect(input: HTMLInputElement): void {

    /**
     * Format the size to a human readable string
     *
     * @param bytes
     * @returns the formatted string e.g. `105 kB` or 25.6 MB
     */
    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;

      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }

      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }

    const file = input.files![0];
    this.fileInfo = `${file.name} (${formatBytes(file.size)})`;
  }

  @ViewChild('select')
  select!: MatSelect;

  allSelected = false;
  foods: any[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' }
  ];
  educationList: any = [];
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  constructor(
    private mainservice: MainService,
    private fb: FormBuilder,
    private notifier: NotifierService,
    private router: Router,
    private _refresh: RefreshService,
    private _SessionService: SessionService,
    private _UserService:UserService,
    private _auth:AuthServiceService,
  ) {
    this.socialVideosaddmore = false;
    this.showUploaModal = false;
    this.imageUploaded = false;
    this.cerificateSubmitting = false;
    this.showImage = false;
    this.login = new LoginModal();
    this.timeZoneCtrl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      if (value) {
        this.mainservice.getTimeZone('',value).then((res: any) => {
          if (res && res.isSuccess) {
            this.timeZoneArray = res.data;
          }
        })
      }
    })

  }

  ngOnInit(): void {
    this.getGeneralDatails();
    let refreshTokenLog: any[] = [];
    this.interval = setInterval(() => {
      this.refreshToken();
      refreshTokenLog.push({ message: 'refresh token api called', dateTime: new Date() });
      localStorage.setItem('refreshTokenLog', JSON.stringify(refreshTokenLog));
    }, 1500000);
    this.browser = this.login.device;
    this.getEducationList();
    this.certificateForm = this.fb.group({
      certificateName: new FormControl('', Validators.required),
      certificateUrl: new FormControl('', Validators.required),
      issuingOrganization: new FormControl('', Validators.required),

    });
    this.mainservice.getTimeZone('','A').then((res: any) => {
      if (res && res.isSuccess) {
        this.timeZoneArray = res.data;
      }
    })

    this.generalDetailsForm = this.fb.group({
      displayName: new FormControl('', Validators.required),
      educationDetails: new FormControl(this.preSlectedValues),
      experience: new FormControl('', Validators.required),
      timeZone: new FormControl('', Validators.required),
      // educationDetails: new FormControl(this.educationValue),
      // countriesCtrl: new FormControl('',Validators.required)
    });


  }

  removeTimeZone(timeZone: string): void {
    const index = this.searchTimeZone.indexOf(timeZone);
    if (index >= 0) {
      this.searchTimeZone.splice(index, 1);
    }
    this.generalDetailsForm.controls.timeZone.setValue(this.searchTimeZone)
  }
  selectedTimeZone(event: MatAutocompleteSelectedEvent): void {
    this.searchTimeZone[0] = (event.option.viewValue);
    this.generalDetailsForm.controls.timeZone.setValue(this.searchTimeZone)
    this.timeZoneInput.nativeElement.value = '';
    this.timeZoneCtrl.setValue(null);
  }


  ngAfterViewInit() {
    this.placesRef.options.componentRestrictions = { country: 'US' }
    // this.placesRef.options.fields = ["formatted_address", "geometry", "place_id"]
  }



  refreshToken() {
    this._refresh.refreshToken().then(data => {
      if (data && data.isSuccess) {
        this.newToken = data.data;
        this._SessionService.removeSession('token');
        this._SessionService.setSessions({ token: this.newToken });
      } else { }
    })
  }

  getEducationList() {
    this.mainservice.getEducationList().then((data: any) => {
      if (data && data.isSuccess)
        this.educationList = data.data
    })
  }
  onFileSelectCertificate(event: any) {
    const file = event.target.files[0];
    if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/svg' || file.type == 'image/jpeg' || file.type == 'pdf') {
      if (event.target.files && event.target.files[0]) {
        this.cerificateSubmitting = true;
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageSrc = <string>(<FileReader>event.target).result;
          this.fileName = file.name;
          this.showImage = true;
        }
        reader.readAsDataURL(event.target.files[0]);
        this.imageUploaded = true;
        const fd = new FormData();
        fd.append('multipartFile', file);
        this.mainservice.fileUpload(fd).then((data: any) => {
          if (data && data.isSuccess) {
            this.imageUploaded = false;
            this.certificateUrl = data.data;
            this.cerificateSubmitting = false;
            this.notifier.showSuccess(data.message);
          }
          else {
            this.imageUploaded = false;
            this.notifier.showError(data.message)
          }
        },
        )}
    } else
      this.notifier.showError("Only Images Are Allowed")
  }

  certificateDetailsUpload() {
    let yearObtainedFrom = this.yearFrom.value.year();
    let yearObtainedTo = this.yearTo.value.year();
    let certificateName = this.certificateForm.controls['certificateName'].value;
    let issuingOrganization = this.certificateForm.controls['issuingOrganization'].value;
    if (!certificateName) {
      this.notifier.showError("Please Enter Certificate Name")
    } else if (!issuingOrganization) {
      this.notifier.showError("Please Enter Issuing Organization")
    } else if (yearObtainedFrom >= yearObtainedTo) {
      this.notifier.showError('Please enter correct Year Range ')
    } else if (yearObtainedTo >= 2023) {
      this.notifier.showError('Only current year seleted  ')
    } else if (!this.certificateUrl) {
      this.notifier.showError('Please Upload Certificate')
    } else if (certificateName && issuingOrganization && yearObtainedFrom < yearObtainedTo && this.certificateUrl) {
      const body = {
        certificateName: certificateName,
        certificateUrl: this.certificateUrl,
        issuingOrganization: issuingOrganization,
        yearObtainedFrom: this.yearFrom.value.year(),
        yearObtainedTo: this.yearTo.value.year()
      }
      this.mainservice.uploadCerificateDetails(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.notifier.showSuccess(data.message);
          this.showUploaModal = false;
        }
        else
          this.notifier.showError(data.message);
      })
    }
  }
  saveGeneralProfileDetails() {
    let displayName = this.generalDetailsForm.controls['displayName'].value;
    let educationDetails = this.generalDetailsForm.controls['educationDetails'].value;
    let experience = this.generalDetailsForm.controls['experience'].value;
    let timezone = this.generalDetailsForm.controls['timeZone'].value;

    if (!displayName) {
      this.notifier.showError("Please Enter Display Name")
    } else if (!experience) {
      this.notifier.showError("Please Enter How many years you have been playing")
    } else if (!educationDetails) {
      this.notifier.showError("Please Enter Education")
    } else if (!this.location) {
      this.notifier.showError("Please Enter Locations")
    } else if (!timezone) {
      this.notifier.showError("Please Select TimeZone")
    } else if (displayName && experience && educationDetails && timezone && this.location) {
      this.generalFormSubmitting = true;

      const body = {
        displayName: displayName,
        educationDetails: educationDetails,
        experience: experience,
        locationDetails: this.location,
        timeZone: typeof timezone == 'object' ? timezone[0] : timezone
      }
      this.mainservice.generalDetails(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.generalFormSubmitting = false;
          this.router.navigateByUrl('/essential');
          this.notifier.showSuccess(data.message);
        }
        else
          this.notifier.showError(data.message)
      })
    }
  }

  getGeneralDatails() {
    let userId = localStorage.getItem('userId');
    this.mainservice.getGeneralProfileById(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.generalDetails = data.data;
        this.CertificatedDetails = this.generalDetails.certificateDetails;
        var fileText = this.CertificatedDetails[0]?.certificateUrl
        this.fileName = this.CertificatedDetails[0]?.certificateUrl.slice((fileText?.length - 45), fileText?.length)
        this.generalDetailsForm.controls.displayName.setValue(this.generalDetails?.displayName);
        this.generalDetailsForm.controls.experience.setValue(this.generalDetails?.experience);
        this.generalDetailsForm.controls.educationDetails.setValue(this.generalDetails?.educationDetails);
        this.generalDetailsForm.controls.timeZone.setValue(this.generalDetails?.timeZone);
        this.searchTimeZone[0] = this.generalDetails?.timeZone
        this.educationValue = this.generalDetails?.educationDetails
        this.preSlectedValues = this.educationValue.map((item: any) => item)
        for (let country of data.data?.locations) {
          this.location.push(country)
        }
      }
    })
  }

  certicatedPreview(id: any) {
    for (let certificate of this.generalDetails?.certificateDetails) {
      if (id === certificate.id)
        this.previewCertificate = certificate.certificateUrl;
    }
  }

  logoutUser() {
    this.logOut = false
    clearInterval(this.interval);
    const device = this.browser;
    this._auth.logout(device).then(data =>{
      if(data && data.isSuccess) {
        localStorage.clear();
        // this._SessionService.removeSessions();
        // this._SessionService.removeSession('email');
        // this._SessionService.removeSession('refreshToken');
        // this._SessionService.removeSession('userId');
        // this._SessionService.removeSession('refreshTokenLog');
        // this._SessionService.removeSession('token');
        // this._SessionService.removeSession('newtoken');
        // this._UserService.setLogedUser(null);
        this.router.navigate([`/login`])
      }else{
        this.notifier.showError(data.message);
      }
    })
  }

}
