import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { MainService } from 'src/app/service/main.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { UtilityService } from 'src/app/service/helper/utility.service';
import { SessionService } from 'src/app/service/helper/session.service';

@Component({
  selector: 'app-drafts',
  templateUrl: './drafts.component.html',
  styleUrls: ['./drafts.component.scss']
})
export class DraftsComponent implements OnInit {

  deleteDraftIds: any[] = [];
  draftList: any;
  editDraft: boolean = false
  status: any
  eventLiveStreamedForm: FormGroup;
  eventOnSiteForm: FormGroup;
  eventHybridForm: FormGroup;
  preferedList: any;
  specificDraftDetails: any;
  eventForm: FormGroup
  genList: any;
  searchgen: string[] = [];
  selectablegen = true;
  removablegen = true;
  genCtrl = new FormControl();
  separatorKeysCodesgen: number[] = [COMMA];
  actSizeList: any;
  eventList: any;
  eventDesc: any;
  performanceEnvironment: any;
  production: any;
  streetAdd: any;
  prefferdDevice: any;
  requirements: any;
  toogleButtonStatus: any;
  specificId: any
  data: any
  allSelected2 = false;
  showLiveSection: boolean;
  onsite: boolean;
  hybrid: boolean;
  generList: any;
  @ViewChild('fruitInput')
  fruitInput!: ElementRef<HTMLInputElement>;
  today: any
  startTime?: Date;
  time: any;
  endTimes: any;
  startTime2: any;
  endTime2: any;
  tempStartTime: any
  tempEndTime: any
  selectAllDraft: boolean = false;
  draftListData: boolean = false;
  TimeZone = this.sessionStorage.getSession('timeZone');
  userStatus: any;
  startTimectrl = new FormControl('', (control: AbstractControl) => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hours = value.getHours();
    if (hours < 11 || hours > 12) {
      return { outOfRange: true };
    }
    return null;
  });

  endTime?: Date;
  endTimectrl = new FormControl('', (control: AbstractControl) => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hours = value.getHours();
    if (hours < 11 || hours > 12) {
      return { outOfRange: true };
    }
    return null;
  });

  constructor(private MainService: MainService, private notifier: NotifierService,
    private _FormBuilder: FormBuilder,
    private router: Router,
    private sessionStorage: SessionService,
    private utility: UtilityService
  ) {
    this.showLiveSection = true;
    this.onsite = false;
    this.hybrid = false;
    this.eventForm = this._FormBuilder.group({
      specialReq: ['', [Validators.required]],
      eventTitle: ['', [Validators.required]],
      eventType: ['', [Validators.required]],
      actSize: ['', [Validators.required]],
      genres: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      budget: ['', [Validators.required]],
      location: ['', [Validators.required]],

    });

    this.eventLiveStreamedForm = this._FormBuilder.group({
      preferredDevices: ['', [Validators.required]],
      eventDescp: ['', [Validators.required]],
    });
    this.eventOnSiteForm = this._FormBuilder.group({
      production: ['', [Validators.required]],
      streetAddress: ['', [Validators.required]],
      performanceEnv: ['', [Validators.required]],
      eventDescription: ['', [Validators.required]],
      liveStream: ['', [Validators.required]],
    });
    this.eventHybridForm = this._FormBuilder.group({
      prodCapabilities: ['', [Validators.required]],
      streetAddress: ['', [Validators.required]],
      performanceEnv: ['', [Validators.required]],
      eventDescription: ['', [Validators.required]],
    });

    this.genCtrl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      if (value) {
        this.MainService.searchGenres(value).then((res: any) => {
          if (res && res.isSuccess) {
            this.generList = res.data;
          }
        })
      }
    })
  }

  ngOnInit(): void {
    this.today = new Date();
    var dd = String(this.today.getDate()).padStart(2, '0');
    var mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = this.today.getFullYear();
    this.today = yyyy + '-' + mm + '-' + dd;
    this.userStatus = this.sessionStorage.getSession('status');

    this.getDraftList()
    this.getActSizeList();
    this.getPreferredDeviceList()
    this.getEventTypeList()

    this.genCtrl.valueChanges.pipe(
      debounceTime(500),
      startWith(null)
    ).subscribe((value: string) => {
      if (value) {
        this.MainService.searchGenres(value).then((res: any) => {
          if (res && res.isSuccess) {
            this.genList = res.data;
          }
        })
      }
    })
  }

  getDraftList() {
    this.draftListData = true;
    this.MainService.getDraftList().then((data) => {
      if (data && data.isSuccess) {
        this.draftListData = false;
        this.draftList = data && data.data && data.data.content ? data.data.content : [];
        // this.notifier.showSuccess(data.message)
        for (let i = 0; i < this.draftList.length; i++) {
          this.draftList[i]['date'] = this.draftList[i].createdAt.split("T");
        }
      } else {
        this.draftListData = false;
      }
    })
  }

  getPreferredDeviceList() {
    this.MainService.getPreferredDeviceList().then((data) => {
      if (data && data.isSuccess) {
        this.preferedList = data.data;
      } else {
        this.notifier.showError(data.message);
      }
    })
  }
  getActSizeList() {
    this.MainService.getActSizeList().then((data) => {
      if (data && data.isSuccess) {
        this.actSizeList = data.data;
      } else {
        this.notifier.showError(data.message);
      }
    })
  }
  getEventTypeList() {
    this.MainService.getEventTypeList().then((data) => {
      if (data && data.isSuccess) {
        this.eventList = data.data;
      } else {
        this.notifier.showError(data.message);
      }
    })
  }

  @ViewChild('select2')
  select2!: MatSelect;

  toggleAllSelection2() {
    if (this.allSelected2) {
      this.select2.options.forEach((item: MatOption) => item.select());
    } else {
      this.select2.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick2() {
    let newStatus = true;
    this.select2.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected2 = newStatus;
  }
  specificDraft(id: any) {
    this.specificId = id
    this.editDraft = true
    this.MainService.getEventDetailById(id).then((data) => {
      if (data && data.isSuccess) {
        this.specificDraftDetails = data.data;
        // this.notifier.showSuccess(data.message);
        this.searchgen = this.specificDraftDetails?.genreDetails;
        this.eventForm.controls.genres.setValue(this.searchgen)
        this.eventForm.controls.eventTitle.setValue(this.specificDraftDetails?.title);
        this.eventForm.controls.eventType.setValue(this.specificDraftDetails?.eventTypeDetails);
        this.eventForm.controls.actSize.setValue(this.specificDraftDetails?.actSizeDetails);
        this.eventForm.controls.startDate.setValue(this.specificDraftDetails?.startDate.slice(0, 10));
        this.eventForm.controls.endDate.setValue(this.specificDraftDetails?.endDate.slice(0, 10));
        this.tempStartTime = this.specificDraftDetails?.startDate.slice(11, 16)
        this.tempEndTime = this.specificDraftDetails?.endDate.slice(11, 16)
        this.startTime = new Date(this.specificDraftDetails?.startDate);
        this.endTime = new Date(this.specificDraftDetails?.endDate);

        this.eventForm.controls.budget.setValue(this.specificDraftDetails?.budget);
        this.eventForm.controls.location.setValue(this.specificDraftDetails?.endTIme);
        this.status = this.specificDraftDetails?.venue
        if (this.status == 'HYBRID') {
          this.eventHybridForm.controls.prodCapabilities.setValue(this.specificDraftDetails?.productionCapability);
          this.eventHybridForm.controls.streetAddress.setValue(this.specificDraftDetails?.streetAddress);
          this.eventHybridForm.controls.performanceEnv.setValue(this.specificDraftDetails?.performanceEnvironment);
          this.eventHybridForm.controls.eventDescription.setValue(this.specificDraftDetails?.description);
        }
        else if (this.status == 'LIVE_STREAMED') {
          this.eventLiveStreamedForm.controls.preferredDevices.setValue(this.specificDraftDetails?.preferredDeviceDetails);
          this.eventLiveStreamedForm.controls.eventDescp.setValue(this.specificDraftDetails?.description);
        }
        else if (this.status == 'ONSITE') {
          this.eventOnSiteForm.controls.production.setValue(this.specificDraftDetails?.productionCapability);
          this.eventOnSiteForm.controls.streetAddress.setValue(this.specificDraftDetails?.streetAddress);
          this.eventOnSiteForm.controls.performanceEnv.setValue(this.specificDraftDetails?.performanceEnvironment);
          this.eventOnSiteForm.controls.eventDescription.setValue(this.specificDraftDetails?.description);
        }
      }
    })

  }

  selectedgen(event: MatAutocompleteSelectedEvent): void {
    this.searchgen.push(event.option.viewValue);
    this.eventForm.controls.genres.setValue(this.searchgen)
    this.fruitInput.nativeElement.value = '';
    this.genCtrl.setValue(null);
  }
  removegen(gen: string): void {
    const index = this.searchgen.indexOf(gen);
    if (index >= 0) {
      this.searchgen.splice(index, 1);
    }
    this.eventForm.controls.genres.setValue(this.searchgen)
  }
  addgen(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.searchgen.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.genCtrl.setValue(null);
  }
  toggle(text: any) {
    this.status = text;
    if (text === 'live_streamed') {
      this.showLiveSection = true;
      this.hybrid = false;
      this.onsite = false;
    } else if (text === 'onsite') {
      this.onsite = true;
      this.showLiveSection = false;
      this.hybrid = false;
    } else if (text === 'hybrid') {
      this.hybrid = true;
      this.onsite = false;
      this.showLiveSection = false;
    }
  }

  statusToggle(status: any) {
    this.toogleButtonStatus = status;
  }


  editEventSubmit(type?: any) {
    if (type == 'back') {
      this.editDraft = false
    }
    else if (type == 'post') {
      let title = this.eventForm.controls['eventTitle'].value;
      let eventType = this.eventForm.controls['eventType'].value;
      let genres = this.eventForm.controls['genres'].value;;
      let actSize = this.eventForm.controls['actSize'].value;
      let startDate = this.eventForm.controls['startDate'].value;
      let endDate = this.eventForm.controls['endDate'].value;
      let budget = this.eventForm.controls['budget'].value;

      this.time = this.startTime;
      this.startTime2 = this.time?.toLocaleTimeString();
      var newTime = this.time?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      this.endTimes = this.endTime;
      this.endTime2 = this.endTimes?.toLocaleTimeString();
      var newEndTime = this.endTimes?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      // let startDate = this.eventDetailForm.controls['startDate'].value;
      // let endDate = this.eventDetailForm.controls['endDate'].value;
      // let budget = this.eventDetailForm.controls['budget'].value;
      var startDateMili = new Date(startDate + ' ' + newTime).getTime()

      if (this.eventLiveStreamedForm.controls['preferredDevices'].value) {
        this.prefferdDevice = this.eventLiveStreamedForm.controls['preferredDevices'].value;
      } else {
        this.prefferdDevice = null;
      }
      if (this.eventLiveStreamedForm.controls['eventDescp'].value) {
        this.eventDesc = this.eventLiveStreamedForm.controls['eventDescp'].value;
      } else if (this.eventOnSiteForm.controls['eventDescription'].value) {
        this.eventDesc = this.eventOnSiteForm.controls['eventDescription'].value;
      } else if (this.eventHybridForm.controls['eventDescription'].value) {
        this.eventDesc = this.eventHybridForm.controls['eventDescription'].value;
      }

      if (this.eventOnSiteForm.controls['performanceEnv'].value) {
        this.performanceEnvironment = this.eventOnSiteForm.controls['performanceEnv'].value;
      } else if (this.eventHybridForm.controls['performanceEnv'].value) {
        this.performanceEnvironment = this.eventHybridForm.controls['performanceEnv'].value;
      } else {
        this.performanceEnvironment = ''
      }

      if (this.eventOnSiteForm.controls['production'].value) {
        this.production = this.eventOnSiteForm.controls['production'].value;
      } else if (this.eventHybridForm.controls['prodCapabilities'].value) {
        this.production = this.eventHybridForm.controls['prodCapabilities'].value;
      } else {
        this.production = '';
      }


      if (this.eventOnSiteForm.controls['streetAddress'].value) {
        this.streetAdd = this.eventOnSiteForm.controls['streetAddress'].value;
      } else if (this.eventHybridForm.controls['streetAddress'].value) {
        this.streetAdd = this.eventHybridForm.controls['streetAddress'].value;
      } else {
        this.streetAdd = '';
      }

      if (!this.requirements) {
        this.requirements = '';
      }
      if (!this.status) {
        this.status = 'LIVE_STREAMED'
      }
      var a = startDate.replace(/-/g, "/");
      var modifyStartDate = a + ' ' + this.tempStartTime;
      var b = endDate.replace(/-/g, "/");
      var modifyEndDate = b + ' ' + this.tempEndTime;
      let newStartDate = new Date(modifyStartDate);
      let newEndDate = new Date(modifyEndDate);

      if (this.TimeZone) {
        let date = new Date();
        let time = date.toLocaleString("en-US", { timeZone: this.TimeZone });
        let userTimezoneMilis = new Date(time).getTime()
        var startDateMili = new Date(startDate + ' ' + newTime).getTime()
        var endDateMili = new Date(endDate + ' ' + newEndTime).getTime()


        if (!startDate) {
          this.notifier.showError("Please Enter Event Date");
        } else if (!endDate) {
          this.notifier.showError("Please Enter Event Closing Date")
        } else if (!this.startTime) {
          this.notifier.showError("Please Select Event Starting Time")
        } else if (!this.endTime) {
          this.notifier.showError("Please Select Event Closing Time")
        } else if (!budget) {
          this.notifier.showError("Please Mention Event Budget")
        } else if (startDateMili < userTimezoneMilis) {
          this.notifier.showError("Event Start Date & Time Should Be Greater Than Today's Date & Time")
        } else if (endDateMili < userTimezoneMilis) {
          this.notifier.showError("Event End Date & Time Should Be Greater Than Today's Date & Time")
        } else if (startDateMili >= endDateMili) {
          this.notifier.showError("Event End Date & Time Should Be Greater Than Event Start Date & Time")
        }
        else {
          let body = {
            actSizes: actSize,
            budget: budget,
            startDate: this.utility.formatDate(newStartDate),
            endDate: this.utility.formatDate(newEndDate),
            description: this.eventDesc,
            genres: genres,
            performanceEnvironment: this.performanceEnvironment,
            preferredDevices: this.prefferdDevice,
            productionCapability: this.production,
            specialRequirement: this.requirements,
            streetAddress: this.streetAdd,
            title: title,
            types: eventType,
            venue: this.status,
            visibilityCriteria: this.toogleButtonStatus ? this.toogleButtonStatus : 'PUBLIC'
          }

          this.MainService.editDraftDetails(this.specificId, body).then((data) => {
            if (data && data.isSuccess) {
              this.notifier.showSuccess(data.message);
              this.router.navigate(['audienceDashboard/inviteArtist'], { queryParams: { type: this.toogleButtonStatus == 'PRIVATE' ? 'pri' : 'pub', id: data.data } });
            } else
              this.notifier.showError(data.message);
          })

        }


      }
    }
  }
  selectAll() {
    this.selectAllDraft = !this.selectAllDraft
    if (this.selectAllDraft) {
      for (let i = 0; i < this.draftList.length; i++) {
        this.deleteDraftIds.push(this.draftList[i].id)
      }
    } else
      this.deleteDraftIds = []
  }

  deleteDraftSelection(id: any) {
    this.deleteDraftIds.push(id)
  }

  slectValue(event: any) {
    this.MainService.searchGenres(event.target.value).then((data) => {
      this.data = data.data;
      this.eventForm.controls.genres.reset();
    })
  }
  deleteDrafts() {
    if (this.deleteDraftIds.length > 0) {
      this.MainService.deleteDrafts(this.deleteDraftIds).then((data) => {
        if (data && data.isSuccess) {
          this.notifier.showSuccess(data.message);
          this.deleteDraftIds = []
          this.getDraftList()
        } else
          this.notifier.showError(data.message);
      })
    } else
      this.notifier.showError('Please Select Atleast One Draft')
  }
}
