import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { MainService } from 'src/app/service/main.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-audience-simple-search',
  templateUrl: './audience-simple-search.component.html',
  styleUrls: ['./audience-simple-search.component.scss']
})
export class AudienceSimpleSearchComponent implements OnInit {

  keyWord: any = this.router.queryParams
  pageSize: number = 10;
  pageNo: number = 0;
  searchList: any;
  search: any;
  max = 5;
  rate = 0;
  isReadonly = true;
  invite = null;
  eventId = 0;
  artistData: any;
  showStarIcon: boolean = false;
  showDropdown: boolean = false;
  url = environment.hubspotNavigateUrl;
  hireNow: boolean = false;
  selectedArtistId: any;
  eventForm: FormGroup;
  existingEventList: any;
  tabName = 'exitingEvent';
  userStatus: any;
  allSelected2 = false;

  constructor(private router: ActivatedRoute, private route: Router, private mainservice: MainService, private _NotifierService: NotifierService,
    private _FormBuilder: FormBuilder, private sessionService: SessionService) {
    this.eventForm = this._FormBuilder.group({
      existingEvent: ['', [Validators.required]],
    });
  }

  @ViewChild('select2')
  select2!: MatSelect;

  ngOnInit(): void {
    this.dataSearch()
  }

  dataSearch() {
    this.search = this.keyWord['_value'].keyWord
    this.mainservice.normalSearch(this.pageNo, this.pageSize, this.keyWord['_value'].keyWord, this.invite, this.eventId).then((data: any) => {
      if (data && data.isSuccess) {
        if (data.data.content.length > 0)
          this.searchList = data.data.content
        else
          this.searchList = "Data Not Found"
      }
    })
  }
  getArtistDetailsById(id: any) {
    // this.artistShowData = true;
    this.mainservice.getArtistDetailsById(id).then((data) => {
      if (data && data.isSuccess) {
        // this.artistShowData = false;
        this.artistData = data.data;
      } else {
        // this.artistShowData = false;
        this._NotifierService.showError(data.message);
      }
    })
  }
  favoriteArtist(id: any) {
    this.mainservice.addOrRemoveFavouriteArtist(id).then((data) => {
      if (data && data.isSuccess) {
        this.showStarIcon = data.data;
        // this.getAllNewArtist(this.pageNo,this.pageSize);
        // this.getAllStaffPicks(this.pageNo,this.pageSize);
        this._NotifierService.showSuccess(data.message);
      } else
        this._NotifierService.showError(data.message);
    })
  }
  shareProfile(id: any) {
    this._NotifierService.showSuccess('Link Copied')
    navigator.clipboard.writeText(this.url + '/userProfile?userId=' + id)
    this.showDropdown = false;
  }
  hireArtist() {
    let eventData = this.eventForm.controls['existingEvent'].value
    this.sessionService.setSessions({ selectedArtistId: this.selectedArtistId, });
    this.route.navigate(['audienceDashboard/inviteArtist'], { queryParams: { type: 'pub', id: eventData.eventId, hired: true, home: true } });
  }
  createNewEvent() {
    this.sessionService.setSessions({ selectedArtistId: this.selectedArtistId, });
    this.route.navigate(['audienceDashboard/event'], { queryParams: { createEvent: true, hired: true, home: true } });
  }
  report() {
    this.showDropdown = false;
    var _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
    _hsq.push(["identify", {
      type: 'report'
    }]);
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.src = "//js.hs-scripts.com/9406878.js";
    document.body.appendChild(chatScript);
    var _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
    _hsq.push(["identify", {
      type: 'report'
    }]);
    _hsq.push(['trackPageView']);
  }
  getExistingEventList() {
    this.mainservice.getExistingEventList().then((data: any) => {
      if (data && data.isSuccess) {
        if (data.message == 'No records found') {
          let data = {
            eventName: 'No Records Found'
          }
          this.existingEventList.push(data)
        }
        else
          this.existingEventList = data.data;
      } else {
        this._NotifierService.showError(data.message);
      }
    })
  }
  sendMessage(id: any, name: any) {
    this.route.navigate([`/audienceDashboard/message`], {
      queryParams: {
        'id': id,
        'name': name,
        'home': true
      }
    })
  }
  optionClick2() {
    let newStatus = true;
    this.select2.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected2 = newStatus;
  }

  ngDoCheck() {
    if (this.search != this.keyWord['_value'].keyWord)
      this.dataSearch()
  }
}
