<main>
  <div class="container px-4 pt-3" style="width: 944px;">
    <div class="hgnb bot-m">
      <div class=" nvhi">
        <span>Search</span>
      </div>
      <div class="erfg">
        <img src="assets/Vector8.svg">
      </div>
    </div>
    <div class="hi-pi">
      <div>
        <span class="fgrh" *ngFor="let key of searchArray">
          <span *ngIf="key != ''">{{key}}</span>
        </span>
      </div>
      <div class="degqq">
      </div>
    </div>
    <ng-container>
      <div class="error-wrapper" *ngIf="advanceSearchDetails == 'Data Not Found' && advanceSearchDetails.length>0">
        <h1 style="text-align: center;color: white;">Oops</h1>
        <p style="text-align: center;color: white;">
          No Records Found
        </p>
      </div>
    </ng-container>

    <!-- Card section start here -->
    <div class="cr-di" *ngFor="let user of advanceSearchDetails, i: index">
      <div class="gdtdb pointer" data-toggle="modal" data-target="#artist-home" (click)="getArtistDetailsById(user.artistProfileId)">
        <div class="edsaw">
          <img [src]="user.avartarUrl ? user.avartarUrl: 'assets/artist/unsplash_4korPWFLBk0.png'">
        </div>
        <div class="baho">
          <p class="edcv">Recommended</p>
          <h4>{{user.displayName}} </h4>
          <p class="olku">
            <span *ngFor="let place of user.locations">
              {{place}}
            </span>
          </p>
          <div class="row mt-5">
            <div class="col-lg-3">
              <h5>${{user.feeRangeFrom}} - ${{user.feeRangeTo}}</h5>
            </div>
            <div class="col-lg-3">
              <h5>{{user.events}} events</h5>
            </div>
            <div class="col-lg-3">
              <span *ngFor="let method of user.genres">
                <h5> {{method}} </h5>
              </span>
            </div>
            <div class="col-lg-3">
              <div class="ygre">
                <!-- <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i>
                <i class="fa fa-star-o" aria-hidden="true"></i> -->
              </div>
            </div>
          </div>
          <div class="defrvg">
            <p> {{user.careerSummary}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<!-- Individual Artist Modal -->
<div class="modal fade drfwt mt-5 pt-3" id="artist-home" tabindex="-1" role="dialog" aria-labelledby="artist-homeTitle"
  aria-hidden="true" data-backdrop="false" style="margin-left: 85px;">
  <div class="modal-dialog modal-lg" role="document" style="overflow-y: initial !important">
    <div class="modal-content bg-dgf" *ngIf="artistData">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDropdown = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="max-height: calc(100vh - 200px); overflow-y: auto;">
        <div class="jmki">
          <div class="profile-img-wrapper">
            <img src="assets/artist/ckr.svg" class="edaqqer" data-toggle="modal" data-target=".statuss">
            <img [src]="artistData?.profilePicUrl ? artistData?.profilePicUrl : 'assets/artist/Rectangle 30 (1).png'"
              onerror="this.src='assets/artist/Rectangle 30 (1).png'"
              [ngStyle]="{'object-fit': artistData?.profilePicUrl ? 'fill' : 'cover' }" alt="" class="img-fluid">
          </div>
          <div class="dchy">
            <div>
              <h2>{{artistData?.displayName}}</h2>
              <i class="fa fa-ellipsis-v fkui pl-3" (click)="showDropdown = !showDropdown"></i>
              <i class="fa fa-heart-o fkui" aria-hidden="true" aria-hidden="true" *ngIf="!showStarIcon"
                (click)="favoriteArtist(artistData?.id)"></i>
              <i class="fa fa-gratipay fkui" *ngIf="showStarIcon" style="color: #F93822;font-size: 26px;"
                (click)="favoriteArtist(artistData?.id)"></i>
            </div>
            <div *ngIf="showDropdown" class="fkui"
              style="border: 1px solid white; border-radius: 15px; width: 25%; position: relative; top: -25px;">
              <p class="mb-0 py-1 pl-3" style="border-bottom: 1px solid white; cursor: pointer;" (click)="report()">
                Report</p>
              <p class="mb-0 py-1 pl-3" (click)="shareProfile(artistData?.id)" style="cursor: pointer;">Share Profile</p>
            </div>
            <span class="ujy" *ngFor="let country of artistData?.locations">
              {{country}}
            </span>
            <h4>${{artistData?.feeRangeFrom}} - ${{artistData?.feeRangeTo}} per hour</h4>
            <p class="ure">{{artistData?.eventCount}} events<span *ngFor="let band of artistData?.bandSizes"> | {{band}}
              </span></p>
            <div class="deft mb-3 rating">
              <!-- <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating> -->
            </div>
            <button type="button" (click)="hireNow = true; selectedArtistId = artistData.id; getExistingEventList()" class="btn hire-nw" data-dismiss="modal" >Hire Now!</button>
            <button type="button" class="btn sn-mg" (click)="sendMessage(artistData?.userId,artistData?.displayName)">Send A Message</button>
          </div>
        </div>
        <div class="jguht">
          <div class="mb-4 hgaa">
            <p>{{artistData?.careerSummary}}</p>
          </div>
          <div class="mb-4 hgaa">
            <h4>My First Musical Memory</h4>
            <p>{{artistData?.musicalMemory}}</p>
          </div>
          <div class="mb-4 hgaa">
            <h4>What I Love About Music</h4>
            <p>{{artistData?.loveAboutMusic}}</p>
          </div>
          <div class="row ">

            <div class="col-lg-6">
              <div class="mb-4 hgaa">
                <h4>Years of Experience</h4>
                <p>{{artistData?.experience}}</p>
              </div>
              <div class="mb-4 hgaa">
                <h4>Act / Band Size</h4>
                <ng-container *ngFor="let band of artistData?.bandSizes">
                  <p style="display: inline-block !important;">{{band}}</p>
                </ng-container>
              </div>
              <div class="mb-4 hgaa">
                <h4>Onsite</h4>
                <ng-container *ngFor="let onsite of artistData?.onsites">
                  <p style="display: inline-block !important;">{{onsite}}</p>
                </ng-container>
              </div>
              <div class="mb-4 hgaa">
                <h4>Video Setup</h4>
                <ng-container *ngFor="let video of artistData?.videoSetups">
                  <p style="display: inline-block !important;">{{video}}</p>
                </ng-container>
              </div>
              <div class="mb-4 hgaa">
                <h4>Video Quality</h4>
                <ng-container *ngFor="let quality of artistData?.videoQualities">
                  <p style="display: inline-block !important;">{{quality}}</p>
                </ng-container>
              </div>
              <div class="mb-4 hgaa">
                <h4>Audio Setup</h4>
                <ng-container *ngFor="let setup of artistData?.audioSetups">
                  <p style="display: inline-block !important;">{{setup}}</p>
                </ng-container>

              </div>
              <div class="mb-4 hgaa">
                <h4>Performance Environment</h4>
                <div *ngFor="let env of artistData?.performanceEnvironments">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="mb-4 hgaa">
                <h4>Favorite Genres</h4>
                <div *ngFor="let env of artistData?.favouriteGenres">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
              <div class="mb-4 hgaa">
                <h4>Instruments Played</h4>
                <div *ngFor="let env of artistData?.instrumentsPlayed">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
              <div class="mb-4 hgaa">
                <h4>Most Influential Artists</h4>
                <p>{{artistData?.influentialArtists}}</p>
              </div>
              <div class="mb-4 hgaa">
                <h4>Education</h4>
                <div *ngFor="let env of artistData?.educations">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
              <div class="mb-4 hgaa">
                <h4>Public Videos Links</h4>
                <div *ngFor="let env of artistData?.publicVideosLinks">
                  <a [href]="env" target="_blank"> <p style="display: inline-block !important;">{{env}}</p></a>
                </div>

              </div>
              <div class="mb-4 hgaa">
                <h4>Social Videos Links</h4>
                <div *ngFor="let env of artistData?.socialVideosLinks">
                  <a [href]="env" target="_blank"><p style="display: inline-block !important;">{{env}}</p></a>
                </div>

              </div>
            </div>
          </div>
          <ng-container *ngFor="let certificate of artistData?.certificateDetails">
            <div class="row sawqde">
              <div class="col-lg-4">
                <div class="mb-4 hgaa">
                  <h4>Certifications</h4>
                  <h4>{{certificate.certificateName}}</h4>
                  <p>{{certificate.issuingOrganization}}</p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-4 hgaa">
                  <p>{{certificate.issuingOrganization}}</p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-4 hgaa gftj">
                  <!-- (click)="certicatedPreview(certificate.id)" -->
                  <p  data-toggle="modal" data-target="#examplesModal"
                    style="cursor: pointer;"><img src="assets/dashboard/swd.png" class=""> View Certificate</p>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="mb-5 fercc">
            <h4 class="imnsde"> Images </h4>
            <div class="row">
              <div class="col-md-12">
                <ng-container *ngFor="let image of artistData?.images">
                  <img [src]="image" alt="no img" style="height: 100px;width: 30%;object-fit: cover;padding: 0px 30px;">
                </ng-container>
              </div>
            </div>
          </div>
          <div class="mb-5 fercc">
            <h4 class="imnsde">Videos</h4>
            <div class="row">
              <div class="col-md-12">
                <ng-container *ngFor="let video of artistData?.videoUrls">
                  <video [src]="video" alt="no img" style="height: 100%;width: 25%;object-fit: cover;padding: 10px;"
                    controls> </video>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Hire Now Modal -->
<div *ngIf="hireNow" class="drfwt" style="z-index: 100; position: fixed; left: 430px; top: 900px;">
  <div class="modal-dialog modal-md meraww" role="document" style="overflow-y: initial !important">
    <div class="modal-content bg-dgf">
      <div class="modal-header pb-0 pt-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="hireNow = false; this.eventForm.controls.existingEvent.setValue(null);">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-0" style="color: white;">
        <div class="container">
          <div class="row">
            <div class="col-12 fir-tab">
              <p>Do you want to hire this Artist for:</p>
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item pr-5">
                  <input type=radio id="rdo1" checked class="radio-input" name="radio-group">
                  <label for="rdo1" class="radio-label" style="padding-left: 30px;" (click)="tabName = 'exitingEvent'">
                    <span class="radio-border"></span>
                    For Exiting Event </label>
                </li>
                <li class="nav-item pl-5">
                  <input type=radio id="rdo2" class="radio-input" name="radio-group">
                  <label for="rdo2" class="radio-label" style="padding-left: 30px;" (click)="tabName = 'newEvent'"><span
                      class="radio-border"></span>
                    Create New Event </label>
                </li>
              </ul>
              <div class="tab-content my-4">
                <div *ngIf="tabName == 'exitingEvent'">
                  <form [formGroup]="eventForm">
                    <div class="form-group exitEvent">
                      <label for="eventtype">Select Event</label>
                      <div>
                        <mat-form-field class="multiSelect" style="width: 100%;">
                          <mat-select class="pl-3" formControlName="existingEvent" #select2>
                            <mat-option style="z-index: 10000000;" (click)="optionClick2()"
                              *ngFor="let event of existingEventList" [value]="event">
                              {{event.eventName}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </form>
                  <div class="my-3 text-center">
                    <button [disabled]="userStatus == 'PENDING_DELETE' || this.eventForm.invalid" (click)="hireArtist()"
                      type="submit" class="btn defrqqa edcae"> Confirm </button>
                  </div>
                </div>
                <div *ngIf="tabName == 'newEvent'" class="text-center">
                  <button [disabled]="userStatus == 'PENDING_DELETE'" type="submit" class="btn defrqqa edcae"
                    (click)="createNewEvent()"> Confirm </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
