import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from '../service/helper/session.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _Injector: Injector,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url !== environment.restrictedUrl.refreshTokenUrl ) {
      const _SessionService = this._Injector.get(SessionService);
      const token = _SessionService.getSession('token');
      if (token != null) {
        const reqCloned = req.clone({
          setHeaders: {
            Authorization: `${token}`,
            AcceptLanguage: 'en',
          },
        });

        return next.handle(reqCloned);
      } else {
        return next.handle(req);
      }
    } else if (req.url === environment.restrictedUrl.refreshTokenUrl) {
      const _SessionService = this._Injector.get(SessionService);
      const refreshToken = _SessionService.getSession('refreshToken');
      if (refreshToken != null) {
        const reqCloned = req.clone({
          setHeaders: {
            refreshToken: `${refreshToken}`,
            AcceptLanguage: 'en',
          },
        });
        return next.handle(reqCloned);
       } else {
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }

  }
}
