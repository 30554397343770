import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { FormBuilder, AbstractControl, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { COMMA } from '@angular/cdk/keycodes';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/service/auth-service.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { debounceTime } from 'rxjs/operators';
import { UtilityService } from 'src/app/service/helper/utility.service';
import { SessionService } from 'src/app/service/helper/session.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  keyWord: any = this.route.queryParams
  reviewEvent: boolean = false
  editEvent: boolean = false;
  showBookEvent: boolean = false;
  eventData: boolean = false
  eventList: any;
  status: any = 'live_streamed';
  actSizeList: any
  PerformanceEnvironmentList: any;
  preferedList: any;
  eventForm: FormGroup;
  eventDetailForm: FormGroup;
  generList: any;
  genCtrl = new FormControl();
  eventLiveStreamedForm: FormGroup;
  eventOnSiteForm: FormGroup;
  requirements: any;
  eventHybridForm: FormGroup;
  preffredList = new FormControl();
  eventPreviewData: any[] = [];
  genList: any;
  selectablegen = true;
  removablegen = true;
  toogleButtonStatus: any;
  eventId: any;
  tabName = 'sectionOne'
  secOneStatus = "active"
  secTwoStatus = ""
  secThreeStatus = ""
  allSelected = false;
  allSelected2 = false;
  allSelected3 = false;
  selectedItems: any;
  items = [];
  eventDesc: any;
  performanceEnvironment: any;
  production: any;
  streetAdd: any;
  prefferdDevice: any;
  events: Event[] = [];
  dropdownList = [];
  settings: any;
  data = [];
  tableData = [];
  pageNo: number = 0;
  pageSize: number = 5;
  showLiveSection: boolean;
  onsite: boolean;
  hybrid: boolean;
  startTime?: Date;
  time: any;
  endTimes: any;
  startTime2: any;
  endTime2: any;
  separatorKeysCodes: number[] = [COMMA];
  @ViewChild('fruitInput')
  fruitInput!: ElementRef<HTMLInputElement>;
  searchgen: string[] = [];
  today: any
  userStatus: any;
  TimeZone = this._SessionService.getSession('timeZone');

  postcreatebtn: boolean = false

  startTimectrl = new FormControl('', (control: AbstractControl) => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hours = value.getHours();
    if (hours < 11 || hours > 12) {
      return { outOfRange: true };
    }
    return null;
  });

  endTime?: Date;
  endTimectrl = new FormControl('', (control: AbstractControl) => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hours = value.getHours();
    if (hours < 11 || hours > 12) {
      return { outOfRange: true };
    }
    return null;
  });
  selectedArtistId: any
  buttonHandler: boolean = false


  constructor(
    private utility: UtilityService,
    private route: ActivatedRoute,
    private MainService: MainService,
    private NotifierService: NotifierService,
    private _FormBuilder: FormBuilder,
    private _SessionService: SessionService,
    private router: Router,
    private auth: AuthServiceService
  ) {
    this.genCtrl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      if (value) {
        this.MainService.searchGenres(value).then((res: any) => {
          if (res && res.isSuccess) {
            this.generList = res.data;
          }
        })
      }
    })

    this.showLiveSection = true;
    this.onsite = false;
    this.hybrid = false;
    this.eventForm = this._FormBuilder.group({
      eventTitle: ['', [Validators.required]],
      eventType: ['', [Validators.required]],
      genres: ['', [Validators.required]],
      size: ['', [Validators.required]],
    });
    this.eventDetailForm = this._FormBuilder.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      budget: ['', [Validators.required]],
      startTime: ['', [Validators.required]],
      startTime2: ['', [Validators.required]],
      endTime: ['', [Validators.required]],
      endTime2: ['', [Validators.required]],
    });
    this.eventLiveStreamedForm = this._FormBuilder.group({
      preferredDevices: ['', [Validators.required]],
      eventDescp: ['', [Validators.required]],
      liveStream: ['', [Validators.required]]
    });
    this.eventOnSiteForm = this._FormBuilder.group({
      production: ['', [Validators.required]],
      streetAddress: ['', [Validators.required]],
      performanceEnv: ['', [Validators.required]],
      eventDescription: ['', [Validators.required]],
      liveStream: ['', [Validators.required]],
    });
    this.eventHybridForm = this._FormBuilder.group({
      prodCapabilities: ['', [Validators.required]],
      streetAddress: ['', [Validators.required]],
      performanceEnv: ['', [Validators.required]],
      eventDescription: ['', [Validators.required]],
      liveStream: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    // Code for disable previous date from calender according to user Timezone
    let date = new Date();
    if (this.TimeZone) {
      let time = date.toLocaleString("en-US", { timeZone: this.TimeZone });
      let a = time.split(",")
      let b = a[0].split('/')
      let c = parseInt(b[0]) < 10? 0+b[0]:b[0]
      let d = parseInt(b[1]) < 10? 0+b[1]:b[1]
      this.today = b[2]+'-'+c+'-'+d
    }

    // Code for disable previous date from calender according to system Timezone
    // this.today = new Date();
    // var dd = String(this.today.getDate()).padStart(2, '0');
    // var mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy = this.today.getFullYear();
    // this.today = yyyy + '-' + mm + '-' + dd;

    this.selectedArtistId = this._SessionService.getSession('selectedArtistId');
    if (this.selectedArtistId && this.keyWord['_value'].hired) {
      this.toogleButtonStatus = 'PRIVATE';
      this.buttonHandler = true
    }
    this.userStatus = this._SessionService.getSession('status');
    var createEvent = this.keyWord['_value'].createEvent
    if (createEvent) {
      this.showBookEvent = true
      this.getEventTypeList();
      this.getPreferredDeviceList();
      this.getActSizeList();
      this.getPerformanceEnvironmentList();
    } else {
      this.MainService.getAudienceEventList(this.pageNo, this.pageSize, 'all').then((data) => {
        if (data && data.isSuccess && data.data) {
          this.router.navigate(['audienceDashboard/eventPreview'])
        } else {
          this.eventData = true
          this.getEventTypeList();
          this.getPreferredDeviceList();
          this.getActSizeList();
          this.getPerformanceEnvironmentList();
        }
      })
    }
  }

  slectValue(event: any) {
    this.MainService.searchGenres(event.target.value).then((data) => {
      this.data = data.data;
      this.eventForm.controls.genres.reset();
    })
  }

  @ViewChild('select')
  select!: MatSelect;
  @ViewChild('select2')
  select2!: MatSelect;
  @ViewChild('select3')
  select3!: MatSelect;

  getEventTypeList() {
    this.MainService.getEventTypeList().then((data) => {
      if (data && data.isSuccess) {
        this.eventList = data.data;
      } else
        this.NotifierService.showError(data.message);
    })
  }
  getActSizeList() {
    this.MainService.getActSizeList().then((data) => {
      if (data && data.isSuccess) {
        this.actSizeList = data.data;
      } else
        this.NotifierService.showError(data.message);
    })
  }
  getPerformanceEnvironmentList() {
    this.MainService.getPerformanceEnvironmentList().then((data: any) => {
      if (data && data.isSuccess) {
        this.PerformanceEnvironmentList = data.data
      }
    })
  }
  getPreferredDeviceList() {
    this.MainService.getPreferredDeviceList().then((data) => {
      if (data && data.isSuccess) {
        this.preferedList = data.data;
      } else
        this.NotifierService.showError(data.message);
    })
  }
  eventFormSubmit() {
    let eventTitle = this.eventForm.controls['eventTitle'].value;
    let genres = this.eventForm.controls['genres'].value;
    let size = this.eventForm.controls['size'].value;
    let eventType = this.eventForm.controls['eventType'].value;
    if (!eventTitle) {
      this.NotifierService.showError("Please Enter Event Title");
    } else if (!eventType || eventType.length == 0) {
      this.NotifierService.showError("Please Select Event Type")
    }
    else if (!genres || genres.length == 0) {
      this.NotifierService.showError("Please Select Genres")
    }
    else if (!size || size.length == 0) {
      this.NotifierService.showError("Please Select Size")
    } else if (eventTitle && eventType && eventType.length > 0 && genres && genres.length > 0 && size && size.length > 0) {
      this.tabName = 'sectionTwo'
      window.scrollTo(0, 0);
      this.scrollTo('footer')
      this.secOneStatus = ''; this.secTwoStatus = 'active'; this.secThreeStatus = ''
    }
  }

  scrollTo(className: string): void {
    const elementList = document.querySelectorAll('.' + className);
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth' });
  }

  eventSetUpSubmit(type?: any) {
    if (type == 'Back') {
      this.tabName = 'sectionTwo'
      window.scrollTo(0, 0);
      this.scrollTo('footer')
      this.secOneStatus = ''; this.secTwoStatus = 'active'; this.secThreeStatus = ''
    } else if (this.status == 'live_streamed') {
      let preferredDevices = this.eventLiveStreamedForm.controls['preferredDevices'].value;
      let eventDescp = this.eventLiveStreamedForm.controls['eventDescp'].value;
      if (!preferredDevices || preferredDevices.length == 0) {
        this.NotifierService.showError("Please Enter Preferred Devices");
      } else if (!eventDescp) {
        this.NotifierService.showError("Please Select Event Type")
      } else if (eventDescp && eventDescp.length < 30) {
        this.NotifierService.showError("Please mention minimum 30 character Description")
      } else if (!this.eventLiveStreamedForm.controls['liveStream'].value) {
        this.NotifierService.showError('Please select the Live Streaming Acknowledgement')
      } else if (preferredDevices && preferredDevices.length > 0 && eventDescp) {
        this.eventSetUpFinalSubmit(type)
      }
    } else if (this.status == 'onsite') {
      let production = this.eventOnSiteForm.controls['production'].value;
      let streetAddress = this.eventOnSiteForm.controls['streetAddress'].value;
      let performanceEnv = this.eventOnSiteForm.controls['performanceEnv'].value;
      let eventDescription = this.eventOnSiteForm.controls['eventDescription'].value;
      if (!production) {
        this.NotifierService.showError("Please Enter Production  Capabilities");
      } else if (!streetAddress) {
        this.NotifierService.showError("Please Select Street Adress")
      } else if (!performanceEnv) {
        this.NotifierService.showError("Please Select Performance Environment")
      } else if (!eventDescription) {
        this.NotifierService.showError("Please Select Event Description")
      } else if (eventDescription && eventDescription.length < 30) {
        this.NotifierService.showError("Please mention minimum 30 character Description")
      } else if (!this.eventOnSiteForm.controls['liveStream'].value) {
        this.NotifierService.showError('Please select the Live Streaming Acknowledgement')
      } else if (production && streetAddress && performanceEnv && eventDescription) {
        this.eventSetUpFinalSubmit(type)
      }
    } else if (this.status == 'hybrid') {
      let production = this.eventHybridForm.controls['prodCapabilities'].value;
      let streetAddress = this.eventHybridForm.controls['streetAddress'].value;
      let performanceEnv = this.eventHybridForm.controls['performanceEnv'].value;
      let eventDescription = this.eventHybridForm.controls['eventDescription'].value;
      if (!production) {
        this.NotifierService.showError("Please Enter Production  Capabilities");
      } else if (!streetAddress) {
        this.NotifierService.showError("Please Select Street Adress")
      } else if (!performanceEnv) {
        this.NotifierService.showError("Please Select Performance Environment")
      } else if (!eventDescription) {
        this.NotifierService.showError("Please Select Event Description")
      } else if (eventDescription && eventDescription.length < 30) {
        this.NotifierService.showError("Please mention minimum 30 character Description")
      } else if (!this.eventHybridForm.controls['liveStream'].value) {
        this.NotifierService.showError('Please select the Live Streaming Acknowledgement')
      } else if (production && streetAddress && performanceEnv && eventDescription) {
        this.eventSetUpFinalSubmit(type)
      }
    }
  }

  // save as draft
  eventSetUpFinalSubmit(type: any) {
    this.time = this.startTime;
    this.startTime2 = this.time.toLocaleTimeString();
    var newTime = this.time.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
    this.endTimes = this.endTime;
    this.endTime2 = this.endTimes.toLocaleTimeString();
    var newEndTime = this.endTimes.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
    if (type == 'reviewEvent') {
      window.scrollTo(0, 0);
      this.scrollTo('footer')
      this.reviewEvent = true
      this.editEvent = false
    }
    else if (type == 'saveDraft') {
      this.time = this.startTime;
      this.startTime2 = this.time.toLocaleTimeString();
      var newTime = this.time.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      this.endTimes = this.endTime;
      this.endTime2 = this.endTimes.toLocaleTimeString();
      var newEndTime = this.endTimes.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      let title = this.eventForm.controls['eventTitle'].value;
      let eventType = this.eventForm.controls['eventType'].value;
      let genres = this.eventForm.controls['genres'].value;
      let actSize = this.eventForm.controls['size'].value;
      let startDate = this.eventDetailForm.controls['startDate'].value;
      let endDate = this.eventDetailForm.controls['endDate'].value;
      let budget = this.eventDetailForm.controls['budget'].value;
      if (this.eventLiveStreamedForm.controls['preferredDevices'].value) {
        this.prefferdDevice = this.eventLiveStreamedForm.controls['preferredDevices'].value;
      } else {
        this.prefferdDevice = null;
      }
      if (this.eventLiveStreamedForm.controls['eventDescp'].value) {
        this.eventDesc = this.eventLiveStreamedForm.controls['eventDescp'].value;
      } else if (this.eventOnSiteForm.controls['eventDescription'].value) {
        this.eventDesc = this.eventOnSiteForm.controls['eventDescription'].value;
      } else if (this.eventHybridForm.controls['eventDescription'].value) {
        this.eventDesc = this.eventHybridForm.controls['eventDescription'].value;
      }

      if (this.eventOnSiteForm.controls['performanceEnv'].value) {
        this.performanceEnvironment = this.eventOnSiteForm.controls['performanceEnv'].value;
      } else if (this.eventHybridForm.controls['performanceEnv'].value) {
        this.performanceEnvironment = this.eventHybridForm.controls['performanceEnv'].value;
      } else {
        this.performanceEnvironment = ''
      }

      if (this.eventOnSiteForm.controls['production'].value) {
        this.production = this.eventOnSiteForm.controls['production'].value;
      } else if (this.eventHybridForm.controls['prodCapabilities'].value) {
        this.production = this.eventHybridForm.controls['prodCapabilities'].value;
      } else {
        this.production = '';
      }

      if (this.eventOnSiteForm.controls['streetAddress'].value) {
        this.streetAdd = this.eventOnSiteForm.controls['streetAddress'].value;
      } else if (this.eventHybridForm.controls['streetAddress'].value) {
        this.streetAdd = this.eventHybridForm.controls['streetAddress'].value;
      } else {
        this.streetAdd = '';
      }

      if (!this.toogleButtonStatus) {
        this.toogleButtonStatus = "PUBLIC"
      }
      if (!this.requirements) {
        this.requirements = '';
      }
      if (!this.status) {
        this.status = 'live_streamed'
      }
      var a = startDate.replace(/-/g, "/");
      var modifyStartDate = a + ' ' + newTime;
      var b = endDate.replace(/-/g, "/");
      var modifyEndDate = b + ' ' + newEndTime;
      let newStartDate = new Date(modifyStartDate);
      let newEndDate = new Date(modifyEndDate);
      let body = {
        actSizes: actSize,
        budget: budget,
        startDate: this.utility.formatDate(newStartDate),
        endDate: this.utility.formatDate(newEndDate),
        description: this.eventDesc,
        genres: genres,
        performanceEnvironment: this.performanceEnvironment,
        preferredDevices: this.prefferdDevice,
        productionCapability: this.production,
        specialRequirement: this.requirements,
        streetAddress: this.streetAdd,
        title: title,
        types: eventType,
        venue: this.status,
        visibilityCriteria: this.toogleButtonStatus
      }
      this.MainService.saveDraftDetails(body).then((data) => {
        if (data && data.isSuccess) {
          this.NotifierService.showSuccess(data.message);
          this.router.navigate(['audienceDashboard/audienceDrafts'])
        } else {
          this.NotifierService.showError(data.message);
        }
      })
    }
  }
  reviewEventSubmit(type?: any) {
    if (type == 'back') {
      this.reviewEvent = false
      this.showBookEvent = true
      this.tabName = 'sectionThree'
      window.scrollTo(0, 0);
      this.scrollTo('footer')
    }
    else if (type == 'editEvent') {
      this.editEvent = true
      window.scrollTo(0, 0);
      this.scrollTo('footer')
      this.reviewEvent = false
    }
  }
  editEventSubmit(type?: any) {
    if (type == 'back') {
      this.editEvent = false
      this.reviewEvent = true
      window.scrollTo(0, 0);
      this.scrollTo('footer')
    }
  }

  tabController(tabName: any) {
    this.tabName = tabName
  }

  eventDetailFormSubmit(type?: any) {
    if (type == 'next') {
      this.time = this.startTime;
      this.startTime2 = this.time?.toLocaleTimeString();
      var newTime = this.time?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      this.endTimes = this.endTime;
      this.endTime2 = this.endTimes?.toLocaleTimeString();
      var newEndTime = this.endTimes?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      let startDate = this.eventDetailForm.controls['startDate'].value;
      let endDate = this.eventDetailForm.controls['endDate'].value;
      let budget = this.eventDetailForm.controls['budget'].value;
      var startDateMili = new Date(startDate + ' ' + newTime).getTime()

      if (this.TimeZone) {
        let date = new Date();
        let time = date.toLocaleString("en-US", { timeZone: this.TimeZone });
        let userTimezoneMilis = new Date(time).getTime()
        var endDateMili = new Date(endDate + ' ' + newEndTime).getTime()
        // var sdate = new Date(startDate + ' ' + newTime);
        // var smilliseconds = sdate.getTime();
        // var edate = new Date(endDate + ' ' + newEndTime);
        // var emilliseconds = edate.getTime();

        if (!startDate) {
          this.NotifierService.showError("Please Enter Event Date");
        } else if (!endDate) {
          this.NotifierService.showError("Please Enter Event Closing Date")
        } else if (!this.startTime) {
          this.NotifierService.showError("Please Select Event Starting Time")
        } else if (!this.endTime) {
          this.NotifierService.showError("Please Select Event Closing Time")
        } else if (!budget) {
          this.NotifierService.showError("Please Mention Event Budget")
        } else if (startDateMili < userTimezoneMilis) {
          this.NotifierService.showError("Event Start Date & Time Should Be Greater Than Today's Date & Time")
        } else if (endDateMili <  userTimezoneMilis) {
          this.NotifierService.showError("Event End Date & Time Should Be Greater Than Today's Date & Time")
        } else if (startDateMili >= endDateMili) {
          this.NotifierService.showError("Event End Date & Time Should Be Greater Than Event Start Date & Time")
        }
        //  else if (emilliseconds <= smilliseconds) {
        //   this.NotifierService.showError("Please Select Valid Time Range")
        // }
         else if (startDate && budget && endDate) {
          this.tabName = 'sectionThree'
          window.scrollTo(0, 0);
          this.scrollTo('footer')
          this.secOneStatus = ''; this.secTwoStatus = ''; this.secThreeStatus = 'active'
        }
      }
    } else {
      this.tabName = ('sectionOne')
      window.scrollTo(0, 0);
      this.scrollTo('footer')
      this.secOneStatus = 'active'; this.secTwoStatus = ''; this.secThreeStatus = ''
    }
  }
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else
      this.select.options.forEach((item: MatOption) => item.deselect());
  }
  toggleAllSelection2() {
    if (this.allSelected2) {
      this.select2.options.forEach((item: MatOption) => item.select());
    } else
      this.select2.options.forEach((item: MatOption) => item.deselect());
  }
  toggleAllSelection3() {
    if (this.allSelected3) {
      this.select3.options.forEach((item: MatOption) => item.select());
    } else
      this.select3.options.forEach((item: MatOption) => item.deselect());
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  optionClick2() {
    let newStatus = true;
    this.select2.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected2 = newStatus;
  }
  optionClick3() {
    let newStatus = true;
    this.select3.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected3 = newStatus;
  }

  addgen(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.searchgen.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.genCtrl.setValue(null);
  }
  selectedgen(event: MatAutocompleteSelectedEvent): void {
    this.searchgen.push(event.option.viewValue);
    this.eventForm.controls.genres.setValue(this.searchgen)
    this.fruitInput.nativeElement.value = '';
    this.genCtrl.setValue(null);
  }
  removegen(gen: string): void {
    const index = this.searchgen.indexOf(gen);
    if (index >= 0) {
      this.searchgen.splice(index, 1);
    }
    this.eventForm.controls.genres.setValue(this.searchgen)
  }
  toggle(text: any) {
    this.status = text;
    if (text === 'live_streamed') {
      this.showLiveSection = true;
      this.hybrid = false;
      this.onsite = false;
    } else if (text === 'onsite') {
      this.onsite = true;
      this.showLiveSection = false;
      this.hybrid = false;
    } else if (text === 'hybrid') {
      this.hybrid = true;
      this.onsite = false;
      this.showLiveSection = false;
    }
  }
  statusToggle(status: any) {
    this.toogleButtonStatus = status;
  }




  saveEventDetails() {
    this.postcreatebtn = true
    this.time = this.startTime;
    this.startTime2 = this.time.toLocaleTimeString();
    var newTime = this.time.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    this.endTimes = this.endTime;
    this.endTime2 = this.endTimes.toLocaleTimeString();
    var newEndTime = this.endTimes.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    let title = this.eventForm.controls['eventTitle'].value;
    let eventType = this.eventForm.controls['eventType'].value;
    let genres = this.eventForm.controls['genres'].value;
    let actSize = this.eventForm.controls['size'].value;
    let startDate = this.eventDetailForm.controls['startDate'].value;
    let endDate = this.eventDetailForm.controls['endDate'].value;
    let endTime = newEndTime;
    let budget = this.eventDetailForm.controls['budget'].value;
    if (this.eventLiveStreamedForm.controls['preferredDevices'].value) {
      this.prefferdDevice = this.eventLiveStreamedForm.controls['preferredDevices'].value;
    } else {
      this.prefferdDevice = null;
    }
    if (this.eventLiveStreamedForm.controls['eventDescp'].value) {
      this.eventDesc = this.eventLiveStreamedForm.controls['eventDescp'].value;
    } else if (this.eventOnSiteForm.controls['eventDescription'].value) {
      this.eventDesc = this.eventOnSiteForm.controls['eventDescription'].value;
    } else if (this.eventHybridForm.controls['eventDescription'].value) {
      this.eventDesc = this.eventHybridForm.controls['eventDescription'].value;
    }

    if (this.eventOnSiteForm.controls['performanceEnv'].value) {
      this.performanceEnvironment = this.eventOnSiteForm.controls['performanceEnv'].value;
    } else if (this.eventHybridForm.controls['performanceEnv'].value) {
      this.performanceEnvironment = this.eventHybridForm.controls['performanceEnv'].value;
    } else {
      this.performanceEnvironment = ''
    }

    if (this.eventOnSiteForm.controls['production'].value) {
      this.production = this.eventOnSiteForm.controls['production'].value;
    } else if (this.eventHybridForm.controls['prodCapabilities'].value) {
      this.production = this.eventHybridForm.controls['prodCapabilities'].value;
    } else {
      this.production = '';
    }

    if (this.eventOnSiteForm.controls['streetAddress'].value) {
      this.streetAdd = this.eventOnSiteForm.controls['streetAddress'].value;
    } else if (this.eventHybridForm.controls['streetAddress'].value) {
      this.streetAdd = this.eventHybridForm.controls['streetAddress'].value;
    } else {
      this.streetAdd = '';
    }

    if (!this.toogleButtonStatus) {
      this.toogleButtonStatus = "PUBLIC"
    }
    if (!this.requirements) {
      this.requirements = '';
    }
    if (!this.status) {
      this.status = 'live_streamed'
    }
    var a = startDate.replace(/-/g, "/");
    var modifyStartDate = a + ' ' + newTime;
    var b = endDate.replace(/-/g, "/");
    var modifyEndDate = b + ' ' + newEndTime;
    let newStartDate = new Date(modifyStartDate);
    let newEndDate = new Date(modifyEndDate);
    let body = {
      actSizes: actSize,
      budget: budget,
      startDate: this.utility.formatDate(newStartDate),
      endDate: this.utility.formatDate(newEndDate),
      description: this.eventDesc,
      genres: genres,
      performanceEnvironment: this.performanceEnvironment,
      preferredDevices: this.prefferdDevice,
      productionCapability: this.production,
      specialRequirement: this.requirements,
      streetAddress: this.streetAdd,
      title: title,
      types: eventType,
      venue: this.status,
      visibilityCriteria: this.toogleButtonStatus
    }

    this.MainService.saveEventDetails(body).then((data) => {
      if (data && data.isSuccess) {
        this.eventId = data.data;
        this.NotifierService.showSuccess(data.message);
        if (this.buttonHandler)
          this.router.navigate(['audienceDashboard/inviteArtist'], { queryParams: { type: this.toogleButtonStatus == 'PRIVATE' ? 'pri' : 'pub', id: this.eventId, hired: true } });
        else
          this.router.navigate(['audienceDashboard/inviteArtist'], { queryParams: { type: this.toogleButtonStatus == 'PRIVATE' ? 'pri' : 'pub', id: this.eventId } });
      } else
        this.NotifierService.showError(data.message);
      this.postcreatebtn = false;
    })
  }
}
