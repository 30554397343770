import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
declare const videojs: any;

@Component({
  selector: 'app-live-player',
  template: `
    <video #target class="rounded mt-1 video-js vjs-big-play-centered" controls crossorigin="anonymous"  preload="none" controls  style="object-fit:cover" width="813px" height="400.75px">
          <source  [src]="videoLink" type="video/mp4" #source>
    </video>
  `

})
export class LivePlayerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('target', { static: true }) video: ElementRef<HTMLVideoElement> | null;
  @ViewChild('source') source: ElementRef<HTMLSourceElement> | null;
  player: any
  @Input() videoLink: any;
  @Input() poster: any;
  constructor() {
    this.video = null;
    this.source = null;
  }

  ngAfterViewInit() {
    this.getVideo();
  }

  ngOnInit() {
  }

  getVideo() {
    this.player = (window as any).player = videojs(this.video?.nativeElement, {
    });

    this.player.src({
      src: this.videoLink,
      type: 'application/x-mpegURL'
    });

    if (this.video?.nativeElement) {
      this.video.nativeElement.poster = this.poster;
    }
  }
  ngOnDestroy() {}
}
