<div class="container mr-5 mt-4 pb-3 pl-0 ml-0">
  <div class="row ml-5">
    <div class="col-12 pl-0 d-flex align-items-center">
      <i class="fa fa-chevron-left mr-4 pointer" style="font-size: 20px;" [routerLink]="['/ArtistDashboard/artistHome']"></i>
      <img src="../../../../../../assets/earningIcon.png" alt="">
      <h4 class="mb-0 ml-1">Earnings</h4>
    </div>
  </div>
  <div class="row ml-5 mt-4 shadowBox">
    <div class="col-12 mt-1 ml-2">
      <div class="row my-1">
        <div class="col-7 d-flex align-items-center">
          <h5 class="mb-0 pb-2 borderBtm">General</h5>
        </div>
        <div class="col-5">
          <button  *ngIf="profilePicData?.accountId === null || profilePicData?.accountId === undefined" [disabled]="userStatus == 'PENDING_DELETE'" [disabled]="userStatus == 'PENDING_DELETE'" type="button" class="degth"
            (click)="getAccountId()">
            <ng-container *ngIf="changeAccountProcessing; else changeAccountProcess">
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Processing...
            </ng-container>
            <ng-template #changeAccountProcess>
              Add Your Payout Account
            </ng-template>
          </button>
          <button *ngIf="profilePicData?.accountId" style="cursor: default;" type="button" class="degth">
            You already have a payout account <i class="fa fa-ellipsis-v px-3 py-1 pointer"
              (click)="showDropdown = !showDropdown"></i></button>
        </div>
        <div *ngIf="showDropdown" class="optionBox">
          <!-- getAccountDetial() -->
          <p class="mb-0 py-1 pl-3 pointer" style="border-bottom: 1px solid white;"
            (click)="tabName = 'viewAccountDetail'; showDropdown = false;"> View Account Details </p>
          <p class="mb-0 py-1 pl-3 pointer"
            (click)="changeAccount = true; showDropdown = false;"> Change Account </p>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3 ml-2">
      <div class="row mb-5">
        <div class="col-4">
          <div class="ckij">
            <div style="background: #11111100; margin-left: 65px; background-size: 100% 100%; height: 125px;width: 125px;">
              <img [src]="profilePicData?.avatarUrl ? profilePicData?.avatarUrl: 'assets/dashboard/Ellipse 2.png'"
              width="100%" height="100%" style="border: 1px solid red; padding: 4px; border-radius: 50%; object-fit: cover;">
            </div>
            <h4>{{profilePicData?.fullName}}</h4>
            <p>{{profilePicData?.email}}</p>
            <p *ngFor="let loc of profilePicData?.locations">{{loc}}</p>
          </div>
        </div>
        <div *ngIf=" tabName == 'viewGenralDetail'" class="col-8">
          <div class="row pl-5 mt-4">
            <div class="col-4">
              <h6> Full Name: </h6>
            </div>
            <div class="col-8">
              <p> {{profilePicData?.fullName}} </p>
            </div>
          </div>
          <div class="row pl-5 mt-3">
            <div class="col-4">
              <h6> Email: </h6>
            </div>
            <div class="col-8">
              <p> {{profilePicData?.email}} </p>
            </div>
          </div>
          <div class="row pl-5 mt-3">
            <div class="col-4">
              <h6> Display Name: </h6>
            </div>
            <div class="col-8">
              <p> {{profilePicData?.displayName}} </p>
            </div>
          </div>
          <div class="row pl-5 mt-3">
            <div class="col-4">
              <h6> Stripe Balance: </h6>
            </div>
            <div class="col-8">
              <p> $ {{availableBalance}} </p>
            </div>
          </div>
          <div class="row pl-5 mt-3">
            <div class="col-4">
              <h6> My Earnings: </h6>
            </div>
            <div class="col-8" style="color: red;">
              <p class="pointer" (click)="getEarningDashboardLink()">
                <ng-container *ngIf="viewEarnings; else earningView">
                  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Processing...
                </ng-container>
                <ng-template #earningView> Click Here to View Details </ng-template>
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="tabName == 'viewAccountDetail'" class="col-8">
          <div class="row pl-5 mt-5">
            <div class="col-3">
              <h6> Account Id: </h6>
            </div>
            <div class="col-9">
              <p> {{ profilePicData?.accountId }} </p>
            </div>
          </div>
          <div class="row pl-5 mt-4">
            <div class="col-6">
              <button type="button" class="btn hftrdd" (click)="tabName = 'viewGenralDetail'"> Go Back </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Change Account Modal -->
<div *ngIf="changeAccount" class="drfwt" style="z-index: 100000; position: relative; top: -520px;">
  <div class="modal-dialog modal-md meraww" role="document" style="overflow-y: initial !important">
    <div class="modal-content bg-dgf mt-5">
      <div class="modal-header pb-0 pt-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="changeAccount = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-0" style="color: white;">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h5>Are you sure you want to change your exiting account?</h5>
            </div>
            <div class="col-12 my-5">
              <div class="row">
                <div class="col-6">
                  <button [disabled]="userStatus == 'PENDING_DELETE'" type="button" class="btn hftrdd"
                    (click)="changeAccount = false"> Go Back </button>
                </div>
                <div class="col-6">
                  <button [disabled]="userStatus == 'PENDING_DELETE'" type="button" class="degth"
                    (click)="getAccountId()">
                    <ng-container *ngIf="changeAccountProcessing; else changeAccountProcess">
                      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Processing...
                    </ng-container>
                    <ng-template #changeAccountProcess>
                      Change Account
                    </ng-template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Delete Account Modal -->
<div *ngIf="DeleteAccount" class="drfwt" style="z-index: 100000; position: relative; top: -520px;">
  <div class="modal-dialog modal-md meraww" role="document" style="overflow-y: initial !important">
    <div class="modal-content bg-dgf">
      <div class="modal-header pb-0 pt-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="DeleteAccount = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-0" style="color: white;">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h5>Are you sure you want to delete your old account?</h5>
            </div>
            <div class="col-12 my-5">
              <div class="row">
                <div class="col-6">
                  <button [disabled]="userStatus == 'PENDING_DELETE'" type="button" class="btn hftrdd"
                    (click)="DeleteAccount = false"> Go Back </button>
                </div>
                <div class="col-6">
                  <button [disabled]="userStatus == 'PENDING_DELETE'" type="button" class="degth"
                    (click)="DeleteAccount = false"> Delete Account </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
