<!-- Header Code -->
<nav class="navbar navbar-expand-md navbar-dark fixed-top tp-hd bg-dark-blck">
  <a class="navbar-brand sxaw">
    <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg" />
  </a>
  <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse"
    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="navbar-collapse collapse" id="navbarCollapse">
    <ul class="navbar-nav ml-auto ul-im">
      <li class="nav-item nbhg active">
        <div *ngIf="searchBox" class="form-group has-search input-group">
          <input type="text" class="form-control" placeholder="Search Artist Name" [(ngModel)]="searchkey"
            (keyup.enter)="searchPublicArtistProfile($event)" />
          <div class="input-group-append">
            <button class="btn btn-secondary gftrj" type="button" (click)="searchBoxController()">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        <img *ngIf="!searchBox" style="cursor: pointer; width: 28px" src="assets/dashboard/Vector (1).svg"
          (click)="searchBoxController()" />
      </li>
    </ul>
  </div>
</nav>

<!-- Users List -->
<div class="mt-5 pt-5 mx-5" style="color: white;">
  <div class="container">
    <div class="row mx-3 mb-4 ">
      <span> Search Results For: </span> <span class="pl-2"><b> {{searchKeyword}} </b></span>
    </div>
    <div class="row gdtdb mx-3" *ngFor="let user of userList, i:index ">
      <div class="col-2 d-flex align-items-center justify-content-center edsaw py-3">
        <img [src]="user.avartarUrl ? user.avartarUrl: 'assets/artist/unsplash_4korPWFLBk0.png'">
      </div>
      <div class="col-3 d-flex align-items-center justify-content-start">
        <h4>{{user.displayName}} </h4>
      </div>
      <div class="col-3 d-flex align-items-center justify-content-start">
        <span *ngFor="let place of user.locations">
          {{place}}
        </span>
      </div>
        <div class="col-2 d-flex align-items-center justify-content-center">
          <h5>${{user.feeRangeFrom}} - ${{user.feeRangeTo}}</h5>
        </div>
        <div class="col-2 d-flex align-items-center justify-content-center">
          <button class="btn defrqqa" (click)="viewProfile(user.artistProfileId)"> View Profile </button>
        </div>
    </div>
  </div>
</div>
