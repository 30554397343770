import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from '../artist-signup/password validation/confirm-validation';
import { AuthServiceService } from '../../service/auth-service.service';
import { NotifierService } from '../../service/helper/notifier.service';
import { CryptoService } from 'src/app/service/helper/crypto.service';
import { MainService } from 'src/app/service/main.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-artist-signup',
  templateUrl: './artist-signup.component.html',
  styleUrls: ['./artist-signup.component.scss']
})
export class ArtistSignupComponent implements OnInit {
  artistForm: FormGroup;
  hide = true;
  refrelCode: any;
  showModal: boolean = false;
  sendConBtn: boolean = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthServiceService,
    private _NotifierService: NotifierService,
    private activatedRoute: ActivatedRoute,
    private _CryptoService: CryptoService,
    private _mainService: MainService
  ) {
    this.artistForm = fb.group({
      fullName: [
        '',
        [
          Validators.compose([
            Validators.required,
            Validators.maxLength(25),
            Validators.minLength(3),
          ]),
        ],
      ],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.*\w))$/)])],
      password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)]],
      confirmPassword: ['', Validators.required],
      userType: ['Artist'],
      olderThan18: [false, Validators.requiredTrue],
      tncAgreed: [false, Validators.requiredTrue],
      getUpdate: [false],
    },
      { validator: MustMatch('password', 'confirmPassword'), });
  }

  ngOnInit(): void {
  }

  artistSignUp() {
    let confirmPassword = this.artistForm.controls['confirmPassword'].value;
    let email = this.artistForm.controls['email'].value;
    let fullName = this.artistForm.controls['fullName'].value;
    let getUpdate = this.artistForm.controls['getUpdate'].value;
    let olderThan18 = this.artistForm.controls['olderThan18'].value;
    let password = this.artistForm.controls['password'].value;
    let tncAgreed = this.artistForm.controls['tncAgreed'].value;
    let userType = this.artistForm.controls['userType'].value;
    if (!fullName) {
      this._NotifierService.showError("Full Name is required")
    } else if (!email) {
      this._NotifierService.showError("Email is required")
    } else if (!password) {
      this._NotifierService.showError("Password is required")
    } else if (!confirmPassword) {
      this._NotifierService.showError("Please confirm password")
    } else if (!olderThan18) {
      this._NotifierService.showError("Please Select I am 18 or older ")
    } else if (!tncAgreed) {
      this._NotifierService.showError("Please Select  I agree to the terms & conditions")
    } else if (fullName && email && password && confirmPassword && olderThan18 && tncAgreed) {
      this.sendConBtn = true;
      let body = {
        "confirmPassword": confirmPassword,
        "email": email,
        "fullName": fullName,
        "getUpdate": getUpdate,
        "olderThan18": olderThan18,
        "password": password,
        "tncAgreed": tncAgreed,
        "userType": userType
      }
      this.auth.signup(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.submitHubspotForm();
          this._NotifierService.showSuccess(data.message);
          this.artistForm.reset();
          this.router.navigate([``], {
            queryParams: {
              'showModal': this._CryptoService.encrypt('true'),
            }
          });
        } else
          this._NotifierService.showError(data.message);
        this.sendConBtn = false;
      })
    }
  }

  submitHubspotForm() {
    let email = this.artistForm.controls['email'].value;
    let fullName = this.artistForm.controls['fullName'].value;
    let body = {
      "submittedAt": Date.now(),
      "fields": [
        {
          "name": "email",
          "value": email
        },
        {
          "name": "firstname",
          "value": fullName
        }
      ],
      "context": {
        // "hutk": ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        "pageUri": environment.hubspotNavigateUrl + '/artist-signup',
        "pageName": "ARTIST User Reg "
      },
    }
    this._mainService.submitHubspotForm('9406878', '91c891ad-bb75-4484-82c1-a4fd76a8d93e', body).then((data) => {
      if (data)
        console.log(data.inlineMessage, 'hubspot')
    })
  }

}




