import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MainService } from 'src/app/service/main.service';
import { Router } from '@angular/router';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from 'swiper';
import { SessionService } from 'src/app/service/helper/session.service';
import { UserService } from 'src/app/service/user.service';
import { AuthServiceService } from 'src/app/service/auth-service.service';
import { LoginModal } from 'src/app/service/models/auth.modals';
import {
  ImageCroppedEvent,
  ImageTransform,
  Dimensions,
  base64ToFile,
} from 'ngx-image-cropper';

declare const Swiper: any;
SwiperCore.use([Autoplay, Pagination, Navigation]);

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnInit {
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  allFruits: string[] = [];
  submitted = false;
  imageUploaded = false;
  publicVideo: any;
  @ViewChild('select')
  select!: MatSelect;
  imageArray: any[] = [];
  videoArray: any[] = [];
  socialVideo: any;
  allSelected = false;
  foods: any[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' },
  ];
  @ViewChild('fruitInput')
  fruitInput!: ElementRef<HTMLInputElement>;

  imageSrc: any;
  showImage: boolean;
  fileName: any;
  showUploaModal: boolean;
  avatarUploaded: boolean;
  socialMedia: boolean;
  imageSrcavt: any;
  showImageavt: boolean;
  fileNameavt: any;
  showUploaModalavt: boolean;
  imageUrl: any = [];
  avatarUrl: any;
  videoUrl: any;
  imageSubmitting: boolean;
  mediaFormSubmitting: boolean;
  videoSubmitting: boolean;
  avatarSubmitting: boolean;
  videoUploaded: boolean;
  VideoArray: any = [];
  imageSrcvid: any;
  showImagevid: boolean;
  fileNamevid: any;
  showUploaModalvid: boolean;
  arrayOfImage: any = [];
  yourWebsite: boolean;
  socialVideosaddmore: boolean;
  websiteLink: any;
  role: any;
  showLogoutModal: boolean = false;
  interval: any;
  browser: any;
  login: LoginModal;
  logOut: boolean = false;
  showDragDrop: boolean = false;
  fileData: any;
  imageSpinnerDone: boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  showPreviousImage: boolean = false;
  showFileName: boolean = false;
  hint: boolean = false;
  hintImg: boolean = false;
  videoModal: boolean = false;
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  // select all
  // select all
  //  essential select all
  //  @ViewChild('select')
  //  select!: MatSelect;

  //  allSelectedp=false;
  //   playeds: any[] = [
  //    {value: 'rock-0', viewValue: 'Rock'},
  //    {value: 'classi-1', viewValue: 'Classical'},
  //    {value: 'jazz-2', viewValue: 'Jazz'}
  //  ];
  //  toggleAllSelectionpl() {
  //    if (this.allSelectedp) {
  //      this.select.options.forEach((item: MatOption) => item.select());
  //    } else {
  //      this.select.options.forEach((item: MatOption) => item.deselect());
  //    }
  //  }
  //  optionClickpl() {
  //    let newStatus = true;
  //    this.selects.options.forEach((item: MatOption) => {
  //      if (!item.selected) {
  //        newStatus = false;
  //      }
  //    });
  //    this.allSelectedp = newStatus;
  //  }
  //  essential select all

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  // fruits: Fruit[] = [{ name: 'cicago' }, { name: 'California' }, { name: 'Texas' }];

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.fruits.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  videoUploadForm!: FormGroup;
  mediaDetailsForm!: FormGroup;
  WebsiteForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private mainservice: MainService,
    private router: Router,
    private notifer: NotifierService,
    private _SessionService: SessionService,
    private _UserService: UserService,
    private _auth: AuthServiceService
  ) {
    this.socialMedia = false;
    this.showImage = false;
    this.showUploaModal = false;
    this.showImageavt = false;
    this.showUploaModalavt = false;
    this.showImagevid = false;
    this.showUploaModalvid = false;
    this.imageSubmitting = false;
    this.mediaFormSubmitting = false;
    this.videoSubmitting = false;
    this.avatarSubmitting = false;
    this.imageUploaded = false;
    this.avatarUploaded = false;
    this.videoUploaded = false;
    this.yourWebsite = false;
    this.login = new LoginModal();
    this.socialVideosaddmore = false;
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.allFruits.slice()
      )
    );
  }

  ngOnInit(): void {
    this.videoUploadForm = this.formBuilder.group({
      videoTitle: new FormControl(''),
      videoTags: new FormControl(''),
    });
    this.browser = this.login.device;
    this.mediaDetailsForm = this.formBuilder.group({
      websites: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=. ]+$/),
        ],
      ],
      iConfirmed: [false, Validators.requiredTrue],
      TermsofService: [false, Validators.requiredTrue],
      isCorrect: [false, Validators.requiredTrue],
    });
    this.WebsiteForm = this.formBuilder.group({
      websiteLink: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=. ]+$/),
        ],
      ]
    })
  }


  onFileSelect(event: any) {
    const file = event.target.files[0];
    if (
      file.type == 'image/jpeg' ||
      file.type == 'image/png' ||
      file.type == 'image/jpg' ||
      file.type == 'image/svg+xml' ||
      file.type == 'image/webp'
    ) {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          this.imageSubmitting = true;
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageSrc = <string>(<FileReader>event.target).result;
            this.fileName = file.name;
            this.showImage = true;
            this.imageArray.push(this.imageSrc);
          };
          reader.readAsDataURL(event.target.files[0]);
        }
        this.imageUploaded = true;
        const fd = new FormData();
        fd.append('multipartFile', file);
        this.mainservice.fileUpload(fd).then((data: any) => {
          if (data && data.isSuccess) {
            this.imageUrl = data.data;
            this.arrayOfImage.push(this.imageUrl);
            this.imageUploaded = false;
            this.imageSubmitting = false;
            this.notifer.showSuccess(data.message);
          } else {
            this.notifer.showError(data.message);
            this.imageSubmitting = false;
          }
        });
      }
    } else {
      this.notifer.showError(
        'Only PNG , JPG , SVG , JPEG ,WEBP Images Are Allowed'
      );
    }

    //  this.uploadFile(file);
  }

  onFileSelectavt(event: any) {
    if (event.target.files[0]) {
      this.showDragDrop = true;
    }
    this.imageChangedEvent = event;
    const file = event.target.files[0];
    if (
      file.type == 'image/jpeg' ||
      file.type == 'image/png' ||
      file.type == 'image/jpg' ||
      file.type == 'image/svg+xml' ||
      file.type == 'image/webp'
    ) {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageSrcavt = <string>(<FileReader>event.target).result;
          this.fileNameavt = file.name;
          this.showImageavt = true;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    } else
      this.notifer.error(
        'Only PNG , JPG , SVG , JPEG ,WEBP Images Are Allowed'
      );
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileData = base64ToFile(this.croppedImage);
  }

  cropDone() {
    this.showDragDrop = false;
    this.imageSpinnerDone = true;
    this.showFileName = false;
    this.uploadFile(this.fileData);
  }

  uploadFile(file: any) {
    this.avatarSubmitting = true;
    this.avatarUploaded = true;
    const fd = new FormData();
    fd.append('multipartFile', file);
    this.mainservice.fileUpload(fd).then((data: any) => {
      if (data && data.isSuccess) {
        this.avatarUploaded = false;
        this.avatarSubmitting = false;
        this.avatarUrl = data.data;
        this.notifer.showSuccess(data.message);
      } else this.notifer.showError(data.message);
    });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  removeImage(index: any) {
    this.imageArray.splice(index, 1);
    this.fileName = '';
    if(this.imageArray.length == 0)
    this.showImage = false;
  }
  // https://
  onFileSelectvid(event: any) {
    const file = event.target.files[0];
    if ( file.type == 'video/mp4' || file.type == 'video/MOV' || file.type == 'video/WMV' ||
      file.type == 'video/AVI' || file.type == 'video/AVCHD' || file.type == 'video/WEBM' ) {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        var filesAmount = event.target.files.length;
        var reader = new FileReader();
        for (let i = 0; i < filesAmount; i++) {
          this.videoSubmitting = true;
          reader.onload = (event: any) => {
            this.imageSrcvid = <string>(<FileReader>event.target).result;
            this.fileNamevid = file.name;
            this.showImagevid = true;
          };
        }
        reader.readAsDataURL(event.target.files[0]);
        this.uploadvideo(file);
      }
    } else
      this.notifer.error('Only PNG , JPG , SVG , JPEG ,WEBP video Are Allowed');
  }

  uploadvideo(file: any) {
    this.videoUploaded = true;
    const fd = new FormData();
    fd.append('multipartFile', file);
    this.mainservice.fileUpload(fd).then((data: any) => {
      if (data && data.isSuccess) {
        this.videoSubmitting = false;
        this.videoUploaded = false;
        this.videoUrl = data.data;
        this.notifer.showSuccess(data.message);
      } else this.showImagevid = false;
      this.videoUploaded = false;
      this.notifer.showError(data.message);
    });
  }

  mediaDetails() {
    let websites = this.mediaDetailsForm.controls['websites'].value;
    // let urlValid = this.mediaDetailsForm.controls['websites'].invalid;
    // if (!this.imageSrc) {
    //   this.notifer.showError('Please upload Image');
    // } else if (!this.imageSrcvid) {
    //   this.notifer.showError('Please upload Video');
    // }
    if (!this.avatarUrl) {
      this.notifer.showError('Please upload Profile Picture');
    } else if (!this.mediaDetailsForm.controls['iConfirmed'].value) {
      this.notifer.showError(
        'Please check I confirm that I am over 18 years of age field is Required'
      );
    } else if (!this.mediaDetailsForm.controls['TermsofService'].value) {
      this.notifer.showError(
        'Please check I agree to Virtuosica Terms of Service field is Required'
      );
    } else if (!this.mediaDetailsForm.controls['isCorrect'].value) {
      this.notifer.showError(
        'Please check I confirm that all information submitted here is correct field is Required'
      );
    }
    // else if (urlValid) {
    //   this.notifer.showError('Please Enter Valid Url');
    // }
    else if (this.avatarUrl) {
      this.submitted = true;
      this.mediaFormSubmitting = true;
      const body = {
        avatarUrl: this.avatarUrl,
        imageDetails: this.arrayOfImage,
        videoDetails: this.VideoArray,
        websites: [websites],
      };
      this.mainservice.saveMediaDetails(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.mediaFormSubmitting = false;
          this.role = this._SessionService.getSession('roles');
          if (this.role == 'AUDIENCE') {
            this.showLogoutModal = true;
          } else {
            this.router.navigateByUrl('ArtistDashboard/myProfile');
          }
          this.notifer.showSuccess(data.message);
        } else {
          this.mediaFormSubmitting = false;
          this.notifer.showError(data.message);
        }
      });
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allFruits.filter((fruit) =>
      fruit.toLowerCase().includes(filterValue)
    );
  }

  fromSubmit() {
    let title = this.videoUploadForm.value.videoTitle;
    let tag = this.fruits

    if (!title) {
      this.notifer.showError("Please Enter Video Title")
    } else if (tag.length == 0) {
      this.notifer.showError("Please Add Video Tags")
    } else if (title && tag) {

      this.VideoArray.push({
        videoTags: this.fruits,
        videoTitle: this.videoUploadForm.value.videoTitle,
        videoUrl: this.videoUrl,
      });
      this.fruits = [];
      this.videoUploadForm.reset();
      this.videoUrl = null;
      this.showImagevid = false;
      this.videoModal = false;
    }
  }
  deleteVideo(){
    this.showImagevid = false;
    this.videoUrl = '';
    this.fileNamevid = null;
  }
  closeVideoModal(){
    this.videoModal = false;
    this.showImagevid = false;
    this.videoUrl = '';
    this.fileNamevid = null;
    this.fruits = [];
    this.videoUploadForm.reset();
  }

  savePublicVideo() {
    if (!this.publicVideo) {
      this.notifer.showError('Please Enter link');
    } else if (this.publicVideo) {
      this.mediaDetailsForm.controls.publicVideos.setValue(
        this.mediaDetailsForm.controls['publicVideos'].value +
        ',' +
        this.publicVideo
      );
      this.publicVideo = null;
      this.socialMedia = false;
    }
  }

  saveSocialVideo() {
    if (!this.socialVideo) {
      this.notifer.showError('Please Enter link');
    } else if (this.socialVideo) {
      this.mediaDetailsForm.controls.socialVideos.setValue(
        this.mediaDetailsForm.controls['socialVideos'].value +
        ',' +
        this.socialVideo
      );
      this.socialVideo = null;
      this.socialVideosaddmore = false;
    }
  }

  saveWebsiteLink() {
    if (!this.websiteLink) {
      this.notifer.showError('Please Enter link');
    } else if (this.websiteLink) {
      this.mediaDetailsForm.controls.websites.setValue(
        this.mediaDetailsForm.controls['websites'].value +
        ',' +
        this.websiteLink
      );
      this.websiteLink = null;
      this.yourWebsite = false;
    }
  }

  logoutUser() {
    this.logOut = false;
    clearInterval(this.interval);
    const device = this.browser;
    this._auth.logout(device).then((data) => {
      if (data && data.isSuccess) {
        this._SessionService.removeSessions();
        this._SessionService.removeSession('email');
        this._SessionService.removeSession('refreshToken');
        this._SessionService.removeSession('userId');
        this._SessionService.removeSession('refreshTokenLog');
        this._SessionService.removeSession('token');
        this._SessionService.removeSession('newtoken');
        this._UserService.setLogedUser(null);
        this.router.navigate([`/login`]);
      } else {
        this.notifer.showError(data.message);
      }
    });
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    // console.log('Load failed');
  }
}
