<main style="overflow:hidden">
  <div class="container-fluid px-4 pt-3">
    <div class="hgnb bot-m">
      <div class=" nvhi">
        <span>Home</span>
      </div>
      <div class="erfg">
        <a class="up-grd hgnoo">Premium </a>
      </div>
    </div>

    <!-- New artist section -->
    <div class="mb-4 ughne">
      <div class="fyhq">
        <p class="huikdjy">New Artists</p>
        <p class="huikdjy ghu" [routerLink]="['/ArtistDashboard/artistSeeAll']">See All</p>
      </div>
      <div class="for-slid ghb-wr">
        <ng-container *ngIf="newArtistData">
          <div style="text-align: center;">
            <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
              secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
          </div>
        </ng-container>
        <ng-container *ngIf="newArtist && newArtist.length">
          <swiper [slidesPerView]="5" [spaceBetween]="30" [navigation]="true" class="mySwiper">
            <ng-template swiperSlide *ngFor="let images of newArtist" #newArtistDataDone>
              <div class="sliderImage">
                <img [src]="images.avartarUrl ? images.avartarUrl: 'assets/artist/unsplash_4korPWFLBk0.png'" alt="">
                <div class="fhg-et">
                  <div class="yhguu">
                    <button type="button" class="btn hire-nw sm-pl">Hire Now!</button>
                    <div style="float: right;">
                      <i class="fa fa-heart-o" aria-hidden="true"
                        *ngIf="images.favourite === false || images.favourite === null"
                        (click)="favoriteArtist(images.artistProfileId)"></i>
                      <i class="fa fa-gratipay" *ngIf="images.favourite === true"
                        style="color: #F93822;font-size: 15px;" (click)="favoriteArtist(images.artistProfileId)"></i>
                      <i class="fa fa-angle-down rft" data-toggle="modal" data-target="#artist-home"
                        (click)="getArtistDetailsById(images.artistProfileId)"></i>
                    </div>
                  </div>
                  <h4>{{images.displayName}}</h4>
                  <p *ngFor="let geners of images.genres | slice:0:2; let i=index ">
                    <span>{{geners}} </span>
                  </p>
                  <div class="deft rating">
                    <!-- <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating> -->
                  </div>
                </div>
              </div>
            </ng-template>
          </swiper>
        </ng-container>
        <ng-container *ngIf="!newArtist?.length && !newArtistData">
          <div class="center-error">
            <div class="error-wrapper">
              <h1 class="text-center" style="color: white;">Oops</h1>
              <p class="text-center" style="color: white;">No New Artist Found</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- Staff Picks section -->
    <div class="mb-4 ughne">
      <div class="fyhq">
        <p class="huikdjy">Staff Picks</p>
        <p class="huikdjy ghu" [routerLink]="['/ArtistDashboard/staffSeeAll']">See All</p>
      </div>
      <div class="for-slid">
        <ng-container *ngIf="staffPickData">
          <i class="fa fa-spinner fa-spin" aria-hidden="true" style="color: whitesmoke;"></i>
          <span style="color: white;">
            Loading...
          </span>
        </ng-container>
        <ng-container *ngIf="staffPicks && staffPicks.length">
          <swiper [slidesPerView]="5" [spaceBetween]="30" [navigation]="true" class="mySwiper">
            <ng-template swiperSlide *ngFor="let images of staffPicks">
              <div class="sliderImage">
                <img [src]="images.avartarUrl ? images.avartarUrl: 'assets/artist/unsplash_4korPWFLBk0.png'" alt="">
                <div class="fhg-et">
                  <div class="yhguu">
                    <button type="button" class="btn hire-nw sm-pl">Hire Now!</button>
                    <div style="float: right;">
                      <i class="fa fa-heart-o" aria-hidden="true"
                        *ngIf="images.favourite === false || images.favourite === null"
                        (click)="favoriteArtist(images.artistProfileId)"></i>
                      <i class="fa fa-gratipay" *ngIf="images.favourite === true"
                        style="color: #F93822;font-size: 15px;" (click)="favoriteArtist(images.artistProfileId)"></i>
                      <i class="fa fa-angle-down rft" data-toggle="modal" data-target="#artist-home"
                        (click)="getArtistDetailsById(images.artistProfileId)"></i>
                    </div>
                  </div>
                  <h4>{{images.displayName}}</h4>
                  <p *ngFor="let geners of images.genres | slice:0:2; let i=index ">
                    <span>{{geners}} </span>
                  </p>
                  <div class="deft rating">
                    <!-- <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating> -->
                  </div>
                </div>
              </div>
            </ng-template>
          </swiper>
        </ng-container>
        <ng-container *ngIf="!staffPicks?.length && !staffPickData">
          <div class="center-error">
            <div class="error-wrapper">
              <h1 class="text-center" style="color: white;">Oops</h1>
              <p class="text-center" style="color: white;">No Staff Picks Found</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- Highlight section -->
    <div class="">
      <p class="huikdjy">Highlights</p>
      <div class="row">
        <div class="col-lg-4">
          <div class="">
            <div class="card cr-fgt" style="width: 18rem;">
              <img src="assets/artist/Rectangle 30.png" class="card-img-top">
              <div class="card-body sscd">
                <h5 class="card-title ">Virtuosica U</h5>
                <p class="card-text ">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
                <a class="btn go-wh">Go somewhere</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <!-- for premium card code -->
          <div class="">
            <div class="card cr-fgt" style="width: 18rem;">
              <div class="ufgth">
                <div class="reetg">
                  <h4>Basic</h4>
                  <h5>Free</h5>
                  <p>Virtuosica Artist roster online promotion services customer service (ticket system and email,
                    response within 24 hrs)</p>
                </div>
              </div>
              <div class="card-body sscd ">
                <div class="hfvu des dd2">
                  <h5 class="card-title ">Premium</h5>
                  <p class="dol-65">$ 65/month</p>
                  <div class="commission defr">
                    <p>commission goes down to 12% if user makes </p>
                    <p> > $2000 in 90 day cycle or 10% if user makes </p>
                    <p> > $5000 in 180 day cycle</p>
                  </div>
                </div>
                <a href="#" class="btn go-wh" data-toggle="modal" data-target="#premium-home">View Plans</a>
              </div>
            </div>
          </div>
          <!-- for premium card code end here -->
        </div>
        <div class="col-lg-4">
          <div class="">
            <div class="card cr-fgt" style="width: 18rem;">
              <img src="assets/artist/Rectangle 30 (1).png" class="card-img-top">
              <div class="card-body sscd">
                <h5 class="card-title ">Backstage</h5>
                <p class="card-text ">Some quick example text to build on the card title and make up the bulk of the
                  card's content.</p>
                <a class="btn go-wh">Explore all forums</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<!-- Artist Home page modal section -->
<div class="modal fade drfwt" id="artist-home" data-backdrop="false" tabindex="-1" role="dialog"
  aria-labelledby="artist-homeTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" style="overflow-y: initial !important">
    <div class="modal-content bg-dgf" *ngIf="artistData">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="max-height: calc(100vh - 200px); overflow-y: auto;">
        <div class="jmki">
          <div class="profile-img-wrapper">
            <img src="assets/artist/ckr.svg" class="edaqqer" data-toggle="modal" data-target=".statuss">
            <img [src]="artistData?.profilePicUrl ? artistData?.profilePicUrl : 'assets/artist/Rectangle 30 (1).png'"
              onerror="this.src='assets/artist/Rectangle 30 (1).png'"
              [ngStyle]="{'object-fit': artistData?.profilePicUrl ? 'fill' : 'cover' }" alt="" class="img-fluid">
          </div>
          <div class="dchy">
            <div>
              <h2>{{artistData?.displayName}}</h2>
              <i class="fa fa-heart-o fkui" aria-hidden="true" aria-hidden="true" *ngIf="!showStarIcon"
                (click)="favoriteArtist(artistData?.id)"></i>
              <i class="fa fa-gratipay fkui" *ngIf="showStarIcon" style="color: #F93822;font-size: 26px;"
                (click)="favoriteArtist(artistData?.id)"></i>
            </div>
            <span class="ujy" *ngFor="let country of artistData?.locations">
              {{country}}
            </span>
            <h4>${{artistData?.feeRangeFrom}} - ${{artistData?.feeRangeTo}} per hour</h4>
            <p class="ure">{{artistData?.eventCount}} events<span *ngFor="let band of artistData?.bandSizes"> | {{band}}
              </span></p>
            <div class="deft mb-3 rating">
              <!-- <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating> -->
            </div>
            <button type="button" class="btn hire-nw">Hire Now!</button>
            <button type="button" class="btn sn-mg"
              (click)="sendMessage(artistData?.userId,artistData?.displayName)">Send A Message</button>
          </div>
        </div>
        <div class="jguht">
          <div class="mb-4 hgaa">
            <h4>My Career in Summary</h4>
            <p>{{artistData?.careerSummary}}</p>
          </div>
          <div class="mb-4 hgaa">
            <h4>My First Musical Memory</h4>
            <p>{{artistData?.musicalMemory}}</p>
          </div>
          <div class="mb-4 hgaa">
            <h4>What I Love About Music</h4>
            <p>{{artistData?.loveAboutMusic}}</p>
          </div>
          <div class="row ">
            <div class="col-lg-6">
              <div class="mb-4 hgaa">
                <h4>Years of Experience</h4>
                <p>{{artistData?.experience}}</p>
              </div>
              <div class="mb-4 hgaa">
                <h4>Act / Band Size</h4>
                <ng-container *ngFor="let band of artistData?.bandSizes">
                  <p style="display: inline-block !important;">{{band}}</p>
                </ng-container>
              </div>
              <div class="mb-4 hgaa">
                <h4>Onsite</h4>
                <ng-container *ngFor="let onsite of artistData?.onsites">
                  <p style="display: inline-block !important;">{{onsite}}</p>
                </ng-container>
              </div>
              <div class="mb-4 hgaa">
                <h4>Video Setup</h4>
                <ng-container *ngFor="let video of artistData?.videoSetups">
                  <p style="display: inline-block !important;">{{video}}</p>
                </ng-container>
              </div>
              <div class="mb-4 hgaa">
                <h4>Video Quality</h4>
                <ng-container *ngFor="let quality of artistData?.videoQualities">
                  <p style="display: inline-block !important;">{{quality}}</p>
                </ng-container>
              </div>
              <div class="mb-4 hgaa">
                <h4>Audio Setup</h4>
                <ng-container *ngFor="let setup of artistData?.audioSetups">
                  <p style="display: inline-block !important;">{{setup}}</p>
                </ng-container>
              </div>
              <div class="mb-4 hgaa">
                <h4>Performance Environment</h4>
                <div *ngFor="let env of artistData?.performanceEnvironments">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="mb-4 hgaa">
                <h4>Favorite Genres</h4>
                <div *ngFor="let env of artistData?.favouriteGenres">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
              <div class="mb-4 hgaa">
                <h4>Instruments Played</h4>
                <div *ngFor="let env of artistData?.instrumentsPlayed">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
              <div class="mb-4 hgaa">
                <h4>Most Influential Artists</h4>
                <p>{{artistData?.influentialArtists}}</p>
              </div>
              <div class="mb-4 hgaa">
                <h4>Education</h4>
                <div *ngFor="let env of artistData?.educations">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
              <div class="mb-4 hgaa">
                <h4>Public Videos Links</h4>
                <div *ngFor="let env of artistData?.publicVideosLinks">
                  <a [href]="env" target="_blank">
                    <p style="display: inline-block !important;">{{env}}</p>
                  </a>
                </div>
              </div>
              <div class="mb-4 hgaa">
                <h4>Social Videos Links</h4>
                <div *ngFor="let env of artistData?.socialVideosLinks">
                  <a [href]="env" target="_blank">
                    <p style="display: inline-block !important;">{{env}}</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngFor="let certificate of artistData?.certificateDetails">
            <div class="row sawqde">
              <div class="col-lg-4">
                <div class="mb-4 hgaa">
                  <h4>Certifications</h4>
                  <h4>{{certificate.certificateName}}</h4>
                  <p>{{certificate.issuingOrganization}}</p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-4 hgaa">
                  <p>{{certificate.issuingOrganization}}</p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-4 hgaa gftj">
                  <p (click)="certicatedPreview(certificate.id)" data-toggle="modal" data-target="#examplesModal"
                    style="cursor: pointer;"><img src="assets/dashboard/swd.png" class=""> View Certificate</p>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- image section -->
          <div class="mb-5 fercc">
            <h4 class="imnsde"> Images </h4>
            <div class="row">
              <div class="col-md-12">
                <ng-container *ngFor="let image of artistData?.images">
                  <img [src]="image" alt="no img" style="height: 100px;width: 30%;object-fit: cover;padding: 0px 30px;">
                </ng-container>
              </div>
            </div>
          </div>
          <!-- video section -->
          <div class="mb-5 fercc">
            <h4 class="imnsde"> Videos </h4>
            <div class="row">
              <div class="col-md-12">
                <ng-container *ngFor="let video of artistData?.videoUrls">
                  <video [src]="video" alt="no img" style="height: 40%;width: 25%;object-fit: cover;padding: 10px;"
                    controls> </video>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Artist Home page Basic modal-->
<div class="modal fade drfwt" data-backdrop="false" id="basic-home" tabindex="-1" role="dialog"
  aria-labelledby="basic-homeTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content bg-dgf">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="trgdy">
          <h2>Subscription Plans</h2>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="tahnl">
              <div class="ksnyr">
                <h3>Basic</h3>
                <p>Free</p>
              </div>
              <div class="alop">
                <ul>
                  <li> <img src="assets/artist/check.png" class=""> Musician profile</li>
                  <li> <img src="assets/artist/check.png" class=""> Access to gig board, live streaming tools</li>
                  <li> <img src="assets/artist/check.png" class=""> Secure payment (with escrow)</li>
                  <li> <img src="assets/artist/check.png" class=""> Access to Backstage (Virtuosica Artist Community) </li>
                  <li> <img src="assets/artist/check.png" class=""> 25% commission on all gigs contracted on Virtuosica </li>
                  <li> <img src="assets/artist/check.png" class=""> Virtuosica Artist roster online promotion services </li>
                  <li> <img src="assets/artist/check.png" class=""> Customer service (ticket system, response within 24 hrs)</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="tahnl pre1">
              <div class="ksnyr">
                <h3>Premium</h3>
                <p>$ 65/ month</p>
              </div>
              <div class="alop">
                <ul>
                  <li> <img src="assets/artist/check.png" class=""> All features in Basic Plan.</li>
                  <li> <img src="assets/artist/check.png" class=""> 15% commision on all gigs contracted on Virtuosica* </li>
                  <li> <img src="assets/artist/check.png" class=""> Individual artist online promotion and advertising </li>
                  <li> <img src="assets/artist/check.png" class=""> Access to Virtuosica U (training and tools)</li>
                  <li> <img src="assets/artist/check.png" class=""> 24/7 customer care (live chat, phone, video call) </li>
                  <li> <img src="assets/artist/check.png" class=""> commission goes down to 12% if user makes>$2000 in 90 day cycle or 10% if user makes > $5000 in 180 day cycle</li>
                </ul>
                <div class="text-center">
                  <button type="button" class="degth">Upgrade</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Artist Home page Premium modal-->
<div class="modal fade drfwt" data-backdrop="false" id="premium-home" tabindex="-1" role="dialog"
  aria-labelledby="premium-homeTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content bg-dgf">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="trgdy ">
          <h2>Subscription Plans</h2>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="tahnl pre1">
              <div class="ksnyr">
                <h3>Basic</h3>
                <p>Free</p>
              </div>
              <div class="alop">
                <ul>
                  <li> <img src="assets/artist/check.png" class=""> Musician profile</li>
                  <li> <img src="assets/artist/check.png" class=""> Access to gig board, live streaming tools</li>
                  <li> <img src="assets/artist/check.png" class=""> Secure payment (with escrow)</li>
                  <li> <img src="assets/artist/check.png" class=""> Access to Backstage (Virtuosica Artist Community) </li>
                  <li> <img src="assets/artist/check.png" class=""> 25% commission on all gigs contracted on Virtuosica </li>
                  <li> <img src="assets/artist/check.png" class=""> Virtuosica Artist roster online promotion services </li>
                  <li> <img src="assets/artist/check.png" class=""> Customer service (ticket system, response within 24 hrs)</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="tahnl ">
              <div class="ksnyr">
                <h3>Premium</h3>
                <p>$ 65/ month</p>
              </div>
              <div class="alop">
                <ul>
                  <li> <img src="assets/artist/check.png" class=""> All features in Basic Plan.</li>
                  <li> <img src="assets/artist/check.png" class=""> 15% commision on all gigs contracted on Virtuosica* </li>
                  <li> <img src="assets/artist/check.png" class=""> Individual artist online promotion and advertising </li>
                  <li> <img src="assets/artist/check.png" class=""> Access to Virtuosica U (training and tools)</li>
                  <li> <img src="assets/artist/check.png" class=""> 24/7 customer care (live chat, phone, video call) </li>
                  <li> <img src="assets/artist/check.png" class=""> commission goes down to 12% if user makes>$2000
                    in 90 day cycle or 10% if user makes > $5000 in 180 day cycle</li>
                </ul>
                <div class="text-center">
                  <button type="button" class="degth">Upgrade</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade statuss" data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content edvbgy">
      <div class="modal-header hgbaw">
        <h5 class="modal-title edvt" id="exampleModalLabel"><img src="assets/s1.jpg">
          <span>{{artistData?.displayName}}</span></h5>
        <button type="button" class="dtdre" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img src="assets/Vector (1).svg"></span>
        </button>
      </div>
      <div class="modal-body rdfeqqw">
        <video [src]="artistData?.videoUrls[0]" style="width: 100%;" controls></video>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" data-backdrop="false" id="examplesModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 2147483647; background: #0a0a0a80;">
  <div class="modal-dialog d-flex" role="document">
    <div class="modal-content" style="width: 100%; position: absolute;max-height: 465px;">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Certificate View</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <img [src]="previewCertificate" style="width: 100%;height: 60%;">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
