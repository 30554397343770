import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/service/auth-service.service';

@Component({
  selector: 'app-change-email-verify',
  templateUrl: './change-email-verify.component.html',
  styleUrls: ['./change-email-verify.component.scss']
})
export class ChangeEmailVerifyComponent implements OnInit {

  keyWord: any = this.ar.queryParams
  constructor(private auth: AuthServiceService, private ar: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    let body = {
      token: this.keyWord['_value'].t,
      fromEmail: this.keyWord['_value'].emailFrom,
      toEmail: this.keyWord['_value'].emailTo
    }
    this.auth.changeEmailVerify(body).then((res) => {
      if (res && res.isSuccess) { }
      else
        this.router.navigate(['/accountVerifyError']);
    })
  }

}
