import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthServiceService } from '../../service/auth-service.service';
import { MainService } from '../../service/main.service';
import { NotifierService } from '../../service/helper/notifier.service';
import { SessionService } from '../../service/helper/session.service';
import { LoginModal } from '../../service/models/auth.modals';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagingService } from 'src/app/service/messaging.service';
import { UUID } from 'angular2-uuid';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  userForm: FormGroup;
  hide = true;
  stayLoggedIn: boolean = false;
  authLogin: any;
  loginData: any;
  login: LoginModal;
  loginFormSubmitting: boolean;
  fcmToken: any = '';
  uuidValue: any;
  banModal: boolean = false;
  eventData: any = this._ActivatedRoute.queryParams;
  eventUserDetail: any;
  signInBtn: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthServiceService,
    private _UserService: UserService,
    private _MainService: MainService,
    private _NotifierService: NotifierService,
    private _SessionService: SessionService,
    private _ActivatedRoute: ActivatedRoute,
    private messagingService: MessagingService
  ) {
    this.uuidValue = UUID.UUID();
    this.login = new LoginModal();
    this.userForm = fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/
          ),
        ]),
      ],
      password: [
        '',
        [
          Validators.required,
        ],
      ],
    });
    this.loginFormSubmitting = false;
  }
  ngOnInit(): void {
    this.eventUserDetail = this.eventData['_value']

    this._ActivatedRoute.data.subscribe((data) => {
      const currentIpInfo = data.info;
      this.login.browser = this.auth.detectBrowser();
      this.login.country = currentIpInfo.country;
      this.login.loginLocation = currentIpInfo.region;
      this.login.loginIp = currentIpInfo.ip;
    });
    this.messagingService.receiveMessage();
  }
  print() {
    this.stayLoggedIn = !this.stayLoggedIn
  }

  timer: any;

  async userSignin() {
    clearTimeout(this.timer)
    this.timer = setTimeout(async () => {
      let email = this.userForm.controls['email'].value.trim();
      let password = this.userForm.controls['password'].value;
      const permission = await this.messagingService.checkPermission();
      if (window.Notification && permission === 'granted') {
        this.messagingService.requestPermission().subscribe((fbToken) => {
          if (fbToken) {
            this.fcmToken = fbToken;
          } else {
            this.fcmToken = '';
          }
          if (!email) {
            this._NotifierService.showError('Please Enter Email');
          } else if (!password) {
            this._NotifierService.showError('Please Enter Password');
          } else if (email && password) {
            this.signInBtn = true;
            let address = '';
            let countryCode = this.login.country;
            let browser = this.login.browser;
            let device = this.login.device;
            let loginIp = this.login.loginIp;
            let fcmToken = '';
            this.loginFormSubmitting = true;
            let body = {
              address: address,
              browser: this.login.browser,
              device: device,
              email: email,
              fcmToken: this.fcmToken,
              loginIp: loginIp,
              password: password,
            };
            this.auth.signIn(body).then((res: any) => {
              if (res && res.isSuccess) {
                this.loginFormSubmitting = false;
                const data = res.data;
                this._SessionService.setSessions({ token: res.data.accessToken });
                this._SessionService.setSessions({ email: res.data.email });
                this._SessionService.setSessions({ userId: res.data.userId });
                this._SessionService.setSessions({ profileAdded: res.data.profileAdded });
                this._SessionService.setSessions({ timeZone: res.data.timeZone });
                this._SessionService.setSessions({ refreshToken: res.data.refreshToken, });
                this._SessionService.setSessions({ roles: res.data.roles });
                this._SessionService.setSessions({ profileStatus: res.data.profileAdded, });
                this._SessionService.setSessions({ avatarUrl: res.data.avatarUrl, });
                this._SessionService.setSessions({ userName: res.data.fullName, });
                this._SessionService.setSessions({ privileges: res.data.privileges, });
                this._SessionService.setSessions({ roles: res.data.roles });
                this._SessionService.setSessions({ status: res.data.status });
                this._SessionService.setSessions({ stayLoggedIn: this.stayLoggedIn });
                this._UserService.setLogedUser(data.data);
                if (data.chatUuId) {
                  this._SessionService.setSessions({
                    chatUuId: res.data.chatUuId,
                  });
                } else {
                  this._MainService
                    .saveChatUuid(this.uuidValue)
                    .then((data: any) => {
                      if (data && data.isSuccess) {
                      }
                    });
                }
                if (res.data.roles && res.data.roles.length > 0) {
                  if (res.data.roles.includes('PENDING_ARTIST') && res.data.profileAdded == false) {
                    this._NotifierService.showSuccess('Login Success');
                    this.router.navigate(['general']);
                  } else if (res.data.roles.includes('AUDIENCE') && res.data.profileAdded == false) {
                    this._NotifierService.showSuccess('Login Success');
                    this.router.navigate(['/audienceGeneral']);
                  } else if (res.data.roles.includes('AUDIENCE') && res.data.profileAdded == true && res.data.audienceProfileIncomplete == true){
                    this.router.navigate(['/completeProfile']);
                  } else if (res.data.roles.includes('PENDING ARTIST') && res.data.profileAdded == false) {
                    this._NotifierService.showSuccess('Login Success');
                    this.router.navigate(['general']);
                  } else if (res.data.roles.includes('PENDING_ARTIST') && res.data.profileAdded == true) {
                    this._NotifierService.showSuccess('Login Success');
                    if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email)) {
                      this.router.navigate(['/ArtistDashboard/watchLive'], { queryParams: { id: this.eventUserDetail.eventId, type: 'invite' } })
                    }
                    else if (this.eventUserDetail.streamId) {
                      this.router.navigate(['/ArtistDashboard/watchTestStream'], { queryParams: { streamId: this.eventUserDetail.streamId } })
                    } else
                      this.router.navigate(['ArtistDashboard/pendingArtistHome']);
                  } else if (res.data.roles.includes('PENDING ARTIST') && res.data.profileAdded == true) {
                    this._NotifierService.showSuccess('Login Success');
                    if(this.eventUserDetail.chatId && (this.eventUserDetail.email == email)){
                      this.router.navigate(['/ArtistDashboard/message'], { queryParams: { id: this.eventUserDetail.chatId, name: this.eventUserDetail.name } })
                    }
                    else if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email)) {
                      this.router.navigate(['/ArtistDashboard/watchLive'], { queryParams: { id: this.eventUserDetail.eventId, type: 'invite' } })
                    }
                    else if (this.eventUserDetail.streamId) {
                      this.router.navigate(['/ArtistDashboard/watchTestStream'], { queryParams: { streamId: this.eventUserDetail.streamId } })
                    } else
                      this.router.navigate(['ArtistDashboard/pendingArtistHome']);
                  } else if (res.data.roles.includes('ARTIST') && res.data.profileAdded == true) {
                    this._NotifierService.showSuccess('Login Success');
                    if(this.eventUserDetail.chatId && (this.eventUserDetail.email == email)){
                      this.router.navigate(['/ArtistDashboard/message'], { queryParams: { id: this.eventUserDetail.chatId, name: this.eventUserDetail.name } })
                    } else if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email) && this.eventUserDetail.goLive == 'true') {
                      this.router.navigate(['/ArtistDashboard/artistEventDetail'], { queryParams: { eventId: this.eventUserDetail.eventId, goLive: true } })
                    }
                    else if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email) && (this.eventUserDetail.type == 'submitProposal')) {
                      this.router.navigate(['/ArtistDashboard/gigBoard'], { queryParams: { eventId: this.eventUserDetail.eventId, type: 'submitProposal' } })
                    } else if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email) && this.eventUserDetail.proposalId) {
                      this.router.navigate(['/ArtistDashboard/artistEventDetail'], { queryParams: { eventId: this.eventUserDetail.eventId, proposalId: this.eventUserDetail.proposalId, createdBy: this.eventUserDetail.createdBy } })
                    }
                    else if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email)) {
                      this.router.navigate(['/ArtistDashboard/watchLive'], { queryParams: { id: this.eventUserDetail.eventId, type: 'invite' } })
                    }
                    else if (this.eventUserDetail.streamId) {
                      this.router.navigate(['/ArtistDashboard/watchTestStream'], { queryParams: { streamId: this.eventUserDetail.streamId } })
                    } else
                      this.router.navigate(['ArtistDashboard/artistHome']);
                  } else if (res.data.roles.includes('AUDIENCE') && res.data.profileAdded == true) {
                    this._NotifierService.showSuccess('Login Success');
                    if(this.eventUserDetail.chatId && (this.eventUserDetail.email == email)){
                      this.router.navigate(['/audienceDashboard/message'], { queryParams: { id: this.eventUserDetail.chatId, name: this.eventUserDetail.name } })
                    } else if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email) && this.eventUserDetail.goLive == 'true') {
                      this.router.navigate(['/audienceDashboard/eventPreview'], { queryParams: { eventId: this.eventUserDetail.eventId, goLive: true } })
                    }
                    else if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email) && this.eventUserDetail.proposalId) {
                      this.router.navigate(['/audienceDashboard/eventPreview'], { queryParams: { eventId: this.eventUserDetail.eventId, proposalId: this.eventUserDetail.proposalId } })
                    }
                    else if (this.eventUserDetail.eventId && this.eventUserDetail.email == email) {
                      this.router.navigate(['/audienceDashboard/audienceLiveStreaming'], { queryParams: { id: this.eventUserDetail.eventId, type: 'invite' } })
                    }
                    else if (this.eventUserDetail.streamId) {
                      this.router.navigate(['/audienceDashboard/watchteststream'], { queryParams: { streamId: this.eventUserDetail.streamId } })
                    } else
                      this.router.navigate(['audienceDashboard/audienceHome']);
                  } else {
                    this._NotifierService.showError('Role not found');
                    localStorage.clear();
                  }
                }
              } else {
                this._NotifierService.showError(res.message);
                if (res.message == 'This account has been permanently disabled by Virtuosica') {
                  this.banModal = true
                }
                this.loginFormSubmitting = false;
              }
              this.signInBtn = false;

            });
          }
        });
      } else {
        if (!email) {
          this._NotifierService.showError('Please Enter Email');
        } else if (!password) {
          this._NotifierService.showError('Please Enter Password');
        } else if (email && password) {
          this.signInBtn = true;
          let address = '';
          let countryCode = this.login.country;
          let browser = this.login.browser;
          let device = this.login.device;
          let loginIp = this.login.loginIp;
          let fcmToken = '';
          this.loginFormSubmitting = true;
          let body = {
            address: address,
            browser: this.login.browser,
            device: device,
            email: email,
            fcmToken: fcmToken,
            loginIp: loginIp,
            password: password,
          };
          this.auth.signIn(body).then((res: any) => {
            if (res && res.isSuccess) {
              this.loginFormSubmitting = false;
              const data = res.data;
              this._SessionService.setSessions({ token: res.data.accessToken });
              this._SessionService.setSessions({ email: res.data.email });
              this._SessionService.setSessions({ userId: res.data.userId });
              this._SessionService.setSessions({ refreshToken: res.data.refreshToken, });
              this._SessionService.setSessions({ timeZone: res.data.timeZone });
              this._SessionService.setSessions({ roles: res.data.roles });
              this._SessionService.setSessions({ profileAdded: res.data.profileAdded });
              this._SessionService.setSessions({ avatarUrl: res.data.avatarUrl });
              this._SessionService.setSessions({ userName: res.data.fullName, });
              this._SessionService.setSessions({ privileges: res.data.privileges, });
              this._SessionService.setSessions({ roles: res.data.roles });
              this._SessionService.setSessions({ status: res.data.status });
              this._UserService.setLogedUser(data.data);
              if (data.chatUuId) {
                this._SessionService.setSessions({ chatUuId: res.data.chatUuId });
              } else {
                this._MainService
                  .saveChatUuid(this.uuidValue)
                  .then((data: any) => {
                    if (data && data.isSuccess) {
                    }
                  });
              }
              if (res.data.roles && res.data.roles.length > 0) {
                if (res.data.roles.includes('PENDING_ARTIST') && res.data.profileAdded == false) {
                  this._NotifierService.showSuccess('Login Success');
                  this.router.navigate(['general']);
                } else if (res.data.roles.includes('AUDIENCE') && res.data.profileAdded == false) {
                  this._NotifierService.showSuccess('Login Success');
                  this.router.navigate(['/audienceGeneral']);
                } else if (res.data.roles.includes('AUDIENCE') && res.data.profileAdded == true && res.data.audienceProfileIncomplete == true){
                  this.router.navigate(['/completeProfile']);
                } else if (res.data.roles.includes('PENDING ARTIST') && res.data.profileAdded == false) {
                  this._NotifierService.showSuccess('Login Success');
                  this.router.navigate(['general']);
                } else if (res.data.roles.includes('PENDING_ARTIST') && res.data.profileAdded == true) {
                  this._NotifierService.showSuccess('Login Success');
                  if(this.eventUserDetail.chatId && (this.eventUserDetail.email == email)){
                    this.router.navigate(['/ArtistDashboard/message'], { queryParams: { id: this.eventUserDetail.chatId, name: this.eventUserDetail.name } })
                  }
                  else if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email)) {
                    this.router.navigate(['/ArtistDashboard/watchLive'], { queryParams: { id: this.eventUserDetail.eventId, type: 'invite' } })
                  }
                  else if (this.eventUserDetail.streamId) {
                    this.router.navigate(['/ArtistDashboard/watchTestStream'], { queryParams: { streamId: this.eventUserDetail.streamId } })
                  } else
                    this.router.navigate(['ArtistDashboard/pendingArtistHome']);
                } else if (res.data.roles.includes('PENDING ARTIST') && res.data.profileAdded == true) {
                  this._NotifierService.showSuccess('Login Success');
                  if(this.eventUserDetail.chatId && (this.eventUserDetail.email == email)){
                    this.router.navigate(['/ArtistDashboard/message'], { queryParams: { id: this.eventUserDetail.chatId, name: this.eventUserDetail.name } })
                  }
                  else if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email)) {
                    this.router.navigate(['/ArtistDashboard/watchLive'], { queryParams: { id: this.eventUserDetail.eventId, type: 'invite' } })
                  }
                  else if (this.eventUserDetail.streamId) {
                    this.router.navigate(['/ArtistDashboard/watchTestStream'], { queryParams: { streamId: this.eventUserDetail.streamId } })
                  } else
                    this.router.navigate(['ArtistDashboard/pendingArtistHome']);
                } else if (res.data.roles.includes('ARTIST') && res.data.profileAdded == true) {
                  if(this.eventUserDetail.chatId && (this.eventUserDetail.email == email)){
                    this.router.navigate(['/ArtistDashboard/message'], { queryParams: { id: this.eventUserDetail.chatId, name: this.eventUserDetail.name } })
                  } else if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email) && this.eventUserDetail.goLive == 'true') {
                    this.router.navigate(['/ArtistDashboard/artistEventDetail'], { queryParams: { eventId: this.eventUserDetail.eventId, goLive: true } })
                  }
                  else if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email) && (this.eventUserDetail.type == 'submitProposal')) {
                    this.router.navigate(['/ArtistDashboard/gigBoard'], { queryParams: { eventId: this.eventUserDetail.eventId, type: 'submitProposal' } })
                  } else if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email)) {
                    this.router.navigate(['/ArtistDashboard/watchLive'], { queryParams: { id: this.eventUserDetail.eventId, type: 'invite' } })
                  } else if (this.eventUserDetail.streamId) {
                    this.router.navigate(['/ArtistDashboard/watchTestStream'], { queryParams: { streamId: this.eventUserDetail.streamId } })
                  } else
                    this.router.navigate(['ArtistDashboard/artistHome']);
                  this._NotifierService.showSuccess('Login Success');
                } else if (res.data.roles.includes('AUDIENCE') && res.data.profileAdded == true) {
                  if(this.eventUserDetail.chatId && (this.eventUserDetail.email == email)){
                    this.router.navigate(['/audienceDashboard/message'], { queryParams: { id: this.eventUserDetail.chatId, name: this.eventUserDetail.name } })
                  } else if (this.eventUserDetail.eventId && (this.eventUserDetail.email == email) && this.eventUserDetail.goLive == 'true') {
                    this.router.navigate(['/audienceDashboard/eventPreview'], { queryParams: { eventId: this.eventUserDetail.eventId, goLive: true } })
                  }
                  else if (this.eventUserDetail.eventId && this.eventUserDetail.email == email) {
                    this.router.navigate(['/audienceDashboard/audienceLiveStreaming'], { queryParams: { id: this.eventUserDetail.eventId, type: 'invite' } })
                  } else if (this.eventUserDetail.streamId) {
                    this.router.navigate(['/audienceDashboard/watchteststream'], { queryParams: { streamId: this.eventUserDetail.streamId } })
                  } else
                    this.router.navigate(['audienceDashboard/audienceHome']);
                  this._NotifierService.showSuccess('Login Success');
                } else {
                  this._NotifierService.showError('Role not found');
                  localStorage.clear();
                }
              }
            } else {
              this._NotifierService.showError(res.message);
              this.loginFormSubmitting = false;
            }
            this.signInBtn = false;

          });
        }
      }
    }, 1000)
  }
}
