import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { MainService } from 'src/app/service/main.service';
import { SessionService } from 'src/app/service/helper/session.service';
import PubNub from 'pubnub';
import { UUID } from 'angular2-uuid';
import { SafePipe } from 'src/app/service/safe.pipe';
import { } from 'jquery';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-audience-live-streaming',
  templateUrl: './audience-live-streaming.component.html',
  styleUrls: ['./audience-live-streaming.component.scss']
})
export class AudienceLiveStreamingComponent implements OnInit {
  @ViewChild('liveSource', { static: true }) liveSource: ElementRef<HTMLVideoElement> | null;
  param: any = this.route.queryParams
  selectTab = 'mainTab'
  // selectTab = 'concertStarted'
  fullScreen: boolean = false;
  tabName = 'onboardDevices';
  poster: any;
  videoLink: any;
  eventDetails: any
  interval: any
  eventStartTime: any
  eventEndTime: any
  max = 5;
  rate = 0;
  isReadonly = false;
  timeCount: any = {
    day: 0,
    hour: 0,
    minute: 0,
    second: 0,
    timeDifference: 0,
  }
  WatchLiveBtn: boolean = false
  eventStarted: boolean = false
  eventEndedModal: boolean = false
  feedbackModal: boolean = false
  type: any;
  reactionValue: any;
  currentTime: any;
  commentedText: any;
  messages: any[] = [];
  pubnub: any;
  newChannelName: any;
  uuidValue: any;
  currentUserProfile: any;
  currentUserName: any;
  startLive: boolean = true;
  TimeZone = this._SessionService.getSession('timeZone');
  leaveButton: boolean = true;
  leaveBtnStatus: boolean = false;
  mobileView: boolean = false;
  userRole: any;
  emoji: any;
  emojiView: boolean = false;
  timeCountCheck: any;
  constructor(
    private route: ActivatedRoute,
    private MainService: MainService,
    private NotifierService: NotifierService,
    private router: Router,
    private observer: BreakpointObserver,
    private pipe: SafePipe,
    private _SessionService: SessionService,) {
    this.liveSource = null;
    this.newChannelName = "liveChat";
    if (this._SessionService.getSession('chatUuId')) {
      this.uuidValue = this._SessionService.getSession('chatUuId');
    } else {
      this.uuidValue = UUID.UUID();
    }
    this.commentedText = '';
    this.pubnub = new PubNub({
      publishKey: 'pub-c-6ae48ff7-ea5d-4b56-b7ce-a553e0ebc7de',
      subscribeKey: 'sub-c-c9a52fe0-a8ad-11ec-94c0-bed45dbe0fe1',
      uuid: this.uuidValue,
    });
  }

  ngOnInit(): void {
    this.userRole = this._SessionService.getSession('roles');
    this.currentUserProfile = this._SessionService.getSession('avatarUrl');
    this.currentUserName = this._SessionService.getSession('userName');
    this.pubnub.subscribe({
      channels: [this.newChannelName],
      withPresence: true,
      presence: function (data: any) {
      },
      triggerEvents: ['message', 'presence', 'status'],
    });
    this.pubnub.addListener({
      status: async (statusEvent: { category: string }) => {
        if (statusEvent.category === 'PNConnectedCategory') {
          try {
            await this.pubnub.setState({
              state: {
                some: 'state',
              },
            });
          } catch (status) { }
        }
      },
      message: (messageEvent: any) => {
        this.messages.push(messageEvent);
      },
      presence: (presenceEvent: any) => {
      },
      file: (event: any) => { },
    });

    this.type = this.param['_value']
    if (this.type.type == 'invite')
      this.leaveButton = false;

    this.MainService.getStreamingUrl(this.type.id).then((data) => {
      if (data && data.isSuccess) {
        this.videoLink = data.data
        this.videoLink = this.pipe.transform(this.videoLink)
        var a = data.data.split("=");
        this.newChannelName = a[1]

      }
    })
    this.currentTime = new Date().getTime()
    this.getEventDetail();

  }
  ngAfterViewInit() {
    this.observer.observe(['(max-width: 767px)']).subscribe((res) => {
      if (res.matches) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    });
  }

  testing() {
    console.log('its click event working');
  }
  getEventDetail() {
    if (this.type) {
      this.MainService.getEventDetailById(this.type.id).then((data) => {
        if (data && data.isSuccess) {
          this.eventDetails = data.data;
          // var newtimeline = data.data.startDate.split('T')
          var sdate = new Date(data.data.startDate);
          this.eventStartTime = sdate.getTime();
          // var endtime = data.data.endDate.split('T')
          var edate = new Date(data.data.endDate);
          this.eventEndTime = edate.getTime();
          this.interval = setInterval(() => this.getTimeDifference(), 1000);
        }
      })
    }
  }

  getTimeDifference() {
    this.timeCountCheck =  Date.now();
    console.log(this.timeCountCheck,'timeCountCheck');

    let date = new Date();
    if (this.TimeZone) {
      let time = date.toLocaleString("en-US", { timeZone: this.TimeZone });
      let userTimezoneMilis = new Date(time).getTime()
      if ((userTimezoneMilis > this.eventStartTime) && (userTimezoneMilis < this.eventEndTime)) {
        this.selectTab = 'concertStarted';
        this.leaveBtnStatus = true;
        this.startLiveStream();
      }
      else {
        this.timeCount.timeDifference = this.eventStartTime - userTimezoneMilis;
        this.timeCount.day = Math.floor(this.timeCount.timeDifference / (1000 * 60 * 60 * 24));
        this.timeCount.hour = Math.floor((this.timeCount.timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.timeCount.minute = Math.floor((this.timeCount.timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        this.timeCount.second = Math.floor((this.timeCount.timeDifference % (1000 * 60)) / 1000);
        if (this.timeCount.day == 0 && this.timeCount.hour == 0 && this.timeCount.minute < 30 && this.timeCount.minute > -1)
          this.WatchLiveBtn = true;
        if (this.timeCount.day == 0 && this.timeCount.hour == 0 && this.timeCount.minute < 1 && this.timeCount.minute > -1)
          this.leaveBtnStatus = true;
      }
    }
  }
  startLiveStream() {
    if (this.startLive) {
      this.MainService.startLiveStream(this.type.id).then((data) => {
        if (data && data.isSuccess) {
          this.NotifierService.showSuccess(data.message)
        } else
          this.NotifierService.showError(data.message)
        // var a = document.getElementById('iframe1')
        // console.log(a, 'Iframe');
        this.startLive = false;
      })
    }
  }
  leaveEvent() {
    if (this.currentTime < this.eventEndTime)
      this.eventEndedModal = true
    else if (this.currentTime > this.eventEndTime)
      this.feedbackModal = true
  }
  submitFeedback() {
    let body = {
      "eventId": this.type.id,
      "rating": this.rate,
      "reaction": this.reactionValue
    }
    this.MainService.feedbackSubmission(body).then((data) => {
      if (data && data.isSuccess) {
        this.NotifierService.showSuccess(data.message)
        if (this.userRole === 'AUDIENCE')
          this.router.navigate(['audienceDashboard/eventPreview'])
        else
          this.router.navigate(['ArtistDashboard/artistEventDetail'])
      } else
        this.NotifierService.showError(data.message)
    })
    this.MainService.endLiveStream(this.type.id).then((data) => {
      if (data && data.isSuccess) {
        this.NotifierService.showSuccess(data.message)
        if (this.userRole === 'AUDIENCE')
          this.router.navigate(['audienceDashboard/eventPreview'])
        else
          this.router.navigate(['ArtistDashboard/artistEventDetail'])
      } else
        this.NotifierService.showError(data.message)
    })
  }

  ngOnDestroy() {
    clearInterval(this.interval)
  }


  addEmoji(event: any) {
    this.commentedText = `${this.commentedText}${event.emoji.native}`;
  }

  async addComment(event?: any) {
    this.pubnub.subscribe({
      channels: [this.newChannelName],
      withPresence: true,
      presence: function (data: any) {
      },
      triggerEvents: ['message', 'presence', 'status'],
    });
    // let message = event && event.target && event.target.value ? event.target.value : event.emoji.native;
    let message = this.emoji;
    let time = Date.now();
    var date = new Date().toDateString();
    if (message) {
      console.log(date,'date');
      console.log(time,'time');

      const newMessage = {
        messageType: 'TEXT',
        // typeMessage: event && event.target && event.target.value ? event.target.value : event.emoji.native,
        typeMessage: this.emoji,
        messageTime: time,
        currentUserProfile: this.currentUserProfile,
        currentDate: date,
        username: this.currentUserName
      };
      try {
        const result = await this.pubnub.publish({
          message: newMessage,
          channel: this.newChannelName,
          storeInHistory: true,
        });
      } catch (status) { }
    }
    setTimeout(() => {
      $('#div1').animate({ scrollTop: $('#div1').prop('scrollHeight') }, 1000);
    }, 20);
    this.commentedText = '';
  }
  report() {
    var _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
    _hsq.push(["identify", {
      type: 'report'
    }]);
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.src = "//js.hs-scripts.com/9406878.js";
    document.body.appendChild(chatScript);
    var _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
    _hsq.push(["identify", {
      type: 'report'
    }]);
    _hsq.push(['trackPageView']);
  }

  callFunction() {
    console.log('Hi called');
    var videoPlayer = document.getElementById('player');
    // don't allow full screen
    // if(videoPlayer)
    // videoPlayer.allowFullscreen = false;
  }


  //  Function to open fullscreen mode
  openFullscreen() {
    var elem = document.getElementById('myvideo');
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        // } else if (elem.webkitRequestFullscreen) {
        //   / Safari /
        //   elem.webkitRequestFullscreen();
        // } else if (elem.msRequestFullscreen) {
        //   / IE11 /
        //   elem.msRequestFullscreen();
        // }
      }
    }
  }
  openFull() {
    var elem = document.getElementById('m');
    console.log(elem,'elem');

    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        // } else if (elem.webkitRequestFullscreen) {
        //   / Safari /
        //   elem.webkitRequestFullscreen();
        // } else if (elem.msRequestFullscreen) {
        //   / IE11 /
        //   elem.msRequestFullscreen();
        // }
      }
    }
  }
}
