<div id="layoutSidenav_content">
  <main>
    <div class="container-fluid px-4 pt-3">
      <div class="hgnb">
        <div class=" nvhi">
          <img src="assets/dashboard/arr.svg">
          <img src="assets/dashboard/Mask Group.png" class="pro-fil">
          <span>My Profile</span>
        </div>
        <div class="erfg">
          <a class="eijh" style="cursor: pointer;" (click)="editProfile()">Edit Profile <img
              src="assets/dashboard/et.png"> </a>
        </div>
      </div>
      <div class="mt-4">
        <div class="khiu card">
          <div class="card-header">
            <div class="tabbable-responsive">
              <div class="tabbable">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="first-tab" data-toggle="tab" href="#first" role="tab"
                      aria-controls="first" aria-selected="true" (click)="showDragDrop = false; tabname = 'General'">General</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="second-tab" data-toggle="tab" href="#second" role="tab"
                      aria-controls="second" aria-selected="false" (click)="showDragDrop = false; tabname = 'Essentials'">Essentials</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="third-tab" data-toggle="tab" href="#third" role="tab" aria-controls="third"
                      aria-selected="false" (click)="showDragDrop = false; tabname = 'Background'">Background</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="fourth-tab" data-toggle="tab" href="#fourth" role="tab"
                      aria-controls="fourth" aria-selected="false" (click)="showDragDrop = false; tabname = 'Production'">Production
                      Capabilities</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="fifth-tab" data-toggle="tab" href="#fifth" role="tab" aria-controls="fifth"
                      aria-selected="false" (click)="showDragDrop = false; tabname = 'Media'">Media</a>
                  </li>
                   </ul>
              </div>
            </div>
          </div>
          <div class="card-body" id="style-2">
            <div class="tab-content">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="ckij">
                      <div *ngIf="showDragDrop">
                      <image-cropper
                      [imageChangedEvent]="imageChangedEvent"
                      [maintainAspectRatio]="true"
                      [containWithinAspectRatio]="containWithinAspectRatio"
                      [aspectRatio]="16 / 9"
                      [resizeToWidth]="256"
                      [cropperMinWidth]="149"
                      [onlyScaleDown]="true"
                      [roundCropper]="false"
                      [canvasRotation]="canvasRotation"
                      [transform]="transform"
                      [alignImage]="'left'"
                      [style.display]="showCropper ? null : 'none'"
                      (imageCropped)="imageCropped($event)"
                      (imageLoaded)="imageLoaded()"
                      (cropperReady)="cropperReady($event)"
                      (loadImageFailed)="loadImageFailed()"
                  ></image-cropper>
                  </div>
                  <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" style="margin-right:127px" *ngIf="showDragDrop" />
                  <div class="edit-profile-option2">
                    <button type="submit" class="eijh2" style="cursor: pointer;" *ngIf="showDragDrop" (click)="cropDone()">Submit</button>
                    <button type="submit"  class="btn hftrdd" style="cursor: pointer;" *ngIf="showDragDrop" (click)="showDragDrop = false">Cancel</button>
                    </div>
                  <div *ngIf="imageSpinnerDone" class="spinner">
                    <sa-spinner-dotted [size]="59" [thickness]="103" [speed]="111" color="#dd0031"
                    secondaryColor="rgba(24, 24, 24, 0.1)" ></sa-spinner-dotted>
                  </div>
                  <div style="background: #11111100; margin-left: 75px; margin-top: 40px; background-size: 100% 100%; height: 125px;width: 125px;">
                    <img [src]="profilePicData?.avatarUrl ? profilePicData?.avatarUrl: 'assets/dashboard/Ellipse 2.png'" *ngIf="!showDragDrop && !imageSpinnerDone"
                    width="100%" height="100%" style="border: 1px solid red; padding: 4px; border-radius: 50%; object-fit: cover;">
                  </div>
                  <div class="edit-profile-option" *ngIf="!showDragDrop">
                        <i class="fa fa-pencil" aria-hidden="true" (click)="file.click()" ></i>
                      </div>
                      <input type="file" accept="image/*" #file (change)="onFileSelect($event)" style="display: none;">
                      <div>
                       </div>
                      <h4>{{profilePicData?.fullName}}</h4>
                      <p>{{profilePicData?.email}}</p>
                      <p>{{profilePicData?.location}}</p>
                    </div>
                  </div>
                  <div *ngIf="tabname == 'General'" class="col-lg-8 tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                    <div class="hgbpp">
                      <table class="ithy">
                        <tr>
                          <td>Full Name</td>
                          <td class="f14">{{generalDetails?.fullName}}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td class="f14">{{generalDetails?.email}}</td>
                        </tr>
                        <tr>
                          <td>Display Name </td>
                          <td class="f14">{{generalDetails?.displayName}}</td>
                        </tr>
                        <tr>
                          <td>Years of Experience</td>
                          <td class="f14">{{generalDetails?.experience}}</td>
                        </tr>
                        <tr>
                          <td>Location</td>
                          <td *ngFor="let location of generalDetails?.locations">
                            {{location}}
                          </td>
                        </tr>
                        <tr>
                          <td>Time Zone</td>
                          <td>
                            {{generalDetails?.timeZone}}
                          </td>
                        </tr>
                        <tr>
                          <td>Certifications</td>
                          <td class="f14">
                            <div class="dhcn ml-2">
                              <div class="yth klm mb-2" style="border: 1px solid; padding: 2px 10px;" *ngFor="let certificate of generalDetails?.certificateDetails">
                                <div class="dis iykg">
                                  <p class="wse">{{certificate?.certificateName}}
                                  </p>
                                  <p class="ber">
                                    {{certificate?.issuingOrganization}}
                                  </p>
                                </div>
                                <div class="dis fl-ri">
                                  <p>{{certificate?.yearObtainedFrom}} -
                                    {{certificate?.yearObtainedTo}}</p>
                                </div>
                                <div>
                                  <p class="dis f12 hfq"> <img src="assets/dashboard/swd.png"><span
                                      (click)="certicatedPreview(certificate.id)" data-toggle="modal"
                                      data-target="#examplesModal" style="cursor: pointer;">View
                                      Certificate</span></p>
                                  <p class="dis fl-ri" style="cursor: pointer;"
                                    (click)="removeCertificateDetails(certificate)">
                                    Remove</p>
                                </div>
                              </div>
                              <a class="cmvk" (click)="showCertificateModal = true;" style="cursor: pointer;">+ Add
                                More</a>
                            </div>
                          </td>
                          <td class="f14">
                            <div class="">
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Education</td>
                          <td class="f14" style="border: 1px solid white;">
                            <div class="pl-4 pt-2" *ngFor="let education of generalDetails?.educationDetails">
                            {{education}}</div></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div *ngIf="tabname == 'Essentials'" class="col-lg-8 tab-pane fade" id="second" role="tabpanel" aria-labelledby="second-tab">
                    <div class="hgbpp">
                      <table class="ithy">
                        <tr>
                          <td>Instruments Played</td>
                          <td class="f14">
                            <div class="lo-cn pb-4" *ngFor="let instrument of essentailsDetails?.instruments">
                              <span> {{instrument}} </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Favorite Genres</td>
                          <td>
                            <div class="lo-cn pb-4" *ngFor="let genres of essentailsDetails?.genres">
                              <span>{{genres}}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Act / Band Size</td>
                          <td class="f14">
                            <div class="lo-cn pb-3" *ngFor="let bandsize of essentailsDetails?.bandSizes">
                              <span> {{bandsize}} </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Notable Musicians
                            you played with</td>
                          <td>
                            <div class="lo-cn" *ngFor="let notableMusician of essentailsDetails?.notableMusicians">
                              <span>{{notableMusician}}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Fee Range</td>
                          <td class="f14">{{essentailsDetails?.feeRangeFrom}}$ -
                            {{essentailsDetails?.feeRangeTo}}$</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div *ngIf="tabname == 'Background'" class="col-lg-8 tab-pane fade" id="third" role="tabpanel" aria-labelledby="third-tab">
                    <div class="hgbpp">
                      <table class="ithy fdfd-bck">
                        <tr>
                          <td class="wi-tf">My Career In
                            Summary</td>
                          <td class="f14">
                            <div class="namo baxl">{{backgroundDetails?.careerSummary}}<p>
                                <img src="assets/dashboard/war.svg">This is
                                displayed as your bio on your profile
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Most Influential Artists</td>
                          <td class="f14">{{backgroundDetails?.influentialArtists}}</td>
                        </tr>
                        <tr>
                          <td>Albums Credits</td>
                          <td class="f14">{{backgroundDetails?.albumCredits}}</td>
                        </tr>
                        <tr>
                          <td>My First Musical
                            Memory</td>
                          <td class="f14">{{backgroundDetails?.firstMusicalMemory}}</td>
                        </tr>
                        <tr>
                          <td>What I love
                            about music</td>
                          <td class="f14">{{backgroundDetails?.loveAboutMusic}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div *ngIf="tabname == 'Production'" class="col-lg-8 tab-pane fade" id="fourth" role="tabpanel" aria-labelledby="fourth-tab">
                    <div class="hgbpp">
                      <table class="ithy">
                        <tr>
                          <td class="promarg">Onsite</td>
                          <td class="f14 promarg" *ngFor="let onsite of productionDetails?.onsites">{{onsite}}
                          </td>
                        </tr>
                        <tr>
                          <td class="promarg">Video Setup</td>
                          <td class="f14 promarg" *ngFor="let videoSetup of productionDetails?.videoSetups">
                            {{videoSetup}}
                          </td>
                        </tr>
                        <tr>
                          <td class="promarg">Video Quality</td>
                          <td class="f14 promarg" *ngFor="let videoQuality of productionDetails?.videoQualities">
                            {{videoQuality}}
                          </td>
                        </tr>
                        <tr>
                          <td class="promarg">Audio Setup</td>
                          <td class="f14 promarg" *ngFor="let audiosetup of productionDetails?.audioSetups">
                            {{audiosetup}}</td>
                        </tr>
                        <tr>
                          <td class="promarg">Performance
                            Environment</td>
                          <td class="f14 promarg"
                            *ngFor="let performance of productionDetails?.performanceEnvironments">
                            {{performance}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div *ngIf="tabname == 'Media'" class="col-lg-8 tab-pane fade" id="fifth" role="tabpanel" aria-labelledby="fifth-tab">
                    <div class="hgbpp">
                      <table class="ithy">
                        <tr>
                          <td>Upload Images</td>
                          <td class="f14">
                            <ng-container *ngFor="let image of mediaDetails?.imageDetails">
                              <div class="dfgrq">
                                <div class="jkmne">
                                  <img [src]="image.imageUrl" width="60px" height="60px">
                                  <span class="pl-2">{{image.imageUrl }}</span>
                                </div>
                                <div class="swde">
                                  <p (click)="imagePreview(image.id)" style="cursor: pointer;">View</p>
                                  <p style="cursor: pointer;" (click)="imageRemove(image.id)">
                                    Remove</p>
                                </div>
                              </div>
                            </ng-container>
                            <a class="cmvk" (click)="showUploaImageModal = true;" style="cursor: pointer;">+ Add
                              More</a>
                          </td>
                        </tr>
                        <tr>
                          <td>Upload Videos</td>
                          <td class="f14">
                            <ng-container *ngFor="let media of mediaDetails?.videoDetails">
                              <div class="dfgrq">
                                <div class="jkmne">
                                  <div class="row" style="display: inline-flex;">
                                    <div class="col-md-6">
                                      <video width="122" height="60" [src]="media.videoUrl" type="video/mp4" controls
                                        style="margin-top: 16px;"></video>
                                    </div>
                                    <div class="col-md-6">
                                      <p class="pl-2" style="margin-top: 10px;">
                                        Video Title : {{media.videoTitle}}</p>
                                      <p style="color: rgb(59, 126, 214);" class="pl-2">#{{media.videoTags[0]}}</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="swde">
                                  <p (click)="videoPreview(media.id)" style="cursor: pointer;">View</p>
                                  <p style="cursor: pointer;" (click)="videoRemove(media.id)">
                                    Remove</p>
                                </div>
                              </div>
                            </ng-container>
                            <div class="dfgrq">
                              <div class="jkmne">
                                <img src="assets/dashboard/Rectangle 3.svg">
                                <span class="pl-2">Filename.mp4</span>
                              </div>
                              <div class="swde">
                                <p>View</p>
                                <p>Remove</p>
                              </div>
                            </div>
                            <a class="cmvk" style="cursor: pointer;" data-toggle="modal" data-target=".video-modal"
                              data-backdrop="static" data-keyboard="false" data-backdrop="false">+ Add More</a>
                          </td>
                        </tr>
                        <tr>
                          <td>Links to Public Videos</td>
                          <div class="lo-cn">
                            <span>
                              <ng-container *ngFor="let media of mediaDetails?.publicVideos">
                               {{media}}
                              </ng-container>
                            </span>
                          </div>
                        </tr>
                        <tr>
                          <td>Links to Social Videos</td>
                          <div class="lo-cn">
                            <span>
                              <ng-container *ngFor="let media of  mediaDetails?.socialVideos">
                                {{media}}
                              </ng-container>
                            </span>
                          </div>
                        </tr>
                        <tr>
                          <td>Link To Your Website</td>
                          <div class="lo-cn">
                            <span>
                              <ng-container *ngFor="let media of  mediaDetails?.websites">
                                {{media}}
                              </ng-container>
                            </span>
                          </div>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                 </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
<div class="modal fade" data-backdrop="false" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog d-flex" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Image preview</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <img src={{mediaDetails?.imageUrl}}>
    </div>
  </div>
</div>
<div class="modal fade" data-backdrop="false" id="exampleModals" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog d-flex" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Video Preview</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <video src={{videoDetail?.videoUrl}} controls>
      </video>
    </div>
  </div>
</div>

<div class="modal fade edfraa" id="examplesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog d-flex modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Certificate View</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <img [src]="previewCertificate" style="width: 100%;">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': showCertificateModal, 'd-none': !showCertificateModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content  bg_md degy"
      style="box-shadow: 3px 1px black;overflow-y: scroll;overflow-x: hidden;height: 90vh;">
      <div class="modal-header" style="border-bottom:none">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="showCertificateModal = false;" style="color: white; font-size: 31px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <form class="jhnu ge" [formGroup]="certificateForm">
              <div class="form-group">
                <label for="username">Certificate Name</label>
                <input type="text" class="ge-fk" formControlName="certificateName" placeholder="e.g Music Production">
                <div
                  *ngIf="certificateForm.controls['certificateName'].touched && !certificateForm.controls['certificateName'].valid"
                  class="text-danger">
                  <span *ngIf="certificateForm.controls['certificateName'].hasError('required')">
                    Certificate Name is required
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="username">Issuing Organization</label>
                <input type="text" class="ge-fk" formControlName="issuingOrganization" placeholder="e.g Berklee Online">
                <div
                  *ngIf="certificateForm.controls['issuingOrganization'].touched && !certificateForm.controls['issuingOrganization'].valid"
                  class="text-danger">
                  <span *ngIf="certificateForm.controls['issuingOrganization'].hasError('required')">
                    Issuing Organization is required
                  </span>
                </div>
              </div>
              <div class="form-group yr-ob">
                <label for="username">Year Obtained</label>
                <div class="row">
                  <div class="col-6">
                    <mat-form-field appearance="outline" style="height: 50px;">
                      <input matInput [matDatepicker]="yearObtainedFrom" [formControl]="yearFrom" placeholder="From">
                      <mat-datepicker-toggle matSuffix [for]="yearObtainedFrom" class="text-white">
                      </mat-datepicker-toggle>
                      <mat-datepicker #yearObtainedFrom startView="multi-year"
                        (yearSelected)="YearFrom($event,yearObtainedFrom)" panelClass="example-month-picker"
                        color="primary"></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="outline" style="height: 50px;">
                      <input matInput [matDatepicker]="yearObtainedTo" [formControl]="yearTo" placeholder="To">
                      <mat-datepicker-toggle matSuffix [for]="yearObtainedTo" class="text-white">
                      </mat-datepicker-toggle>
                      <mat-datepicker #yearObtainedTo startView="multi-year"
                        (yearSelected)="YearTo($event,yearObtainedTo)" panelClass="example-month-picker"
                        color="primary"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="text-danger mt-2 text-center"
                  *ngIf="certificateForm.controls['issuingOrganization'].hasError('required')">
                  Year is required
                </div>
              </div>
              <div class="form-group">
                <label for="username">Upload Certificate</label>
                <div class="row">
                  <div class="col-md-12">
                    <label class="ge-fk lbf" style="text-align: center;
                                        justify-content: center;
                                        align-items: center;">
                      <span class="brw" style="display: inline;">
                        <img src="assets/upload_video_icon.svg" class="img-fluid" height="20" width="20">
                        <p class="mb-0" style="color: #FF6720;">Browse to upload</p>
                      </span>
                      <input type="file" class="fi_n" formControlName="certificateUrl" #fileInput
                        (change)="onFileSelectCertificate($event)" />
                    </label>
                    <div class="innerModal">
                      <ng-container *ngIf="imageUploaded; else imageUploadedDone">
                        <p style="color: #FF6720;"> <i class="fa fa-spinner" aria-hidden="true"></i> Uploading... </p>
                      </ng-container>
                      <ng-template #imageUploadedDone>
                        <span class="fhnbi-c">×</span>
                        <img *ngIf="showImage" [src]="imageSrc" alt="" class="certificateImage de-ht">
                      </ng-template>
                    </div>
                    <div
                      *ngIf="certificateForm.controls['certificateUrl'].touched && !certificateForm.controls['certificateUrl'].valid">
                      <span *ngIf="certificateForm.controls['certificateUrl'].hasError('required')">
                        Certicficate Upload is required
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer fm-bt" style="border-top:none">
        <button type="button" class="btn btn-primary col-6" data-dismiss="modal"
          (click)="certificateDetailsUpload()">Submit</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade video-modal ge" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content bg_md">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Upload Videos</h5>
        <button type="button" (click)="showUploaModalvid = false;" class="close close-btn" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true" class="clo">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="videoUploadForm">
          <div class="form-group ">
            <label for="username">Video Title</label>
            <input type="text" class="ge-fk ti" formControlName="videoTitle"
              placeholder="What is the title of your video">
          </div>
          <div class="form-group thyj">
            <label for="username">Add Video Tags</label>
            <mat-form-field class="example-chip-list" appearance="outline">
              <mat-chip-list #musicList>
                <mat-chip *ngFor="let keyword of keywords" [selectable]="selectableTag" [removable]="removableTag"
                  (removed)="removeMusic(keyword)">
                  {{keyword}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input style="color: #FFFFFF;" [matChipInputFor]="musicList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodesTag" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addMusic($event)" />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="form-group">
            <label for="username">Upload Video</label>
            <div class="row">
              <div class="col-md-12">
                <div class="eqwy">
                  <div class="image-upload-wrapper">
                    <div class="logo-wrapper" *ngIf="showIcon || !showImagevid">
                      <img src="../../../assets/icons/image-upload.png" (click)="filevid.click()" alt="">
                    </div>
                    <p *ngIf="showIcon || !showImagevid">Click to browse
                    </p>
                  </div>
                  <div class="image-wrapper">
                    <ng-container *ngIf="videoUploaded; else videoUploadedDone">
                      <p style="color: #FF6720;"> <i class="fa fa-spinner" aria-hidden="true"></i>
                        Uploading... </p>
                    </ng-container>
                    <ng-template #videoUploadedDone>
                      <video *ngIf="showImagevid" [src]="imageSrcvid" class="xie"
                        style="height: 100%;width: 100%; object-fit: cover;" type="video/mp4" controls></video>
                      <span style="color: #FF6720;cursor:pointer;" *ngIf="showImage" (click)="showImage = false"></span>
                      <i *ngIf="showImagevid" class="fa fa-times" aria-hidden="true" style="position: absolute;
                      bottom: 50px;top: 0;right: 0; color: red;cursor: pointer;" (click)="showImagevid = false"></i>
                    </ng-template>
                  </div>
                </div>
                <input class="img-fluid" formCotrolName="videoUrl" type="file" accept="video/*" #filevid style="display: none;"
                  (change)="onFileSelectvid($event)">
                <p class="info">
                  <img src="../../../assets/icons/info.png" alt="">
                  Please upload at least one video.
                  You must own or have rights to use these video.
                </p>
              </div>
            </div>
          </div>
          <span class="file-info"></span>
        </form>
      </div>
      <div class="modal-footer fm-bt border-0 pb_t">
        <button type="button" class="btn btn-primary col-6" (click)="addVideoDetails()"
          data-dismiss="modal">Submit</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': showImageModal, 'd-none': !showImageModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content upladModal bg_md">
      <div class="modal-header">
        <div>
          <h5 class="modal-title">
            Image View</h5>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showImageModal = false;"
          style="color: white; font-size: 31px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <img [src]="previewImage" style="width: 100%;">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': videoImageModal, 'd-none': !videoImageModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content upladModal bg_md">
      <div class="modal-header">
        <div>
          <h5 class="modal-title">
            Video View</h5>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="videoImageModal = false;"
          style="color: white; font-size: 31px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <video [src]="previewVideo" style="width: 100%; " controls></video>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="images-modal" [ngClass]="{'d-block': showUploaImageModal, 'd-none': !showUploaImageModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content upladModal" style="width: 58vh;
        top: 136px;
        position: absolute;
        right: 41px; background: #111111;">
      <div class="modal-header">
        <div>
          <h5 class="modal-title">
            Upload Image</h5>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="showUploaImageModal = false;" style="color: white; font-size: 31px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div>
              <div class="image-upload-wrapper" *ngIf="!showImage">
                <div class="logo-wrapper">
                  <img src="../../../assets/icons/image-upload.png" alt="" (click)="file3.click()">
                </div>
                <p>Click to browse</p>
              </div>
              <div class="image-wrapper">
                <ng-container *ngIf="imageUploaded2; else imageUploadedDones">
                  <p style="color: #FF6720;"> <i class="fa fa-spinner" aria-hidden="true"></i>
                    Uploading... </p>
                </ng-container>
                <ng-template #imageUploadedDones>
                  <img *ngIf="showImage" [src]="videoDetails" style="height: 30%;width: 101%; object-fit: cover;">
                  <i *ngIf="showImage" class="fa fa-times" aria-hidden="true"
                    style="position: absolute; bottom: 262px;left: 396px;cursor: pointer;font-size: 20px;color: red;"
                    (click)="showImage = false"></i>
                </ng-template>
              </div>
              <input class="img-fluid" type="file" accept="image/*" #file3 style="display: none;" (change)="onFileSelectImage($event)">
              <div class="erfg">
                <button type="submit" class="eijh" style="margin-top: 17px;" (click)="addImageDetails()">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
