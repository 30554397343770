import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/service/main.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-artist-invitation',
  templateUrl: './artist-invitation.component.html',
  styleUrls: ['./artist-invitation.component.scss']
})
export class ArtistInvitationComponent implements OnInit {
  keyWord: any = this.router.queryParams
  type: any
  pageSize: number = 10;
  pageNo: number = 0;
  searchList: any;
  max = 5;
  rate = 0;
  isReadonly = true;
  screen: boolean = false;
  inviteCancel: boolean = false;
  userTypeValue: any;
  dateRangePicker: boolean = false
  advanceSearchForm!: FormGroup
  searchgen: string[] = [];
  selectablegen = true;
  removable = true;
  removablegen = true;
  genCtrl = new FormControl();
  // separatorKeysCodess: number[] = [ENTER, COMMA];
  // separatorKeysCodesgen: number[] = [ENTER, COMMA];
  separatorKeysCodess: number[] = [COMMA];
  location: string[] = [];
  locationCtrl = new FormControl();
  genList: any;
  searchInstuments: string[] = [];
  selectable = true;
  instumentCtrl = new FormControl();
  instrumentList: any;
  allSelected = false;
  allSelected1 = false;
  allSelected2 = false;
  allSelected3 = false;
  allSelected4 = false;
  videoQualityList: any;
  videosetList: any;
  onSiteList: any;
  audioSetupList: any;
  bandSize: any = [];
  PerformanceEnvironmentList: any;
  searchKeywords: any[] = [];
  deviceCtrl = new FormControl();
  activateRoute: any;
  eventId: any;
  artistInvite: any;
  showContent: boolean;
  inviteArtist: any;
  secOneStatus = "active";
  secTwoStatus = "";
  tabName = 'sectionOne';
  searchKeyword: any;
  searchArray: any;
  param: any;
  advanceSearchDetails: any;
  data = [];
  instData = [];
  invitationId: any;
  inviteData: boolean = false;
  inviteDatas: boolean = false;
  @ViewChild('locInput')
  locInput!: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [COMMA];
  searchName: any;

  constructor(
    private router: ActivatedRoute,
    private fb: FormBuilder,
    private route: Router,
    private mainservice: MainService,
    private ActivatedRoute: ActivatedRoute,
    private NotifierService: NotifierService,
    public sessionService: SessionService
  ) {
    this.showContent = false;
    this.advanceSearchForm = this.fb.group({
      audioSetupDetails: new FormControl(),
      onsiteDetails: new FormControl(),
      performanceEnvironmentDetails: new FormControl(''),
      videoQualityDetails: new FormControl(''),
      videoSetupDetails: new FormControl(''),
      feeRangeFrom: new FormControl(''),
      feeRangeTo: new FormControl(''),
      location: new FormControl(''),
      bandSizes: new FormControl(''),
      experience: new FormControl(''),
      genres: new FormControl(''),
      instruments: new FormControl(''),
    })
    history.pushState(null, window.location.href);

    this.genCtrl.valueChanges.pipe(
      debounceTime(500),
      startWith(null)
    ).subscribe((value: string) => {
      if (value) {
        this.mainservice.searchGenres(value).then((res: any) => {
          if (res && res.isSuccess) {
            this.genList = res.data;
          }
        })
      }
    })

    this.instumentCtrl.valueChanges.pipe(
      debounceTime(500),
      startWith(null)
    ).subscribe((value: string | null) => {
      if (value) {
        this.mainservice.searchInstuments(value).then((res: any) => {
          if (res && res.isSuccess) {
            this.instrumentList = res.data;
          }
        })
      }
    })

  }


  ngOnInit(): void {
    this.type = this.keyWord['_value'].type
    this.eventId = this.ActivatedRoute.snapshot.queryParams.id;
    let selectedArtistId = this.sessionService.getSession('selectedArtistId');
    if (selectedArtistId && this.keyWord['_value'].hired) {
      let body = {
        "artistId": selectedArtistId,
        "eventId": this.eventId
      }
      this.mainservice.artistInvitation(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.inviteCancel = false;
          this.tabName = 'sectionTwo';
          this.screen = true;
          this.mainservice.getAllInvitedArtistList(this.pageNo, this.pageSize, this.eventId).then((data) => {
            if (data && data.isSuccess) {
              this.inviteDatas = false;
              this.inviteArtist = data.data.content;
            } else
              this.inviteDatas = false;
          })
          this.NotifierService.showSuccess(data.message);
        } else
          this.inviteData = false;
      })
    }

    if (this.type == 'pri') {
      this.screen = true
    } else if (this.type === 'pub') {
      this.screen = false
    }
    this.getOnSiteList();
    this.getVideosetList();
    this.getVideoQualityList();
    this.getAudioSetupList();
    this.getPerformanceEnvironmentList();
    this.getBandSize()
  }

  @ViewChild('select')
  select!: MatSelect;
  @ViewChild('select1')
  select1!: MatSelect;
  @ViewChild('select2')
  select2!: MatSelect;
  @ViewChild('select3')
  select3!: MatSelect;
  @ViewChild('select4')
  select4!: MatSelect;


  dataSearch(event?: any) {
    this.inviteData = true;
    this.userTypeValue = event?.target?.value ? event.target.value : this.searchName ;
    this.mainservice.normalSearch(this.pageNo, this.pageSize, this.userTypeValue, 'invite', this.eventId).then((data: any) => {
      if (data && data.isSuccess) {
        this.inviteData = false;
        this.searchList = data.data.content;
        this.showContent = true;
      } else {
        this.inviteData = false;
        this.searchList = "Data Not Found";
      }
    })
  }
  handleAddressChange(address: Address) {
    this.location.push(address.formatted_address);
    this.locationCtrl.setValue(null);
    this.locInput.nativeElement.value = '';
  }
  removeLocation(address: string): void {
    const index = this.location.indexOf(address);
    if (index >= 0) {
      this.location.splice(index, 1);
    }
  }

  pubFunctionality(type: any) {
    if (type == 'skip')
      this.route.navigate(['ArtistDashboard/artistEventDetail'])
    else if (type == 'next')
      this.screen = true
  }

  invite(userId: any, eventId: any) {
    this.dateRangePicker = true
  }

  removegen(gen: string): void {
    const index = this.searchgen.indexOf(gen);
    if (index >= 0) {
      this.searchgen.splice(index, 1);
    }
  }

  addgen(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our country
    if ((value || '').trim()) {
      this.searchgen.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.genCtrl.setValue(null);
  }

  selectedgen(event: MatAutocompleteSelectedEvent): void {
    this.searchgen.push(event.option.viewValue);
    // this.genInput.nativeElement.value = '';
    this.genCtrl.setValue(null);
  }
  remove(instrument: string): void {
    const index = this.searchInstuments.indexOf(instrument);
    if (index >= 0) {
      this.searchInstuments.splice(index, 1);
    }
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our country
    if ((value || '').trim()) {
      this.searchInstuments.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.deviceCtrl.setValue(null);
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.advanceSearchForm.controls['instruments'].setValue(event.option.viewValue)
    this.searchInstuments.push(event.option.viewValue);
    // this.deviceInput.nativeElement.value = '';
    this.instumentCtrl.setValue(null);
  }
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  toggleAllSelection1() {
    if (this.allSelected1) {
      this.select1.options.forEach((item: MatOption) => item.select());
    } else {
      this.select1.options.forEach((item: MatOption) => item.deselect());
    }
  }
  toggleAllSelection2() {
    if (this.allSelected2) {
      this.select2.options.forEach((item: MatOption) => item.select());
    } else {
      this.select2.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllSelection3() {
    if (this.allSelected3) {
      this.select3.options.forEach((item: MatOption) => item.select());
    } else {
      this.select3.options.forEach((item: MatOption) => item.deselect());
    }
  }
  toggleAllSelection4() {
    if (this.allSelected4) {
      this.select4.options.forEach((item: MatOption) => item.select());
    } else {
      this.select4.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  optionClick1() {
    let newStatus = true;
    this.select1.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected1 = newStatus;
  }

  optionClick2() {
    let newStatus = true;
    this.select2.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected2 = newStatus;
  }

  optionClick3() {
    let newStatus = true;
    this.select3.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected3 = newStatus;
  }

  optionClick4() {
    let newStatus = true;
    this.select4.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected4 = newStatus;
  }

  getVideosetList() {
    this.mainservice.getVideoSetupList().then((data: any) => {
      if (data && data.isSuccess) {
        this.videosetList = data.data
      }
    })
  }

  getVideoQualityList() {
    this.mainservice.getVideoQualityList().then((data: any) => {
      if (data && data.isSuccess) {
        this.videoQualityList = data.data
      }
    })
  }

  getAudioSetupList() {
    this.mainservice.getAudioSetupList().then((data: any) => {
      if (data && data.isSuccess ) {
        this.audioSetupList = data.data
      }
    })
  }

  getPerformanceEnvironmentList() {
    this.mainservice.getPerformanceEnvironmentList().then((data: any) => {
      if (data && data.isSuccess) {
        this.PerformanceEnvironmentList = data.data
      }
    })
  }

  getBandSize(): any {
    this.mainservice.getBandSize().then((data: any) => {
      if (data && data.isSuccess ) {
        this.bandSize = data.data
      }
    })
  }
  getOnSiteList() {
    this.mainservice.getOnsiteList().then((data: any) => {
      if (data && data.isSuccess ) {
        this.onSiteList = data.data;

      }
    })
  }

  slectValue(event: any) {
    this.mainservice.searchGenres(event.target.value).then((data) => {
      this.data = data.data;
      this.advanceSearchForm.controls.genres.reset();
    })
  }

  slectValueInst(event: any) {
    this.mainservice.searchInstuments(event.target.value).then((data) => {
      this.instData = data.data;
      this.advanceSearchForm.controls.instruments.reset();
    })
  }

  advancedSearch() {
    // type MyArrayType = Array<{ type: string}>;

    let MyArrayType = [
      { type: 'audioSetupDetails', text: 'audioSetup' },
      { type: 'performanceEnvironmentDetails', text: 'environment' },
      { type: 'videoQualityDetails', text: 'videoQuality' },
      { type: 'videoSetupDetails', text: 'videoSetups' },
      { type: 'onsiteDetails', text: 'onsite' },
      { type: 'feeRangeTo', text: 'feeRangeTo' },
      { type: 'feeRangeFrom', text: 'feeRangeFrom' },
      { type: 'bandSizes', text: 'bandSize' },
      { type: 'location', text: 'locations' },
      { type: 'genres', text: 'genres' },
      { type: 'instruments', text: 'instrumentsPlayed' },
      { type: 'experience', text: 'experience' },
    ];
    let param, paramholder
    let count = 0
    this.advanceSearchForm.controls['genres'].setValue(this.searchgen);
    for (let i = 0; i < 12; i++) {
      if (this.advanceSearchForm.controls[MyArrayType[i].type].value != null &&
        this.advanceSearchForm.controls[MyArrayType[i].type].value != "" &&
        this.advanceSearchForm.controls[MyArrayType[i].type].value != undefined) {
        if (count == 0) {
          param = MyArrayType[i].text + '=' + this.advanceSearchForm.controls[MyArrayType[i].type].value
        }
        else {
          param = paramholder + '&' + MyArrayType[i].text + '=' + this.advanceSearchForm.controls[MyArrayType[i].type].value
        }
        paramholder = param
        count++
        this.searchKeywords[i] = (this.advanceSearchForm.controls[MyArrayType[i].type].value)
      }
    }
    if (count > 0)
      this.getAdvanceSearchData(param + "&source=invite&eventId=" + this.eventId)
  }

  getAdvanceSearchData(param: any) {
    this.inviteData = true;
    this.mainservice.advanceSearch(param).then((data: any) => {
      if (data && data.isSuccess) {
        this.inviteData = false;
        this.searchList = data.data.content;
        this.showContent = true;
      } else {
        this.inviteData = false;
        this.advanceSearchDetails = 'Data Not Found'
      }

    })
  }

  artistInvitation(i: any, id: any, type: any) {
    this.invitationId = id
    if (type == 'invite')
      this.confirmArtistInvitation()
    else if (type == 'cancelInvite')
      this.inviteCancel = true
  }
  confirmArtistInvitation(type?: any) {
    this.inviteData = true;
    this.inviteDatas = true;
    let body = {
      "artistId": this.invitationId,
      "eventId": this.eventId
    }
    this.mainservice.artistInvitation(body).then((data: any) => {
      if (data && data.isSuccess) {
        this.inviteCancel = false
        this.mainservice.getAllInvitedArtistList(this.pageNo, this.pageSize, this.eventId).then((data) => {
          if (data && data.isSuccess) {
            this.inviteDatas = false;
            this.inviteArtist = data.data.content;
          } else {
            this.inviteDatas = false;
          }
        })
        this.NotifierService.showSuccess(data.message);
        this.mainservice.normalSearch(this.pageNo, this.pageSize, this.userTypeValue, 'invite', this.eventId).then((data: any) => {
          if (data && data.isSuccess) {
            this.inviteData = false;
            this.searchList = data.data.content;
          } else {
            this.inviteData = false;
            this.searchList = "Data Not Found";
          }
        })
      } else {
        this.inviteData = false;
        this.NotifierService.showError(data.message);
      }
    })
  }

  getAllInvitedArtistList() {
    this.inviteData = true;
    this.mainservice.getAllInvitedArtistList(this.pageNo, this.pageSize, this.eventId).then((data) => {
      if (data && data.isSuccess) {
        this.inviteData = false;
        this.inviteArtist = data.data.content;
        this.tabName = 'sectionTwo'
        this.secOneStatus = ''; this.secTwoStatus = 'active';
        this.NotifierService.showSuccess(data.message);
      } else {
        this.inviteData = false;
        this.NotifierService.showError(data.message);
      }
    })
  }

  inviteFormSubmit() {
    this.tabName = ('sectionOne');
    this.secOneStatus = 'active'; this.secTwoStatus = '';
  }
  ngOnDestroy() {
    this.sessionService.removeSession('selectedArtistId')
  }

}
