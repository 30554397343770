<div *ngIf="mainTab == 'editDraft'" id="layoutSidenav_content ml-4">
  <main style="margin-left: 40px;">
    <div class="container-fluid px-4 pt-3">
      <div class="setting-content-wrapper">
        <section>
          <div class=" row">
            <div class="col-12" style="color: white; font-size: 28px; font-weight: 500;"><span>Drafts</span></div>
          </div>
          <div class="hgnb bot-m row">
            <div class="nvhi hgnb col-6 text-center" style="cursor: pointer;"
              [ngClass]="selectTab == 'draft' ? 'activeSelectTab' : ''" (click)="selectTab = 'draft'">
              <span>Events</span>
            </div>
            <div class="nvhi hgnb col-6 text-center" style="cursor: pointer;"
              [ngClass]="selectTab == 'proposal' ? 'activeSelectTab' : ''" (click)="proposalsList()">
              <span>Proposals</span>
            </div>
          </div>
          <div>
            <div *ngIf="selectTab == 'draft'" class="are-you">
              <div *ngIf="draftList?.length>0" class="derfik">
                <div class="">
                  <label class="container1"><i class="fa fa-sort-desc" aria-hidden="true"></i>
                    <input type="checkbox" (click)="selectAll('eventDraft')">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div>
                  <i class="fa fa-repeat" (click)="getDraftList()" aria-hidden="true"></i>
                  <i class="fa fa-trash-o" aria-hidden="true" (click)="deleteDrafts()"></i>
                </div>
              </div>
              <ng-container *ngIf="draftListData">
                <div style="text-align: center;">
                  <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
                    secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
                </div>
              </ng-container>
              <ng-container *ngIf="!draftList?.length && !draftListData">
                <div class="center-error">
                  <div class="error-wrapper">
                    <h1 class="text-center" style="color: white;">Oops</h1>
                    <p class="text-center" style="color: white;">No Drafts Found </p>
                  </div>
                </div>
              </ng-container>
              <div class="derfik hgtya" *ngFor="let draft of draftList">
                <div class="row ml-3" style="width: 100%;">
                  <div class="row" style="width: 100%;">
                    <div class="col-1 px-0">
                      <label class="container1"><input type="checkbox" [checked]="selectAllDraft">
                        <span class="checkmark" (click)="deleteDraftSelection(draft.id)"></span>
                      </label>
                    </div>
                    <div class="col-1 px-0" style="cursor: pointer;" (click)="specificDraft(draft.id)">
                      <h5 class="mb-0 pointer breakLine"
                        (click)="specificDraft(draft.id)">{{draft.title}}</h5>
                    </div>
                    <div class="col-8 pl-4" style="cursor: pointer;" (click)="specificDraft(draft.id)">
                      <p class="iu7 mb-0 d-flex text-justify breakLine">
                        {{draft.description.slice(0, 175)}}...</p>
                    </div>
                    <div class="col-2" style="cursor: pointer;" (click)="specificDraft(draft.id)">
                      <p>{{draft?.createdAt }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectTab == 'proposal'" class="are-you">
              <div *ngIf="proposalDraftList?.length>0" class="derfik">
                <div class="">
                  <label class="container1"><i class="fa fa-sort-desc" aria-hidden="true"></i>
                    <input type="checkbox" (click)="selectAll('proposalDraft')">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div>
                  <i class="fa fa-repeat" (click)="proposalsList()" aria-hidden="true"></i>
                  <i class="fa fa-trash-o" aria-hidden="true" (click)="deleteProposalDrafts()"></i>
                </div>
              </div>
              <ng-container *ngIf="proposalListData">
                <div style="text-align: center;">
                  <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
                    secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
                </div>
              </ng-container>
              <ng-container *ngIf="!proposalDraftList?.length && !proposalListData">
                <div class="center-error">
                  <div class="error-wrapper">
                    <h1 class="text-center" style="color: white;">Oops</h1>
                    <p class="text-center" style="color: white;">No Drafts Found </p>
                  </div>
                </div>
              </ng-container>
              <div class=" hgtya" style="color: white; padding-left: 20px;"
                *ngFor="let proposaldraft of proposalDraftList">
                <div class="row ml-3">
                  <div class="row" style="width: 100%;">
                    <div class="col-1 px-0">
                      <label class="container1"><input type="checkbox" [checked]="selectAllProposalDraft">
                        <span class="checkmark" (click)="deleteProposalDraftSelection(proposaldraft.proposalId)"></span>
                      </label>
                    </div>
                    <div class="col-1 px-0" style="cursor: pointer;"
                      (click)="specificProposalDraft(proposaldraft.proposalId)">
                      <h5 class="mb-0 breakLine"> ${{proposaldraft.totalFee}} </h5>
                    </div>
                    <div class="col-8 pl-4" style="cursor: pointer;"
                      (click)="specificProposalDraft(proposaldraft.proposalId)">
                      <p class="iu7 mb-0 d-flex text-justify breakLine">
                        {{proposaldraft.coverLetter.slice(0, 175)}}...</p>
                    </div>
                    <div class="col-2" style="cursor: pointer;"
                      (click)="specificProposalDraft(proposaldraft.proposalId)">
                      <p>{{proposaldraft?.createdAt}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</div>
<!-- view proposal draft screen -->
<div *ngIf="mainTab == 'proposalModal'" class="deaa ml-5 pl-4" id="rockband">
  <div class=" modalBg my-4" role="document">
    <div class=" modalBg judhr">
      <div>
        <div class="container">
          <div class="row pt-3" style="color: white;">
            <div class="col-11">
              <h3>Submit a proposal</h3>
            </div>
            <div class="col-1 text-right">
              <button type="button" class="close">
                <span aria-hidden="true" (click)="mainTab='editDraft'" style="color: white;">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body editproposal" style="color: white;">
        <div class="container sthutr">
          <div class="row pb-3">
            <div class="col-12 mt-3">
              <h5>Event Details</h5>
              <hr style="border-bottom: 1px solid white;">
            </div>
            <div class="col-9 breakLine">
              <h5 > {{proposalDetails?.title | titlecase}} </h5>
              <p class="mt-2 d-flex text-justify"> {{proposalDetails?.description}} </p>
            </div>
            <div class="col-3" style="border-left: 1px solid white;">
              <p><small>${{proposalDetails?.budget}}</small></p>
              <p><small *ngFor="let gen of proposalDetails?.genres">{{gen}}</small></p>
              <p><small>{{proposalDetails.proposals}} Proposal, {{proposalDetails.messages}} Messaged</small></p>
              <p><small>Status : {{proposalDetails.proposalStatus | titlecase}}</small></p>
            </div>
          </div>
        </div>
        <div class="container" style="width: 90%;">
          <div class="row py-3">
            <div class="col-12 mt-3">
              <div>
                <label for="coverLetter">Cover Letter</label><br>
                <textarea class="textBox" [(ngModel)]="sendProposalValue.coverLetter" name="coverLetter"
                  id="coverLetter" cols="30" rows="4"></textarea>
              </div>
              <div class="pt-3">
                <label for="estimatedHours">Estimated Hours</label><br>
                <input type="number" [(ngModel)]="sendProposalValue.estimateHours" (keyup)="calculateAmount()"
                  class="inputBox" id="coverLetter" name="coverLetter">
              </div>
            </div>
          </div>
        </div>
        <div class="container" style="width: 90%;">
          <div class="row pt-2">
            <div class="col-12 fir-tab">
              <p class="ve-nue">How do you want to be paid </p>
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item pr-5">
                  <input type=radio id="rdo1" checked class="radio-input" name="radio-group"
                    (click)="paidOptionSelection('per_hour')">
                  <label for="rdo1" class="radio-label"> <span class="radio-border"></span> Per Hour </label>
                </li>
                <li class="nav-item pl-5">
                  <input type=radio id="rdo2" class="radio-input" name="radio-group"
                    (click)="paidOptionSelection('total_fee')">
                  <label for="rdo2" class="radio-label"><span class="radio-border"></span>Total Fee</label>
                </li>
              </ul>
              <div class="tab-content mt-4">
                <div *ngIf="tabName == 'per_hour'">
                  <form [formGroup]="proposalDraftForm">
                    <div class="form-group">
                      <label for="hourlyFee">Hourly Fee</label><br>
                      <p class="ratoj">$</p>
                      <input type="number" class="inputBox" formControlName="hourlyFee" (keyup)="calculateAmount()">
                    </div>
                    <div class="form-group">
                      <label for="totalFee">Total Fee</label><br>
                      <p class="ratoj">$</p>
                      <input type="number" class="inputBox" formControlName="totalFee">
                    </div>
                    <div class="form-group">
                      <label for="additionalNotes">Additional Notes</label>
                      <textarea class="form-control textBox" formControlName="additionalNotes" id="description" rows="4"
                        autocomplete="off"></textarea>
                    </div>
                  </form>
                </div>
                <div *ngIf="tabName == 'total_fee'">
                  <form [formGroup]="proposalDraftForm">
                    <div class="form-group">
                      <label for="totalFee">Total Fee</label><br>
                      <p class="ratoj">$</p>
                      <input class="inputBox" formControlName="totalFee" type="number">
                    </div>
                    <div class="form-group">
                      <label for="additionalNotes">Additional Notes</label>
                      <textarea class="form-control textBox" formControlName="additionalNotes" id="description" rows="4"
                        autocomplete="off"></textarea>
                    </div>
                  </form>
                </div>
                <div class="form-check">
                  <input class="form-check-input" [(ngModel)]="checkBox1" type="checkbox" value="" id="defaultCheck1">
                  <label class="form-check-label" for="defaultCheck1">
                    I have read and understand the requirements of the gig posting that I am responding to in this
                    proposal.
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" [(ngModel)]="checkBox2" type="checkbox" value="" id="defaultCheck2">
                  <label class="form-check-label" for="defaultCheck2">
                    I have read and understand the Virtuosica ToS that are an integral part of any contract that results
                    as an outcome of this proposal.
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 my-5">
              <button [disabled]="userStatus == 'PENDING_DELETE'" type="submit" class="btn defrqqa edcae"
                (click)="submitProposal('submitProposal')" data-toggle="modal" data-target=".sendProModal">Send
                Proposal</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- View Draft Screen Start Here -->
<section *ngIf="mainTab == 'editDraftView'" style="margin-left: '40px';">
  <div class="hgnb bot-m">
    <div class="nvhi">
      <span class="utg"> Edit Event </span>
    </div>
  </div>
  <div class="sthutr mt-5">
    <div class="vent-deta">
      <div class="rujghyt">
        <form [formGroup]="eventForm">
          <div class=" form-group mb-4">
            <label for="Evetle">Event Title</label>
            <input type="text" class="form-control" id="Evetle" formControlName="eventTitle">
          </div>
          <div class="form-group mb-4">
            <label for="Eveyp">Event Type</label>
            <div>
              <mat-form-field class="multiSelect">
                <mat-select class="pl-3" formControlName="eventType" #select2 multiple>
                  <div class="select-all ml-3">
                    <mat-checkbox [(ngModel)]="allSelected2" [ngModelOptions]="{standalone: true}"
                      (change)="toggleAllSelection2()">Select All
                    </mat-checkbox>
                  </div>
                  <mat-option style="z-index: 10000000;" (click)="optionClick2()" *ngFor="let videoquality of eventList"
                    [value]="videoquality"> {{videoquality}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="form-group mb-4 ">
            <label class="f700 mb-2">Genre</label>
            <div class="deggt">
              <mat-form-field class="example-chip-list" appearance="fill">
                <mat-chip-list #chipList aria-label="Fruit selection">
                  <mat-chip *ngFor="let genres of searchgen" (removed)="removegen(genres)"> {{genres}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input style="border: none; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                    #genInput [formControl]="genCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodesgen" (matChipInputTokenEnd)="addgen($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedgen($event)">
                  <mat-option *ngFor="let gen of genList" [value]="gen"> {{gen}} </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start typing to select your genres</small>
            </div>
          </div>
          <div class="form-group mb-4 ">
            <label class="f700 mb-2 siy">Size of Act</label>
            <div>
              <mat-form-field class="multiSelect">
                <mat-select class="pl-3" formControlName="actSize" #select multiple>
                  <div class="select-all ml-3">
                    <mat-checkbox [(ngModel)]="allSelected" style="color: white;" [ngModelOptions]="{standalone: true}"
                      (change)="toggleAllSelection()">Select All
                    </mat-checkbox>
                  </div>
                  <mat-option (click)="optionClick()" *ngFor="let bandsize of actSizeList" [value]="bandsize">
                    {{bandsize}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="Date">Start Date</label>
                <input type="date" [min]="today" autocomplete="off" class="form-control" formControlName="startDate" id="Date">
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="Date">End Date</label>
                <input type="date" [min]="today" autocomplete="off" class="form-control" formControlName="endDate" id="Date">
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="time">Start Time</label>
                <timepicker [(ngModel)]="startTime" [formControl]="startTimectrl"></timepicker>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="time2">End Time</label>
                <timepicker [(ngModel)]="endTime" [formControl]="endTimectrl"></timepicker>
              </div>
            </div>
          </div>
          <div class="form-group gftrhh ">
            <div class="mb-4 fgt1">
              <label class="f700 mb-2 nujm">Budget</label>
              <p class="rato"><i class="fa fa-usd" aria-hidden="true"></i></p>
              <input type="number" class="form-control edww" id="nujm" formControlName="budget">
            </div>
          </div>
          <div class="fir-tab">
            <p class="ve-nue">Venue </p>
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item" (click)="toggle('live_streamed')"
                [ngClass]="status == 'LIVE_STREAMED'? 'active':'' ">
                <input type=radio id="rdo1" checked class="radio-input" name="radio-group">
                <label for="rdo1" class="radio-label"> <span class="radio-border"></span> Live Streamed </label>
              </li>
              <li class="nav-item" (click)="toggle('onsite')" [ngClass]="status == 'ONSITE'? 'active':'' ">
                <input type=radio id="rdo2" class="radio-input" name="radio-group">
                <label for="rdo2" class="radio-label"><span class="radio-border"></span>Onsite</label>
              </li>
              <li class="nav-item" (click)="toggle('hybrid')" [ngClass]="status == 'HYBRID'? 'active':'' ">
                <input type=radio id="rdo3" class="radio-input" name="radio-group">
                <label for="rdo3" class="radio-label"><span class="radio-border"></span>Hybrid</label>
              </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content mt-4">
              <div [ngClass]="status == 'LIVE_STREAMED'? 'active':'' " *ngIf="showLiveSection">
                <form [formGroup]="eventLiveStreamedForm">
                  <div class="form-group">
                    <label for="preferred">Preferred Devices</label>
                    <mat-form-field class="multiSelect">
                      <mat-select class="pl-3" formControlName="preferredDevices" #select3 multiple>
                        <div class="ml-3 pt-1">
                          <mat-checkbox [(ngModel)]="allSelected3" [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection3()">Select All
                          </mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick3()" *ngFor="let item of preferedList" [value]="item">{{item}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <label for="description">Event Description</label>
                    <textarea class="form-control hgtaa" formControlName="eventDescp" id="description" rows="4"></textarea>
                  </div>
                </form>
              </div>
              <div [ngClass]="status == 'ONSITE'? 'active':'' " *ngIf="onsite && !showLiveSection && !hybrid">
                <form [formGroup]="eventOnSiteForm">
                  <div class="form-group">
                    <label for="production">Production Capabilities</label>
                    <textarea class="form-control hgtaa" formControlName="production" id="production" rows="4"></textarea>
                  </div>
                  <div class="form-group pou">
                    <label for="street">Street Address</label>
                    <p class="ratoj"><i class="fa fa-map-marker" aria-hidden="true"></i></p>
                    <input type="text" class="form-control" id="street" formControlName="streetAddress">
                  </div>
                  <div class="form-group">
                    <label for="performance">Performance Environment</label>
                    <input type="text" class="form-control" id="performance" formControlName="performanceEnv">
                  </div>
                  <div class="form-group">
                    <label for="description">Event Description</label>
                    <textarea class="form-control hgtaa" id="description" formControlName="eventDescription" rows="4"></textarea>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" formControlName="liveStream">
                    <label class="form-check-label" for="defaultCheck1">
                      I will not be live streaming or distributing recordings of this event
                    </label>
                  </div>
                </form>
              </div>
              <div [ngClass]="status == 'HYBRID'? 'active':'' " *ngIf="hybrid && !onsite && !showLiveSection">
                <form [formGroup]="eventHybridForm">
                  <div class="form-group">
                    <label for="production">Production Capabilities</label>
                    <textarea class="form-control hgtaa" formControlName="prodCapabilities" id="production" rows="4"></textarea>
                  </div>
                  <div class="form-group pou">
                    <label for="street">Street Address</label>
                    <p class="ratoj"><i class="fa fa-map-marker" aria-hidden="true"></i></p>
                    <input type="text" class="form-control" formControlName="streetAddress" id="street">
                  </div>
                  <div class="form-group">
                    <label for="performance">Performance Environment</label>
                    <input type="text" class="form-control" id="performance" formControlName="performanceEnv">
                  </div>
                  <div class="form-group">
                    <label for="description">Event Description</label>
                    <textarea class="form-control hgtaa" id="description" formControlName="eventDescription" rows="4"></textarea>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="form-group mb-4">
            <h5>Special Requirements</h5>
            <textarea class="form-control hgtaa" id="special" formControlName="specialReq" rows="4"></textarea>
          </div>
          <div class="form-group mb-4">
            <p class="f700 mb-2">Select Status</p>
          </div>
          <div class="mb-4">
            <label class='toggle-label'>
              <input type='checkbox' />
              <span class='back'>
                <span class='toggle'></span>
                <span class='label off' (click)="statusToggle('PRIVATE')">Private</span>
                <span class='label on' (click)="statusToggle('PUBLIC')">Public</span>
              </span>
            </label>
          </div>
          <button type="submit" class="btn hftrdd" (click)="editEventSubmit('back')">Back</button>
          <button [disabled]="userStatus == 'PENDING_DELETE'" type="submit" class="btn defrqqa edcae"
            (click)="editEventSubmit('post')">Post </button>
        </form>
      </div>
    </div>
  </div>
</section>
