import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';

@Component({
  selector: 'app-artist-pending-staff-pick',
  templateUrl: './artist-pending-staff-pick.component.html',
  styleUrls: ['./artist-pending-staff-pick.component.scss']
})
export class ArtistPendingStaffPickComponent implements OnInit {
  staffPicks:any;
  pageNo: number = 0;
  pageSize: number = 8;

  constructor(
    private _MainService:MainService,
    private _NotifierService:NotifierService
  ) { }

  ngOnInit(): void {
    this.getAllStaffPicks(this.pageNo,this.pageSize)
  }

  getAllStaffPicks(pageNo: number, pageSize: number){
    this._MainService.getAllStaffPicks().then((data)=>{
      if(data && data.isSuccess){
          this.staffPicks =  data.data;
      }else{
        this._NotifierService.showError(data.message);
      }
    })
  }
}
