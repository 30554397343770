import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SafePipe } from 'src/app/service/safe.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-audience-watch-test-stream',
  templateUrl: './audience-watch-test-stream.component.html',
  styleUrls: ['./audience-watch-test-stream.component.scss']
})
export class AudienceWatchTestStreamComponent implements OnInit {

  poster: any;
  // videoLink = 'https://bitmovin-a.akamaihd.net/content/playhouse-vr/m3u8s/105560.m3u8';
  watchVideoLink = environment.watchVideoLink;
  videoLink: any;
  param: any = this.activatedRoute.queryParams;

  constructor(private activatedRoute: ActivatedRoute, private pipe: SafePipe ) { }

  ngOnInit(): void {
    this.videoLink = this.watchVideoLink + this.param['_value'].streamId;
    this.videoLink = this.pipe.transform(this.videoLink)
  }

}
