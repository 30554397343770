<div class="joinpage">
    <div class="joinhead">
        <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg" routerLink="" style="cursor: pointer;">
        <div class="nav-item dropdown hgne" style="top: -2px;display: flex; float: right;">
            <a class="nav-link dropdown-toggle" (click)="logOut = !logOut" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <img src="assets/dashboard/Mask Group.png">
            </a>
            <div *ngIf="logOut" class="" style="position: absolute;left: -63px;top: 41px;; border: 1px solid white; border-radius: 15px; background: black;" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" style="cursor: pointer;color: white;background: none;" (click)="logoutUser()"  ><i class="fa fa-sign-out"
                  aria-hidden="true" ></i> Log Out</a>
            </div>
          </div>
    </div>
  </div>
<div class="joinbody">
    <div class="container ge">
      <div class="">
        <div class="">
          <div class="row mb-5">
            <div class="col-md-6 col-sm-12 col-xl-6">
              <div class="joinleft">
                <app-slider></app-slider>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xl-6 ">
              <div class="">
                <div class="row">
                  <div class="col-md-10">
                    <div class="wi-4">
                      <app-tabsall [activeTab]="1"></app-tabsall>
                      <div class="tab-content" id="pills-tabContent">
                        <form class="ghbt" [formGroup]="generalDetailsForm">
                          <div class="form-group ">
                            <label for="username"> Display Name </label>
                            <input type="text" formControlName="displayName" class="ge-fk " placeholder="John Snow">
                            <div
                              *ngIf="generalDetailsForm.controls['displayName'].touched && !generalDetailsForm.controls['displayName'].valid"
                              class="text-danger">
                              <span *ngIf="generalDetailsForm.controls['displayName'].hasError('required')">
                                Display Name is required
                              </span>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="password">How many years you have been
                              playing?</label>
                            <input type="text" class="ge-fk " autocomplete="off" placeholder="Enter your experience"
                              formControlName="experience" id="Enter your experience">
                            <div
                              *ngIf="generalDetailsForm.controls['experience'].touched && !generalDetailsForm.controls['experience'].valid"
                              class="text-danger">
                              <span *ngIf="generalDetailsForm.controls['experience'].hasError('required')">
                                Experience is required
                              </span>
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="password">Locations</label>
                            <mat-form-field class="example-chip-list genersList" appearance="fill">
                              <mat-chip-list #chipList aria-label="Fruit selection">
                                <mat-chip *ngFor="let address of location" (removed)="remove(address)">
                                  {{address}}
                                  <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                  </button>
                                </mat-chip>
                                <input style="color: white;" placeholder="&nbsp;" #fruitInput
                                  [formControl]="locationCtrl" [matChipInputFor]="chipList"
                                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" ngx-google-places-autocomplete
                                  #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)">
                              </mat-chip-list>
                            </mat-form-field>
                  <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start typing to select your location</small>
                  <div *ngIf="locationCtrl.touched && locationCtrl.valid" class="text-danger">
                              <span *ngIf="locationCtrl.hasError('required')">
                                Location is required
                              </span>
                            </div>
                          </div>
                          <div class="form-group hftuu dee">
                            <label for="password">Time Zone</label>
                            <mat-form-field class="example-chip-list" appearance="fill">
                              <mat-chip-list #chipListTimeZone>
                                <mat-chip *ngFor="let timeZone of searchTimeZone" (removed)="removeTimeZone(timeZone)">
                                  {{timeZone}}
                                  <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                  </button>
                                </mat-chip>
                                <input
                                  style="border: none; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                                  #timeZoneInput [formControl]="timeZoneCtrl" [matAutocomplete]="auto"
                                  [matChipInputFor]="chipListTimeZone"
                                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                              </mat-chip-list>
                              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTimeZone($event)">
                                <mat-option *ngFor="let time of timeZoneArray" [value]="time">
                                  {{time}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                          <div class="form-group">
                            <label class="huhb" for="password">Certifications</label>
                            <div class="add-label-wrapper">
                              <input type="text" class="ge-fk" (click)="showUploaModal = true;" readonly>
                              <span lass="file-info" style="right: 176px !important;">{{fileName}}</span>
                              <span style="color: white; right: 6px;">
                                <a class="cmvk" (click)="showUploaModal = true;" style="cursor: pointer;">+ Add More</a>
                              </span>
                              <span style="color: white; right: 6px;margin-top: 42px;"
                                *ngIf="CertificatedDetails && CertificatedDetails.length">
                                <a class="cmvk" (click)="socialVideosaddmore = true;" style="cursor: pointer;">View
                                  uploaded Certificate</a>
                              </span>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="password">Education</label>
                            <mat-form-field appearance="outline">
                              <mat-select #select formControlName="educationDetails" multiple>
                                <div class="select-all">
                                  <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                                    (change)="toggleAllSelection()">Select All
                                  </mat-checkbox>
                                </div>
                                <mat-option (click)="optionClick()" *ngFor="let educationlist of educationList"
                                  [value]="educationlist">
                                  {{educationlist}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <div
                              *ngIf="generalDetailsForm.controls['educationDetails'].touched && !generalDetailsForm.controls['educationDetails'].valid"
                              class="text-danger">
                              <span *ngIf="generalDetailsForm.controls['educationDetails'].hasError('required')">
                                Education is required
                              </span>
                            </div>
                          </div>
                          <div class="form-group for-str kdjnb">
                            <button type="submit" class="form-control nxt" (click)="saveGeneralProfileDetails()">
                              <ng-container *ngIf="generalFormSubmitting; else genralFormSubmittingDone">
                                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...
                              </ng-container>
                              <ng-template #genralFormSubmittingDone>
                                Next
                              </ng-template>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="modal" [ngClass]="{'d-block': showUploaModal, 'd-none': !showUploaModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content  bg_md" style="box-shadow: 3px 1px black;overflow-y: scroll;height: 90vh;">
      <div class="modal-header" style="border-bottom:none">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showUploaModal = false;"
          style="color: white; font-size: 31px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-0">
        <div class="row">
          <div class="col-md-12">
            <form class="jhnu ge" [formGroup]="certificateForm">
              <div class="form-group">
                <label for="username">Certificate Name</label>
                <input type="text" class="ge-fk" formControlName="certificateName" placeholder="e.g Music Production">
                <div
                  *ngIf="certificateForm.controls['certificateName'].touched && !certificateForm.controls['certificateName'].valid"
                  class="text-danger">
                  <span *ngIf="certificateForm.controls['certificateName'].hasError('required')">
                    Certificate Name is required
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="username">Issuing Organization</label>
                <input type="text" class="ge-fk" formControlName="issuingOrganization" placeholder="e.g Berklee Online">
                <div
                  *ngIf="certificateForm.controls['issuingOrganization'].touched && !certificateForm.controls['issuingOrganization'].valid"
                  class="text-danger">
                  <span *ngIf="certificateForm.controls['issuingOrganization'].hasError('required')">
                    Issuing Organization is required
                  </span>
                </div>
              </div>
              <div class="form-group yr-ob">
                <label for="username">Year Obtained</label>
                <div class="row">
                  <div class="col-6">
                    <mat-form-field appearance="outline" style="height: 50px;">
                      <input type="number" matInput [matDatepicker]="yearObtainedFrom" [formControl]="yearFrom"
                        placeholder="From">
                      <mat-datepicker-toggle matSuffix [for]="yearObtainedFrom" class="text-white">
                      </mat-datepicker-toggle>
                      <mat-datepicker #yearObtainedFrom startView="multi-year"
                        (yearSelected)="YearFrom($event,yearObtainedFrom)" panelClass="example-month-picker"
                        color="primary" disabled="false"></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="outline" style="height: 50px;">
                      <input type="number" matInput [matDatepicker]="yearObtainedTo" [formControl]="yearTo"
                        placeholder="To">
                      <mat-datepicker-toggle matSuffix [for]="yearObtainedTo" class="text-white">
                      </mat-datepicker-toggle>
                      <mat-datepicker #yearObtainedTo startView="multi-year"
                        (yearSelected)="YearTo($event,yearObtainedTo)" panelClass="example-month-picker" color="primary"
                        disabled="false"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="text-danger mt-2 text-center"
                  *ngIf="certificateForm.controls['issuingOrganization'].hasError('required')">
                  Year is required
                </div>
              </div>
              <div class="form-group mb-1">
                <label for="username">Upload Certificate</label>
                <div class="row">
                  <div class="col-md-12">
                    <label class="ge-fk lbf" style="text-align: center; justify-content: center; align-items: center;">
                      <span class="brw" style="display: inline;">
                        <img src="assets/upload_video_icon.svg" class="img-fluid" height="20" width="20">
                        <p class="mb-0" style="color: #FF6720;">Browse to upload</p>
                      </span>
                      <input type="file" accept="image/*" class="fi_n" formControlName="certificateUrl" #fileInput
                        (change)="onFileSelectCertificate($event)" />
                      <span class="file-info">{{ fileInfo }}</span>
                    </label>
                    <div class="innerModal">
                      <ng-container *ngIf="imageUploaded; else imageUploadedDone">
                        <p style="color: #FF6720;"> <i class="fa fa-spinner" aria-hidden="true"></i> Uploading... </p>
                      </ng-container>
                      <ng-template #imageUploadedDone>
                        <img *ngIf="showImage" [src]="imageSrc" alt="" class="certificateImage">
                        <span style="color: #FF6720;cursor:pointer;" *ngIf="showImage" (click)="showImage = false">
                          <i class="fa fa-trash-o" aria-hidden="true" style="position: relative;
                          top: -165px; right: 4px; color: red; cursor: pointer;"></i>
                        </span>
                      </ng-template>
                    </div>
                    <div
                      *ngIf="certificateForm.controls['certificateUrl'].touched && !certificateForm.controls['certificateUrl'].valid">
                      <span *ngIf="certificateForm.controls['certificateUrl'].hasError('required')">
                        Certicficate Upload is required
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer fm-bt pt-0" style="border-top:none">
        <div class="row" style="width: 100%;">
          <div class="offset-3 col-6">
            <button style="width: 100%;" type="button" class="btn btn-primary" data-dismiss="modal"
            (click)="certificateDetailsUpload()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': socialVideosaddmore, 'd-none': !socialVideosaddmore}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body edgt">
        <form class="oik ">
          <div class="form-group">
            <div class="row deftt">
              <div class="col-12 pb-l add-label-wrapper">
                <h2>Certifications</h2>
                <div class="dhcn">
                  <div class="yth klm" *ngFor="let certificate of generalDetails?.certificateDetails">
                    <div class="dis iykg">
                      <p class="wse">{{certificate?.certificateName}}
                      </p>
                      <p class="ber">
                        {{certificate?.issuingOrganization}}
                      </p>
                    </div>
                    <div class="dis fl-ri">
                      <p>{{certificate?.yearObtainedFrom}} -
                        {{certificate?.yearObtainedTo}}</p>
                    </div>
                    <div>
                      <p class="dis f12 hfq"> <img src="assets/dashboard/swd.png">
                        <span (click)="certicatedPreview(certificate.id)" data-toggle="modal"
                          data-target="#examplesModal" style="cursor: pointer;"
                          style="right: 0px !important;top: 0px !important;position: relative;cursor: pointer;">View
                          Certificate</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer ede">
        <button type="button" class="btn btn-secondary yht " (click)="socialVideosaddmore = false"
          style="height: 42px;width: 86px;">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="examplesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog d-flex wsaw" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Certificate View</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <img [src]="previewCertificate" style="width: 100%;">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
