import { Component, OnInit, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
// import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MainService } from 'src/app/service/main.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from 'swiper';
import { UserService } from 'src/app/service/user.service';
import { AuthServiceService } from 'src/app/service/auth-service.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { LoginModal } from 'src/app/service/models/auth.modals';

declare const Swiper: any;
SwiperCore.use([Autoplay, Pagination, Navigation]);
@Component({
  selector: 'app-production',
  templateUrl: './production.component.html',
  styleUrls: ['./production.component.scss'],
})
export class ProductionComponent implements OnInit {
  onSiteList: any;
  productionCapabilitiesForm!: FormGroup;
  toppings = new FormControl();
  videosetList: any;
  videoQualityList: any;
  audioSetupList: any;
  PerformanceEnvironmentList: any;
  productionFormSubmitting: boolean;
  submitted = false;
  productionDetails: any;
  onsites: any;
  onSiteValues: any;
  videosetup: any;
  onvideoSetupValues: any;
  videoquality: any;
  onvideoQualityValues: any;
  audioSetup: any;
  onaudioSetupValues: any;
  performance: any;
  perfomanceValues: any;
  interval: any;
  browser: any;
  login: LoginModal;
  logOut: boolean = false;

  constructor(
    private mainservice: MainService,
    private fb: FormBuilder,
    private router: Router,
    private notifier: NotifierService,
    private _UserService: UserService,
    private _auth: AuthServiceService,
    private _SessionService: SessionService
  ) {
    this.productionFormSubmitting = false;
    this.login = new LoginModal();
  }

  ngOnInit(): void {
    this.getOnSiteList();
    this.getVideosetList();
    this.getVideoQualityList();
    this.getAudioSetupList();
    this.getPerformanceEnvironmentList();
    this.getProducionDetails();
    this.browser = this.login.device;
    this.productionCapabilitiesForm = this.fb.group({
      audioSetupDetails: new FormControl(this.onaudioSetupValues),
      onsiteDetails: new FormControl(),
      performanceEnvironmentDetails: new FormControl(''),
      videoQualityDetails: new FormControl(''),
      videoSetupDetails: new FormControl(''),

      // audioSetupDetails: new FormControl(this.onaudioSetupValues),
      // onsiteDetails: new FormControl(this.getOnSite),
      // performanceEnvironmentDetails: new FormControl(this.perfomanceValues),
      // videoQualityDetails: new FormControl(this.onvideoQualityValues),
      // videoSetupDetails: new FormControl(this.onvideoSetupValues),
    });
  }
  // select all
  @ViewChild('select')
  select!: MatSelect;
  @ViewChild('select1')
  select1!: MatSelect;
  @ViewChild('select2')
  select2!: MatSelect;
  @ViewChild('select3')
  select3!: MatSelect;
  @ViewChild('select4')
  select4!: MatSelect;

  allSelected = false;
  allSelected1 = false;
  allSelected2 = false;
  allSelected3 = false;
  allSelected4 = false;

  allSelectedNone = false;
  allSelectedNone1 = false;
  allSelectedNone2 = false;
  allSelectedNone3 = false;
  allSelectedNone4 = false;

  noneAllSelect() {
    this.allSelected = false;
    this.select.options.forEach((item: MatOption) => item.deselect());
    this.productionCapabilitiesForm.controls.onsiteDetails.setValue(['None']);
  }

  noneAllSelect1() {
    this.allSelected1 = false;
    this.select1.options.forEach((item: MatOption) => item.deselect());
    this.productionCapabilitiesForm.controls.videoSetupDetails.setValue([
      'None',
    ]);
  }

  noneAllSelect2() {
    this.allSelected2 = false;
    this.select2.options.forEach((item: MatOption) => item.deselect());
    this.productionCapabilitiesForm.controls.videoQualityDetails.setValue([
      'None',
    ]);
  }

  noneAllSelect3() {
    this.allSelected3 = false;
    this.select3.options.forEach((item: MatOption) => item.deselect());
    this.productionCapabilitiesForm.controls.audioSetupDetails.setValue([
      'None',
    ]);
  }

  noneAllSelect4() {
    this.allSelected4 = false;
    this.select4.options.forEach((item: MatOption) => item.deselect());
    this.productionCapabilitiesForm.controls.performanceEnvironmentDetails.setValue(
      ['None']
    );
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
      this.allSelectedNone= false;
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  toggleAllSelection1() {
    if (this.allSelected1) {
      this.select1.options.forEach((item: MatOption) => item.select());
      this.allSelectedNone1= false;
    } else {
      this.select1.options.forEach((item: MatOption) => item.deselect());
    }
  }
  toggleAllSelection2() {
    if (this.allSelected2) {
      this.select2.options.forEach((item: MatOption) => item.select());
      this.allSelectedNone2= false;
    } else {
      this.select2.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllSelection3() {
    if (this.allSelected3) {
      this.select3.options.forEach((item: MatOption) => item.select());
      this.allSelectedNone3= false;
    } else {
      this.select3.options.forEach((item: MatOption) => item.deselect());
    }
  }
  toggleAllSelection4() {
    if (this.allSelected4) {
      this.select4.options.forEach((item: MatOption) => item.select());
      this.allSelectedNone4= false;
    } else {
      this.select4.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() {
    this.allSelectedNone = false;
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  optionClick1() {
    this.allSelectedNone1 = false;
    let newStatus = true;
    this.select1.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected1 = newStatus;
  }

  optionClick2() {
    this.allSelectedNone2 = false;
    let newStatus = true;
    this.select2.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected2 = newStatus;
  }
  optionClick3() {
    this.allSelectedNone3 = false;
    let newStatus = true;
    this.select3.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected3 = newStatus;
  }
  optionClick4() {
    this.allSelectedNone4 = false;
    let newStatus = true;
    this.select4.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected4 = newStatus;
  }

  getOnSiteList() {
    this.mainservice.getOnsiteList().then((data: any) => {
      if (data && data.isSuccess) {
        let a = data.data;
        this.onSiteList = a.reverse().slice(1).reverse();
      }
    });
  }

  getVideosetList() {
    this.mainservice.getVideoSetupList().then((data: any) => {
      if (data && data.isSuccess) {
        let a = data.data;
        this.videosetList = a.reverse().slice(1).reverse();
      }
    });
  }

  getVideoQualityList() {
    this.mainservice.getVideoQualityList().then((data: any) => {
      if (data && data.isSuccess) {
        let a = data.data;
        this.videoQualityList = a.reverse().slice(1).reverse();

      }
    });
  }

  getAudioSetupList() {
    this.mainservice.getAudioSetupList().then((data: any) => {
      if (data && data.isSuccess) {
        let a = data.data;
        this.audioSetupList = a.reverse().slice(1).reverse();
      }
    });
  }

  getPerformanceEnvironmentList() {
    this.mainservice.getPerformanceEnvironmentList().then((data: any) => {
      if (data && data.isSuccess) {
        let a = data.data;
        this.PerformanceEnvironmentList = a.reverse().slice(1).reverse();
      }
    });
  }

  productionCapabilities() {
    let audioSetupDetails =
      this.productionCapabilitiesForm.controls['audioSetupDetails'].value;
    let onsiteDetails =
      this.productionCapabilitiesForm.controls['onsiteDetails'].value;
    let performanceEnvironmentDetails =
      this.productionCapabilitiesForm.controls['performanceEnvironmentDetails']
        .value;
    let videoQualityDetails =
      this.productionCapabilitiesForm.controls['videoQualityDetails'].value;
    let videoSetupDetails =
      this.productionCapabilitiesForm.controls['videoSetupDetails'].value;

    this.submitted = true;
    if (!onsiteDetails) {
      this.notifier.showError('Please Select Onsite');
    } else if (!videoSetupDetails) {
      this.notifier.showError('Please Select Video Setup');
    } else if (!videoQualityDetails) {
      this.notifier.showError('Please Select Video Quality');
    } else if (!audioSetupDetails) {
      this.notifier.showError('Please Select Audio Setup');
    } else if (!performanceEnvironmentDetails) {
      this.notifier.showError('Please Select Performance Environment');
    } else if (
      onsiteDetails &&
      videoSetupDetails &&
      videoQualityDetails &&
      audioSetupDetails &&
      performanceEnvironmentDetails
    ) {
      this.productionFormSubmitting = true;

      const body = {
        audioSetupDetails: audioSetupDetails,
        onsiteDetails: onsiteDetails,
        performanceEnvironmentDetails: performanceEnvironmentDetails,
        videoQualityDetails: videoQualityDetails,
        videoSetupDetails: videoSetupDetails,
      };
      this.mainservice
        .saveOrUpdateProductionCapabilities(body)
        .then((data: any) => {
          if (data && data.isSuccess) {
            this.router.navigateByUrl('media');
            this.productionFormSubmitting = false;
            this.notifier.showSuccess(data.message);
          } else {
            this.notifier.showError(data.message);
            this.productionFormSubmitting = false;
          }
        });
    }
  }

  getProducionDetails() {
    let userId = localStorage.getItem('userId');
    this.mainservice.getProductionDetails(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.productionDetails = data.data;
        this.productionCapabilitiesForm.controls.onsiteDetails.setValue(
          this.productionDetails?.onsites
        );
        this.onsites = this.productionDetails?.onsites;
        this.onSiteValues = this.onsites.map((item: any) => item);
        this.productionCapabilitiesForm.controls.videoSetupDetails.setValue(
          this.productionDetails?.videoSetups
        );
        this.videosetup = this.productionDetails?.videoSetups;
        this.onvideoSetupValues = this.videosetup.map((item: any) => item);
        this.productionCapabilitiesForm.controls.videoQualityDetails.setValue(
          this.productionDetails?.videoQualities
        );
        this.videoquality = this.productionDetails?.videoQualities;
        this.onvideoQualityValues = this.videoquality.map((item: any) => item);
        this.productionCapabilitiesForm.controls.audioSetupDetails.setValue(
          this.productionDetails?.audioSetups
        );
        this.audioSetup = this.productionDetails?.audioSetups;
        this.onaudioSetupValues = this.audioSetup.map((item: any) => item);
        this.productionCapabilitiesForm.controls.performanceEnvironmentDetails.setValue(
          this.productionDetails?.performanceEnvironments
        );
        this.performance = this.productionDetails?.performanceEnvironments;
        this.perfomanceValues = this.performance.map((item: any) => item);
      }
    });
  }

  logoutUser() {
    this.logOut = false;
    clearInterval(this.interval);
    const device = this.browser;
    this._auth.logout(device).then((data) => {
      if (data && data.isSuccess) {
        this._SessionService.removeSessions();
        this._SessionService.removeSession('email');
        this._SessionService.removeSession('refreshToken');
        this._SessionService.removeSession('userId');
        this._SessionService.removeSession('refreshTokenLog');
        this._SessionService.removeSession('token');
        this._SessionService.removeSession('newtoken');
        this._UserService.setLogedUser(null);
        this.router.navigate([`/login`]);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }
}
