import { Injectable, OnInit } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { data } from 'jquery';
import { SessionService } from '../../service/helper/session.service';
import { AuthServiceService } from '../../service/auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class LoginComponentResolver implements Resolve<any> {
  constructor(
    private _AuthService: AuthServiceService,
    private _SessionService: SessionService,
    private _Router: Router
  ) {}

  resolve(): Promise<any> {
    return this._AuthService.getCurrentIpInfo().then((data) => data);
  }
}
