<div *ngIf="selectTab == 'mainTab'" class="deaa ml-0 ml-md-0" id="rockband">
  <div class="container" style="color: white;">
    <div class="row">
      <div class="col-12">
        <h4>Live Streaming</h4>
      </div>
    </div>
    <hr class="mt-0 mb-4" style="border-bottom: 1px solid white;">
    <div class="modalBg" role="document">
      <div class=" modalBg judhr" style="border-radius: 15px !important;">
        <div class="modal-body p-0" style="color: white;">
          <div class="container">
            <div class="row" style="width: 100%;">
              <div class="col-12 col-md-5 p-0">
                <img class="img1" src="../../../../../../../assets/liveStreaming.png" width="390" height="440" alt="image">
              </div>
              <div class="col-12 col-md-7 px-0 px-md-2">
                <div class="row">
                  <div *ngIf="eventDetails"
                    class="col-12 my-2 my-md-4 my-lg-5 pl-3 pl-md-5 pr-0 pt-3 text-center text-md-justify breakLine">
                    <h3 *ngIf="!mobileView" class="text-break"> {{eventDetails?.title | titlecase}} </h3>
                    <h6 *ngIf="mobileView" class="text-break"> {{eventDetails?.title | titlecase}} </h6>
                    <p class="py-2 mb-0 mb-md-2"> {{eventDetails?.startDate }} </p>
                    <p class="text-center text-md-justify"> {{eventDetails?.displayName | titlecase}}
                    </p>
                  </div>
                  <div *ngIf="eventDetails" class="col-12 mb-5 text-center">
                    <button *ngIf="userRole === 'AUDIENCE'" type="submit" class="btn hftrdd ml-4 ml-md-0"
                      [routerLink]="['/audienceDashboard/eventPreview']">Back</button>
                    <button *ngIf="userRole != 'AUDIENCE'" type="submit" class="btn hftrdd ml-4 ml-md-0"
                      [routerLink]="['ArtistDashboard/artistEventDetail']">Back</button>
                    <button type="submit" class="btn defrqqa mt-3 mt-sm-0 ml-4 ml-md-0"
                      (click)="selectTab = 'stayTunedLiveStream'">Watch
                      Stream</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Stay Tuned Live Stream Screen -->
<div *ngIf="selectTab == 'stayTunedLiveStream'" class="deaa ml-0 ml-md-0" id="rockband">
  <div class="container" style="color: white;">
    <div class="row">
      <div class="col-12">
        <h4>Live Stream</h4>
      </div>
    </div>
    <hr class="mt-0 mb-4" style="border-bottom: 1px solid white;">
    <div class="modalBg my-4" role="document">
      <div class=" modalBg tunedBg" style="border-radius: 10px !important;">
        <div class="modal-body pt-3 pl-2" style="color: white;">
          <div class="container ml-0 ml-md-2 pt-3 pl-0 pl-md-3">
            <div class="row ml-2 mt-4" style="width: 100%;">
              <div class="col-12 my-5">
                <div class="row text-center">
                  <div class="col-12 mb-2">
                    <h3>Stay Tuned!</h3>
                    <p class="py-2 text-break"> Live streaming page for this event will be available 30 minutes before
                      the event.
                    </p>
                  </div>
                  <div *ngIf="timeCount?.day >= 0 && timeCount?.hour >= 0 && timeCount?.minute > -1"
                    class="col-12 mb-5 d-flex justify-content-center">
                    <div>
                      <h1> {{timeCount?.day}} </h1>
                      <p>Days</p>
                    </div>
                    <div class="mx-0 mx-md-1">
                      <h1>:</h1>
                    </div>
                    <div>
                      <h1> {{timeCount?.hour}} </h1>
                      <p>Hours</p>
                    </div>
                    <div class="mx-0 mx-md-1">
                      <h1>:</h1>
                    </div>
                    <div>
                      <h1> {{timeCount?.minute}} </h1>
                      <p>Minute</p>
                    </div>
                    <div class="mx-0 mx-md-1">
                      <h1>:</h1>
                    </div>
                    <div>
                      <h1> {{timeCount?.second}} </h1>
                      <p>Second</p>
                    </div>
                  </div>
                  <div class="col-12 mb-3 d-flex justify-content-center">
                    <button class="btn defrqqa" (click)="selectTab = 'mainTab'" style="width: 200px;"> Back </button>
                    <button *ngIf="WatchLiveBtn || eventStarted" class="btn defrqqa"
                      (click)="selectTab = 'concertStarted'; startLiveStream()" style="width: 200px;">Watch
                      Event</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Concert Started Screen -->
<div *ngIf="selectTab == 'concertStarted'" class="deaa ml-0 ml-0" id="rockband">
  <div class="container" style="color: white;">
    <div class="row">
      <div class="col-12">
        <h4 class="mb-0">Live Stream</h4>
      </div>
    </div>
  </div>
  <hr class="mt-0 mb-2" style="border-bottom: 1px solid white;">
  <div class="my-2" role="document">
    <div class="judhr" style="border-radius: 10px !important;">
      <div style="color: white;">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-xl-12 col-md-12">
              <div id="myvideo" class="row" style="height: 435px;">

                  <div class="col-12">
                    <iframe width="100%" height="100%" id="frame" [src]="videoLink" frameborder="1"></iframe>
                  </div>
                  <div class="col-4 offset-8 comment-wrapper">
                    <div class="scroll-x-wrapper" id="div1">
                      <div  *ngFor="let item of messages; let i = index">
                        <div class="comment-section" [ngStyle]="{'display':(item.message.messageTime+10000)<=timeCountCheck?'none':''}">
                      <div class="comment"[ngStyle]="{'opacity ': (messages.length == (i+1) || messages.length == (i+2)) ? '1' : ( messages.length == (i+3)  || messages.length == (i+4)) ? '0.8': messages.length == (i+5) ? '0.6' : messages.length == (i+6)  ? '0.4' : '0.2'}">
                          <div class="img-wrapper">
                            <img
                              [src]="item.message && item.message.currentUserProfile ? item.message.currentUserProfile : 'https://bootdey.com/img/Content/avatar/avatar3.png'"
                              alt="" style="object-fit: cover;">
                          </div>
                        </div>
                        <div class="comment-content"[ngStyle]="{'opacity ': (messages.length == (i+1) || messages.length == (i+2)) ? '1' : ( messages.length == (i+3)  || messages.length == (i+4)) ? '0.8': messages.length == (i+5) ? '0.6' : messages.length == (i+6)  ? '0.4' : '0.2'}">
                          <p class="username-wrapper"> {{ item.message.username | titlecase}} <span
                              style="font-size: 12px;"> {{item.message.messageTime |
                              date:'shortTime'}} </span>
                          </p>
                          <p class="message"> {{ item.message.typeMessage }} </p>
                        </div>
                      </div>
                      </div>
                    </div>
                    <form class="form" #commentForm="ngForm">
                      <div class="send-message">
                        <div class="input-wrapper">
                          <input type="text" autocomplete="off" class="form-control" name="message"
                            [(ngModel)]="commentedText" id="basic-addon2"
                            (keyup.enter)="addComment($event)">
                          <div class="emozi-attach" >
                            <span class="cursor-pointer" style="background: transparent;max-height: 72%;" (click)="emojiView=!emojiView">
                              <img src="../../../../../../assets/image.png" alt="">
                            </span>
                            <div *ngIf="emojiView == true" class="pl-2" style="width: 60px; border: 2px solid red;
                            border-radius: 25px; background: white; position: absolute; top: -326px; left: -20px;
                            height: 315px; overflow-y: scroll; " >
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😩';addComment();">😩</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😞';addComment();">😞</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '🤑';addComment();">🤑</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😐';addComment();">😐</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😊';addComment();">😊</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '⭐';addComment();">⭐</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😀';addComment();">😀</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '🍾';addComment();">🍾</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '👌';addComment();">👌</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '👍';addComment();">👍</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '👎';addComment();">👎</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '👏';addComment();">👏</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😍';addComment();">😍</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😘';addComment();">😘</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '🌟';addComment();">🌟</p>

                            </div>
                            <!-- <span class="cursor-pointer" style="background: transparent;max-height: 72%;"
                              id="basic-addon2" placement="top" container="body" [outsideClick]="true"
                              [popover]="popemoji">
                              <img src="../../../../../../assets/image.png" alt="">
                            </span> -->
                          </div>
                        </div>
                        <!-- <ng-template #popemoji >
                          <emoji-mart id="m" [emojiTooltip]="false" [isNative]="false" [perLine]="5" [enableSearch]="false"  class="emoji-mart float-right" (emojiSelect)="addComment($event)"
                            title="change color">
                          </emoji-mart>
                        </ng-template> -->
                      </div>
                    </form>
                  </div>
                </div>
                <p class="mb-0 zoom pointer" style="color: red; width: 120px;" (click)="openFullscreen()"> <b> View Full Screen</b></p>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-lg-7 col-md-5 breakLine">
                <h5 class="text-break"> {{eventDetails?.title}} Live Concert</h5>
                <p class="text-break mb-1">California, United States <span *ngFor="let gen of eventDetails?.genreDetails"> |
                    {{gen}}</span></p>
              </div>
              <div class="col-lg-5 col-md-7 p-0 d-md-flex flex-row-reverse">
                <button *ngIf="leaveButton" type="submit"
                  [disabled]="eventEndedModal || feedbackModal || leaveBtnStatus == false"
                  class="btn hftrdd ml-5 ml-md-0  px-4" (click)="leaveEvent()">Leave
                  Concert</button>
                <button type="submit" class="btn hftrdd ml-5 ml-md-0 mt-2 mt-md-0  px-2"
                  (click)="report()">Report</button>
                <button *ngIf="userRole === 'AUDIENCE'" type="submit" class="btn hftrdd ml-5 ml-md-0 mt-2 mt-md-0  px-2"
                  [routerLink]="['/audienceDashboard/eventPreview']">Back</button>
                <button *ngIf="userRole != 'AUDIENCE'" type="submit" class="btn hftrdd ml-5 ml-md-0 mt-2 mt-md-0  px-2"
                  [routerLink]="['ArtistDashboard/artistEventDetail']">Back</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Event Ended Modal -->
  <div class="modal" id="eventEndedModal" [ngClass]="{'d-block': eventEndedModal, 'd-none': !eventEndedModal}">
    <div class="modal-dialog" role="document">
      <div class="modal-content modalBg">
        <div class="modal-body" style="color: white;">
          <div class="container mb-5">
            <div class="row">
              <div class="col-12 text-right">
                <button type="button" class="close" (click)="eventEndedModal = false">
                  <span aria-hidden="true" style="color: white;">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <div class="container my-5">
            <div class="row">
              <div class="col-12 text-center">
                <h5> Are you sure you want to end this stream? This event isn't scheduled to end yet. </h5>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <button type="submit" class="btn hftrdd mt-5 mr-4" style="width: 200px;"
                  (click)="eventEndedModal = false">Go
                  Back</button>
                <button class="btn defrqqa mt-5" (click)="eventEndedModal = false ; feedbackModal = true"
                  style="width: 200px;">End Event</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Feedback Modal -->
  <div class="modal" id="feedbackModal" [ngClass]="{'d-block': feedbackModal, 'd-none': !feedbackModal}">
    <div class="modal-dialog" role="document">
      <div class="modal-content modalBg">
        <div class="modal-body" style="color: white;">
          <div class="container mb-1">
            <div class="row mt-5">
              <div class="col-12 text-right">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  (click)="feedbackModal = false">
                  <span aria-hidden="true" style="color: white;">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <div class="container mb-5">
            <div class="row">
              <div class="col-12 text-center">
                <h5> This event has ended. Tell us how you liked it! </h5>
              </div>
              <div class="col-12 text-center">
                <p><small>How do you feel after the event?</small></p>
              </div>
              <div class="col-12 text-center">
                <div class="row offset-1">
                  <div class="col-2">
                    <p style="cursor: pointer;" title="Strongly Positive"
                      [ngClass]="reactionValue == 'Strongly Positive' ? 'fs40' : 'fs35' "
                      (click)="reactionValue = 'Strongly Positive'">😀</p>
                  </div>
                  <div class="col-2">
                    <p style="cursor: pointer;" [ngClass]="reactionValue == 'Positive' ? 'fs40' : 'fs35' "
                      (click)="reactionValue = 'Positive'" title="Positive">😊</p>
                  </div>
                  <div class="col-2">
                    <p style="cursor: pointer;" [ngClass]="reactionValue == 'Neutral' ? 'fs40' : 'fs35' "
                      (click)="reactionValue = 'Neutral'" title="Neutral">😐</p>
                  </div>
                  <div class="col-2">
                    <p style="cursor: pointer;" [ngClass]="reactionValue == 'Negative' ? 'fs40' : 'fs35' "
                      (click)="reactionValue = 'Negative'" title="Negative">😞</p>
                  </div>
                  <div class="col-2">
                    <p style="cursor: pointer;" [ngClass]="reactionValue == 'Extremely Negative' ? 'fs40' : 'fs35' "
                      (click)="reactionValue = 'Extremely Negative'" title="Extremely Negative">😩</p>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-2 text-center">
                <p class="mb-0"><small>Rate This Artist Or Band</small></p>
                <div class="deft rating">
                  <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                </div>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <!-- <button type="submit" class="btn hftrdd mt-5 mr-4" style="width: 200px;" data-dismiss="modal">Go
                Back</button> -->
                <button class="btn defrqqa mt-5" (click)="submitFeedback()"
                  [disabled]="reactionValue == undefined || rate == 0" data-dismiss="modal" style="width: 200px;">End
                  Event</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
