import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';

@Component({
  selector: 'app-artist-pending-see-all',
  templateUrl: './artist-pending-see-all.component.html',
  styleUrls: ['./artist-pending-see-all.component.scss']
})
export class ArtistPendingSeeAllComponent implements OnInit {
  newArtist:any;
  pageNo: number = 0;
  pageSize: number = 8;

  constructor(
    private _MainService:MainService,
    private _NotifierService:NotifierService
  ) { }

  ngOnInit(): void {
    this.getAllNewArtist(this.pageNo,this.pageSize);
  }

  getAllNewArtist(pageNo: number, pageSize: number){
    this._MainService.getAllNewArtist(pageNo,pageSize).then((data)=>{
      if(data && data.isSuccess){
        this.newArtist = data.data.content;
        }else{
          this._NotifierService.showError(data.message);
        }
    })
  }

}
