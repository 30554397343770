<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <div class="liner"></div>
    <li class="nav-item">
        <a class="nav-link active"   [ngClass]="{'active': activeTab === 1}" id="pills-general-tab" data-toggle="pill"
             href="#pills-general" role="tab" aria-controls="pills-general"
            aria-selected="true">
            <button  [disabled]="activeTab != 1" class="round-tabs p-0 hide" >
                1
            </button> <br>
            <small class="tab-label"   [ngClass]="{'visible': activeTab === 1, 'invisible': activeTab !== 1}">
                General
            </small>

        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': activeTab === 2}" id="pills-essentials-tab" data-toggle="pill"
            href="#pills-essentials" role="tab" aria-controls="pills-essentials"
            aria-selected="false">
            <button  [disabled]="activeTab != 2" class="round-tabs p-0">
                2
            </button>
            <br>
            <small class="tab-label"   [ngClass]="{'visible': activeTab === 2, 'invisible': activeTab !== 2}">
                Essentials
            </small>
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': activeTab === 3}" id="pills--background-detail-tab" data-toggle="pill"
             href="#pills-background-detail" role="tab" aria-controls="pills--background-detail"
            aria-selected="false"><button  [disabled]="activeTab != 3" class="round-tabs p-0">
                3
            </button><br>
            <small class="tab-label" [ngClass]="{'visible': activeTab === 3, 'invisible': activeTab !== 3}">
                Background
            </small>
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': activeTab === 4}" id="pills-productioncapabilities-tab"
            data-toggle="pill"  href="#pills-productioncapabilities" role="tab"
            aria-controls="pills-productioncapabilities" aria-selected="false"><button  [disabled]="activeTab != 4" class="round-tabs p-0">
                4
            </button><br>
            <small class="tab-label" [ngClass]="{'visible': activeTab === 4, 'invisible': activeTab !== 4}">
                Production Capabilities
            </small>
        </a>
    </li>
    <!-- <li class="nav-item">
        <a class="nav-link" id="pills-media-tab" data-toggle="pill" href="#pills-media"
            role="tab" aria-controls="pills-media" aria-selected="false"><button
                class="round-tabs p-0">
                5
            </button></a>
    </li> -->

    <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': activeTab === 5}" id="pills-media-tab" data-toggle="pill"
             href="#pills-media" role="tab" aria-controls="pills-media" aria-selected="false"><button  [disabled]="activeTab != 5"
                class="round-tabs p-0">
                5
            </button><br>
            <small class="tab-label" [ngClass]="{'visible': activeTab === 5, 'invisible': activeTab !== 5}">
                Media
            </small>
        </a>
    </li>

</ul>