import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { RefreshService } from 'src/app/service/refresh.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { LoginModal } from 'src/app/service/models/auth.modals';
import { UserService } from 'src/app/service/user.service';
import { AuthServiceService } from 'src/app/service/auth-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/service/main.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { getMessaging, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
// import { Subject } from 'rxjs';
initializeApp(environment.firebaseConfig)
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class HomeComponent implements OnInit {
  @ViewChild('placesRef') placesRef!: GooglePlaceDirective;
  advanceSearchForm!: FormGroup;
  newToken: any;
  interval: any;
  interVal: any;
  browser: any;
  login: LoginModal;
  showPendingArtistData: boolean;
  showRightSideMenu: boolean;
  logOut: boolean = false
  videoQualityList: any;
  videosetList: any;
  onSiteList: any;
  audioSetupList: any;
  PerformanceEnvironmentList: any;
  allSelected = false;
  allSelected1 = false;
  allSelected2 = false;
  allSelected3 = false;
  allSelected4 = false;
  bandSize: any = [];
  searchgen: string[] = [];
  selectable = true;
  selectablegen = true;
  removable = true;
  userStatus: any;
  removablegen = true;
  instrumentList: any;
  genCtrl = new FormControl();
  instumentCtrl = new FormControl();
  deviceCtrl = new FormControl();
  separatorKeysCodess: number[] = [COMMA];
  separatorKeysCodesgen: number[] = [COMMA];
  genList: any;
  searchInstuments: string[] = [];
  path: any = this.route.queryParams;
  searchKeywords: any[] = [];
  searchBox: boolean = false;
  isNotificationActive: boolean = false;
  profileClick = 0;
  isProfileOpened: boolean = false;
  notificationClick = 0;
  notificationContainer: any;
  notification: any[] = [];
  unreadNotification: any;
  notificationData: boolean;
  location: string[] = [];
  locationCtrl = new FormControl();
  timeZoneArray: any = [];
  separatorKeysCodes: number[] = [COMMA];
  requestType = 'SETTINGS';
  @ViewChild('instInput')
  instInput!: ElementRef<HTMLInputElement>;
  @ViewChild('genInput')
  genInput!: ElementRef<HTMLInputElement>;
  @ViewChild('locInput')
  locInput!: ElementRef<HTMLInputElement>;
  myControl = new FormControl('');
  tabName = 'home';
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  sideBar: boolean = true;
  // pushNotification = new Subject();


  constructor(
    private mainservice: MainService,
    private notifier: NotifierService,
    private _refresh: RefreshService,
    private _SessionService: SessionService,
    private _UserService: UserService,
    private fb: FormBuilder,
    private _auth: AuthServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private sessionStorage: SessionService,
    private observer: BreakpointObserver

  ) {
    this.notificationData = false;
    this.showRightSideMenu = false;
    this.showPendingArtistData = false;
    this.login = new LoginModal();
    history.pushState(null, window.location.href);
    // this.location.onPopState(() => {
    //   history.pushState(null, window.location.href);
    // });
  }


  ngOnInit(): void {
    // let getMessage = getMessaging()
    // onMessage(getMessage, function (payload) {
    //   console.log("on Message", payload);
    // });

    // messaging.onBackgroundMessage(function (payload) {
    //   console.log("on onBackgroundMessage Message", payload);
    // });



    // this.pushNotification.subscribe(notification => {
    //   this.getNotifications();
    //   console.log('notification working fine');

    // })
    this.userStatus = this.sessionStorage.getSession('status');
    let refreshTokenLog: any[] = [];
    this.interval = setInterval(() => {
      this.refreshToken();
      refreshTokenLog.push({
        message: 'refresh token api called',
        dateTime: new Date(),
      });
      localStorage.setItem('refreshTokenLog', JSON.stringify(refreshTokenLog));
    }, 900000);
    this.getNotifications();
    this.interVal = setInterval(() => {
      this.getNotifications();
    }, 60000);
    this.browser = this.login.device;
    // if ((localStorage.getItem('roles') === 'PENDING ARTIST' && localStorage.getItem('profileStatus') === 'true') || localStorage.getItem('profileStatus') === 'false') {
    //   this.showPendingArtistData = true;
    // }
    if (localStorage.getItem('roles') == 'PENDING ARTIST') {
      this.showPendingArtistData = true;
    }
    this.instumentCtrl.valueChanges
      .pipe(debounceTime(500), startWith(null))
      .subscribe((value: string | null) => {
        if (value) {
          this.mainservice.searchInstuments(value).then((res: any) => {
            if (res && res.isSuccess) {
              this.instrumentList = res.data;
            }
          });
        }
      });

    this.genCtrl.valueChanges
      .pipe(debounceTime(500), startWith(null))
      .subscribe((value: string) => {
        if (value) {
          this.mainservice.searchGenres(value).then((res: any) => {
            if (res && res.isSuccess) {
              this.genList = res.data;
            }
          });
        }
      });

    this.advanceSearchForm = this.fb.group({
      audioSetupDetails: new FormControl(),
      onsiteDetails: new FormControl(),
      performanceEnvironmentDetails: new FormControl(''),
      videoQualityDetails: new FormControl(''),
      videoSetupDetails: new FormControl(''),
      feeRangeFrom: new FormControl(''),
      feeRangeTo: new FormControl(''),
      location: new FormControl(''),
      bandSizes: new FormControl(''),
      experience: new FormControl(''),
      genres: new FormControl(''),
      instruments: new FormControl(''),
      locationCtrl: new FormControl(),
    });

    this.getOnSiteList();
    this.getVideosetList();
    this.getVideoQualityList();
    this.getAudioSetupList();
    this.getPerformanceEnvironmentList();
    this.getBandSize();
  }


  ngAfterViewInit() {
    this.placesRef.options.componentRestrictions = { country: 'US' };
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
        var a = document.getElementById('mSideNav')
        if (a)
          a.style.marginLeft = '0px'

      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
      }
    });
  }

  ngDoCheck() {
    var url = window.location.href
    var a = document.getElementById('mSideNav')
    var b = document.getElementById('main')
    if (url.includes('/ArtistDashboard/artistLiveStreaming')) {
      this.sideBar = false
      if (a)
        a.style.marginLeft = '0px'
      if (b)
        b.style.paddingTop = '10px'
    }
    else {

        this.sideBar = true
      if (this.sidenav && this.sidenav.mode != 'over') {
        if (a)
          a.style.marginLeft = '179px'
        if (b)
          b.style.paddingTop = '75px'
      }
    }
    if (this.path['_value'].home) {
      this.showRightSideMenu = false;
    }
  }

  handleAddressChange(address: Address) {
    this.location.push(address.formatted_address);
    this.locationCtrl.setValue(null);
    this.locInput.nativeElement.value = '';

    this.mainservice.getTimeZone(address.address_components[3].long_name).then((data: any) => {
      if (data && data.isSuccess) {
        this.timeZoneArray = data.data
      }
    })
  }

  removeLocation(address: string): void {
    const index = this.location.indexOf(address);
    if (index >= 0) {
      this.location.splice(index, 1);
    }
  }

  @ViewChild('select')
  select!: MatSelect;
  @ViewChild('select1')
  select1!: MatSelect;
  @ViewChild('select2')
  select2!: MatSelect;
  @ViewChild('select3')
  select3!: MatSelect;
  @ViewChild('select4')
  select4!: MatSelect;

  refreshToken() {
    this._refresh.refreshToken().then((data) => {
      if (data && data.isSuccess) {
        this.newToken = data.data;
        this._SessionService.removeSession('token');
        this._SessionService.setSessions({ token: this.newToken });
      } else {
        this.router.navigate([`/login`]);
      }
    });
  }

  logoutUser() {
    this.logOut = false
    clearInterval(this.interval);
    clearInterval(this.interVal);
    const device = this.browser;
    this._auth.logout(device).then((data) => {
      if (data && data.isSuccess) {
        this._SessionService.removeSessions();
        this._SessionService.removeSession('email');
        this._SessionService.removeSession('refreshToken');
        this._SessionService.removeSession('userId');
        this._SessionService.removeSession('refreshTokenLog');
        this._SessionService.removeSession('token');
        this._SessionService.removeSession('newtoken');
        this._SessionService.removeSession('chatUuId');
        this._UserService.setLogedUser(null);
        this.router.navigate([`/login`]);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  searchQuery(event: any) {
    if (event.target.value) {
      this.router.navigate(['/ArtistDashboard/simpleSearch'], {
        queryParams: { keyWord: event.target.value },
      });
    } else {
      this.router.navigate(['/ArtistDashboard/artistHome']);
    }
  }

  advancedSearch() {
    // type MyArrayType = Array<{ type: string}>;

    let MyArrayType = [
      { type: 'audioSetupDetails', text: 'audioSetup' },
      { type: 'performanceEnvironmentDetails', text: 'environment' },
      { type: 'videoQualityDetails', text: 'videoQuality' },
      { type: 'videoSetupDetails', text: 'videoSetups' },
      { type: 'onsiteDetails', text: 'onsite' },
      { type: 'feeRangeTo', text: 'feeRangeTo' },
      { type: 'feeRangeFrom', text: 'feeRangeFrom' },
      { type: 'bandSizes', text: 'bandSize' },
      { type: 'location', text: 'locations' },
      { type: 'genres', text: 'genres' },
      { type: 'instruments', text: 'instrumentsPlayed' },
      { type: 'experience', text: 'experience' },
    ];
    let param, paramholder;
    let count = 0;
    if (this.searchgen) {
      this.advanceSearchForm.controls['genres'].setValue(this.searchgen);
    }

    for (let i = 0; i < 12; i++) {
      if (
        this.advanceSearchForm.controls[MyArrayType[i].type].value != null &&
        this.advanceSearchForm.controls[MyArrayType[i].type].value != '' &&
        this.advanceSearchForm.controls[MyArrayType[i].type].value != undefined
      ) {
        if (count == 0) {
          param =
            MyArrayType[i].text +
            '=' +
            this.advanceSearchForm.controls[MyArrayType[i].type].value;
        } else {
          param =
            paramholder +
            '&' +
            MyArrayType[i].text +
            '=' +
            this.advanceSearchForm.controls[MyArrayType[i].type].value;
        }
        paramholder = param;
        count++;

        this.searchKeywords[i] =
          this.advanceSearchForm.controls[MyArrayType[i].type].value;
      } else {
      }
    }
    if (count > 0) this.getAdvanceSearchData(param);
    else if (count == 0)
      this.notifier.showError('Atleast Select One Search Criteria');
  }

  getAdvanceSearchData(param: any) {
    this._SessionService.setSessions({ advanceSearch: param });
    this._SessionService.setSessions({ searchKeywords: this.searchKeywords });
    this.showRightSideMenu = true;
    this.searchBox = false;
    this.router.navigate(['/ArtistDashboard/advancedSearch']);
  }
  closeAdvanceSearch() {
    this.advanceSearchForm.reset();
    this.searchgen = [];
    this.searchInstuments = [];
    this.location = [];
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  toggleAllSelection1() {
    if (this.allSelected1) {
      this.select1.options.forEach((item: MatOption) => item.select());
    } else {
      this.select1.options.forEach((item: MatOption) => item.deselect());
    }
  }
  toggleAllSelection2() {
    if (this.allSelected2) {
      this.select2.options.forEach((item: MatOption) => item.select());
    } else {
      this.select2.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllSelection3() {
    if (this.allSelected3) {
      this.select3.options.forEach((item: MatOption) => item.select());
    } else {
      this.select3.options.forEach((item: MatOption) => item.deselect());
    }
  }
  toggleAllSelection4() {
    if (this.allSelected4) {
      this.select4.options.forEach((item: MatOption) => item.select());
    } else {
      this.select4.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  optionClick1() {
    let newStatus = true;
    this.select1.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected1 = newStatus;
  }

  optionClick2() {
    let newStatus = true;
    this.select2.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected2 = newStatus;
  }

  optionClick3() {
    let newStatus = true;
    this.select3.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected3 = newStatus;
  }

  optionClick4() {
    let newStatus = true;
    this.select4.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected4 = newStatus;
  }

  getOnSiteList() {
    this.mainservice.getOnsiteList().then((data: any) => {
      if (data && data.isSuccess) {
        this.onSiteList = data.data;
      }
    });
  }

  getVideosetList() {
    this.mainservice.getVideoSetupList().then((data: any) => {
      if (data && data.isSuccess) {
        this.videosetList = data.data;
      }
    });
  }

  getVideoQualityList() {
    this.mainservice.getVideoQualityList().then((data: any) => {
      if (data && data.isSuccess) {
        this.videoQualityList = data.data;
      }
    });
  }

  getAudioSetupList() {
    this.mainservice.getAudioSetupList().then((data: any) => {
      if (data && data.isSuccess) {
        this.audioSetupList = data.data;
      }
    });
  }

  getPerformanceEnvironmentList() {
    this.mainservice.getPerformanceEnvironmentList().then((data: any) => {
      if (data && data.isSuccess) {
        this.PerformanceEnvironmentList = data.data;
      }
    });
  }

  getBandSize(): any {
    this.mainservice.getBandSize().then((data: any) => {
      if (data && data.isSuccess) {
        this.bandSize = data.data;
      }
    });
  }

  remove(instrument: string): void {
    const index = this.searchInstuments.indexOf(instrument);
    if (index >= 0) {
      this.searchInstuments.splice(index, 1);
    }
  }

  removegen(gen: string): void {
    const index = this.searchgen.indexOf(gen);
    if (index >= 0) {
      this.searchgen.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our country
    if ((value || '').trim()) {
      this.searchInstuments.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.deviceCtrl.setValue(null);
  }

  addgen(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our country
    if ((value || '').trim()) {
      this.searchgen.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.genCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.advanceSearchForm.controls['instruments'].setValue(
      event.option.viewValue
    );
    this.searchInstuments.push(event.option.viewValue);
    this.instInput.nativeElement.value = '';
    this.instumentCtrl.setValue(null);
  }

  selectedgen(event: MatAutocompleteSelectedEvent): void {
    this.searchgen.push(event.option.viewValue);
    this.genInput.nativeElement.value = '';
    this.genCtrl.setValue(null);
  }



  searchBoxController() {
    // this.router.navigate(['/ArtistDashboard/artistHome'], {queryParams: { home: true },});
    this.searchBox = !this.searchBox;
  }
  sideBarController() {
    this.router.navigate(['/ArtistDashboard/artistHome'], {
      queryParams: { home: true },
    });
  }

  toggleNotification() {
    this.isNotificationActive = !this.isNotificationActive;
    if (this.isNotificationActive) {
      this.getNotifications();
    }
  }

  onClickedOutside(event?: any) {
    this.profileClick++;
    if (this.isProfileOpened && this.profileClick > 1) {
      this.isProfileOpened = false;
      this.profileClick = 0;
    }
  }

  onClickNotification(event: any) {
    this.notificationClick++;
    if (this.isNotificationActive && this.notificationClick > 0) {
      this.isNotificationActive = false;
      this.notificationClick = 0;
    }
  }

  getNotifications() {
    this.notificationData = true;
    this.notificationContainer = [];
    this.mainservice.getNotification().then((data) => {
      if (data && data.isSuccess) {
        this.notificationData = false;
        this.notification = data.data.contents;
        this.unreadNotification = data.data.totalUnreadCount;
        for (let key in data.data.contents) {
          const modifydata = {
            key: key,
            item: data.data.contents[key],
          };
          this.notificationContainer.push(modifydata);
        }
      } else {
        this.notificationData = false;
        this.notifier.showError(data.message);
      }
    });
  }

  markAllRead() {
    let body = {};
    this.mainservice.markAllRead(body).then((data) => {
      if (data && data.isSuccess) {
        this.getNotifications();
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  removeAllNotification() {
    this.mainservice.removeAllNotification().then((data) => {
      if (data && data.isSuccess) {
        this.getNotifications();
        this.notifier.showSuccess(data.message);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  markReady(id: any, read: any) {
    if (read === false) {
      this.mainservice.markRead(id).then((data) => {
        if (data && data.isSuccess) {
          this.notifier.showSuccess(data.message);
          this.getNotifications();
        } else {
          this.notifier.showError(data.message);
        }
      });
    }
  }

  navigateOn(type: any, pId: any, eId: any) {
    if (type === "PROPOSAL_ACCEPTED" || type === "PROPOSAL_DECLINED" || type === "CONTRACT_ACCEPTED" || type === "PROPOSAL_SUBMITTED" || type === "PENDING_PAYMENT"
      || type === "CONTRACT_CANCELLED" || type === "EVENT_CANCELLED" || type === "CONTRACT_DECLINED") {
      this.tabName = 'event';
      this.router.navigate(['/ArtistDashboard/artistEventDetail'], { queryParams: { proposalId: pId, eventId: eId, eventType: type } })
    } else if (type === "HIRING_PENDING" || type === "LIVE_EVENT") {
      this.tabName = 'event';
      this.router.navigate(['/ArtistDashboard/artistEventDetail'], { queryParams: { eventId: eId, goLive: true} })
    } else if (type === "ARTIST_INVITATION") {
      this.tabName = 'gigBoard';
      this.router.navigate([`/ArtistDashboard/gigBoard`], { queryParams: { eventId: eId, eventType: type } })
    } else if (type === "ACCOUNT_BAN") {
      this.router.navigate([`/ArtistDashboard/artistHome`])
    } else if (type === "SHARED_LIVE_EVENTS") {
      this.router.navigate(['/ArtistDashboard/watchLive'], { queryParams: { id: eId, type: 'invite' } })
    }
  }

  removeNotification(id: any) {
    this.mainservice.removeNotification(id).then((data) => {
      if (data && data.isSuccess) {
        this.getNotifications();
        this.notifier.showSuccess(data.message);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  footer() {
    this.isNotificationActive = false;
  }
}
