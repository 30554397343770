export class PeerStats {
  streamId: any;
  totalBytesReceivedCount = 0;
  videoPacketsLost = 0;
  fractionLost = 0;
  startTime = 0;
  lastFramesEncoded = 0;
  totalFramesEncodedCount = 0;
  lastBytesReceived = 0;
  lastBytesSent = 0;
  currentTimestamp = 0;
  lastTime = 0;
  timerId = 0;
  firstByteSentCount = 0;
  firstBytesReceivedCount = 0;
  audioLevel = -1;
  qualityLimitationReason = "";

  resWidth = 0;
  resHeight = 0;
  srcFps = 0;

  frameWidth = 0;
  frameHeight = 0;

  videoRoundTripTime = 0;
  videoJitter = 0;

  audioRoundTripTime = 0;
  audioJitter = 0;

  audioPacketsLost = 0;

  framesReceived = 0;
  framesDropped = 0;
  framesDecoded = 0;

  audioJitterAverageDelay = 0;
  videoJitterAverageDelay = 0;
  totalBytesSentCount: any;

  constructor(streamId: any) {
    this.streamId = streamId;

  }

  //kbits/sec
  get averageOutgoingBitrate() {
    return Math.floor(8 * (this.totalBytesSentCount - this.firstByteSentCount) / (this.currentTimestamp - this.startTime));
  }

  //frames per second
  get currentFPS() {
    return (((this.totalFramesEncodedCount - this.lastFramesEncoded) / (this.currentTimestamp - this.lastTime)) * 1000).toFixed(1);
  }
  //kbits/sec
  get averageIncomingBitrate() {
    return Math.floor(8 * (this.totalBytesReceivedCount - this.firstBytesReceivedCount) / (this.currentTimestamp - this.startTime));
  }

  //kbits/sec
  get currentOutgoingBitrate() {
    return Math.floor(8 * (this.totalBytesSentCount - this.lastBytesSent) / (this.currentTimestamp - this.lastTime));
  }

  //kbits/sec
  get currentIncomingBitrate() {
    return Math.floor(8 * (this.totalBytesReceivedCount - this.lastBytesReceived) / (this.currentTimestamp - this.lastTime));
  }

  set currentTime(timestamp: any) {
    this.lastTime = this.currentTimestamp;
    this.currentTimestamp = timestamp;
    if (this.startTime == 0) {
      this.startTime = timestamp - 1; // do not have zero division error
    }
  }

  set totalBytesReceived(bytesReceived: any) {
    this.lastBytesReceived = this.totalBytesReceivedCount;
    this.totalBytesReceivedCount = bytesReceived;
    if (this.firstBytesReceivedCount == 0) {
      this.firstBytesReceivedCount = bytesReceived;
    }
  }

  set totalBytesSent(bytesSent: any) {
    this.lastBytesSent = this.totalBytesSentCount;
    this.totalBytesSentCount = bytesSent;
    if (this.firstByteSentCount == 0) {
      this.firstByteSentCount = bytesSent;
    }
  }
  set totalFramesEncoded(framesEncoded: any) {
    this.lastFramesEncoded = this.totalFramesEncodedCount;
    this.totalFramesEncodedCount = framesEncoded;
    if (this.lastFramesEncoded == 0) {
      this.lastFramesEncoded = framesEncoded;
    }
  }

}