import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { VerifyComponent } from './verify/verify.component';


@Component({
  selector: 'app-send-code',
  templateUrl: './send-code.component.html',
  styleUrls: ['./send-code.component.scss']
})
export class SendCodeComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<SendCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  // Save(){
  //   this.dialogRef.close("IT was save");
  // }
  openDialog(){
    this.dialog.open(VerifyComponent,{
      "width":"400px",
      "height":"400px",
    });
  }
}
