<div class="joinpage">
  <div class="joinhead">
    <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg">
  </div>
  <div class="joinbody container">
    <div class="row joinb">
      <div class="col-md-6 col-sm-12 col-xl-6">
        <div class="joinleft">
          <app-slider></app-slider>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 col-xl-6">
        <div class="joinright">
          <div class="wl-cm">
            <h3>Welcome back</h3>
            <h4>Log into your account</h4>
          </div>
          <div>
            <form class="frm" style="color: white; font-size: 14px;margin-top: -15px;" [formGroup]="userForm">
              <div>
                <label class="f1 required"><b>Email</b></label>
                <input class="f2" type="text" formControlName="email" style="padding: 0px 10px;">
                <div *ngIf="userForm.controls['email'].touched && !userForm.controls['email'].valid">
                  <span *ngIf="userForm.controls['email'].hasError('required')">
                    Email is required
                  </span>
                  <span *ngIf="userForm.controls['email'].hasError('pattern') ">
                    Please Enter Valid Email Address
                  </span>
                </div>
              </div>
              <div class="ey-y">
                <label class="f1 required"><b>Password</b></label>
                <input matInput type="password" formControlName="password" class="f2"
                  [type]="hide ? 'password' : 'text'" style="padding: 0px 5px;" (keyup.enter)="userSignin()">
                <mat-icon matSuffix (click)="hide = !hide">{{
                  hide ? "visibility_off" : "visibility"
                  }}</mat-icon>
                <div *ngIf="userForm.controls['password'].touched && !userForm.controls['password'].valid">
                  <span *ngIf="userForm.controls['password'].hasError('required')">
                    Password is required
                  </span>
                </div>
              </div>
              <div class="for-gt">
                <label>
                  <input class="f3" type="checkbox" name="remember" style="accent-color:#12AD2B;">
                  Remember me
                </label>
                <span class="psw" style="color: #ff0000a8; cursor: pointer;" [routerLink]="['/forgot-password']" [queryParams]="{ 'type': 'regenerateEmail'}"><a
                  style="float: right; margin-top: 10px;">Regenerate Confirmation Link</a></span>
              </div>
              <div class="for-gt">
                <label for="staylogin">
                  <input class="f3" (click)="stayLoggedIn=!stayLoggedIn" type="checkbox" id="staylogin"
                    style="accent-color:#12AD2B"> Stay logged
                  in on this device
                </label>
                <span class="psw" style="color: #ff0000a8; cursor: pointer; " routerLink="/forgot-password"><a
                  style="float: right; margin-top: 10px;">Forgot Password?</a></span>
              </div>
              <button type="submit" [disabled]="signInBtn" class="button" (click)="userSignin()">
                <ng-container *ngIf="loginFormSubmitting; else signInFormSubmittingDone">
                  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Processing...
                </ng-container>
                <ng-template #signInFormSubmittingDone>
                  Login
                </ng-template>
              </button>
            </form>
            <h2 class="uhy">Don't have an account?<span style="color: #FF6720; cursor: pointer;"
                routerLink=""><a>&nbsp;Join today</a></span></h2>
          </div>
        </div>

      </div>
    </div>
  </div>
  <app-footer></app-footer>
  <!-- <div class="joinfoot">
    <div class="row joinf">
      <a href="https://virtuosica.com/privacy-policy/" target="_blank" class="pointer"> Privacy Policy</a>
      <a href="https://virtuosica.com/terms-of-service/" target="_blank" class="pointer"> Terms Of Service</a>
      <a href="https://help.virtuosica.com/help-center" target="_blank" class="pointer">Need Help?</a>
    </div>
  </div> -->
</div>

<div *ngIf="banModal" class="modal" style="display: block;" id="inviteCancelModal" tabindex="-1" role="dialog"
  aria-labelledby="inviteCancelModalLabel" aria-hidden="true">
  <div class="modal-dialog vehz" role="document">
    <div class="modal-content py-5" style="background: black;">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="banModal = false" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body my-3">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <h6>This Account has been banned. If you want to continue with this Account then connect with Virtuosica's
                Customer Service</h6>
              <a href="https://help.virtuosica.com/help-center"
                style="text-decoration: underline; color: white; cursor: pointer;">https://help.virtuosica.com/help-center</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
