<main *ngIf="userStatus != 'SUSPENDED'" style="overflow:hidden">
  <div class="container-fluid pl-0 pt-3">
    <div class="hgnb bot-m">
      <div class=" nvhi">
        <span>Home</span>
      </div>
    </div>
    <!-- New artist section -->
    <div class="mb-4 ughne">
      <div class="fyhq">
        <p class="huikdjy">New Artists</p>
        <p class="huikdjy ghu" [routerLink]="['/audienceDashboard/seeAllArtist']">See All</p>
      </div>
      <div class="for-slid ghb-wr">
        <ng-container *ngIf="newArtistData">
          <div style="text-align: center;">
            <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
              secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
          </div>
        </ng-container>
        <ng-container *ngIf="newArtist && newArtist.length">
          <swiper [slidesPerView]="5" [spaceBetween]="30" [navigation]="true" class="mySwiper">
            <ng-template swiperSlide *ngFor="let images of newArtist">
              <div class="sliderImage">
                <img [src]="images.avartarUrl ? images.avartarUrl: 'assets/artist/unsplash_4korPWFLBk0.png'" alt="">
                <div class="fhg-et">
                  <div class="yhguu">
                    <button type="button" class="btn hire-nw sm-pl"
                    (click)="hireNow = true ; selectedArtistId = images.artistProfileId;getExistingEventList()"
                    >Hire Now!</button>
                    <div style="float: right;">
                      <i class="fa fa-heart-o" aria-hidden="true"
                        *ngIf="images.favourite === false || images.favourite === null"
                        (click)="favoriteArtist(images.artistProfileId)"></i>
                      <i class="fa fa-gratipay rft" *ngIf="images.favourite === true"
                        style="color: #F93822;font-size: 15px;" data-target="#artist-home"
                        (click)="favoriteArtist(images.artistProfileId)"></i>
                      <i class="fa fa-angle-down rft" data-toggle="modal" data-target="#artist-home"
                        (click)="getArtistDetailsById(images.artistProfileId)"></i>
                    </div>
                  </div>
                  <h4>{{images.displayName}}</h4>
                  <p *ngFor="let geners of images.genres | slice:0:2; let i=index ">
                    <span>{{geners}} </span>
                  </p>
                  <div class="deft rating">
                    <!-- <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating> -->
                  </div>
                </div>
              </div>
            </ng-template>
          </swiper>
        </ng-container>
        <ng-container *ngIf="!newArtist?.length && !newArtistData">
          <div class="center-error">
            <div class="error-wrapper">
              <h1 class="text-center" style="color: white;">Oops</h1>
              <p class="text-center" style="color: white;">No New Artist Found</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- Staff Picks section -->
    <div class="mb-4 ughne">
      <div class="fyhq  ">
        <p class="huikdjy">Staff Picks</p>
        <p class="huikdjy ghu" [routerLink]="['/audienceDashboard/seeAllStaff']">See All</p>
      </div>
      <div class="for-slid fegt">
        <ng-container *ngIf="staffPickData">
          <div style="text-align: center;">
            <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
              secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
          </div>
        </ng-container>
        <ng-container *ngIf="staffPicks && staffPicks.length">
          <swiper [slidesPerView]="5" [spaceBetween]="30" [navigation]="true" class="mySwiper">
            <ng-template swiperSlide *ngFor="let images of staffPicks">
              <div class="sliderImage">
                <img [src]="images.avartarUrl ? images.avartarUrl: 'assets/artist/unsplash_4korPWFLBk0.png'" alt="">
                <div class="fhg-et">
                  <div class="yhguu">
                    <button type="button" class="btn hire-nw sm-pl"
                    (click)="hireNow = true ; selectedArtistId = images.artistProfileId; getExistingEventList()">Hire Now!</button>
                    <div style="float: right;">
                      <i class="fa fa-heart-o" aria-hidden="true"
                        *ngIf="images.favourite === false || images.favourite === null"
                        (click)="favoriteArtist(images.artistProfileId)"></i>
                      <i class="fa fa-gratipay rft" *ngIf="images.favourite === true"
                        style="color: #F93822;font-size: 15px;" data-target="#artist-home"
                        (click)="favoriteArtist(images.artistProfileId)"></i>
                      <i class="fa fa-angle-down rft" data-toggle="modal" data-target="#artist-home"
                        (click)="getArtistDetailsById(images.artistProfileId)"></i>
                    </div>
                  </div>
                  <h4>{{images.displayName}}</h4>
                  <p *ngFor="let geners of images.genres | slice:0:2; let i=index ">
                    <span>{{geners}} </span>
                  </p>
                  <div class="deft rating">
                    <!-- <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating> -->
                  </div>
                </div>
              </div>
            </ng-template>
          </swiper>
        </ng-container>
        <ng-container *ngIf="!staffPicks?.length && !staffPickData">
          <div class="center-error">
            <div class="error-wrapper">
              <h1 class="text-center" style="color: white;">Oops</h1>
              <p class="text-center" style="color: white;">No Staff Picks Found</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</main>

<!-- Hire Now Modal -->
<div *ngIf="hireNow" class="drfwt" style="z-index: 100; position: relative; top: -650px;">
  <div class="modal-dialog modal-md meraww" role="document" style="overflow-y: initial !important">
    <div class="modal-content" style="background: #111; overflow: auto;">
      <div class="modal-header pb-0 pt-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
        (click)="hireNow = false; this.eventForm.controls.existingEvent.setValue(null);">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb-0" style="color: white;">
        <div class="container">
          <div class="row">
            <div class="col-12 fir-tab">
              <p>Do you want to hire this Artist for:</p>
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item pr-5">
                  <input type=radio id="rdo1" checked class="radio-input" name="radio-group">
                  <label for="rdo1" class="radio-label" style="padding-left: 30px;" (click)="tabName = 'exitingEvent'">
                    <span class="radio-border"></span>
                    For Exiting Event </label>
                </li>
                <li class="nav-item pl-5">
                  <input type=radio id="rdo2" class="radio-input" name="radio-group">
                  <label for="rdo2" class="radio-label" style="padding-left: 30px;" (click)="tabName = 'newEvent'"><span
                      class="radio-border"></span>
                    Create New Event </label>
                </li>
              </ul>
              <div class="tab-content my-4">
                <div *ngIf="tabName == 'exitingEvent'">
                  <form [formGroup]="eventForm">
                    <div class="form-group exitEvent">
                      <label for="eventtype">Select Event</label>
                      <div>
                        <mat-form-field class="multiSelect">
                          <mat-select class="pl-3" formControlName="existingEvent" #select2>
                            <mat-option style="z-index: 10000000;" (click)="optionClick2()"
                              *ngFor="let event of existingEventList" [value]="event">
                              {{event.eventName}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </form>
                  <div class="my-3 text-center">
                    <button [disabled]="userStatus == 'PENDING_DELETE' || this.eventForm.invalid" (click)="hireArtist()" type="submit"
                      class="btn defrqqa edcae"> Confirm </button>
                  </div>
                </div>
                <div *ngIf="tabName == 'newEvent'" class="text-center">
                  <button [disabled]="userStatus == 'PENDING_DELETE'" type="submit" class="btn defrqqa edcae"
                    (click)="createNewEvent()"> Confirm </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Individual Artist Modal -->
<div class="modal fade drfwt mt-5 pt-3" id="artist-home" tabindex="-1" role="dialog" aria-labelledby="artist-homeTitle"
  aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-lg" role="document" style="overflow-y: initial !important">
    <div class="modal-content bg-dgf" *ngIf="artistData">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDropdown = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="max-height: calc(100vh - 200px); overflow-y: auto;">
        <div class="jmki">
          <div class="mr-5">
            <div style="background: #11111100; margin-right: 20px; background-size: 100% 100%; height: 125px;width: 125px;">
              <img src="assets/artist/ckr.svg" class="edaqqer" data-toggle="modal" data-target=".statuss" style="background: white;
              position: absolute; top: 110px; left: 130px;">
            <img [src]="artistData?.profilePicUrl ? artistData?.profilePicUrl : 'assets/artist/Rectangle 30 (1).png'"
              onerror="this.src='assets/artist/Rectangle 30 (1).png'" alt="" width="100%" height="100%"
              style="border: 1px solid red; padding: 4px; border-radius: 50%; object-fit: cover;">
            </div>
            </div>
          <div class="dchy">
            <div>
              <h2>{{artistData?.displayName}}</h2>
              <i class="fa fa-ellipsis-v fkui pl-3" (click)="showDropdown = !showDropdown"></i>
              <i class="fa fa-heart-o fkui" aria-hidden="true" aria-hidden="true" *ngIf="!showStarIcon"
                (click)="favoriteArtist(artistData?.id)"></i>
              <i class="fa fa-gratipay fkui" *ngIf="showStarIcon" style="color: #F93822;font-size: 26px;"
                (click)="favoriteArtist(artistData?.id)"></i>
            </div>
            <div *ngIf="showDropdown" class="fkui"
              style="border: 1px solid white; border-radius: 15px; width: 25%; position: relative; top: -25px;">
              <p class="mb-0 py-1 pl-3" style="border-bottom: 1px solid white; cursor: pointer;" (click)="report()">
                Report</p>
              <p class="mb-0 py-1 pl-3" (click)="shareProfile(artistData?.id)" style="cursor: pointer;">Share Profile</p>
            </div>
            <span class="ujy" *ngFor="let country of artistData?.locations">
              {{country}}
            </span>
            <h4>${{artistData?.feeRangeFrom}} - ${{artistData?.feeRangeTo}} per hour</h4>
            <p class="ure">{{artistData?.eventCount}} events<span *ngFor="let band of artistData?.bandSizes"> | {{band}}
              </span></p>
            <div class="deft mb-3 rating">
              <!-- <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating> -->
            </div>
            <button type="button" class="btn hire-nw" data-dismiss="modal" (click)="hireNow = true; selectedArtistId = artistData.id; getExistingEventList()">Hire Now!</button>
            <button type="button" class="btn sn-mg" (click)="sendMessage(artistData?.userId,artistData?.displayName)">Send A Message</button>
          </div>
        </div>
        <div class="jguht">
          <div class="mb-4 hgaa">
            <h4>My Career in Summary</h4>
            <p>{{artistData?.careerSummary}}</p>
          </div>
          <div class="mb-4 hgaa">
            <h4>My First Musical Memory</h4>
            <p>{{artistData?.musicalMemory}}</p>
          </div>
          <div class="mb-4 hgaa">
            <h4>What I Love About Music</h4>
            <p>{{artistData?.loveAboutMusic}}</p>
          </div>
          <div class="row ">

            <div class="col-lg-6">
              <div class="mb-4 hgaa">
                <h4>Years of Experience</h4>
                <p>{{artistData?.experience}}</p>
              </div>
              <div class="mb-4 hgaa">
                <h4>Act / Band Size</h4>
                <ng-container *ngFor="let band of artistData?.bandSizes">
                  <p style="display: inline-block !important;">{{band}}</p>
                </ng-container>
              </div>
              <div class="mb-4 hgaa">
                <h4>Onsite</h4>
                <ng-container *ngFor="let onsite of artistData?.onsites">
                  <p style="display: inline-block !important;">{{onsite}}</p>
                </ng-container>
              </div>
              <div class="mb-4 hgaa">
                <h4>Video Setup</h4>
                <ng-container *ngFor="let video of artistData?.videoSetups">
                  <p style="display: inline-block !important;">{{video}}</p>
                </ng-container>
              </div>
              <div class="mb-4 hgaa">
                <h4>Video Quality</h4>
                <ng-container *ngFor="let quality of artistData?.videoQualities">
                  <p style="display: inline-block !important;">{{quality}}</p>
                </ng-container>
              </div>
              <div class="mb-4 hgaa">
                <h4>Audio Setup</h4>
                <ng-container *ngFor="let setup of artistData?.audioSetups">
                  <p style="display: inline-block !important;">{{setup}}</p>
                </ng-container>

              </div>
              <div class="mb-4 hgaa">
                <h4>Performance Environment</h4>
                <div *ngFor="let env of artistData?.performanceEnvironments">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="mb-4 hgaa">
                <h4>Favorite Genres</h4>
                <div *ngFor="let env of artistData?.favouriteGenres">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
              <div class="mb-4 hgaa">
                <h4>Instruments Played</h4>
                <div *ngFor="let env of artistData?.instrumentsPlayed">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
              <div class="mb-4 hgaa">
                <h4>Most Influential Artists</h4>
                <p>{{artistData?.influentialArtists}}</p>
              </div>
              <div class="mb-4 hgaa">
                <h4>Education</h4>
                <div *ngFor="let env of artistData?.educations">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
              <div class="mb-4 hgaa">
                <h4>Public Videos Links</h4>
                <div *ngFor="let env of artistData?.publicVideosLinks">
                  <a [href]="env" target="_blank"> <p style="display: inline-block !important;">{{env}}</p></a>
                </div>

              </div>
              <div class="mb-4 hgaa">
                <h4>Social Videos Links</h4>
                <div *ngFor="let env of artistData?.socialVideosLinks">
                  <a [href]="env" target="_blank"><p style="display: inline-block !important;">{{env}}</p></a>
                </div>

              </div>
            </div>
          </div>
          <ng-container *ngFor="let certificate of artistData?.certificateDetails">
            <div class="row sawqde">
              <div class="col-lg-4">
                <div class="mb-4 hgaa">
                  <h4>Certifications</h4>
                  <h4>{{certificate.certificateName}}</h4>
                  <p>{{certificate.issuingOrganization}}</p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-4 hgaa">
                  <p>{{certificate.issuingOrganization}}</p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-4 hgaa gftj">
                  <p (click)="certicatedPreview(certificate.id)" data-toggle="modal" data-target="#examplesModal"
                    style="cursor: pointer;"><img src="assets/dashboard/swd.png" class=""> View Certificate</p>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="mb-5 fercc">
            <h4 class="imnsde"> Images </h4>
            <div class="row">
              <div class="col-md-12">
                <ng-container *ngFor="let image of artistData?.images">
                  <img [src]="image" alt="no img" style="height: 100px;width: 30%;object-fit: cover;padding: 0px 30px;">
                </ng-container>
              </div>
            </div>
          </div>
          <div class="mb-5 fercc">
            <h4 class="imnsde">Videos</h4>
            <div class="row">
              <div class="col-md-12">
                <ng-container *ngFor="let video of artistData?.videoUrls">
                  <video [src]="video" alt="no img" style="height: 100%;width: 25%;object-fit: cover;padding: 10px;"
                    controls> </video>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade statuss mt-5" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-lg">
    <div class="modal-content edvbgy">

      <div class="modal-header hgbaw">
        <h5 class="modal-title edvt" id="exampleModalLabel"><img src="assets/s1.jpg">
          <span>{{artistData?.displayName}}</span></h5>
        <button (click)="vPause()" type="button" class="dtdre" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><img src="assets/Vector (1).svg"></span>
        </button>
      </div>

      <div class="modal-body rdfeqqw">
        <video id="myVideo" [src]="artistData?.videoUrls[0]" style="width: 100%;" controls></video>
      </div>


    </div>
  </div>
</div>

<div class="modal fade" id="examplesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="false" style="z-index: 2147483647; background: #0a0a0a80;">

  <div class="modal-dialog d-flex" role="document">
    <div class="modal-content" style="width: 100%; position: absolute;max-height: 465px;">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Certificate View</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <img [src]="previewCertificate" style="width: 100%;height: 60%;">
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Code for suspended Account -->
<div *ngIf="userStatus == 'SUSPENDED'" class="container mt-5 pt-5">
  <div class="row" style="color: white; font-size: 25px;">
    <div class="col-8 offset-2 test-center">
      This Account has been suspended. If you want to reactivate this Account contact on the below link.
    </div>
    <div class="col-8 offset-2 test-center">
      <a href="https://help.virtuosica.com/help-center"
        style="text-decoration: underline; cursor: pointer;">https://help.virtuosica.com/help-center</a>
    </div>
  </div>
</div>
