import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { FormControl, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
const moment = _rollupMoment || _moment;
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MustMatch } from 'src/app/module/login/reset-password/password validation/confirm-validation';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/service/helper/session.service';
import { DOCUMENT } from '@angular/common';
import { LoginModal } from 'src/app/service/models/auth.modals';
import { UserService } from 'src/app/service/user.service';
import { AuthServiceService } from 'src/app/service/auth-service.service';
import { environment } from 'src/environments/environment';
import { ImageCroppedEvent, ImageTransform, Dimensions, base64ToFile } from 'ngx-image-cropper';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
declare var hbspt: any;
@Component({
  selector: 'app-artist-edit',
  templateUrl: './artist-edit.component.html',
  styleUrls: ['./artist-edit.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ArtistEditComponent implements OnInit {
  [x: string]: any;
  @ViewChild('placesRef') placesRef!: GooglePlaceDirective;
  proReportingForm!: FormGroup;
  certificateForm!: FormGroup;
  visible = true;
  selectable = true;
  selectablegen = true;
  removable = true;
  selectableTag = true;
  removableTag = true;
  removablegen = true;
  removableNotable = true;
  addOnBlur: boolean = true;
  separatorKeysCodess: number[] = [ENTER, COMMA];
  separatorKeysCodesloc: number[] = [ENTER, COMMA];
  separatorKeysCodesins: number[] = [COMMA];
  separatorKeysCodesgen: number[] = [COMMA];
  separatorKeysCodesveg: number[] = [ENTER, COMMA];
  separatorKeysCodesTag: number[] = [ENTER, COMMA];
  countriesCtrl = new FormControl();
  showCertificateModal: boolean;
  instuments: any = [];
  allCountries: string[] = [];
  brandList: any;
  instrumentList: any;
  genList: any;
  instumentCtrl = new FormControl();
  genCtrl = new FormControl();
  vegCtrl = new FormControl();
  searchInstuments: string[] = [];
  searchgen: string[] = [];
  searchveg: string[] = [];
  searchTimeZone: string[] = [];
  imageArray: any[] = [];
  @ViewChild('devicesInput')
  deviceInput!: ElementRef<HTMLInputElement>;
  @ViewChild('genInput')
  genInput!: ElementRef<HTMLInputElement>;
  deviceCtrl = new FormControl();
  imageUploaded: boolean;
  imageUploaded2: boolean;
  year: any;
  videoUploadForm!: FormGroup;
  proListIds: any[] = [];
  imageSrc: any;
  imageUrl: any;
  videoDetails: any;
  selectAllproList: boolean = false;
  @ViewChild('countryInput')
  countryInput!: ElementRef<HTMLInputElement>;
  @ViewChild('auto')
  matAutocomplete!: MatAutocomplete;
  @ViewChild('autoloc')
  matAutocompleteloc!: MatAutocomplete;
  @ViewChild('autogen')
  matAutocompletegen!: MatAutocomplete;
  showUploaModalvid: boolean;
  showImagevid: boolean;
  videoSubmitting: boolean;
  showImage: boolean;
  fileName: any;
  showIcon: boolean;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  allFruits: string[] = [];
  newToken: any;
  interval: any;
  timeZoneArray: any;
  toppings = new FormControl();
  imageSrcvid: any;
  toppingList: string[] = ['Self-Taught', 'A', 'B', 'C', 'D', 'E'];
  @ViewChild('fruitInput') fruitInput!: ElementRef<HTMLInputElement>;
  generalDetails: any;
  CertificatedDetails: any;
  essentailsDetails: any;
  backgroundDetails: any;
  productionDetails: any;
  mediaDetails: any;
  videoDetail: any;
  educationList: any;
  bandSize: any;
  onSiteList: any;
  videosetList: any;
  videoQualityList: any;
  audioSetupList: any;
  PerformanceEnvironmentList: any;
  editGeneralForm!: FormGroup;
  editEssentailForm!: FormGroup;
  editProductionForm!: FormGroup;
  editCertifateForm!: FormGroup;
  generalTabButton: boolean;
  essentialsButton: boolean;
  backgroundButton: boolean;
  productionButton: boolean;
  preSlectedValues: any;
  educationValue: any;
  getRole: any;
  getOnSite: any;
  onSiteValues: any;
  onsites: any;
  videosetup: any;
  onvideoSetupValues: any;
  getOnvideosetup: any;
  videoquality: any;
  onvideoQualityValues: any;
  audioSetup: any;
  onaudioSetupValues: any;
  performance: any;
  perfomanceValues: any;
  editBackgroundForm!: FormGroup;
  mediaForm!: FormGroup;
  seetingForm!: FormGroup;
  websiteLink: any;
  socialVideo: any;
  publicVideo: any;
  proListData: any;
  mediaButton: boolean;
  showImageModal: boolean;
  previewImage: any;
  videoImageModal: boolean;
  previewVideo: any;
  profilePicData: any;
  videoArray: any[] = [];
  fileNamevid: any;
  videoUploaded: boolean;
  videoUrl: any;
  getOnvideoQuality: any;
  getOnAudioSetup: any;
  getEnviroment: any;
  keywords: string[] = [];
  showUploaModal: boolean;
  bandSizeValue: any;
  bandSlectedValues: any;
  fileInfo: any;
  getBand: any;
  id: any;
  showUploaImageModal: boolean;
  editCerticateForm!: FormGroup;
  addCertificatedForm!: FormGroup;
  deleteForm!: FormGroup;
  previewCertificate: any;
  generalFormSubmitting!: boolean;
  essentailFormSubmitting!: boolean;
  previewYear: any;
  YearObtainedFrom: any;
  preSelectedValues: any;
  socialMedia: boolean;
  socialVideosaddmore: boolean;
  yourWebsite: boolean;
  countryName: any;
  location: string[] = [];
  locationCtrl = new FormControl();
  locationlength: any;
  timeZoneCtrl = new FormControl();
  @ViewChild('timeZoneInput')
  timeZoneInput!: ElementRef<HTMLInputElement>;
  addMusicTag: boolean = false;
  hubspotEmail: any;
  tabName = 'ASCAP';
  videoId: any;
  link = 'https://www.ascap.com/';
  proId: any[] = [];
  oldPassWordHide = true;
  newPassWordHide = true;
  confirmPasswordHide = true;
  sectionName = 'general';
  checkBox1: boolean = false;
  checkBox2: boolean = false;
  checkBox3: boolean = false;
  deleteShow: boolean = false;
  checkBox9: boolean = false;
  deleteNextStep: boolean = false;
  deleteNotProcess: boolean = false;
  requestType!: string;
  type: any;
  status: any;
  showDeleteButton: boolean = false;
  loadAPI: Promise<any>;
  login: LoginModal;
  browser: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  showDragDrop: boolean = false;
  fileData: any;
  imageSpinnerDone: boolean = false;
  publicVideoForm!: FormGroup;
  socialVideoForm!: FormGroup;
  WebsiteVideoForm!: FormGroup;


  constructor(
    private _mainService: MainService,
    private notifier: NotifierService,
    private fb: FormBuilder,
    private activatederoute: ActivatedRoute,
    private _SessionService: SessionService,
    // private scriptService: ScriptService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private _UserService: UserService,
    private _auth: AuthServiceService,
    private router: Router
  ) {
    this.login = new LoginModal();
    this.generalTabButton = true;
    this.socialMedia = false;
    this.essentialsButton = false;
    this.backgroundButton = false;
    this.yourWebsite = false;
    this.socialVideosaddmore = false;
    this.productionButton = false;
    this.showImageModal = false;
    this.videoImageModal = false;
    this.imageUploaded = false;
    this.imageUploaded2 = false;
    this.showUploaModalvid = false;
    this.showImagevid = false;
    this.videoSubmitting = false;
    this.videoUploaded = false;
    this.showUploaModal = false;
    this.showImage = false;
    this.mediaButton = false;
    this.showIcon = true;
    this.cerificateSubmitting = false;
    this.showUploaImageModal = false;
    this.showCertificateModal = false;



    this.loadAPI = new Promise((resolve) => {

      resolve(true);
    });
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.allFruits.slice()
      )
    );
    this.instumentCtrl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value: string) => {
        if (value) {
          this._mainService.searchInstuments(value).then((res: any) => {
            if (res && res.isSuccess) {
              this.instrumentList = res.data;
            }
          });
        }
      });
    this.genCtrl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value: string) => {
        if (value) {
          this._mainService.searchGenres(value).then((res: any) => {
            if (res && res.isSuccess) {
              this.genList = res.data;
            }
          });
        }
      });
    this.timeZoneCtrl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value: string) => {
        if (value) {
          this._mainService.getTimeZone('', value).then((res: any) => {
            if (res && res.isSuccess) {
              this.timeZoneArray = res.data;
            }
          });
        }
      });
    this.mediaForm = this.fb.group({
      publicVideos: [
        '',
        [
          Validators.pattern(
            /^(?:(?:(?:https?|ftp):)?\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=. ]+$/
          ),
        ],
      ],
      socialVideos: [
        '',
        [
          Validators.pattern(
            /^(?:(?:(?:https?|ftp):)?\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=. ]+$/
          ),
        ],
      ],
      website: [
        '',
        [
          Validators.pattern(
            /^(?:(?:(?:https?|ftp):)?\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=. ]+$/
          ),
        ],
      ],
    });
    this.videoUploadForm = this.fb.group({
      videoTitle: ['', []],
      videoTags: ['', []],
    });
    this.proReportingForm = this.fb.group({
      title: ['', Validators.required],
      link: ['', Validators.required],
    });
    this.seetingForm = this.fb.group(
      {
        oldPassword: ['', Validators.required],
        newPassword: ['', Validators.required],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: MustMatch('newPassword', 'confirmPassword'),
      }
    );
    this.deleteForm = this.fb.group({
      reason: ['', Validators.required],
    });
    this.publicVideoForm = this.fb.group({
      websiteLink: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(?:(?:(?:https?|ftp):)?\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=. ]+$/),
        ],
      ]
    })
    this.socialVideoForm = this.fb.group({
      websiteLink: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(?:(?:(?:https?|ftp):)?\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=. ]+$/),
        ],
      ]
    })
    this.WebsiteVideoForm = this.fb.group({
      websiteLink: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(?:(?:(?:https?|ftp):)?\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=. ]+$/),
        ],
      ]
    })
  }

  onFileSelect2(input: HTMLInputElement): void {
    /**
     * Format the size to a human readable string
     *
     * @param bytes
     * @returns the formatted string e.g. `105 kB` or 25.6 MB
     */
    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;

      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }

      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }

    const file = input.files![0];
    this.fileInfo = `${file.name} (${formatBytes(file.size)})`;
  }
  ngOnInit(): void {
    this.type = this.activatederoute.snapshot.queryParams.type;
    this.status = this._SessionService.getSession('status');
    this.hubspotEmail = this._SessionService.getSession('email');
    this.browser = this.login.device;
    if (this.type) {
      this.requestType = this.type;
    } else {
      this.requestType = 'GENERAL';
    }
    if (this.status === 'PENDING_DELETE') {
      this.showDeleteButton = true;
    } else {
      this.showDeleteButton = false;
    }
    this.getGeneralDatails();
    this.getEssentailDetails();
    this.getBackgroundDetails();
    this.getProducionDetails();
    this.getMediaDetials();
    this.getEducationList();
    this.getBandSize();
    this.getOnSiteList();
    this.getVideosetList();
    this.getVideoQualityList();
    this.getAudioSetupList();
    this.getPerformanceEnvironmentList();
    this.getProfilepic();
    this.getTimeZone();
    this._mainService.getTimeZone('', 'A').then((res: any) => {
      if (res && res.isSuccess) {
        this.timeZoneArray = res.data;
      }
    });
    this.editGeneralForm = this.fb.group({
      displayName: new FormControl('', Validators.required),

      experience: new FormControl('', Validators.required),
      education: new FormControl(this.educationValue),
      timeZone: new FormControl('', Validators.required),
    });
    this.certificateForm = this.fb.group({
      certificateName: new FormControl('', Validators.required),
      certificateUrl: new FormControl('', Validators.required),
      issuingOrganization: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      // yearFrom : new FormControl(''),
      // yearTo : new FormControl(''),
    });

    this.addCertificatedForm = this.fb.group({
      certificateName: new FormControl('', Validators.required),
      certificateUrl: new FormControl('', Validators.required),
      issuingOrganization: new FormControl('', Validators.required),
      // yearFrom : new FormControl(),
      // yearTo : new FormControl(),
    });

    this.editProductionForm = this.fb.group({
      audioSetupDetails: new FormControl(this.onaudioSetupValues),
      onsiteDetails: new FormControl(this.getOnSite),
      performanceEnvironmentDetails: new FormControl(this.perfomanceValues),
      videoQualityDetails: new FormControl(this.onvideoQualityValues),
      videoSetupDetails: new FormControl(this.onvideoSetupValues),
    });

    this.editBackgroundForm = this.fb.group({
      albumCredits: new FormControl(''),
      careerSummary: new FormControl(''),
      firstMusicalMemory: new FormControl(''),
      influentialArtists: new FormControl(''),
      loveAboutMusic: new FormControl(''),
    });
    this.videoUploadForm = this.fb.group({
      videoTitle: new FormControl(''),
      videoTags: new FormControl(''),
    });
    this.editEssentailForm = this.fb.group({
      // instruments: new FormControl(this.searchInstuments),
      // genres: new FormControl(this.searchgen),
      bandSizes: new FormControl(this.bandSlectedValues),
      feeRangeFrom: new FormControl(''),
      feeRangeTo: new FormControl(''),
      notableMusicians: new FormControl(this.searchveg),
      timeZone: new FormControl(''),
    });
    this.editCerticateForm = this.fb.group({});
  }
  removeTimeZone(timeZone: string): void {
    const index = this.searchTimeZone.indexOf(timeZone);
    if (index >= 0) {
      this.searchTimeZone.splice(index, 1);
    }
    this.editGeneralForm.controls.timeZone.setValue(this.searchTimeZone);
  }

  selectedTimeZone(event: MatAutocompleteSelectedEvent): void {
    this.searchTimeZone[0] = event.option.viewValue;
    this.editGeneralForm.controls.timeZone.setValue(this.searchTimeZone);
    this.timeZoneInput.nativeElement.value = '';
    this.timeZoneCtrl.setValue(null);
  }

  ngAfterViewInit() {
    this.placesRef.options.componentRestrictions = { country: 'US' };
    // hbspt.forms.create({
    //   portalId: '9406878',
    //   formId: 'd3aefdd5-5583-4d76-b4e5-967e287b245f',
    //   target: '#hubspotForm',
    // });
  }

  handleAddressChange(address: Address) {
    this.location.push(address.formatted_address);
    this.locationCtrl.setValue(null);
    this.countryName = address.address_components[3].long_name;
    this.getTimeZone();
  }

  getTimeZone() {
    this._mainService.getTimeZone(this.countryName).then((data: any) => {
      if (data && data.isSuccess) {
        this.timeZoneArray = data.data;
      }
    });
  }

  remove(address: string): void {
    const index = this.location.indexOf(address);
    if (index >= 0) {
      this.location.splice(index, 1);
    }
  }

  yearFrom = new FormControl(moment());
  yearTo = new FormControl(moment());
  certificateUrl: any;
  cerificateSubmitting: boolean;

  YearFrom(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    const ctrlValue = this.yearFrom.value;
    ctrlValue.year(normalizedYear.year());
    this.yearFrom.setValue(ctrlValue);
  }

  YearTo(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    const ctrlValue = this.yearTo.value;
    ctrlValue.year(normalizedYear.year());
    this.yearTo.setValue(ctrlValue);
  }
  onFileSelectCertificate(event: any) {
    const file = event.target.files[0];

    if (
      file.type == 'image/png' ||
      file.type == 'image/jpg' ||
      file.type == 'image/svg' ||
      file.type == 'image/jpeg' ||
      file.type == 'pdf'
    ) {
      if (event.target.files && event.target.files[0]) {
        this.cerificateSubmitting = true;
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageSrc = <string>(<FileReader>event.target).result;
          this.fileName = file.name;
          this.showImage = true;
        };
        reader.readAsDataURL(event.target.files[0]);
        this.imageUploaded = true;
        const fd = new FormData();
        fd.append('multipartFile', file);
        this._mainService.fileUpload(fd).then((data: any) => {
          if (data && data.isSuccess) {
            this.imageUploaded = false;
            this.certificateUrl = data.data;
            this.cerificateSubmitting = false;
            this.notifier.showSuccess(data.message);
          } else {
            this.imageUploaded = false;
            this.notifier.showError(data.message);
          }
        });
      }
    } else {
      this.notifier.showError('Only Images Are Allowed');
    }
  }

  certificateDetailsUpload() {
    let yearObtainedFrom = this.yearFrom.value.year();
    let yearObtainedTo = this.yearTo.value.year();
    let certificateName =
      this.addCertificatedForm.controls['certificateName'].value;
    let issuingOrganization =
      this.addCertificatedForm.controls['issuingOrganization'].value;
    if (!certificateName) {
      this.notifier.showError('Please Enter Certificate Name');
    } else if (!issuingOrganization) {
      this.notifier.showError('Please Enter Issuing Organization');
    } else if (yearObtainedFrom >= yearObtainedTo) {
      this.notifier.showError('Please enter correct Year Range ');
    } else if (yearObtainedTo >= 2023) {
      this.notifier.showError('Only current year seleted ');
    } else if (!this.certificateUrl) {
      this.notifier.showError('Please Upload Certificate');
    } else if (
      certificateName &&
      issuingOrganization &&
      yearObtainedFrom < yearObtainedTo &&
      this.certificateUrl
    ) {
      const body = {
        certificateName: certificateName,
        certificateUrl: this.certificateUrl,
        issuingOrganization: issuingOrganization,
        yearObtainedFrom: this.yearFrom.value.year(),
        yearObtainedTo: this.yearTo.value.year(),
      };
      this._mainService.uploadCerificateDetails(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.notifier.showSuccess(data.message);
          this.showCertificateModal = false;
          this.getGeneralDatails();
        } else {
          this.notifier.showError(data.message);
        }
      });
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.fruits.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  selectedIns(event: MatAutocompleteSelectedEvent): void {
    this.searchInstuments.push(event.option.viewValue);
    this.deviceInput.nativeElement.value = '';
    this.instumentCtrl.setValue(null);
  }
  selectedgen(event: MatAutocompleteSelectedEvent): void {
    this.searchgen.push(event.option.viewValue);
    this.genInput.nativeElement.value = '';
    this.genCtrl.setValue(null);
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allCountries.filter((country) =>
      country.toLowerCase().includes(filterValue)
    );
  }

  addIns(event: MatChipInputEvent): void {
    const input = event;
    const value = event.value;

    // Add our country
    if ((value || '').trim()) {
      this.searchInstuments.push(value.trim());
    }

    // Reset the input valueeducationDetails
    if (input) {
      input.value = '';
    }

    this.deviceCtrl.setValue(null);
  }
  addgen(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our country
    if ((value || '').trim()) {
      this.searchgen.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.genCtrl.setValue(null);
  }
  adds(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our country
    if ((value || '').trim()) {
      this.searchveg.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.vegCtrl.setValue(null);
  }
  removes(veg: string): void {
    const index = this.searchveg.indexOf(veg);

    if (index >= 0) {
      this.searchveg.splice(index, 1);
    }
  }
  removeIns(instrument: string): void {
    const index = this.searchInstuments.indexOf(instrument);

    if (index >= 0) {
      this.searchInstuments.splice(index, 1);
    }
  }
  removegen(gen: string): void {
    const index = this.searchgen.indexOf(gen);

    if (index >= 0) {
      this.searchgen.splice(index, 1);
    }
  }

  getGeneralDatails() {
    let userId = localStorage.getItem('userId');
    this._mainService.getGeneralProfileById(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.generalDetails = data.data;
        this.CertificatedDetails = this.generalDetails.certificateDetails;
        // this.editGeneralForm.controls.timeZone.setValue(
        //   this.generalDetails?.timeZone[0]
        // );
        this.searchTimeZone[0] = this.generalDetails?.timeZone;
        this.editGeneralForm.controls.timeZone.setValue(this.searchTimeZone);
        this.editGeneralForm.controls.displayName.setValue(
          this.generalDetails?.displayName
        );
        this.editGeneralForm.controls.experience.setValue(
          this.generalDetails?.experience
        );
        this.editGeneralForm.controls.education.setValue(
          this.generalDetails?.educationDetails
        );
        this.educationValue = this.generalDetails?.educationDetails;
        this.preSlectedValues = this.educationValue.map((item: any) => item);
        for (let country of data.data?.locations) {
          this.location.push(country);
        }
      }
    });
  }

  getEssentailDetails() {
    let userId = localStorage.getItem('userId');
    this._mainService.getessentailProfileById(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.essentailsDetails = data.data;
        this.editEssentailForm.controls.feeRangeTo.setValue(
          this.essentailsDetails?.feeRangeTo
        );
        this.editEssentailForm.controls.feeRangeFrom.setValue(
          this.essentailsDetails?.feeRangeFrom
        );
        this.editEssentailForm.controls.bandSizes.setValue(
          this.essentailsDetails?.bandSizes
        );
        this.bandSizeValue = this.essentailsDetails?.bandSizes;
        this.bandSlectedValues = this.bandSizeValue.map((item: any) => item);
        for (let instument of data.data?.instruments) {
          this.searchInstuments.push(instument);
        }
        for (let genres of data.data?.genres) {
          this.searchgen.push(genres);
        }
        for (let notable of data.data?.notableMusicians) {
          this.fruits.push(notable);
        }
      } else {
        this.notifier.showError(data.message);
      }
    });
  }
  onroleChange(ob: any) {
    var selectedRole = ob.value;
    this.getRole = selectedRole;
  }

  onsiteChange(onsite: any) {
    var selectedonSite = onsite.value;
    this.getOnSite = selectedonSite;
  }

  onvideoSetupChange(onvideo: any) {
    var selectedVideoSetup = onvideo.value;
    this.getOnvideosetup = selectedVideoSetup;
  }
  onvideoQualityChange(onvideoQuality: any) {
    var selectedVideoSetup = onvideoQuality.value;
    this.getOnvideoQuality = selectedVideoSetup;
  }

  onaudioSetupChange(onaudioSetup: any) {
    var selectedAudioSetup = onaudioSetup.value;
    this.getOnAudioSetup = selectedAudioSetup;
  }

  enviromentChange(enviroment: any) {
    var selectedAudioSetup = enviroment.value;
    this.getEnviroment = selectedAudioSetup;
  }

  onbandChange(band: any) {
    var selectedonband = band.value;
    this.getBand = selectedonband;
  }

  getBandSize(): any {
    this._mainService.getBandSize().then((data: any) => {
      if (data && data.isSuccess) {
        this.bandSize = data.data;
      }
    });
  }
  getBackgroundDetails() {
    let userId = localStorage.getItem('userId');
    this._mainService.getBackgroundDetails(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.backgroundDetails = data.data;
        this.editBackgroundForm.controls.careerSummary.setValue(
          this.backgroundDetails?.careerSummary
        );
        this.editBackgroundForm.controls.albumCredits.setValue(
          this.backgroundDetails?.albumCredits
        );
        this.editBackgroundForm.controls.firstMusicalMemory.setValue(
          this.backgroundDetails?.firstMusicalMemory
        );
        this.editBackgroundForm.controls.influentialArtists.setValue(
          this.backgroundDetails?.influentialArtists
        );
        this.editBackgroundForm.controls.loveAboutMusic.setValue(
          this.backgroundDetails?.loveAboutMusic
        );
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  saveOrUpdateBackground() {
    const body = {
      albumCredits: this.editBackgroundForm.value.albumCredits,
      careerSummary: this.editBackgroundForm.value.careerSummary,
      firstMusicalMemory: this.editBackgroundForm.value.firstMusicalMemory,
      influentialArtists: this.editBackgroundForm.value.influentialArtists,
      loveAboutMusic: this.editBackgroundForm.value.loveAboutMusic,
    };
    this._mainService.saveOrUpdateBackgroundDetails(body).then((data: any) => {
      if (data.isSuccess) {
        this.notifier.showSuccess(data.message);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  getProducionDetails() {
    let userId = localStorage.getItem('userId');
    this._mainService.getProductionDetails(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.productionDetails = data.data;
        this.editProductionForm.controls.onsiteDetails.setValue(
          this.productionDetails?.onsites
        );
        this.onsites = this.productionDetails?.onsites;
        this.onSiteValues = this.onsites.map((item: any) => item);
        this.editProductionForm.controls.videoSetupDetails.setValue(
          this.productionDetails?.videoSetups
        );
        this.videosetup = this.productionDetails?.videoSetups;
        this.onvideoSetupValues = this.videosetup.map((item: any) => item);
        this.editProductionForm.controls.videoQualityDetails.setValue(
          this.productionDetails?.videoQualities
        );
        this.videoquality = this.productionDetails?.videoQualities;
        this.onvideoQualityValues = this.videoquality.map((item: any) => item);
        this.editProductionForm.controls.audioSetupDetails.setValue(
          this.productionDetails?.audioSetups
        );
        this.audioSetup = this.productionDetails?.audioSetups;
        this.onaudioSetupValues = this.audioSetup.map((item: any) => item);
        this.editProductionForm.controls.performanceEnvironmentDetails.setValue(
          this.productionDetails?.performanceEnvironments
        );
        this.performance = this.productionDetails?.performanceEnvironments;
        this.perfomanceValues = this.performance.map((item: any) => item);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }
  getMediaDetials() {
    let userId = localStorage.getItem('userId');
    this._mainService.getMediaDetails(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.mediaDetails = data.data;
        if (this.mediaDetails && this.mediaDetails.websites) {
          for (let website of this.mediaDetails?.websites) {
            this.mediaForm.controls.website.setValue(website);
          }
        }
        if (this.mediaDetails && this.mediaDetails.publicVideos) {
          for (let publicVideo of this.mediaDetails?.publicVideos) {
            this.mediaForm.controls.publicVideos.setValue(publicVideo);
          }
        }
        if (this.mediaDetails && this.mediaDetails.socialVideos) {
          for (let socialVideo of this.mediaDetails?.socialVideos) {
            this.mediaForm.controls.socialVideos.setValue(socialVideo);
          }
        }

      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  getEducationList() {
    this._mainService.getEducationList().then((data: any) => {
      if (data && data.isSuccess) {
        this.educationList = data.data;
      }
    });
  }

  getOnSiteList() {
    this._mainService.getOnsiteList().then((data: any) => {
      if (data && data.isSuccess) {
        this.onSiteList = data.data;
      }
    });
  }

  getVideosetList() {
    this._mainService.getVideoSetupList().then((data: any) => {
      if (data && data.isSuccess) {
        this.videosetList = data.data;
      }
    });
  }

  getVideoQualityList() {
    this._mainService.getVideoQualityList().then((data: any) => {
      if (data && data.isSuccess) {
        this.videoQualityList = data.data;
      }
    });
  }

  getAudioSetupList() {
    this._mainService.getAudioSetupList().then((data: any) => {
      if (data && data.isSuccess) {
        this.audioSetupList = data.data;
      }
    });
  }

  getPerformanceEnvironmentList() {
    this._mainService.getPerformanceEnvironmentList().then((data: any) => {
      if (data && data.isSuccess) {
        this.PerformanceEnvironmentList = data.data;
      }
    });
  }

  toggleRequest(reqType: string) {
    this.requestType = reqType;
  }

  UpdateDetails() {
    this.showDragDrop = false;
    if (this.requestType === 'GENERAL') {
      this.saveOrUpdateGeneral();
    } else if (this.requestType === 'ESSENTIALS') {
      this.saveOrUpdateEssentail();
    } else if (this.requestType === 'BACKGROUND') {
      this.saveOrUpdateBackground();
    } else if (this.requestType === 'PRODUCTION') {
      this.saveOrUpdateProduction();
    } else if (this.requestType === 'MEDIA') {
      this.saveOrUpdateMediaDetails();
    } else if (this.requestType === 'SETTINGS') {
      this.changePassword();
    }
  }

  changePassword() {
    let oldPassword = this.seetingForm.controls['oldPassword'].value;
    let newPassword = this.seetingForm.controls['newPassword'].value;
    let confirmPassword = this.seetingForm.controls['confirmPassword'].value;
    if (!oldPassword) {
      this.notifier.showError('Please Enter Old Password');
    } else if (!newPassword) {
      this.notifier.showError('Please Enter New Password');
    } else if (!confirmPassword) {
      this.notifier.showError('Please Enter Confirm Password');
    } else if (oldPassword && newPassword && confirmPassword) {
      let body = {
        confirmPassword: this.seetingForm.controls['confirmPassword'].value,
        newPassword: this.seetingForm.controls['newPassword'].value,
        oldPassword: this.seetingForm.controls['oldPassword'].value,
      };
      this._mainService.changePassword(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.seetingForm.reset();
          this.oldPassWordHide = false;
          this.newPassWordHide = false;
          this.confirmPasswordHide = false;
          this.notifier.showSuccess(data.message);
        } else {
          this.notifier.showError(data.message);
        }
      });
    }
  }

  imagePreview(id: any) {
    this.showImageModal = true;
    for (let image of this.mediaDetails?.imageDetails) {
      if (id === image.id) {
        this.previewImage = image.imageUrl;
      }
    }
  }

  certicatedPreview(id: any) {
    for (let certificate of this.generalDetails?.certificateDetails) {
      if (id === certificate.id) {
        this.previewCertificate = certificate.certificateUrl;
      }
    }
  }

  videoPreview(id: any) {
    this.videoImageModal = true;
    for (let media of this.mediaDetails?.videoDetails) {
      if (id === media.id) {
        this.previewVideo = media.videoUrl;
      }
    }
  }
  addMusicTagVideoPreview(id: any) {
    this.videoId = id;
    this.getProPlaylistDetails(this.videoId);
    for (let media of this.mediaDetails?.videoDetails) {
      if (id === media.id) {
        this.previewVideo = media.videoUrl;
      }
    }
  }

  getProfilepic() {
    this.imageSpinnerDone = true;
    let userId = localStorage.getItem('userId');
    this._mainService.getProfilepic(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.imageSpinnerDone = false;
        this.profilePicData = data.data;
      } else {
        this.imageSpinnerDone = false;
        this.notifier.showError(data.message);
      }
    });
  }

  onFileSelect(event: any) {
    if (event.target.files[0]) {
      this.showDragDrop = true;
    }
    this.imageChangedEvent = event;
    const file = event.target.files[0];
    if (file) {
      this.imageSrc = <string>(<FileReader>event.target).result;
    }
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileData = base64ToFile(this.croppedImage);
  }

  cropDone() {
    this.showDragDrop = false;
    this.imageSpinnerDone = true;
    this.uploadProfilePhoto(this.fileData);
  }

  uploadProfilePhoto(file: any) {
    this.generalTabButton = true;
    this.essentialsButton = false;
    this.backgroundButton = false;
    this.productionButton = false;
    this.imageSpinnerDone = true;
    this.mediaButton = false;
    this.imageUploaded = true;
    const fd = new FormData();
    fd.append('multipartFile', file);
    this._mainService.fileUpload(fd).then((data: any) => {
      if (data && data.isSuccess) {
        this.imageUrl = data.data;
        this._mainService.updateProfilePic(data.data).then((res: any) => {
          if (data && data.isSuccess) {
            this.getProfilepic();
            this._SessionService.setSessions({ avatarUrl: data.data });
            this.showDragDrop = false;
            this.notifier.showSuccess(res.message);
          } else {
            this.notifier.showError(res.message);
          }
        });
      } else {
      }
    });
  }

  imageRemove(id: any) {
    this._mainService.deleteImage(id).then((data: any) => {
      if (data && data.isSuccess) {
        this.notifier.showSuccess(data.message);
        this.getMediaDetials();
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  videoRemove(id: any) {
    this._mainService.deleteVideo(id).then((data: any) => {
      if (data && data.isSuccess) {
        this.notifier.showSuccess(data.message);
        this.getMediaDetials();
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  onFileSelectvid(event: any) {
    const file = event.target.files[0];
    if (file.type == 'video/mp4' || file.type == 'video/avi') {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          this.videoSubmitting = true;
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageSrcvid = <string>(<FileReader>event.target).result;
            this.videoArray.push(this.imageSrcvid);
            this.fileNamevid = file.name;
            this.showImagevid = true;
          };
          reader.readAsDataURL(event.target.files[0]);
          this.uploadFile(file);
        }
      }
    } else {
      this.notifier.showError('Only Video Are Allowed');
    }
  }

  uploadFile(file: any) {
    this.videoUploaded = true;
    const fd = new FormData();
    fd.append('multipartFile', file);
    this._mainService.fileUpload(fd).then((data: any) => {
      if (data && data.isSuccess) {
        this.videoUrl = data.data;
        this.videoSubmitting = false;
        this.videoUploaded = false;
        this.showIcon = false;
      } else {
        this.showImagevid = false;
        this.notifier.showError(data.message);
        this.videoUploaded = false;
      }
    });
  }

  addMusic(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;
    if ((value || '').trim()) {
      this.keywords.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
  }

  addVideoDetails() {
    let videoTitle = this.videoUploadForm.controls['videoTitle'].value;
    if (!this.keywords) {
      this.notifier.showError('Please Enter Video Tag');
    } else if (!videoTitle) {
      this.notifier.showError('Please Enter Video Title');
    } else if (!this.videoUrl) {
      this.notifier.showError('Please upload Video');
    } else if (this.keywords && videoTitle && this.videoUrl) {
      let body = {
        videoTags: this.keywords,
        videoTitle: this.videoUploadForm.controls['videoTitle'].value,
        videoUrl: this.videoUrl,
      };
      this._mainService.addVideoDetails(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.notifier.showSuccess(data.message);
          this.getMediaDetials();
          this.videoUploadForm.reset();
          this.keywords = [];
          this.videoUrl = null;
          this.showImagevid = false;
        } else {
          this.notifier.showError(data.message);
        }
      });
    }
  }

  showUploaModaledit(certificate: any) {
    this.showUploaModal = true;
    this.id = certificate.id;
    this.certificateForm.controls.certificateName.setValue(
      certificate.certificateName
    );
    this.certificateForm.controls.issuingOrganization.setValue(
      certificate.issuingOrganization
    );

    this.yearFrom.setValue(certificate.yearObtainedFrom.toString());
    this.yearTo.setValue(certificate.yearObtainedTo.toString());

    this.showImage = true;
    this.imageSrc = certificate.certificateUrl;
  }

  addImageDetails() {
    if (!this.videoDetails) {
      this.notifier.showError('Please Select Image');
    } else {
      let body = {
        imageUrl: this.videoDetails,
      };
      this._mainService.addImageDetails(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.notifier.showSuccess(data.message);
          this.showUploaImageModal = false;
          this.videoDetails = null;
          this.showImage = false;
          this.getMediaDetials();
        } else {
          this.notifier.showError(data.message);
        }
      });
    }
  }

  saveOrUpdateMediaDetails() {
    for (let image of this.mediaDetails?.imageDetails) {
      this.imageArray.push(image.imageUrl);
    }

    let body = {
      avatarUrl: this.mediaDetails?.avatarUrl,
      imageDetails: this.imageArray,
      publicVideos: [this.mediaForm.controls['publicVideos'].value],
      socialVideos: [this.mediaForm.controls['socialVideos'].value],
      videoDetails: this.mediaDetails?.videoDetails,
      websites: [this.mediaForm.controls['website'].value],
    };
    this._mainService.saveOrUpdateMediaDetails(body).then((data: any) => {
      if (data && data.isSuccess) {
        this.notifier.showSuccess(data.message);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  saveOrUpdateGeneral() {
    let displayName = this.editGeneralForm.controls['displayName'].value;
    let educationDetails = this.editGeneralForm.controls['education'].value;
    let experience = this.editGeneralForm.controls['experience'].value;
    let timezone = this.editGeneralForm.controls['timeZone'].value;
    if (!displayName) {
      this.notifier.showError('Please Enter Display Name');
    } else if (!experience) {
      this.notifier.showError(
        'Please Enter How many years you have been playing'
      );
    } else if (!educationDetails) {
      this.notifier.showError('Please Enter Education');
    } else if (!this.location) {
      this.notifier.showError('Please Enter Locations');
    } else if (!timezone) {
      this.notifier.showError('Please Select Timezone');
    } else if (displayName && experience && educationDetails && timezone) {
      this.generalFormSubmitting = true;
      const body = {
        displayName: this.editGeneralForm.value.displayName,
        educationDetails: this.editGeneralForm.value.education,
        experience: this.editGeneralForm.value.experience,
        locationDetails: this.location,
        timeZone: timezone[0],
      };
      this._mainService.saveorUpdateArtistGeneralDetails(body).then((data: any) => {
        if (data && data.isSuccess) {
          this._SessionService.setSessions({ timeZone: body.timeZone });
          this.notifier.showSuccess(data.message);
          this.getProfilepic();
        } else {
          this.notifier.showError(data.message);
        }
      });
    }
  }

  saveOrUpdateProduction() {
    const body = {
      audioSetupDetails: this.editProductionForm.value.audioSetupDetails,
      onsiteDetails: this.editProductionForm.value.onsiteDetails,
      performanceEnvironmentDetails:
        this.editProductionForm.value.performanceEnvironmentDetails,
      videoQualityDetails: this.editProductionForm.value.videoQualityDetails,
      videoSetupDetails: this.editProductionForm.value.videoSetupDetails,
    };
    this._mainService.saveOrUpdateProductionCapabilities(body).then((data: any) => {
      if (data && data.isSuccess) {
        this.notifier.showSuccess(data.message);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }
  onFileSelectImage(event: any) {
    const file = event.target.files[0];
    if (
      file.type == 'image/jpeg' ||
      file.type == 'image/png' ||
      file.type == 'image/svg+xml' ||
      file.type == 'image/webp'
    ) {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageSrc = <string>(<FileReader>event.target).result;
          this.fileName = file.name;
          this.showImage = true;
        };
        reader.readAsDataURL(event.target.files[0]);
        this.uploadFileImage(file);
      }
    } else {
      this.notifier.error('Only images are allowed');
    }
  }

  uploadFileImage(file: any) {
    this.imageUploaded2 = true;
    const fd = new FormData();
    fd.append('multipartFile', file);
    this._mainService.fileUploadImage(fd).then((data: any) => {
      if (data && data.isSuccess) {
        this.imageUploaded2 = false;
        this.videoDetails = data.data;
        this.notifier.showSuccess(data.message);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  saveOrUpdateEssentail() {
    let instrument = this.searchInstuments;
    let Genres = this.searchgen;
    let FeeRangeFrom = this.editEssentailForm.controls['feeRangeFrom'].value;
    let FeeRangeTo = this.editEssentailForm.controls['feeRangeTo'].value;
    let bandSize = this.editEssentailForm.controls['bandSizes'].value;
    let notableMusician = this.fruits;
    // this.editEssentailForm.controls['notableMusicians'].value;
    if (!instrument) {
      this.notifier.showError('Please Enter Instruments Played ');
    } else if (!Genres) {
      this.notifier.showError('Please Enter Genres Played ');
    } else if (!bandSize) {
      this.notifier.showError('Please Enter Act/Band Size');
    } else if (!notableMusician) {
      this.notifier.showError(
        'Please Enter Notable musicians you’ve worked with'
      );
    } else if (FeeRangeFrom >= FeeRangeTo) {
      this.notifier.showError('Please enter valid Fee range value');
    } else if (notableMusician && bandSize && FeeRangeFrom <= FeeRangeTo) {
      this.essentailFormSubmitting = true;
      const body = {
        bandSizes: this.editEssentailForm.value.bandSizes,
        feeRangeFrom: this.editEssentailForm.value.feeRangeFrom,
        feeRangeTo: this.editEssentailForm.value.feeRangeTo,
        genres: this.searchgen,
        instruments: this.searchInstuments,
        notableMusicians: this.fruits,
      };
      this._mainService.essentailDetails(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.notifier.showSuccess(data.message);
        } else {
          this.notifier.showError(data.message);
        }
      });
    }
  }

  removeCertificateDetails(certificate: any) {
    this.id = certificate.id;
    this._mainService.removeCertificateDetails(this.id).then((data: any) => {
      if (data && data.isSuccess) {
        this.notifier.showSuccess(data.message);
        this.getGeneralDatails();
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  removeMusic(keyword: string): void {
    let index = this.keywords.indexOf(keyword);

    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }

  removenotable(notable: string): void {
    const index = this.fruits.indexOf(notable);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  editCerticateDetails() {
    const body = {
      certificateName: this.certificateForm.value.certificateName,
      certificateUrl: this.certificateUrl,
      id: this.id,
      issuingOrganization: this.certificateForm.value.issuingOrganization,
      yearObtainedFrom: this.yearFrom.value.year(),
      yearObtainedTo: this.yearTo.value.year(),
    };
    this._mainService.editCertificateDetails(body).then((data: any) => {
      if (data && data.isSuccess) {
        this.notifier.showSuccess(data.message);
        this.showUploaModal = false;
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  savePublicVideo() {
    if (!this.publicVideo) {
      this.notifier.showError('Please Enter link');
    } else if (this.publicVideo) {
      this.mediaForm.controls.publicVideos.setValue(
        this.mediaForm.controls['publicVideos'].value + ',' + this.publicVideo
      );
      this.socialMedia = false;
    }
  }

  saveSocialVideo() {
    if (!this.socialVideo) {
      this.notifier.showError('Please Enter link');
    } else if (this.socialVideo) {
      this.mediaForm.controls.socialVideos.setValue(
        this.mediaForm.controls['socialVideos'].value + ',' + this.socialVideo
      );
      this.socialVideosaddmore = false;
    }
  }

  saveWebsiteLink() {
    if (!this.websiteLink) {
      this.notifier.showError('Please Enter link');
    } else if (this.websiteLink) {
      this.mediaForm.controls.website.setValue(
        this.mediaForm.controls['website'].value + ',' + this.websiteLink
      );
      this.yourWebsite = false;
    }
  }

  saveProPlaylist() {
    let body = {
      category: this.tabName,
      identifierId: this.videoId,
      artist: this.proReportingForm.controls['link'].value,
      proPlaylistType: 'VIDEO',
      title: this.proReportingForm.controls['title'].value,
    };
    this._mainService.addProSongToEvent(body).then((data: any) => {
      if (data && data.isSuccess) {
        this.proReportingForm.reset();
        this.getProPlaylistDetails(this.videoId);
        this.notifier.showSuccess(data.message);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  getProPlaylistDetails(id: any) {
    this._mainService.getProPlaylistDetails(id).then((data: any) => {
      if (data && data.isSuccess) {
        this.proListData = data.data;
        // this.notifier.showSuccess(data.message);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    this.proId = [];
    moveItemInArray(this.proListData, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.proListData.length; i++) {
      var proId = this.proListData[i].id;
      this.proId.push(proId);
    }
    this.updatePROPlaylistOrder(this.proId);
  }

  selectAll() {
    this.selectAllproList = !this.selectAllproList;
    if (this.selectAllproList) {
      for (let i = 0; i < this.proListData.length; i++) {
        this.proListIds.push(this.proListData[i].id);
      }
    } else {
      this.proListIds = [];
    }
  }

  deleteProList(id: any) {
    this._mainService.deleteAddedSongOnEvent(id).then((data: any) => {
      if (data && data.isSuccess) {
        this.notifier.showSuccess(data.message);
        this.getProPlaylistDetails(this.videoId);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  delteProList() {
    this._mainService.deleteAddedSongOnEvent(this.proListIds).then((data: any) => {
      if (data && data.isSuccess) {
        this.notifier.showSuccess(data.message);
        this.selectAllproList = true;
        this.getProPlaylistDetails(this.videoId);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  changeMenu() {
    if (this.tabName == 'ASCAP') {
      this.link = 'https://www.ascap.com/';
    } else if (this.tabName == 'BMI') {
      this.link = 'https://repertoire.bmi.com/';
    }
  }

  updatePROPlaylistOrder(id: any) {
    let body = {
      identifierId: this.videoId,
      playlistIds: id,
      proPlaylistType: 'VIDEO',
    };
    this._mainService.updateAddedSongsOrder(body).then((data: any) => {
      if (data && data.isSuccess) {
      } else {
      }
    });
  }

  getUserAccPendingDetails() {
    this._mainService.getUserAccPendingDetails().then((data: any) => {
      if (data && data.isSuccess) {
        if (data.data == false) {
          this.deleteNotProcess = true;
          this.deleteShow = false;
          this.checkBox9 = false;
        } else {
          this.deleteNextStep = true;
        }
        // this.notifier.showSuccess(data.message);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }



  deleteUserAccount() {
    let reason = this.deleteForm.controls['reason'].value;
    let win = <any>window;
    this._mainService.deleteUserAccount(reason).then((data: any) => {
      if (data && data.isSuccess) {
        this.submitHubspotForm();
        this.notifier.showSuccess(data.message);
        this.logoutUser();
        this.deleteNextStep = false;
        this.deleteShow = false;
        this.deleteForm.reset();
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  logoutUser() {
    const device = this.browser;
    this._auth.logout(device).then((data: any) => {
      if (data && data.isSuccess) {
        this._SessionService.removeSessions();
        this._SessionService.removeSession('email');
        this._SessionService.removeSession('refreshToken');
        this._SessionService.removeSession('userId');
        this._SessionService.removeSession('refreshTokenLog');
        this._SessionService.removeSession('token');
        this._SessionService.removeSession('newtoken');
        this._SessionService.removeSession('chatUuId');
        this._UserService.setLogedUser(null);
        this.router.navigate([`/login`]);
      } else {
        this.notifier.showError(data.message);
      }
    });
  }

  submitHubspotForm() {
    let reason = this.deleteForm.controls['reason'].value;
    var xhr = new XMLHttpRequest();
    var url =
      'https://api.hsforms.com/submissions/v3/integration/submit/9406878/363427d3-bef8-41e0-abea-d789f03e3e01';
    var data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: this.hubspotEmail,
        },
        {
          name: 'TICKET.subject',
          value: '',
        },
        {
          name: 'TICKET.content',
          value: reason,
        },
        {
          name: 'accept_tos',
          value: 1,
        },
      ],
      context: {
        pageUri: environment.hubspotNavigateUrl + '/ArtistDashboard/edit?type=SETTINGS',
        pageName: 'Delete Account',
      },
    };
    var final_data = JSON.stringify(data);
    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
      } else if (xhr.readyState == 4 && xhr.status == 400) {
      } else if (xhr.readyState == 4 && xhr.status == 403) {
      } else if (xhr.readyState == 4 && xhr.status == 404) {
        // alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
      }
    };
    xhr.send(final_data);
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }
}
