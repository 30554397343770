<div class="joinpage">
    <div class="joinhead">
        <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg" routerLink="" style="cursor: pointer;">
    </div>
    <div class="joinbody">
        <div class="row joinb">
            <div class="col-md-6">
                <div class="joinleft">
                    <app-slider></app-slider>
                </div>
            </div>
            <div *ngIf="regenerateLink == false" class="col-md-6">
                <form class="mt-5 pt-2" [formGroup]="forgetPasswordForm">
                    <div class="joinright">
                        <h3>Forgot Password</h3>
                        <label class="required"><b>Email</b></label><br>
                        <input type="text" formControlName="email"><br>
                        <div style="color: red;"
                            *ngIf="forgetPasswordForm.controls['email'].touched && !forgetPasswordForm.controls['email'].valid">
                            <span *ngIf="forgetPasswordForm.controls['email'].hasError('required')">
                                Email is required
                            </span>
                            <span *ngIf="forgetPasswordForm.controls['email'].hasError('pattern') ">
                                Please Enter Valid Email Address
                            </span>
                        </div>
                        <button class="button" (click)="forgotPwd()">Send Verification code</button>
                        <h1 routerLink="/login" style="cursor: pointer;">Back to log in</h1>
                    </div>
                </form>
            </div>
            <div *ngIf="regenerateLink == true" class="col-md-6">
              <form class="mt-5 pt-2" [formGroup]="forgetPasswordForm">
                  <div class="joinright">
                      <h3>Regenerate Confirmation Link</h3>
                      <label class="required"><b>Email</b></label><br>
                      <input type="text" formControlName="email"><br>
                      <div style="color: red;"
                          *ngIf="forgetPasswordForm.controls['email'].touched && !forgetPasswordForm.controls['email'].valid">
                          <span *ngIf="forgetPasswordForm.controls['email'].hasError('required')">
                              Email is required
                          </span>
                          <span *ngIf="forgetPasswordForm.controls['email'].hasError('pattern') ">
                              Please Enter Valid Email Address
                          </span>
                      </div>
                      <button class="button" (click)="forgotPwd()">Send Verification Link</button>
                      <h1 routerLink="/login" style="cursor: pointer;">Back to log in</h1>
                  </div>
              </form>
          </div>
        </div>
<div class="mt-4 ml-4">
  <app-footer></app-footer>
</div>
        <!-- <div class="joinfoot">
            <div class="row joinf">
                <h2 style="margin-right: 50px;">Privacy Policy</h2>
                <h2 style="margin-right: 50px;">Terms and Conditions</h2>
                <h2>Need Help?</h2>
            </div>
        </div> -->
    </div>
    <div class="modal" [ngClass]="{'d-block': showVerificationModal, 'd-none': !showVerificationModal}">
        <div class="modal-dialog" role="document">
            <div class="modal-content" style="background-color: #111111;margin-top: 92px;">
                <div class="modal-header" style="border-bottom: none;">
                    <button type="button" class="close" (click)="showVerificationModal = false" style="color: white;">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="sendpage">
                        <div class="">
                            <form [formGroup]="otpFormVerification" (submit)="verifyOtp()">
                                <div class="joinbody">
                                    <h3 class="required">Enter Verification Code</h3>
                                    <label class="required"><b>Verification Code</b></label><br>
                                    <input type="text" placeholder="Enter Code" formControlName="otp"><br>
                                    <h2 style="padding-top: 13px;">Don't get the code?<a><span style="color: #FF6720;cursor:pointer;
                              text-decoration:underline;" (click)="forgotPwd()">&nbsp;Resend Code</span></a></h2>
                                </div>
                                <button class="button" routerLink="reset-password"
                                    style="margin-left: 61px;">Verify</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
