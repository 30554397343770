import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { AudienceRoutingModule } from './audience-routing.module';
import { AudienceHomeComponent } from './audience-home/audience-home.component';
import { DetailsComponent } from './audience-home/details/details.component';
import { MessagesComponent } from './audience-home/messages/messages.component';
import { EventsComponent } from './audience-home/events/events.component';
import { DetailHomeComponent } from './audience-home/detail-home/detail-home.component'
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { EditComponent } from './audience-home/details/edit/edit.component';
import { GigBoardComponent } from './home/gig-board/gig-board.component';
import { EventComponent } from './home/event/event.component';
import { EarningComponent } from './home/earning/earning.component';
import { ArtistEditComponent } from './home/details/artist-edit/artist-edit.component';
import { ArtistHomeDetailComponent } from './home/artist-home-detail/artist-home-detail.component';
import { DetailComponent } from './home/details/details.component';
import { SwiperModule } from 'swiper/angular';
import { ArtistSeeAllComponent } from './home/artist-home-detail/artist-see-all/artist-see-all.component';
import { ArtistPreComponent } from './home/artist-home-detail/artist-pre/artist-pre.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ArtistPendingComponent } from './home/artist-pending/artist-pending.component';
import { ArtistPendingSeeAllComponent } from './home/artist-pending/artist-pending-see-all/artist-pending-see-all.component';
import { ArtistPendingStaffPickComponent } from './home/artist-pending/artist-pending-staff-pick/artist-pending-staff-pick.component';
import { SimpleSearchComponent } from './home/simple-search/simple-search.component';
import { AdvancedSearchComponent } from './home/advanced-search/advanced-search.component';
import { AudienceSimpleSearchComponent } from './audience-home/audience-simple-search/audience-simple-search.component';
import { AudienceAdvancedSearchComponent } from './audience-home/audience-advanced-search/audience-advanced-search.component';
import { DraftsComponent } from './audience-home/drafts/drafts.component';
import { EventDetailsComponent } from './audience-home/events/event-details/event-details.component';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InviteArtistComponent } from './audience-home/events/invite-artist/invite-artist.component';
import { ArtistEventDetailsComponent } from './home/event/artist-event-details/artist-event-details.component';
import { ArtistDraftsComponent } from './home/artist-drafts/artist-drafts.component';
import { ArtistInvitationComponent } from './home/event/artist-invitation/artist-invitation.component';
import { AudienceCalenderComponent } from './audience-home/audience-calender/audience-calender.component';
import { DropDownButtonAllModule } from '@syncfusion/ej2-angular-splitbuttons';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { DropDownListAllModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { MaskedTextBoxModule, UploaderAllModule } from '@syncfusion/ej2-angular-inputs';
import { ToolbarAllModule, ContextMenuAllModule } from '@syncfusion/ej2-angular-navigations';
import { ButtonAllModule, CheckBoxAllModule, SwitchAllModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerAllModule, TimePickerAllModule, DateTimePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { NumericTextBoxAllModule, TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { ScheduleAllModule, RecurrenceEditorAllModule } from '@syncfusion/ej2-angular-schedule';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { MatRadioModule } from '@angular/material/radio';
import { WebcamModule } from 'ngx-webcam';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ArtistSeeALLNotificationComponent } from './home/artist-see-allnotification/artist-see-allnotification.component';
import { AudienceSeeAllNotificationComponent } from './audience-home/audience-see-all-notification/audience-see-all-notification.component';
import { ArtistCalenderComponent } from './home/artist-calender/artist-calender.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClickOutsideModule } from 'ng-click-outside';
import { SpinnersAngularModule } from 'spinners-angular';
import { ArtistLiveStreamingComponent } from './home/event/artist-live-streaming/artist-live-streaming.component';
import { SharedModule } from '../../../shared/shared.module';
import { AudienceLiveStreamingComponent } from './audience-home/events/audience-live-streaming/audience-live-streaming.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ProReportingComponent } from './home/pro-reporting/pro-reporting.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ArtistInviteComponent } from './home/artist-invite/artist-invite.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxSocialShareModule } from 'ngx-social-share';
import { InviteGuestComponent } from './audience-home/invite-guest/invite-guest.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu'
import { ImageCropperModule } from 'ngx-image-cropper';
import {MatExpansionModule} from '@angular/material/expansion';
import { AudienceEarningsComponent } from './audience-home/audience-earnings/audience-earnings.component';
import { BillingComponent } from './home/billing/billing.component';
import { AudienceWatchTestStreamComponent } from './audience-home/audience-watch-test-stream/audience-watch-test-stream.component';
import { ArtistWatchTestStreamComponent } from './home/artist-watch-test-stream/artist-watch-test-stream.component';
import { SafePipe } from 'src/app/service/safe.pipe';
@NgModule({
  declarations: [
    HomeComponent,
    AudienceHomeComponent,
    DetailsComponent,
    MessagesComponent,
    EventsComponent,
    EditComponent,
    GigBoardComponent,
    EventComponent,
    EarningComponent,
    ArtistEditComponent,
    DetailHomeComponent,
    ArtistHomeDetailComponent,
    ArtistSeeAllComponent,
    ArtistPreComponent,
    ArtistPendingComponent,
    ArtistPendingSeeAllComponent,
    ArtistPendingStaffPickComponent,
    SimpleSearchComponent,
    AdvancedSearchComponent,
    AudienceSimpleSearchComponent,
    AudienceAdvancedSearchComponent,
    DraftsComponent,
    EventDetailsComponent,
    InviteArtistComponent,
    ArtistEventDetailsComponent,
    ArtistDraftsComponent,
    ArtistInvitationComponent,
    AudienceCalenderComponent,
    ArtistSeeALLNotificationComponent,
    AudienceSeeAllNotificationComponent,
    ArtistCalenderComponent,
    ArtistLiveStreamingComponent,
    AudienceLiveStreamingComponent,
    ProReportingComponent,
    ArtistInviteComponent,
    InviteGuestComponent,
    DetailComponent,
    AudienceEarningsComponent,
    BillingComponent,
    AudienceWatchTestStreamComponent,
    ArtistWatchTestStreamComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    AudienceRoutingModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    // MatChipsModule,
    MatIconModule,
    WebcamModule,
    MatTabsModule,
    MatCheckboxModule,
    // MatAutocompleteModule,
    MatListModule,
    SwiperModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatNativeDateModule,
    MatChipsModule,
    MatDialogModule,
    MatStepperModule,
    // MatIconModule,
    MatFormFieldModule,
    InfiniteScrollModule,
    HttpClientModule,
    NgSelectModule,
    NgMultiSelectDropDownModule,
    RatingModule.forRoot(),
    ScheduleAllModule,
    RecurrenceEditorAllModule,
    NumericTextBoxAllModule,
    TextBoxAllModule,
    DatePickerAllModule,
    TimePickerAllModule,
    DateTimePickerAllModule,
    CheckBoxAllModule,
    ToolbarAllModule,
    DropDownListAllModule,
    ContextMenuAllModule,
    MaskedTextBoxModule,
    UploaderAllModule,
    MultiSelectAllModule,
    TreeViewModule,
    ButtonAllModule,
    DropDownButtonAllModule,
    SwitchAllModule,
    MatRadioModule,
    GoogleMapsModule,
    GooglePlaceModule,
    ButtonsModule.forRoot(),
    TimepickerModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    PickerModule,
    PopoverModule,
    CalendarModule,
    PdfViewerModule,
    ClickOutsideModule,
    SpinnersAngularModule,
    SharedModule,
    ClipboardModule,
    DragDropModule,
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    NgxSocialShareModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatButtonModule,
    MatMenuModule,
    ImageCropperModule,
    MatExpansionModule
  ],
  exports: [],
  providers:    [ SafePipe ]
})
export class AudienceModule { }
