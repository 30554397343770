import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { debounceTime, startWith } from 'rxjs/operators';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { MustMatch } from 'src/app/module/login/reset-password/password validation/confirm-validation';
import { SessionService } from 'src/app/service/helper/session.service';
import { UserService } from 'src/app/service/user.service';
import { AuthServiceService } from 'src/app/service/auth-service.service';
import { LoginModal } from 'src/app/service/models/auth.modals';
import { environment } from 'src/environments/environment';
import { ImageCroppedEvent , ImageTransform ,Dimensions, base64ToFile} from 'ngx-image-cropper';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
  @ViewChild('placesRef') placesRef!: GooglePlaceDirective;
  audienceProfileData: any;
  profilePicData: any;
  imageUrl: any;
  selectable = true;
  removable = true;
  selectable2 = true;
  removable2 = true;
  separatorKeysCodes: number[] = [COMMA];
  imageUploaded: boolean;
  editArtistForm: FormGroup;
  editArtistDetialsForm: FormGroup;
  ageArray: any = [];
  timeZoneArray: any = [];
  imageSrc: any;
  @ViewChild('countryInput')
  countryInput!: ElementRef<HTMLInputElement>;
  searchGenres: string[] = [];
  brandCtrl = new FormControl();
  @ViewChild('deviceInput')
  deviceInput!: ElementRef<HTMLInputElement>;
  brandList: any;
  allSelected = false;
  @ViewChild('select')
  select!: MatSelect;
  deviceArray: any = [];
  searchgen: string[] = [];
  removablegen = true;
  selectablegen = true;
  genCtrl = new FormControl();
  separatorKeysCodesgen: number[] = [COMMA];
  genList: any;
  keywords: string[] = [];
  selectableMusician = true;
  removableMusician = true;
  separatorKeysCodesMusician: number[] = [ENTER, COMMA];
  addOnBlur: boolean = true;
  removableNotable = true;
  avatarUrl: any;
  newUrl: any;
  getRole: any;
  educationValue: any;
  preSlectedValues: any;
  location: string[] = [];
  locationCtrl = new FormControl();
  @ViewChild('genInput')
  genInput!: ElementRef<HTMLInputElement>;
  socialVideosaddmore: boolean;
  socialVideo: any;
  WebsiteForm!:FormGroup;
  locationlength: any
  searchTimeZone: string[] = [];
  @ViewChild('timeZoneInput')
  timeZoneInput!: ElementRef<HTMLInputElement>;
  timeZoneCtrl = new FormControl();
  oldPassWordHide = true;
  newPassWordHide = true;
  confirmPasswordHide = true;
  sectionName = 'general';
  checkBox1: boolean = false
  checkBox2: boolean = false
  checkBox3: boolean = false
  deleteShow: boolean = false;
  checkBox9: boolean = false;
  deleteNextStep: boolean = false;
  seetingForm!: FormGroup;
  deleteForm!: FormGroup;
  deleteNotProcess: boolean = false;
  requestType!: string;
  type: any;
  status: any;
  showDeleteButton: boolean = false;
  login: LoginModal;
  browser: any;
  hubspotEmail:any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  showDragDrop:boolean = false;
  fileData:any;
  imageSpinnerDone:boolean = false;

  constructor(
    private _FormBuilder: FormBuilder,
    private _mainService: MainService,
    private _notifierService: NotifierService,
    private fb: FormBuilder,
    private activatederoute: ActivatedRoute,
    private _SessionService: SessionService,
    private _UserService: UserService,
    private _auth: AuthServiceService,
    private router: Router,
  ) {
    this.login = new LoginModal();
    this.socialVideosaddmore = false;
    this.genCtrl.valueChanges
      .pipe(startWith(null), debounceTime(500))
      .subscribe((value: string) => {
        if (value) {
          this._mainService.searchGenres(value).then((res: any) => {
            if (res && res.isSuccess) {
              this.genList = res.data;
            }
          });
        }
      });

    this.timeZoneCtrl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((value: string) => {
      if (value) {
        this._mainService.getTimeZone('', value).then((res: any) => {
          if (res && res.isSuccess) {
            this.timeZoneArray = res.data;
          }
        })
      }
    })
    this.imageUploaded = false;
    (this.editArtistForm = this._FormBuilder.group({
      displayName: ['', []],
      age: ['', []],
      timeZone: ['', []]
    })),
      (this.editArtistDetialsForm = this._FormBuilder.group({
        deviceUsed: new FormControl(this.educationValue),
        media: ['',  [
          Validators.pattern(
            /^(?:(?:(?:https?|ftp):)?\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=. ]+$/
          ),
        ]],
      }));
    this.seetingForm = this.fb.group({
      oldPassword: ["", Validators.required],
      newPassword: ["", Validators.required],
      confirmPassword: ['', [Validators.required]],
    }, {

      validator: MustMatch('newPassword', 'confirmPassword'),
    })
    this.deleteForm = this.fb.group({
      reason: ["", Validators.required]
    })
    this.WebsiteForm = this.fb.group({
      websiteLink :[
       '',
       [
         Validators.required,
         Validators.pattern(/^(?:(?:(?:https?|ftp):)?\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=. ]+$/),
       ],
      ]
   })
  }

  ngOnInit(): void {
    this.type = this.activatederoute.snapshot.queryParams.type;
    this.status = this._SessionService.getSession('status');
    this.hubspotEmail = this._SessionService.getSession('email');
    this.browser = this.login.device;
    if (this.type) {
      this.requestType = this.type
    } else {
      this.requestType = 'GENERAL';
    }
    if (this.status === 'PENDING_DELETE') {
      this.showDeleteButton = true;
    } else {
      this.showDeleteButton = false;
    }
    this.getProfilepic();
    this.getAudienceProfileDetails();
    this.getAgeList();
    this.getDeviceList();
    this._mainService.getTimeZone('', 'A').then((res: any) => {
      if (res && res.isSuccess) {
        this.timeZoneArray = res.data;
      }
    })
  }

  removeTimeZone(timeZone: string): void {
    const index = this.searchTimeZone.indexOf(timeZone);
    if (index >= 0) {
      this.searchTimeZone.splice(index, 1);
    }
    this.editArtistForm.controls.timeZone.setValue(this.searchTimeZone)
  }
  selectedTimeZone(event: MatAutocompleteSelectedEvent): void {
    this.searchTimeZone[0] = (event.option.viewValue);
    this.editArtistForm.controls.timeZone.setValue(this.searchTimeZone)
    this.timeZoneInput.nativeElement.value = '';
    this.timeZoneCtrl.setValue(null);
  }
  ngAfterViewInit() {
    this.placesRef.options.componentRestrictions = { country: 'US' };
  }

  handleAddressChange(address: Address) {
    this.location.push(address.formatted_address);
    this.locationCtrl.setValue(null);
    this._mainService.getTimeZone(address.address_components[3].long_name).then((data: any) => {
      if (data && data.isSuccess) {
        this.timeZoneArray = data.data
      }
    })
  }

  remove(address: string): void {
    const index = this.location.indexOf(address);
    if (index >= 0) {
      this.location.splice(index, 1);
    }
  }

  getAudienceProfileDetails() {
    let userId = localStorage.getItem('userId');
    this._mainService.getAudienceProfileDetails(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.audienceProfileData = data.data;
        this.editArtistForm.controls.displayName.setValue(
          this.audienceProfileData?.displayName
        );
        this.editArtistForm.controls.age.setValue(
          this.audienceProfileData?.ageRange
        );
        this.editArtistForm.controls.timeZone.setValue(
          this.audienceProfileData?.timeZone
        );
        this.searchTimeZone[0] = this.audienceProfileData?.timeZone
        this.educationValue = this.audienceProfileData?.devices;
        this.preSlectedValues = this.educationValue.map((item: any) => item);
        this.editArtistDetialsForm.controls.deviceUsed.setValue(
          this.preSlectedValues
        );
        for (let country of data.data?.locations) {
          this.location.push(country);
        }
        for (let genere of data.data?.genres) {
          this.searchgen.push(genere);
        }
        for (let keywords of data.data?.musicians) {
          this.keywords.push(keywords);
        }
        for (let media of data.data?.socialMediaLinks) {
          this.editArtistDetialsForm.controls.media.setValue(media);
        }
      } else {
        this._notifierService.showError(data.message);
      }
    });
  }

  getAgeList() {
    this._mainService.getAgeList().then((data: any) => {
      if (data && data.isSuccess) {
        this.ageArray = data.data;
      }
    });
  }

  getProfilepic() {
    this.imageSpinnerDone = true;
    let userId = localStorage.getItem('userId');
    this._mainService.getProfilepic(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.imageSpinnerDone = false;
        this.profilePicData = data.data;
      } else
        this._notifierService.showError(data.message);
        this.imageSpinnerDone = false;
    });
  }

  onFileSelect(event: any) {
    if(event.target.files[0]){
    this.showDragDrop = true;
    }
    this.imageChangedEvent = event;
    const file = event.target.files[0];
     if (file) {
      this.imageSrc = <string>(<FileReader>event.target).result;
     }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileData = base64ToFile(this.croppedImage);
   }

  cropDone(){
   this.showDragDrop = false;
   this.imageSpinnerDone = true;
   this.uploadProfilePhoto( this.fileData);
  }


  uploadProfilePhoto(file: any) {
    this.imageUploaded = true;
    this.imageSpinnerDone = true;
    const fd = new FormData();
    fd.append('multipartFile', file);
    this._mainService.fileUpload(fd).then((data: any) => {
      if (data.isSuccess) {
        this.imageUrl = data.data;
        this._mainService.updateProfilePic(data.data).then((res: any) => {
          if (data && data.isSuccess) {
            this.getProfilepic();
            this._SessionService.setSessions({ avatarUrl: data.data });
            this.showDragDrop = false;
            this._notifierService.showSuccess(res.message);
          } else
            this._notifierService.showError(res.message);
        });
      }
    });
  }
  addgeneres(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.searchGenres.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.brandCtrl.setValue(null);
  }

  removeGeneres(country: string): void {
    const index = this.searchGenres.indexOf(country);
    if (index >= 0) {
      this.searchGenres.splice(index, 1);
    }
  }

  selectedGeneres(event: MatAutocompleteSelectedEvent): void {
    this.searchGenres.push(event.option.viewValue);
    this.deviceInput.nativeElement.value = '';
    this.brandCtrl.setValue(null);
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  getDeviceList() {
    this._mainService.getDeviceList().then((data: any) => {
      if (data && data.isSuccess) {
        this.deviceArray = data.data;
      }
    });
  }

  removegen(gen: string): void {
    const index = this.searchgen.indexOf(gen);
    if (index >= 0) {
      this.searchgen.splice(index, 1);
    }
  }

  addgen(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.searchgen.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.genCtrl.setValue(null);
  }

  selectedgen(event: MatAutocompleteSelectedEvent): void {
    this.searchgen.push(event.option.viewValue);
    this.genInput.nativeElement.value = '';
    this.genCtrl.setValue(null);
  }

  addMusic(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;
    if ((value || '').trim()) {
      this.keywords.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
  }

  removenotable(notable: string): void {
    const index = this.keywords.indexOf(notable);

    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }


  removeMusic(keyword: string): void {
    const index = this.keywords.indexOf(keyword);

    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }

  toggleRequest(reqType: string) {
    this.requestType = reqType;
  }

  audienceProfileDetails() {
    this.showDragDrop = false;
    if (this.requestType === 'GENERAL' || this.requestType === 'DETAILS') {
      this.saveOrUpdateAudienceProfileDetails();
    } else if (this.requestType === 'SETTINGS') {
      this.changePassword();
    }
  }

  saveOrUpdateAudienceProfileDetails() {
    if (!this.imageUploaded) {
      this.avatarUrl = this.profilePicData?.avatarUrl;
    } else
      this.avatarUrl = this.imageUrl;
    var timeZone = this.editArtistForm.controls['timeZone'].value
    let body = {
      ageRange: this.editArtistForm.controls['age'].value,
      avatarUrl: this.avatarUrl,
      deviceDetails: this.editArtistDetialsForm.controls['deviceUsed'].value,
      displayName: this.editArtistForm.controls['displayName'].value,
      genreDetails: this.searchgen,
      locationDetails: this.location,
      musicianDetails: this.keywords,
      socialMediaLinks: [this.editArtistDetialsForm.controls['media'].value],
      timeZone: this.searchTimeZone[0]
    };
    this._mainService.saveOrUpdateAudienceProfileDetails(body).then((data: any) => {
        if (data && data.isSuccess) {
          this._SessionService.setSessions({ timeZone: body.timeZone });
          this._notifierService.showSuccess(data.message);
          this.router.navigate(['/audienceDashboard/details']);
        } else
          this._notifierService.showError(data.message);
      });
  }

  changePassword() {
    let oldPassword = this.seetingForm.controls['oldPassword'].value;
    let newPassword = this.seetingForm.controls['newPassword'].value;
    let confirmPassword = this.seetingForm.controls['confirmPassword'].value;
    if (!oldPassword) {
      this._notifierService.showError("Please Enter Old Password")
    } else if (!newPassword) {
      this._notifierService.showError("Please Enter New Password")
    } else if (!confirmPassword) {
      this._notifierService.showError("Please Enter Confirm Password")
    } else if (oldPassword && newPassword && confirmPassword) {
      let body = {
        "confirmPassword": this.seetingForm.controls['confirmPassword'].value,
        "newPassword": this.seetingForm.controls['newPassword'].value,
        "oldPassword": this.seetingForm.controls['oldPassword'].value,
      }
      this._mainService.changePassword(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.seetingForm.reset();
          this.oldPassWordHide = false;
          this.newPassWordHide = false;
          this.confirmPasswordHide = false;
          this._notifierService.showSuccess(data.message);
        } else {
          this._notifierService.showError(data.message);
        }
      })
    }
  }

  onroleChange(ob: any) {
    var selectedRole = ob.value;
    this.getRole = selectedRole;
  }

  saveSocialVideo() {
    if (!this.socialVideo) {
      this._notifierService.showError('Please Enter link');
    }else if (this.socialVideo){
    this.editArtistDetialsForm.controls.media.setValue(
      this.editArtistDetialsForm.controls['media'].value +
      ',' +
      this.socialVideo
    );
    this.socialVideosaddmore = false
    }
  }

  getUserAccPendingDetails() {
    this._mainService.getUserAccPendingDetails().then((data: any) => {
      if (data && data.isSuccess) {
        if (data.data == false) {
          this.deleteNotProcess = true;
          this.deleteShow = false;
          this.checkBox9 = false;
        } else {
          this.deleteNextStep = true;
        }
        // this._notifierService.showSuccess(data.message);
      } else
        this._notifierService.showError(data.message);
    })
  }



  deleteUserAccount() {
    let reason = this.deleteForm.controls['reason'].value;
    this._mainService.deleteUserAccount(reason).then((data: any) => {
      if (data && data.isSuccess) {
        this.submitHubspotForm();
        this._notifierService.showSuccess(data.message);
        this.logoutUser();
        this.deleteNextStep = false;
        this.deleteShow = false;
        this.deleteForm.reset();
      } else
        this._notifierService.showError(data.message);
    })
  }

  logoutUser() {
    const device = this.browser;
    this._auth.logout(device).then((data: any) => {
      if (data && data.isSuccess) {
        this._SessionService.removeSessions();
        this._SessionService.removeSession('email');
        this._SessionService.removeSession('refreshToken');
        this._SessionService.removeSession('userId');
        this._SessionService.removeSession('refreshTokenLog');
        this._SessionService.removeSession('token');
        this._SessionService.removeSession('newtoken');
        this._SessionService.removeSession('chatUuId');
        this._UserService.setLogedUser(null);
        this.router.navigate([`/login`]);
      } else
        this._notifierService.showError(data.message);
    });
  }

  submitHubspotForm() {
    let reason = this.deleteForm.controls['reason'].value;
    var xhr = new XMLHttpRequest();
    var url =
      'https://api.hsforms.com/submissions/v3/integration/submit/9406878/363427d3-bef8-41e0-abea-d789f03e3e01';
    var data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: this.hubspotEmail,
        },
        {
          name: 'TICKET.subject',
          value: '',
        },
        {
          name: 'TICKET.content',
          value: reason,
        },
        {
          name: 'accept_tos',
          value: 1,
        },
      ],
      context: {
        pageUri:  environment.hubspotNavigateUrl+'/audienceDashboard/edit?type=SETTINGS',
        pageName: 'Delete Account',
      },
    };
    var final_data = JSON.stringify(data);
    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
      } else if (xhr.readyState == 4 && xhr.status == 400) {
      } else if (xhr.readyState == 4 && xhr.status == 403) {
      } else if (xhr.readyState == 4 && xhr.status == 404) {
        // alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
      }
    };
    xhr.send(final_data);
  }

  imageLoaded() {
    this.showCropper = true;
  }

cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
}

loadImageFailed() {
    console.log('Load failed');
}

}
