import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/service/helper/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-watch-sound-check',
  template: ''
})
export class WatchSoundCheckComponent implements OnInit {
  poster: any;
  param: any = this.activatedRoute.queryParams;
  url = environment.hubspotNavigateUrl;

  constructor(private activatedRoute: ActivatedRoute, private sessionService: SessionService, private route: Router) { }

  ngOnInit(): void {
    let email = this.sessionService.getSession('email')
    var role = this.sessionService.getSession('roles')
    if (email && role) {
      if (role == 'ARTIST' || role == 'PENDING ARTIST') {
        this.route.navigate(['/ArtistDashboard/watchTestStream'], { queryParams: { streamId: this.param['_value'].streamId } })
      }
      else if (role == 'AUDIENCE') {
        this.route.navigate(['/audienceDashboard/watchteststream'], { queryParams: { streamId: this.param['_value'].streamId } })
      }
    }
    else {
      this.route.navigate(['/login'], { queryParams: { streamId: this.param['_value'].streamId } })
    }
  }

}
