import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { UserWithPrivilege } from '../models/user-modal';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userInfo: Subject<UserWithPrivilege | null>
  user: UserWithPrivilege | null;
  constructor() {
    this.userInfo = new BehaviorSubject<UserWithPrivilege | null>(null);
    this.user = null;
  }

  setLogedUser(user: UserWithPrivilege | null): void {
    this.userInfo.next(user);
    this.user = user;
  }

  getLogedUser(): Observable<UserWithPrivilege | null> {
    return this.userInfo.asObservable();
  }

  getLogedUserWithoutObservable(): UserWithPrivilege | null {
    return this.user;
  }
}
