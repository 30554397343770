import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MainService } from 'src/app/service/main.service';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import SwiperCore, { Pagination ,Navigation,Autoplay} from "swiper";
import { UserService } from 'src/app/service/user.service';
import { AuthServiceService } from 'src/app/service/auth-service.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { LoginModal } from 'src/app/service/models/auth.modals';

declare const Swiper: any;
SwiperCore.use([Autoplay, Pagination, Navigation]);

@Component({
  selector: 'app-essentials',
  templateUrl: './essentials.component.html',
  styleUrls: ['./essentials.component.scss']
})
export class EssentialsComponent implements OnInit {
  visible = true;
  selectable = true;
  selectablegen = true;
  removable = true;
  removablegen = true;
  separatorKeysCodess: number[] = [COMMA];
  separatorKeysCodes: number[] = [COMMA];
  separatorKeysCode: number[] = [COMMA];
  separatorKeysCodesveg: number[] = [ENTER, COMMA];
  countriesCtrl = new FormControl();
  countries: string[] = [];
  instuments: any = []
  allCountries: string[] = [];
  brandList: any;
  instrumentList: any;
  genList: any;
  instumentCtrl = new FormControl('');
  genCtrl = new FormControl();
  vegCtrl = new FormControl();
  searchInstuments: string[] = [];
  searchgen: string[] = [];
  searchveg: string[] = [];
  @ViewChild('deviceInput')
  deviceInput!: ElementRef<HTMLInputElement>;
  @ViewChild('genInput')
  genInput!: ElementRef<HTMLInputElement>;
  @ViewChild('instInput')
  instInput!: ElementRef<HTMLInputElement>;
  deviceCtrl = new FormControl();
  logOut: boolean = false;
  filterdevice: Observable<string[]>;
  essentailFormSubmitting: boolean
  showUploaModal: boolean;
  browser: any;
  interval:any;
  @ViewChild('countryInput')
  countryInput!: ElementRef<HTMLInputElement>;
  @ViewChild('auto')
  matAutocomplete!: MatAutocomplete;
  @ViewChild('autogen')
  matAutocompletegen!: MatAutocomplete;
  essentailsDetails: any;
  bandSizeValue: any;
  bandSlectedValues: any;
  data = [];
  generesList = [];
  feeRange:boolean;
  login: LoginModal;


  selected(event: MatAutocompleteSelectedEvent): void {
    this.searchInstuments.push(event.option.viewValue);
    this.instInput.nativeElement.value = '';
    this.essentialForm.controls.instruments.setValue(this.searchInstuments);
    const instrumentEl: any = document .getElementById('mat-chip-list-input-1');
    if(instrumentEl) {
      instrumentEl.value = null;
    }
  }
  selectedgen(event: MatAutocompleteSelectedEvent): void {
    this.searchgen.push(event.option.viewValue);
    this.genInput.nativeElement.value = '';
    this.essentialForm.controls.genres.setValue(this.searchgen);
    const genEl: any = document .getElementById('mat-chip-list-input-1');
    if(genEl) {
      genEl.value = null;
    }
  }

  private _filter(country: string): any {
    throw new Error('Method not implemented.');
  }
  //   return this.allCountries.filter(instument => instument.toLowerCase().indexOf(filterValue) === 0);
  // }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();

  //   return this.allCountries.filter(instument => instument.toLowerCase().indexOf(filterValue) === 0);
  // }
  // instruments played api
  bandSize: any = [];
  essentialForm!: FormGroup;

  constructor(
    private mainservice: MainService,
    private fb: FormBuilder,
     private notifier: NotifierService,
     private router: Router,
     private _UserService:UserService,
     private _auth:AuthServiceService,
     private _SessionService: SessionService,
     ) {
      this.login = new LoginModal();
       this.feeRange = false;
    this.essentailFormSubmitting = false;
    this.showUploaModal = false;
    this.filterdevice = this.deviceCtrl.valueChanges.pipe(
      startWith(null),
      map((country: string | null) => country ? this._filter(country) : this.allCountries.slice()));
    this.instumentCtrl.valueChanges.pipe(
      debounceTime(500),
      startWith(null)
    ).subscribe((value: string | null) => {
      if (value) {
        this.mainservice.searchInstuments(value).then((res: any) => {
          if (res && res.isSuccess) {
            this.instrumentList = res.data;
          }
        })
      }
    })
    this.genCtrl.valueChanges.pipe(
      debounceTime(500),
      startWith(null)
    ).subscribe((value: string) => {
      if (value) {
        this.mainservice.searchGenres(value).then((res: any) => {
          if (res && res.isSuccess) {
            this.genList = res.data;
          }
        })
      }
    })

    this.essentialForm = this.fb.group({
      instruments: ['', [Validators.required]],
      genres: ['', [Validators.required]],
      bandSizes: ['', [Validators.required]],
      endTime: ['', [Validators.required]],
      notableMusicians:['', [Validators.required]],
      feeRangeFrom: ['', [Validators.required]],
      feeRangeTo: ['', [Validators.required]],
    });



  }

  ngOnInit(): void {
    this.getBandSize();
    this.getEssentailDetails();
    this.browser = this.login.device;
   }

  // slectValue(event: any) {
  //   this.mainservice.searchInstuments(event.target.value).then((data) => {
  //     this.data = data.data;
  //     this.essentialForm.controls.instruments.reset();
  //    })
  // }

  // slectGenres(event:any){
  //   this.mainservice.searchGenres(event.target.value).then((data) => {
  //     this.generesList = data.data;
  //     this.essentialForm.controls.genres.reset();
  //    })
  // }




  @ViewChild('select')
  select!: MatSelect;
  allSelectedp = false;

  toggleAllSelectionpl() {
    if (this.allSelectedp) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClickpl() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelectedp = newStatus;
  }

  addOnBlur = true;
  addOnBlur1 = true;
  // readonly separatorKeysCodes = [ENTER, COMMA] as const;

  genres: string[] = [];

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const input = event.input;
    if ((value || '').trim()) {
      this.searchInstuments.push(value.trim());
    }
    // event.chipInput!.clear();
    // this.deviceCtrl.setValue(null);
  }

  addgen(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.searchgen.push(value.trim());
    }
    // if (input) {
    //   input.value = '';
    // }
    // this.genCtrl.setValue(null);
  }

  adds(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.searchveg.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.vegCtrl.setValue(null);
  }

  removes(veg: string): void {
    const index = this.searchveg.indexOf(veg);
    if (index >= 0) {
      this.searchveg.splice(index, 1);
    }
  }

  remove(instrument: string): void {
    const index = this.searchInstuments.indexOf(instrument);
    if (index >= 0) {
      this.searchInstuments.splice(index, 1);
    }
  }

  removegen(gen: string): void {
    const index = this.searchgen.indexOf(gen);
    if (index >= 0) {
      this.searchgen.splice(index, 1);
    }
  }

  slides = [
    { 'image': 'assets/slide1.png' },
    { 'image': 'assets/slide1.png' },
    { 'image': 'assets/slide1.png' },
    { 'image': 'https://picsum.photos/seed/picsum/1200/300' },
    { 'image': 'https://picsum.photos/seed/picsum/1200/300' }
  ];

  getBandSize(): any {
    this.mainservice.getBandSize().then((data: any) => {
      if (data && data.isSuccess) {
        this.bandSize = data.data
      }
    })
  }

  essentialsDetails() {
    let instruments = this.essentialForm.controls['instruments'].value;
    let genres = this.essentialForm.controls['genres'].value;
    let bandSizes = this.essentialForm.controls['bandSizes'].value;
    let notableMusicians = this.essentialForm.controls['notableMusicians'].value;
    let feeRangeFrom = this.essentialForm.controls['feeRangeFrom'].value;
    let feeRangeTo = this.essentialForm.controls['feeRangeTo'].value;
      if (!instruments?.length) {
      this.notifier.showError("Please Enter Instruments Played ")
    } else if (!genres?.length) {
      this.notifier.showError("Please Enter Genres Played ")
    } else if (!bandSizes?.length) {
      this.notifier.showError("Please Enter Act/Band Size")
    } else if (!this.searchveg) {
      this.notifier.showError("Please Enter Notable musicians you’ve worked with")
    } else if (feeRangeFrom  >= feeRangeTo) {
       this.notifier.showError(" Please enter valid Fee range value")
    } else if ( instruments && genres && bandSizes && this.searchveg && feeRangeFrom  < feeRangeTo) {
      this.essentailFormSubmitting = true;
      const body = {
        bandSizes: bandSizes,
        feeRangeFrom: feeRangeFrom,
        feeRangeTo: feeRangeTo,
        genres: genres,
        instruments: instruments,
        notableMusicians: this.searchveg,
      }
      this.mainservice.essentailDetails(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.essentailFormSubmitting = false;
          this.router.navigateByUrl('background');
          this.notifier.showSuccess(data.message);
        }
        else
          this.notifier.showError(data.message)
      })
    }
  }

  getEssentailDetails() {
    let userId = localStorage.getItem('userId');
    this.mainservice.getessentailProfileById(userId).then((data: any) => {
       if (data && data.isSuccess) {
        this.essentailsDetails = data.data;
        this.searchInstuments = this.essentailsDetails.instruments;
        this.essentialForm.controls.instruments.setValue(this.essentailsDetails.instruments);
        this.searchgen = this.essentailsDetails?.genres
        this.essentialForm.controls.genres.setValue(this.essentailsDetails?.genres)
        this.essentialForm.controls.feeRangeTo.setValue(this.essentailsDetails?.feeRangeTo);
        this.essentialForm.controls.feeRangeFrom.setValue(this.essentailsDetails?.feeRangeFrom);
        this.essentialForm.controls.bandSizes.setValue(this.essentailsDetails?.bandSizes);
        this.bandSizeValue = this.essentailsDetails?.bandSizes;
        this.bandSlectedValues = this.bandSizeValue.map((item: any) => item)

        for (let notable of data.data?.notableMusicians) {
          this.searchveg.push(notable);
          // this.essentialForm.controls['notableMusicians'].setValue(notable)
        }
      }
      else {
        // this.notifier.showError(data.message);
      }
    })
  }

  logoutUser() {
    this.logOut = false;
    clearInterval(this.interval);
    const device = this.browser;
    this._auth.logout(device).then(data =>{
      if(data && data.isSuccess) {
        this._SessionService.removeSessions();
        this._SessionService.removeSession('email');
        this._SessionService.removeSession('refreshToken');
        this._SessionService.removeSession('userId');
        this._SessionService.removeSession('refreshTokenLog');
        this._SessionService.removeSession('token');
        this._SessionService.removeSession('newtoken');
        this._UserService.setLogedUser(null);
        this.router.navigate([`/login`])
      }else{
        this.notifier.showError(data.message);
      }
    })
  }

}
