<!-- header section -->
<nav class="navbar navbar-expand-md navbar-dark fixed-top tp-hd bg-dark-blck">
  <div>
    <mat-toolbar class="mat-elevation-z8">
      <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
        <mat-icon *ngIf="!sidenav.opened">
          menu
        </mat-icon>
        <mat-icon *ngIf="sidenav.opened">
          close
        </mat-icon>
      </button>
    </mat-toolbar>
  </div>
  <a class="navbar-brand sxaw" (click)="sideBarController()">
    <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg">
  </a>
  <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse"
    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <!-- <span class="navbar-toggler-icon"></span> -->
    <span><i class="fa fa-ellipsis-v"></i></span>
  </button>
  <div class="navbar-collapse collapse" id="navbarCollapse">
    <ul class="navbar-nav ml-auto ul-im">
      <li *ngIf="userStatus != 'SUSPENDED'" class="nav-item nbhg active" title="Search">
        <div *ngIf="searchBox" class="form-group has-search input-group">
          <input type="text" class="form-control" placeholder="Search this blog" (keyup.enter)="searchQuery($event)">
          <div class="input-group-append">
            <button class="btn btn-secondary aedsr" type="button">
              <i class="fa fa-search"></i>
            </button>
          </div>
          <div class="input-group-append">
            <button class="btn btn-secondary gftrj" type="button" data-toggle="modal" data-target="#searchModal">
              <img src="assets/Vector8.svg">
            </button>
          </div>
          <div class="input-group-append">
            <button class="btn btn-secondary gftrj" type="button" (click)="searchBoxController()">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        <img *ngIf="!searchBox" style="cursor: pointer;" src="assets/dashboard/Vector (1).svg"
          (click)="searchBoxController()">
      </li>

      <li *ngIf="userStatus != 'SUSPENDED'" class="nav-item dropdown hgne" title="Notification">
        <div dropdown #dropdown="bs-dropdown" [autoClose]="true">
          <a class="cursor-pointer dropdown-toggle" href="#" role="button" id="dropdownMenuLink20"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link user-action"
            style="cursor: pointer;" (click)="markAllRead()">
            <i class="fa"
              [ngClass]="{'fa-bell-o text-white': !isNotificationActive, 'fa-bell white-color': isNotificationActive}"
              style="font-size: 21px;"></i>
            <span class="badge noti-fc">{{unreadNotification}}</span>
          </a>
          <div class="notification-wrapper dropdown-menu" aria-labelledby="dropdownMenuLink20"
            style=" left: -298px !important;">
            <div class="container-fluid">
              <div class="heading shadows">
                <p>Notifications <span style="margin-left: 215px;" tooltip="Mark all as read" placement="left"
                    (click)="markAllRead();"><i class="fa fa-check-circle" aria-hidden="true"></i></span><span
                    style="margin-left: 19px;" tooltip="Delete all" placement="left"
                    (click)="removeAllNotification();"><i class="fa fa-trash-o" aria-hidden="true"></i></span></p>
              </div>
              <div class="notification-list">
                <ng-container *ngIf="notificationData">
                  <div style="text-align: center;">
                    <sa-spinner-circular [size]="37" [thickness]="102" [speed]="100" color="#dd0031"
                      secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
                  </div>
                </ng-container>
                <ng-container *ngIf="notificationContainer.length">
                  <ng-container *ngFor="let main of notificationContainer">
                    <ng-container *ngFor="let noti of main.item.notificationDetails">
                      <div class="all-notification" [style.background-color]="noti.read === true ? '#343434' : 'black'"
                        (click)="markRead(noti.id,noti.read)">
                        <div class="message" (click)="navigateOn(noti.type,noti.proposalId,noti.eventId)">
                          <div>
                            <p class="edcv" style="font-size: 11px !important;margin-bottom: 10px !important;">
                              {{noti.title}} <span style="font-size: 5px;padding: 0px 7px;"> <i
                                  class="fa fa-circle"></i>
                              </span> {{ noti.createdAt }}</p>
                          </div>
                          <div>
                            <h6>{{noti.fullname}} </h6>
                          </div>
                          <div class="pointer">
                            <p><b> {{noti.message | slice:0:80}} {{ noti.message.length > 80 ? '....' : '' }} </b></p>
                          </div>
                        </div>
                        <div>
                          <span style="margin-right: 18px; color: #666666;" tooltip="Delete" placement="top"> <i
                              class="fa fa-times" aria-hidden="true" style="font-size: 14px;margin-left: 49px;"
                              (click)="removeNotification(noti.id)"></i> </span>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!notificationContainer?.length && !notificationData">
                  <div class="request-notification">
                    <div class="message">
                      <div class="name">
                        <h3 style="text-align: center;">
                          No Data
                        </h3>
                      </div>
                    </div>
                  </div>
                </ng-container>

              </div>
            </div>
            <div class="notificaton-footer">
              <p class="pull-right pointer" [routerLink]="[ '/audienceDashboard/audienceSeeAllNotification' ]"
                style="cursor: pointer;">
                See all notifications
              </p>
            </div>
          </div>
        </div>
      </li>


      <li *ngIf="userStatus != 'SUSPENDED'" class="nav-item" title="Calendar">
        <i class="fa fa-calendar"
          style="color: white;font-size: 21px; margin-top: 3px;padding-left: 18px;cursor: pointer;"
          [routerLink]="['/audienceDashboard/calender']" (click)="closeAdvanceSearch(); showRightSideMenu = false;"></i>
      </li>
      <li *ngIf="userStatus != 'SUSPENDED'" class="nav-item" title="Favorite Artist">
        <img src="assets/dashboard/Vector (Stroke).svg" [routerLink]="['/audienceDashboard/audienceFavSearch']">
      </li>
      <li class="nav-item dropdown hgne" style="top: -2px;" title="Profile">
        <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink19" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img src="assets/dashboard/Mask Group.png">
        </a>
        <div class="dropdown-menu userMenu" aria-labelledby="dropdownMenuLink19">
          <a class="dropdown-item" style="border-radius: 15px;" [routerLink]="['/audienceDashboard/details']"
            (click)="showRightSideMenu = false; logOut = false"><i class="fa fa-user"></i> View Profile</a>
          <a class="dropdown-item" style="cursor: pointer; border-radius: 15px;" (click)="logoutUser()"><i
              class="fa fa-sign-out" aria-hidden="true"></i> Log Out</a>
        </div>
      </li>
    </ul>
  </div>
</nav>
<mat-sidenav-container>
  <mat-sidenav #sidenav="matSidenav">
    <mat-nav-list *ngIf="userStatus != 'SUSPENDED'">
      <div *ngIf="!showRightSideMenu">
        <a mat-list-item (click)="tabName = 'home'" [ngClass]="tabName == 'home' ? 'borderRight' : '' "
          [routerLink]="['/audienceDashboard/audienceHome']">
          <span class="navImage"> <img src="assets/dashboard/Vector (1)h.svg"> </span> Home </a>
        <a mat-list-item (click)="tabName = 'messages'" [ngClass]="tabName == 'messages' ? 'borderRight' : '' "
          [routerLink]="['/audienceDashboard/message']">
          <span class="navImage"> <img src="assets/dashboard/Vector (3)m.svg"> </span> Messages </a>
        <a mat-list-item (click)="tabName = 'events'" [ngClass]="tabName == 'events' ? 'borderRight' : '' "
          [routerLink]="['/audienceDashboard/event']">
          <span class="navImage"> <img src="assets/dashboard/Vector (4).svg"> </span> Events </a>
        <a mat-list-item (click)="tabName = 'billing'" [ngClass]="tabName == 'billing' ? 'borderRight' : '' "
          [routerLink]="['/audienceDashboard/billings']"> <span class="navImage"> <img
              src="assets/dashboard/Vector-bill.png"> </span>Billing </a>
        <!-- <a mat-list-item><span class="navImage"> <img src="assets/dashboard/Vector (7).svg">  </span> Earnings </a> -->

        <a mat-list-item href="https://help.virtuosica.com/" target="_blank">
          <span class="navImage"> <img src="assets/dashboard/Vector (9).svg"> </span>Support </a>
        <a mat-list-item (click)="tabName = 'settings'" [ngClass]="tabName == 'settings' ? 'borderRight' : '' "
          [routerLink]="['/audienceDashboard/edit']" [queryParams]="{ 'type': requestType}">
          <span class="navImage"> <img src="assets/dashboard/Vector (10).svg"> </span>Settings </a>
        <!-- <a mat-list-item  (click)="tabName = 'drafts'" [ngClass]="tabName == 'drafts' ? 'borderRight' : '' "  [routerLink]="['/audienceDashboard/audienceDrafts']">
        <span class="navImage"> <img src="assets/dashboard/Vector (4).svg">  </span> Drafts </a> -->
        <div style="margin-top: 130px;">
          <a mat-list-item>
            <p style="font-size: 16px;">Want to be an artist?</p>
          </a>
          <a mat-list-item>
            <button [disabled]="userStatus == 'PENDING_DELETE'" class="sendButton" (click)="artistShow = true">Apply
              Now!</button>
          </a>
        </div>
      </div>
      <div class="nav edu" *ngIf="showRightSideMenu" style="width: 275px;" >
        <form [formGroup]="advanceSearchForm">
          <ul class="ihrds">
            <li class="nav-link">
              <div class="form-group w-100 hfdqqa gen">
                <label for="genre">Genre</label>
                <mat-form-field class="example-chip-list" appearance="fill">
                  <mat-chip-list #chipListGen>
                    <mat-chip *ngFor="let genres of searchgen" (removed)="removegen(genres)">
                      {{genres}}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input
                      style="border: none; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                      #genInput [formControl]="genCtrl" [matAutocomplete]="autoGen" [matChipInputFor]="chipListGen"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodesgen" (matChipInputTokenEnd)="addgen($event)">
                  </mat-chip-list>
                  <mat-autocomplete #autoGen="matAutocomplete" (optionSelected)="selectedgen($event)">
                    <mat-option *ngFor="let gen of genList" [value]="gen">
                      {{gen}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start
                  typing to select your genres</small>
              </div>
            </li>
            <li class="nav-link">
              <div class="form-group w-100 hfdqqa">
                <label>Experience</label>
                <input type="text" formControlName="experience" class="form-control"
                  placeholder="Enter your experience">
              </div>
            </li>
            <li class="nav-link">
              <div class="form-group w-100 hfdqqa gen">
                <label for="genre">Instruments Played</label>
                <mat-form-field class="example-chip-list" appearance="fill">
                  <mat-chip-list #chipListInst>
                    <mat-chip *ngFor="let instrument of searchInstuments" [selectable]="selectable"
                      [removable]="removable" (removed)="remove(instrument)"> {{instrument}}
                      <mat-icon matChipRemove *ngIf="removable">close </mat-icon>
                    </mat-chip>
                    <input style="color: #FFFFFF; border: none;" #instInput [formControl]="instumentCtrl" [matAutocomplete]="autoInst"
                      [matChipInputFor]="chipListInst" [matChipInputSeparatorKeyCodes]='separatorKeysCodess'
                      (matChipInputTokenEnd)="add($event)">
                  </mat-chip-list>
                  <mat-autocomplete #autoInst="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let instruments of instrumentList" [value]="instruments"> {{instruments}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start
                  typing to select your instruments</small>
              </div>
            </li>
            <li class="nav-link">
              <div class="form-group w-100 hfdqqa">
                <label for="genre">Location</label>
                <mat-form-field class="example-chip-list location" appearance="fill">
                  <mat-chip-list #chipListLoc aria-label="Fruit selection">
                    <mat-chip *ngFor="let address of location" (removed)="removeLocation(address)">
                      {{address}}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input style="color: white; border: none;" placeholder="&nbsp;" #fruitInput [formControl]="locationCtrl"
                      [matChipInputFor]="chipListLoc" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      ngx-google-places-autocomplete #placesRef="ngx-places"
                      (onAddressChange)="handleAddressChange($event)">
                  </mat-chip-list>
                </mat-form-field>
                <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start
                  typing to select your location</small>
              </div>
            </li>
            <li class="nav-link">
              <div class="form-group w-100 hfdqqa">
                <label for="genre">Video Quality</label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="videoQualityDetails" #select2 multiple
                    placeholder="What level of video quality can you provide?">
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allSelected2" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection2()">Select All
                      </mat-checkbox>
                    </div>
                    <mat-option style="z-index: 10000000;" (click)="optionClick2()"
                      *ngFor="let videoquality of videoQualityList" [value]="videoquality">
                      {{videoquality}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </li>
            <li class="nav-link">
              <div class="form-group w-100 hfdqqa">
                <label for="genre">Onsite</label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="onsiteDetails" #select multiple
                    placeholder="Tell us about your production capabilities">
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection()">Select All
                      </mat-checkbox>
                    </div>
                    <mat-option (click)="optionClick()" *ngFor="let onsite of onSiteList" [value]="onsite">
                      {{onsite}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </li>
            <li class="nav-link">
              <div class="form-group w-100 hfdqqa">
                <label for="genre">Video Setups</label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="videoSetupDetails" #select1 multiple
                    placeholder="Which video setups do you perform with?">
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allSelected1" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection1()">Select All
                      </mat-checkbox>
                    </div>
                    <mat-option (click)="optionClick1()" *ngFor="let setup of videosetList" [value]="setup">
                      {{setup}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </li>
            <li class="nav-link">
              <div class="form-group w-100 hfdqqa">
                <label for="genre">Audio Setup</label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="audioSetupDetails" #select3 multiple
                    placeholder="Which audio setups do you perform with?">
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allSelected3" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection3()">Select All
                      </mat-checkbox>
                    </div>
                    <mat-option (click)="optionClick3()" *ngFor="let audiosetup of audioSetupList" [value]="audiosetup">
                      {{audiosetup}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </li>
          </ul>
          <button type="button" class="btn ytgft" data-dismiss="modal" (click)="advancedSearch()">Apply</button>
        </form>


      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div id="layoutSidenav_content" class="mainlayout">
      <div class="container-fluid">
        <main style="padding-top: 75px;">
          <router-outlet></router-outlet>
        </main>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>




<!-- Search modal  -->
<div class="modal fade" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="searchModalLabel"
  aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-lg vehz" role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <h5 class="modal-title wsedv" id="searchModalLabel">Advanced Search</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="advanceSearchForm">
          <div class="row advanceSearch">
            <div class="col-lg-4">
              <div class="">
                <div class="">
                  <label class="f1 required">Experience</label>
                  <input type="text" formControlName="experience" class="form-control amhre"
                    placeholder="Enter your experience">
                </div>
                <div class="gen mt-4">
                  <label class="required pt-3 mb-0">Instruments Played</label>
                  <mat-form-field class="example-chip-list " appearance="fill">
                    <mat-chip-list #chipListinst>
                      <mat-chip *ngFor="let instrument of searchInstuments" [selectable]="selectable"
                        [removable]="removable" (removed)="remove(instrument)">
                        {{instrument}}
                        <mat-icon matChipRemove *ngIf="removable">close
                        </mat-icon>
                      </mat-chip>
                      <input #instInput [formControl]="instumentCtrl" [matAutocomplete]="autoinst"
                        [matChipInputFor]="chipListinst" [matChipInputSeparatorKeyCodes]='separatorKeysCodess'
                        (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                    <mat-autocomplete #autoinst="matAutocomplete" (optionSelected)="selected($event)">
                      <mat-option *ngFor="let instruments of instrumentList" [value]="instruments">
                        {{instruments}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start
                    typing to select your instruments</small>
                </div>
                <div class="">
                  <label class="">Location</label>
                  <mat-form-field class="example-chip-list location" appearance="fill">
                    <mat-chip-list #chipListloc>
                      <mat-chip *ngFor="let address of location" (removed)="removeLocation(address)">
                        {{address}}
                        <button matChipRemove>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip>
                      <input placeholder="&nbsp;" #locInput [formControl]="locationCtrl" [matChipInputFor]="chipListloc"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" ngx-google-places-autocomplete
                        #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)">
                    </mat-chip-list>
                  </mat-form-field>
                </div>
                <div class="gen">
                  <label class="required">Genre</label>
                  <mat-form-field class="example-chip-list" appearance="fill">
                    <mat-chip-list #chipListgen>
                      <mat-chip *ngFor="let genres of searchgen" [selectable]="selectable" [removable]="removable"
                        (removed)="removegen(genres)">
                        {{genres}}
                        <button matChipRemove>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip>
                      <input
                        style="border: none; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                        #genInput [formControl]="genCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipListgen"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addgen($event)">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedgen($event)">
                      <mat-option *ngFor="let gen of genList" [value]="gen">
                        {{gen}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start
                    typing to select your genres</small>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="">
                <div class="mb-3">
                  <div class="form-group">
                    <label class="f1 required">Video Quality</label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="videoQualityDetails" #select2 multiple
                        placeholder="What level of video quality can you provide?">
                        <div class="select-all">
                          <mat-checkbox [(ngModel)]="allSelected2" [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection2()">Select All
                          </mat-checkbox>
                        </div>
                        <mat-option style="z-index: 10000000;" (click)="optionClick2()"
                          *ngFor="let videoquality of videoQualityList" [value]="videoquality">
                          {{videoquality}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="form-group">
                    <label class="required">Onsite</label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="onsiteDetails" #select multiple
                        placeholder="Tell us about your production capabilities">
                        <div class="select-all">
                          <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection()">Select All
                          </mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick()" *ngFor="let onsite of onSiteList" [value]="onsite">
                          {{onsite}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="form-group for-str">
                    <label class="f1 required">Video Setups</label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="videoSetupDetails" #select1 multiple
                        placeholder="Which video setups do you perform with?">
                        <div class="select-all">
                          <mat-checkbox [(ngModel)]="allSelected1" [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection1()">Select All
                          </mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick1()" *ngFor="let setup of videosetList" [value]="setup">
                          {{setup}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="form-group for-str">
                    <label class="f1 required">Audio Setup</label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="audioSetupDetails" #select3 multiple
                        placeholder="Which audio setups do you perform with?">
                        <div class="select-all">
                          <mat-checkbox [(ngModel)]="allSelected3" [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection3()">Select All
                          </mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick3()" *ngFor="let audiosetup of audioSetupList"
                          [value]="audiosetup">
                          {{audiosetup}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="">
                <div class="mb-3">
                  <label class=" required">Band Size</label>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="bandSizes" multiple placeholder="Enter your band size">
                      <mat-option *ngFor="let bandsize of bandSize" [value]="bandsize">
                        {{bandsize}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="mb-3">
                  <div class="form-group for-str">
                    <label class="f1 required">Environment</label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="performanceEnvironmentDetails" #select4 multiple
                        placeholder="Tell us about your performance environment?">
                        <div class="select-all">
                          <mat-checkbox [(ngModel)]="allSelected4" [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection4()">Select All
                          </mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick4()"
                          *ngFor="let performanceenvironment of PerformanceEnvironmentList"
                          [value]="performanceenvironment">
                          {{performanceenvironment}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="mb-3">
                  <label class="f1 required">Fee Range $</label>
                  <div class="row">
                    <div class="col-md-6">
                      <input formControlName="feeRangeFrom" placeholder="From" type="text" class="form-control amhre">
                    </div>
                    <div class="col-md-6 pl-0">
                      <div class="ujbt">
                        <input formControlName="feeRangeTo" placeholder="To" type="text"
                          class="form-control amhre"><span class="hr-j">/hr</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button [disabled]="userStatus == 'PENDING_DELETE'" type="button" class="btn ytgft"
          (click)="closeAdvanceSearch()">Reset</button>
        <button [disabled]="userStatus == 'PENDING_DELETE'" type="button" class="btn ytgft" data-dismiss="modal"
          (click)="advancedSearch()">Apply</button>
        <button type="button" class="btn htgfr" data-dismiss="modal" (click)="closeAdvanceSearch()">Cancel</button>
      </div>
    </div>
  </div>
</div>


<div class="modal" [ngClass]="{'d-block': artistShow, 'd-none': !artistShow}">
  <div class="modal-dialog">
    <div class="modal-content modalBg">
      <div class="modal-body">
        <div class="container">
          <div class="row text-center px-5 pt-5" style="color: white;">
            <div class="col-12">
              <p>Wish to Apply for an an Artist ?? </p>
              <p style="width: 362px;"><span style="color: #ff0000d1;">Note: </span> Once you are an Artist, you will be
                no longer an Audience </p>
              <button type="button" [disabled]="userStatus == 'PENDING_DELETE'" class="btn ytgft"
                style="padding: 10px 47px;margin-right:5px !important" [routerLink]="['/general']">Yes</button>
              <button type="button" class="btn hftrdd" (click)="artistShow = false">No</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
