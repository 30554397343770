<div class="container-fluid  px-4 pt-3">
  <div class="col-12 scroll">
    <div class="notification-wrapper">
      <p [routerLink]="['/audienceDashboard/audienceHome']">
        <i class="fa fa-angle-left" style="color:white;cursor: pointer;"></i>
      </p>
      <div class="hgnb bot-m">
        <div class="nvhi">
          <span>All Notification</span>
        </div>
      </div>
      <div class="notification-list" infiniteScroll [infiniteScrollDistance]="2" (scrolled)="onScroll()"
        [scrollWindow]="false" style="height: 68vh !important;overflow: auto;overflow-x: hidden;">
        <ng-container *ngIf="notificationData">
          <div style="text-align: center;">
            <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
              secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
          </div>
        </ng-container>
        <ng-container *ngIf="activeNotificationTab === 0 ">
          <ng-container *ngIf="notificationDetails">
            <ng-container *ngFor="let noti of notificationDetails">
              <div class="cr-di" style="padding: 0px 20px;cursor: pointer;">
                <div class="gdtdb" (click)="markRead(noti.id,noti.read,noti.type);navigateOn(noti.type,noti.proposalId,noti.eventId)"
                  [style.background-color]="noti.read === true ? '#343434' : 'black'">
                  <div class="baho breakLine">
                    <p class="edcv">{{noti.title}} <span style="font-size: 5px;padding: 0px 7px;"> <i
                          class="fa fa-circle"></i> </span> {{ noti.createdAt }}
                      <span class="cross" (click)="removeNotification(noti.id)">
                        <i class="fa fa-times"></i>
                      </span>
                    </p>
                    <h4>{{noti.fullname}} </h4>
                    <span class="olku">
                      {{noti.message}}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container>
            <div class="request-notification" *ngIf="!notificationDetails?.length && !notificationData">
              <div class="message">
                <div class="name">
                  <h3 style="text-align: center;color: #fff;">
                    No Data
                  </h3>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
