import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { SpinnerService } from 'src/app/service/helper/spinner.service';
import { MainService } from 'src/app/service/main.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invite-guest',
  templateUrl: './invite-guest.component.html',
  styleUrls: ['./invite-guest.component.scss']
})
export class InviteGuestComponent implements OnInit {
  selectTab = 'send';
  inviteForm: FormGroup;
  status = 'Artist';
  pageNo: number = 0;
  pageSize: number = 10;
  inviteUserList: any;
  inviteCount: any;
  inviteData: boolean;
  currentPage = 0;
  inviteFormSubmitting: boolean;
  inviteSendCount: any;
  id: any;

  constructor(
    private _FormBuilder: FormBuilder,
    private _NotifierService: NotifierService,
    private _MainService: MainService,
    private _SpinnerService: SpinnerService,
    private activatederoute: ActivatedRoute,
  ) {
    this.inviteFormSubmitting = false;
    this.inviteData = false;
    this.inviteForm = this._FormBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)]],
      emailId: ['', [Validators.required, Validators.pattern(/^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/)]],
    });
  }

  ngOnInit(): void {
    this.id = this.activatederoute.snapshot.queryParams.id;
  }

  toggle(text: any) {
    this.status = text;

  }


  shareLiveEvent() {
    this.inviteFormSubmitting = true;
    let name = this.inviteForm.controls['name'].value;
    let email = this.inviteForm.controls['emailId'].value.trim();
    let emailValid = this.inviteForm.controls['emailId'].invalid;
    let nameValid = this.inviteForm.controls['name'].invalid;
    if (!name) {
      this.inviteFormSubmitting = false;
      this._NotifierService.showError("Please Enter Name")
    } else if (!email) {
      this.inviteFormSubmitting = false;
      this._NotifierService.showError("Please Enter email")
    } else if (nameValid) {
      this._NotifierService.showError("Please Enter Valid Name");
      this.inviteFormSubmitting = false;
    } else if (emailValid) {
      this._NotifierService.showError("Please Enter Valid Email");
      this.inviteFormSubmitting = false;
    } else if (name && email && !nameValid && !emailValid) {
      let body = {
        "email": email,
        "eventId": this.id,
        "name": name
      }
      this._MainService.shareLiveEvent(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.inviteFormSubmitting = false;
          this._NotifierService.showSuccess(data.message);
          this.inviteForm.reset();
        } else {
          this.inviteFormSubmitting = false;
          this._NotifierService.showError(data.message);
        }
      })
    }
  }



  inviteList() {
    this.inviteData = true;
    this.selectTab = "invite";
    this._MainService.getSharedLiveEventDetailList(this.pageNo, this.pageSize, this.id).then((data: any) => {
      if (data && data.isSuccess) {
        this.inviteData = false;
        this.inviteUserList = data.data.content;
        this.inviteCount = data.data.totalRecords;
        // this._NotifierService.showSuccess(data.message);
      } else {
        if (data.message == 'No shared live event list found')
          this._NotifierService.showSuccess(data.message)
        else
          this._NotifierService.showError(data.message);
        this.inviteData = false;
      }
    })
  }

  pageChanged(event: any) {
    this.inviteData = false;
    this._SpinnerService.setState(true);
    this.currentPage = event.page - 1;
    this._MainService.getSharedLiveEventDetailList(this.currentPage, this.pageSize, this.id).then(data => {
      this._SpinnerService.setState(false)
      if (data && data.isSuccess) {
        this.inviteData = false;
        this.inviteUserList = data.data.content;
        this.inviteCount = data.data.totalRecords;
      }
    })
  }



}
