import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import SwiperCore, { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import { UserService } from 'src/app/service/user.service';
import { AuthServiceService } from 'src/app/service/auth-service.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { LoginModal } from 'src/app/service/models/auth.modals';

declare const Swiper: any;
SwiperCore.use([Autoplay, Pagination, Navigation]);
@Component({
  selector: 'app-background-detail',
  templateUrl: './background-detail.component.html',
  styleUrls: ['./background-detail.component.scss']
})
export class BackgroundDetailComponent implements OnInit {
  backgroundForm!: FormGroup;
  backgroundFormSubmitting: boolean;
  backgroundDetail: any;
  browser: any;
  interval: any;
  login: LoginModal;
  logOut: boolean = false;


  constructor(
    private fb: FormBuilder,
    private mainservice: MainService,
    private router: Router,
    private notifier: NotifierService,
    private _UserService: UserService,
    private _auth: AuthServiceService,
    private _SessionService: SessionService) {
    this.backgroundFormSubmitting = false;
    this.login = new LoginModal();
  }

  ngOnInit(): void {
    this.getBackgroundDetails()
    this.browser = this.login.device;
    this.backgroundForm = this.fb.group({
      albumCredits: new FormControl(''),
      careerSummary: new FormControl(''),
      firstMusicalMemory: new FormControl(''),
      influentialArtists: new FormControl(''),
      loveAboutMusic: new FormControl(''),
    });
  }




  backgroundDetails() {
    let albumCredits = this.backgroundForm.controls['albumCredits'].value;
    let careerSummary = this.backgroundForm.controls['careerSummary'].value;
    let firstMusicalMemory = this.backgroundForm.controls['firstMusicalMemory'].value;
    let influentialArtists = this.backgroundForm.controls['influentialArtists'].value;
    let loveAboutMusic = this.backgroundForm.controls['loveAboutMusic'].value;
    if (!careerSummary)
      this.notifier.showError("Please enter My Career In Summary")
    else if (careerSummary.length > 1500)
      this.notifier.showError("Career Summary size cannot be more than 1500.. ")
    else if (careerSummary) {
      this.backgroundFormSubmitting = true;
      const body = {
        albumCredits: albumCredits,
        careerSummary: careerSummary,
        firstMusicalMemory: firstMusicalMemory,
        influentialArtists: influentialArtists,
        loveAboutMusic: loveAboutMusic
      }
      this.mainservice.saveOrUpdateBackgroundDetails(body).then((data: any) => {
        if (data && data.isSuccess) {
          this.router.navigateByUrl('production');
          this.backgroundFormSubmitting = false;
          this.notifier.showSuccess(data.message);
        }
        else {
          this.notifier.showError(data.message);
          this.backgroundFormSubmitting = false;
        }
      })
    }
  }

  getBackgroundDetails() {
    let userId = localStorage.getItem('userId');
    this.mainservice.getBackgroundDetails(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.backgroundDetail = data.data;
        this.backgroundForm.controls.careerSummary.setValue(this.backgroundDetail?.careerSummary);
        this.backgroundForm.controls.albumCredits.setValue(this.backgroundDetail?.albumCredits);
        this.backgroundForm.controls.firstMusicalMemory.setValue(this.backgroundDetail?.firstMusicalMemory);
        this.backgroundForm.controls.influentialArtists.setValue(this.backgroundDetail?.influentialArtists);
        this.backgroundForm.controls.loveAboutMusic.setValue(this.backgroundDetail?.loveAboutMusic);
      }
    })

  }

  logoutUser() {
    this.logOut = false
    clearInterval(this.interval);
    const device = this.browser;
    this._auth.logout(device).then(data => {
      if (data && data.isSuccess) {
        this._SessionService.removeSessions();
        this._SessionService.removeSession('email');
        this._SessionService.removeSession('refreshToken');
        this._SessionService.removeSession('userId');
        this._SessionService.removeSession('refreshTokenLog');
        this._SessionService.removeSession('token');
        this._SessionService.removeSession('newtoken');
        this._UserService.setLogedUser(null);
        this.router.navigate([`/login`])
      } else {
        this.notifier.showError(data.message);
      }
    })
  }

}
