import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/service/helper/session.service';
import { MainService } from 'src/app/service/main.service';

@Component({
  selector: 'app-open-chat',
  template: '',
})
export class OpenChatComponent implements OnInit {

  eventData: any = this.router.queryParams;
  chatDetails: any;
  email: any;

  constructor(private router: ActivatedRoute, private route: Router, private mainService: MainService,
    private session: SessionService) { }

  ngOnInit(): void {
    this.chatDetails = this.eventData['_value']
    this.email = this.session.getSession('email')
    var role = this.session.getSession('roles')
    if (this.email) {
      if (this.email == this.chatDetails.email) {
        if (role == 'ARTIST' || role == 'PENDING ARTIST') {
          this.route.navigate(['/ArtistDashboard/message'], { queryParams: { id: this.chatDetails.id, name: this.chatDetails.name } })
        }
        else if (role == 'AUDIENCE') {
          this.route.navigate(['/audienceDashboard/message'], { queryParams: { id: this.chatDetails.id, name: this.chatDetails.name } })
        }
      }
      else if (this.email != this.chatDetails.email) {
        this.route.navigate(['/login'], { queryParams: { chatId: this.chatDetails.id, name: this.chatDetails.name, email: this.chatDetails.email } })
      }
    }
    else {
      this.route.navigate(['/login'], { queryParams: { chatId: this.chatDetails.id, name: this.chatDetails.name, email: this.chatDetails.email } })
    }
  }

}
