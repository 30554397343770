<div class="joinpage">
    <div class="joinhead">
        <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg" routerLink="" class="pointer">
        <div class="nav-item dropdown hgne" style="top: -2px;display: flex; float: right;">
          <a class="nav-link dropdown-toggle" (click)="logOut = !logOut" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <img src="assets/dashboard/Mask Group.png">
          </a>
          <div *ngIf="logOut" class="" style="position: absolute;left: -63px;top: 41px;; border: 1px solid white; border-radius: 15px; background: black;" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item pointer" style="color: white;background: none;" (click)="logoutUser()"  ><i class="fa fa-sign-out"
                aria-hidden="true" ></i> Log Out</a>
          </div>
        </div>
    </div>
  </div>
  <div class="joinbody">
    <div class="container ge">
      <div class="">
        <div class="">
          <div class="row">
            <div class="col-md-6 col-sm-12 col-xl-6">
              <div class="joinleft">
                <app-slider></app-slider>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xl-6 ">
              <div class="row ">
                <div class="col-md-10">
                  <div class="wi-4">
                    <app-tabsall [activeTab]="2"></app-tabsall>
                    <!-- <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <div class="liner"></div>
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-general-tab" data-toggle="pill"
                                            href="#pills-general" role="tab" aria-controls="pills-general"
                                            aria-selected="true"><span class="round-tabs">
                                                1
                                            </span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-essentials-tab" data-toggle="pill" routerLink='/essential'
                                            href="#pills-essentials" role="tab" aria-controls="pills-essentials"
                                            aria-selected="false"><span class="round-tabs">
                                                2
                                            </span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-background-tab" data-toggle="pill"
                                            href="#pills-background" role="tab" aria-controls="pills-background"
                                            aria-selected="false"><span class="round-tabs">
                                                3
                                            </span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-productioncapabilities-tab" data-toggle="pill"
                                            href="#pills-productioncapabilities" role="tab"
                                            aria-controls="pills-productioncapabilities" aria-selected="false"><span
                                                class="round-tabs">
                                                4
                                            </span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-media-tab" data-toggle="pill" href="#pills-media"
                                            role="tab" aria-controls="pills-media" aria-selected="false"><span
                                                class="round-tabs">
                                                5
                                            </span></a>
                                    </li>
                                </ul> -->
                    <div class="tab-content" id="pills-tabContent">
                      <form class="ghbt" [formGroup]="essentialForm">
                        <div class="form-group">
                          <label style="padding-bottom: 2px;">Instruments Played</label>
                          <mat-form-field class="example-mat-chip-lis gen" appearance="fill">
                            <mat-chip-list #instChipList>
                              <mat-chip *ngFor="let instru of searchInstuments" (removed)="remove(instru)">
                                {{instru}}
                                <button matChipRemove>
                                  <mat-icon>cancel</mat-icon>
                                </button>
                              </mat-chip>
                              <input style="border: none; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                                #instInput [formControl]="instumentCtrl" [matAutocomplete]="autoinst"
                                [matChipInputFor]="instChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCode"
                                (matChipInputTokenEnd)="add($event)">
                            </mat-chip-list>
                            <mat-autocomplete #autoinst="matAutocomplete" (optionSelected)="selected($event)">
                              <mat-option *ngFor="let instru of instrumentList" [value]="instru">
                                {{instru}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                          <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start typing to select your instruments</small>
                        </div>
                        <div class="form-group">
                          <label class="text-white">Genres Played</label>
                          <mat-form-field class="example-chip-list gen" appearance="fill">
                            <mat-chip-list #chipList>
                              <mat-chip *ngFor="let genres of searchgen" (removed)="removegen(genres)">
                                {{genres}}
                                <button matChipRemove>
                                  <mat-icon>cancel</mat-icon>
                                </button>
                              </mat-chip>
                              <input
                                style="border: none; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                                #genInput [formControl]="genCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="addgen($event)">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedgen($event)">
                              <mat-option *ngFor="let gen of genList" [value]="gen">
                                {{gen}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                  <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Start typing to select your genres</small>
                </div>
                        <div class="form-group hgni">
                          <label class="text-white">Act/Band Size</label>
                          <mat-form-field appearance="outline">
                            <mat-select formControlName="bandSizes" multiple>
                              <mat-option *ngFor="let bandsize of bandSize" [value]="bandsize">{{bandsize}}</mat-option>
                            </mat-select>
                            <div
                              *ngIf="essentialForm.controls['bandSizes'].touched && !essentialForm.controls['bandSizes'].valid">
                              <span *ngIf="essentialForm.controls['bandSizes'].hasError('required')">
                                This Field is required
                              </span>
                            </div>
                          </mat-form-field>
                  <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">Enter your band size</small>
                </div>
                        <div class="form-group notR">
                          <label >Notable musicians you’ve worked with</label>
                          <mat-form-field class="example-chip-list gen" appearance="fill">
                            <mat-chip-list #chipListveg aria-label="Fruit selection">
                              <mat-chip *ngFor="let vegtable of searchveg" (removed)="removes(vegtable)">
                                {{vegtable}}
                                <button matChipRemove>
                                  <mat-icon>cancel</mat-icon>
                                </button>
                              </mat-chip>
                              <input [matChipInputFor]="chipListveg"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodesveg"
                                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="adds($event)"
                                formControlName="notableMusicians">
                            </mat-chip-list>
                          </mat-form-field>
                  <img src="assets/dashboard/war.svg"><small class="pl-2" style="color: white; font-size: 10px;">To add a name, type and hit enter in the notable musicians to work with</small>
                </div>
                        <div class="form-group">
                          <label class="fee-range-label" for="username">Fee Range $</label>
                          <div class="row">
                            <div class="col-5 ">
                              <label for="for" class="lbf arrow">From</label>
                              <input type="number" formControlName="feeRangeFrom" class="ge-fk">
                              <span style="color: #ff0000bd"
                              *ngIf="essentialForm.controls['feeRangeFrom'].touched && !essentialForm.controls['feeRangeFrom'].valid">
                              <span *ngIf="essentialForm.controls['feeRangeFrom'].hasError('required')">
                                This Field is required
                              </span>
                            </span>
                            </div>
                            <div class="col-5 p_d">
                              <label for="to" class="lbf">To</label>
                              <input type="number" class="ge-fk" formControlName="feeRangeTo">
                              <span style="color: #ff0000bd"
                              *ngIf="essentialForm.controls['feeRangeTo'].touched && !essentialForm.controls['feeRangeTo'].valid">
                              <span *ngIf="essentialForm.controls['feeRangeTo'].hasError('required')">
                                This Field is required
                              </span>
                            </span>
                            </div>
                            <div class="col-2 fee-hr-wrapper">
                              <p class="fee-price-hr">
                                /hr
                              </p>
                            </div>
                          </div>

                        </div>
                        <div class="form-group fm-bt ghtw">
                          <div class="row">
                            <div class="col p_d">
                              <button type="submit" class="btn btn-outline-primary  btn-block"
                                [routerLink]="['/general']">Back</button>
                            </div>
                            <div class="col p_d">
                              <button type="submit" class="btn btn-primary submit btn-block"
                                (click)="essentialsDetails()">
                                <ng-container *ngIf="essentailFormSubmitting; else essentailFormSubmittingDone">
                                  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...
                                </ng-container>
                                <ng-template #essentailFormSubmittingDone>
                                  Next
                                </ng-template>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
