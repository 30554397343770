import { Component, OnInit } from '@angular/core';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';

declare const Swiper: any;
SwiperCore.use([Autoplay, Pagination, Navigation]);

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  images = [
    {
      profileUrl: '../../../assets/mainSliderImg/slide1.webp',
    },
    {
      profileUrl: '../../../assets/mainSliderImg/slide-2.webp',
    },
    {
      profileUrl: '../../../assets/mainSliderImg/slide 3.webp',
    },
    {
      profileUrl: '../../../assets/mainSliderImg/slide 4.webp',
    },
    {
      profileUrl: '../../../assets/mainSliderImg/slider-5_1.webp',
    },
    {
      profileUrl: '../../../assets/mainSliderImg/slide 6.webp',
    },
    {
      profileUrl: '../../../assets/mainSliderImg/slide 7.webp',
    },
    {
      profileUrl: '../../../assets/mainSliderImg/slide 8.webp',
    },
    {
      profileUrl: '../../../assets/mainSliderImg/slide 9.webp',
    },
    {
      profileUrl: '../../../assets/mainSliderImg/slide 10.webp',
    },
    {
      profileUrl: '../../../assets/mainSliderImg/slide 11.webp',
    },
    {
      profileUrl: '../../../assets/mainSliderImg/slide 12.webp',
    },
    {
      profileUrl: '../../../assets/mainSliderImg/slide 13.webp',
    },
    {
      profileUrl: '../../../assets/mainSliderImg/slide-14.webp',
    },
    {
      profileUrl: './../../../assets/mainSliderImg/slide 15.webp',
    },
  ];

  constructor() {}

  ngOnInit(): void { }
}
