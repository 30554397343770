import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AudienceHomeComponent } from './audience-home/audience-home.component';
import { HomeComponent } from './home/home.component';
import { EventsComponent } from './audience-home/events/events.component';
import { MessagesComponent } from './audience-home/messages/messages.component';
import { DetailsComponent } from './audience-home/details/details.component';
import { EditComponent } from './audience-home/details/edit/edit.component';
import { DetailComponent } from './home/details/details.component';
import { EventComponent } from './home/event/event.component';
import { EarningComponent } from './home/earning/earning.component';
import { ArtistEditComponent } from './home/details/artist-edit/artist-edit.component';
import { DetailHomeComponent } from './audience-home/detail-home/detail-home.component';
import { ArtistHomeDetailComponent } from './home/artist-home-detail/artist-home-detail.component';
import { ArtistSeeAllComponent } from './home/artist-home-detail/artist-see-all/artist-see-all.component';
import { ArtistPreComponent } from './home/artist-home-detail/artist-pre/artist-pre.component';
import { ArtistPendingComponent } from './home/artist-pending/artist-pending.component';
import { ArtistPendingSeeAllComponent } from './home/artist-pending/artist-pending-see-all/artist-pending-see-all.component';
import { ArtistPendingStaffPickComponent } from './home/artist-pending/artist-pending-staff-pick/artist-pending-staff-pick.component';
import { SimpleSearchComponent } from './home/simple-search/simple-search.component';
import { AdvancedSearchComponent } from './home/advanced-search/advanced-search.component';
import { AudienceSimpleSearchComponent } from './audience-home/audience-simple-search/audience-simple-search.component';
import { AudienceAdvancedSearchComponent } from './audience-home/audience-advanced-search/audience-advanced-search.component';
import { AuthGuardService } from './../../../service/guard/auth-guard.service';
import { DraftsComponent } from './audience-home/drafts/drafts.component';
import { EventDetailsComponent } from './audience-home/events/event-details/event-details.component';
import { InviteArtistComponent } from './audience-home/events/invite-artist/invite-artist.component';
import { GigBoardComponent } from './home/gig-board/gig-board.component';
import { ArtistEventDetailsComponent } from './home/event/artist-event-details/artist-event-details.component';
import { ArtistDraftsComponent } from './home/artist-drafts/artist-drafts.component';
import { ArtistInvitationComponent } from './home/event/artist-invitation/artist-invitation.component';
import { AudienceCalenderComponent } from './audience-home/audience-calender/audience-calender.component';
import { ArtistSeeALLNotificationComponent } from './home/artist-see-allnotification/artist-see-allnotification.component';
import { AudienceSeeAllNotificationComponent } from './audience-home/audience-see-all-notification/audience-see-all-notification.component';
import { ArtistCalenderComponent } from './home/artist-calender/artist-calender.component';
import { GeneralComponent } from '../../after-login/artist-general/general.component';
import { EssentialsComponent } from '../../after-login/artist-essentials/essentials.component';
import { BackgroundDetailComponent } from '../../after-login/artist-background-detail/background-detail.component';
import { ProductionComponent } from '../../after-login/artist-production/production.component';
import { MediaComponent } from '../../after-login/artist-media/media.component';
import { ArtistLiveStreamingComponent } from './home/event/artist-live-streaming/artist-live-streaming.component';
import { AudienceLiveStreamingComponent } from './audience-home/events/audience-live-streaming/audience-live-streaming.component';
import { ProReportingComponent } from './home/pro-reporting/pro-reporting.component';
import { ArtistInviteComponent } from './home/artist-invite/artist-invite.component';
import { InviteGuestComponent } from './audience-home/invite-guest/invite-guest.component';
import { AudienceEarningsComponent } from './audience-home/audience-earnings/audience-earnings.component';
import { BillingComponent } from './home/billing/billing.component';
import { ArtistWatchTestStreamComponent } from './home/artist-watch-test-stream/artist-watch-test-stream.component';
import { AudienceWatchTestStreamComponent } from './audience-home/audience-watch-test-stream/audience-watch-test-stream.component';


const routes: Routes = [
  {
    path: 'ArtistDashboard',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'myProfile',
        pathMatch: 'full',
      },
      {
        path: 'artistHome',
        component: ArtistHomeDetailComponent,
      },
      {
        path: 'pendingArtistHome',
        component: ArtistPendingComponent,
      },
      {
        path: 'artistSeeAll',
        component: ArtistSeeAllComponent,
      },
      {
        path: 'staffSeeAll',
        component: ArtistSeeAllComponent,
      },
      {
        path: 'artistPendingSeeAll',
        component: ArtistPendingSeeAllComponent,
      },
      {
        path: 'artistPendingStaffSeeAll',
        component: ArtistPendingStaffPickComponent,
      },
      {
        path: 'simpleSearch',
        component: SimpleSearchComponent,
      },
      {
        path: 'advancedSearch',
        component: AdvancedSearchComponent,
      },
      {
        path: 'favArtist',
        component: ArtistSeeAllComponent,
      },
      {
        path: 'artistPre',
        component: ArtistPreComponent,
      },
      {
        path: 'myProfile',
        component: DetailComponent,
      },
      {
        path: 'message',
        component: MessagesComponent,
      },
      {
        path: 'event',
        component: EventComponent,
      },
      {
        path: 'artistEventDetail',
        component: ArtistEventDetailsComponent,
      },
      {
        path: 'artistLiveStreaming',
        component: ArtistLiveStreamingComponent,
      },
      {
        path: 'watchLive',
        component: AudienceLiveStreamingComponent,
      },
      {
        path: 'artistInvitation',
        component: ArtistInvitationComponent,
      },
      {
        path: 'earnings',
        component: EarningComponent,
      },
      {
        path: 'billing',
        component: BillingComponent,
      },
      {
        path: 'edit',
        component: ArtistEditComponent,
      },
      {
        path: 'gigBoard',
        component: GigBoardComponent,
      },
      {
        path: 'artistDraft',
        component: ArtistDraftsComponent,
      },
      {
        path: 'artistSeeAllNotification',
        component: ArtistSeeALLNotificationComponent,
      },
      {
        path: 'artistCalender',
        component: ArtistCalenderComponent,
      },
      {
        path: 'proReporting/:id',
        component: ProReportingComponent,
      },
      {
        path: 'artistInvite',
        component: ArtistInviteComponent,
      },
      {
        path: 'watchTestStream',
        component: ArtistWatchTestStreamComponent,
      },
    ],
  },
  {
    path: 'audienceDashboard',
    component: AudienceHomeComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'details',
        pathMatch: 'full',
      },
      {
        path: 'details',
        component: DetailsComponent,
      },
      {
        path: 'audienceHome',
        component: DetailHomeComponent,
      },
      {
        path: 'seeAllArtist',
        component: ArtistSeeAllComponent,
      },
      {
        path: 'audienceSimpleSearch',
        component: AudienceSimpleSearchComponent,
      },
      {
        path: 'audienceAdvancedSearch',
        component: AudienceAdvancedSearchComponent,
      },
      {
        path: 'audienceFavSearch',
        component: ArtistSeeAllComponent,
      },
      {
        path: 'seeAllStaff',
        component: ArtistSeeAllComponent,
      },
      {
        path: 'message',
        component: MessagesComponent,
      },
      {
        path: 'event',
        component: EventsComponent,
      },
      {
        path: 'billings',
        component: AudienceEarningsComponent,
      },
      {
        path: 'eventPreview',
        component: EventDetailsComponent,
      },
      {
        path: 'audienceLiveStreaming',
        component: AudienceLiveStreamingComponent,
      },
      {
        path: 'edit',
        component: EditComponent,
      },
      {
        path: 'audienceDrafts',
        component: DraftsComponent,
      },
      {
        path: 'inviteArtist',
        component: InviteArtistComponent,
      },
      {
        path: 'calender',
        component: AudienceCalenderComponent,
      },
      {
        path: 'audienceSeeAllNotification',
        component: AudienceSeeAllNotificationComponent,
      },
      {
        path: 'Invite-Guests',
        component: InviteGuestComponent,
      },
      {
        path: 'watchteststream',
        component: AudienceWatchTestStreamComponent,
      },
    ],
  },

  {
    path: 'general',
    component: GeneralComponent,
  },
  {
    path: 'essential',
    component: EssentialsComponent,
  },
  {
    path: 'background',
    component: BackgroundDetailComponent,
  },
  {
    path: 'production',
    component: ProductionComponent,
  },
  {
    path: 'media',
    component: MediaComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AudienceRoutingModule {}
