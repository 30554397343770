import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CryptoService } from 'src/app/service/helper/crypto.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { RefreshService } from 'src/app/service/refresh.service';
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";

declare const Swiper: any;
SwiperCore.use([Autoplay, Pagination, Navigation]);
@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.scss']
})
export class JoinUsComponent implements OnInit {
  showModal: any;
  showverifyEmail: boolean = false;

  constructor(
    private _CryptoService: CryptoService,
    private activatederoute: ActivatedRoute,
    private sessionService: SessionService,
    private router: Router,
    private _refresh: RefreshService
  ) { }

  ngOnInit(): void {
    if (this.activatederoute.snapshot && this.activatederoute.snapshot.queryParams && this.activatederoute.snapshot.queryParams.showModal) {
      this.showModal = this._CryptoService.decrypt(this.activatederoute.snapshot.queryParams.showModal);
    }
    if (this.showModal) {
      this.showverifyEmail = true;
    }
    let userId = this.sessionService.getSession('userId');
    let role = this.sessionService.getSession('roles');
    let stayLoggedIn = this.sessionService.getSession('stayLoggedIn');
    let profileAdded = this.sessionService.getSession('profileAdded');
    if (userId && role && stayLoggedIn == 'true') {
      this._refresh.refreshToken().then(data => {
        if (data && data.isSuccess) {
          // this.newToken = data.data;
          this.sessionService.removeSession('token');
          this.sessionService.setSessions({ token: data.data });
        } else { }
      })
      if ((role == 'ARTIST' || role == 'PENDING ARTIST') && profileAdded == 'true')
        this.router.navigate(['ArtistDashboard/artistHome']);
      else if (role == 'PENDING ARTIST' && profileAdded == 'false')
        this.router.navigate(['general']);
      else if (role == 'AUDIENCE' && profileAdded == 'false')
        this.router.navigate(['/audienceGeneral']);
      else if (role == 'AUDIENCE' && profileAdded == 'true')
        this.router.navigate(['audienceDashboard/audienceHome']);
    }
  }



}
