<!-- Header Code -->
<nav class="navbar navbar-expand-md navbar-dark fixed-top tp-hd bg-dark-blck">
  <a class="navbar-brand sxaw">
    <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg" />
  </a>
  <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse"
    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="navbar-collapse collapse" id="navbarCollapse">
    <ul class="navbar-nav ml-auto ul-im">
      <li class="nav-item nbhg active">
        <div *ngIf="searchBox" class="form-group has-search input-group">
          <input type="text" class="form-control" placeholder="Search Artist Name" [(ngModel)]="searchkey"
            (keyup.enter)="searchPublicArtistProfile($event)" />
          <div class="input-group-append">
            <button class="btn btn-secondary gftrj" type="button" (click)="searchBoxController()">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        <img *ngIf="!searchBox" style="cursor: pointer; width: 28px" src="assets/dashboard/Vector (1).svg"
          (click)="searchBoxController()" />
      </li>
    </ul>
  </div>
</nav>
<!-- Profile background image -->
<div *ngIf="mainTab == 'userProfile'">
  <div class="container mt-5 pt-4">
    <div class="row">
      <div class="col-12">
        <img src="../../../../assets/publicProfileBg.png" style="width: 100%; height: 210px;">
      </div>
    </div>
    <div class="row mx-2">
      <div class="col-2" style="position: relative; top: -75px;">
        <div class="profile-img-wrapper">
          <img
            [src]="userProfileData?.profilePicUrl ? userProfileData?.profilePicUrl : 'assets/artist/Rectangle 30 (1).png'"
            onerror="this.src='assets/artist/Rectangle 30 (1).png'" Style="object-fit:'fill'" alt="" class="img-fluid">
        </div>
      </div>
      <div class="col-7 mt-2">
        <h4> {{userProfileData?.displayName}} </h4>
        <span *ngFor="let location of userProfileData?.locations">
          <span> {{location}} </span>
        </span>
      </div>
      <div class="col-3 mt-2 pl-5">
        <button type="submit"
          class="btn defrqqa edcae ml-5" (click)="shareProfile = !shareProfile"><i class="fa fa-share-alt pr-2"></i>Share
          Profile
        </button>
        <div *ngIf="shareProfile" class="fkui" style="border: 1px solid white; border-radius: 15px; width: 75%; right: -50px; position: relative; top: 5px;">
          <p class="my-1 pl-3" style="cursor: pointer;" [socialShare]="'fb'">
            <a class="facebook pr-3"><i class="fa fa-facebook"></i></a> Facebook
          </p>
          <p class="my-1 pl-3" [socialShare]="'tw'" style="border-top: 1px solid white; border-bottom: 1px solid white; cursor: pointer;">
            <a class="twitter pr-3"><i class="fa fa-twitter"></i></a> Twitter
          </p>
          <p class="my-1 pl-3" style="cursor: pointer;" ngxClipboard [cbContent]="url+'/userProfile?userId='+userId" (click)="linkCopied()">
          <i class="fa fa-link pr-3"></i> Copy Link
          </p>
        </div>
        <!-- <button type="submit" class="btn hftrdd">Send A Message</button> -->
      </div>

    </div>
  </div>
  <div class="container">
    <div class="row" style="border-bottom: 1px solid #565151;">
      <div class="col-1 pl-0 text-center">
        <h6 class="mb-0" style="cursor: pointer;" [ngClass]="tabName == 'about' ? 'border2px' : ''"
          (click)="tabName = 'about'">About</h6>
      </div>
      <!-- <div class="col-1">
        <p class="mb-0" style="cursor: pointer;" [ngClass]="tabName == 'videos' ? 'border2px' : ''"
          (click)="tabName = 'videos'">Videos</p>
      </div>
      <div class="col-10">
        <p class="mb-0" [ngClass]="tabName == 'images' ? 'border2px' : ''" (click)="tabName = 'images'"
          style="width: 65px; cursor: pointer;">Images</p>
      </div> -->
    </div>
  </div>
  <div *ngIf="tabName == 'about'" class="container mt-3">
    <div class="row">
      <div class="col-3">
        <p class="mb-1">Ratings</p>
        <div class="deft rating">
          <!-- <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating> -->
        </div>
      </div>
      <div class="col-3">
        <p class="mb-1">Number Of Events</p>
        <p class="mb-1"> {{userProfileData?.eventCount}} </p>
      </div>
      <div class="col-6">
        <p class="mb-1">Price</p>
        <p class="mb-1">${{userProfileData?.feeRangeFrom}} - ${{userProfileData?.feeRangeTo}} / Hour</p>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-12">
        <h5>My Career in Summary</h5>
        <p class="text-justify"> {{userProfileData?.careerSummary}} </p>
      </div>
      <div class="col-12">
        <h5>My First Musical Memory</h5>
        <p class="text-justify"> {{userProfileData?.musicalMemory}} </p>
      </div>
      <div class="col-12">
        <h5>What I Love About Music</h5>
        <p class="text-justify"> {{userProfileData?.loveAboutMusic}} </p>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <h6>Years of Experience</h6>
        <p> {{userProfileData?.experience}} Year</p>
        <h6>Act/Band Size</h6>
        <span class="pb-3" *ngFor="let bandSize of userProfileData?.bandSizes">
          <span> {{bandSize}}, </span>
        </span>
        <!-- <p> {{userProfileData?.experience}} Members</p> -->
        <h6>Production Level</h6>
        <p> Basic </p>
        <h6>Public Videos Link</h6>
        <p> {{userProfileData?.publicVideosLinks}} </p>
      </div>
      <div class="col-6">
        <h6>Favorite Genres</h6>
        <span class="pb-3" *ngFor="let genres of userProfileData?.favouriteGenres">
          <span> {{genres}}, </span>
        </span>
        <h6>Instruments Played</h6>
        <span class="pb-3" *ngFor="let instu of userProfileData?.instrumentsPlayed">
          <span> {{instu}}, </span>
        </span>
        <h6>Most Influential Artists</h6>
        <p> {{userProfileData?.influentialArtists}} </p>
        <h6>Social Video Link</h6>
        <p> {{userProfileData?.socialVideosLinks}} </p>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12">
        <h4>Certifications</h4>
      </div>
      <div class="col-3">
        <p class="mb-0">Music Production (123456)</p>
        <small>Berklee Online</small>
      </div>
      <div class="col-3">May-2019 - Dec 2020</div>
      <div class="col-6"><i class="fa fa-file-image-o"></i> View Certificate</div>
    </div>
  </div>
  <!-- <div *ngIf="tabName == 'videos'" class="container mt-3">
    <div class="row">
      <div class="col-1">
        <i class="fa fa-ban fa-4x" style="color: red;"></i>
      </div>
      <div class="col-11">
        <p class="text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco... Lorem ipsum
          dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco...</p>
      </div>
    </div>
    <div class="row meraww mb-5 ">
      <div class="col-12">
        <div class="row">
          <div class="col-4 p-0" style="border: 1px solid rgb(41, 40, 40); height: 220px;"
            *ngFor="let video of userProfileData?.videoUrls">
            <video id="myVideo" width="360" height="220" style="cursor: pointer;">
              <source controls="hidden" [src]="video" type="video/mp4">
            </video>
            <img src="../../../../assets/videoLock.png " alt="" [routerLink]="'/'"
              style="position: relative; top: -187px; left: 109px;">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="tabName == 'images'" class="container mt-3">
    <div class="row">
      <div class="col-1">
        <i class="fa fa-ban fa-4x" style="color: red;"></i>
      </div>
      <div class="col-11">
        <p class="text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco... Lorem ipsum
          dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco...</p>
      </div>
    </div>
    <div class="row meraww mb-5">
      <div class="col-12">
        <div class="row">
          <div class="col-4 p-0" style="border: 1px solid rgb(41, 40, 40); height: 220px;"
            *ngFor="let image of userProfileData?.images">
            <img [src]="image" style="cursor: pointer;" alt="" width="360" height="220">
            <img src="../../../../assets/imageLock.png " alt="" [routerLink]="'/'"
              style="position: relative; top: -165px; left: 150px;">
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>

