import { StreamService } from "src/app/service/stream.service.js"
import { PeerStats } from "./peer_stats"
import { WebSocketAdaptor } from "./websocket_adaptor"

export class WebRTCAdaptor {
  peerconnection_config: any;
  sdp_constraints: any;
  remotePeerConnection = new Array();
  remotePeerConnectionStats = new Array();
  remoteDescriptionSet = new Array();
  iceCandidateList = new Array();
  roomName: any;
  videoTrackSender: any;
  audioTrackSender: any;
  playStreamId = new Array();
  currentVolume: any;
  audioContext: any;
  soundOriginGainNode: any;
  secondStreamGainNode: any;
  localStream: any;
  bandwidth = 900;
  isMultiPeer = false;
  multiPeerStreamId: any;
  isWebSocketTriggered = false;
  webSocketAdaptor: any;
  isPlayMode = false;
  debug = false;
  publishMode = "camera";
  candidateTypes = ["udp", "tcp"];
  camera_location = "top";
  desktopStream: any;
  camera_margin = 15;
  camera_percent = 15;
  localVideo: any;
  remoteVideo: any;
  localVideoId: any;
  remoteVideoId: any;
  mediaConstraints: any;
  mode: any;
  callback: any;
  callbackError: any;
  adapter: any;
  audioStream: any;
  cameraStream: any;
  websocket_url: any;
  constructor(initialValues: any,
    private _StreamService: StreamService
  ) {
    for (var key in initialValues) {
      if (initialValues.hasOwnProperty(key)) {
        (this as any)[key] = initialValues[key];
      }
    }
    this.webSocketAdaptor = new WebSocketAdaptor({ websocket_url: this.websocket_url, webrtcadaptor: this, callback: this.callback, callbackError: this.callbackError, debug: this.debug });
    this.localVideo = document.getElementById(this.localVideoId);
    this.remoteVideo = document.getElementById(this.remoteVideoId);

    // It should be compatible with previous version
    if (this.mediaConstraints?.video == "camera") {
      this.publishMode = "camera";
    }
    else if (this.mediaConstraints?.video == "screen") {
      this.publishMode = "screen";
    }
    else if (this.mediaConstraints?.video == "screen+camera") {
      this.publishMode = "screen+camera";
    }


    if (!this.isPlayMode && typeof this.mediaConstraints != "undefined" && this.localStream == null) {
      // this.checkWebRTCPermissions();
      //Check browser support for screen share function
      // this.checkBrowserScreenShareSupported();

      // Get devices only in publish mode.
      // this.getDevices();
      // this.trackDeviceChange();

      // if (typeof this.mediaConstraints.video != "undefined" && this.mediaConstraints.video != false) {
      //   this.openStream(this.mediaConstraints, this.mode);
      // }
      // else {
      //   // get only audio
      //   var media_audio_constraint = { audio: this.mediaConstraints.audio };
      //   this.navigatorUserMedia(media_audio_constraint, (stream: any) => {
      //     this.gotStream(stream);
      //   }, true)
      // }
    }
    else {
      //just playing, it does not open any stream
      if (this.webSocketAdaptor == null || this.webSocketAdaptor.isConnected() == false) {
        this.webSocketAdaptor = new WebSocketAdaptor({ websocket_url: this.websocket_url, webrtcadaptor: this, callback: this.callback, callbackError: this.callbackError, debug: this.debug });

      }
    }
  }


  setDesktopwithCameraSource(stream: any, streamId: any, audioStream: any, onEndedCallback: any) {
    this.desktopStream = stream;
    this.navigatorUserMedia({ video: true, audio: false }, (cameraStream: any) => {

      //create a canvas element
      var canvas = document.createElement("canvas");
      var canvasContext = canvas.getContext("2d");

      //create video element for screen
      //var screenVideo = document.getElementById('sourceVideo');
      var screenVideo = document.createElement('video');

      screenVideo.srcObject = stream;
      screenVideo.play();
      //create video element for camera
      var cameraVideo = document.createElement('video');

      cameraVideo.srcObject = cameraStream;
      cameraVideo.play();
      // var canvasStream = canvas.captureStream(15);

      if (this.localStream == null) {
        // this.gotStream(this.canvasStream);
      }
      else {
        this.updateVideoTrack(null, streamId, this.mediaConstraints, onended, null);
      }
      if (onEndedCallback != null) {
        stream.getVideoTracks()[0].onended = function (event: any) {
          onEndedCallback(event);
        }
      }

      //update the canvas
      setInterval(() => {
        //draw screen to canvas
        canvas.width = screenVideo.videoWidth;
        canvas.height = screenVideo.videoHeight;
        // canvasContext.drawImage(screenVideo, 0, 0, canvas.width, canvas.height);

        var cameraWidth = screenVideo.videoWidth * (this.camera_percent / 100);
        var cameraHeight = (cameraVideo.videoHeight / cameraVideo.videoWidth) * cameraWidth

        var positionX = (canvas.width - cameraWidth) - this.camera_margin;
        var positionY;

        if (this.camera_location == "top") {
          positionY = this.camera_margin;
        }
        else { //if not top, make it bottom
          //draw camera on right bottom corner
          positionY = (canvas.height - cameraHeight) - this.camera_margin;
        }
        // canvasContext.drawImage(cameraVideo, positionX, positionY, cameraWidth, cameraHeight);
      }, 66);
    }, true)
  }
  trackDeviceChange() {
    navigator.mediaDevices.ondevicechange = () => {
      this.getDevices();
    }
  }

  getDevices() {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      let deviceArray = new Array();
      let checkAudio = false
      devices.forEach(device => {
        if (device.kind == "audioinput" || device.kind == "videoinput") {
          deviceArray.push(device);
          if (device.kind == "audioinput") {
            checkAudio = true;
          }
        }
      });
      this.callback("available_devices", deviceArray);
      if (checkAudio == false && this.localStream == null) {
        this.openStream({ video: true, audio: false }, this.mode)
      }
    }).catch(err => {
      console.error("Cannot get devices -> error name: " + err.name + ": " + err.message);
    });
  }

  prepareStreamTracks(mediaConstraints: any, audioConstraint: any, stream: any, streamId: any) {
    //this trick, getting audio and video separately, make us add or remove tracks on the fly
    var audioTrack = stream.getAudioTracks()
    if (audioTrack.length > 0 && this.publishMode == "camera") {
      audioTrack[0].stop();
      stream.removeTrack(audioTrack[0]);
    }
    //now get only audio to add this stream
    if (audioConstraint != "undefined" && audioConstraint != false) {
      var media_audio_constraint = { audio: audioConstraint };
      this.navigatorUserMedia(media_audio_constraint, (audioStream: any) => {

        audioStream = this.setGainNodeStream(audioStream);

        //add callback if desktop is sharing
        var onended = (event: any) => {
          this.callback("screen_share_stopped");
          this.setVideoCameraSource(streamId, mediaConstraints, null, true);
        }

        if (this.publishMode == "screen") {
          this.updateVideoTrack(stream, streamId, mediaConstraints, onended, true);
          if (audioTrack.length > 0) {
            var mixedStream = this.mixAudioStreams(stream, audioStream, streamId);
            this.updateAudioTrack(mixedStream, streamId, null);
          }
          else {
            this.updateAudioTrack(audioStream, streamId, null);
          }
        }
        else if (this.publishMode == "screen+camera") {
          if (audioTrack.length > 0) {
            var mixedStream = this.mixAudioStreams(stream, audioStream, streamId);
            this.updateAudioTrack(mixedStream, streamId, null);
            this.setDesktopwithCameraSource(stream, streamId, mixedStream, onended);
          }
          else {
            this.updateAudioTrack(audioStream, streamId, null);
            this.setDesktopwithCameraSource(stream, streamId, audioStream, onended);
          }
        }
        else {
          if (audioConstraint != false && audioConstraint != undefined) {
            stream.addTrack(audioStream.getAudioTracks()[0]);
          }
          this.gotStream(stream);
        }
      }, true)
    }
    else {
      if (typeof this.audioStream != "undefined" && this.audioStream.getAudioTracks()[0] != null) {
        stream.addTrack(this.audioStream.getAudioTracks()[0]);
      }
      this.gotStream(stream);
    }
  }

  navigatorUserMedia(mediaConstraints: any, func: any, catch_error: any) {
    if (catch_error == true) {
      navigator.mediaDevices.getUserMedia(mediaConstraints).then(func).catch(error => {
        if (error.name == "NotFoundError") {
          this.getDevices()
        } else {
          this.callbackError(error.name, error.message);
        }
      });
    } else {
      navigator.mediaDevices.getUserMedia(mediaConstraints).then(func)
    }
  }

  /**
   * Get user media
   */
  getUserMedia(mediaConstraints: any, audioConstraint: any, streamId: any) {
    // Check Media Constraint video value screen or screen + camera
    if (this.publishMode == "screen+camera" || this.publishMode == "screen") {
      (window.navigator.mediaDevices as any).getDisplayMedia(mediaConstraints)
        .then((stream: any) => {
          this.prepareStreamTracks(mediaConstraints, audioConstraint, stream, streamId);

        })
        .catch((error: any) => {
          if (error.name === "NotAllowedError") {
            console.debug("Permission denied error");
            this.callbackError("ScreenSharePermissionDenied");

            // Redirect Default Stream Camera
            if (this.localStream == null) {

              var mediaConstraints = {
                video: true,
                audio: true
              };

              this.openStream(mediaConstraints, null);
            }
            else {
              this.switchVideoCameraCapture(streamId, null);
            }

          }
        });
    }
    // If mediaConstraints only user camera
    else {
      this.navigatorUserMedia(mediaConstraints, ((stream: any) => {
        this.prepareStreamTracks(mediaConstraints, audioConstraint, stream, streamId);
      }), true);
    }
  }

  /**
   * Open media stream, it may be screen, camera or audio
   */
  openStream(mediaConstraints: any, mode: any) {
    this.mediaConstraints = mediaConstraints;
    var audioConstraint = false;
    if (typeof mediaConstraints.audio != "undefined" && mediaConstraints.audio != false) {
      audioConstraint = mediaConstraints.audio;
    }

    if (typeof mediaConstraints.video != "undefined") {
      this.getUserMedia(mediaConstraints, audioConstraint, null);
    }
    else {
      console.error("MediaConstraint video is not defined");
      this.callbackError("media_constraint_video_not_defined");
    }
  }

  /**
   * Closes stream, if you want to stop peer connection, call stop(streamId)
   */
  closeStream() {
    this.localStream.getVideoTracks().forEach(function (track: any) {
      track.onended = null;
      track.stop();
    });

    this.localStream.getAudioTracks().forEach(function (track: any) {
      track.onended = null;
      track.stop();
    });
  }
  /*
  * Checks if we is permitted from browser
  */
  checkWebRTCPermissions() {
    if (!("WebSocket" in window)) {

      this.callbackError("WebSocketNotSupported");
      return;
    }

    if (typeof navigator.mediaDevices == "undefined" && this.isPlayMode == false) {

      this.callbackError("UnsecureContext");
      return;
    }
    if (typeof navigator.mediaDevices == "undefined" || navigator.mediaDevices == undefined || navigator.mediaDevices == null) {
      this.callbackError("getUserMediaIsNotAllowed");
    }
  }

  /**
   * Checks browser supports screen share feature
   * if exist it calls callback with "browser_screen_share_supported"
   */

  checkBrowserScreenShareSupported() {
    // if ((typeof navigator.mediaDevices != "undefined" && navigator.mediaDevices.getDisplayMedia) || navigator.getDisplayMedia) {
    //   this.callback("browser_screen_share_supported");
    // }
  };

  enableSecondStreamInMixedAudio(enable: any) {

    if (this.secondStreamGainNode != null) {
      if (enable) {
        this.secondStreamGainNode.gain.value = 1;
      }
      else {
        this.secondStreamGainNode.gain.value = 0;
      }
    }
  }

  publish(streamId: any, stream: any, token: any, subscriberId: any, subscriberCode: any) {
    this.localStream = stream;
    //If it started with playOnly mode and wants to publish now
    if (this.localStream == null) {
      this.navigatorUserMedia(this.mediaConstraints, ((stream: any) => {
        this.gotStream(stream);
        var jsCmd = {
          command: "publish",
          streamId: streamId,
          token: "123",
          subscriberId: typeof subscriberId !== undefined ? subscriberId : "",
          subscriberCode: typeof subscriberCode !== undefined ? subscriberCode : "",
          video: this.localStream.getVideoTracks().length > 0 ? true : false,
          audio: this.localStream.getAudioTracks().length > 0 ? true : false,
        };
        this.webSocketAdaptor.send(JSON.stringify(jsCmd));
      }), false);
    } else {
      var jsCmd = {
        command: "publish",
        streamId: streamId,
        token: "123",
        subscriberId: typeof subscriberId !== undefined ? subscriberId : "",
        subscriberCode: typeof subscriberCode !== undefined ? subscriberCode : "",
        video: this.localStream.getVideoTracks().length > 0 ? true : false,
        audio: this.localStream.getAudioTracks().length > 0 ? true : false,
      };
      this.webSocketAdaptor.send(JSON.stringify(jsCmd));
    }
  }

  joinRoom(roomName: any, streamId: any) {
    this.roomName = roomName;

    var jsCmd = {
      command: "joinRoom",
      room: roomName,
      streamId: streamId,
    }
    this.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }

  play(streamId: any, token: any, roomId: any, enableTracks: any, subscriberId: any, subscriberCode: any) {
    this.playStreamId.push(streamId);
    var jsCmd =
    {
      command: "play",
      streamId: streamId,
      token: token,
      room: roomId,
      trackList: enableTracks,
      subscriberId: typeof subscriberId !== undefined ? subscriberId : "",
      subscriberCode: typeof subscriberCode !== undefined ? subscriberCode : "",
    }

    this.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }

  stop(streamId: any) {
    this.closePeerConnection(streamId);

    var jsCmd = {
      command: "stop",
      streamId: streamId,
    };

    this.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }

  join(streamId: any) {
    var jsCmd = {
      command: "join",
      streamId: streamId,
      multiPeer: this.isMultiPeer && this.multiPeerStreamId == null,
      mode: this.isPlayMode ? "play" : "both",
    };

    this.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }

  leaveFromRoom(roomName: any) {
    this.roomName = roomName;
    var jsCmd = {
      command: "leaveFromRoom",
      room: roomName,
    };

    this.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }

  leave(streamId: any) {
    var jsCmd = {
      command: "leave",
      streamId: this.isMultiPeer && this.multiPeerStreamId != null ? this.multiPeerStreamId : streamId,
    };

    this.webSocketAdaptor.send(JSON.stringify(jsCmd));
    this.closePeerConnection(streamId);
    this.multiPeerStreamId = null;
  }

  getStreamInfo(streamId: any) {
    var jsCmd = {
      command: "getStreamInfo",
      streamId: streamId,
    };
    this.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }

  getRoomInfo(roomName: any, streamId: any) {
    var jsCmd = {
      command: "getRoomInfo",
      streamId: streamId,
      room: roomName,
    };
    this.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }

  enableTrack(mainTrackId: any, trackId: any, enabled: any) {
    var jsCmd = {
      command: "enableTrack",
      streamId: mainTrackId,
      trackId: trackId,
      enabled: enabled,
    };
    this.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }

  getTracks(streamId: any, token: any) {
    this.playStreamId.push(streamId);
    var jsCmd =
    {
      command: "getTrackList",
      streamId: streamId,
      token: token,
    }

    this.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }

  gotStream(stream: any) {
    stream = this.setGainNodeStream(stream);

    this.localStream = stream;
    this.localVideo.srcObject = stream;

    if (this.webSocketAdaptor == null || this.webSocketAdaptor.isConnected() == false) {
      this.webSocketAdaptor = new WebSocketAdaptor({ websocket_url: this.websocket_url, webrtcadaptor: this, callback: this.callback, callbackError: this.callbackError, debug: this.debug });

    }
    this.getDevices();
  }

  switchDesktopCapture(streamId: any) {
    this.publishMode = "screen";

    var audioConstraint = false;
    if (typeof this.mediaConstraints.audio != "undefined" && this.mediaConstraints.audio != false) {
      audioConstraint = this.mediaConstraints.audio;
    }

    this.getUserMedia(this.mediaConstraints, audioConstraint, streamId);
  }

  mixAudioStreams(stream: any, secondStream: any, streamId: any) {
    //console.debug("audio stream track count: " + audioStream.getAudioTracks().length);
    var composedStream = new MediaStream();
    //added the video stream from the screen
    stream.getVideoTracks().forEach(function (videoTrack: any) {
      composedStream.addTrack(videoTrack);
    });

    this.audioContext = new AudioContext();
    var audioDestionation = this.audioContext.createMediaStreamDestination();

    if (stream.getAudioTracks().length > 0) {
      this.soundOriginGainNode = this.audioContext.createGain();

      //Adjust the gain for screen sound
      this.soundOriginGainNode.gain.value = 1;
      var audioSource = this.audioContext.createMediaStreamSource(stream);

      audioSource.connect(this.soundOriginGainNode).connect(audioDestionation);
    }
    else {
      console.debug("Origin stream does not have audio track")
    }

    if (secondStream.getAudioTracks().length > 0) {
      this.secondStreamGainNode = this.audioContext.createGain();

      //Adjust the gain for second sound
      this.secondStreamGainNode.gain.value = 1;

      var audioSource2 = this.audioContext.createMediaStreamSource(secondStream);
      audioSource2.connect(this.secondStreamGainNode).connect(audioDestionation);
    }
    else {
      console.debug("Second stream does not have audio track")
    }

    audioDestionation.stream.getAudioTracks().forEach(function (track: any) {
      composedStream.addTrack(track);
    });

    return composedStream;
  }

  setGainNodeStream(stream: any) {

    // Get the videoTracks from the stream.
    const videoTracks = stream.getVideoTracks();

    // Get the audioTracks from the stream.
    const audioTracks = stream.getAudioTracks();

    /**
      * Create a new audio context and build a stream source,
      * stream destination and a gain node. Pass the stream into
      * the mediaStreamSource so we can use it in the Web Audio API.
      */
    this.audioContext = new AudioContext();
    let mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
    let mediaStreamDestination = this.audioContext.createMediaStreamDestination();
    this.soundOriginGainNode = this.audioContext.createGain();

    /**
      * Connect the stream to the gainNode so that all audio
      * passes through the gain and can be controlled by it.
      * Then pass the stream from the gain to the mediaStreamDestination
      * which can pass it back to the RTC client.
      */
    mediaStreamSource.connect(this.soundOriginGainNode);
    this.soundOriginGainNode.connect(mediaStreamDestination);

    if (this.currentVolume == null) {
      this.soundOriginGainNode.gain.value = 1;
    }
    else {
      this.soundOriginGainNode.gain.value = this.currentVolume;
    }

    /**
      * The mediaStreamDestination.stream outputs a MediaStream object
      * containing a single AudioMediaStreamTrack. Add the video track
      * to the new stream to rejoin the video with the controlled audio.
      */
    const controlledStream = mediaStreamDestination.stream;

    for (const videoTrack of videoTracks) {
      controlledStream.addTrack(videoTrack);
    }
    for (const audioTrack of audioTracks) {
      controlledStream.addTrack(audioTrack);
    }

    /**
      * Use the stream that went through the gainNode. This
      * is the same stream but with altered input volume levels.
      */
    return controlledStream;
  }

  switchAudioInputSource(streamId: any, deviceId: any) {
    //stop the track because in some android devices need to close the current camera stream
    var audioTrack = this.localStream.getAudioTracks()[0];
    if (audioTrack) {
      audioTrack.stop();
    }
    else {
      console.warn("There is no audio track in local stream");
    }

    if (typeof deviceId != "undefined") {
      this.mediaConstraints.audio = { "deviceId": deviceId };
    }
    this.setAudioInputSource(streamId, this.mediaConstraints, null);
  }

  switchVideoCameraCapture(streamId: any, deviceId: any) {
    //stop the track because in some android devices need to close the current camera stream
    var videoTrack = this.localStream.getVideoTracks()[0];
    if (videoTrack) {
      videoTrack.stop();
    }
    else {
      console.warn("There is no video track in local stream");
    }

    this.publishMode = "camera";

    if (typeof deviceId != "undefined") {
      this.mediaConstraints.video = { "deviceId": deviceId };
    }
    this.setVideoCameraSource(streamId, this.mediaConstraints, null, true);
  }

  switchDesktopCaptureWithCamera(streamId: any) {
    this.publishMode = "screen+camera";

    var audioConstraint = false;
    if (typeof this.mediaConstraints.audio != "undefined" && this.mediaConstraints.audio != false) {
      audioConstraint = this.mediaConstraints.audio;
    }
    this.getUserMedia(this.mediaConstraints, audioConstraint, streamId);
  }

  /**
   * This method updates the local stream. It removes existant audio track from the local stream
   * and add the audio track in `stream` parameter to the local stream
   */
  updateLocalAudioStream(stream: any, onEndedCallback: any) {
    var audioTrack = this.localStream.getAudioTracks()[0];
    var newAudioTrack = stream.getAudioTracks()[0];

    if (audioTrack != null) {
      this.localStream.removeTrack(audioTrack);
      audioTrack.stop();
    }


    this.localStream.addTrack(newAudioTrack);


    if (this.localVideo != null) {   //it can be null
      this.localVideo.srcObject = this.localStream;
    }
    if (onEndedCallback != null) {
      stream.getAudioTracks()[0].onended = function (event: any) {
        onEndedCallback(event);
      }
    }
  }

  /**
   * This method updates the local stream. It removes existant video track from the local stream
   * and add the video track in `stream` parameter to the local stream
   */
  updateLocalVideoStream(stream: any, onEndedCallback: any, stopDesktop: any) {
    if (stopDesktop && this.desktopStream != null) {
      this.desktopStream.getVideoTracks()[0].stop();
    }

    var videoTrack = this.localStream.getVideoTracks()[0];
    this.localStream.removeTrack(videoTrack);
    videoTrack.stop();
    this.localStream.addTrack(stream.getVideoTracks()[0]);
    this.localVideo.srcObject = this.localStream;

    if (onEndedCallback != null) {
      stream.getVideoTracks()[0].onended = function (event: any) {
        onEndedCallback(event);
      }
    }
  }

  /**
   * This method sets Audio Input Source.
   * It calls updateAudioTrack function for the update local audio stream.
   */
  setAudioInputSource(streamId: any, mediaConstraints: any, onEndedCallback: any) {
    this.navigatorUserMedia(mediaConstraints, (stream: any) => {
      this.updateAudioTrack(stream, streamId, mediaConstraints);
    }, true);
  }

  /**
   * This method sets Video Input Source.
   * It calls updateVideoTrack function for the update local video stream.
   */
  setVideoCameraSource(streamId: any, mediaConstraints: any, onEndedCallback: any, stopDesktop: any) {
    this.navigatorUserMedia(mediaConstraints, (stream: any) => {
      stream = this.setGainNodeStream(stream);
      this.updateVideoTrack(stream, streamId, mediaConstraints, onEndedCallback, stopDesktop);
      this.updateAudioTrack(stream, streamId, mediaConstraints);
    }, true);
  }

  updateAudioTrack(stream: any, streamId: any, onEndedCallback: any) {
    if (this.remotePeerConnection[streamId] != null) {
      var audioTrackSender = this.remotePeerConnection[streamId].getSenders().find(function (s: any) {
        return s.track.kind == "audio";
      });

      if (audioTrackSender) {
        audioTrackSender.replaceTrack(stream.getAudioTracks()[0]).then((result: any) => {
          this.updateLocalAudioStream(stream, onEndedCallback);

        }).catch(function (error: any) {
        });
      }
      else {
        console.error("AudioTrackSender is undefined or null");
      }
    }
    else {
      this.updateLocalAudioStream(stream, onEndedCallback);
    }
  }

  updateVideoTrack(stream: any, streamId: any, mediaConstraints: any, onEndedCallback: any, stopDesktop: any) {
    if (this.remotePeerConnection[streamId] != null) {
      var videoTrackSender = this.remotePeerConnection[streamId].getSenders().find(function (s: any) {
        return s.track.kind == "video";
      });

      if (videoTrackSender) {
        videoTrackSender.replaceTrack(stream.getVideoTracks()[0]).then((result: any) => {
          this.updateLocalVideoStream(stream, onEndedCallback, stopDesktop);

        }).catch((error: any) => {
        });
      }
      else {
        console.error("VideoTrackSender is undefined or null");
      }
    }
    else {
      this.updateLocalVideoStream(stream, onEndedCallback, stopDesktop);
    }
  }

  onTrack(event: any, streamId: any) {
    if (this.remoteVideo != null) {
      //this.remoteVideo.srcObject = event.streams[0];

      if (this.remoteVideo.srcObject !== event.streams[0]) {
        this.remoteVideo.srcObject = event.streams[0];
        this._StreamService.setStream(event);
      }
    }
    else {
      var dataObj = {
        stream: event.streams[0],
        track: event.track,
        streamId: streamId
      }
      this._StreamService.setStream(event);
      this.callback("newStreamAvailable", dataObj);
    }

  }

  iceCandidateReceived(event: any, streamId: any) {
    if (event.candidate) {

      var protocolSupported = false;

      if (event.candidate.candidate == "") {
        //event candidate can be received and its value can be "".
        //don't compare the protocols
        protocolSupported = true;
      }
      else if (typeof event.candidate.protocol == "undefined") {
        this.candidateTypes.forEach(element => {
          if (event.candidate.candidate.toLowerCase().includes(element)) {
            protocolSupported = true;
          }
        });
      }
      else {
        protocolSupported = this.candidateTypes.includes(event.candidate.protocol.toLowerCase());
      }


      if (protocolSupported) {

        var jsCmd = {
          command: "takeCandidate",
          streamId: streamId,
          label: event.candidate.sdpMLineIndex,
          id: event.candidate.sdpMid,
          candidate: event.candidate.candidate
        };

        if (this.debug) {

        }
        this.webSocketAdaptor.send(JSON.stringify(jsCmd));
      }
      else {
        if (event.candidate.candidate != "") { //
          this.callbackError("protocol_not_supported", "Support protocols: " + this.candidateTypes.toString() + " candidate: " + event.candidate.candidate);
        }
      }
    }
    else {

    }
  }

  initDataChannel(streamId: any, dataChannel: any) {
    dataChannel.onerror = (error: any) => {
      var obj = {
        streamId: streamId,
        error: error
      };
      if (dataChannel.readyState != "closed") {
        this.callbackError("data_channel_error", obj);
      }
    };

    dataChannel.onmessage = (event: any) => {
      var obj = {
        streamId: streamId,
        event: event,
      };
      this.callback("data_received", obj);
    };

    dataChannel.onopen = () => {
      this.remotePeerConnection[streamId].dataChannel = dataChannel;
      this.callback("data_channel_opened", streamId)
    };

    dataChannel.onclose = () => {
      this.callback("data_channel_closed", streamId);
    };
  }

  // data channel mode can be "publish" , "play" or "peer" based on this it is decided which way data channel is created
  initPeerConnection(streamId: any, dataChannelMode: any) {
    if (this.remotePeerConnection[streamId] == null) {
      var closedStreamId = streamId;
      this.remotePeerConnection[streamId] = new RTCPeerConnection(this.peerconnection_config);
      this.remoteDescriptionSet[streamId] = false;
      this.iceCandidateList[streamId] = new Array();
      if (!this.playStreamId.includes(streamId)) {
        if (this.localStream != null) {
          this.remotePeerConnection[streamId].addStream(this.localStream);
        }
      }
      this.remotePeerConnection[streamId].onicecandidate = (event: any) => {
        this.iceCandidateReceived(event, closedStreamId);
      }
      this.remotePeerConnection[streamId].ontrack = (event: any) => {
        this.onTrack(event, closedStreamId);
      }

      if (dataChannelMode == "publish") {
        //open data channel if it's publish mode peer connection
        const dataChannelOptions = {
          ordered: true,
        };
        if (this.remotePeerConnection[streamId].createDataChannel) {
          var dataChannel = this.remotePeerConnection[streamId].createDataChannel(streamId, dataChannelOptions);
          this.initDataChannel(streamId, dataChannel);
        }
        else {
          console.warn("CreateDataChannel is not supported");
        }

      } else if (dataChannelMode == "play") {
        //in play mode, server opens the data channel
        this.remotePeerConnection[streamId].ondatachannel = (ev: any) => {
          this.initDataChannel(streamId, ev.channel);
        };
      }
      else {
        //for peer mode do both for now
        const dataChannelOptions = {
          ordered: true,
        };

        if (this.remotePeerConnection[streamId].createDataChannel) {
          var dataChannelPeer = this.remotePeerConnection[streamId].createDataChannel(streamId, dataChannelOptions);
          this.initDataChannel(streamId, dataChannelPeer);

          this.remotePeerConnection[streamId].ondatachannel = (ev: any) => {
            this.initDataChannel(streamId, ev.channel);
          };
        }
        else {
          console.warn("CreateDataChannel is not supported");
        }
      }

      this.remotePeerConnection[streamId].oniceconnectionstatechange = (event: any) => {
        var obj = { state: this.remotePeerConnection[streamId].iceConnectionState, streamId: streamId };
        this.callback("ice_connection_state_changed", obj);

        if (!this.isPlayMode) {
          if (this.remotePeerConnection[streamId].iceConnectionState == "connected") {

            this.changeBandwidth(this.bandwidth, streamId).then(() => {
            })
              .catch((e: any) => console.warn(e));
          }
        }
      }

    }
  }

  closePeerConnection(streamId: any) {
    if (this.remotePeerConnection[streamId] != null) {
      if (this.remotePeerConnection[streamId].dataChannel != null) {
        this.remotePeerConnection[streamId].dataChannel.close();
      }
      if (this.remotePeerConnection[streamId].signalingState != "closed") {
        this.remotePeerConnection[streamId].close();
        this.remotePeerConnection[streamId] = null;
        delete this.remotePeerConnection[streamId];
        var playStreamIndex = this.playStreamId.indexOf(streamId);
        if (playStreamIndex != -1) {
          this.playStreamId.splice(playStreamIndex, 1);
        }
      }
    }

    if (this.remotePeerConnectionStats[streamId] != null) {
      clearInterval(this.remotePeerConnectionStats[streamId].timerId);
      delete this.remotePeerConnectionStats[streamId];
    }
  }

  signallingState(streamId: any) {
    if (this.remotePeerConnection[streamId] != null) {
      return this.remotePeerConnection[streamId].signalingState;
    }
    return null;
  }

  iceConnectionState(streamId: any) {
    if (this.remotePeerConnection[streamId] != null) {
      return this.remotePeerConnection[streamId].iceConnectionState;
    }
    return null;
  }

  gotDescription(configuration: any, streamId: any) {
    this.remotePeerConnection[streamId]
      .setLocalDescription(configuration)
      .then((responose: any) => {
        console.debug("Set local description successfully for stream Id " + streamId);

        var jsCmd = {
          command: "takeConfiguration",
          streamId: streamId,
          type: configuration.type,
          sdp: configuration.sdp

        };

        if (this.debug) {
          console.debug("local sdp: ");
          console.debug(configuration.sdp);
        }

        this.webSocketAdaptor.send(JSON.stringify(jsCmd));

      }).catch((error: any) => {
        console.error("Cannot set local description. Error is: " + error);
      });
  }


  turnOffLocalCamera() {
    if (this.remotePeerConnection != null) {

      if (this.localStream !== null) {
        var track = this.localStream.getVideoTracks()[0];
        track.enabled = false;
      }
    }
    else {
      this.callbackError("NoActiveConnection");
    }
  }

  turnOnLocalCamera() {
    //If it started in playOnly mode and wants to turn on the camera
    if (this.localStream == null) {
      this.navigatorUserMedia(this.mediaConstraints, (stream: any) => {
        this.gotStream(stream);
      }, false);
    }
    else if (this.remotePeerConnection != null) {
      var track = this.localStream.getVideoTracks()[0];
      track.enabled = true;
    }
  }

  muteLocalMic() {
    if (this.remotePeerConnection != null) {
      var track = this.localStream.getAudioTracks()[0];
      track.enabled = false;
    }
    else {
      this.callbackError("NoActiveConnection");
    }
  }

  unmuteLocalMic() {
    if (this.remotePeerConnection != null) {
      if (this.localStream && this.localStream !== null) {
        var track = this.localStream.getAudioTracks()[0];
        track.enabled = true;
      }
    }
    else {
      this.callbackError("NoActiveConnection");
    }
  }

  takeConfiguration(idOfStream: any, configuration: any, typeOfConfiguration: any) {
    var streamId = idOfStream
    var type = typeOfConfiguration;
    var conf = configuration;
    var isTypeOffer = (type == "offer");

    var dataChannelMode = "publish";
    if (isTypeOffer) {
      dataChannelMode = "play";
    }

    this.initPeerConnection(streamId, dataChannelMode);

    this.remotePeerConnection[streamId].setRemoteDescription(new RTCSessionDescription({
      sdp: conf,
      type: type
    })).then((response: any) => {

      if (this.debug) {
        console.debug("set remote description is succesfull with response: " + response + " for stream : "
          + streamId + " and type: " + type);
        console.debug(conf);
      }

      this.remoteDescriptionSet[streamId] = true;
      var length = this.iceCandidateList[streamId].length;
      console.debug("Ice candidate list size to be added: " + length);
      for (var i = 0; i < length; i++) {
        this.addIceCandidate(streamId, this.iceCandidateList[streamId][i]);
      }
      this.iceCandidateList[streamId] = [];

      if (isTypeOffer) {
        //SDP constraints may be different in play mode


        this.remotePeerConnection[streamId].createAnswer(this.sdp_constraints)
          .then((configuration: any) => {
            this.gotDescription(configuration, streamId);
          })
          .catch((error: any) => {
          });
      }

    }).catch((error: any) => {
      if (this.debug) {
      }
      if (error.toString().indexOf("InvalidAccessError") > -1 || error.toString().indexOf("setRemoteDescription") > -1) {
        /**
         * This error generally occurs in codec incompatibility.
         * AMS for a now supports H.264 codec. This error happens when some browsers try to open it from VP8.
         */
        this.callbackError("notSetRemoteDescription");
      }
    });

  }

  takeCandidate(idOfTheStream: any, tmpLabel: any, tmpCandidate: any) {
    var streamId = idOfTheStream;
    var label = tmpLabel;
    var candidateSdp = tmpCandidate;

    var candidate = new RTCIceCandidate({
      sdpMLineIndex: label,
      candidate: candidateSdp
    });

    var dataChannelMode = "peer";
    this.initPeerConnection(streamId, dataChannelMode);

    if (this.remoteDescriptionSet[streamId] == true) {
      this.addIceCandidate(streamId, candidate);
    }
    else {
      console.debug("Ice candidate is added to list because remote description is not set yet");
      this.iceCandidateList[streamId].push(candidate);
    }
  };

  addIceCandidate(streamId: any, candidate: any) {
    var protocolSupported = false;
    if (candidate.candidate == "") {
      //candidate can be received and its value can be "".
      //don't compare the protocols
      protocolSupported = true;
    }
    else if (typeof candidate.protocol == "undefined") {
      this.candidateTypes.forEach(element => {
        if (candidate.candidate.toLowerCase().includes(element)) {
          protocolSupported = true;
        }
      });
    }
    else {
      protocolSupported = this.candidateTypes.includes(candidate.protocol.toLowerCase());
    }

    if (protocolSupported) {

      this.remotePeerConnection[streamId].addIceCandidate(candidate)
        .then((response: any) => {
          if (this.debug) {
          }
        })
        .catch((error: any) => {
          console.error("ice candiate cannot be added for stream id: " + streamId + " error is: " + error);
          console.error(candidate);
        });
    }
    else {
      if (this.debug) {

      }
    }
  };

  startPublishing(idOfStream: any) {
    var streamId = idOfStream;

    this.initPeerConnection(streamId, "publish");

    this.remotePeerConnection[streamId].createOffer(this.sdp_constraints)
      .then((configuration: any) => {
        this.gotDescription(configuration, streamId);
      })
      .catch((error: any) => {
        console.error("create offer error for stream id: " + streamId + " error: " + error);
      });
  };


  getVideoSender(streamId: any) {
    var videoSender = null;
    if ((this.adapter.browserDetails.browser === 'chrome' ||
      (this.adapter.browserDetails.browser === 'firefox' ||
        this.adapter.browserDetails.browser === 'safari' &&
        this.adapter.browserDetails.version >= 64)) &&
      'RTCRtpSender' in window &&
      'setParameters' in window.RTCRtpSender.prototype) {
      if (this.remotePeerConnection[streamId] != null) {
        const senders = this.remotePeerConnection[streamId].getSenders();

        for (let i = 0; i < senders.length; i++) {
          if (senders[i].track != null && senders[i].track.kind == "video") {
            videoSender = senders[i];
            break;
          }
        }
      }

    }
    return videoSender;
  }


  changeBandwidth(bandwidth: any, streamId: any) {
    var errorDefinition = "";

    var videoSender = this.getVideoSender(streamId);

    if (videoSender != null) {
      const parameters = videoSender.getParameters();

      if (!parameters.encodings) {
        parameters.encodings = [{}];
      }

      if (bandwidth === 'unlimited') {
        delete parameters.encodings[0].maxBitrate;
      }
      else {
        parameters.encodings[0].maxBitrate = bandwidth * 1000;
      }

      return videoSender.setParameters(parameters)
    }
    else {
      errorDefinition = "Video sender not found to change bandwidth. Streaming may not be active";
    }

    return Promise.reject(errorDefinition);
  };

  getStats(streamId: any) {
    this.remotePeerConnection[streamId].getStats(null).then((stats: any) => {
      var bytesReceived = -1;
      var videoPacketsLost = -1;
      var audioPacketsLost = -1;
      var fractionLost = -1;
      var currentTime = -1;
      var bytesSent = -1;
      var audioLevel = -1;
      var qlr = "";
      var framesEncoded = -1;
      var width = -1;
      var height = -1;
      var fps = -1;
      var frameWidth = -1;
      var frameHeight = -1;
      var videoRoundTripTime = -1;
      var videoJitter = -1;

      var audioRoundTripTime = -1;
      var audioJitter = -1;

      var framesDecoded = -1;
      var framesDropped = -1;
      var framesReceived = -1;

      var audioJitterAverageDelay = -1;
      var videoJitterAverageDelay = -1;


      stats.forEach((value: any) => {
        if (value.type == "inbound-rtp" && typeof value.kind != "undefined") {
          bytesReceived += value.bytesReceived;
          if (value.kind == "audio") {
            audioPacketsLost = value.packetsLost;
          }
          else if (value.kind == "video") {
            videoPacketsLost = value.packetsLost;
          }

          fractionLost += value.fractionLost;
          currentTime = value.timestamp;


        }
        else if (value.type == "outbound-rtp") {//TODO: SPLIT AUDIO AND VIDEO BITRATES
          bytesSent += value.bytesSent
          currentTime = value.timestamp
          qlr = value.qualityLimitationReason;
          if (value.framesEncoded != null) { //audio tracks are undefined here
            framesEncoded += value.framesEncoded;
          }
        }
        else if (value.type == "track" && typeof value.kind != "undefined" && value.kind == "audio") {
          if (typeof value.audioLevel != "undefined") {
            audioLevel = value.audioLevel;
          }

          if (typeof value.jitterBufferDelay != "undefined" && typeof value.jitterBufferEmittedCount != "undefined") {
            audioJitterAverageDelay = value.jitterBufferDelay / value.jitterBufferEmittedCount;
          }
        }
        else if (value.type == "track" && typeof value.kind != "undefined" && value.kind == "video") {
          if (typeof value.frameWidth != "undefined") {
            frameWidth = value.frameWidth;
          }
          if (typeof value.frameHeight != "undefined") {
            frameHeight = value.frameHeight;
          }

          if (typeof value.framesDecoded != "undefined") {
            framesDecoded = value.framesDecoded;
          }

          if (typeof value.framesDropped != "undefined") {
            framesDropped = value.framesDropped;
          }

          if (typeof value.framesReceived != "undefined") {
            framesReceived = value.framesReceived;
          }

          if (typeof value.jitterBufferDelay != "undefined" && typeof value.jitterBufferEmittedCount != "undefined") {
            videoJitterAverageDelay = value.jitterBufferDelay / value.jitterBufferEmittedCount;
          }
        }
        else if (value.type == "remote-inbound-rtp" && typeof value.kind != "undefined") {

          if (typeof value.packetsLost != "undefined") {
            if (value.kind == "video") {
              //this is the packetsLost for publishing
              videoPacketsLost = value.packetsLost;
            }
            else if (value.kind == "audio") {
              //this is the packetsLost for publishing
              audioPacketsLost = value.packetsLost;
            }
          }

          if (typeof value.roundTripTime != "undefined") {
            if (value.kind == "video") {
              videoRoundTripTime = value.roundTripTime;
            }
            else if (value.kind == "audio") {
              audioRoundTripTime = value.roundTripTime;
            }
          }

          if (typeof value.jitter != "undefined") {
            if (value.kind == "video") {
              videoJitter = value.jitter;
            }
            else if (value.kind == "audio") {
              audioJitter = value.jitter;
            }
          }
        }
        else if (value.type == "media-source") {
          if (value.kind == "video") { //returns video source dimensions, not necessarily dimensions being encoded by browser
            width = value.width;
            height = value.height;
            fps = value.framesPerSecond;
          }
        }
      });

      this.remotePeerConnectionStats[streamId].totalBytesReceived = bytesReceived;
      this.remotePeerConnectionStats[streamId].videoPacketsLost = videoPacketsLost;
      this.remotePeerConnectionStats[streamId].audioPacketsLost = audioPacketsLost;
      this.remotePeerConnectionStats[streamId].fractionLost = fractionLost;
      this.remotePeerConnectionStats[streamId].currentTime = currentTime;
      this.remotePeerConnectionStats[streamId].totalBytesSent = bytesSent;
      this.remotePeerConnectionStats[streamId].audioLevel = audioLevel;
      this.remotePeerConnectionStats[streamId].qualityLimitationReason = qlr;
      this.remotePeerConnectionStats[streamId].totalFramesEncoded = framesEncoded;
      this.remotePeerConnectionStats[streamId].resWidth = width;
      this.remotePeerConnectionStats[streamId].resHeight = height;
      this.remotePeerConnectionStats[streamId].srcFps = fps;
      this.remotePeerConnectionStats[streamId].frameWidth = frameWidth;
      this.remotePeerConnectionStats[streamId].frameHeight = frameHeight;
      this.remotePeerConnectionStats[streamId].videoRoundTripTime = videoRoundTripTime;
      this.remotePeerConnectionStats[streamId].videoJitter = videoJitter;
      this.remotePeerConnectionStats[streamId].audioRoundTripTime = audioRoundTripTime;
      this.remotePeerConnectionStats[streamId].audioJitter = audioJitter;
      this.remotePeerConnectionStats[streamId].framesDecoded = framesDecoded;
      this.remotePeerConnectionStats[streamId].framesDropped = framesDropped;
      this.remotePeerConnectionStats[streamId].framesReceived = framesReceived;

      this.remotePeerConnectionStats[streamId].videoJitterAverageDelay = videoJitterAverageDelay;
      this.remotePeerConnectionStats[streamId].audioJitterAverageDelay = audioJitterAverageDelay;


      this.callback("updated_stats", this.remotePeerConnectionStats[streamId]);

    });
  }
  disableStats(streamId: any) {
    clearInterval(this.remotePeerConnectionStats[streamId].timerId);
  }

  enableStats(streamId: any) {
    if (this.remotePeerConnectionStats[streamId] == null) {
      this.remotePeerConnectionStats[streamId] = new PeerStats(streamId);
      this.remotePeerConnectionStats[streamId].timerId = setInterval(() => {
        this.getStats(streamId);

      }, 5000);
    }
  }


  closeWebSocket() {
    for (var key in this.remotePeerConnection) {
      this.remotePeerConnection[key].close();
    }
    this.remotePeerConnection = new Array();
    this.webSocketAdaptor.close();
  }

  peerMessage(streamId: any, definition: any, data: any) {
    var jsCmd = {
      command: "peerMessageCommand",
      streamId: streamId,
      definition: definition,
      data: data,
    };

    this.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }

  forceStreamQuality(streamId: any, resolution: any) {
    var jsCmd = {
      command: "forceStreamQuality",
      streamId: streamId,
      streamHeight: resolution
    };
    this.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }

  sendData(streamId: any, message: any) {
    var dataChannel = this.remotePeerConnection[streamId].dataChannel;
    dataChannel.send(message);
  }

  replaceMedia(streamId: any, stream: any) {
    if (this.remotePeerConnection[streamId] != null) {
      this.remotePeerConnection[streamId].getSenders().map((sender: any) =>
        sender.replaceTrack(stream.getTracks().find((t: any) => t.kind == sender.track.kind), stream));
    }

  }


}
