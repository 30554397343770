import { Component, OnInit, ViewChild } from '@angular/core';
import {
  EventSettingsModel, DayService, WeekService, MonthService, ResizeService, DragAndDropService,
  ScheduleComponent, ActionEventArgs, View, EventRenderedArgs, PopupOpenEventArgs, TimelineViewsService,
} from "@syncfusion/ej2-angular-schedule";
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { DateTimePicker } from '@syncfusion/ej2-angular-calendars';
import { UtilityService } from 'src/app/service/helper/utility.service';

@Component({
  selector: 'app-audience-calender',
  templateUrl: './audience-calender.component.html',
  styleUrls: ['./audience-calender.component.scss'],
  providers: [DayService, WeekService, TimelineViewsService, MonthService, ResizeService, DragAndDropService]
})
export class AudienceCalenderComponent implements OnInit {
  @ViewChild("schedule", { static: false })
  public scheduleObj!: ScheduleComponent;
  public selectedDate: Date = new Date();
  public minDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate());
  calenderData: any = [];
  public views: Array<string> = ['Day', 'Week', 'Month'];
  view: any;
  eventData: any;
  title: any;
  eventStartTime: any;
  eventEndTime: any;
  calenderId: any;
  addButton: boolean = false;
  public eventSettings: EventSettingsModel = {
    dataSource: []
  };
  selectionTarget: any;
  allDay: any;
  calenderType: boolean = false

  constructor(
    private _mainService: MainService,
    private _NotifierService: NotifierService,
    private _utilityService: UtilityService,
  ) { }

  ngOnInit(): void { }

  onRenderCell(args: { elementType: string; date: { getTime: () => number; }; element: { classList: { contains: (arg0: string) => any; add: (arg0: string) => void; }; }; }): void {
    if (args.elementType === "workCells" && args.date.getTime() <= new Date().getTime() && !args.element.classList.contains("e-disable-dates")) {
      args.element.classList.add("e-disable-dates");
      args.element.classList.add("e-disable-cell");
    }
  }

  onDragStart(args: { cancel: boolean; data: { StartTime: any; }; }) {
    args.cancel = this.isValidAction(args.data.StartTime);
  }
  onResizeStart(args: { cancel: boolean; data: { StartTime: any; }; }) {
    args.cancel = this.isValidAction(args.data.StartTime);
  }
  isValidAction(date: { getTime: () => number; }) {
    return !(date.getTime() > new Date().getTime());
  }

  formatDate(date: any) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  onPopupOpen(args: PopupOpenEventArgs): void {
    this.calenderId = args.data;
    if (["QuickInfo", "Editor"].indexOf(args.type) > -1) {
      args.cancel = this.isValidAction(this.calenderId.StartTime);
    }
    if (args.type === 'Editor') {
      let startElement: HTMLInputElement = args.element.querySelector('#StartTime') as HTMLInputElement;
      if (!startElement.classList.contains('e-datetimepicker')) {
        new DateTimePicker({ value: new Date(startElement.value) || new Date() }, startElement);
      }
      let endElement: HTMLInputElement = args.element.querySelector('#EndTime') as HTMLInputElement;
      if (!endElement.classList.contains('e-datetimepicker')) {
        new DateTimePicker({ value: new Date(endElement.value) || new Date() }, endElement);
      }
    }
  }

  onActionComplete(args: ActionEventArgs): void {
    let currentView: View = this.scheduleObj.currentView;
    this.view = currentView;
    let date = this.scheduleObj.selectedDate;
    this.eventData = args.data;
    this.getAllCalenderDetails(this.formatDate(date), currentView);
    if (args.requestType === "eventRemoved") {
      this._mainService.deleteBlockedCalenderSlot(this.calenderId.Id).then((data: any) => {
        if (data && data.isSuccess) {
          this._NotifierService.showSuccess(data.message);
          this.getAllCalenderDetails(this.formatDate(date), currentView);
        } else
          this._NotifierService.showError(data.message);
      })
    } else if (args.requestType === "eventCreated" && !this.addButton) {
      this.addButton = false;
      this.eventData = args.data;
      let body = {
        "endDate": this._utilityService.formatDate(this.eventData[0].EndTime),
        "startDate": this._utilityService.formatDate(this.eventData[0].StartTime),
        "title": this.eventData[0].Subject
      }
      this._mainService.blockCalenderSlot(body).then((data: any) => {
        if (data && data.isSuccess) {
          this._NotifierService.showSuccess(data.message);
          this.getAllCalenderDetails(this.formatDate(date), currentView);;
          this.addButton = true;
        } else {
          this._NotifierService.showError(data.message);
        }
      })
    } else if (args.requestType === "eventChanged") {
      let body = {
        "endDate": this._utilityService.formatDate(this.eventData[0].EndTime),
        "startDate": this._utilityService.formatDate(this.eventData[0].StartTime),
        "title": this.eventData[0].Subject
      }
      this._mainService.editBlockedCalenderSlot(this.calenderId.Id, body).then((data: any) => {
        if (data && data.isSuccess) {
          this._NotifierService.showSuccess(data.message);
          this.getAllCalenderDetails(this.formatDate(date), currentView);
        } else
          this._NotifierService.showError(data.message);
      })
    }
  }

  getAllCalenderDetails(date: any, currentView: any) {
    this._mainService.getAllCalenderDetails(date, currentView).then((data: any) => {
      if (data && data.isSuccess) {
        this.calenderData = data.data;
        if (this.calenderData.length) {
          this.calenderData = this.calenderData.map((item: any) => {
            if (item.eventId) {
              delete item.eventId
            }
            if (item.userId) {
              delete item.userId
            }
            return item;
          });
        }
        this.eventSettings = {
          dataSource:
            this.calenderData
        };
      }
    })
  }

  eventRendered(args: EventRenderedArgs): void {
    let data: { [key: string]: Object } = args.data;
    if (data.calenderType === "PENDING") {
      args.element.style.backgroundColor = 'yellow';
      args.element.style.color = 'black';
    }
    else if (data.calenderType === "INVITED") {
      args.element.style.backgroundColor = 'blue';
      args.element.style.color = 'black';
    }
    else if (data.calenderType === "PROPOSED") {
      args.element.style.backgroundColor = '#FF5F15';
      args.element.style.color = 'black';
    }
    else if (data.calenderType === "BOOKED") {
      args.element.style.backgroundColor = 'green';
      args.element.style.color = 'black';
    }
    else if (data.calenderType === "BLOCKED") {
      args.element.style.backgroundColor = 'red';
      args.element.style.color = 'black';
    } else if (data.calenderType === 'CANCELLED') {
      args.element.style.backgroundColor = 'grey';
      args.element.style.color = 'black';
    }
  }

  onCloseClick() {
    this.scheduleObj.quickPopup.quickPopupHide();
  }
  onDetailsClick() {
    this.onCloseClick();
    const data: Object = this.scheduleObj.getCellDetails(this.scheduleObj.getSelectedElements()) as Object;
    this.scheduleObj.openEditor(data, 'Add');
  }
  onAddClick() {
    this.addButton = true;
    this.onCloseClick();
    const data: Object = this.scheduleObj.getCellDetails(this.scheduleObj.getSelectedElements()) as Object;
    const eventData: { [key: string]: Object } = this.scheduleObj.eventWindow.getObjectFromFormData('e-quick-popup-wrapper');
    this.scheduleObj.eventWindow.convertToEventData(data as { [key: string]: Object }, eventData);
    eventData.Id = this.scheduleObj.eventBase.getEventMaxID() as number + 1;
    this.scheduleObj.addEvent(eventData);
    let body = {
      "endDate": this._utilityService.formatDate(this.eventData[0].EndTime),
      "startDate": this._utilityService.formatDate(this.eventData[0].StartTime),
      "title": this.eventData[0].Subject
    }
    this._mainService.blockCalenderSlot(body).then((data: any) => {
      if (data && data.isSuccess) {
        this._NotifierService.showSuccess(data.message);
        this.addButton = false;
      } else {
        this._NotifierService.showError(data.message);
      }
    })
  }
}
