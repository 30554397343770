<main class="content">
  <div class="container-fluid pl-0 pr-10 pt-3">
    <div class="hgnb bot-m">
      <div class="nvhi"><span>Messages</span>
        <button class="ekuhrte" (click)="openModal()">New Chat</button>
        <button class="yht">Active <span style="color: #28b228eb;font-size: 12px;"><i
              class="fa fa-circle colorIcon"></i></span>
        </button>
      </div>
    </div>
    <!-- <h1 class="h3 mb-3" style="color: white;">Messages</h1> -->
    <div class="card" style="background-color: black;height: 562px;">
      <div class="row g-0">
        <div class="col-md-12 col-lg-5 col-xl-3 border-right" style="overflow: auto;height: 70vh;">
          <div class="px-4 d-none d-md-block">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <input type="text" class="form-control my-3 searchInput" (input)="searchChatUser($event)"
                  placeholder="Search...">
              </div>
            </div>
          </div>
          <ng-container *ngFor="let user of historyUser;let i=index">
            <div (click)="openUserChatList(user.userId,user.channelName,i,user.messageEndTime,user.avatarUrl)">
              <a class="list-group-item list-group-item-action border-0">
                <div class="badge  float-right">
                  <div class="time2" *ngIf="user.dateStatus === 'Today Chat'">
                    {{user.messageTime | date:'shortTime'}}
                  </div>
                  <div class="time2" *ngIf="user.dateStatus === 'Yesterday Chat'">
                    Yesterday
                  </div>
                  <div class="time2" *ngIf="user.dateStatus === 'Day After'">
                    {{user.messageTime | date:'shortDate'}}
                  </div>
                </div>
                <div class="d-flex align-items-start">
                  <img [src]="user.avatarUrl ? user.avatarUrl : '../../../../../../assets/mainSliderImg/slide1.webp'"
                    class="rounded-circle mr-1" alt="" width="40" height="40">
                  <div class="flex-grow-1 ml-3">
                    {{user.displayName}}
                    <!-- <span class="fas fa-circle chat-online"></span> -->
                    <div class="small" style="color: #7d827dcc;">{{user.message}}</div>
                  </div>
                </div>
              </a>
            </div>
          </ng-container>
          <hr class="d-block d-lg-none mt-1 mb-0">
        </div>
        <div class="col-md-12 col-lg-7 col-xl-9" *ngIf="!showChatScreen">
          <div class="position-relative">
            <div class="chat-messages p-4">
              <div class="container">
                <div class="item" style="text-align: center;">
                  <img class="userImg" src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="" style="    height: 100px;
									width: 100px;
									border-radius: 50px;">
                  <p class="startPara" style="color: white;">Select any user to start chatting</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-7 col-xl-9" *ngIf="showChatScreen">
          <div class="doc-view-section" *ngIf="showUploadDocUi">
            <div class="row" style="border-bottom: 2px solid beige;margin-top: 25px;">
              <div class="col-md-12 pl15 py20">
                <p class="back-to-home-btn pointer" (click)="showUploadDocUi = false">
                  <i class="fa fa-times" aria-hidden="true" style="cursor: pointer;color: white;"></i>
                  <span>{{fileName}}</span>
                </p>

              </div>
            </div>
            <div class="row">
              <div class="col-md-12 pl15 py20" style="text-align: center;">
                <img *ngIf="getFileType() === 'image'" [src]="uploadDoc">
                <video *ngIf="getFileType() === 'video'" [src]="uploadDoc" controls></video>
                <pdf-viewer *ngIf="getFileType() === 'doc'" [src]="pdfSrc" [rotation]="0" [original-size]="false"
                  [show-all]="true" [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'" [stick-to-page]="false"
                  [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
                  style="height: 87vh;"></pdf-viewer>
                <!-- <ngx-doc-viewer
											[url]="pdfSrc"
											viewer="google"
											style="width:100%;height:50vh;"
										></ngx-doc-viewer> -->
              </div>
            </div>
          </div>

          <div class="py-2 px-4  d-none d-lg-block userBlock" *ngIf="!showUploadDocUi">
            <div class="d-flex align-items-center py-1">
              <div class="position-relative">
                <img [src]="profileImage ? profileImage : '../../../../../../assets/sliderImage/slide1.png'"
                  class="rounded-circle mr-1" alt="" width="40" height="40">
              </div>
              <div class="flex-grow-1 pl-3">
                <strong>{{userName}}</strong>
                <!-- <div class="text-muted small" *ngIf="showOnline"><em>Online</em></div> -->
                <!-- <div class="text-muted small"><em>Typing...</em></div> -->
              </div>
              <!-- <div class="side-menu">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <img src="../../../../../../assets/Vector (2).png">
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li>Mute</li>
                  <li (click)="deleteMessage()">Delete Chat</li>
                  <li>Report</li>
                  <li>Block</li>
                </div>
              </div> -->
              <!-- <button class="btn btn-primary btn-lg mr-1 px-3"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone feather-lg"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg></button>
								<button class="btn btn-info btn-lg mr-1 px-3 d-none d-md-inline-block"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-video feather-lg"><polygon points="23 7 16 12 23 17 23 7"></polygon><rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect></svg></button> -->
              <!-- <button class="btn btn-light border btn-lg px-3"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal feather-lg"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg></button> -->

            </div>
          </div>

          <div class="position-relative">
            <div class="flex-grow-0 py-3 px-4">

              <div class="chat-messages p-4" id="div1" *ngIf="!showUploadDocUi">
                <!-- <ng-container *ngFor="let oldmessage of listMessage">
								<div class="chat-message-right pb-4" >

							   <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3" style="color:black !important" >
								   <div class="font-weight-bold mb-1">{{ oldmessage.uuid}}</div>
								   {{ oldmessage.message.typeMessage }}
							   </div>
						   </div>
							</ng-container> -->
                <ng-container *ngIf="newMessages && newMessages.length > 0">
                  <ng-container *ngFor="let item of newMessages">
                    <div class="_2wUmf V-zSs focusable-list-item">
                      <div class="cvjcv EtBAv" *ngIf="item.date && item.date !== 'undefined'">
                        <span class="i0jNr">{{item.date | date : 'LL/dd/yyyy'}}</span>

                      </div>
                    </div>
                    <ng-container *ngFor="let message of item.mesg,let index = index">
                      <div class="chat-message-right pb-4" *ngIf="message.message.senderId == currentUser">
                        <div>
                          <!-- <img
                            [src]="message.message.currentUserProfile ? message.message.currentUserProfile : 'https://bootdey.com/img/Content/avatar/avatar3.png'" -->
                            <img
                            [src]="currentUserProfile? currentUserProfile: 'https://bootdey.com/img/Content/avatar/avatar3.png'"
                            class="rounded-circle mr-1" alt="" width="40" height="40">
                          <!-- <div class="text-muted small text-nowrap mt-2"> {{message.message.messageTime | date:'shortTime'}}</div> -->
                        </div>
                        <div class="flex-shrink-1  rounded py-2 px-3 mr-3 messageSection">
                          <!-- <div class="font-weight-bold mb-1">You</div> -->
                          {{ message.message.typeMessage }}
                          <div class="text-muted small text-nowrap mt-2 time">
                            {{message.message.messageTime | date:'shortTime'}}</div>
                        </div>
                      </div>

                      <div class="chat-message-left pb-4" *ngIf="message.message.senderId !== currentUser">
                        <div>
                          <!-- [src]="message.message.currentUserProfile ? message.message.currentUserProfile : 'https://bootdey.com/img/Content/avatar/avatar3.png'" -->
                          <img
                              [src]="opponentDp ? opponentDp : 'https://bootdey.com/img/Content/avatar/avatar3.png'"
                            class="rounded-circle mr-1" alt="" width="40" height="40">
                          <!-- <div class="text-muted small text-nowrap mt-2"> {{message.message.messageTime | date:'shortTime'}}</div> -->
                        </div>
                        <div class="flex-shrink-1  rounded py-2 px-3 ml-3 messageSection">
                          <!-- <div class="font-weight-bold mb-1">Sharon Lessman</div> -->
                          {{message.message.typeMessage }}
                          <div class="text-muted small text-nowrap mt-2 time">
                            {{message.message.messageTime | date:'shortTime'}}</div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>


            <div class="send-message">
              <div class="input-wrapper">
                <input type="text" #file [(ngModel)]="emojiText" id="basic-addon2" class="form-control messageInput"
                  (keyup.enter)="addComment($event)" placeholder="Write a  message" autocomplete="off">
                <div class="emozi-attach">

                  <span class="cursor-pointer" style="background: transparent;max-height: 72%;" id="basic-addon2"
                    placement="top" container="body" [outsideClick]="true" [popover]="popemoji">
                    <img (click)="file3.click()" src="../../../../../../assets/attach_file_black_24dp.svg" alt="">
                    <!-- <span class="fileImage"><i class="fa fa-file-text-o" style="font-size: 22px !important;"></i></span>  -->
                    <img src="../../../../../../assets/image.png" alt="" style="margin-left: 13px;">

                    <input type="file" #file3 style="display: none;" (change)="onFileSelect($event)" multiple>
                  </span>
                </div>
              </div>
              <div class="send-button-wrapper">
                <span class="sendIcon" (click)="addComment()"><i class="fa fa-paper-plane"
                    style="font-size: 22px;color: white;" aria-hidden="true"></i></span>
              </div>
              <ng-template #popemoji>
                <emoji-mart class="emoji-mart float-right" (emojiSelect)="addEmoji($event)" title="change color">
                </emoji-mart>
              </ng-template>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</main>




<div class="modal" [ngClass]="{'d-block': showAllChatUserModal, 'd-none': !showAllChatUserModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBackground">
      <div class="modal-header" style="border-bottom: 0px !important;">
        <div class="px-4 d-none d-md-block">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <input type="text" class="form-control my-3 searchInput"
                placeholder="Click or search user to start chat..." (input)="searchByUser($event)"
                style="width: 201% !important;">
            </div>
          </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="showAllChatUserModal = false;" style="color: white; font-size: 31px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body list-view-container" infiniteScroll [infiniteScrollDistance]="2" (scrolled)="onScroll()"
        [scrollWindow]="false">
        <ng-container *ngIf="chatData">
          <div style="text-align: center;">
            <sa-spinner-circular [size]="37" [thickness]="102" [speed]="100" color="#dd0031"
              secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
          </div>
        </ng-container>
        <ng-container *ngIf="allChatUserList && allChatUserList.length">
          <div class="container-fluid itemHover" *ngFor="let allUser of allChatUserList;let i=index">
            <div class="row d-flex align-items-center" style="margin-bottom:10px;cursor: pointer;"
              (click)="openUserChat(allUser.userId,i)">
              <div class="col-2">
                <img width="45" class="userProfile"
                  [src]="allUser.avatarUrl ? allUser.avatarUrl : '../../../../../../assets/mainSliderImg/slide1.webp'"
                  alt="">
              </div>
              <div class="col-10 d-flex justify-content-between align-items-center mt-2 list-user">
                <p> {{ allUser.displayName | titlecase}}
                  <br>

                </p>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!allChatUserList?.length && !chatData">
          <div class="center-error" style="margin-top: 137px;">
            <div class="error-wrapper">
              <h1 class="text-center" style="color: white;">Oops</h1>
              <p class="text-center" style="color: white;">No Data Found.</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
