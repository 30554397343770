import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LivePlayerComponent } from './video-player/live-player.component';
import { SliderComponent } from './slider/slider.component';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [
    LivePlayerComponent,
    SliderComponent
],
  exports: [
    LivePlayerComponent,
    SliderComponent
 ],
  imports: [
   CommonModule,
   SwiperModule
  ]
})
export class SharedModule { }
