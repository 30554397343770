export class AdminUsers {
  accountLocked: boolean;
  confirmPassword: string;
  email: string;
  firstName: string;
  id: number;
  lastName: string
  middleName: string;
  password: string;
  roleId: number;
  status: string;
  userName: string
  sta: any
  constructor() {
      this.accountLocked = true;
      this.confirmPassword = '';
      this.email = '';
      this.firstName = '';
      this.id = 0;
      this.lastName = '';
      this.middleName = '';
      this.password = '';
      this.roleId = 0;
      this.status = '';
      this.userName = '';

  }

}
export class LoginModal {
  browser: string;
  country: string;
  device: string;
  email: string;
  loginIp: string;
  loginLocation: string;
  password: string;
  constructor(
  ) {
      this.browser = '';
      this.country = '';
      this.device = window && window.navigator && window.navigator.platform ? window.navigator.platform : 'web_device' ;
      this.email = '';
      this.loginIp = '';
      this.loginLocation = '';
      this.password = '';
  }
}