import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class StreamService {
  stream: any;
  constructor() {



  }
  setStream(stream: any) {
    this.stream = stream;
  }
  getStream() {
    return this.stream;
  }
}
