import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { MainService } from 'src/app/service/main.service';
import { MustMatch } from './password validation/confirm-validation';
import { ResetDialogComponent } from './reset-dialog/reset-dialog.component';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  showVerificationModal: boolean;
  email: any;
  token: any;
  hide = true;

  constructor(private dialog: MatDialog,
    private _mainService: MainService,
    private _FormBuilder: FormBuilder,
    private _NotifierService: NotifierService,
    private router: Router,
  ) {
    this.showVerificationModal = false;
    this.resetPasswordForm = this._FormBuilder.group({
      newPassword: ['', [Validators.required,Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_`{|}~]{8,}$/)]],
      confirmPassword: ['', [Validators.required]],
    },{

      validator: MustMatch('newPassword', 'confirmPassword'),
    });
  }

  ngOnInit(): void {
   this.email = this._mainService.localEmail;
   this.token = this._mainService.localToken
  }

  resetPwd() {
    if (!this.resetPasswordForm.value.newPassword) {
      this._NotifierService.showError("Please enter Password")
    } else if (this.resetPasswordForm.valid) {
      const body = {
        confirmPassword: this.resetPasswordForm.value.confirmPassword,
        email: this.email,
        newPassword: this.resetPasswordForm.value.newPassword,
        token: this.token

      }
      this._mainService.resetPassword(body).then((data) => {
        if (data && data.isSuccess) {
          this.showVerificationModal = true;
          this._NotifierService.showSuccess(data.message)
          this.dialog.open(ResetDialogComponent,{
            "width":"400px",
            "height":"400px"
          })
        } else {
          this._NotifierService.showError(data.message);
        }
      })
    }
  }
}
