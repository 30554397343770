import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  currentMessage:any = new BehaviorSubject(null);

constructor(private afMessaging: AngularFireMessaging) {
 }

checkPermission() {
    return this.afMessaging.requestPermission.toPromise();
  }

  requestPermission() {
    return this.afMessaging.requestToken;
  }

  receiveMessage() {
   this.afMessaging.messages.subscribe(
      (payload) => {
        this.currentMessage.next(payload);
      })
    }

}
