<div class="container" style="width: 100%; max-width: 100%;">
  <div class="row">
    <div class="col-6 col-md-2">
      <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg" class="pointer" (click)="openWarningModal('home')">
    </div>
    <div class="col-6 col-md-10 d-flex flex-row-reverse">
      <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink10" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
      <img src="assets/dashboard/Mask Group.png">
    </a>
    <div class="dropdown-menu userMenu" aria-labelledby="dropdownMenuLink10">
      <a class="dropdown-item" style="cursor: pointer; border-radius: 15px;" (click)="openWarningModal('logOut')"><i
          class="fa fa-sign-out" aria-hidden="true"></i> Log Out</a>
    </div>
    </div>
  </div>
</div>
<div *ngIf="selectTab == 'mainTab'" class="deaa mt-4" id="rockband">
  <div class="container white">
    <div class="row">
      <div class="col-12">
        <h4> {{pageTitle}} </h4>
      </div>
    </div>
    <hr class="mt-0 mb-4" style="border-bottom: 1px solid white;">
    <div class=" modalBg my-4" role="document">
      <div class=" modalBg judhr" style="border-radius: 10px !important;">
        <div class="modal-body pt-1 pt-md-3 px-0 pl-md-2 white" >
          <div class="container mx-0 ml-md-2 pt-1 pt-md-3 px-0 pl-md-3" style="background: #181818;">
            <div class="row ml-2 mt-2" style="width: 100%;">
              <div class="col-12 fir-tab px-0 px-md-2">
                <h5 class="ve-nue mb-3 px-2">Streaming Devices</h5>
                <ul class="nav" role="tablist">
                  <li class="nav-item pr-5">
                    <input type=radio id="rdo1" (click)="tabName = 'onboardDevices'"
                      [checked]="tabName == 'onboardDevices'" class="radio-input" name="radio-group">
                    <label for="rdo1" class="radio-label pl-5"> <span class="radio-border"></span> Onboard Devices
                    </label>
                  </li>
                  <!-- *ngIf="mobileView == false" -->
                  <li class="nav-item pl-0 pl-md-5">
                    <input type=radio id="rdo2" [checked]="tabName == 'encodingDevices'"
                      (click)="tabName = 'encodingDevices'" class="radio-input" name="radio-group">
                    <label for="rdo2" class="radio-label pl-5"><span class="radio-border"></span> Encoding
                      Devices</label>
                  </li>
                </ul>
                <div class="tab-content mt-2">
                  <div *ngIf="tabName == 'onboardDevices'">
                    <form class="d-flex">
                      <div class="container">
                        <div class="row mt-2">
                          <div class="col-12">
                            <h6><b>Onboard Devices</b></h6>
                          </div>
                        </div>
                        <div class="col-12 px-0">
                          <div class="form-group mt-3 device">
                            <label class="f1 required">Audio Input Devices</label> <br>
                            <mat-form-field>
                              <mat-select placeholder="Please Select Audio Input Device" class="pl-3" (click)="getDeviceList()">
                                <div *ngFor="let device of devicesArray">
                                  <mat-option *ngIf="device.kind == 'audioinput'" style="z-index: 10000000;"
                                    [value]="device">
                                    <p (click)="selectedAudioDevice = device">{{device.label}}</p>
                                  </mat-option>
                                </div>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <!--  video-options -->
                        <div class="form-group mt-3 device">
                          <label class="f1 required">Video Input Devices</label><br>
                          <mat-form-field>
                            <mat-select placeholder="Please Select Video Input Device" class="pl-3"  (click)="getDeviceList()">
                              <div *ngFor="let device of devicesArray">
                                <mat-option *ngIf="device.kind == 'videoinput'" style="z-index: 10000000;"
                                  [value]="device">
                                  <p (click)="selectedCamera = device" >{{device.label}}</p>
                                </mat-option>
                              </div>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div *ngIf="tabName == 'encodingDevices'">
                    <form class="d-flex">
                      <div class="container">
                        <div class="row mt-2">
                          <div class="col-12">
                            <h6><b>Encoded Devices</b></h6>
                            <p>Here is the Generated Streaming Url and the Streaming Key</p>
                          </div>
                        </div>
                        <div class="col-12 px-0">
                          <div class="form-group">
                            <label>Streaming URL</label><br>
                            <input *ngIf="testStream.rtmpUrl" type="text" [value]="rtmp" [disabled]="true"
                              class="inputBox">
                          </div>
                          <div class="form-group">
                            <label>Streaming Key</label><br>
                            <input *ngIf="testStream.streamId" type="text" [value]="testStream.streamId"
                              [disabled]="true" class="inputBox">
                          </div>

                          <p class="m-0"><b>Instructions:</b></p>
                          <ul class="pl-3">
                            <li>Start the stream on the encoder.</li>
                            <li>Return to the stream on Virtuosica.</li>
                            <li>Start stream on Virtuosica.</li>
                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-12 mt-3 mb-2 mb-md-5">
                    <button type="submit" class="btn hftrdd mb-3 mb-md-0"
                      [routerLink]="['/ArtistDashboard/artistEventDetail']" [queryParams]="{ 'refresh': true}">Back</button>
                    <button type="submit" class="btn defrqqa edcae mb-3 mb-md-0 mr-3" (click)="startCamera()">{{btnText}}</button>
                    <button *ngIf="pageTitle == 'Sound Check'" type="submit" class="btn defrqqa edcae"
                      (click)="shareStreamEvent()">Share Stream Event</button>
                  </div>
                  <div *ngIf="tabName == 'onboardDevices'">
                    <div class="col-12 mt-3 mb-5 px-0 px-md-5" style="width: 80%;">
                      <div class="col-12 text-center py-3"
                        style="background: #2e2d2d; border: 1px solid white; border-radius: 15px;">
                        <p>Having trouble with your {{pageTitle}} ?</p>
                        <button type="submit" class="btn hftrdd" style="color: black !important;">Customer
                          Support</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Onboard Device Sound Check Video  -->
<div *ngIf="selectTab == 'onboardSoundCheckVideo'" class="deaa mt-3" id="rockband">
  <div class="container white">
    <div class="row">
      <div class="col-12">
        <h4> {{pageTitle}} </h4>
      </div>
    </div>
  </div>
  <hr class="mt-0 mb-3" style="border-bottom: 1px solid white;">
  <div class=" modalBg my-3" role="document">
    <div class=" modalBg judhr" style="border-radius: 10px !important;">
      <div class="modal-body white">
        <div class="container">
          <div class="row">
            <div class="col-12 px-0 px-md-2">
              <div *ngIf="tabName == 'encodingDevices'" class="row">
                <!-- <app-live-player [videoLink]="videoLink" [poster]="poster"></app-live-player> -->
                <!-- <iframe width="850" height="350" src="https://antmedia.virtuosica.com:5443/WebRTCAppEE/play.html?name=932438141506483433128950" frameborder="1" allowfullscreen></iframe> -->
                <iframe *ngIf="mobileView == false" width="750" height="350" [src]="videoLink" frameborder="1" allowfullscreen></iframe>
                <iframe *ngIf="mobileView == true" width="290" height="210" [src]="videoLink" frameborder="1" allowfullscreen></iframe>
              </div>
              <div *ngIf="tabName == 'onboardDevices'" class="row">
                <!--  height: 500px; -->
                <div class="col-8 pl-2 pr-0">
                  <video #mergeSource autoplay playsinline id="videoPlayer" class="video-viewer pr-2"
                    width="610px" [ngStyle]="{'display':ismergeView ?'block':'none'}"></video>
                  </div>
                <div class="col-4">
                  <div class="comment-wrapper">
                    <div class="scroll-x-wrapper" id="div1">
                      <div class="comment-section" *ngFor="let item of messages; let i = index">
                        <div class="comment-section" [ngStyle]="{'display':(item.message.messageTime+10000)<=timeCountCheck?'none':''}">
                          <div class="comment"[ngStyle]="{'opacity ': (messages.length == (i+1) || messages.length == (i+2)) ? '1' : ( messages.length == (i+3)  || messages.length == (i+4)) ? '0.8': messages.length == (i+5) ? '0.6' : messages.length == (i+6)  ? '0.4' : '0.2'}">
                          <div class="img-wrapper">
                            <img
                              [src]="item.message && item.message.currentUserProfile ? item.message.currentUserProfile : 'https://bootdey.com/img/Content/avatar/avatar3.png'"
                              alt="" style="object-fit: cover;">
                          </div>
                        </div>
                        <div class="comment-content"[ngStyle]="{'opacity ': (messages.length == (i+1) || messages.length == (i+2)) ? '1' : ( messages.length == (i+3)  || messages.length == (i+4)) ? '0.8': messages.length == (i+5) ? '0.6' : messages.length == (i+6)  ? '0.4' : '0.2'}">
                          <p class="username-wrapper"> {{ item.message.username | titlecase}} <span
                              style="font-size: 12px;"> {{item.message.messageTime |
                              date:'shortTime'}} </span>
                          </p>
                          <p class="message"> {{ item.message.typeMessage }} </p>
                        </div>
                        </div>
                      </div>
                    </div>
                    <form class="form" #commentForm="ngForm">
                      <div class="send-message">
                        <div class="input-wrapper">
                          <input type="text" autocomplete="off" class="form-control" name="message"
                            [(ngModel)]="commentedText" id="basic-addon2"
                            (keyup.enter)="addComment($event)">
                          <div class="emozi-attach" >
                            <span class="cursor-pointer" style="background: transparent;max-height: 72%;" (click)="emojiView=!emojiView">
                              <img src="../../../../../../assets/image.png" alt="">
                            </span>
                            <div *ngIf="emojiView == true" class="pl-2" style="width: 60px; border: 2px solid red;
                            border-radius: 25px; background: white; position: absolute; top: -326px; left: -20px;
                            height: 315px; overflow-y: scroll; " >
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😩';addComment();">😩</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😞';addComment();">😞</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '🤑';addComment();">🤑</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😐';addComment();">😐</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😊';addComment();">😊</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '⭐';addComment();">⭐</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😀';addComment();">😀</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '🍾';addComment();">🍾</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '👌';addComment();">👌</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '👍';addComment();">👍</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '👎';addComment();">👎</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '👏';addComment();">👏</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😍';addComment();">😍</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '😘';addComment();">😘</p>
                              <p class="pointer mb-0" style="font-size: 30px;" (click)="emoji = '🌟';addComment();">🌟</p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- d-flex justify-content-end -->
                <div class="col-7" style="position: relative; top: -38px;">
                  <button class="btn play" *ngIf="this.liveStream?.getVideoTracks()[0].enabled"
                    (click)="videoControls('pause')"><i class="fa fa-2x fa-pause-circle-o" style="position: relative; top: -3px;"></i></button>
                  <button class="btn pause" *ngIf="!this.liveStream?.getVideoTracks()[0].enabled"
                    (click)="videoControls('play')"><i class="fa fa-2x fa-play-circle-o" style="position: relative; top: -3px;"></i></button>
                  <!-- <button class="btn pause" *ngIf="maxi && mobileView == false" (click)="maxi = !maxi">
                    <i class="fa fa-2x fa-window-minimize" style="position: relative; top: -10px;"></i></button>
                  <button class="btn play" *ngIf="!maxi && mobileView == false" (click)="maxi = !maxi">
                    <i class="fa fa-2x fa-window-maximize" style="position: relative; top: -3px;"></i></button> -->
                </div>
              </div>

              <!-- <button *ngIf="this.currentAudio?.getAudioTracks()[0].enabled" (click)="videoControls('unmute')"><i class="fa fa-microphone"></i></button>
              <button *ngIf="!this.currentAudio?.getAudioTracks()[0].enabled" (click)="videoControls('mute')"><i class="fa fa-microphone-slash"></i></button> -->
            </div>
          </div>
          <div class="row my-1">
            <div class="col-12 px-0 px-md-0">
              <p class="pb-0">If it is recommended you moniter the stream on another device, and if none is available,
                in a seperate browser window</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <button *ngIf="pageTitle == 'Live Stream' && tabName == 'onboardDevices'" [disabled]="onBoardLiveStreamingBtnText == 'Stop Streaming'" type="submit"
                class="btn defrqqa edcae" (click)="liveStreamingCtrl('start')"> Start Streaming
              </button>
              <button *ngIf="pageTitle == 'Live Stream' && tabName == 'onboardDevices'" [disabled]="endConcertBtn == false" type="submit"
                class="btn defrqqa edcae ml-0 ml-md-3 mt-3 mt-md-0" data-toggle="modal" data-target="#eventEndedModal">End Streaming</button>
              <button *ngIf="pageTitle == 'Sound Check'" type="submit" class="btn defrqqa edcae" (click)="leaveEvent()">Leave Event</button>
              <button *ngIf="pageTitle == 'Live Stream' && tabName == 'encodingDevices'" type="submit" class="btn hftrdd" (click)="selectTab = 'encodedSoundCheckVideo'">Back</button>
              <button *ngIf="pageTitle == 'Live Stream' && tabName == 'encodingDevices'" type="submit" class="btn defrqqa edcae mt-3 mt-md-0" data-toggle="modal" data-target="#eventEndedModal">Leave Concert</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Encoded Device Sound Check Video  -->
<div *ngIf="selectTab == 'encodedSoundCheckVideo'" class="deaa mt-4" id="rockband">
  <div class="container white">
    <div class="row">
      <div class="col-12">
        <h4> {{pageTitle}} </h4>
      </div>
    </div>
  </div>
  <hr class="mt-0 mb-4" style="border-bottom: 1px solid white;">
  <div class=" modalBg my-4" role="document">
    <div class=" modalBg judhr" style="border-radius: 10px !important;">
      <div class="modal-body white px-0 px-md-2">
        <div class="container">
          <div *ngIf="mobileView == false" class="row">
            <div class="col-12 px-0 px-md-2 breakLine">
              <img src="../../../../../../../assets/soundCheckBg.png" width="1010px" alt="image">
            </div>
            <h5 style="position: relative; top: -130px; left: 70px; ">Streaming through third party devices</h5>
          </div>
          <div *ngIf="mobileView == true" class="row">
            <div class="col-12 px-0 px-md-2 breakLine">
              <img src="../../../../../../../assets/soundCheckBg.png" width="280" alt="image">
            </div>
            <h6 style="position: relative; top: -45px; left: 4px; ">Streaming through third party devices</h6>
          </div>
          <div class="row">
            <div class="col-12">
              <p><b>You have select the third party streaming software</b></p>
              <p>Enter the stream URL and stream key to connect your third party streaming software to Virtuosica.
                Please note that Virtuosica cannot control the quality or assist with technical issues related to third
                party software.</p>
            </div>
            <div class="col-12">
              <p class="mb-1">Stream Key URL</p>
              <div class="row pl-1" style="border: 1px solid white; border-radius: 20px;">
                <div class="col-10 col-md-11 px-0 px-md-2 breakLine">
                  <p *ngIf="testStream.rtmpUrl" class="my-2 ml-0 ml-md-3">{{rtmp}}</p>
                </div>
                <div class="col-1">
                  <i ngxClipboard [cbContent]="rtmp" title="Copy" class="fa fa-clipboard ml-0 ml-md-5 my-4"></i>
                </div>
              </div>
            </div>
            <div class="col-12 mt-3 px-0 px-md-2">
              <p class="mb-1">Stream Key</p>
              <div class="row ml-0 mr-1 mr-md-0 ml-md-1" style="border: 1px solid white; border-radius: 20px;">
                <div class="col-10 col-md-11 px-0 px-md-2">
                  <p *ngIf="testStream.streamId" class="my-2 ml-2 ml-md-3">{{testStream.streamId}}</p>
                </div>
                <div class="col-1 px-0 px-md-0">
                  <i ngxClipboard [cbContent]="testStream.streamId" title="Copy" class="fa fa-clipboard ml-0 ml-md-5 my-2"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 my-5">
              <button type="submit" class="btn hftrdd ml-5 ml-md-0" (click)="selectTab = 'mainTab'">Back</button>
              <button type="submit" (click)="selectTab = 'onboardSoundCheckVideo'" class="btn defrqqa edcae ml-3 ml-md-3 mt-3 mt-md-0"
                style="width: 230px;"> {{embededBtnText}} </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Event Ended Modal -->
<div class="modal fade" data-backdrop="false" id="eventEndedModal" tabindex="-1" role="dialog"
  aria-labelledby="eventEndedModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg">
      <div class="modal-body white">
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" class="white">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container my-5">
          <div class="row">
            <div class="col-12 text-center">
              <h5> Are you sure? This event is ongoing </h5>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button type="submit" class="btn hftrdd mt-5 mr-4" style="width: 200px;" data-dismiss="modal"
                aria-label="Close">Cancel</button>
              <button class="btn defrqqa mt-5" data-dismiss="modal" (click)="openModal()" style="width: 200px;">End
                Streaming</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Event Ended Modal 2 -->
<div class="modal" [ngClass]="{'d-block': eventEndModal2, 'd-none': !eventEndModal2}" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg">
      <div class="modal-body white">
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" (click)="eventEndModal2 = false">
                <span aria-hidden="true" class="white">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container my-5">
          <div class="row">
            <div class="col-12 text-center">
              <h5> Please make sure the Event Owner knows you're ending this stream. </h5>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button type="submit" class="btn hftrdd mt-5 mr-4" style="width: 200px;"
                (click)="eventEndModal2 = false">Go Back</button>
              <button class="btn defrqqa mt-5" style="width: 200px;" (click)="liveStreamingCtrl('end')">End
                Streaming</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Congrants Modal -->
<div class="modal" id="congratsModal" [ngClass]="{'d-block': congratsModal, 'd-none': !congratsModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg">
      <div class="modal-body white">
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" (click)="congratsModal = false">
                <span aria-hidden="true" class="white">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container my-5">
          <div class="row">
            <div class="col-12 text-center">
              <h5> Congrats on another great show! Please keep in mind that you must supply a set list before you can
                receive payout for this gig. Have you submitted a set list? </h5>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button type="submit" class="btn hftrdd mt-5 mr-4" style="width: 200px;"
                [routerLink]="['/ArtistDashboard/artistEventDetail']" [queryParams]="{ 'refresh': true}">Skip for Now</button>
              <button class="btn defrqqa mt-5" (click)="cameraCtrl()"
                [routerLink]="['/ArtistDashboard/proReporting' , eventId]"[queryParams]="{ 'refresh': true}" style="width: 200px;">Go To Setlist</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Encoded device end streaming warning Modal -->
<div class="modal" id="warningModal" [ngClass]="{'d-block': warningModal, 'd-none': !warningModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg">
      <div class="modal-body white">
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-right">
              <!-- <button type="button" class="close" (click)="congratsModal = false">
                <span aria-hidden="true" class="white">&times;</span>
              </button> -->
            </div>
          </div>
        </div>
        <div class="container my-5">
          <div class="row">
            <div class="col-12 text-center">
              <h5> You should also off the streaming from that application from which you are started streaming </h5>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button class="btn defrqqa mt-5" (click)="congratsModal = true; warningModal = false;" style="width: 200px;">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- leave page warning modal -->
<div class="modal" [ngClass]="{'d-block': openWarnModal, 'd-none': !openWarnModal}" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg">
      <div class="modal-body white">
        <div class="container mb-5">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" (click)="openWarnModal = false">
                <span aria-hidden="true" class="white">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container my-5">
          <div class="row">
            <div class="col-12 text-center">
              <h5> If you leave this page your streaming will Stop. </h5>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button type="submit" class="btn hftrdd mt-5 mr-4" style="width: 200px;"
                (click)="openWarnModal = false">Go Back</button>
              <button class="btn defrqqa mt-5" style="width: 200px;" (click)="confirmLeavePage()">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
