import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RefreshService } from 'src/app/service/refresh.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { AuthServiceService } from 'src/app/service/auth-service.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { LoginModal } from 'src/app/service/models/auth.modals';
import { UserService } from 'src/app/service/user.service';
import { COMMA } from '@angular/cdk/keycodes';
import { debounceTime, startWith } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MainService } from 'src/app/service/main.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
@Component({
  selector: 'app-audience-home',
  templateUrl: './audience-home.component.html',
  styleUrls: ['./audience-home.component.scss']
})
export class AudienceHomeComponent implements OnInit {
  @ViewChild('placesRef') placesRef!: GooglePlaceDirective;
  settingRoute: any[] = [];
  activeLink: number = 0;
  newToken: any;
  interval: any;
  interVal: any;
  isProfileOpened: boolean = false;
  browser: any;
  login: LoginModal;
  showRightSideMenu: boolean;
  searchBox: boolean = false;
  path: any = this.route.queryParams
  videoQualityList: any;
  videosetList: any;
  onSiteList: any;
  audioSetupList: any;
  PerformanceEnvironmentList: any;
  allSelected = false;
  allSelected1 = false;
  allSelected2 = false;
  allSelected3 = false;
  allSelected4 = false;
  bandSize: any = [];
  searchKeywords: any[] = [];
  searchgen: string[] = [];
  selectable = true;
  selectablegen = true;
  removable = true;
  removablegen = true;
  instrumentList: any;
  genCtrl = new FormControl();
  instumentCtrl = new FormControl();
  deviceCtrl = new FormControl();
  separatorKeysCodess: number[] = [COMMA];
  separatorKeysCodesgen: number[] = [COMMA];
  genList: any;
  searchInstuments: string[] = [];
  advanceSearchForm!: FormGroup;
  isNotificationActive: boolean = false;
  profileClick = 0;
  notificationClick = 0;
  notificationContainer: any;
  notification: any[] = [];
  unreadNotification: any;
  @ViewChild('genInput')
  genInput!: ElementRef<HTMLInputElement>;
  @ViewChild('instInput')
  instInput!: ElementRef<HTMLInputElement>;
  artistShow: boolean = false;
  notificationData: boolean;
  location: string[] = [];
  locationCtrl = new FormControl();
  timeZoneArray: any = [];
  separatorKeysCodes: number[] = [COMMA];
  logOut: boolean = false;
  requestType = 'SETTINGS';
  userStatus: any;
  tabName = 'home';
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  constructor(
    private router: Router,
    private _refresh: RefreshService,
    private _SessionService: SessionService,
    private _auth: AuthServiceService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private sessionStorage: SessionService,
    private _NotifierService: NotifierService,
    private _UserService: UserService,
    private mainservice: MainService,
    private observer: BreakpointObserver
  ) {
    this.notificationData = false;
    this.showRightSideMenu = false;
    this.login = new LoginModal();
    history.pushState(null, window.location.href);
  }

  ngOnInit(): void {
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.getNotifications();
    this.interVal = setInterval(() => {
      this.getNotifications();
    }, 60000);
    this.instumentCtrl.valueChanges.pipe(
      debounceTime(500),
      startWith(null)
    ).subscribe((value: string | null) => {
      if (value) {
        this.mainservice.searchInstuments(value).then((res: any) => {
          if (res && res.isSuccess) {
            this.instrumentList = res.data;
          }
        })
      }
      this.mainservice.getAllStripeCustomer().then((data) => {
        if (data && data.isSuccess) {
        }
      })
    })

    this.genCtrl.valueChanges.pipe(
      debounceTime(500),
      startWith(null)
    ).subscribe((value: string) => {
      if (value) {
        this.mainservice.searchGenres(value).then((res: any) => {
          if (res && res.isSuccess) {
            this.genList = res.data;
          }
        })
      }
    })
    this.userStatus = this.sessionStorage.getSession('status');
    let refreshTokenLog: any[] = [];
    this.interval = setInterval(() => {
      this.refreshToken();
      refreshTokenLog.push({ message: 'refresh token api called', dateTime: new Date() });
      localStorage.setItem('refreshTokenLog', JSON.stringify(refreshTokenLog));
    }, 150000);

    this.advanceSearchForm = this.fb.group({
      audioSetupDetails: new FormControl(),
      onsiteDetails: new FormControl(),
      performanceEnvironmentDetails: new FormControl(''),
      videoQualityDetails: new FormControl(''),
      videoSetupDetails: new FormControl(''),
      feeRangeFrom: new FormControl(''),
      feeRangeTo: new FormControl(''),
      location: new FormControl(''),
      bandSizes: new FormControl(''),
      experience: new FormControl(''),
      genres: new FormControl(''),
      instruments: new FormControl(''),
    })
    this.getOnSiteList();
    this.getVideosetList();
    this.getVideoQualityList();
    this.getAudioSetupList();
    this.getPerformanceEnvironmentList();
    this.getBandSize()
  }

  @ViewChild('select')
  select!: MatSelect;
  @ViewChild('select1')
  select1!: MatSelect;
  @ViewChild('select2')
  select2!: MatSelect;
  @ViewChild('select3')
  select3!: MatSelect;
  @ViewChild('select4')
  select4!: MatSelect;

  toggleProfile() {
    this.isProfileOpened = !this.isProfileOpened;
  }

  ngAfterViewInit() {
    this.placesRef.options.componentRestrictions = { country: 'US' };
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
      }
    });
  }

  handleAddressChange(address: Address) {
    this.location.push(address.formatted_address);
    this.locationCtrl.setValue(null);
    this.mainservice.getTimeZone(address.address_components[3].long_name).then((data: any) => {
      if (data && data.isSuccess) {
        this.timeZoneArray = data.data
      }
    })
  }

  removeLocation(address: string): void {
    const index = this.location.indexOf(address);
    if (index >= 0) {
      this.location.splice(index, 1);
    }
  }

  refreshToken() {
    this._refresh.refreshToken().then(data => {
      if (data.isSuccess) {
        this.newToken = data.data;
        this._SessionService.removeSession('token');
        this._SessionService.setSessions({ token: this.newToken });
      } else { }
    })
  }

  logoutUser() {
    this.logOut = false
    clearInterval(this.interval);
    clearInterval(this.interVal);
    const device = this.browser;
    this._auth.logout(device).then(data => {
      if (data && data.isSuccess) {
        this._SessionService.removeSessions();
        this._SessionService.removeSession('email');
        this._SessionService.removeSession('refreshToken');
        this._SessionService.removeSession('userId');
        this._SessionService.removeSession('refreshTokenLog');
        this._SessionService.removeSession('token');
        this._SessionService.removeSession('newtoken');
        this._UserService.setLogedUser(null);
        this.router.navigate([`/login`])
      } else {
        this._NotifierService.showError(data.message);
      }
    })


  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  toggleAllSelection1() {
    if (this.allSelected1) {
      this.select1.options.forEach((item: MatOption) => item.select());
    } else {
      this.select1.options.forEach((item: MatOption) => item.deselect());
    }
  }
  toggleAllSelection2() {
    if (this.allSelected2) {
      this.select2.options.forEach((item: MatOption) => item.select());
    } else {
      this.select2.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleAllSelection3() {
    if (this.allSelected3) {
      this.select3.options.forEach((item: MatOption) => item.select());
    } else {
      this.select3.options.forEach((item: MatOption) => item.deselect());
    }
  }
  toggleAllSelection4() {
    if (this.allSelected4) {
      this.select4.options.forEach((item: MatOption) => item.select());
    } else {
      this.select4.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  optionClick1() {
    let newStatus = true;
    this.select1.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected1 = newStatus;
  }

  optionClick2() {
    let newStatus = true;
    this.select2.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected2 = newStatus;
  }

  optionClick3() {
    let newStatus = true;
    this.select3.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected3 = newStatus;
  }

  optionClick4() {
    let newStatus = true;
    this.select4.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected4 = newStatus;
  }

  getOnSiteList() {
    this.mainservice.getOnsiteList().then((data: any) => {
      if (data && data.isSuccess) {
        this.onSiteList = data.data;
      }
    })
  }

  getVideosetList() {
    this.mainservice.getVideoSetupList().then((data: any) => {
      if (data && data.isSuccess) {
        this.videosetList = data.data
      }
    })
  }

  getVideoQualityList() {
    this.mainservice.getVideoQualityList().then((data: any) => {
      if (data && data.isSuccess) {
        this.videoQualityList = data.data
      }
    })
  }

  getAudioSetupList() {
    this.mainservice.getAudioSetupList().then((data: any) => {
      if (data && data.isSuccess) {
        this.audioSetupList = data.data
      }
    })
  }

  getPerformanceEnvironmentList() {
    this.mainservice.getPerformanceEnvironmentList().then((data: any) => {
      if (data && data.isSuccess) {
        this.PerformanceEnvironmentList = data.data
      }
    })
  }

  getBandSize(): any {
    this.mainservice.getBandSize().then((data: any) => {
      if (data && data.isSuccess) {
        this.bandSize = data.data
      }
    })
  }

  remove(instrument: string): void {
    const index = this.searchInstuments.indexOf(instrument);
    if (index >= 0) {
      this.searchInstuments.splice(index, 1);
    }
  }

  removegen(gen: string): void {
    const index = this.searchgen.indexOf(gen);
    if (index >= 0) {
      this.searchgen.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.searchInstuments.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.deviceCtrl.setValue(null);
  }

  addgen(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.searchgen.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.genCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.advanceSearchForm.controls['instruments'].setValue(event.option.viewValue)
    this.searchInstuments.push(event.option.viewValue);
    this.instInput.nativeElement.value = '';
    this.instumentCtrl.setValue(null);
  }

  selectedgen(event: MatAutocompleteSelectedEvent): void {
    this.searchgen.push(event.option.viewValue);
    this.genInput.nativeElement.value = '';
    this.genCtrl.setValue(null);
  }


  searchQuery(event: any) {
    if (event.target.value) {
      this.router.navigate(['/audienceDashboard/audienceSimpleSearch'], { queryParams: { keyWord: event.target.value } })
    } else {
      this.router.navigate(['/audienceDashboard/audienceHome'])
    }
  }

  advancedSearch() {
    let MyArrayType = [
      { type: 'audioSetupDetails', text: 'audioSetup' },
      { type: 'performanceEnvironmentDetails', text: 'environment' },
      { type: 'videoQualityDetails', text: 'videoQuality' },
      { type: 'videoSetupDetails', text: 'videoSetups' },
      { type: 'onsiteDetails', text: 'onsite' },
      { type: 'feeRangeTo', text: 'feeRangeTo' },
      { type: 'feeRangeFrom', text: 'feeRangeFrom' },
      { type: 'bandSizes', text: 'bandSize' },
      { type: 'location', text: 'locations' },
      { type: 'genres', text: 'genres' },
      { type: 'instruments', text: 'instrumentsPlayed' },
      { type: 'experience', text: 'experience' },
    ];
    let param, paramholder
    let count = 0
    if (this.searchgen) {
      this.advanceSearchForm.controls['genres'].setValue(this.searchgen);
    }

    for (let i = 0; i < 12; i++) {
      if (this.advanceSearchForm.controls[MyArrayType[i].type].value != null &&
        this.advanceSearchForm.controls[MyArrayType[i].type].value != "" &&
        this.advanceSearchForm.controls[MyArrayType[i].type].value != undefined) {
        if (count == 0) {
          param = MyArrayType[i].text + '=' + this.advanceSearchForm.controls[MyArrayType[i].type].value
        }
        else {
          param = paramholder + '&' + MyArrayType[i].text + '=' + this.advanceSearchForm.controls[MyArrayType[i].type].value
        }
        paramholder = param
        count++
        this.searchKeywords[i] = (this.advanceSearchForm.controls[MyArrayType[i].type].value)
      }
      else {
      }
    }
    if (count > 0)
      this.getAdvanceSearchData(param)
    else if (count == 0)
      this._NotifierService.showError('Atleast Select One Search Criteria')
  }

  getAdvanceSearchData(param: any) {
    this._SessionService.setSessions({ advanceSearch: param });
    this._SessionService.setSessions({ searchKeywords: this.searchKeywords });
    this.showRightSideMenu = true;
    this.searchBox = false;
    this.router.navigate(['/audienceDashboard/audienceAdvancedSearch'])
  }
  closeAdvanceSearch() {
    this.advanceSearchForm.reset();
    this.searchgen = [];
    this.searchInstuments = [];
    this.location = [];
  }

  searchBoxController() {
    this.searchBox = !this.searchBox
  }
  sideBarController() {
    this.router.navigate(['/audienceDashboard/audienceHome'], { queryParams: { home: true } })
  }
  ngDoCheck() {
    if (this.path['_value'].home) {
      this.showRightSideMenu = false;
    }
  }


  toggleNotification() {
    this.isNotificationActive = !this.isNotificationActive;
    if (this.isNotificationActive) {
      this.getNotifications();
    }
  }

  onClickedOutside(event?: any) {
    this.profileClick++;
    if (this.isProfileOpened && this.profileClick > 1) {
      this.isProfileOpened = false;
      this.profileClick = 0
    }
  }

  onClickNotification(event: any) {
    this.notificationClick++;
    if (this.isNotificationActive && this.notificationClick > 0) {
      this.isNotificationActive = false;
      this.notificationClick = 0;
    }
  }

  getNotifications() {
    this.notificationData = true;
    this.notificationContainer = [];
    this.mainservice.getNotification().then(data => {
      if (data && data.isSuccess) {
        this.notificationData = false;
        this.notification = data.data.contents;
        this.unreadNotification = data.data.totalUnreadCount;
        for (let key in data.data.contents) {
          const modifydata = {
            key: key,
            item: data.data.contents[key]
          }
          this.notificationContainer.push(modifydata);
        }
      } else {
        this.notificationData = false;
        this._NotifierService.showError(data.message);
      }

    })
  }


  markAllRead() {
    let body = {};
    this.mainservice.markAllRead(body).then(data => {
      if (data && data.isSuccess) {
        this.getNotifications();
      } else {
        this._NotifierService.showError(data.message);
      }
    })
  }

  removeAllNotification() {
    this.mainservice.removeAllNotification().then(data => {
      if (data && data.isSuccess) {
        this.getNotifications();
        this._NotifierService.showSuccess(data.message)
      } else {
        this._NotifierService.showError(data.message);
      }
    })
  }

  markRead(id: any, read: any) {
    if (read === false) {
      this.mainservice.markRead(id).then(data => {
        if (data && data.isSuccess) {
          this._NotifierService.showSuccess(data.message);
          this.getNotifications();
        } else {
          this._NotifierService.showError(data.message);
        }
      })
    }
  }

  removeNotification(id: any) {
    this.mainservice.removeNotification(id).then(data => {
      if (data && data.isSuccess) {
        this.getNotifications();
        this._NotifierService.showSuccess(data.message)
      } else {
        this._NotifierService.showError(data.message);
      }
    })
  }

  footer() {
    this.isNotificationActive = false;
  }

  navigateOn(type: any, pId?: any, eId?: any) {
    if (type === "PROPOSAL_ACCEPTED" || type === "PROPOSAL_DECLINED" || type === 'PENDING_PAYMENT' || type === "PROPOSAL_SUBMITTED" || type === "CONTRACT_DECLINED"
      || type === "CONTRACT_ACCEPTED"){
      this.tabName = 'events'
      this.router.navigate(['/audienceDashboard/eventPreview'], { queryParams: { eventId: eId, proposalId: pId, eventType: type }})
    } else if(type === "HIRING_PENDING" || type === "LIVE_EVENT"){
      this.tabName = 'events'
      this.router.navigate(['/audienceDashboard/eventPreview'], { queryParams: { eventId: eId, goLive: true } })
    } else if(type === "ACCOUNT_BAN"){
      this.router.navigate([`/audienceDashboard/audienceHome`])
    } else if(type === "SHARED_LIVE_EVENTS"){
      this.router.navigate(['/audienceDashboard/audienceLiveStreaming'], { queryParams: { id: eId, type: 'invite' } })
    }
  }

}
