<div class="container mt-3">
  <div class="row">
    <div class="col-12">
      <iframe width="750" height="350" [src]="videoLink" frameborder="1" allowfullscreen></iframe>
      <!-- <app-live-player [videoLink]="videoLink" [poster]="poster"></app-live-player> -->
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button type="submit" class="btn hftrdd" [routerLink]="['ArtistDashboard/artistHome']">Back</button>
    </div>
  </div>
</div>
