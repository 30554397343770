import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/service/helper/session.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-artist-see-all',
  templateUrl: './artist-see-all.component.html',
  styleUrls: ['./artist-see-all.component.scss']
})
export class ArtistSeeAllComponent implements OnInit {
  newArtist: any;
  currentPage = 0;
  max = 5;
  rate = 0;
  isReadonly = true;
  artistData: any;
  previewCertificate: any;
  showStarIcon: boolean;
  artistSeeAllData: boolean;
  hireNow: boolean = false;
  selectedArtistId: any;
  eventForm: FormGroup;
  existingEventList: any;
  tabName = 'exitingEvent';
  allSelected2 = false;
  userStatus: any;
  userId: any;
  pageNo: number = 0;
  pageSize: number = 16;
  showDropdown: boolean = false;
  url = environment.hubspotNavigateUrl;
  pageTitle: any;
  currentPath = this.router.url;
  userRole: any;

  constructor(
    private _MainService: MainService,
    private router: Router,
    private sessionStorage: SessionService,
    private _FormBuilder: FormBuilder,
    private _NotifierService: NotifierService,
    private activatedRoute: ActivatedRoute
  ) {
    this.showStarIcon = false;
    this.artistSeeAllData = false;
    this.eventForm = this._FormBuilder.group({
      existingEvent: ['', [Validators.required]],
    });
  }

  @ViewChild('select2')
  select2!: MatSelect;
  //  path: any = this.activatedRoute.queryParams

  ngOnInit(): void {
    this.userStatus = this.sessionStorage.getSession('status');
    this.userId = this.sessionStorage.getSession('userId');
    this.userRole = this.sessionStorage.getSession('roles');
    this.getExistingEventList();
    console.log(this.currentPath, 'rreigroi');

    if (this.currentPath == '/ArtistDashboard/artistSeeAll' || this.currentPath == '/audienceDashboard/seeAllArtist') {
      this.getAllNewArtist(this.pageNo, this.pageSize);
      this.pageTitle = 'New Artists';
    }
    else if (this.currentPath == '/ArtistDashboard/staffSeeAll' || this.currentPath == '/audienceDashboard/seeAllStaff') {
      this.getAllStaffPicks(this.pageNo, this.pageSize);
      this.pageTitle = 'Staff Picks';
    }
    else if (this.currentPath == '/ArtistDashboard/favArtist' || this.currentPath == '/audienceDashboard/audienceFavSearch') {
      this.getAllFavourites(this.pageNo, this.pageSize);
      this.pageTitle = 'Favourite Artists';
    }
  }
  getExistingEventList() {
    this._MainService.getExistingEventList().then((data: any) => {
      if (data && data.isSuccess) {
        if (data.message == 'No records found') {
          let data = {
            eventName: 'No Records Found'
          }
          this.existingEventList.push(data)
        }
        else
          this.existingEventList = data.data;
      } else {
        this._NotifierService.showError(data.message);
      }
    })
  }


  getAllNewArtist(pageNo: number, pageSize: number) {
    this.artistSeeAllData = true;
    this._MainService.getAllNewArtist(pageNo, pageSize).then((data) => {
      if (data && data.isSuccess) {
        this.artistSeeAllData = false;
        this.newArtist = data.data.content;
      } else {
        this.artistSeeAllData = false;
        this._NotifierService.showError(data.message);
      }
    })
  }
  getAllFavourites(pageNo: number, pageSize: number) {
    this.artistSeeAllData = true;
    this._MainService.getAllFavourites(pageNo, pageSize).then(data => {
      if (data && data.isSuccess) {
        this.artistSeeAllData = false;
        this.newArtist = data.data.content;
      } else {
        this.artistSeeAllData = false;
        this._NotifierService.showError(data.message);
      }
    })
  }
  getAllStaffPicks(pageNo: number, pageSize: number) {
    this.artistSeeAllData = true;
    this._MainService.getAllStaffPicks().then((data) => {
      if (data && data.isSuccess) {
        this.artistSeeAllData = false;
        this.newArtist = data.data;
      } else {
        this.artistSeeAllData = false;
        this._NotifierService.showError(data.message);
      }
    })
  }

  onScroll() {
    this.currentPage = this.currentPage + 1;

    if (this.currentPath == '/ArtistDashboard/artistSeeAll' || this.currentPath == '/audienceDashboard/seeAllArtist') {
      this._MainService.getAllNewArtist(this.currentPage, this.pageSize).then(data => {
        if (data && data.isSuccess) {
          if (data.data.content.length) {
            this.newArtist = [...this.newArtist, ...data.data.content];
          }
        }
      })
    }
    else if (this.currentPath == '/ArtistDashboard/favArtist' || this.currentPath == '/audienceDashboard/audienceFavSearch') {
      this._MainService.getAllFavourites(this.currentPage, this.pageSize).then(data => {
        if (data && data.isSuccess) {
          if (data.data.content.length) {
            this.newArtist = [...this.newArtist, ...data.data.content];
          }
        }
      })
    }
  }

  getArtistDetailsById(id: any) {
    this._MainService.getArtistDetailsById(id).then((data) => {
      if (data && data.isSuccess) {
        this.artistData = data.data;
        // this._NotifierService.showSuccess(data.message);
      } else {
        this._NotifierService.showError(data.message);
      }
    })
  }

  favoriteArtist(id: any) {
    this._MainService.addOrRemoveFavouriteArtist(id).then((data) => {
      if (data && data.isSuccess) {
        this.showStarIcon = data.data;
        if (this.currentPath == '/ArtistDashboard/artistSeeAll' || this.currentPath == '/audienceDashboard/seeAllArtist') {
          this.getAllNewArtist(this.pageNo, this.pageSize);
        }
        else if (this.currentPath == '/ArtistDashboard/staffSeeAll' || this.currentPath == '/audienceDashboard/seeAllStaff') {
          this.getAllStaffPicks(this.pageNo, this.pageSize);
        }
        else if (this.currentPath == '/ArtistDashboard/favArtist' || this.currentPath == '/audienceDashboard/audienceFavSearch') {
          this.getAllFavourites(this.pageNo, this.pageSize);
        }
        this._NotifierService.showSuccess(data.message);
      } else {
        this._NotifierService.showError(data.message);
      }
    })
  }


  certicatedPreview(id: any) {
    for (let certificate of this.artistData?.certificateDetails) {
      if (id === certificate.id) {
        this.previewCertificate = certificate.certificateUrl;
      }
    }
  }
  hireArtist() {
    let eventData = this.eventForm.controls['existingEvent'].value
    this.sessionStorage.setSessions({ selectedArtistId: this.selectedArtistId, });
    if (this.currentPath == '/ArtistDashboard/artistSeeAll' || this.currentPath == '/ArtistDashboard/staffSeeAll' || this.currentPath == '/ArtistDashboard/favArtist')
      this.router.navigate(['ArtistDashboard/artistInvitation'], { queryParams: { type: 'pub', id: eventData.eventId, hired: true } });
    else if (this.currentPath == '/audienceDashboard/seeAllArtist' || this.currentPath == '/audienceDashboard/seeAllStaff' || this.currentPath == '/audienceDashboard/audienceFavSearch')
      this.router.navigate(['audienceDashboard/inviteArtist'], { queryParams: { type: 'pub', id: eventData.eventId, hired: true } });
  }

  vPause() {
    const myVideo: any = document.getElementById("myVideo");
    myVideo.pause();
  }

  createNewEvent() {
    this.sessionStorage.setSessions({ selectedArtistId: this.selectedArtistId, });
    if (this.currentPath == '/ArtistDashboard/artistSeeAll' || this.currentPath == '/ArtistDashboard/staffSeeAll' || this.currentPath == '/ArtistDashboard/favArtist')
      this.router.navigate(['ArtistDashboard/event'], { queryParams: { createEvent: true, hired: true } });
    else if (this.currentPath == '/audienceDashboard/seeAllArtist' || this.currentPath == '/audienceDashboard/seeAllStaff' || this.currentPath == '/audienceDashboard/audienceFavSearch')
      this.router.navigate(['audienceDashboard/event'], { queryParams: { createEvent: true, hired: true } });
  }
  optionClick2() {
    let newStatus = true;
    this.select2.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected2 = newStatus;
  }

  sendMessage(id: any, name: any) {
    if (this.currentPath == '/ArtistDashboard/artistSeeAll' || this.currentPath == '/ArtistDashboard/staffSeeAll' || this.currentPath == '/ArtistDashboard/favArtist')
      this.router.navigate([`/ArtistDashboard/message`], { queryParams: { 'id': id, 'name': name, } })
    else if (this.currentPath == '/audienceDashboard/seeAllArtist' || this.currentPath == '/audienceDashboard/seeAllStaff' || this.currentPath == '/audienceDashboard/audienceFavSearch')
      this.router.navigate([`/audienceDashboard/message`], { queryParams: { 'id': id, 'name': name, } })
  }
  report() {
    this.showDropdown = false;
    var _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
    _hsq.push(["identify", {
      type: 'report'
    }]);
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.src = "//js.hs-scripts.com/9406878.js";
    document.body.appendChild(chatScript);
    var _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
    _hsq.push(["identify", {
      type: 'report'
    }]);
    _hsq.push(['trackPageView']);
  }
  shareProfile(id: any) {
    this._NotifierService.showSuccess('Link Copied')
    navigator.clipboard.writeText(this.url + '/userProfile?userId=' + id)
    this.showDropdown = false;
  }
  ngOnDestroy() {
    this.showDropdown = false;
  }
}
