<main class="pl-4 mb-5">
    <div class="container-fluid px-5 pt-3 pl-5 invite">
      <div class="row">
        <p>Invite</p>
      </div>
      <div class="row hgnb bot-m">
        <div class="nvhi hgnb col-md-6 col-lg-6 col-xl-6 text-center" style="cursor: pointer;"
          [ngClass]="selectTab == 'send' ? 'activeSelectTab' : ''" (click)="selectTab = 'send'">
          <span>Send Invitations</span>
        </div>
        <div class="nvhi hgnb col-md-6 col-lg-6 col-xl-6 text-center" style="cursor: pointer;"
          [ngClass]="selectTab == 'invite' ? 'activeSelectTab' : ''" (click)="inviteList()">
          <span>Invitation List</span>
        </div>
      </div>
      <div *ngIf="selectTab == 'send'">
        <div class="row tabContent">
          <div class="col-lg-6 col-xl-6 col-md-6">
            <div class="content">
              <p>How it works ?</p>
            </div>
            <div class="content2">
              <h4><img src="../../../../../../assets/invite/send.png" alt="no img"> Invite guests to view your Virtuosica event</h4>
              <p> Share your event with friends and family! Invite up to 10 guests for each Virtuosica event you create.</p>
            </div>
            <div class="content2">
              <h4><img src="../../../../../../assets/invite/create.png" alt="no img"> Can I invite people who aren't users of Virtuosica?</h4>
              <p>Yes, but they will need to register a free Virtuosica account before they can view your event. Existing Virtuosica users just need to log in.</p>
            </div>
            <div class="content2">
              <h4><img src="../../../../../../assets/invite/earn.png" alt="no img"> Watch Event</h4>
              <p>Watch and enjoy together! Regardless of where your friends and family are, they can enjoy your virtual event with you, in real time.</p>
            </div>
            <div class="content2">
              <img src="../../../../../../assets/invite/Group.png" alt="no img">
            </div>
          </div>
          <div class="col-lg-6 col-xl-6 col-md-6">
            <div class="content">
              <p>Invite Guests </p>
            </div>
            <div class="content3">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco...</p>
            </div>
            <form [formGroup]="inviteForm" style="padding:0px 30px" (submit)="shareLiveEvent()">
              <div class="form-group">
                <label>Name</label>
                <input type="text" formControlName="name" class="form-control" autocomplete="off"
                  placeholder="Enter the name here.">
                <div *ngIf="inviteForm.controls['name'].touched && !inviteForm.controls['name'].valid"
                  style="color: #ff0000a3;">
                  <span *ngIf="inviteForm.controls['name'].hasError('required')">
                    Name is required
                  </span>
                  <span *ngIf="inviteForm.controls['name'].hasError('pattern') ">
                    Please Enter Valid Name
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label>Email Id</label>
                <input type="text" formControlName="emailId" class="form-control" autocomplete="off"
                  placeholder="Enter the email id">
                <div *ngIf="inviteForm.controls['emailId'].touched && !inviteForm.controls['emailId'].valid"
                  style="color: #ff0000a3;">
                  <span *ngIf="inviteForm.controls['emailId'].hasError('required')">
                    Email is required
                  </span>
                  <span *ngIf="inviteForm.controls['emailId'].hasError('pattern') ">
                    Please Enter Valid Email Id
                  </span>
                </div>
              </div>
              <button type="submit" class="inviteButton button">
                <ng-container *ngIf="inviteFormSubmitting; else inviteFormSubmittingDone">
                  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Processing...
                </ng-container>
                <ng-template #inviteFormSubmittingDone>
                  Send Invite
                </ng-template>
              </button>
              <!-- <button type="submit" class="inviteButton">Send Invite</button> -->
            </form>
          </div>
        </div>
      </div>
      <div *ngIf="selectTab == 'invite'">
        <div class="row tabContent">
          <div class="col-lg-12 col-xl-12 col-md-12">
            <ng-container *ngIf="inviteData">
              <div style="text-align: center;">
                <sa-spinner-circular [size]="69" [thickness]="103" [speed]="111" color="#dd0031"
                  secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-circular>
              </div>
            </ng-container>
            <ng-container *ngIf="this.inviteUserList && this.inviteUserList.length">
              <table class="table flatTable">
                <thead class="tableHeading">
                  <tr class="tableRow">
                    <th>#</th>
                    <th>Invitee list</th>
                    <th>Email Id</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let invite of inviteUserList;let i= index">
                    <th scope="row">{{i+1}}</th>
                    <th style="font-weight: 400 !important">{{invite.name | titlecase}}</th>
                    <td>{{invite.email}}</td>
                    <td>{{invite.createdAt }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
            <ng-container>
              <div class="error-wrapper" *ngIf="!inviteUserList?.length  && !inviteData">
                <h1 style="text-align: center;color: white;">Oops</h1>
                <p class="noInvite">
                  No Invite Found
                </p>
              </div>
            </ng-container>
            <div class="col-md-12 px50 d-flex justify-content-center"
              *ngIf="this.inviteUserList && this.inviteUserList.length > 0">
              <pagination [boundaryLinks]="true" [totalItems]="inviteCount" (pageChanged)="pageChanged($event)"
                previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
              </pagination>
            </div>
          </div>
        </div>
      </div>

    </div>
  </main>
