import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessionService } from '../helper/session.service';
import { Router } from '@angular/router';
import { NotifierService } from '../helper/notifier.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiRoot: string;
  hubspotApiRoot : string;

  constructor(
    private _HttpClient: HttpClient,
    private _SessionService: SessionService,
    private Router: Router,
    private NotifierService: NotifierService
  ) {
    this.apiRoot = environment.baseUrl;
    this.hubspotApiRoot = environment.hubspotUrl;
  }

  postRequest<T>(
    url: string,
    body: any,
    options?: IRequestOptions
  ): Promise<T> {
    const { apiRoot, headers } = this.getApiRootAndHeaders(options);
    return this._HttpClient
      .post(`${apiRoot}${url}`, body, { headers })
      .toPromise()
      .then((res) => res as T)
      .catch((err) => this.handleCatchError<any>(err));
  }

  getRequest<T>(url: string, options?: IRequestOptions): Promise<T> {
    const { apiRoot, headers } = this.getApiRootAndHeaders(options);
    return this._HttpClient
      .get(`${apiRoot}${url}`, { headers })
      .toPromise()
      .then((res) => res as T)
      .catch((err) => this.handleCatchError<any>(err));
  }

  putRequest<T>(url: string, body: any, options?: IRequestOptions): Promise<T> {
    const { apiRoot, headers } = this.getApiRootAndHeaders(options);
    return this._HttpClient
      .put(`${apiRoot}${url}`, body, { headers })
      .toPromise()
      .then((res) => res as T)
      .catch((err) => this.handleCatchError<any>(err));
  }

  deleteRequest<T>(url: string, options?: IRequestOptions): Promise<T> {
    const { apiRoot, headers } = this.getApiRootAndHeaders(options);
    return this._HttpClient
      .delete(`${apiRoot}${url}`, { headers })
      .toPromise()
      .then((res) => res as T)
      .catch((err) => this.handleCatchError<any>(err));
  }

  downloadPdf(url: string, options?: IRequestOptions): Promise<any> {
    const { apiRoot, headers } = this.getApiRootAndHeaders(options);
    return this._HttpClient
      .get(`${apiRoot}${url}`, { headers, responseType: 'blob' })
      .toPromise()
      .then((res) => res as any)
      .catch((err) => this.handleCatchError<any>(err));
  }


  hubspotPostRequest<T>(
    url: string,
    body: any,
    options?: IRequestOptions
  ): Promise<T> {
    const { hubspotApiRoot, headers } = this.getApiRootAndHeaders(options);
    return this._HttpClient
      .post(`${this.hubspotApiRoot}${url}`, body, { headers })
      .toPromise()
      .then((res) => res as T)
      .catch((err) => this.handleCatchError<any>(err));
  }


  // handle errors
  private handleCatchError<T>(err: HttpErrorResponse) {
    if (err.status === 404 || (err.status === 0 && err.url === null)) {
      // tslint:disable-next-line: no-console
      return err.error;
    }
    if (err.status === 401) {
      // tslint:disable-next-line: no-console
      // this._refresh.refreshToken().then(res => {
      //   if(res && res.data) {
      //     this._SessionService.removeSession('token');
      //     this._SessionService.setSessions({ token: res.data });
      //   }
      // });
      this.NotifierService.showError('Session Expired');
      this._SessionService.removeSessions();
      this.Router.navigate(['/login']);
      return err.error;
    }
    if (
      err.status !== 0 &&
      err.status !== 200 &&
      err.status !== 401 &&
      err.status !== 404
    ) {
      // tslint:disable-next-line: no-console
      return err.error;
    }
    return err.error;
  }

  private getApiRootAndHeaders(options?: IRequestOptions): IRequestOptions {
    return {
      apiRoot: options && options.apiRoot ? options.apiRoot : this.apiRoot,
      headers: options && options.headers ? options.headers : new HttpHeaders(),
    };
  }
}

interface IRequestOptions {
  headers?: HttpHeaders;
  apiRoot?: string;
  hubspotApiRoot?:string;
}
