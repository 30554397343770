<div class="hgnb">
  <div class=" nvhi">
    <img src="assets/dashboard/arr.svg">
    <img src="assets/dashboard/Mask Group.png" class="pro-fil">
    <span>My Profile</span>
  </div>
  <div class="erfg">
    <button (click)="audienceProfileDetails();">Save</button>
    <button [routerLink]="['/audienceDashboard/details']" (click)="showDragDrop = false">Cancel</button>
  </div>
</div>
<div class="mt-4">
  <div class="khiu card ">
    <div class="card-header">
      <div class="tabbable-responsive">
        <div class="tabbable">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" (click)="toggleRequest('GENERAL')">
              <a class="nav-link active" id="first-tab" data-toggle="tab" href="#first" role="tab" aria-controls="first"
                aria-selected="true" [ngClass]="{'active': requestType === 'GENERAL'}"
                (click)="showDragDrop = false">General</a>
            </li>
            <li class="nav-item" (click)="toggleRequest('DETAILS')">
              <a class="nav-link" id="second-tab" data-toggle="tab" href="#second" role="tab" aria-controls="second"
                aria-selected="false" [ngClass]="{'active': requestType === 'DETAILS'}"
                (click)="showDragDrop = false">Details</a>
            </li>
            <li class="nav-item" (click)="toggleRequest('SETTINGS')">
              <a class="nav-link" id="sixth-tab" data-toggle="tab" href="#sixth" role="tab" aria-controls="sixth"
                aria-selected="false" [ngClass]="{'active': requestType === 'SETTINGS'}"
                (click)="showDragDrop = false">Settings</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="tab-content">
        <div class="row">
          <div class="col-lg-4">
            <div class="ckij">
              <div *ngIf="showDragDrop">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                  [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="16 / 9" [resizeToWidth]="256"
                  [cropperMinWidth]="149" [onlyScaleDown]="true" [roundCropper]="false"
                  [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
                  [style.display]="showCropper ? null : 'none'" (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                  (loadImageFailed)="loadImageFailed()"></image-cropper>
              </div>
              <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" *ngIf="showDragDrop"
                style="margin-right:127px" />
              <div class="edit-profile-option2">
                <button type="submit" class="eijh2" style="cursor: pointer;" *ngIf="showDragDrop"
                  (click)="cropDone()">Submit</button>
                <button type="submit" class="btn hftrdd" style="cursor: pointer;" *ngIf="showDragDrop"
                  (click)="showDragDrop = false">Cancel</button>
              </div>
              <div *ngIf="imageSpinnerDone" class="spinner">
                <sa-spinner-dotted [size]="59" [thickness]="103" [speed]="111" color="#dd0031"
                  secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-dotted>
              </div>
              <div style="background: #11111100; margin-left: 75px; margin-top: 40px; background-size: 100% 100%; height: 125px;width: 125px;">
                <img [src]="profilePicData?.avatarUrl ? profilePicData?.avatarUrl: 'assets/dashboard/Ellipse 2.png'"
                *ngIf="!showDragDrop && !imageSpinnerDone"width="100%" height="100%" style="border: 1px solid red; padding: 4px; border-radius: 50%; object-fit: cover;">
                </div>
              <div class="edit-profile-option" *ngIf="!showDragDrop">
                <i class="fa fa-pencil" aria-hidden="true" (click)="file.click()"></i>
              </div>
              <input type="file" accept="image/*" #file (change)="onFileSelect($event)" style="display: none;">
              <div>
              </div>
              <h4>{{profilePicData?.fullName}}</h4>
              <p>{{profilePicData?.email}}</p>
              <p>{{profilePicData?.location}}</p>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="active" *ngIf="requestType === 'GENERAL'">
              <div class="hgbpp" style="overflow-y: scroll; height: 435px;">
                <form [formGroup]="editArtistForm">
                  <table class="ithy">
                    <tr>
                      <td>Name</td>
                      <td class="f14">{{audienceProfileData?.fullName}}
                        <div style="margin-top: 21px;">
                          <span style="font-size: 12px;color: #C9C9C9;">
                            <img src="assets/a1.png"> To change your name you should contact
                            support@virtuosica.com</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td class="f14">{{audienceProfileData?.email}}
                        <div style="margin-top: 21px;">
                          <span style="font-size: 12px;color: #C9C9C9;">
                            <img src="assets/a1.png"> To change your email you should contact
                            support@virtuosica.com</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Display Name </td>
                      <td class="f14">
                        <input type="text" formControlName="displayName" class="inputBox">
                      </td>
                    </tr>
                    <tr>
                      <td>Age</td>
                      <mat-form-field appearance="outline" style="width: 45% !important;">
                        <mat-select placeholder="Select your Age" formControlName="age"
                          style="color: white !important;">
                          <mat-option style="color: white !important;" *ngFor="let age of ageArray" [value]="age">
                            {{age}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </tr>
                    <tr>
                      <td>Location</td>
                      <td>
                        <mat-form-field class="example-chip-list location" appearance="fill">
                          <mat-chip-list #chipList aria-label="Fruit selection">
                            <mat-chip *ngFor="let address of location" (removed)="remove(address)">
                              {{address}}
                              <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                              </button>
                            </mat-chip>
                            <input style="color: white;" placeholder="&nbsp;" #fruitInput [formControl]="locationCtrl"
                              [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                              ngx-google-places-autocomplete #placesRef="ngx-places"
                              (onAddressChange)="handleAddressChange($event)">
                          </mat-chip-list>
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td>Time Zone</td>
                      <mat-form-field class="example-chip-list" appearance="fill">
                        <mat-chip-list #chipListTimeZone>
                          <mat-chip *ngFor="let timeZone of searchTimeZone" (removed)="removeTimeZone(timeZone)">
                            {{timeZone}}
                            <button matChipRemove>
                              <mat-icon>cancel</mat-icon>
                            </button>
                          </mat-chip>
                          <input
                            style="border: none; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                            #timeZoneInput [formControl]="timeZoneCtrl" [matAutocomplete]="auto"
                            [matChipInputFor]="chipListTimeZone" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTimeZone($event)">
                          <mat-option *ngFor="let time of timeZoneArray" [value]="time">
                            {{time}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </tr>
                  </table>
                </form>
              </div>
            </div>

            <div *ngIf="requestType === 'DETAILS'">
              <div class="hgbpp">
                <form [formGroup]="editArtistDetialsForm">
                  <table class="ithy">
                    <tr>
                      <td>Favorite generes</td>
                      <td>
                        <mat-form-field class="example-chip-list gen" appearance="fill">
                          <mat-chip-list #chipListgen>
                            <mat-chip *ngFor="let genres of searchgen" [selectable]="selectablegen"
                              [removable]="removablegen" (removed)="removegen(genres)">
                              {{genres}}
                              <button matChipRemove *ngIf="removablegen">
                                <mat-icon>cancel</mat-icon>
                              </button>
                            </mat-chip>
                            <input
                              style="border: none; color: white; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                              #genInput [formControl]="genCtrl" [matAutocomplete]="autogen"
                              [matChipInputFor]="chipListgen" [matChipInputSeparatorKeyCodes]="separatorKeysCodesgen"
                              (matChipInputTokenEnd)="addgen($event)">
                          </mat-chip-list>
                          <mat-autocomplete #autogen="matAutocomplete" (optionSelected)="selectedgen($event)">
                            <mat-option *ngFor="let gen of genList" [value]="gen">
                              {{gen}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                        <img src="assets/dashboard/war.svg"><small class="pl-2"
                          style="color: white; font-size: 10px;">Start typing to select your genres</small>
                      </td>
                    </tr>
                    <tr>
                      <td>Devices Used</td>
                      <div class="row" style="padding: 0px 30px;">
                        <mat-form-field appearance="outline" style="width: 100% !important;">
                          <mat-select (selectionChange)="onroleChange($event)" formControlName="deviceUsed" multiple
                            style="margin-top: 10px !important;">
                            <div class="select-all">
                              <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                                (change)="toggleAllSelection()">Select All
                              </mat-checkbox>
                            </div>
                            <mat-option (click)="optionClick()" *ngFor="let device of deviceArray" [value]="device">
                              {{device}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </tr>
                    <tr>
                      <td>Favourite musicans/bands/composers</td>
                      <div class="row" style="padding: 0px 30px;">
                        <mat-form-field class="example-chip-list favMusician" style="width: 100% !important;">
                          <mat-chip-list #musicList>
                            <mat-chip *ngFor="let keyword of keywords" [selectable]="selectableMusician"
                              [removable]="removableNotable" (removed)="removenotable(keyword)">
                              {{keyword}}
                              <button matChipRemove *ngIf="removableNotable">
                                <mat-icon>cancel</mat-icon>
                              </button>
                            </mat-chip>
                            <input style="color: #FFFFFF;" autocomplete="off" [matChipInputFor]="musicList"
                              [matChipInputSeparatorKeyCodes]="separatorKeysCodesMusician"
                              [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addMusic($event)" />
                          </mat-chip-list>
                        </mat-form-field>
                      </div>
                    </tr>
                    <tr>
                      <td>Social Media Links</td>
                      <td class="f14">
                        <div>
                          <input type="text" formControlName="media" class="edi-in fro-bo">
                          <span style="color: white;" (click)="socialVideosaddmore = true">
                            <a class="cmvk" (click)="socialVideosaddmore = true;" style="cursor: pointer;">+ Add
                              More</a>
                          </span>
                          <small class="text-danger"
                            *ngIf="editArtistDetialsForm.controls.media.invalid && editArtistDetialsForm.controls.media.touched">
                            <span *ngIf="editArtistDetialsForm.controls['media'].hasError('pattern')">Please enter valid
                              url</span>
                          </small>
                        </div>
                      </td>
                    </tr>
                  </table>
                </form>
              </div>
            </div>

            <div *ngIf="requestType === 'SETTINGS'">
              <div class="hgbpp">
                <h4 style="color: white; padding-bottom: 34px;">Password</h4>
                <form [formGroup]="seetingForm">
                  <table class="ithy">
                    <tr>
                      <td>Old Password</td>
                      <td class="f14">
                        <div class="">
                          <input matInput type="password" formControlName="oldPassword" class="edi-in"
                            [type]="oldPassWordHide ? 'password' : 'text'" style="padding: 0px 5px;">
                          <mat-icon matSuffix (click)="oldPassWordHide = !oldPassWordHide"
                            style="margin-left:-40px !important">{{
                            oldPassWordHide ? "visibility_off" : "visibility" }}
                          </mat-icon>
                          <div class="text-danger"
                            *ngIf="seetingForm.controls['oldPassword'].touched && !seetingForm.controls['oldPassword'].valid">
                            <span *ngIf="seetingForm.controls['oldPassword'].hasError('required')">
                              Old Password is required
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>New Password</td>
                      <td class="f14">
                        <div class="">
                          <input matInput type="password" formControlName="newPassword" class="edi-in"
                            [type]="newPassWordHide ? 'password' : 'text'" style="padding: 0px 5px;">
                          <mat-icon matSuffix (click)="newPassWordHide = !newPassWordHide"
                            style="margin-left:-40px !important">{{
                            newPassWordHide ? "visibility_off" : "visibility" }}
                          </mat-icon>
                          <div class="text-danger"
                            *ngIf="seetingForm.controls['newPassword'].touched && !seetingForm.controls['newPassword'].valid">
                            <span *ngIf="seetingForm.controls['oldPassword'].hasError('required')">
                              New Password is required
                            </span>
                            <span *ngIf="seetingForm.controls['newPassword'].hasError('newPassword') ">
                              Password Should be eight characters long and
                              alphanumeric with special characters
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Confirm New Password</td>
                      <td class="f14">
                        <div class="">
                          <input matInput type="password" formControlName="confirmPassword" class="edi-in"
                            [type]="confirmPasswordHide ? 'password' : 'text'" style="padding: 0px 5px;">
                          <mat-icon matSuffix style="margin-left:-40px !important"
                            (click)="confirmPasswordHide = !confirmPasswordHide">{{
                            confirmPasswordHide ? "visibility_off" : "visibility" }}
                          </mat-icon>
                          <div class="text-danger"
                            *ngIf="seetingForm.controls['confirmPassword'].touched && !seetingForm.controls['confirmPassword'].valid">
                            <span *ngIf="seetingForm.controls['confirmPassword'].hasError('required')">
                              Confirm Password is required
                            </span>
                            <span *ngIf="seetingForm.hasError('mustMatch', 'confirmPassword')">
                              Password doesn't match..?
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </form>
                <h4 style="color: white; padding-bottom: 14px;">Notification Preferences</h4>
                <div class="form-check">
                  <input class="form-check-input" [(ngModel)]="checkBox1" type="checkbox" value="" id="defaultCheck1">
                  <label class="form-check-label" for="defaultCheck1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua.
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" [(ngModel)]="checkBox2" type="checkbox" value="" id="defaultCheck2">
                  <label class="form-check-label" for="defaultCheck2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua.
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" [(ngModel)]="checkBox3" type="checkbox" value="" id="defaultCheck3">
                  <label class="form-check-label" for="defaultCheck3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </label>
                </div>
                <div>
                  <h5 style="color: #ff0000c9;cursor: pointer;padding: 43px 0px;" *ngIf="!showDeleteButton"
                    (click)="deleteShow = true">Delete Account Permanently</h5>
                </div>
                <div *ngIf="showDeleteButton">
                  <h5 style="color: #ff0000c9;cursor: pointer;padding: 43px 0px;">Your Account has been initiated for
                    Delete Account Process. If your account doesn't recover in 15 days then it will delete
                    permanently.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal" [ngClass]="{'d-block': socialVideosaddmore, 'd-none': !socialVideosaddmore}">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="margin-top: 121px;">
      <div class="modal-body">
        <form class="oik" [formGroup]="WebsiteForm">
          <div class="form-group">
            <div class="row">
              <div class="col-8 pb-l add-label-wrapper">
                <input type="text" class="ge-fk" formControlName="websiteLink" [(ngModel)]="socialVideo"
                  placeholder="https://www.sds.com" style="width: 155%; color: black !important;">
              </div>
            </div>
            <small class="text-danger"
              *ngIf="WebsiteForm.controls.websiteLink.invalid &&(WebsiteForm.controls.websiteLink.touched)">
              <span *ngIf="WebsiteForm.controls['websiteLink'].hasError('pattern')">Please
                enter valid url</span>
            </small>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary nxt" [disabled]="WebsiteForm.invalid" (click)="saveSocialVideo()"
          style="height: 40px;">Save
          changes</button>
        <button type="button" class="btn btn-secondary yht" (click)="socialVideosaddmore = false"
          style="height: 42px;width: 86px;">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal" [ngClass]="{'d-block': deleteShow, 'd-none': !deleteShow}">
  <div class="modal-dialog">
    <div class="modal-content modalBg" style="width:112%">
      <div class="modal-body">
        <div class="container">
          <div class="row px-3 pt-3" style="color: white;">
            <div class="col-md-12">
              <div class="text-center">
                <span style="color: red;font-size: 40px;"><i class="fa fa-trash-o"></i> </span>
              </div>
              <p>Are you sure you want to permanently delete your account? Before proceeding with your request to
                permanently delete your Virtuosica account, please make sure you understand our Account Removal Policy.
              </p>
              <p style="font-size: 11px;text-decoration: underline;">Account Removal Policy</p>
              <div class="form-check" style="color: #C9C9C9;padding-bottom: 27px;">
                <input class="form-check-input" [(ngModel)]="checkBox9" type="checkbox" value="" id="defaultCheck5">
                <label class="form-check-label" for="defaultCheck5" style="font-size:14px">
                  I have read and understand the requirements of the gig posting that I am responding to in this
                  proposal.
                </label>
              </div>
              <div class="text-center">
                <button type="button" class="btn hftrdd" (click)="deleteShow = false">Go Back</button>
                <button type="button" class="btn ytgft" [disabled]="!checkBox9"
                  (click)="getUserAccPendingDetails()">Delete Account</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': deleteNextStep, 'd-none': !deleteNextStep}">
  <div class="modal-dialog">
    <div class="modal-content modalBg" style="width:112%">
      <div class="modal-body">
        <div class="container">
          <div class="row px-3 pt-3" style="color: white;">
            <div class="col-md-12">
              <p>You have requested that we delete your Virtuosica account. You have 15 days to change your mind and
                stop the deletion of your account. In those 15 days, you will still have access to your Virtuosica
                account, but you will not have access to certain features, such as creating a new event. </p>
              <p>Please tell us why you are deleting your Virtuosica account.</p>
              <form class="jhnu ge" [formGroup]="deleteForm">
                <div class="form-group">
                  <textarea class="reasonTxtarea rfgt" rows="4" cols="60" formControlName="reason"
                    placeholder="Enter your reason here."> </textarea>
                </div>
              </form>
              <div class="text-center">
                <button type="button" class="btn hftrdd" (click)="deleteNextStep = false">Cancel</button>
                <button type="button" class="btn ytgft" style="padding: 8px 37px !important;"
                  (click)="deleteUserAccount();">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal" [ngClass]="{'d-block': deleteNotProcess, 'd-none': !deleteNotProcess}">
  <div class="modal-dialog">
    <div class="modal-content modalBg" style="width:112%">
      <div class="modal-header" style="border-bottom: none;">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteNotProcess = false"
          style="color: white">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row px-3 pt-3" style="color: white;">
            <div class="col-md-12">
              <p>We are unable to process your request to delete your Virtuosica account at this time
                because you have pending events or payments on Virtuosica.com. For assistance in
                deleting your account, please contact Customer Service or read and follow the steps in
                our Account Removal Policy.</p>
              <p style="font-size: 11px;text-decoration: underline;">Account Removal Policy</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
