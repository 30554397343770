import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from './api/api.service';
import { BaseResponse } from './models/baseResponse';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceService {
  constructor(private http: HttpClient, private _ApiService: ApiService) {}

  signIn(body: any): Promise<BaseResponse> {
    return this._ApiService.postRequest<BaseResponse>(`user/signIn`, body);
  }

  signup(body: any) {
    return this._ApiService.postRequest<BaseResponse>(`user/signUp`, body);
  }

  emailVerify(token: any) {
    return this._ApiService.getRequest<BaseResponse>( `user/accountVerify/${token}` );
  }
  changeEmailVerify(body: any) {
    return this._ApiService.postRequest<BaseResponse>( `user/emailChangeVerify?token=${body.token}&fromEmail=${body.fromEmail}&toEmail=${body.toEmail}`, {} );
  }
  logout(device: any) {
    return this._ApiService.postRequest<BaseResponse>(
      `user/logout?device=${device}`,
      device
    );
  }

  secretKey = 'Virtuosica app';

  getCurrentIpInfo(): Promise<any> {
    const token = 'c0da6d29d587e2';
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json');
    header = header.set('Authorization', 'Bearer ' + token);
    return this._ApiService.getRequest<any>(`/json`, {
      apiRoot: 'https://ipinfo.io',
      headers: header,
    });
  }




getSession() {
    return this.http.get<any>('https://api.stripe.com/v1/checkout/sessions');
  }

  getCountry() {
    return this.http.get<any>(
      'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCIcK1aFr6hYCwL1y'
    );
  }

  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return `Opera`;
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') != -1) {
      return 'IE';
    } else {
      return 'unknown';
    }
  }
}
