<div class="joinpage">
  <div class="joinhead">
    <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg" routerLink="" style="cursor: pointer;">
  </div>
  <div class="joinbody container hgns">
    <div class="row joinb">
      <div class="col-md-6 col-sm-12 col-xl-6">
        <div class="joinleft">
          <app-slider></app-slider>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 col-xl-6">
        <div class="joinright">
          <h1>Join Us!</h1>
          <h2>What type of account do you need?</h2>
          <div class="button" routerLink="artist-signup">
            <div class="row" style="display: inline-flex; text-align: left;">
              <div class="jugh">
                <div class=""><img class="icon1" src="assets/Group 22.png"></div>
                <div class="">
                  <h3>Artist</h3>
                  <h4>Reach audiences, book gigs, perform,and get paid on Virtuosica</h4>
                </div>
                <div class="arrow">
                  <img class="arrow1" src="assets/arrow-rightwhite.png">
                </div>
              </div>
            </div>
          </div><br>
          <button class="button2" routerLink="audience-signup">
            <div class="row" style="display: inline-flex; text-align: left;">
              <div class="jugh">
                <div class=""> <img class="icon2" src="assets/Group 23.png"></div>
                <div class="">
                  <h3 style="color: black;">Audience</h3>
                  <h4 style="color: #808080;">Discover, book, and experience live, music events on Virtuosica</h4>
                </div>
                <div class="arrow">
                  <img class="arrow2" src="assets/arrow-right.png">
                </div>
              </div>
            </div>
          </button>
          <h2>Already have an account?
            <span><a [routerLink]="['/login']" style="color: #FF6720 ;">&nbsp;Log in.</a></span>
          </h2>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <div class="modal" [ngClass]="{'d-block': showverifyEmail, 'd-none': !showverifyEmail}">
    <div class="modal-dialog" role="document">
      <div class="modal-content upladModal">
        <div class="modal-header" style="border-bottom:none">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="showverifyEmail = false;">
            <span class="close" aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div>
                <div class="innerModal">
                  <div>
                    <img src="assets/e2.png" style="margin-left: 170px;">
                  </div>
                  <div class="logo-wrapper">
                    <img src="assets/e1.png" style="margin-left: 140px;margin-top: -15px;">
                  </div>
                </div>
                <div>
                  <h1> Verify Your Email.</h1>
                  <h2>We have sent a verification link to your email.</h2>
                  <h2>Please verify your email to confirm your account.</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
