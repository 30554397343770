import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SafePipe } from 'src/app/service/safe.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-artist-watch-test-stream',
  templateUrl: './artist-watch-test-stream.component.html',
  styleUrls: ['./artist-watch-test-stream.component.scss']
})
export class ArtistWatchTestStreamComponent implements OnInit {
  poster: any;
  videoLink: any;
  param: any = this.activatedRoute.queryParams;
  watchVideoLink = environment.watchVideoLink;

  constructor( private activatedRoute: ActivatedRoute, private pipe: SafePipe  ) { }

  ngOnInit(): void {
    this.videoLink = this.watchVideoLink + this.param['_value'].streamId;
    this.videoLink = this.pipe.transform(this.videoLink)
  }

}
