import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { MainService } from 'src/app/service/main.service';
import { StreamService } from 'src/app/service/stream.service';
import { WebRTCAdaptor } from 'src/assets/js/webrtc_adaptor';
import { getUrlParameter } from 'src/assets/js/fetch.stream';
import { SessionService } from 'src/app/service/helper/session.service';
import PubNub from 'pubnub';
import { UUID } from 'angular2-uuid';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { SafePipe } from 'src/app/service/safe.pipe';
import { BreakpointObserver } from '@angular/cdk/layout';

declare const VideoStreamMerger: any;

@Component({
  selector: 'app-artist-live-streaming',
  templateUrl: './artist-live-streaming.component.html',
  styleUrls: ['./artist-live-streaming.component.scss']
})
export class ArtistLiveStreamingComponent implements OnInit {
  @ViewChild('mergeSource', { static: false, read: ElementRef }) mergeSource: ElementRef<HTMLVideoElement> | null;
  // @ViewChild('mergeSource', { static: true }) mergeSource: ElementRef<HTMLVideoElement> | null;
  @ViewChild('liveSource', { static: true }) liveSource: ElementRef<HTMLVideoElement> | null;
  url = environment.hubspotNavigateUrl
  selectTab = 'mainTab'
  tabName = 'onboardDevices';
  poster: any;
  pageTitle = 'Live Stream'
  btnText = 'Start Streaming'
  embededBtnText = 'Watch Live Streaming'
  videoLink: any;
  safeUrl: any;
  testStream: any
  param: any = this.route.queryParams
  eventId: any;
  currentVideo: any;
  ismergeView: boolean;
  _screenTrack: any;
  vdoHeight: any;
  vdoWidth: any;
  merge: any;
  currentAudio: any;
  liveStream: any;
  isStreamRunning = false;
  disableStreambtn = true;
  ownerId: any;
  currentUserId: any;
  isCameraOn = true;
  streamId: any;
  showWebcam = true;
  debug: any;
  isCameraExist = true;
  WebRTCAdaptor: any;
  onBoardLiveStreamingBtnText = 'Start Streaming';
  autoRepublishEnabled = true;
  // @Output() getPicture = new EventEmitter<WebcamImage>();
  // @ViewChild('mergeSource', { static: true }) mergeSource: ElementRef<HTMLVideoElement> | null;
  sdpConstraints = {
    OfferToReceiveAudio: false,
    OfferToReceiveVideo: false
  };
  commentedText: any;
  newChannelName: any;
  uuidValue: any;
  messages: any[] = [];
  pubnub: any;
  currentUserName: any
  rtmp: any;
  pc_config = {
    'iceServers': [{
      'urls': 'stun:stun1.l.google.com:19302'
    }]
  };
  devicesArray: any = []
  mediaConstraints = {
    video: true,
    audio: true
  };
  maxi: boolean = false;
  maxVideoBitrateKbps = 900;
  title = 'Virtuosica-Project-user-app';
  autoRepublishIntervalJob: any;
  websocketURL: any;
  eventDetails: any;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  selectedCamera: any;
  selectedAudioDevice: any;
  eventEndModal2: boolean = false;
  congratsModal: boolean = false;
  warningModal: boolean = false;
  eventEndTime: any;
  eventStartTime: any;
  interval: any
  currentUserProfile: any;
  TimeZone = this._SessionService.getSession('timeZone');
  timeCount: any = {
    day: 0,
    hour: 0,
    minute: 0,
    second: 0,
    timeDifference: 0,
  }
  timeCountCheck: any;
  endConcertBtn: boolean = false;
  openWarnModal: boolean = false;
  mobileView: boolean = false;
  decisionType: any;
  emoji: any;
  emojiView: boolean = false;
  constructor(
    private mainService: MainService,
    private route: ActivatedRoute,
    private NotifierService: NotifierService,
    private _StreamService: StreamService,
    private _SessionService: SessionService,
    private sanitizer: DomSanitizer,
    private pipe: SafePipe,
    private router: Router,
    private observer: BreakpointObserver
  ) {
    this.commentedText = '';
    this.mergeSource = null;
    this.liveSource = null;
    this.ismergeView = false;
    this.newChannelName = "liveChat"
    if (this._SessionService.getSession('chatUuId')) {
      this.uuidValue = this._SessionService.getSession('chatUuId');
    } else {
      this.uuidValue = UUID.UUID();
    }
    this.commentedText = '';
    this.pubnub = new PubNub({
      publishKey: 'pub-c-6ae48ff7-ea5d-4b56-b7ce-a553e0ebc7de',
      subscribeKey: 'sub-c-c9a52fe0-a8ad-11ec-94c0-bed45dbe0fe1',
      uuid: this.uuidValue,
    });
  }

  ngOnInit(): void {
    this.currentUserProfile = this._SessionService.getSession('avatarUrl');
    this.currentUserName = this._SessionService.getSession('userName');
    this.currentUserId = this._SessionService.getSession('userId')
    this.pubnub.subscribe({
      channels: [this.newChannelName],
      withPresence: true,
      presence: function (data: any) { },
      triggerEvents: ['message', 'presence', 'status'],
    });
    this.debug = getUrlParameter("debug");
    if (this.debug == null) {
      this.debug = false;
    }
    this.websocketURL = environment.websocketURL;
    this.initWebRTCAdaptor(false, this.autoRepublishEnabled);
    var constraints = { audio: true, video: { width: 1280, height: 720 } };

    navigator.mediaDevices.getUserMedia(constraints)
      .then(function (mediaStream) {
      })
      .catch(function (err) { console.log(err.name + ": " + err.message); })

    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        devices.forEach((device) => {
          this.devicesArray.push(device)
        });
      })
      .catch(function (err) { });
    let type = this.param['_value']
    this.eventId = type.id
    this.ownerId = type.ownerId;
    if (type.type == "soundCheck") {
      this.btnText = 'Test Streaming'
      this.pageTitle = 'Sound Check'
      this.embededBtnText = 'Watch Test Live Streaming'
      this.mainService.getTestStream().then((data) => {
        if (data && data.isSuccess) {
          this.testStream = data.data
          this.rtmp = this.testStream.rtmpUrl.substr(0, this.testStream.rtmpUrl.lastIndexOf("/"));
          this.videoLink = this.testStream.liveStreamUrl;
          this.newChannelName = this.testStream.streamId;
          // this.videoLink = 'https://antmedia.virtuosica.com:5443/WebRTCAppEE/play.html?name='+this.testStream.streamId;
          this.videoLink = this.pipe.transform(this.videoLink)
          // this.streamId = this.testStream.streamId
        } else {
          this.NotifierService.showError(data.message);
        }
      })
    }

    else if (type.type == "liveStream") {
      this.mainService.getEventStreamingDetails(this.eventId).then((data) => {
        if (data && data.isSuccess) {
          this.testStream = data.data
          this.rtmp = this.testStream.rtmpUrl.substr(0, this.testStream.rtmpUrl.lastIndexOf("/"));
          this.videoLink = this.testStream.liveStreamUrl
          this.videoLink = this.pipe.transform(this.videoLink)
          this.newChannelName = this.testStream.streamId;
        } else {
          this.NotifierService.showError(data.message);
        }
      })
      this.mainService.getEventDetailById(this.eventId).then((data) => {
        if (data && data.isSuccess) {
          this.eventDetails = data.data;
          // var endtime = data.data.endDate.split('T')
          var edate = new Date(data.data.endDate);
          this.eventEndTime = edate.getTime();


          // var newtimeline = data.data.startDate.split('T')
          var sdate = new Date(data.data.startDate);
          this.eventStartTime = sdate.getTime();
          // var endtime = data.data.endDate.split('T')
          // var edate = new Date(endtime[0] + ' ' + endtime[1]);
          // this.eventEndTime = edate.getTime();
          this.interval = setInterval(() => this.getTimeDifference(), 1000);


        }
      })
    }
    if (this.liveStream && this.liveStream.getVideoTracks) {
      this.liveStream.getVideoTracks()[0].stop();
    }
    if (this.currentAudio && this.currentAudio.getAudioTracks) {
      this.currentAudio.getAudioTracks()[0].stop();
    }
    this.pubnub.addListener({
      status: async (statusEvent: { category: string }) => {
        if (statusEvent.category === 'PNConnectedCategory') {
          try {
            await this.pubnub.setState({
              state: {
                some: 'state',
              },
            });
          } catch (status) { }
        }
      },
      message: (messageEvent: any) => {
        this.messages.push(messageEvent);
      },
      presence: (presenceEvent: any) => {
      },
      file: (event: any) => { },
    });
  }
  ngAfterViewInit() {
    this.observer.observe(['(max-width: 767px)']).subscribe((res) => {
      if (res.matches) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    });
  }

  getTimeDifference() {
    this.timeCountCheck =  Date.now();
    let date = new Date();
    if (this.TimeZone) {
      let time = date.toLocaleString("en-US", { timeZone: this.TimeZone });
      let userTimezoneMilis = new Date(time).getTime()
      if ((userTimezoneMilis > this.eventStartTime) && (userTimezoneMilis < this.eventEndTime)) {
        this.endConcertBtn = true;
      }
      else {
        this.timeCount.timeDifference = this.eventStartTime - new Date().getTime();
        this.timeCount.day = Math.floor(this.timeCount.timeDifference / (1000 * 60 * 60 * 24));
        this.timeCount.hour = Math.floor((this.timeCount.timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.timeCount.minute = Math.floor((this.timeCount.timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        this.timeCount.second = Math.floor((this.timeCount.timeDifference % (1000 * 60)) / 1000);
        if (this.timeCount.day == 0 && this.timeCount.hour == 0 && this.timeCount.minute < 1 && this.timeCount.minute > -1)
          this.endConcertBtn = true;
      }
    }
  }

  getDeviceList() {
    if (this.selectedCamera == undefined && this.selectedAudioDevice == undefined) {
      this.devicesArray = [];
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            this.devicesArray.push(device)
          });
        })
        .catch(function (err) { });
    }
    if (this.liveStream && this.liveStream.getVideoTracks) {
      this.liveStream.getVideoTracks()[0].stop();
    }
    if (this.currentAudio && this.currentAudio.getAudioTracks) {
      this.currentAudio.getAudioTracks()[0].stop();
    }
  }
  openModal() {
    if (new Date().getTime() < this.eventEndTime)
      this.eventEndModal2 = true
    else
      this.liveStreamingCtrl('end')
  }

  leaveEvent() {
    this.selectTab = 'mainTab'
    this.WebRTCAdaptor.stop(this.testStream.streamId);
    this.liveStream.getVideoTracks()[0].stop();
    this.currentAudio.getAudioTracks()[0].stop();
  }

  mergeView() {
    var _width = 0;
    var _height = 0;
    if (this._screenTrack) {
      _width = 665;
      _height = 450;
    }
    else if (this.currentVideo) {
      _width = this.currentVideo.getSettings().width;
      _height = this.currentVideo.getSettings().height;
    }
    this.vdoHeight = _height
    this.vdoWidth = _width

    this.merge = new VideoStreamMerger({
      width: _width,   // Width of the output video
      height: _height,  // Height of the output video
      //fps: 1,       // Video capture frames per second
      audioContext: null,
    })

    if (this._screenTrack && this._screenTrack.readyState === "live") {
      this.merge.addStream(this.currentAudio)
      // Add the screen capture.Position it to fill the whole stream (the default)
      this.merge.addStream(new MediaStream([this._screenTrack]), {
        x: 0, // position of the topleft corner
        y: 0,
        //width: _screenTrack.getSettings().width,
        //height: _screenTrack.getSettings().height,
        mute: true // we don't want sound from the screen (if there is any)
      });

      if (this.currentVideo && this.currentVideo.readyState === "live") {
        // Add the webcam stream. Position it on the bottom left and resize it to 100x100.
        this.merge.addStream(new MediaStream([this.currentVideo]), {
          x: 0,
          y: this.merge.height - 100,
          width: 100,
          height: 100,
          mute: true
        });
      }
    }
    else {
      if (this.currentVideo && this.currentVideo.readyState === "live") {
        // Add the webcam stream.
        this.merge.addStream(new MediaStream([this.currentVideo]), {
          x: 0,
          y: 0,
          width: _width,
          height: _height,
          mute: true
        });
        this.merge.addStream(this.currentAudio)
      }
    }
    this.merge.start()
    var stream = this.merge.result;
    this.liveStream = this.merge.result;
    (this.mergeSource?.nativeElement as any).srcObject = this.liveStream;
    this.mergeSource?.nativeElement.load();
    this.ismergeView = true;
  }

  startCamera() {
    if (this.tabName == 'onboardDevices') {
      if (this.selectedCamera && this.selectedAudioDevice) {
        this.selectTab = 'onboardSoundCheckVideo'
        this.getAudio();
        this.getVideo();
      } else {
        this.NotifierService.showError('Please Select Audio & Video Input Devices')
      }
    }
    else if (this.tabName == 'encodingDevices') {
      this.selectTab = 'encodedSoundCheckVideo';
      this.liveStreamingCtrl('start');
    }
  }

  async getVideo() {
    this.disableStreambtn = false;
    this.ismergeView = true;
    if (this.currentVideo && this.currentVideo.readyState === 'live') {
      this.currentVideo.stop();
      this.isCameraOn = true;
      if (this._screenTrack && this._screenTrack.readyState === 'live') {
        (this.mergeSource?.nativeElement as any).srcObject = new MediaStream([this._screenTrack]);
      } else {
        this.currentVideo = null;
      }
    } else {
      this.isCameraOn = false;
      let astream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: this.selectedCamera.deviceId }, audio: false });
      this.liveStream = astream;
      this.currentVideo = astream.getVideoTracks()[0];
      if (this.mergeSource?.nativeElement) {
        (this.mergeSource?.nativeElement as any).srcObject = new MediaStream([this.currentVideo]);
      }
    }
    if (this.pageTitle == 'Sound Check') {
      this.streamNow();
    }
  }

  initWebRTCAdaptor(publishImmediately: any, autoRepublishEnabled: any) {
    this.WebRTCAdaptor = new WebRTCAdaptor({
      websocket_url: this.websocketURL,
      mediaConstraints: this.mediaConstraints,
      peerconnection_config: this.pc_config,
      sdp_constraints: this.sdpConstraints,
      localVideoId: "localVideo",
      debug: this.debug,
      bandwidth: this.maxVideoBitrateKbps,
      callback: (info: any, obj: any) => {
        if (info == "initialized") {
          if (publishImmediately) {
            this.WebRTCAdaptor.publish(this.streamId, 'token')
          }

        } else if (info == "publish_started") {
          if (autoRepublishEnabled && this.autoRepublishIntervalJob == null) {
            this.autoRepublishIntervalJob = setInterval(() => {
              this.checkAndRepublishIfRequired();
            }, 3000);
          }
          this.WebRTCAdaptor.enableStats(obj.streamId);
        } else if (info == "publish_finished") {
          //stream is being finished

          $("#stats_panel").hide();
        }
        else if (info == "browser_screen_share_supported") {
          $(".video-source").prop("disabled", false);
        }
        else if (info == "screen_share_stopped") { }
        else if (info == "closed") {
          if (typeof obj != "undefined") { }
        }
        else if (info == "pong") {
          //ping/pong message are sent to and received from server to make the connection alive all the time
          //It's especially useful when load balancer or firewalls close the websocket connection due to inactivity
        }
        else if (info == "refreshConnection") {
          // this.checkAndRepublishIfRequired();
        }
        else if (info == "ice_connection_state_changed") { }
        else if (info == "data_received") { }
      },
      callbackError: function (error: any, message: any) {
        var errorMessage = JSON.stringify(error);
        if (typeof message != "undefined") {
          errorMessage = message;
        }
        var errorMessage = JSON.stringify(error);
        if (error.indexOf("NotFoundError") != -1) {
          errorMessage = "Camera or Mic are not found or not allowed in your device";
        }
        else if (error.indexOf("NotReadableError") != -1 || error.indexOf("TrackStartError") != -1) {
          errorMessage = "Camera or Mic is being used by some other process that does not let read the devices";
        }
        else if (error.indexOf("OverconstrainedError") != -1 || error.indexOf("ConstraintNotSatisfiedError") != -1) {
          errorMessage = "There is no device found that fits your video and audio constraints. You may change video and audio constraints"
        }
        else if (error.indexOf("NotAllowedError") != -1 || error.indexOf("PermissionDeniedError") != -1) {
          errorMessage = "You are not allowed to access camera and mic.";
        }
        else if (error.indexOf("TypeError") != -1) {
          errorMessage = "Video/Audio is required";
        }
        else if (error.indexOf("getUserMediaIsNotAllowed") != -1) {
          errorMessage = "You are not allowed to reach devices from an insecure origin, please enable ssl";
        }
        else if (error.indexOf("ScreenSharePermissionDenied") != -1) {
          errorMessage = "You are not allowed to access screen share";
          $(".video-source").first().prop("checked", true);
        }
        else if (error.indexOf("WebSocketNotConnected") != -1) {
          errorMessage = "WebSocket Connection is disconnected.";
        }
        // alert(errorMessage);
      }
    }, this._StreamService);
  }

  checkAndRepublishIfRequired() {
    var iceState = this.WebRTCAdaptor.iceConnectionState(this.streamId);
    if (iceState == null || iceState == "failed" || iceState == "disconnected") {
      this.WebRTCAdaptor.stop(this.streamId);
      this.WebRTCAdaptor.closePeerConnection(this.streamId);
      this.WebRTCAdaptor.closeWebSocket();
    }
  }

  streamNow() {
    const user = {
      room: this.testStream.streamId,
      name: 'manisha',
    };
    this.streamId = user.room
    this.mergeView()
    this.getAudio();
    this.WebRTCAdaptor.publish(this.streamId, this.liveStream);
    this.isStreamRunning = true;
    this.ismergeView = true;
    (this.mergeSource?.nativeElement as any).srcObject = this.liveStream;
    if (this.mergeSource?.nativeElement) {
      this.mergeSource.nativeElement.muted = true;
    }
    this.mergeSource?.nativeElement.load();
  }
  // { deviceId: (this.selectedAudioDevice && this.selectedAudioDevice.deviceId) ? this.selectedAudioDevice.deviceId : false}
  //{ deviceId: this.selectedAudioDevice.deviceId }
  async getAudio() {
    this.currentAudio = await navigator.mediaDevices.getUserMedia({ video: false, audio: this.selectedAudioDevice.deviceId });
  }

  liveStreamingCtrl(type: any) {
    if (type == 'start') {
      this.mainService.startLivePerformance(this.eventId).then((data) => {
        if (data && data.isSuccess) {
          this.streamNow()
          this.NotifierService.showSuccess(data.message);
          this.onBoardLiveStreamingBtnText = 'Stop Streaming'
        }
      })
    }
    else if (type == 'end') {
      this.mainService.endLivePerformance(this.eventId).then((data) => {
        if (data && data.isSuccess) {
          this.NotifierService.showSuccess(data.message);
          this.onBoardLiveStreamingBtnText = 'Start Streaming'
          if (this.pageTitle == 'Live Stream' && this.tabName == 'encodingDevices')
            this.warningModal = true;
          else
            this.congratsModal = true;
          this.eventEndModal2 = false
          if (this.liveStream && this.liveStream.getVideoTracks)
            this.liveStream.getVideoTracks()[0].stop();
          if (this.currentAudio && this.currentAudio.getAudioTracks)
            this.currentAudio.getAudioTracks()[0].stop();
        }
        else
          this.NotifierService.showError(data.message);
      })
      this.WebRTCAdaptor.stop(this.testStream.streamId);
      this.liveStream.getVideoTracks()[0].stop();
      this.currentAudio.getAudioTracks()[0].stop();
    }
  }
  ngOnDestroy() {
    this.WebRTCAdaptor.stop(this.testStream.streamId);
    if (this.liveStream && this.liveStream.getVideoTracks)
      this.liveStream.getVideoTracks()[0].stop();
    if (this.currentAudio && this.currentAudio.getAudioTracks)
      this.currentAudio.getAudioTracks()[0].stop();
    clearInterval(this.interval)
  }

  addEmoji(event: any) {
    this.commentedText = `${this.commentedText}${event.emoji.native}`;
  }

  async addComment(event?: any) {
    this.pubnub.subscribe({
      channels: [this.newChannelName],
      withPresence: true,
      presence: function (data: any) { },
      triggerEvents: ['message', 'presence', 'status'],
    });
    let message = this.emoji;
    // let message = event.target.value;
    let time = Date.now();
    var date = new Date().toDateString();
    if (message) {
      const newMessage = {
        messageType: 'TEXT',
        typeMessage: this.emoji,
        // typeMessage: event.target.value,
        messageTime: time,
        currentUserProfile: this.currentUserProfile,
        currentDate: date,
        username: this.currentUserName
      };
      try {
        const result = await this.pubnub.publish({
          message: newMessage,
          channel: this.newChannelName,
          storeInHistory: true,
        });
      } catch (status) { }
    }
    setTimeout(() => {
      $('#div1').animate({ scrollTop: $('#div1').prop('scrollHeight') }, 1000);
    }, 20);
    this.commentedText = '';
  }
  cameraCtrl() {
    this.congratsModal = false;
    this.WebRTCAdaptor.stop(this.testStream.streamId);
    if (this.liveStream && this.liveStream.getVideoTracks)
      this.liveStream.getVideoTracks()[0].stop();
    if (this.currentAudio && this.currentAudio.getAudioTracks)
      this.currentAudio.getAudioTracks()[0].stop();
  }
  shareStreamEvent() {
    this.NotifierService.showSuccess('Link Copied')
    navigator.clipboard.writeText(this.url + '/watchStreamingEvent?streamId=' + this.testStream.streamId)
  }

  videoControls(event: any) {
    if (event == 'pause') {
      this.liveStream.getVideoTracks()[0].enabled = false;
      this.WebRTCAdaptor.muteLocalMic();
      // this.currentAudio = navigator.mediaDevices.getUserMedia({ video: false, audio: false });
      // this.currentAudio.getAudioTracks()[0].enabled = false;
      // this.currentAudio.getAudioTracks()[0].stop();
    } else if (event == 'play') {
      this.liveStream.getVideoTracks()[0].enabled = true;
      this.WebRTCAdaptor.unmuteLocalMic();
      // this.currentAudio.getAudioTracks()[0].enabled = true;
    }
    // else if (event == 'mute') {
    //   this.currentAudio.getAudioTracks()[0].enabled = false;
    // } else if (event == 'unmute') {
    //   this.currentAudio.getAudioTracks()[0].enabled = true;
    // }
  }
  openWarningModal(type: any) {
    this.decisionType = type
    if (this.selectTab == 'onboardSoundCheckVideo' && this.tabName == 'onboardDevices') {
      this.openWarnModal = true;
    }
    else {
      if (type == 'home')
        this.router.navigateByUrl('/ArtistDashboard/artistHome')
      else if (type == 'logOut') {
        localStorage.clear();
        this.router.navigateByUrl('/login')
      }
    }
  }
  confirmLeavePage() {
    if (this.decisionType == 'home')
      this.router.navigateByUrl('/ArtistDashboard/artistHome')
    else if (this.decisionType == 'logOut') {
      localStorage.clear();
      this.router.navigateByUrl('/login')
    }
  }

}

  // videoElement: any = document.querySelector('video');
  // audioInputSelect: any = document.querySelector('select#audioSource');
  // audioOutputSelect: any = document.querySelector('select#audioOutput');
  // videoSelect: any = document.querySelector('select#videoSource');
  // selectors: any = [this.audioInputSelect, this.audioOutputSelect, this.videoSelect];
  // cameraOptions = document.querySelector('.video-options>mat-select');
 // videoLink = 'https://bitmovin-a.akamaihd.net/content/playhouse-vr/m3u8s/105560.m3u8';
    // navigator.mediaDevices.getUserMedia()
    //   .then((stream) => {
    //     stream.getTracks().forEach((track) => {
    //     });
    //   })
 // ngDoCheck(){
  //   var a = document.getElementById('videoPlayer');
  // }
          // this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.videoLink);
          // this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoLink);
          // this.videoLink = this.safeUrl.changingThisBreaksApplicationSecurity


          // this.NotifierService.showSuccess(data.message);
   // videoPlay(){
  //   var a = document.getElementById('videoPlayer');
  // }
  // videoCTRL(event: any){

  // }

  //   start() {
  // //     navigator.mediaDevices.getUserMedia({audio: false, video: true})
  // // .then(mediaStream => {
  // //   document.querySelector('video').srcObject = mediaStream;
  // //   // Stop the stream after 5 seconds
  // //   setTimeout(() => {
  // //     const tracks = mediaStream.getTracks()
  // //     tracks[0].stop()
  // //   }, 5000)
  // // })

  //     // if (window.stream) {
  //     //   window.stream.getTracks().forEach((track: any) => {
  //     //     track.stop();
  //     //   });
  //     // }
  //     const audioSource = this.audioInputSelect.value;
  //     const videoSource = this.videoSelect.value;
  //     const constraints = {
  //       audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
  //       video: { deviceId: videoSource ? { exact: videoSource } : undefined }
  //     };
  //     navigator.mediaDevices.getUserMedia(constraints).then(this.gotStream);
  //     // .then(gotDevices).catch(handleError)
  //   }

  // gotStream(stream: any) {
  //   // window.stream = stream; // make stream available to console
  //   this.videoElement.srcObject = stream;
  //   // Refresh button list in case labels have become available
  //   return navigator.mediaDevices.enumerateDevices();
  // }

  // function gotDevices(deviceInfos) {
  //   // Handles being called several times to update labels. Preserve values.
  //   const values = selectors.map(select => select.value);
  //   selectors.forEach(select => {
  //     while (select.firstChild) {
  //       select.removeChild(select.firstChild);
  //     }
  //   });
  //   for (let i = 0; i !== deviceInfos.length; ++i) {
  //     const deviceInfo = deviceInfos[i];
  //     const option = document.createElement('option');
  //     option.value = deviceInfo.deviceId;
  //     if (deviceInfo.kind === 'audioinput') {
  //       option.text = deviceInfo.label || `microphone ${audioInputSelect.length + 1}`;
  //       audioInputSelect.appendChild(option);
  //     } else if (deviceInfo.kind === 'audiooutput') {
  //       option.text = deviceInfo.label || `speaker ${audioOutputSelect.length + 1}`;
  //       audioOutputSelect.appendChild(option);
  //     } else if (deviceInfo.kind === 'videoinput') {
  //       option.text = deviceInfo.label || `camera ${videoSelect.length + 1}`;
  //       videoSelect.appendChild(option);
  //     }
  //   }
  //   selectors.forEach((select, selectorIndex) => {
  //     if (Array.prototype.slice.call(select.childNodes).some(n => n.value === values[selectorIndex])) {
  //       select.value = values[selectorIndex];
  //     }
  //   });
  // }

  // audioInputSelect.onchange = start;
  // audioOutputSelect.onchange = changeAudioDestination;
  // videoSelect.onchange = start;

  // takeSnapshot(): void {
  //   this.trigger.next();
  // }

  // onOffWebCame() {
  //   this.showWebcam = !this.showWebcam;
  // }

  // handleInitError(error: WebcamInitError) {
  //   this.errors.push(error);
  // }
   // recordVideo() {
  //   this._mediaRecorder = new MediaRecorder(this.liveStream, { mimeType: 'video/webm; codecs=vp9' });
  //   this._mediaRecorder.ondataavailable = (e: any) => {
  //     if (e.data.size > 0) {
  //       let file = new FormData();
  //       file.append('blob', e.data);

  //       this._MainService.saveVideoChunks(file, this.streamId, this.isStreamEnded).then((data) => {


  //       })
  //       this._recordedChunks.push(e.data);
  //     }
  //   }
  // }


  // changeWebCame(directionOrDeviceId: boolean | string) {
  //   this.nextWebcam.next(directionOrDeviceId);
  // }

  // handleImage(webcamImage: WebcamImage) {
  //   this.getPicture.emit(webcamImage);
  //   this.showWebcam = false;
  // }

  // get triggerObservable(): Observable<void> {
  //   return this.trigger.asObservable();
  // }

  // get nextWebcamObservable(): Observable<boolean | string> {
  //   return this.nextWebcam.asObservable();
  // }
  // pause() {
  //   this.liveStream.getVideoTracks()[0].enabled = false;
  //   this.currentAudio.getAudioTracks()[0].enabled = false;
  // }
  // play() {
  //   this.liveStream.getVideoTracks()[0].enabled = true;
  //   this.currentAudio.getAudioTracks()[0].enabled = true;
  // }
