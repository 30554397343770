import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { events } from '@syncfusion/ej2-calendars';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { MainService } from 'src/app/service/main.service';

@Component({
  selector: 'app-search-public-user',
  templateUrl: './search-public-user.component.html',
  styleUrls: ['./search-public-user.component.scss']
})
export class SearchPublicUserComponent implements OnInit {

  searchKeyword: any;
  userList: any;
  pageSize: number = 10;
  pageNo: number = 0;
  data: any = this.route.queryParams;
  searchBox: boolean= false;
  searchkey: any


  constructor(private mainService: MainService, private notifier: NotifierService, private route: ActivatedRoute,
    private router: Router, private _SessionService: SessionService) { }

  ngOnInit(): void {
    // this.searchKeyword = this.data['_value'].keyWord;
    this.searchKeyword = this._SessionService.getSession('keyWord')
    this.searchPublicArtistProfile();
  }
  searchPublicArtistProfile(event?: any) {
    this.searchkey = ''
    var value= event && event.target.value ? event.target.value : this.searchKeyword
    this.searchKeyword = value
    this.mainService.searchPublicArtistProfile(this.pageNo, this.pageSize, value).then((data: any) => {
      if (data && data.isSuccess) {
        if (data.data.content.length > 0) {
          this.userList = data.data.content
        } else {
          this.userList = data.data.content
          this.notifier.showError('No Data Found')
        }
      }
      else
        this.notifier.showError(data.message)
    })
  }
  searchBoxController() {
    this.searchBox = !this.searchBox;
  }
  viewProfile(id: any) {
    this.router.navigate(['/userProfile'], {
      queryParams: { userId: id },
    });
  }
}
