import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { MainService } from 'src/app/service/main.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA } from '@angular/cdk/keycodes';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { UtilityService } from 'src/app/service/helper/utility.service';
import { SessionService } from 'src/app/service/helper/session.service';

@Component({
  selector: 'app-artist-drafts',
  templateUrl: './artist-drafts.component.html',
  styleUrls: ['./artist-drafts.component.scss']
})
export class ArtistDraftsComponent implements OnInit {

  deleteDraftIds: any[] = [];
  deleteProposalDraftIds: any[] = [];
  draftList: any;
  status: any
  eventLiveStreamedForm: FormGroup;
  eventOnSiteForm: FormGroup;
  eventHybridForm: FormGroup;
  preferedList: any;
  specificDraftDetails: any;
  eventForm: FormGroup
  proposalDraftForm: FormGroup
  genList: any;
  searchgen: string[] = [];
  selectablegen = true;
  removablegen = true;
  genCtrl = new FormControl();
  separatorKeysCodesgen: number[] = [COMMA];
  actSizeList: any;
  eventList: any;
  eventDesc: any;
  performanceEnvironment: any;
  production: any;
  streetAdd: any;
  prefferdDevice: any;
  requirements: any;
  toogleButtonStatus: any;
  specificId: any
  data: any
  allSelected = false;
  allSelected2 = false;
  allSelected3 = false;
  showLiveSection: boolean;
  onsite: boolean;
  hybrid: boolean;
  @ViewChild('genInput')
  genInput!: ElementRef<HTMLInputElement>;
  TimeZone = this.sessionStorage.getSession('timeZone');

  mainTab = 'editDraft'
  proposalDraftList: any
  tabName = 'per_hour'
  proposalDetails: any
  sendProposalValue: any = {
    additionalNotes: "",
    coverLetter: "",
    estimateHours: "",
    eventId: "",
    hourlyFee: "",
    paidOption: "per_hour",
    totalFee: "",
  }
  selectTab = 'draft'
  checkBox1: boolean = false
  checkBox2: boolean = false
  sendProposalModal: boolean = false
  editProposalId: any
  keyWord: any = this.route.queryParams
  today: any
  tempStartTime: any
  tempEndTime: any
  startTime?: Date;
  time: any;
  endTimes: any;
  startTime2: any;
  endTime2: any;
  selectAllDraft: boolean = false
  selectAllProposalDraft: boolean = false;
  draftListData: boolean = false;
  proposalListData: boolean = false;
  userStatus: any;

  startTimectrl = new FormControl('', (control: AbstractControl) => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hours = value.getHours();
    if (hours < 11 || hours > 12) {
      return { outOfRange: true };
    }
    return null;
  });

  endTime?: Date;
  endTimectrl = new FormControl('', (control: AbstractControl) => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hours = value.getHours();
    if (hours < 11 || hours > 12) {
      return { outOfRange: true };
    }
    return null;
  });

  constructor(private MainService: MainService, private notifier: NotifierService,
    private _FormBuilder: FormBuilder,
    private utility: UtilityService,
    private router: Router,
    private sessionStorage: SessionService,
    private route: ActivatedRoute
  ) {
    this.showLiveSection = true;
    this.onsite = false;
    this.hybrid = false;
    this.proposalDraftForm = this._FormBuilder.group({
      hourlyFee: ['', [Validators.required]],
      totalFee: ['', [Validators.required]],
      additionalNotes: ['', [Validators.required]],
    });
    this.eventForm = this._FormBuilder.group({
      specialReq: ['', [Validators.required]],
      eventTitle: ['', [Validators.required]],
      eventType: ['', [Validators.required]],
      actSize: ['', [Validators.required]],
      genres: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      budget: ['', [Validators.required]],
      location: ['', [Validators.required]],

    });

    this.eventLiveStreamedForm = this._FormBuilder.group({
      preferredDevices: ['', [Validators.required]],
      eventDescp: ['', [Validators.required]],
    });
    this.eventOnSiteForm = this._FormBuilder.group({
      production: ['', [Validators.required]],
      streetAddress: ['', [Validators.required]],
      performanceEnv: ['', [Validators.required]],
      eventDescription: ['', [Validators.required]],
      liveStream: ['', [Validators.required]],
    });
    this.eventHybridForm = this._FormBuilder.group({
      prodCapabilities: ['', [Validators.required]],
      streetAddress: ['', [Validators.required]],
      performanceEnv: ['', [Validators.required]],
      eventDescription: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.today = new Date();
    var dd = String(this.today.getDate()).padStart(2, '0');
    var mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = this.today.getFullYear();
    this.today = yyyy + '-' + mm + '-' + dd;
    this.userStatus = this.sessionStorage.getSession('status');

    let type = this.keyWord['_value'].type
    if (type == 'proposal') {
      this.selectTab = 'proposal'
      this.proposalsList()
    }


    this.getDraftList()
    this.getActSizeList();
    this.getPreferredDeviceList()
    this.getEventTypeList()

    this.genCtrl.valueChanges.pipe(
      debounceTime(500),
      startWith(null)
    ).subscribe((value: string) => {
      if (value) {
        this.MainService.searchGenres(value).then((res: any) => {
          if (res && res.isSuccess) {
            this.genList = res.data;
          }
        })
      }
    })
  }

  getDraftList() {
    this.draftListData = true;
    this.MainService.getDraftList().then((data) => {
      if (data && data.isSuccess) {
        this.draftListData = false;
        this.draftList = data && data.data && data.data.content ? data.data.content : [];
        // this.notifier.showSuccess(data.message)
        for (let i = 0; i < this.draftList.length; i++) {
          this.draftList[i]['date'] = this.draftList[i].createdAt.split("T");
        }
      } else
        this.draftListData = false;
    })
  }

  getPreferredDeviceList() {
    this.MainService.getPreferredDeviceList().then((data) => {
      if (data && data.isSuccess) {
        this.preferedList = data.data;
      } else
        this.notifier.showError(data.message);
    })
  }
  getActSizeList() {
    this.MainService.getActSizeList().then((data) => {
      if (data && data.isSuccess) {
        this.actSizeList = data.data;
      } else
        this.notifier.showError(data.message);
    })
  }
  getEventTypeList() {
    this.MainService.getEventTypeList().then((data) => {
      if (data && data.isSuccess) {
        this.eventList = data.data;
      } else
        this.notifier.showError(data.message);
    })
  }

  @ViewChild('select')
  select!: MatSelect;
  @ViewChild('select2')
  select2!: MatSelect;
  @ViewChild('select3')
  select3!: MatSelect;

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else
      this.select.options.forEach((item: MatOption) => item.deselect());
  }
  toggleAllSelection2() {
    if (this.allSelected2) {
      this.select2.options.forEach((item: MatOption) => item.select());
    } else
      this.select2.options.forEach((item: MatOption) => item.deselect());
  }
  toggleAllSelection3() {
    if (this.allSelected3) {
      this.select3.options.forEach((item: MatOption) => item.select());
    } else
      this.select3.options.forEach((item: MatOption) => item.deselect());
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  optionClick2() {
    let newStatus = true;
    this.select2.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected2 = newStatus;
  }
  optionClick3() {
    let newStatus = true;
    this.select3.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected3 = newStatus;
  }

  specificDraft(id: any) {
    this.specificId = id
    this.mainTab = 'editDraftView'
    this.MainService.getEventDetailById(id).then((data) => {
      if (data && data.isSuccess) {
        this.specificDraftDetails = data.data;
        // this.notifier.showSuccess(data.message);
        this.searchgen = this.specificDraftDetails?.genreDetails;
        this.eventForm.controls.eventTitle.setValue(this.specificDraftDetails?.title);
        this.eventForm.controls.eventType.setValue(this.specificDraftDetails?.eventTypeDetails);
        this.eventForm.controls.actSize.setValue(this.specificDraftDetails?.actSizeDetails);
        this.eventForm.controls.startDate.setValue(this.specificDraftDetails?.startDate.slice(0, 10));
        this.eventForm.controls.endDate.setValue(this.specificDraftDetails?.endDate.slice(0, 10));
        this.tempStartTime = this.specificDraftDetails?.startDate.slice(11, 16)
        this.tempEndTime = this.specificDraftDetails?.endDate.slice(11, 16)
        this.startTime = new Date(this.specificDraftDetails?.startDate);
        this.endTime = new Date(this.specificDraftDetails?.endDate);


        this.eventForm.controls.budget.setValue(this.specificDraftDetails?.budget);
        this.eventForm.controls.location.setValue(this.specificDraftDetails?.endTIme);
        this.status = this.specificDraftDetails?.venue
        if (this.status == 'HYBRID') {
          this.eventHybridForm.controls.prodCapabilities.setValue(this.specificDraftDetails?.productionCapability);
          this.eventHybridForm.controls.streetAddress.setValue(this.specificDraftDetails?.streetAddress);
          this.eventHybridForm.controls.performanceEnv.setValue(this.specificDraftDetails?.performanceEnvironment);
          this.eventHybridForm.controls.eventDescription.setValue(this.specificDraftDetails?.description);
        }
        else if (this.status == 'LIVE_STREAMED') {
          this.eventLiveStreamedForm.controls.preferredDevices.setValue(this.specificDraftDetails?.preferredDeviceDetails);
          this.eventLiveStreamedForm.controls.eventDescp.setValue(this.specificDraftDetails?.description);
        }
        else if (this.status == 'ONSITE') {
          this.eventOnSiteForm.controls.production.setValue(this.specificDraftDetails?.productionCapability);
          this.eventOnSiteForm.controls.streetAddress.setValue(this.specificDraftDetails?.streetAddress);
          this.eventOnSiteForm.controls.performanceEnv.setValue(this.specificDraftDetails?.performanceEnvironment);
          this.eventOnSiteForm.controls.eventDescription.setValue(this.specificDraftDetails?.description);
        }
      }
    })
  }

  selectedgen(event: MatAutocompleteSelectedEvent): void {
    this.searchgen.push(event.option.viewValue);
    this.eventForm.controls.genres.setValue(this.searchgen);
    this.genInput.nativeElement.value = '';
    this.genCtrl.setValue(null);
  }

  removegen(gen: string): void {
    const index = this.searchgen.indexOf(gen);
    if (index >= 0)
      this.searchgen.splice(index, 1);
  }
  addgen(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim())
      this.searchgen.push(value.trim());
    if (input)
      input.value = '';
    this.genCtrl.setValue(null);
  }

  toggle(text: any) {
    this.status = text;
    if (text === 'live_streamed') {
      this.showLiveSection = true;
      this.hybrid = false;
      this.onsite = false;
    } else if (text === 'onsite') {
      this.onsite = true;
      this.showLiveSection = false;
      this.hybrid = false;
    } else if (text === 'hybrid') {
      this.hybrid = true;
      this.onsite = false;
      this.showLiveSection = false;
    }
  }

  statusToggle(status: any) {
    this.toogleButtonStatus = status;
  }




  editEventSubmit(type?: any) {
    if (type == 'back') {
      this.mainTab = 'editDraft'
    }
    else if (type == 'post') {
      let title = this.eventForm.controls['eventTitle'].value;
      let eventType = this.eventForm.controls['eventType'].value;
      let genres = this.searchgen;
      let actSize = this.eventForm.controls['actSize'].value;
      let startDate = this.eventForm.controls['startDate'].value;
      let endDate = this.eventForm.controls['endDate'].value;
      let budget = this.eventForm.controls['budget'].value;
      if (this.eventLiveStreamedForm.controls['preferredDevices'].value) {
        this.prefferdDevice = this.eventLiveStreamedForm.controls['preferredDevices'].value;
      } else {
        this.prefferdDevice = null;
      }
      if (this.eventLiveStreamedForm.controls['eventDescp'].value) {
        this.eventDesc = this.eventLiveStreamedForm.controls['eventDescp'].value;
      } else if (this.eventOnSiteForm.controls['eventDescription'].value) {
        this.eventDesc = this.eventOnSiteForm.controls['eventDescription'].value;
      } else if (this.eventHybridForm.controls['eventDescription'].value) {
        this.eventDesc = this.eventHybridForm.controls['eventDescription'].value;
      }

      if (this.eventOnSiteForm.controls['performanceEnv'].value) {
        this.performanceEnvironment = this.eventOnSiteForm.controls['performanceEnv'].value;
      } else if (this.eventHybridForm.controls['performanceEnv'].value) {
        this.performanceEnvironment = this.eventHybridForm.controls['performanceEnv'].value;
      } else {
        this.performanceEnvironment = ''
      }

      if (this.eventOnSiteForm.controls['production'].value) {
        this.production = this.eventOnSiteForm.controls['production'].value;
      } else if (this.eventHybridForm.controls['prodCapabilities'].value) {
        this.production = this.eventHybridForm.controls['prodCapabilities'].value;
      } else {
        this.production = '';
      }

      if (this.eventOnSiteForm.controls['streetAddress'].value) {
        this.streetAdd = this.eventOnSiteForm.controls['streetAddress'].value;
      } else if (this.eventHybridForm.controls['streetAddress'].value) {
        this.streetAdd = this.eventHybridForm.controls['streetAddress'].value;
      } else {
        this.streetAdd = '';
      }
      if (!this.requirements) {
        this.requirements = '';
      }
      if (!this.status) {
        this.status = 'LIVE_STREAMED'
      }
      if (this.startTime)
        this.time = this.startTime;
      this.startTime2 = this.time?.toLocaleTimeString();
      var newTime = this.time?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      this.endTimes = this.endTime;
      this.endTime2 = this.endTimes?.toLocaleTimeString();
      var newEndTime = this.endTimes?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

      var startDateMili = new Date(startDate + ' ' + newTime).getTime()
      if (this.TimeZone) {
        let date = new Date();
        let time = date.toLocaleString("en-US", { timeZone: this.TimeZone });
        let userTimezoneMilis = new Date(time).getTime()
        var endDateMili = new Date(endDate + ' ' + newEndTime).getTime()
        if (!startDate) {
          this.notifier.showError("Please Enter Event Date");
        } else if (!endDate) {
          this.notifier.showError("Please Enter Event Closing Date")
        } else if (!this.startTime) {
          this.notifier.showError("Please Select Event Starting Time")
        } else if (!this.endTime) {
          this.notifier.showError("Please Select Event Closing Time")
        } else if (!budget) {
          this.notifier.showError("Please Mention Event Budget")
        } else if (startDateMili < userTimezoneMilis) {
          this.notifier.showError("Event Start Date & Time Should Be Greater Than Today's Date & Time")
        } else if (endDateMili < userTimezoneMilis) {
          this.notifier.showError("Event End Date & Time Should Be Greater Than Today's Date & Time")
        } else if (startDateMili >= endDateMili) {
          this.notifier.showError("Event End Date & Time Should Be Greater Than Event Start Date & Time")
        }
        else {
          var a = startDate.replace(/-/g, "/");
          var modifyStartDate = a + ' ' + this.tempStartTime;
          var b = endDate.replace(/-/g, "/");
          var modifyEndDate = b + ' ' + this.tempEndTime;
          let newStartDate = new Date(modifyStartDate);
          let newEndDate = new Date(modifyEndDate);
          let body = {
            actSizes: actSize,
            budget: budget,
            startDate: this.utility.formatDate(newStartDate),
            endDate: this.utility.formatDate(newEndDate),
            description: this.eventDesc,
            genres: genres,
            performanceEnvironment: this.performanceEnvironment,
            preferredDevices: this.prefferdDevice,
            productionCapability: this.production,
            specialRequirement: this.requirements,
            streetAddress: this.streetAdd,
            title: title,
            types: eventType,
            venue: this.status,
            visibilityCriteria: this.toogleButtonStatus ? this.toogleButtonStatus : 'PUBLIC'
          }
          this.MainService.editDraftDetails(this.specificId, body).then((data) => {
            if (data && data.isSuccess) {
              this.notifier.showSuccess(data.message);
              this.router.navigate(['ArtistDashboard/artistInvitation'], { queryParams: { type: this.toogleButtonStatus == 'PRIVATE' ? 'pri' : 'pub', id: data.data } });
            } else
              this.notifier.showError(data.message);
          })
        }
      }
    }
  }

  selectAll(type: any) {
    if (type == 'eventDraft') {
      this.selectAllDraft = !this.selectAllDraft
      if (this.selectAllDraft) {
        for (let i = 0; i < this.draftList.length; i++) {
          this.deleteDraftIds.push(this.draftList[i].id)
        }
      } else
        this.deleteDraftIds = []
    } else if (type == 'proposalDraft') {
      this.selectAllProposalDraft = !this.selectAllProposalDraft
      if (this.selectAllProposalDraft) {
        for (let i = 0; i < this.proposalDraftList.length; i++) {
          this.deleteProposalDraftIds.push(this.proposalDraftList[i].proposalId)
        }
      }
      else
        this.deleteProposalDraftIds = []
    }
  }

  deleteDraftSelection(id: any) {
    this.deleteDraftIds.push(id)
  }

  slectValue(event: any) {
    this.MainService.searchGenres(event.target.value).then((data) => {
      this.data = data.data;
      this.eventForm.controls.genres.reset();
    })
  }
  deleteDrafts() {
    if (this.deleteDraftIds.length > 0) {
      this.MainService.deleteDrafts(this.deleteDraftIds).then((data) => {
        if (data && data.isSuccess) {
          this.notifier.showSuccess(data.message);
          this.deleteDraftIds = []
          this.getDraftList()
        } else
          this.notifier.showError(data.message);
      })
    } else
      this.notifier.showError('Please Select Atleast One Draft')
  }

  calculateAmount() {
    this.proposalDraftForm.controls.totalFee.setValue(this.proposalDraftForm.controls['hourlyFee'].value * this.sendProposalValue.estimateHours);
    this.sendProposalValue.hourlyFee = this.proposalDraftForm.controls['hourlyFee'].value
    this.sendProposalValue.totalFee = this.proposalDraftForm.controls['totalFee'].value
  }

  proposalsList() {
    this.proposalListData = true;
    this.MainService.getProposalDraftList().then((data) => {
      if (data && data.isSuccess) {
        this.proposalListData = false;
        this.proposalDraftList = data.data.content;
      } else
        this.proposalListData = false;
    })
    this.selectTab = 'proposal'
  }
  specificProposalDraft(id: any) {
    this.MainService.getEditProposalDraft(id).then((data) => {
      if (data && data.isSuccess) {
        this.proposalDetails = data.data;
        this.editProposalId = this.proposalDetails.proposalId
        this.sendProposalValue.coverLetter = this.proposalDetails.coverLetter
        this.sendProposalValue.estimateHours = this.proposalDetails.estimateHours
        this.sendProposalValue.hourlyFee = this.proposalDetails.hourlyFee
        this.proposalDraftForm.controls.hourlyFee.setValue(this.proposalDetails.hourlyFee);
        this.sendProposalValue.totalFee = this.proposalDetails.totalFee
        this.proposalDraftForm.controls.totalFee.setValue(this.proposalDetails.totalFee);
        this.sendProposalValue.additionalNotes = this.proposalDetails.additionalNotes
        this.proposalDraftForm.controls.additionalNotes.setValue(this.proposalDetails.additionalNotes);
        this.sendProposalValue.eventId = this.proposalDetails.eventId
        this.sendProposalValue.paidOption = this.proposalDetails.paidOption.toLowerCase()
        if (this.proposalDetails.paidOption == 'TOTAL_FEE')
          this.tabName = 'total_fee'
        this.mainTab = 'proposalModal'
      }
    })
  }

  deleteProposalDraftSelection(id: any) {
    this.deleteProposalDraftIds.push(id)
  }
  deleteProposalDrafts() {
    if (this.deleteProposalDraftIds.length > 0) {
      this.MainService.deleteProposalDrafts(this.deleteProposalDraftIds).then((data) => {
        if (data && data.isSuccess) {
          this.notifier.showSuccess(data.message);
          this.deleteProposalDraftIds = []
          this.proposalsList()
        } else
          this.notifier.showError(data.message);
      })
    } else
      this.notifier.showError('Please Select Atleast One Draft')
  }

  paidOptionSelection(type: string) {
    this.tabName = type
    this.sendProposalValue.paidOption = type
  }

  submitProposal(type: string) {
    this.sendProposalValue.additionalNotes = this.proposalDraftForm.controls['additionalNotes'].value
    this.sendProposalValue.totalFee = this.proposalDraftForm.controls['totalFee'].value
    if (this.sendProposalValue.paidOption == 'per_hour')
      this.sendProposalValue.totalFee = (this.sendProposalValue.estimateHours * this.sendProposalValue.hourlyFee)
    else if (this.sendProposalValue.paidOption == 'total_fee')
      this.sendProposalValue.hourlyFee = 0
    if (this.checkBox1 && this.checkBox2) {
      if (this.sendProposalValue.coverLetter == "" || this.sendProposalValue.coverLetter == null || this.sendProposalValue.coverLetter == undefined)
        this.notifier.showError('Please mention cover letter')
      else if (this.sendProposalValue.estimateHours == "" || this.sendProposalValue.estimateHours == null || this.sendProposalValue.estimateHours == undefined)
        this.notifier.showError('Please mention estimate hours')

      else if (this.sendProposalValue.paidOption == 'per_hour' || this.sendProposalValue.paidOption == 'total_fee') {
        if (this.sendProposalValue.paidOption == 'per_hour') {
          if (this.sendProposalValue.hourlyFee == "" || this.sendProposalValue.hourlyFee == null || this.sendProposalValue.hourlyFee == undefined)
            this.notifier.showError('Please mention hourly fee')
          else
            this.finalSubmitProposal(type)
        }
        else if (this.sendProposalValue.paidOption == 'total_fee') {
          if (this.sendProposalValue.totalFee == "" || this.sendProposalValue.totalFee == null || this.sendProposalValue.totalFee == undefined)
            this.notifier.showError('Please mention total fee')
          else
            this.finalSubmitProposal(type)
        }
      }
    }
    else
      this.notifier.showError('Please Select All Terms & Conditions')
  }
  finalSubmitProposal(type: string) {
    let body = this.sendProposalValue
    if (type == 'submitProposal') {
      this.MainService.editProposalproposal(body, this.editProposalId).then((data) => {
        if (data && data.isSuccess) {
          this.mainTab = 'editDraft'
          this.notifier.showSuccess(data.message);
          this.proposalsList()
        } else
          this.notifier.showError(data.message);
      })
    }
  }
}

