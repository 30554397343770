import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/service/helper/session.service';

declare const Stripe: any;
@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit {
  pendingEventList: any;
  upcomingEventList: any;
  eventList: any;
  eventDetails: any;
  pageNo: number = 0;
  pageSize: number = 5;
  newEvent: any[] = [];
  currentPage = 0;
  selectedEventId: any;
  selectedProposalId: any;
  proposalsList: any;
  completeHiringModal: boolean = false;
  declineProposalModal: boolean = false;
  selectTab = 'eventsTab';
  selectedProposalData: any;
  reason: any = 'The Fee is out of my budget';
  remark: any;
  otherReason: boolean = false;
  checkBox1: boolean = false;
  checkBox2: boolean = false;
  eventType: any = 'all';
  editProposalValue: any = {
    estimateHours: '',
    proposalId: '',
    hourlyFee: '',
    paidOption: 'per_hour',
    totalFee: '',
  };
  buttonDisable: boolean = true;
  proposalDraftForm: FormGroup;
  tabName = 'per_hour';
  stripe = Stripe(environment.stripePublicKey);
  newFee: any;
  paymentMessage: any;
  paymentStatus: boolean = false;
  paymentFailed: boolean = false;
  eventsData: boolean = false;
  confirmCancelEvent: boolean = false;
  cancelReason: any;
  userStatus: any;
  proposalTitle: any = [];
  hubspotEmail: any;
  processingPayment: boolean = false;
  specificEventData: any = this.activatederoute.queryParams;
  specificEvent: boolean = false
  keyWord: any;

  constructor(
    private MainService: MainService,
    private NotifierService: NotifierService,
    private fb: FormBuilder,
    private sessionStorage: SessionService,
    private router: Router,
    private activatederoute: ActivatedRoute
  ) {
    this.proposalDraftForm = this.fb.group({
      hourlyFee: ['', [Validators.required]],
      totalFee: ['', [Validators.required]],
      additionalNotes: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.userStatus = this.sessionStorage.getSession('status');
    this.hubspotEmail = this.sessionStorage.getSession('email');
    this.paymentMessage = this.activatederoute.snapshot.queryParams.success;
    if (this.paymentMessage == 'false') {
      this.paymentFailed = true;
    } else if (this.paymentMessage == 'true') {
      this.selectedEventId = this.sessionStorage.getSession('selectedEventId');
      this.selectedProposalId = this.sessionStorage.getSession('selectedProposalId');
      this.paymentStatus = true;
      this.selectTab = 'paymentSuccess';
      this.setPaymentStatus();
      this.markEventHiringInProgress(this.selectedEventId);
    }
    if (this.specificEventData && this.specificEventData._value.eventId && this.specificEventData._value.proposalId) {
      this.specificEvent = true
      this.openRockBand(this.specificEventData._value.eventId);
      this.selectedProposal(this.specificEventData._value.proposalId);
      if (this.specificEventData._value.eventId || this.specificEventData._value.proposalId)
        this.router.navigate(['/audienceDashboard/eventPreview'])
    } else if (this.specificEventData && this.specificEventData._value.eventId && this.specificEventData._value.goLive == 'true') {
      this.getSearchEvent(this.specificEventData._value.eventId);
      this.router.navigate(['/audienceDashboard/eventPreview'])
    }
    else
      this.getAudienceEventList(this.pageNo, this.pageSize, this.eventType);
  }
  ngDoCheck() {
    if (this.specificEventData._value.eventId || this.specificEventData._value.proposalId)
      this.ngOnInit()
  }

  enableClick() {
    var box = document.getElementById('termsCondition');
    if (box && box.scrollTop >= 4700) this.buttonDisable = false;
  }
  checkCondition() {
    this.confirmCancelEvent = !this.confirmCancelEvent;
  }

  getAudienceEventList(pageNo: number, pageSize: number, type: any) {
    this.newEvent = [];
    this.eventsData = true;
    this.MainService.getAudienceEventList(pageNo, pageSize, type).then(
      (data) => {
        if (data && data.isSuccess) {
          this.newEvent = [];
          this.eventsData = false;
          this.eventList = data.data?.content;
          this.newEvent = this.eventList;
          if (this.newEvent) {
            this.newEvent.forEach((item: any) => {
              const day = new Date().toDateString();
              const lastDay = new Date();
              lastDay.setDate(lastDay.getDate() - 1);
              var date = new Date(item.createdAt).toDateString();
              if (date == day) {
                item.dateStatus = 'Today';
              } else if (date == lastDay.toDateString()) {
                item.dateStatus = 'Yesterday';
              } else {
                item.dateStatus = 'Day After';
              }
            });
          }
        } else {
          this.eventsData = false;
          this.newEvent = [];
          this.NotifierService.showError(data.message);
        }
      }
    );
  }
  getSearchEvent(keyWord?: any) {
    if (keyWord == undefined || keyWord == null)
      keyWord = this.keyWord
    if (keyWord.length == 0)
    this.getAudienceEventList(this.pageNo, this.pageSize, this.eventType);
    else {
      this.newEvent = [];
    this.eventsData = true;
    this.MainService.getSearchEventByUser(keyWord).then(
      (data) => {
        if (data && data.isSuccess) {
          this.newEvent = [];
          this.eventsData = false;
          this.eventList = data.data?.content;
          this.newEvent = this.eventList;
          if (this.newEvent) {
            this.newEvent.forEach((item: any) => {
              const day = new Date().toDateString();
              const lastDay = new Date();
              lastDay.setDate(lastDay.getDate() - 1);
              var date = new Date(item.createdAt).toDateString();
              if (date == day) {
                item.dateStatus = 'Today';
              } else if (date == lastDay.toDateString()) {
                item.dateStatus = 'Yesterday';
              } else {
                item.dateStatus = 'Day After';
              }
            });
          }
        } else {
          this.eventsData = false;
          this.newEvent = [];
          this.NotifierService.showError(data.message);
        }
      }
    );
    }
  }

  onScroll(type: any) {
    this.eventsData = true;
    this.currentPage = this.currentPage + 1;
    this.MainService.getAudienceEventList(
      this.currentPage,
      this.pageSize,
      type
    ).then((data) => {
      if (data && data.isSuccess) {
        this.eventsData = false;
        if (data.data.content.length) {
          this.eventList = [...this.eventList, ...data.data.content];
          this.newEvent = this.eventList;
          this.newEvent.forEach((item: any) => {
            const day = new Date().toDateString();
            const lastDay = new Date();
            lastDay.setDate(lastDay.getDate() - 1);
            var date = new Date(item.createdAt).toDateString();
            if (date == day) {
              item.dateStatus = 'Today';
            } else if (date == lastDay.toDateString()) {
              item.dateStatus = 'Yesterday';
            } else {
              item.dateStatus = 'Day After';
            }
          });
        }
      }
    });
  }

  openRockBand(id: any) {
    this.selectedEventId = id;
    this.MainService.getEventDetailById(id).then((data) => {
      if (data && data.isSuccess) {
        this.eventDetails = data.data;
        if (this.specificEvent == false)
          this.selectTab = 'eventDetailTab';
        // this.NotifierService.showSuccess(data.message);
      } else {
        this.NotifierService.showError(data.message);
      }
    });
  }

  createNewEvent() {
    this.router.navigate(['audienceDashboard/event'], {
      queryParams: { createEvent: true },
    });
  }
  getAllProposalList() {
    this.MainService.getAllProposalList(this.selectedEventId).then((data) => {
      if (data && data.isSuccess) {
        this.proposalsList = data?.data?.content;
        if (data.message != 'No Proposal List Found') {
          this.selectTab = 'proposalsListTab';
        }
        // this.NotifierService.showSuccess(data.message);
        if (this.proposalsList && this.proposalsList.length > 0) {
          for (let i = 0; i < this.proposalsList.length; i++) {
            this.proposalTitle[i] = this.proposalsList[i].proposalStatus;
            if (
              this.proposalsList[i].proposalStatus == 'PROPOSAL_OWNER_ACCEPTED'
            )
              this.proposalTitle[i] = 'PROPOSAL OWNER ACCEPTED';
            if (this.proposalsList[i].proposalStatus == 'EVENT_OWNER_ACCEPTED')
              this.proposalTitle[i] = 'EVENT OWNER ACCEPTED';
          }
        }
      } else {
        this.NotifierService.showError(data.message);
      }
    });
  }
  selectedProposal(id: any) {
    this.selectedProposalId = id;
    this.MainService.getEditProposalDraft(id).then((data) => {
      if (data && data.isSuccess) {
        if (data.data.proposalStatus == 'EVENT_OWNER_ACCEPTED')
          this.selectTab = 'pendingProposalOwnerSideView';
        else if (data.data.proposalStatus == 'PROPOSAL_OWNER_ACCEPTED' || data.data.proposalStatus == 'PERFORMANCE_STARTED' || data.data.proposalStatus == 'PERFORMANCE_ENDED') {
          this.selectTab = 'proceedToPayment';
        } else
          this.selectTab = 'individualProposalView';
        this.selectedProposalData = data.data;
        this.editProposalValue.estimateHours = this.selectedProposalData.estimateHours;
        this.proposalDraftForm.controls.hourlyFee.setValue(this.selectedProposalData.hourlyFee);
        this.proposalDraftForm.controls.totalFee.setValue(this.selectedProposalData.totalFee);
        if (this.selectedProposalData.paidOption == 'TOTAL_FEE') {
          this.tabName = 'total_fee';
          this.editProposalValue.paidOption = 'TOTAL_FEE';
        }
      }
    });
  }

  setPaymentStatus() {
    this.MainService.setPaymentStatus(this.selectedProposalId).then((data) => {
      if (data && data.isSuccess) { }
      this.router.navigate(['audienceDashboard/eventPreview'])
    });
  }

  declineReason(reason: any) {
    if (reason == 'Other') {
      this.otherReason = true;
      this.remark = '';
    } else if (reason != 'Other') {
      this.remark = reason;
      this.reason = '';
      this.otherReason = false;
    }
  }

  completeHiring(type: any) {
    let body = {
      eventId: this.selectedEventId,
      proposalId: this.selectedProposalId,
      remark:
        type == 'EVENT_OWNER_ACCEPTED' ? '' : this.reason ? this.reason : this.remark, status: type,
    };

    this.MainService.acceptOrDeclineProposal(body).then((data) => {
      if (data && data.isSuccess) {
        if (type == 'EVENT_OWNER_ACCEPTED') {
          this.selectTab == 'proposalsListTab';
          this.getAllProposalList();
        } else if (type == 'DECLINED') {
          this.getAllProposalList();
          this.declineProposalModal = false;
        }
        this.selectTab = 'proposalsListTab';
        this.NotifierService.showSuccess(data.message);
      } else {
        this.NotifierService.showError(data.message);
      }
    });
  }
  doneHiring(eventId?: any) {
    let id = eventId ? eventId : this.selectedEventId
    this.MainService.doneHiring(id).then((data) => {
      if (data && data.isSuccess) {
        this.completeHiringModal = false;
        this.getAudienceEventList(this.pageNo, this.pageSize, this.eventType);
        this.selectTab = 'eventsTab';
        this.NotifierService.showSuccess(data.message);
      }
    });
  }

  calculateAmount() {
    if (this.tabName == 'per_hour')
      this.proposalDraftForm.controls.totalFee.setValue(
        this.proposalDraftForm.controls['hourlyFee'].value *
        this.editProposalValue.estimateHours
      );
    this.editProposalValue.hourlyFee =
      this.proposalDraftForm.controls['hourlyFee'].value;
    this.editProposalValue.totalFee =
      this.proposalDraftForm.controls['totalFee'].value;
  }
  paidOptionSelection(type: string) {
    this.tabName = type;
    this.editProposalValue.paidOption = type;
  }
  editContract(type: any) {
    if (type == 'cancel') {
      if (this.selectedProposalData.proposalStatus == 'EVENT_OWNER_ACCEPTED')
        this.selectTab = 'pendingProposalOwnerSideView';
      else this.selectTab = 'individualProposalView';
    } else if (type == 'save') {
      this.editProposalValue.proposalId = this.selectedProposalId;
      this.MainService.EditContract(this.editProposalValue).then((data) => {
        if (data && data.isSuccess) {
          if (
            this.selectedProposalData.proposalStatus == 'EVENT_OWNER_ACCEPTED'
          )
            this.selectTab = 'pendingProposalOwnerSideView';
          else this.selectTab = 'individualProposalView';
          this.selectedProposal(this.selectedProposalId);
          this.NotifierService.showSuccess(data.message);
        } else {
          this.NotifierService.showError(data.message);
        }
      });
    }
  }

  checkoutStripe(fee: any) {
    this.processingPayment = true;
    this.sessionStorage.setSessions({ selectedEventId: this.selectedEventId });
    this.sessionStorage.setSessions({
      selectedProposalId: this.selectedProposalId,
    });
    this.newFee = fee * 100;
    this.MainService.createCheckoutSession(
      this.newFee,
      this.selectedProposalId,
      this.selectedEventId
    )
      .then((data) => {
        if (data && data.isSuccess) {
          window.open(data.data, '_blank');
          this.processingPayment = false;
          // return this.stripe.redirectToCheckout({
          //   sessionId: data.data,
          // });
        }
      })
      .then((res) => { });
  }

  markEventHiringInProgress(id: any) {
    this.MainService.markEventHiringInProgress(id).then((data: any) => {
      if (data && data.isSuccess) { }
      this.router.navigate(['audienceDashboard/eventPreview'])
    });
  }

  downloadContract() {
    this.MainService.downloadContract(this.selectedProposalId).then(
      async (data) => {
        if (data) {
          const url = window.URL.createObjectURL(data);
          let aTag = document.createElement('a');
          document.body.appendChild(aTag);
          aTag.setAttribute('style', 'display: none');
          aTag.href = url;
          aTag.download = `Event_Contract${this.selectedProposalId
            }_${new Date().getDate()}/${new Date().getMonth() + 1
            }/${new Date().getFullYear()}`;
          aTag.click();
          window.URL.revokeObjectURL(url);
          aTag.remove();
          this.NotifierService.showSuccess(
            'Contract PDF Downloaded Successfully'
          );
        } else {
          this.NotifierService.showError('Download Failed');
        }
      }
    );
  }
  liveStream(id: any) {
    this.router.navigate(['/audienceDashboard/audienceLiveStreaming'], { queryParams: { id: id } });
  }

  cancelEvent() {
    let body = {
      eventId: this.selectedEventId,
      remark: this.cancelReason,
    };
    this.MainService.cancelEvent(body).then((data) => {
      if (data && data.isSuccess) {
        this.submitHubspotForm();
        this.selectTab = 'eventsTab';
        this.customerRefund();
        this.NotifierService.showSuccess(data.message);
      } else {
        this.NotifierService.showError(data.message);
      }
    });
  }

  customerRefund() {
    this.MainService.customerRefund('event', this.selectedEventId).then(
      (data: any) => {
        if (data && data.isSuccess) {
          this.NotifierService.showSuccess(data.message);
        } else {
          this.NotifierService.showError(data.message);
        }
      }
    );
  }

  shareEvent(id: any) {
    this.router.navigate([`/audienceDashboard/Invite-Guests`], { queryParams: { id: id } });
  }

  submitHubspotForm() {
    var xhr = new XMLHttpRequest();
    var url =
      'https://api.hsforms.com/submissions/v3/integration/submit/9406878/e3e12e0f-1d7e-432e-928e-e8371db56577';
    var data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: this.hubspotEmail,
        },
        {
          name: 'TICKET.subject',
          value: '',
        },
        {
          name: 'TICKET.content',
          value: this.cancelReason,
        },
        {
          name: 'accept_tos',
          value: 1,
        },
      ],
      context: {
        pageUri: environment.hubspotNavigateUrl + '/audienceDashboard/eventPreview',
        pageName: 'Cancel Event',
      },
    };
    var final_data = JSON.stringify(data);
    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
      } else if (xhr.readyState == 4 && xhr.status == 400) {
      } else if (xhr.readyState == 4 && xhr.status == 403) {
      } else if (xhr.readyState == 4 && xhr.status == 404) {
        // alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
      }
    };
    xhr.send(final_data);
  }

  sendMessage(id: any, name: any) {
    this.router.navigate([`/audienceDashboard/message`], { queryParams: { 'id': id, 'name': name } })
  }

}
