import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { MainService } from 'src/app/service/main.service';

@Component({
  selector: 'app-audience-earnings',
  templateUrl: './audience-earnings.component.html',
  styleUrls: ['./audience-earnings.component.scss']
})
export class AudienceEarningsComponent implements OnInit {
  userStatus: any;
  profilePicData: any;
  generalDetails: any;
  viewTransactionList: boolean = false;
  transactionList: any;
  tabName = 'viewGenralDetail';
  constructor(private _MainService: MainService, private sessionStorage: SessionService,
    private notifier: NotifierService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.userStatus = this.sessionStorage.getSession('status');
    // this._MainService.getAllStripeCustomer().then((data) => {
    //   if (data && data.isSuccess) {
    //     this.subscriptionData = data.data;
    //     this.selectedPlanName = this.subscriptionData.subscriptionPlan == null ? 'basic' : this.subscriptionData.subscriptionPlan;
    //   }
    // })
    this.getUserPayoutDetail();
  }
  getUserPayoutDetail() {
    let userId = localStorage.getItem('userId');
    this._MainService.getUserPayoutDetail(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.profilePicData = data.data;
      } else {
        this.notifier.showError(data.message);
      }
    });
  }
  getTransactionDetail(){
    this.tabName = 'viewTransactionList'
    this._MainService.getTransactionDetail().then((data) => {
      if (data.isSuccess) {
        this.transactionList = data.data;
      }
    }).then((res) => {
    })  }
}



