<div class="container mr-5 mt-4 pb-3 pl-0 ml-0">
    <div class="row ml-5">
      <div class="col-12 pl-0 d-flex align-items-center">
        <i class="fa fa-chevron-left mr-4 pointer" style="font-size: 20px;" [routerLink]="['/audienceDashboard/audienceHome']"></i>
        <img src="../../../../../../assets/dashboard/Vector-bill.png" alt="">
        <h4 class="mb-0 ml-1">Billing</h4>
      </div>
    </div>
    <div class="row ml-5 mt-4 shadowBox">
      <div class="col-12 mt-1 ml-2">
        <div class="row my-1">
          <div class="col-12 d-flex align-items-center">
            <h5 class="mb-0 pb-2 borderBtm">General</h5>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3 ml-2">
        <div class="row mb-5">
          <div class="col-4">
            <div class="ckij">
            <div style="background: #11111100; margin-left: 65px; background-size: 100% 100%; height: 125px;width: 125px;">
              <img [src]="profilePicData?.avatarUrl ? profilePicData?.avatarUrl: 'assets/dashboard/Ellipse 2.png'"
              width="100%" height="100%" style="border: 1px solid red; padding: 4px; border-radius: 50%; object-fit: cover;">
              </div>
              <h4>{{profilePicData?.fullName}}</h4>
              <p>{{profilePicData?.email}}</p>
              <p *ngFor="let loc of profilePicData?.locations">{{loc}}</p>
            </div>
          </div>
          <div *ngIf=" tabName == 'viewGenralDetail'" class="col-8">
            <div class="row pl-5 mt-4">
              <div class="col-4">
                <h6> Full Name: </h6>
              </div>
              <div class="col-8">
                <p> {{profilePicData?.fullName}} </p>
              </div>
            </div>
            <div class="row pl-5 mt-3">
              <div class="col-4">
                <h6> Email: </h6>
              </div>
              <div class="col-8">
                <p> {{profilePicData?.email}} </p>
              </div>
            </div>
            <div class="row pl-5 mt-3">
              <div class="col-4">
                <h6> Display Name: </h6>
              </div>
              <div class="col-8">
                <p> {{profilePicData?.displayName}} </p>
              </div>
            </div>
            <div class="row pl-5 mt-3">
              <div class="col-4">
                <h6> View Transaction: </h6>
              </div>
              <div class="col-8" style="color: red;">
                <p class="pointer" (click)="getTransactionDetail()"> Click Here to view my recent transaction</p>
              </div>
            </div>
          </div>
          <div *ngIf="tabName == 'viewTransactionList'" class="col-8">
            <div class="row pl-1 mt-2">
              <div class="col-12">
                <div class="table-responsive" style="overflow: auto;height: 40vh;">
                  <table class="table flatTable">
                    <thead class="tableHeader">
                      <tr class="tableRow">
                        <div class="col-1 p-0">
                        <th>
                          <p class="mb-0"> # </p>
                        </th>
                        </div>
                        <div class="col-5">
                        <th>
                          <p class="mb-0"> Title </p>
                        </th>
                        </div>
                        <div class="col-4">
                          <th>
                            <p class="mb-0"> Date </p>
                          </th>
                          </div>
                        <div class="col-2">
                        <th>
                          <p class="mb-0"> View Receipt </p>
                        </th>
                        </div>
                      </tr>
                    </thead>
                    <tbody *ngIf="transactionList.length > 0">
                      <tr class="tableRow" *ngFor="let list of transactionList;let i= index">
                        <div class="col-1 p-0">
                        <td>
                          <p class="mb-0"> {{i+1}} </p>
                        </td>
                        </div>
                        <div class="col-5 px-0">
                        <td class="px-0">
                          <p class="mb-0"> {{ list.description }} </p>
                        </td>
                        </div>
                        <div class="col-4">
                          <td>
                            <p class="mb-0"> {{ list.createdAt }} </p>
                          </td>
                          </div>
                        <div class="col-2">
                        <td style="cursor: pointer">
                          <p class="mb-0"><a style="color: orangered;" [href]="list.receiptUrl" target="_blank"><i class="fa fa-eye"></i></a></p>
                        </td>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                  <div class="col-12 mt-5 text-center" *ngIf="transactionList.length == 0">
                    <h6> You have no billing information yet. </h6>
                  </div>
              </div>
            </div>
            <div class="row pl-5 mt-4">
              <div class="col-12">
                <button type="button" class="btn hftrdd" (click)="tabName = 'viewGenralDetail'"> Go Back </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

