<div class="joinpage">
  <div class="joinhead">
    <img src="https://virtuosica-content-bucket.s3.amazonaws.com/1654578634574_svglogo.svg" routerLink=""
      style="cursor: pointer;">
  </div>
  <div class="joinbody">
    <div class="row joinb">
      <div class="col-md-7">
        <div class="joinleft" style="width: 490px;margin-top: 58px;">
          <app-slider></app-slider>
        </div>
      </div>
      <div class="col-md-5">
        <form lass="frm" [formGroup]="resetPasswordForm">
          <div class="joinright">
            <h3>Reset Password</h3>
            <label class="required">New Password</label><br>
            <input matInput type="password" formControlName="newPassword" class="f2" [type]="hide ? 'password' : 'text'">
            <mat-icon matSuffix (click)="hide = !hide">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
            <br>
            <!-- <span  matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon class="show-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </span> -->

            <div class="text-danger"
              *ngIf="resetPasswordForm.controls['newPassword'].touched && !resetPasswordForm.controls['newPassword'].valid">
              <span *ngIf="resetPasswordForm.controls['newPassword'].hasError('required')">
                Password is required </span>
              <span *ngIf="resetPasswordForm.controls['newPassword'].hasError('pattern') ">
                Password Should be Eight characters <br> long and alphanumeric with special characters.
              </span>
            </div>
            <label class="required">Confirm New Password</label><br>
            <input type="password" formControlName="confirmPassword">
            <br>
            <div class="text-danger"
              *ngIf="resetPasswordForm.controls['confirmPassword'].touched && !resetPasswordForm.controls['confirmPassword'].valid">
              <span *ngIf="resetPasswordForm.controls['confirmPassword'].hasError('required')">
                Confirm Password is required </span>
              <span *ngIf="resetPasswordForm.hasError('mustMatch', 'confirmPassword')">
                Password doesn't match..? </span>
            </div>
            <button class="button" (click)="resetPwd()">Reset Password</button>
            <h1 routerLink="/login">Back to log in</h1>
          </div>
        </form>
      </div>
    </div>
    <div class="ml-4 mt-4">
      <app-footer></app-footer>
    </div>
  </div>
