<div class="hgnb mt-2">
  <div class=" nvhi">
    <img src="assets/dashboard/arr.svg">
    <img src="assets/dashboard/Mask Group.png" class="pro-fil">
    <span>My Profile</span>
  </div>
  <div class="erfg">
    <a class="eijh" [routerLink]="['/audienceDashboard/edit']">Edit Profile <img src="assets/dashboard/et.png"> </a>
  </div>
</div>
<div class="mt-4">
  <div class="khiu card ">
    <div class="card-header">
      <div class="tabbable-responsive">
        <div class="tabbable">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="first-tab" data-toggle="tab" href="#first" role="tab" aria-controls="first"
                aria-selected="true" (click)="showDragDrop = false; tabName = 'General'">General</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="second-tab" data-toggle="tab" href="#second" role="tab" aria-controls="second"
                aria-selected="false" (click)="showDragDrop = false; tabName = 'Details'">Details</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-body" style="height: 460px;">
      <div class="tab-content">
          <div class="row">
            <div class="col-lg-4">
              <div class="ckij">
                <div *ngIf="showDragDrop">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="16 / 9"
                [resizeToWidth]="256" [cropperMinWidth]="149" [onlyScaleDown]="true" [roundCropper]="false"
                [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
                [style.display]="showCropper ? null : 'none'" (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
            </div>
            <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" *ngIf="showDragDrop" style="margin-right:127px" />
            <div class="edit-profile-option2">
              <button type="submit" class="eijh2" style="cursor: pointer;" *ngIf="showDragDrop" (click)="cropDone()">Submit</button>
              <button type="submit"  class="btn hftrdd" style="cursor: pointer;" *ngIf="showDragDrop" (click)="showDragDrop = false">Cancel</button>
              </div>
            <div *ngIf="imageSpinnerDone" class="spinner">
              <sa-spinner-dotted [size]="59" [thickness]="103" [speed]="111" color="#dd0031"
              secondaryColor="rgba(24, 24, 24, 0.1)" ></sa-spinner-dotted>
            </div>
            <div style="background: #11111100; margin-left: 75px; margin-top: 40px; background-size: 100% 100%; height: 125px;width: 125px;">
              <img [src]="profilePicData?.avatarUrl ? profilePicData?.avatarUrl: 'assets/dashboard/Ellipse 2.png'" *ngIf="!showDragDrop && !imageSpinnerDone"
              width="100%" height="100%" style="border: 1px solid red; padding: 4px; border-radius: 50%; object-fit: cover;">
              </div>
                <div class="edit-profile-option" *ngIf="!showDragDrop">
                  <i class="fa fa-pencil" aria-hidden="true" (click)="file.click()" ></i>
                </div>
                <input type="file" accept="image/*" #file (change)="onFileSelect($event)" style="display: none;">
                <div>
                 </div>
                <h4>{{profilePicData?.fullName}}</h4>
                <p>{{profilePicData?.email}}</p>
                <p>{{profilePicData?.location}}</p>
              </div>
            </div>
            <div *ngIf="tabName == 'General'" class="col-lg-8 tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
              <div class="hgbpp">
                <table class="ithy">
                  <tr>
                    <td>Name</td>
                    <td class="f14">{{audienceProfileData?.fullName}}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td class="f14">{{audienceProfileData?.email}}</td>
                  </tr>
                  <tr>
                    <td>Display Name </td>
                    <td class="f14">{{audienceProfileData?.displayName}}</td>
                  </tr>
                  <tr>
                    <td>Age</td>
                    <td class="f14">{{audienceProfileData?.ageRange}}</td>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>
                      <div class="row">
                        <ng-container *ngFor="let country of audienceProfileData?.locations">
                          <div class="col-12 col-md-4 col-lg-4 lo-cn" style="margin-bottom: 14px;">
                            <span style="padding: 0px 8px; display: flex;">{{country}}</span>
                          </div>
                        </ng-container>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Time Zone</td>
                    <td>
                        {{audienceProfileData?.timeZone}}
                    </td>
                </tr>
                </table>
              </div>
            </div>
            <div *ngIf="tabName == 'Details'" class="col-lg-8 tab-pane fade" id="second" role="tabpanel" aria-labelledby="second-tab">
              <div class="hgbpp">
                <table class="ithy">
                  <tr>
                    <td>Favorite Genres</td>
                    <td>
                      <div class="row">
                        <ng-container *ngFor="let generes of audienceProfileData?.genres">
                          <div class="col-12 col-md-4 col-lg-4 lo-cn" style="margin-bottom: 14px;">
                            <span style="padding: 0px 8px; display: flex;">{{generes}}</span>
                          </div>
                        </ng-container>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Devices Used</td>
                    <div class="row">
                      <ng-container *ngFor="let device of audienceProfileData?.devices">
                        <div class="col-12 col-md-4 col-lg-4 lo-cn" style="margin-bottom: 14px;">
                          <span style="padding: 0px 8px; display: flex;">{{device}}</span>
                        </div>
                      </ng-container>
                    </div>
                  </tr>
                  <tr>
                    <td>Favorite musicans/bands/composers</td>
                    <div class="row">
                      <ng-container *ngFor="let musicans of audienceProfileData?.musicians">
                        <div class="col-12 col-md-4 col-lg-4 lo-cn" style="margin-bottom: 14px;">
                          <span style="padding: 0px 8px; display: flex;">{{musicans}}</span>
                        </div>
                      </ng-container>
                    </div>
                  </tr>
                  <tr>
                    <td>Links to social media</td>
                    <div class="lo-cn" *ngFor="let media of audienceProfileData?.socialMediaLinks">
                      <span>{{media}}</span>
                    </div>
                  </tr>
                </table>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
