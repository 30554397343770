import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from 'src/app/service/main.service';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import SwiperCore, { Pagination, Navigation, Autoplay, SwiperOptions } from "swiper";
import { SessionService } from 'src/app/service/helper/session.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

declare const Swiper: any;
SwiperCore.use([Autoplay, Pagination, Navigation]);
@Component({
  selector: 'app-artist-home-detail',
  templateUrl: './artist-home-detail.component.html',
  styleUrls: ['./artist-home-detail.component.scss']
})
export class ArtistHomeDetailComponent implements OnInit {

  showDropdown: boolean = false;
  newArtist: any;
  subscriptionData: any;
  staffPicks: any;
  max = 5;
  rate = 0;
  isReadonly = true;
  eventForm: FormGroup;
  artistData: any;
  newArtistData: boolean;
  staffPickData: boolean;
  artistShowData: boolean;
  showStarIcon: boolean;
  previewCertificate: any;
  plan: any = 'basic';
  plan1: any = 'monthly'
  allSubscriptionPlanList: any;
  userStatus: any;
  selectedPlanName: any;
  userProfile: boolean = false;
  hireNow: boolean = false;
  tabName = 'exitingEvent';
  allSelected2 = false;
  selectedArtistId: any;
  pageNo: number = 0;
  pageSize: number = 12;
  existingEventList: any;
  currentPage = 0;
  userId: any;
  url = environment.hubspotNavigateUrl;
  takeYearlyPlan: boolean = false;
  takeQuarterlyPlan: boolean = false;
  takeMonthlyPlan: boolean = false;
  subscriptionsPlans: any;
  activatedplan: any = 'monthly';
  btnText: string = 'Choose Plan';
  constructor(
    private _MainService: MainService, private router: Router, private route: ActivatedRoute,
    private _FormBuilder: FormBuilder,
    private _NotifierService: NotifierService,
    private sessionStorage: SessionService,
  ) {
    this.showStarIcon = false;
    this.artistShowData = false;
    this.newArtistData = false;
    this.staffPickData = false;
    this.eventForm = this._FormBuilder.group({
      existingEvent: ['', [Validators.required]],
    });
  }
  @ViewChild('select2')
  select2!: MatSelect;

  ngOnInit(): void {
    this.userId = this.sessionStorage.getSession('userId');
    this.userStatus = this.sessionStorage.getSession('status');
    this.getAllNewArtist(this.pageNo, this.pageSize);
    this.getAllStaffPicks(this.pageNo, this.pageSize);
    this._MainService.getAllStripeCustomer().then((data) => {
      if (data && data.isSuccess) {
        this.subscriptionData = data.data;
        this.plan = this.subscriptionData.subscriptionPlan == null || this.subscriptionData.subscriptionStatus == 'PENDING' ? 'basic' : 'premium';
        this.activatedplan = this.subscriptionData.subscriptionPlan == null || this.subscriptionData.subscriptionStatus == 'PENDING' ? 'basic' : this.subscriptionData.subscriptionPlan;
        this.btnText = this.subscriptionData.subscriptionPlan == null || this.subscriptionData.subscriptionStatus == 'PENDING' ? 'Choose Plan' : 'Upgrade';
        this.selectedPlanName = this.subscriptionData.subscriptionPlan == null || this.subscriptionData.subscriptionStatus == 'PENDING' ? 'basic' : this.subscriptionData.subscriptionPlan;
        this.activatedplan = this.activatedplan == 'yearly' ? 'Annual' : this.activatedplan == 'quarterly' ? 'Quarterly' : 'monthly';

      }
    })
    this._MainService.getAllSubscriptionPlans().then((data) => {
      if (data && data.isSuccess)
        this.subscriptionsPlans = data.data
    })

  }

  vPause() {
    const myVideo: any = document.getElementById("myVideo");
    myVideo.pause();
  }

  getAllNewArtist(pageNo: number, pageSize: number) {
    this.newArtistData = true;
    this._MainService.getAllNewArtist(pageNo, pageSize).then((data) => {
      if (data && data.isSuccess) {
        this.newArtistData = false;
        this.newArtist = data.data.content;
      } else {
        this.newArtistData = false;
        this._NotifierService.showError(data.message);
      }
    })
  }

  getAllStaffPicks(pageNo: number, pageSize: number) {
    this.staffPickData = true;
    this._MainService.getAllStaffPicks().then((data) => {
      if (data && data.isSuccess) {
        this.staffPickData = false;
        this.staffPicks = data.data;
      } else {
        this.staffPickData = false;
        this._NotifierService.showError(data.message);
      }
    })
  }

  getArtistDetailsById(id: any) {
    this.userProfile = true
    this.artistShowData = true;
    this._MainService.getArtistDetailsById(id).then((data) => {
      if (data && data.isSuccess) {
        this.artistShowData = false;
        this.artistData = data.data;
        // this._NotifierService.showSuccess(data.message);
      } else {
        this.artistShowData = false;
        this._NotifierService.showError(data.message);
      }
    })
  }

  optionClick2() {
    let newStatus = true;
    this.select2.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected2 = newStatus;
  }

  favoriteArtist(id: any) {
    this._MainService.addOrRemoveFavouriteArtist(id).then((data) => {
      if (data && data.isSuccess) {
        this.showStarIcon = data.data;
        this.getAllNewArtist(this.pageNo, this.pageSize);
        this.getAllStaffPicks(this.pageNo, this.pageSize);
        this._NotifierService.showSuccess(data.message);
      } else {
        this._NotifierService.showError(data.message);
      }
    })
  }

  certicatedPreview(id: any) {
    // this.showImageModal = true;
    for (let certificate of this.artistData?.certificateDetails) {
      if (id === certificate.id) {
        this.previewCertificate = certificate.certificateUrl;
      }
    }
  }
  hireArtist() {
    let eventData = this.eventForm.controls['existingEvent'].value
    this.sessionStorage.setSessions({ selectedArtistId: this.selectedArtistId, });
    this.router.navigate(['ArtistDashboard/artistInvitation'], { queryParams: { type: 'pub', id: eventData.eventId, hired: true } });
  }
  createNewEvent() {
    this.sessionStorage.setSessions({ selectedArtistId: this.selectedArtistId, });
    this.router.navigate(['ArtistDashboard/event'], { queryParams: { createEvent: true, hired: true } });
  }
  getPlan(planType: any) {
    planType == 'yearly' ? this.takeYearlyPlan = true : planType == 'quarterly' ? this.takeQuarterlyPlan = true : this.takeMonthlyPlan = true
    if (this.subscriptionData.subscriptionPlan == null || this.subscriptionData.subscriptionStatus == 'PENDING' || this.subscriptionData.subscriptionPlan == undefined) {
      this._MainService.createSubscriptionSession(planType).then((data) => {
        if (data && data.isSuccess)
          window.location.href = data.data;
      }).then((res) => {
      })
    }
    else {
      this._MainService.createCustomerPortalSession().then((data) => {
        if (data && data.isSuccess)
          window.location.href = data.data;
      }).then((res) => {
      })
    }
  }
  report() {
    this.showDropdown = false;
    var _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
    _hsq.push(["identify", {
      type: 'report'
    }]);
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.src = "//js.hs-scripts.com/9406878.js";
    document.body.appendChild(chatScript);
    var _hsq = (<any>window)._hsq = (<any>window)._hsq || [];
    _hsq.push(["identify", {
      type: 'report'
    }]);
    _hsq.push(['trackPageView']);
  }
  shareProfile(id: any) {
    this._NotifierService.showSuccess('Link Copied')
    navigator.clipboard.writeText(this.url + '/userProfile?userId=' + id)
    this.showDropdown = false;
  }
  ngOnDestroy() {
    this.showDropdown = false;
  }

  sendMessage(id: any, name: any) {
    this.router.navigate([`/ArtistDashboard/message`], {
      queryParams: {
        'id': id,
        'name': name,
      }
    })
  }

  getExistingEventList() {
    this._MainService.getExistingEventList().then((data: any) => {
      if (data && data.isSuccess) {
        if (data.message == 'No records found') {
          let data = {
            eventName: 'No Records Found'
          }
          this.existingEventList.push(data)
        }
        else
          this.existingEventList = data.data;
      } else {
        this._NotifierService.showError(data.message);
      }
    })
  }

  // onScroll() {
  //   this.currentPage = this.currentPage + 1;
  //   this._MainService.getExistingEventList(this.currentPage,this.pageSize).then(data => {
  //     if (data && data.isSuccess) {
  //      this.existingEventList = [...this.existingEventList, ...data.data.content];
  //     }
  //   })
  // }
}
