<main style="overflow:hidden">
  <div class="container-fluid px-10 pt-3" style="padding-left: 25px">
    <div class="hgnb bot-m">
      <div class=" nvhi">
        <span>Home</span>
      </div>
    </div>
    <!-- New artist section -->
    <div class="mb-4 ughne">
      <div class="fyhq">
        <p class="huikdjy">New Artists</p>
        <p class="huikdjy ghu" [routerLink]="['/ArtistDashboard/artistPendingSeeAll']">See All</p>
      </div>
      <div class="for-slid ghb-wr">
        <ng-container *ngIf="newArtistData">
          <i class="fa fa-spinner fa-spin" aria-hidden="true" style="color: whitesmoke;"></i>
          <span style="color: white;">
            Loading...
          </span>
        </ng-container>
        <ng-container *ngIf="newArtist && newArtist.length">
          <swiper [slidesPerView]="5" [spaceBetween]="30" [navigation]="true" class="mySwiper">
            <ng-template swiperSlide *ngFor="let images of newArtist">
              <div class="sliderImage">
                <img [src]="images.avartarUrl ? images.avartarUrl: 'assets/artist/unsplash_4korPWFLBk0.png'" alt="">
                <div class="fhg-et">
                  <div class="yhguu">
                    <button type="button" class="btn hire-nw sm-pl">Hire Now!</button>
                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                    <i class="fa fa-angle-down rft" data-toggle="modal" data-target="#artist-home"
                      (click)="getArtistDetailsById(images.artistProfileId)"></i>
                  </div>
                  <h4>{{images.displayName}}</h4>
                  <p *ngFor="let geners of images.genres | slice:0:2; let i=index ">
                    <span>{{geners}} </span>
                  </p>
                  <div class="deft rating">
                    <!-- <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating> -->
                  </div>
                </div>
              </div>
            </ng-template>
          </swiper>
        </ng-container>
        <ng-container *ngIf="!newArtist?.length && !newArtistData">
          <div class="center-error">
            <div class="error-wrapper">
              <h1 class="text-center" style="color: white;">Oops</h1>
              <p class="text-center" style="color: white;">No New Artist Found</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- Staff Picks section -->
    <div class="mb-4 ughne">
      <div class="fyhq  ">
        <p class="huikdjy">Staff Picks</p>
        <p class="huikdjy ghu" [routerLink]="['/ArtistDashboard/artistPendingStaffSeeAll']">See All</p>
      </div>
      <div class="for-slid fegt">
        <ng-container *ngIf="staffPickData">
          <i class="fa fa-spinner fa-spin" aria-hidden="true" style="color: whitesmoke;"></i>
          <span style="color: white;"> Loading... </span>
        </ng-container>
        <ng-container *ngIf="staffPicks && staffPicks.length">
          <swiper [slidesPerView]="5" [spaceBetween]="30" [navigation]="true" class="mySwiper">
            <ng-template swiperSlide *ngFor="let images of staffPicks">
              <div class="sliderImage">
                <img [src]="images.avartarUrl ? images.avartarUrl: 'assets/artist/unsplash_4korPWFLBk0.png'" alt="">
                <div class="fhg-et">
                  <div class="yhguu">
                    <button type="button" class="btn hire-nw sm-pl">Hire Now!</button>
                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                    <i class="fa fa-angle-down rft" data-toggle="modal" data-target="#artist-home"
                      (click)="getArtistDetailsById(images.artistProfileId)"></i>
                  </div>
                  <h4>{{images.displayName}}</h4>
                  <p *ngFor="let geners of images.genres | slice:0:2; let i=index ">
                    <span>{{geners}} </span>
                  </p>
                  <div class="deft rating">
                    <!-- <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating> -->
                  </div>
                </div>
              </div>
            </ng-template>
          </swiper>
        </ng-container>
        <ng-container *ngIf="!staffPicks?.length && !staffPickData">
          <div class="center-error">
            <div class="error-wrapper">
              <h1 class="text-center" style="color: white;">Oops</h1>
              <p class="text-center" style="color: white;">No Staff Picks Found</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</main>
<!-- Artist Home page modal section -->
<div class="modal fade drfwt" data-backdrop="false" id="artist-home" tabindex="-1" role="dialog"
  aria-labelledby="artist-homeTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" style="overflow-y: initial !important">
    <div class="modal-content bg-dgf" *ngIf="artistData">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="max-height: calc(100vh - 200px); overflow-y: auto;">
        <div class="jmki">
          <div class="profile-img-wrapper">
            <img src="assets/artist/ckr.svg" class="edaqqer" data-toggle="modal" data-target=".statuss">
            <img [src]="artistData?.profilePicUrl ? artistData?.profilePicUrl : 'assets/artist/Rectangle 30 (1).png'"
              onerror="this.src='assets/artist/Rectangle 30 (1).png'"
              [ngStyle]="{'object-fit': artistData?.profilePicUrl ? 'fill' : 'cover' }" alt="" class="img-fluid">
          </div>
          <div class="dchy">
            <div>
              <h2>{{artistData?.displayName}}</h2> <i class="fa fa-heart-o fkui" aria-hidden="true"></i>
            </div>
            <span class="ujy" *ngFor="let country of artistData?.locations">
              {{country}}
            </span>
            <h4>${{artistData?.feeRangeFrom}} - ${{artistData?.feeRangeTo}} per hour</h4>
            <p class="ure">{{artistData?.eventCount}} events<span *ngFor="let band of artistData?.bandSizes"> | {{band}}
              </span></p>
            <div class="deft mb-3 rating">
              <!-- <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating> -->
            </div>
            <button type="button" class="btn hire-nw">Hire Now!</button>
            <button type="button" class="btn sn-mg"
              (click)="sendMessage(artistData?.userId,artistData?.displayName)">Send A Message</button>
          </div>
        </div>
        <div class="jguht">
          <div class="mb-4 hgaa">
            <h4>My Career in Summary</h4>
            <p>{{artistData?.careerSummary}}</p>
          </div>
          <div class="mb-4 hgaa">
            <h4>My First Musical Memory</h4>
            <p>{{artistData?.musicalMemory}}</p>
          </div>
          <div class="mb-4 hgaa">
            <h4>What I Love About Music</h4>
            <p>{{artistData?.loveAboutMusic}}</p>
          </div>
          <div class="row ">
            <div class="col-lg-6">
              <div class="mb-4 hgaa">
                <h4>Years of Experience</h4>
                <p>2 Years</p>
              </div>
              <div class="mb-4 hgaa">
                <h4>Act / Band Size</h4>
                <p>3 Members</p>
              </div>
              <div class="mb-4 hgaa">
                <h4>Onsite</h4>
                <p>Onsite1 - Basic Mics and Amplifiers</p>
              </div>
              <div class="mb-4 hgaa">
                <h4>Video Setup</h4>
                <p>Three Cameras</p>
              </div>
              <div class="mb-4 hgaa">
                <h4>Video Quality</h4>
                <p>4k</p>
              </div>
              <div class="mb-4 hgaa">
                <h4>Audio Setup</h4>
                <p>Pro Studio</p>
              </div>
              <div class="mb-4 hgaa">
                <h4>Performance Environment</h4>
                <p>Home Setting</p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="mb-4 hgaa">
                <h4>Favorite Genres</h4>
                <div *ngFor="let env of artistData?.favouriteGenres">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
              <div class="mb-4 hgaa">
                <h4>Instruments Played</h4>
                <div *ngFor="let env of artistData?.instrumentsPlayed">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
              <div class="mb-4 hgaa">
                <h4>Most Influential Artists</h4>
                <p>{{artistData?.influentialArtists}}</p>
              </div>
              <div class="mb-4 hgaa">
                <h4>Education</h4>
                <div *ngFor="let env of artistData?.educations">
                  <p style="display: inline-block !important;">{{env}}</p>
                </div>
              </div>
              <div class="mb-4 hgaa">
                <h4>Public Videos Links</h4>
                <div *ngFor="let env of artistData?.publicVideosLinks">
                  <a [href]="env" target="_blank">
                    <p style="display: inline-block !important;">{{env}}</p>
                  </a>
                </div>

              </div>
              <div class="mb-4 hgaa">
                <h4>Social Videos Links</h4>
                <div *ngFor="let env of artistData?.socialVideosLinks">
                  <a [href]="env" target="_blank">
                    <p style="display: inline-block !important;">{{env}}</p>
                  </a>
                </div>

              </div>
            </div>
          </div>
          <div class="row sawqde">
            <div class="col-lg-4">
              <div class="mb-4 hgaa">
                <h4>Certifications</h4>
                <h4>Music Production(123456)</h4>
                <p>Berklee Online</p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="mb-4 hgaa">
                <p>Berklee Online</p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="mb-4 hgaa gftj">
                <p><img src="assets/dashboard/swd.png" class="">View Certificate</p>
              </div>
            </div>
          </div>
          <div class="mb-5 fercc">
            <h4 class="imnsde"> Images </h4>
          </div>
          <div class="mb-5 fercc">
            <h4 class="imnsde"> Videos </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
