import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'src/app/service/helper/notifier.service';
import { SessionService } from 'src/app/service/helper/session.service';
import { MainService } from 'src/app/service/main.service';

@Component({
  selector: 'app-earning',
  templateUrl: './earning.component.html',
  styleUrls: ['./earning.component.scss']
})
export class EarningComponent implements OnInit {

  userStatus: any;
  selectedPlanName: any;
  profilePicData: any;
  generalDetails: any;
  showDropdown: boolean = false;
  changeAccount: boolean = false;
  DeleteAccount: boolean = false;
  viewAccountDetail: boolean = false;
  viewEarnings: boolean = false;
  changeAccountProcessing: boolean = false;
  getSubscriptionPlan: boolean = false;
  viewTransactionList: boolean = false;
  transactionList: any;
  tabName = 'viewGenralDetail';
  accountId: any;
  availableBalance: any;
  // accountDetails: any;
  constructor(private _MainService: MainService, private sessionStorage: SessionService,
    private notifier: NotifierService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    let addAccount = this.route.snapshot.queryParams.addAccount;
    let refresh = this.route.snapshot.queryParams.refresh;
    if (addAccount == 'true') {
      let id = this.sessionStorage.getSession('accountId');
      this._MainService.updateCustomer(id).then((data) => {
        if (data && data.isSuccess) {
          this.notifier.showSuccess(data.message)
          this.getUserPayoutDetail();
        }
        else
          this.notifier.showError(data.message)
        this.sessionStorage.removeSession('accountId')
        this.router.navigate(['/ArtistDashboard/earnings'])
      })
    }
    if (refresh == 'true') {
      this.getAccountId();
    }
    this.userStatus = this.sessionStorage.getSession('status');
    this.getUserPayoutDetail();
  }
  getUserPayoutDetail() {
    let userId = localStorage.getItem('userId');
    this._MainService.getUserPayoutDetail(userId).then((data: any) => {
      if (data && data.isSuccess) {
        this.profilePicData = data.data;
      } else
        this.notifier.showError(data.message);
    });
    this._MainService.getBalanceOfStripeAccount().then((data: any) => {
      if (data && data.isSuccess) {
        this.availableBalance = data.data;
      } else
        this.notifier.showError(data.message);
    });
  }
  getEarningDashboardLink() {
    if (this.profilePicData?.accountId === null || this.profilePicData?.accountId === undefined) {
      this.notifier.showSuccess('Please First Add Your Payout Account');
    }
    else {
      this.viewEarnings = true;
      this._MainService.getEarningDashboardLink().then((data) => {
        if (data && data.isSuccess) {
          window.open(data.data, '_blank');
          // window.location.href = data.data;
        }
        this.viewEarnings = false;
      }).then((res) => {
      })
    }
  }
  getAccountId() {
    this.changeAccountProcessing = true;
    let userId = this.sessionStorage.getSession('userId');
    this._MainService.getAccountId(userId).then((data) => {
      if (data && data.isSuccess) {
        this.accountId = data.data
        this.sessionStorage.setSessions({ 'accountId': this.accountId });
        this.addAccount(this.accountId)
      }
    }).then((res) => {
    })
  }
  addAccount(id: any) {
    this._MainService.getAccountLink(id).then((data) => {
      if (data && data.isSuccess) {
        window.location.href = data.data;
      }
      else {
        this.notifier.showError('data.message')
      }
    }).then((res) => {
    })
  }
  getTransactionDetail() {
    this.tabName = 'viewTransactionList'
    this._MainService.getTransactionDetail().then((data) => {
      if (data && data.isSuccess)
        this.transactionList = data.data;
    }).then((res) => {
    })
  }
}
