import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SendCodeComponent } from '../send-code/send-code.component';
import { MainService } from 'src/app/service/main.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from '../../../service/helper/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import SwiperCore, { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";

declare const Swiper: any;
SwiperCore.use([Autoplay, Pagination, Navigation]);
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgetPasswordForm: FormGroup;
  showVerificationModal: boolean;
  otpFormVerification: FormGroup;
  regenerateLink: boolean = false;
  path: any = this.route.queryParams;

  constructor(
    private dialog: MatDialog,
    private _mainService: MainService,
    private _FormBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _NotifierService: NotifierService,
    private router: Router,
  ) {
    this.showVerificationModal = false;
    this.forgetPasswordForm = this._FormBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]+[a-zA-Z0-9._]+@[a-zA-Z]+\.[a-zA-Z.]{2,5}$/)]],
    });
    this.otpFormVerification = this._FormBuilder.group({
      email: ["", Validators.required],
      otp: [null, [Validators.required, Validators.minLength(3)]],
    })
  }
  ngOnInit(): void {
    if (this.path['_value'].type == 'regenerateEmail') {
      this.regenerateLink = true;
    }
  }


  forgotPwd() {
    if (this.regenerateLink == true) {
      if (!this.forgetPasswordForm.value.email) {
        this._NotifierService.showError("Please enter email")
      } else if (this.forgetPasswordForm.valid) {
        this._mainService.regenerateLink(this.forgetPasswordForm.value.email).then((data) => {
          if (data && data.isSuccess) {
            this._NotifierService.showSuccess(data.message)
            this.forgetPasswordForm.reset();
          } else
            this.forgetPasswordForm.reset();
          this._NotifierService.showError(data.message);
        })
      }
    }
    else if (this.regenerateLink == false) {
      if (!this.forgetPasswordForm.value.email) {
        this._NotifierService.showError("Please enter email")
      } else if (this.forgetPasswordForm.valid) {
        this._mainService.forgotPassword(this.forgetPasswordForm.value.email).then((data) => {
          this._mainService.localEmail = this.forgetPasswordForm.value.email;
          if (data && data.isSuccess) {
            this.showVerificationModal = true;
            this._NotifierService.showSuccess(data.message)
          } else
            this._NotifierService.showError(data.message);
        })
      }
    }
  }

  verifyOtp() {
    this.otpFormVerification.controls.email.setValue(this.forgetPasswordForm.value.email);
    this.otpFormVerification.controls.otp.setValue(this.otpFormVerification.value.otp);
    if (this.otpFormVerification.valid) {
      this._mainService.verifyOtp(this.otpFormVerification.value).then(data => {
        this._mainService.localToken = data.data;
        if (data && data.isSuccess) {
          this._NotifierService.showSuccess(data.message);
          this.router.navigate(['reset-password']);
        } else {
          this._NotifierService.showError(data.message);
        }
      })
    } else {
      this._NotifierService.showError("Please Enter Code")
    }
  }

}
