import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/service/helper/session.service';
import { MainService } from 'src/app/service/main.service';

@Component({
  selector: 'app-submit-proposal',
  template: '',
})
export class SubmitProposalComponent implements OnInit {

  eventData: any = this.router.queryParams;
  eventUserDetail: any;
  email: any;

  constructor(private router: ActivatedRoute, private route: Router, private mainService: MainService,
    private session: SessionService) { }

  ngOnInit(): void {
    this.eventUserDetail = this.eventData['_value']
    this.email = this.session.getSession('email')
    var role = this.session.getSession('roles')
    if (this.email) {
      if (this.email == this.eventUserDetail.email) {
        if (role == 'ARTIST') {
          this.route.navigate(['/ArtistDashboard/gigBoard'], { queryParams: { eventId: this.eventUserDetail.eventId, type: 'submitProposal'} })
        }
        // else if (role == 'AUDIENCE') {
        //   this.route.navigate(['/audienceDashboard/eventPreview'], { queryParams: { eventId: this.eventUserDetail.eventId, proposalId: this.eventUserDetail.proposalId } })
        // }
      }
      else if (this.email != this.eventUserDetail.email) {
        this.route.navigate(['/login'], { queryParams: { eventId: this.eventUserDetail.eventId, email: this.eventUserDetail.email, type: 'submitProposal'} })
      }
    }
    else {
      this.route.navigate(['/login'], { queryParams:  { eventId: this.eventUserDetail.eventId, email: this.eventUserDetail.email, type: 'submitProposal'} })
    }
  }

}
