<div class="sendpage">
    <div class="">
        <div class="joinbody">
            <h3 class="required">Enter Verification Code</h3>
            <label class="required"><b>Verification Code</b></label><br>
            <input type="text" placeholder="Enter Code"><br>
            <h2>Don't get the code?<span style="color: #FF6720;">&nbsp;Resend Code</span></h2>
        </div>
        <button class="button" (click)="openDialog()" routerLink="reset-password" >Verify</button>

    </div>
</div>