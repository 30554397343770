export const environment = {
  production: false,
  baseUrl: 'https://backend.dev.virtuosica.com/api/v1/',
  hubspotUrl: 'https://api.hsforms.com/submissions/v3/integration/submit',
  hubspotNavigateUrl : 'https://dev.virtuosica.com',
  websocketURL:'wss://antmedia.virtuosica.com:5443/WebRTCAppEE/websocket',
  watchVideoLink: 'https://antmedia.virtuosica.com:5443/WebRTCAppEE/play.html?name=',
  stripePublicKey:"pk_test_51KDtpiBMMMDBCW5nl6z4cJCjt85LuRcC7rwaEEO8JEvDQsIP2R92lBqnT0jDWNvdiLR7P9jLY9mZB6EMTdraKaML00D0ZEo776",
  restrictedUrl:{
    refreshTokenUrl:  'https://backend.dev.virtuosica.com/api/v1/user/refreshToken',
   },
   firebaseConfig : {
    apiKey: "AIzaSyAdJmLFJzuNBnIDjyLWk-NcnqaBCPq2GVg",
    authDomain: "virtuosica-c7f2d.firebaseapp.com",
    projectId: "virtuosica-c7f2d",
    storageBucket: "virtuosica-c7f2d.appspot.com",
    messagingSenderId: "93318815299",
    appId: "1:93318815299:web:2834b7872f54d576cc3ef6",
    measurementId: "G-XRPJLLQPNJ"
   },
};


