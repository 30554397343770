<div class="joinpage">
    <div class="joinhead">
      <img src="assets/logo.png">
    </div>

    <div class="joinbody">
      <div class="row joinb">
        <div class="col-md-7">
          <div class="joinleft">
            <div class="card">
              <img class="img" src="assets/slide1.png">
            </div>
          </div>
        </div>

        <div class="col-md-5">
          <div class="f1">
            <img src="assets/v1.png">
          </div>
          <h1>Oops! There might be some error.
          </h1>
          <h3>Please signup again.</h3>
          <button type="button" class="button" routerLink="/">Sign Up</button>
        </div>
      </div>
      <app-footer></app-footer>
      <!-- <div class="joinfoot">
        <div class="row joinf">
          <h2 style="margin-right: 50px;">Privacy Policy</h2>
          <h2 style="margin-right: 50px;">Terms and Conditions</h2>
          <h2>Need Help?</h2>
        </div> -->
      </div>
    </div>
