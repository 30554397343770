<div class="hgnb ml-4">
  <div class=" nvhi">
    <img src="assets/dashboard/arr.svg">
    <img src="assets/dashboard/Mask Group.png" class="pro-fil">
    <span>My Profile</span>
  </div>
  <div class="erfg">
    <button class="eijh" (click)="UpdateDetails()">Save</button>
    <button class="can-cl" [routerLink]="['/ArtistDashboard/myProfile']" (click)="showDragDrop = false">Cancel</button>
  </div>
</div>
<div class="mt-4 ml-3">
  <div class="khiu card ">
    <div class="card-header">
      <div class="card-header">
        <div class="tabbable-responsive">
          <div class="tabbable">
            <ul class="nav nav-tabs pl-0" id="myTab" role="tablist">
              <li class="nav-item" (click)="toggleRequest('GENERAL')">
                <a class="nav-link active" id="first-tab" data-toggle="tab" href="#first" role="tab"
                  aria-controls="first" aria-selected="true" [ngClass]="{'active': requestType === 'GENERAL'}"
                  (click)="showDragDrop = false">General</a>
              </li>
              <li class="nav-item" (click)="toggleRequest('ESSENTIALS')">
                <a class="nav-link" id="second-tab" data-toggle="tab" href="#second" role="tab" aria-controls="second"
                  aria-selected="false" [ngClass]="{'active': requestType === 'ESSENTIALS'}"
                  (click)="showDragDrop = false">Essentials</a>
              </li>
              <li class="nav-item" (click)="toggleRequest('BACKGROUND')">
                <a class="nav-link" id="third-tab" data-toggle="tab" href="#third" role="tab" aria-controls="third"
                  aria-selected="false" [ngClass]="{'active': requestType === 'BACKGROUND'}"
                  (click)="showDragDrop = false">Background</a>
              </li>
              <li class="nav-item" (click)="toggleRequest('PRODUCTION')">
                <a class="nav-link" id="fourth-tab" data-toggle="tab" href="#fourth" role="tab" aria-controls="fourth"
                  aria-selected="false" [ngClass]="{'active': requestType === 'PRODUCTION'}"
                  (click)="showDragDrop = false">Production
                  Capabilities</a>
              </li>
              <li class="nav-item" (click)="toggleRequest('MEDIA')">
                <a class="nav-link" id="fifth-tab" data-toggle="tab" href="#fifth" role="tab" aria-controls="fifth"
                  aria-selected="false" [ngClass]="{'active': requestType === 'MEDIA'}"
                  (click)="showDragDrop = false">Media</a>
              </li>
              <li class="nav-item" (click)="toggleRequest('SETTINGS')">
                <a class="nav-link" id="sixth-tab" data-toggle="tab" href="#sixth" role="tab" aria-controls="sixth"
                  aria-selected="false" [ngClass]="{'active': requestType === 'SETTINGS'}"
                  (click)="showDragDrop = false">Settings</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="tab-content">
          <div class="row card-body lopk">
            <div class="col-lg-4">
              <div class="ckij">
                <div *ngIf="showDragDrop">
                  <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="16 / 9" [resizeToWidth]="256"
                    [cropperMinWidth]="149" [onlyScaleDown]="true" [roundCropper]="false"
                    [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
                    [style.display]="showCropper ? null : 'none'" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                    (loadImageFailed)="loadImageFailed()"></image-cropper>
                </div>
                <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'"
                  *ngIf="showDragDrop" style="margin-right:127px" />
                <div class="edit-profile-option2">
                  <button type="submit" class="eijh2" style="cursor: pointer;" *ngIf="showDragDrop"
                    (click)="cropDone()">Submit</button>
                  <button type="submit" class="btn hftrdd" style="cursor: pointer;" *ngIf="showDragDrop"
                    (click)="showDragDrop = false">Cancel</button>
                </div>
                <div *ngIf="imageSpinnerDone" class="spinner">
                  <sa-spinner-dotted [size]="59" [thickness]="103" [speed]="111" color="#dd0031"
                    secondaryColor="rgba(24, 24, 24, 0.1)"></sa-spinner-dotted>
                </div>
                <div style="background: #11111100; margin-left: 65px; margin-top: 40px; background-size: 100% 100%; height: 125px;width: 125px;">
                  <img [src]="profilePicData?.avatarUrl ? profilePicData?.avatarUrl: 'assets/dashboard/Ellipse 2.png'"
                  *ngIf="!showDragDrop && !imageSpinnerDone"width="100%" height="100%" style="border: 1px solid red; padding: 4px; border-radius: 50%; object-fit: cover;">
                  </div>
                <div class="edit-profile-option" *ngIf="!showDragDrop">
                  <i class="fa fa-pencil" aria-hidden="true" (click)="file.click()"></i>
                </div>
                <input type="file" accept="image/*" #file (change)="onFileSelect($event)" style="display: none;">
                <div></div>
                <h4>{{profilePicData?.fullName}}</h4>
                <p>{{profilePicData?.email}}</p>
                <p>{{profilePicData?.location}}</p>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="active" *ngIf="requestType === 'GENERAL'">
                <div class="hgbpp">
                  <form [formGroup]="editGeneralForm">
                    <table class="ithy">
                      <tr>
                        <td>Full Name</td>
                        <td class="f14">
                          <div class="namo">{{generalDetails?.fullName}}
                            <p> <img src="assets/dashboard/war.svg">To change your name you should contact
                              support@virtuosica.com</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td class="f14">
                          <div class="namo">{{generalDetails?.email}}
                            <p> <img src="assets/dashboard/war.svg">To change your email you should contact
                              support@virtuosica.com</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Display Name </td>
                        <td class="f14">
                          <div>
                            <input type="text" formControlName="displayName" class="edi-in">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Years of Experience</td>
                        <td class="f14">
                          <div>
                            <input type="text" formControlName="experience" class="edi-in">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <td>
                          <div class="lo-cn">
                            <div class="location">
                              <mat-form-field class="ge-fk" class="example-chip-list genersList">
                                <mat-chip-list #locationList>
                                  <mat-chip *ngFor="let address of location" (removed)="remove(address)">
                                    {{address}}
                                    <button matChipRemove>
                                      <mat-icon>cancel</mat-icon>
                                    </button>
                                  </mat-chip>
                                  <input style="color: white;" placeholder="&nbsp;" #fruitInput
                                    [formControl]="locationCtrl" [matChipInputFor]="locationList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" ngx-google-places-autocomplete
                                    #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)">
                                </mat-chip-list>
                              </mat-form-field>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Time Zone</td>
                        <td class="f14">
                          <div class="location  fo-br">
                            <mat-form-field class="ge-fk" class="example-chip-list" appearance="fill">
                              <mat-chip-list #chipListTimeZone>
                                <mat-chip *ngFor="let timeZone of searchTimeZone" (removed)="removeTimeZone(timeZone)">
                                  {{timeZone}}
                                  <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                  </button>
                                </mat-chip>
                                <input
                                  style="border: none; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                                  #timeZoneInput [formControl]="timeZoneCtrl" [matAutocomplete]="auto"
                                  [matChipInputFor]="chipListTimeZone"
                                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                  (matChipInputTokenEnd)="addgen($event)">
                              </mat-chip-list>
                              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTimeZone($event)">
                                <mat-option *ngFor="let time of timeZoneArray" [value]="time"> {{time}} </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Certifications</td>
                        <td class="f14">
                          <div class="dhcn">
                            <div class="yth klm" *ngFor="let certificate of CertificatedDetails">
                              <div class="dis iykg">
                                <p class="wse">{{certificate?.certificateName}} </p>
                                <p class="ber"> {{certificate?.issuingOrganization}} </p>
                              </div>
                              <div class="dis fl-ri">
                                <p>{{certificate?.yearObtainedFrom}} - {{certificate?.yearObtainedTo}}</p>
                              </div>
                              <div>
                                <p class="dis f12 hfq"> <img src="assets/dashboard/swd.png"><span
                                    (click)="certicatedPreview(certificate.id)" data-toggle="modal"
                                    data-target="#examplesModal" style="cursor: pointer;">View Certificate</span></p>
                                <p class="dis fl-ri" style="cursor: pointer;"
                                  (click)="removeCertificateDetails(certificate)"> Remove</p>
                                <p (click)="showUploaModaledit(certificate)" class="dis fl-ri mr-2"
                                  style="cursor: pointer;">Edit </p>
                              </div>
                            </div>
                            <a class="cmvk" (click)="showCertificateModal = true;" style="cursor: pointer;">+ Add
                              More</a>
                          </div>
                        </td>
                        <td class="f14">
                          <div></div>
                        </td>
                      </tr>
                      <tr>
                        <td>Education</td>
                        <td class="f14">
                          <div class="edu">
                            <mat-form-field class="ge-fk" appearance="fill">
                              <mat-select (selectionChange)="onroleChange($event)" formControlName="education" multiple>
                                <mat-option *ngFor="let education of educationList" [value]="education">{{education}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </form>
                </div>
              </div>

              <div *ngIf="requestType === 'ESSENTIALS'">
                <form [formGroup]="editEssentailForm">
                  <div class="hgbpp">
                    <div class="form-group rgtaa">
                      <div class="row">
                        <div class="col-md-4">
                          <div><label class="text-white">Instruments Played</label></div>
                        </div>
                        <div class="col-md-8 lo-cn">
                          <div class="loc instGen">
                            <mat-form-field class=" py-0 example-chip-list gen ge-fk" appearance="outline">
                              <mat-chip-list #chipListins aria-label="Country selection">
                                <mat-chip *ngFor="let instrument of searchInstuments" [selectable]="selectable"
                                  [removable]="removable" (removed)="removeIns(instrument)">
                                  {{instrument}}
                                  <button matChipRemove *ngIf="removable">
                                    <mat-icon>cancel</mat-icon>
                                  </button>
                                </mat-chip>
                                <input
                                  style="border: none; color: white; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                                  #devicesInput [formControl]="instumentCtrl" [matAutocomplete]="autoins"
                                  [matChipInputFor]="chipListins"
                                  [matChipInputSeparatorKeyCodes]='separatorKeysCodesins'
                                  (matChipInputTokenEnd)="addIns($event)">
                              </mat-chip-list>
                              <mat-autocomplete #autoins="matAutocomplete" (optionSelected)="selectedIns($event)">
                                <mat-option *ngFor="let instruments of instrumentList" [value]="instruments">
                                  {{instruments}} </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <img src="assets/dashboard/war.svg"><small class="pl-2"
                              style="color: white; font-size: 10px;">Start typing to select your instruments</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group rgtaa">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="text-white">Genres Played</label>
                        </div>
                        <div class="col-md-8">
                          <div class="lo-cn">
                            <div class="loc instGen">
                              <mat-form-field class="py-0 example-chip-list gen ge-fk" appearance="outline">
                                <mat-chip-list #chipListgen>
                                  <mat-chip *ngFor="let genres of searchgen" [selectable]="selectablegen"
                                    [removable]="removablegen" (removed)="removegen(genres)"> {{genres}}
                                    <button matChipRemove *ngIf="removablegen">
                                      <mat-icon>cancel</mat-icon>
                                    </button>
                                  </mat-chip>
                                  <input
                                    style="border: none; color: white; background: none; padding: 0px; margin: 0px; overflow: scroll; height: 40px;"
                                    #genInput [formControl]="genCtrl" [matAutocomplete]="autogen"
                                    [matChipInputFor]="chipListgen"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodesgen"
                                    (matChipInputTokenEnd)="addgen($event)">
                                </mat-chip-list>
                                <mat-autocomplete #autogen="matAutocomplete" (optionSelected)="selectedgen($event)">
                                  <mat-option *ngFor="let gen of genList" [value]="gen">{{gen}}</mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                              <img src="assets/dashboard/war.svg"><small class="pl-2"
                                style="color: white; font-size: 10px;">Start typing to select your genres</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <table class="ithy">
                      <tr>
                        <td>Act / Band Size</td>
                        <td class="f14">
                          <div class="edu fo-br">
                            <mat-form-field class="ge-fk" appearance="fill">
                              <mat-select (selectionChange)="onbandChange($event)" formControlName='bandSizes' multiple>
                                <mat-option *ngFor="let bandsize of bandSize" [value]="bandsize">{{bandsize}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Notable musicians you've worked with</td>
                        <td>
                          <div class="lo-cn">
                            <div class="">
                              <mat-form-field class="ch-pi example-chip-list ge-fk" appearance="fill">
                                <mat-chip-list #chipList>
                                  <mat-chip *ngFor="let fruit of fruits" [selectable]="selectablegen"
                                    [removable]="removableNotable" (removed)="removenotable(fruit)">
                                    {{fruit}}
                                    <mat-icon matChipRemove *ngIf="removableNotable">close</mat-icon>
                                  </mat-chip>
                                  <input #fruitInput [formControl]="fruitCtrl" [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                                </mat-chip-list>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                  <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit"> {{fruit}}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Fee Range $</td>
                        <td class="f14">
                          <div class="edu fo-br">
                            <div class="form-group">
                              <div class="row">
                                <div class="col-5 ">
                                  <label for="for" class="lbf arrow">From</label>
                                  <input type="text" formControlName="feeRangeFrom" class="ge-fk">
                                </div>
                                <div class="col-5 p_d">
                                  <label for="to" class="lbf">To</label>
                                  <input type="text" class="ge-fk" formControlName="feeRangeTo">
                                </div>
                                <div class="col-2 fee-hr-wrapper">
                                  <p class="fee-price-hr">/hr</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </form>
              </div>

              <div *ngIf="requestType === 'BACKGROUND'">
                <div class="hgbpp">
                  <form [formGroup]="editBackgroundForm">
                    <table class="ithy">
                      <tr>
                        <td class="wi-tf">My Career In Summary</td>
                        <td class="f14">
                          <div class="namo">
                            <textarea class="txtarea rfgt" rows="4" name="Text" formControlName="careerSummary"
                              Id="Text"></textarea>
                            <p> <img src="assets/dashboard/war.svg">This is displayed as your bio on your profile</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Most Influential Artists</td>
                        <td class="f14">
                          <div class="">
                            <input type="text" formControlName="influentialArtists" class="edi-in ">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Albums Credits</td>
                        <td class="f14">
                          <div class="">
                            <input type="text" formControlName="albumCredits" class="edi-in ">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>My First Musical Memory</td>
                        <td class="f14">
                          <div class="namo">
                            <textarea class="txtarea rfgt" formControlName="firstMusicalMemory" rows="4" name="Text"
                              Id="Text" value=""> </textarea>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>What I Love About Music</td>
                        <td class="f14">
                          <div class="namo">
                            <textarea class="txtarea rfgt" formControlName="loveAboutMusic" rows="4" name="Text"
                              Id="Text" value=""> </textarea>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </form>
                </div>
              </div>

              <div *ngIf="requestType === 'PRODUCTION'">
                <div class="hgbpp">
                  <form [formGroup]="editProductionForm">
                    <table class="ithy">
                      <tr>
                        <td>Onsite</td>
                        <td class="f14">
                          <div class="edu fo-br">
                            <mat-form-field class="ge-fk" appearance="fill" style="height: 50px;">
                              <mat-select (selectionChange)="onsiteChange($event)" formControlName='onsiteDetails'
                                multiple>
                                <mat-option *ngFor="let onsitelist of onSiteList" [value]="onsitelist">{{onsitelist}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Video Setup</td>
                        <td class="f14">
                          <div class="edu fo-br">
                            <mat-form-field class="ge-fk" appearance="fill" style="height: 50px;">
                              <mat-select (selectionChange)="onvideoSetupChange($event)"
                                formControlName='videoSetupDetails' multiple>
                                <mat-option *ngFor="let videosetup of videosetList" [value]="videosetup">{{videosetup}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Video Quality</td>
                        <td class="f14">
                          <div class="edu fo-br">
                            <mat-form-field class="ge-fk" appearance="fill" style="height: 50px;">
                              <mat-select (selectionChange)="onvideoQualityChange($event)"
                                formControlName='videoQualityDetails' multiple>
                                <mat-option *ngFor="let videoQuality of videoQualityList" [value]="videoQuality">
                                  {{videoQuality}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Audio Setup</td>
                        <td class="f14">
                          <div class="edu fo-br">
                            <mat-form-field class="ge-fk" appearance="fill" style="height: 50px;">
                              <mat-select (selectionChange)="onaudioSetupChange($event)"
                                formControlName='audioSetupDetails' multiple>
                                <mat-option *ngFor="let audiosetup of audioSetupList" [value]="audiosetup">
                                  {{audiosetup}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Performance Environment</td>
                        <td class="f14">
                          <div class="edu fo-br">
                            <mat-form-field class="ge-fk" appearance="fill"
                              style="height: 50px;vertical-align: baseline;">
                              <mat-select formControlName='performanceEnvironmentDetails' multiple>
                                <mat-option *ngFor="let environmentList of PerformanceEnvironmentList"
                                  [value]="environmentList">{{environmentList}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </form>
                </div>
              </div>

              <div *ngIf="requestType === 'MEDIA'">
                <div class="hgbpp">
                  <form [formGroup]="mediaForm">
                    <table class="ithy">
                      <tr>
                        <td>Upload Images</td>
                        <td class="f14">
                          <ng-container *ngFor="let image of mediaDetails?.imageDetails">
                            <div class="dfgrq">
                              <div class="jkmne">
                                <img [src]="image.imageUrl" width="60px" height="60px">
                                <span style="overflow: hidden;text-overflow: ellipsis; width: 50px;"
                                  class="pl-2">{{image.imageUrl |
                                  slice:51:100}}</span>
                              </div>
                              <div class="swde">
                                <p (click)="imagePreview(image.id)" style="cursor: pointer;">View</p>
                                <p style="cursor: pointer;" (click)="imageRemove(image.id)">
                                  Remove</p>
                              </div>
                            </div>
                          </ng-container>
                          <a class="cmvk" (click)="showUploaImageModal = true;" style="cursor: pointer;">+ Add More</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Upload Videos</td>
                        <td class="f14">
                          <ng-container *ngFor="let media of mediaDetails?.videoDetails">
                            <div class="dfgrq">
                              <div class="jkmne">
                                <div class="row" style="display: inline-flex;">
                                  <div class="col-md-6">
                                    <video width="122" height="60" [src]="media.videoUrl" type="video/mp4" controls
                                      style="margin-top: 16px;"></video>
                                  </div>
                                  <div class="col-md-6">
                                    <p class="pl-2" style="margin-top: 10px;">Video Title : {{media.videoTitle}}</p>
                                    <p style="color: rgb(59, 126, 214);" class="pl-2">#{{media.videoTags[0]}}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="swde">
                                <p (click)="videoPreview(media.id)" style="cursor: pointer;">View</p>
                                <p style="cursor: pointer;" (click)="videoRemove(media.id)">Remove</p>
                                <br>
                                <p style="color: orangered; cursor: pointer;"
                                  (click)="addMusicTagVideoPreview(media.id)" data-toggle="modal"
                                  data-target=".addMusicTag-modal" data-backdrop="static" data-keyboard="false"
                                  data-backdrop="false">Add Music Tag</p>
                              </div>
                            </div>
                          </ng-container>
                          <a class="cmvk" style="cursor: pointer;" data-toggle="modal" data-target=".video-modal"
                            data-backdrop="static" data-keyboard="false" data-backdrop="false">+ Add More</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Links to Public Videos</td>
                        <td class="f14">
                          <div>
                            <input type="text" formControlName="publicVideos" class="edi-in fro-bo">
                            <span style="color: white;" (click)="socialMedia = true">
                              <a class="cmvk" (click)="socialMedia = true;" style="cursor: pointer;">+ Add More</a>
                            </span>
                            <small class="text-danger"
                              *ngIf="mediaForm.controls.publicVideos.invalid && mediaForm.controls.publicVideos.touched">
                              <span *ngIf="mediaForm.controls['publicVideos'].hasError('pattern')">Please enter valid
                                url</span>
                            </small>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Links to Social Videos</td>
                        <td class="f14">
                          <div>
                            <input type="text" formControlName="socialVideos" class="edi-in fro-bo">
                            <span style="color: white;" (click)="socialVideosaddmore = true">
                              <a class="cmvk" (click)="socialVideosaddmore = true;" style="cursor: pointer;">+ Add
                                More</a>
                            </span>
                            <small class="text-danger"
                              *ngIf="mediaForm.controls.socialVideos.invalid && mediaForm.controls.socialVideos.touched">
                              <span *ngIf="mediaForm.controls['socialVideos'].hasError('pattern')">Please enter valid
                                url</span>
                            </small>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Link To Your Website</td>
                        <td class="f14">
                          <div><input type="text" formControlName="website" class="edi-in fro-bo">
                            <span style="color: white;" (click)="yourWebsite = true;">
                              <a class="cmvk" (click)="yourWebsite = true;" style="cursor: pointer;">+ Add More</a>
                            </span>
                            <small class="text-danger"
                              *ngIf="mediaForm.controls.website.invalid && mediaForm.controls.website.touched">
                              <span *ngIf="mediaForm.controls['website'].hasError('pattern')">Please enter valid
                                url</span>
                            </small>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </form>
                </div>
              </div>

              <div *ngIf="requestType === 'SETTINGS'">
                <div class="hgbpp">
                  <h4 style="color: white; padding-bottom: 34px;">Password</h4>
                  <form [formGroup]="seetingForm">
                    <table class="ithy">
                      <tr>
                        <td>Old Password</td>
                        <td class="f14">
                          <div>
                            <input matInput type="password" formControlName="oldPassword" class="edi-in"
                              [type]="oldPassWordHide ? 'password' : 'text'" style="padding: 0px 5px;">
                            <mat-icon matSuffix style="margin-left: -40px !important;"
                              (click)="oldPassWordHide = !oldPassWordHide">{{ oldPassWordHide ? "visibility_off" :
                              "visibility" }}
                            </mat-icon>
                            <div class="text-danger"
                              *ngIf="seetingForm.controls['oldPassword'].touched && !seetingForm.controls['oldPassword'].valid">
                              <span *ngIf="seetingForm.controls['oldPassword'].hasError('required')">Old Password is
                                required</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>New Password</td>
                        <td class="f14">
                          <div class="">
                            <input matInput type="password" formControlName="newPassword" class="edi-in"
                              [type]="newPassWordHide ? 'password' : 'text'" style="padding: 0px 5px;">
                            <mat-icon matSuffix style="margin-left: -40px !important;"
                              (click)="newPassWordHide = !newPassWordHide">{{
                              newPassWordHide ? "visibility_off" : "visibility" }}
                            </mat-icon>
                            <div class="text-danger"
                              *ngIf="seetingForm.controls['newPassword'].touched && !seetingForm.controls['newPassword'].valid">
                              <span *ngIf="seetingForm.controls['oldPassword'].hasError('required')"> New Password is
                                required </span>
                              <span *ngIf="seetingForm.controls['newPassword'].hasError('newPassword') ">
                                Password Should be eight characters long and alphanumeric with special characters
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Confirm New Password</td>
                        <td class="f14">
                          <div class="">
                            <input matInput type="password" formControlName="confirmPassword" class="edi-in"
                              [type]="confirmPasswordHide ? 'password' : 'text'" style="padding: 0px 5px;">
                            <mat-icon matSuffix style="margin-left: -40px !important;"
                              (click)="confirmPasswordHide = !confirmPasswordHide">{{
                              confirmPasswordHide ? "visibility_off" : "visibility" }}
                            </mat-icon>
                            <div class="text-danger"
                              *ngIf="seetingForm.controls['confirmPassword'].touched && !seetingForm.controls['confirmPassword'].valid">
                              <span *ngIf="seetingForm.controls['confirmPassword'].hasError('required')">Confirm
                                Password is required</span>
                              <span *ngIf="seetingForm.hasError('mustMatch', 'confirmPassword')">Password doesn't
                                match..?</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </form>
                  <h4 style="color: white; padding-bottom: 14px;">Notification Preferences</h4>
                  <div class="form-check">
                    <input class="form-check-input" [(ngModel)]="checkBox1" type="checkbox" value="" id="defaultCheck1">
                    <label class="form-check-label" for="defaultCheck1">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua.
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" [(ngModel)]="checkBox2" type="checkbox" value="" id="defaultCheck2">
                    <label class="form-check-label" for="defaultCheck2">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua.
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" [(ngModel)]="checkBox3" type="checkbox" value="" id="defaultCheck3">
                    <label class="form-check-label" for="defaultCheck3"> Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit.</label>
                  </div>
                  <div>
                    <h5 style="color: #ff0000c9;cursor: pointer;padding: 43px 0px;" *ngIf="!showDeleteButton"
                      (click)="deleteShow = true">Delete Account Permanently</h5>
                  </div>
                  <div *ngIf="showDeleteButton">
                    <h5 style="color: #ff0000c9;cursor: pointer;padding: 43px 0px;">Your Account has been initiated
                      for Delete Account Process. If your account doesn't recover in 15 days then it will delete
                      permanently.</h5>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade addMusicTag-modal ge" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content bg_md">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">PRO Playlist</h5>
        <button type="button" (click)="showUploaModalvid = false;" class="close close-btn" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true" class="clo">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="my-4" role="document">
          <div class="" style="border-radius: 10px !important;">
            <div class="modal-body pt-3 pl-2" style="color: white;">
              <div class="container ml-2 pt-3 pl-3">
                <div class="row ml-2 mt-4" style="width: 100%;">
                  <div class="col-12">
                    <video [src]="previewVideo" style="width: 100%; border-radius: 15px; height:200px !important;"
                      controls>
                    </video>
                  </div>
                  <div class="col-12 fir-tab">
                    <div class="text-justify">
                      <p class="ve-nue mb-3 px-2 pb-3">Artist may only perform works for which
                        they have been granted performing rights (e.g. you are the composer), are in the ASCAP, BMI,
                        catalogues, or were written at least 96 years ago. Please use the song title and composer as
                        it appeares in catalogues.
                      </p>
                    </div>
                    <ul class="nav" role="tablist">
                      <li class="nav-item pr-2">
                        <input type=radio id="rdo1" checked (click)="tabName = 'ASCAP';changeMenu()" class="radio-input"
                          name="radio-group">
                        <label for="rdo1" class="radio-label pl-5"><span class="radio-border"></span>ASCAP</label>
                      </li>
                      <li class="nav-item pl-2">
                        <input type=radio id="rdo2" (click)="tabName = 'BMI';changeMenu()" class="radio-input"
                          name="radio-group">
                        <label for="rdo2" class="radio-label pl-5"><span class="radio-border"></span> BMI</label>
                      </li>
                      <li class="nav-item pl-2">
                        <input type=radio id="rdo4" (click)="tabName = 'ORIGINAL_WORK';changeMenu()" class="radio-input"
                          name="radio-group">
                        <label for="rdo4" class="radio-label pl-5"><span class="radio-border"></span> Original
                          Work</label>
                      </li>
                      <li class="nav-item pl-2">
                        <input type=radio id="rdo5" (click)="tabName = 'PUBLIC_DOMIN';changeMenu()" class="radio-input"
                          name="radio-group">
                        <label for="rdo5" class="radio-label pl-5"><span class="radio-border"></span> Public
                          Domain</label>
                      </li>
                    </ul>
                    <div class="tab-content mt-2">
                      <form [formGroup]="proReportingForm" (submit)="saveProPlaylist()">
                        <div class="container" style="margin-top: 20px">
                          <div class="row mt-2">
                            <div class="col-12 my-2" *ngIf="tabName == 'ASCAP' || tabName == 'BMI'">
                              <p> For {{tabName}} you refer to this link </p>
                              <p><a [href]="link" target="_blank">{{link}}</a></p>
                            </div>
                            <div class="col-12 my-2">
                              <p class="ratoj"> Title </p>
                              <input type="text" class="inputBox" formControlName="title">
                            </div>
                            <div class="col-12 mt-2">
                              <p class="ratoj"> Artist </p>
                              <input type="text" class="inputBox" formControlName="link">
                            </div>
                            <div class="col-12 my-5">
                              <button type="submit" class="btn defrqqa edcae">Add</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-12 mb-1">
                    <div class="row m-0" style="border-top: 1px solid #6a6565; border-bottom: 1px solid #6a6565;;">
                      <div class="col-3 text-center" style="border-bottom: 1px solid white; background-color: #242424;">
                        <p class="m-1">Playlist</p>
                      </div>
                      <div *ngIf="selectAllproList" class="col-9 text-right deleteAll" (click)="delteProList()">
                        <p class="m-1">Delete All</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="table-responsive" style="overflow: auto;height: 55vh;">
                      <table class="table flatTable">
                        <thead class="tableHeader">
                          <tr class="tableRow">
                            <div class="col-md-1">
                              <th>
                                <label class="container1 m-0">
                                  <input type="checkbox" (click)="selectAll()">
                                  <span class="checkmark"></span>
                                </label>
                              </th>
                            </div>
                            <div class="col-md-1">
                              <th>
                                <p> # </p>
                              </th>
                            </div>
                            <div class="col-md-3">
                              <th>
                                <p> Title </p>
                              </th>
                            </div>
                            <div class="col-md-2">
                              <th>
                                <p> Catagories </p>
                              </th>
                            </div>
                            <div class="col-md-2">
                              <th>
                                <p> Artist </p>
                              </th>
                            </div>
                            <div class="col-md-2">
                              <th>
                                <p>Type</p>
                              </th>
                            </div>
                            <div class="col-md-1">
                              <th>
                                <p style="margin-left: -20px;"> Action </p>
                              </th>
                            </div>
                          </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                          <tr class="tableRow" *ngFor="let list of proListData;let i= index" cdkDrag
                            cdkDragLockAxis="y">
                            <div class="col-md-1">
                              <td>
                                <label class="container1">
                                  <input type="checkbox" [checked]="selectAllproList">
                                  <span class="checkmark"></span>
                                </label>
                              </td>
                            </div>
                            <div class="col-md-1">
                              <td>
                                <p> {{i+1}} </p>
                              </td>
                            </div>
                            <div class="col-md-3 breakLine">
                              <td>
                                <p> {{list.title |titlecase}} </p>
                              </td>
                            </div>
                            <div class="col-md-2">
                              <td>{{list.categories}}</td>
                            </div>
                            <div class="col-md-2 breakLine">
                              <td>
                                <p> {{list.artist |titlecase}} </p>
                              </td>
                            </div>
                            <div class="col-md-2">
                              <td>
                                <p> {{list.proPlayListType}} </p>
                              </td>
                            </div>
                            <div class="col-md-1">
                              <td (click)="deleteProList(list.id)" style="cursor: pointer">
                                <p><i class="fa fa-trash-o"></i></p>
                              </td>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal" [ngClass]="{'d-block': showImageModal, 'd-none': !showImageModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content upladModal" style="top: 168px;width:100%;right: 0px !important;">
      <div class="modal-header">
        <div>
          <h5 class="modal-title">Image View</h5>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showImageModal = false;"
          style="color: white; font-size: 31px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img [src]="previewImage"
          style="width: 100%;border-radius: 15px;height:300px !important;margin-top: -7px !important">
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': videoImageModal, 'd-none': !videoImageModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content upladModal " style="top: 168px;width:100%;right: 0px !important;">
      <div class="modal-header">
        <div>
          <h5 class="modal-title"> Video View</h5>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="videoImageModal = false;"
          style="color: white; font-size: 31px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <video [src]="previewVideo"
          style="width: 100%;border-radius: 15px;height:300px !important;margin-top: -32px !important" controls></video>
      </div>
    </div>
  </div>
</div>

<div class="modal fade video-modal ge" data-backdrop="false" tabindex="-1" role="dialog"
  aria-labelledby="mySmallModalLabel" data-backdrop="false">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content bg_md">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Upload Videos</h5>
        <button type="button" (click)="showUploaModalvid = false;" class="close close-btn" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true" class="clo">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="videoUploadForm">
          <div class="form-group ">
            <label for="username">Video Title</label>
            <input type="text" class="ge-fk ti" formControlName="videoTitle"
              placeholder="What is the title of your video">
          </div>
          <div class="form-group axcr">
            <label for="username">Add Video Tags</label>
            <mat-form-field class="example-chip-list ge-fk ti" appearance="outline">
              <mat-chip-list #musicList>
                <mat-chip *ngFor="let keyword of keywords" [selectable]="selectableTag" [removable]="removableTag"
                  (removed)="removeMusic(keyword)" style="margin-top: -34px !important;"> {{keyword}}
                  <mat-icon matChipRemove *ngIf="removable" style="margin-left: 8px;margin-top: 10px;">cancel</mat-icon>
                </mat-chip>
                <input style="color: #FFFFFF;" [matChipInputFor]="musicList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodesTag" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addMusic($event)" />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="form-group">
            <label for="username">Upload Video</label>
            <div class="row">
              <div class="col-md-12">
                <div>
                  <div class="image-upload-wrapper">
                    <div class="logo-wrapper" *ngIf="showIcon || !showImagevid">
                      <img src="../../../assets/icons/image-upload.png" (click)="filevid.click()" alt="">
                    </div>
                    <p *ngIf="showIcon || !showImagevid">Click to browse</p>
                    <div class="image-wrapper">
                      <ng-container *ngIf="videoUploaded; else videoUploadedDone">
                        <p style="color: #FF6720;"> <i class="fa fa-spinner" aria-hidden="true"></i> Uploading... </p>
                      </ng-container>
                      <ng-template #videoUploadedDone>
                        <video *ngIf="showImagevid" [src]="imageSrcvid"
                          style="height: 100%;width: 100%; object-fit: cover;" type="video/mp4" controls></video>
                        <i *ngIf="showImagevid" class="fa fa-times" aria-hidden="true" style="position: absolute;
                          bottom: 201px; left: 325px; color: red;cursor: pointer;" (click)="showImagevid = false"></i>
                      </ng-template>
                    </div>
                  </div>
                  <input class="img-fluid" formCotrolName="videoUrl" accept="video/*" type="file" #filevid
                    style="display: none;" (change)="onFileSelectvid($event)">
                  <p class="info"> <img src="../../../assets/icons/info.png" alt="">
                    Please upload at least one video. You must own or have rights to use these video.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer fm-bt border-0 pb_t">
        <button type="button" class="btn btn-primary col-6" (click)="addVideoDetails()"
          data-dismiss="modal">Submit</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': showUploaImageModal, 'd-none': !showUploaImageModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content upladModal" style="width: 58vh; top: 136px; position: absolute; right: 41px;">
      <div class="modal-header">
        <div>
          <h5 class="modal-title">Upload Image</h5>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="showUploaImageModal = false;" style="color: white; font-size: 31px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="image-upload-wrapper" *ngIf="!showImage">
            <div class="logo-wrapper">
              <img src="../../../assets/icons/image-upload.png" alt="" (click)="file3.click()">
            </div>
            <p>Click to browse</p>
          </div>
          <div class="image-wrapper container">
            <ng-container *ngIf="imageUploaded2; else imageUploadedDones">
              <p style="color: #FF6720;"> <i class="fa fa-spinner" aria-hidden="true"></i> Uploading... </p>
            </ng-container>
            <ng-template #imageUploadedDones>
              <img *ngIf="showImage" [src]="videoDetails"
                style="width: 100%;border-radius: 15px;height:300px !important;margin-top: -7px !important">
              <i *ngIf="showImage" class="fa fa-times" aria-hidden="true"
                style="position: absolute; bottom: 356px;left: 396px;cursor: pointer;font-size: 20px;color: red;"
                (click)="showImage = false"></i>
            </ng-template>
          </div>
          <input class="img-fluid" type="file" accept="image/*" #file3 style="display: none;"
            (change)="onFileSelectImage($event)">
          <div class="erfg">
            <button type="submit" class="eijh" style="margin-top: 17px;" (click)="addImageDetails()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Certicficate Modal -->
<div class="modal" [ngClass]="{'d-block': showUploaModal, 'd-none': !showUploaModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content  bg_md" style="box-shadow: 3px 1px black;overflow-y: scroll;height: 90vh;">
      <div class="modal-header" style="border-bottom:none">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showUploaModal = false;"
          style="color: white; font-size: 31px;"><span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <form class="jhnu ge" [formGroup]="certificateForm">
              <div class="form-group">
                <label for="username">Certificate Name</label>
                <input type="text" class="ge-fk" formControlName="certificateName" placeholder="e.g Music Production">
                <div
                  *ngIf="certificateForm.controls['certificateName'].touched && !certificateForm.controls['certificateName'].valid"
                  class="text-danger">
                  <span *ngIf="certificateForm.controls['certificateName'].hasError('required')"> Certificate Name is
                    required </span>
                </div>
              </div>
              <div class="form-group">
                <label for="username">Issuing Organization</label>
                <input type="text" class="ge-fk" formControlName="issuingOrganization" placeholder="e.g Berklee Online">
                <div
                  *ngIf="certificateForm.controls['issuingOrganization'].touched && !certificateForm.controls['issuingOrganization'].valid"
                  class="text-danger">
                  <span *ngIf="certificateForm.controls['issuingOrganization'].hasError('required')">
                    Issuing Organization is required
                  </span>
                </div>
              </div>
              <div class="form-group yr-ob">
                <label for="username">Year Obtained</label>
                <div class="row">
                  <div class="col-6">
                    <mat-form-field class="ge-fk" appearance="outline" style="height: 50px;">
                      <input matInput [matDatepicker]="yearObtainedFrom" [formControl]="yearFrom" placeholder="From"
                        style="color: white;">
                      <mat-datepicker-toggle matSuffix [for]="yearObtainedFrom" class="text-white">
                      </mat-datepicker-toggle>
                      <mat-datepicker #yearObtainedFrom startView="multi-year"
                        (yearSelected)="YearFrom($event,yearObtainedFrom)" panelClass="example-month-picker"
                        color="primary"></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field class="ge-fk" appearance="outline" style="height: 50px;">
                      <input matInput [matDatepicker]="yearObtainedTo" [formControl]="yearTo" placeholder="To"
                        style="color: white;">
                      <mat-datepicker-toggle matSuffix [for]="yearObtainedTo" class="text-white">
                      </mat-datepicker-toggle>
                      <mat-datepicker #yearObtainedTo startView="multi-year"
                        (yearSelected)="YearTo($event,yearObtainedTo)" panelClass="example-month-picker"
                        color="primary"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="text-danger mt-2 text-center"
                  *ngIf="certificateForm.controls['issuingOrganization'].hasError('required')"> Year is required
                </div>
              </div>
              <div class="form-group">
                <label for="username">Upload Certificate</label>
                <div class="row">
                  <div class="col-md-12">
                    <label class="ge-fk lbf" style="text-align: center; justify-content: center; align-items: center;">
                      <span class="brw" style="display: inline;">
                        <img src="assets/upload_video_icon.svg" class="img-fluid" height="20" width="20">
                        <p class="mb-0" style="color: #FF6720;">Browse to upload</p>
                      </span>
                      <input type="file" class="fi_n" formControlName="certificateUrl" #fileInput
                        (change)="onFileSelectCertificate($event)" />
                      <span class="file-info">{{ fileInfo }}</span>
                    </label>
                    <div class="innerModal">
                      <ng-container *ngIf="imageUploaded; else imageUploadedDone">
                        <p style="color: #FF6720;"> <i class="fa fa-spinner" aria-hidden="true"></i> Uploading... </p>
                      </ng-container>
                      <ng-template #imageUploadedDone>
                        <img *ngIf="showImage" src={{imageSrc}} alt="" class=" img-fluid certificateImage">
                        <span style="color: #FF6720;cursor:pointer;" *ngIf="showImage"
                          (click)="showImage = false">X</span>
                      </ng-template>
                    </div>
                    <div
                      *ngIf="certificateForm.controls['certificateUrl'].touched && !certificateForm.controls['certificateUrl'].valid">
                      <span *ngIf="certificateForm.controls['certificateUrl'].hasError('required')"> Certicficate Upload
                        is required </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer fm-bt" style="border-top:none">
        <button type="button" class="btn btn-primary col-6" data-dismiss="modal"
          (click)="editCerticateDetails()">Submit</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="examplesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog d-flex" role="document">
    <div class="modal-content" style="width: 238%; position: absolute; right: -315px;">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Certificate View</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <img [src]="previewCertificate" style="width: 100%;">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': showCertificateModal, 'd-none': !showCertificateModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content  bg_md" style="box-shadow: 3px 1px black;overflow-y: scroll;height: 90vh;">
      <div class="modal-header" style="border-bottom:none">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showCertificateModal=false"
          style="color: white; font-size: 31px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <form class="jhnu ge" [formGroup]="addCertificatedForm">
              <div class="form-group">
                <label for="username">Certificate Name</label>
                <input type="text" class="ge-fk" formControlName="certificateName" placeholder="e.g Music Production">
                <div
                  *ngIf="addCertificatedForm.controls['certificateName'].touched && !addCertificatedForm.controls['certificateName'].valid"
                  class="text-danger">
                  <span *ngIf="addCertificatedForm.controls['certificateName'].hasError('required')"> Certificate Name
                    is required </span>
                </div>
              </div>
              <div class="form-group">
                <label for="username">Issuing Organization</label>
                <input type="text" class="ge-fk" formControlName="issuingOrganization" placeholder="e.g Berklee Online">
                <div
                  *ngIf="addCertificatedForm.controls['issuingOrganization'].touched && !certificateForm.controls['issuingOrganization'].valid"
                  class="text-danger">
                  <span *ngIf="addCertificatedForm.controls['issuingOrganization'].hasError('required')">
                    Issuing Organization is required </span>
                </div>
              </div>
              <div class="form-group yr-ob">
                <label for="username">Year Obtained</label>
                <div class="row">
                  <div class="col-6">
                    <mat-form-field class="ge-fk" appearance="outline" style="height: 50px;">
                      <input matInput [matDatepicker]="yearObtainedFrom" [formControl]="yearFrom" placeholder="From">
                      <mat-datepicker-toggle matSuffix [for]="yearObtainedFrom" class="text-white">
                      </mat-datepicker-toggle>
                      <mat-datepicker #yearObtainedFrom startView="multi-year"
                        (yearSelected)="YearFrom($event,yearObtainedFrom)" panelClass="example-month-picker"
                        color="primary"></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field class="ge-fk" appearance="outline" style="height: 50px;">
                      <input matInput [matDatepicker]="yearObtainedTo" [formControl]="yearTo" placeholder="To">
                      <mat-datepicker-toggle matSuffix [for]="yearObtainedTo" class="text-white">
                      </mat-datepicker-toggle>
                      <mat-datepicker #yearObtainedTo startView="multi-year"
                        (yearSelected)="YearTo($event,yearObtainedTo)" panelClass="example-month-picker"
                        color="primary"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="text-danger mt-2 text-center"
                  *ngIf="addCertificatedForm.controls['issuingOrganization'].hasError('required')">
                  Year is required
                </div>
              </div>
              <div class="form-group">
                <label for="username">Upload Certificate</label>
                <div class="row">
                  <div class="col-md-12">
                    <label class="ge-fk lbf" style="text-align: center; justify-content: center; align-items: center;">
                      <span class="brw" style="display: inline;">
                        <img src="assets/upload_video_icon.svg" class="img-fluid" height="20" width="20">
                        <p class="mb-0" style="color: #FF6720;">Browse to upload</p>
                      </span>
                      <input type="file" class="fi_n" formControlName="certificateUrl" #fileInput
                        (change)="onFileSelectCertificate($event)" />
                      <span class="file-info">{{ fileInfo }}</span>
                    </label>
                    <div class="innerModal">
                      <ng-container *ngIf="imageUploaded; else imageUploadedDone">
                        <p style="color: #FF6720;"> <i class="fa fa-spinner" aria-hidden="true"></i> Uploading... </p>
                      </ng-container>
                      <ng-template #imageUploadedDone>
                        <img *ngIf="showImage" [src]="imageSrc" alt="" class="certificateImage">
                        <span style="color: #FF6720;cursor:pointer;" *ngIf="showImage"
                          (click)="showImage = false">Change uploaded video</span>

                      </ng-template>
                    </div>
                    <div
                      *ngIf="addCertificatedForm.controls['certificateUrl'].touched && !addCertificatedForm.controls['certificateUrl'].valid">
                      <span *ngIf="addCertificatedForm.controls['certificateUrl'].hasError('required')">
                        Certicficate Upload is required
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer fm-bt" style="border-top:none">
        <button type="button" class="btn btn-primary col-6" data-dismiss="modal"
          (click)="certificateDetailsUpload()">Submit</button>
      </div>
    </div>
  </div>
</div>


<div class="modal" [ngClass]="{'d-block': socialMedia, 'd-none': !socialMedia}">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="margin-top: 121px;">
      <div class="modal-body">
        <form class="oik" [formGroup]="publicVideoForm">
          <div class="form-group">
            <div class="row">

              <div class="col-8 pb-l add-label-wrapper">
                <input type="text" class="ge-fk" formControlName="websiteLink" [(ngModel)]="publicVideo"
                  placeholder="https://www.sds.com" style="width: 154%; color: black !important;">
              </div>
            </div>
            <small class="text-danger"
              *ngIf="publicVideoForm.controls.websiteLink.invalid &&(publicVideoForm.controls.websiteLink.touched)">
              <span *ngIf="publicVideoForm.controls['websiteLink'].hasError('pattern')">Please
                enter valid url</span>
            </small>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary nxt" [disabled]="publicVideoForm.invalid"
          (click)="savePublicVideo()" style="height: 40px;">Save
          changes</button>
        <button type="button" class="btn btn-secondary yht" (click)="socialMedia = false"
          style="height: 42px;width: 86px;">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': socialVideosaddmore, 'd-none': !socialVideosaddmore}">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="margin-top: 121px;">
      <div class="modal-body">
        <form class="oik" [formGroup]="socialVideoForm">
          <div class="form-group">
            <div class="row">
              <div class="col-8 pb-l add-label-wrapper">
                <input type="text" class="ge-fk" formControlName="websiteLink" [(ngModel)]="socialVideo"
                  placeholder="https://www.sds.com" style="width: 154%; color: black !important;">
              </div>
            </div>
            <small class="text-danger"
              *ngIf="socialVideoForm.controls.websiteLink.invalid &&(socialVideoForm.controls.websiteLink.touched)">
              <span *ngIf="socialVideoForm.controls['websiteLink'].hasError('pattern')">Please
                enter valid url</span>
            </small>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary nxt" [disabled]="socialVideoForm.invalid"
          (click)="saveSocialVideo()" style="height: 40px;">Save
          changes</button>
        <button type="button" class="btn btn-secondary yht" (click)="socialVideosaddmore = false"
          style="height: 42px;width: 86px;">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': yourWebsite, 'd-none': !yourWebsite}">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="margin-top: 121px;">
      <div class="modal-body">
        <form class="oik" [formGroup]="WebsiteVideoForm">
          <div class="form-group">
            <div class="row">

              <div class="col-8 pb-l add-label-wrapper">
                <input type="text" class="ge-fk" formControlName="websiteLink" [(ngModel)]="websiteLink"
                  placeholder="https://www.sds.com" style="width: 154%; color: black !important;">
              </div>
            </div>
            <small class="text-danger"
              *ngIf="WebsiteVideoForm.controls.websiteLink.invalid &&(WebsiteVideoForm.controls.websiteLink.touched)">
              <span *ngIf="WebsiteVideoForm.controls['websiteLink'].hasError('pattern')">Please
                enter valid url</span>
            </small>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary nxt" [disabled]="WebsiteVideoForm.invalid"
          (click)="saveWebsiteLink()" style="height: 40px;">Save
          changes</button>
        <button type="button" class="btn btn-secondary yht" (click)="yourWebsite = false"
          style="height: 42px;width: 86px;">Close</button>
      </div>
    </div>
  </div>
</div>


<div class="modal" [ngClass]="{'d-block': deleteShow, 'd-none': !deleteShow}">
  <div class="modal-dialog">
    <div class="modal-content modalBg" style="width:112%">
      <div class="modal-body">
        <div class="container">
          <div class="row px-3 pt-3" style="color: white;">
            <div class="col-md-12">
              <div class="text-center">
                <span style="color: red;font-size: 40px;"><i class="fa fa-trash-o"></i> </span>
              </div>
              <p>Are you sure you want to permanently delete your account? Before proceeding with your
                request to permanently delete your Virtuosica account, please make sure you understand
                our Account Removal Policy. </p>
              <p style="font-size: 11px;text-decoration: underline;">Account Removal Policy</p>
              <div class="form-check" style="color: #C9C9C9;padding-bottom: 27px;">
                <input class="form-check-input" [(ngModel)]="checkBox9" type="checkbox" value="" id="defaultCheck5">
                <label class="form-check-label" for="defaultCheck5" style="font-size:14px">
                  I have read and understand the requirements of the gig posting that I am responding
                  to in this proposal.
                </label>
              </div>
              <div class="text-center">
                <button type="button" class="btn hftrdd" (click)="deleteShow = false">Go Back</button>
                <button type="button" class="btn ytgft" [disabled]="!checkBox9"
                  (click)="getUserAccPendingDetails();">Delete Account</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{'d-block': deleteNextStep, 'd-none': !deleteNextStep}">
  <div class="modal-dialog">
    <div class="modal-content modalBg" style="width:112%">
      <div class="modal-body">
        <div class="container">
          <div class="row px-3 pt-3" style="color: white;">
            <div class="col-md-12">
              <p>You have requested that we delete your Virtuosica account. You have 15 days to change
                your mind and stop the deletion of your account. In those 15 days, you will still have
                access to your Virtuosica account, but you will not have access to certain features,
                such as creating a new event. </p>
              <p>Please tell us why you are deleting your Virtuosica account.</p>
              <form class="jhnu ge" id="deleteForm" [formGroup]="deleteForm">
                <div class="form-group">
                  <textarea class="reasonTxtarea rfgt" rows="4" cols="60" formControlName="reason"
                    placeholder="Enter your reason here."> </textarea>
                </div>
                <div class="text-center">
                  <button type="button" class="btn hftrdd" (click)="deleteNextStep = false">Cancel</button>
                  <button type="button" class="btn ytgft" style="padding: 8px 37px !important;"
                    (click)="deleteUserAccount()">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal" [ngClass]="{'d-block': deleteNotProcess, 'd-none': !deleteNotProcess}">
  <div class="modal-dialog">
    <div class="modal-content modalBg" style="width:112%">
      <div class="modal-header" style="border-bottom: none;">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteNotProcess = false"
          style="color: white">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row px-3 pt-3" style="color: white;">
            <div class="col-md-12">
              <p>We are unable to process your request to delete your Virtuosica account at this time
                because you have pending or scheduled gigs or payouts on Virtuosica.com. For assistance in
                deleting your account, please contact Customer Service or read and follow the steps in
                our Account Removal Policy.</p>
              <p style="font-size: 11px;text-decoration: underline;">Account Removal Policy</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
