import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  isBusy: Subject<boolean>;

  constructor() {
    this.isBusy = new BehaviorSubject<boolean>(true);
  }

  getState(): Observable<boolean> {
    return this.isBusy.asObservable();
  }

  setState(state: boolean) {
    this.isBusy.next(state);
  }
}
