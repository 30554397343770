<div  id="rockband" class="px-0">
  <div class="container px-0" style="color: white;">
    <div class="row">
      <div class="col-7 col-md-9">
        <h4> PRO Playlist </h4>
      </div>
      <div class="col-5 col-md-3 text-right">
        <button type="submit" [routerLink]="['/ArtistDashboard/artistEventDetail']" class="btn defrqqa"
                  style="width: 110px;">Done</button>
      </div>
    </div>
    <hr class="mt-0 mb-2" style="border-bottom: 1px solid white;">
    <div class="my-2" role="document">
      <div class="" style="border-radius: 10px !important;">
        <div class="modal-body pt-0 px-0" style="color: white;">
          <div class="container ml-0 pt-1 px-0" style="background: #181818;">
            <div class="row ml-0 mt-1 " style="width: 100%;">
              <div *ngIf="proTab=='myPlaylist'" class="col-12 backg">
                <div class="row">
                  <div class="col-2 py-3 d-flex justify-content-center">
                    <img src="../../../../../../assets/Property 1=Default.png" (mouseover)="editPlaylist = true;" (mouseleave)="editPlaylist = false;" height="95" width="95" alt=""
                    data-toggle="modal" data-target="#createNewPROList" class="pointer" (click)="editPlaylistDetails()">
                    <!-- *ngIf="editPlaylist" -->
                    <i *ngIf="editPlaylist" class="fa fa-2x fa-pencil" aria-hidden="true" style="position: absolute; background: none;top: 45px;"></i>
                  </div>
                  <div *ngIf="selectedPlaylist == null || selectedPlaylist == undefined" class="col-10 d-flex flex-column justify-content-center">
                    <h5> Choose Your Playlist </h5>
                  </div>
                  <div *ngIf="selectedPlaylist && selectedPlaylist.name" class="col-10 d-flex flex-column justify-content-center">
                    <h5> {{selectedPlaylist?.name}} </h5>
                    <p> {{selectedPlaylist?.description}} </p>
                  </div>
                </div>
              </div>


              <div class="col-12 fir-tab px-0 px-md-2 mt-2">
                <div class="text-justify">
                  <p class="ve-nue mb-3 px-2">Artist may only perform works for which they have been granted
                    performing rights (e.g. you are the composer), are in the ASCAP, BMI, catalogues, or were
                    written at least 96 years ago. Please use the song title and composer as it appeares in catalogues.
                  </p>
                </div>
                <ul class="nav" role="tablist">
                  <li class="nav-item pr-2">
                    <input type=radio id="rdo1" checked (click)="tabName = 'ASCAP'" class="radio-input" name="radio-group">
                    <label for="rdo1" class="radio-label pl-5"> <span class="radio-border"></span> ASCAP
                    </label>
                  </li>
                  <li class="nav-item pl-2">
                    <input type=radio id="rdo2" (click)="tabName = 'BMI'" class="radio-input" name="radio-group">
                    <label for="rdo2" class="radio-label pl-5"><span class="radio-border"></span> BMI</label>
                  </li>
                  <li class="nav-item pl-2">
                    <input type=radio id="rdo4" (click)="tabName = 'ORIGINAL_WORK'" class="radio-input"
                      name="radio-group">
                    <label for="rdo4" class="radio-label pl-5"><span class="radio-border"></span> Original Work</label>
                  </li>
                  <li class="nav-item pl-2">
                    <input type=radio id="rdo5" (click)="tabName = 'PUBLIC_DOMIN'" class="radio-input"
                      name="radio-group">
                    <label for="rdo5" class="radio-label pl-5"><span class="radio-border"></span> Public Domain</label>
                  </li>
                </ul>
                <div class="tab-content">
                  <form [formGroup]="proReportingForm" (submit)="saveProPlaylist()">
                  <div class="container" style="margin-top: 20px">
                    <div class="row mt-2">
                      <div class="col-12 my-2 d-flex" *ngIf="tabName == 'ASCAP' || tabName == 'BMI'">
                        <p class="mb-0"> For ASCAP & BMI you can refer to this link : &nbsp; &nbsp;</p>
                        <p class="mb-0"><a href="https://repertoire.bmi.com/" target="_blank">https://repertoire.bmi.com/</a></p>
                      </div>
                      <div class="col-12 col-md-9 my-2">
                        <p class="ratoj"> Title </p>
                        <input type="text" class="inputBox" formControlName="title">
                      </div>
                      <div class="col-12 col-md-9 mt-2">
                        <p class="ratoj"> Artist </p>
                        <input type="text" class="inputBox" formControlName="link">
                      </div>
                      <div class="col-12 my-2">
                        <button type="submit" class="btn defrqqa">Add</button>
                      </div>
                    </div>
                  </div>
                  </form>
                </div>
              </div>
              <!-- newly added table -->

            <div class="col-12 mb-1 mx-0 mt-3">
              <div class="row m-0" style="color: white;">
                <div class="col-6 d-flex align-items-center pl-0">
                  <p class="m-1" style="font-weight:500">Event Name: {{proEventData?.title}} &nbsp; &nbsp; </p>
                </div>
                <div class="col-3 d-flex align-items-center">
                  <p class="m-1">Date: {{proEventData?.startDate}} </p>
                </div>
                <div class="col-3 d-flex justify-content-end align-items-center px-0">
                  <p class="mb-0 createPRO" data-toggle="modal" data-target="#createNewPROList" (click)="playlistTitle= 'Add Playlist Details'">
                    Create New PRO Playlist</p>
                </div>
              </div>
            </div>
              <div class="col-12 mb-1 mt-2 mx-0">
                <div class="row m-0 tabMenu">
                  <div class="col-4 text-center" [ngClass]="proTab=='addedSong'?'tabUI':''">
                    <p class="m-1 pointer" (click)="proTab='addedSong'">Added Songs</p>
                  </div>
                  <div class="col-4 text-center" [ngClass]="proTab=='myPlaylist'?'tabUI':''">
                    <p class="m-1 pointer" (click)="myPlaylist(); proTab='myPlaylist'">My Playlist</p>
                  </div>
                  <div class="col-4 text-center" [ngClass]="proTab=='repertoire'?'tabUI':''">
                    <p class="m-1 pointer" (click)="repertoire();proTab='repertoire'">Repertoire</p>
                  </div>
                </div>
              </div>
              <div *ngIf="proTab=='addedSong'" class="col-12 mx-0">
                <div class="col-12 p-0 my-3">
                  <div class="selectEvent">
                    <mat-form-field appearance="outline">
                      <mat-select placeholder="Select the event ">
                        <mat-option *ngFor="let event of proEventList" [value]="event" (click)="selectedProEvent = event">
                          {{event.title}} </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <button type="submit" (click)="getProPlaylistDetails(selectedProEvent); test(selectedProEvent);"
                    class="btn view">View Songs</button>
                </div>
                <div *ngIf="proListData?.length == 0" class="col-12 my-2 d-flex justify-content-center">
                  <p>No PRO Song added in this event. Please add your PRO Song for this event.</p>
                </div>
                <div *ngIf="proListData?.length > 0"class="table-responsive" style="overflow: auto;height: 55vh;">
                  <table class="table flatTable">
                    <thead class="tableHeader">
                      <tr class="tableRow">
                        <div class="col-1 px-0 px-md-1">
                          <th>
                            <label class="container1 m-0">
                              <input type="checkbox" (click)="selectAllAddedSongOfEvent()">
                              <span class="checkmark"></span>
                            </label>
                          </th>
                        </div>
                        <div class="col-1 px-0 px-md-1">
                          <th>
                            <p> # </p>
                          </th>
                        </div>
                        <div class="col-4 col-md-3 px-0 px-md-1">
                          <th>
                            <p> Title </p>
                          </th>
                        </div>
                        <div class="col-2 col-md-2 px-0 px-md-1">
                          <th>
                            <p> Catagories </p>
                          </th>
                        </div>
                        <div class="col-4 px-0 px-md-1">
                          <th class="d-flex justify-content-end">
                            <p class="mr-5 pr-5"> Artist </p>
                            <i class="fa fa-ellipsis-v px-2 mt-1 ml-5 pointer" type="button" id="dropdownMenuButton"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="background: #242424; color:white;">
                              <a class="dropdown-item pointer" style="border-bottom: 1px solid white" (click)="existingPlaylist = !existingPlaylist">Add to
                                Existing Playlist</a>
                                <a class="dropdown-item pointer"  (click)="deleteAddedSong()">Delete</a>
                            </div>
                            <div *ngIf="existingPlaylist" class="existingPlaylist">
                          <p class="text-right mb-0" style="position: absolute; right: 0px;"><i class="fa fa-times pointer" (click)="existingPlaylist = false;"></i></p>
                          <p *ngIf="myPlaylistDetails.length == 0" class="text-center mb-0" (click)="existingPlaylist = false;">You don't have any saved playlist, Please first create your Playlist</p>
                          <p *ngFor="let playlist of myPlaylistDetails" (click)="addSongsToPlaylistFromEvent(playlist.id);existingPlaylist = false;"
                                class="py-2 mb-0 pl-2 pointer" style="border-bottom: 1px solid white">
                                {{playlist.name | titlecase}}
                              </p>
                            </div>
                          </th>
                        </div>
                      </tr>
                    </thead>
                    <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                      <tr class="tableRow" *ngFor="let list of proListData;let i= index" cdkDrag cdkDragLockAxis="y">
                        <div class="col-1 px-0 px-md-1">
                          <td>
                            <label class="container1">
                              <input type="checkbox" [checked]="selectAllAddedSong" (click)="selectAddedSongOfEvent(list)">
                              <span class="checkmark"></span>
                            </label>
                          </td>
                        </div>
                        <div class="col-1 px-0 px-md-1">
                          <td>
                            <p> {{i+1}} </p>
                          </td>
                        </div>
                        <div class="col-4 col-md-3 breakLine px-0 px-md-1">
                          <td>
                            <p> {{list.title |titlecase}} </p>
                          </td>
                        </div>
                        <div class="col-2 col-md-2 px-0 px-md-1">
                          <td>
                            {{list.categories}}
                          </td>
                        </div>
                        <div class="col-4 breakLine px-0 px-md-1">
                          <td class="d-flex justify-content-end ">
                            <p class="pr-5 mr-5"> {{list.artist |titlecase}} </p>
                            <p class="ml-5 px-2"></p>
                          </td>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div *ngIf="proTab=='myPlaylist'" class="col-12 mx-0">
                <div *ngIf="myPlaylistDetails.length == 0" class="row my-4">
                  <div class="col-12">
                    <p class="text-center mb-0">You don't have any saved playlist, Please first create your Playlist</p>
                  </div>
                </div>
                <div class="row ml-0" *ngFor="let playlist of myPlaylistDetails; let i = index" style="width:100%; background:#242424; border-bottom: 1px solid white">
                  <div class="col-12 py-2" style="color: white;">
                    <div class="row" (click)=" selectedPlaylist = playlist;">
                      <div class="col-1 d-flex align-items-center">
                        <label class="container1 m-0">
                          <input type="checkbox" (click)="selectAllFromMyPlaylist(i)">
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="col-1 text-left pl-0">
                        <img src="../../../../../../../assets/Property 1=Default.png" height="50px" width="50px" alt="img">
                      </div>
                      <div class="col-8 d-flex align-items-center">
                        <p class="mb-0"> {{playlist.name}} </p>
                      </div>
                      <div class="col-2 d-flex justify-content-end align-items-center">
                        <i class="fa fa-chevron-down mr-5" data-toggle="collapse"
                          [attr.data-target]="'#collapseExample'+i" aria-expanded="false" aria-controls="collapseExample"
                          (click)="getAllSongOfPlaylist(playlist.id,i);"></i>

                          <i class="fa fa-ellipsis-v px-2 ml-4 mt-1 pointer" type="button" id="dropdownMenuButton"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="background: #242424; color:white;">
                          <a class="dropdown-item pointer" (click)="completedMyPlaylist[i] = true;" style="border-bottom: 1px solid white;">Add Playlist to the Completed Events</a>
                          <a class="dropdown-item pointer" (click)="deleteProPlaylist(playlist);" style="border-bottom: 1px solid white;">Delete Playlist</a>
                          <a class="dropdown-item pointer" (click)="deleteSongOfPlaylist(playlist,i);">Delete Songs</a>
                        </div>
                        <div *ngIf="completedMyPlaylist[i]" class="existingPlaylist" style="left: -48px !important; top:60px;">
                          <p class="text-right mb-0" style="position: absolute; right: 0px;"><i class="fa fa-times pointer" (click)="completedMyPlaylist[i] = false;"></i></p>
                          <p *ngFor="let event of completedEventListArray; let k = index" (click)="addSongsToEventFromPlaylist(event.id); completedMyPlaylist[i] = false;"
                              class="mb-0 pointer" style="border-bottom: 1px solid white" [ngClass]="k == 0? 'activeEvent':'restEvent' ">
                              {{event.title | titlecase}}
                            </p>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="collapse" [id]="'collapseExample'+i" style="width: 100%; background: #181818 !important;">
                    <div class="table-responsive" style="overflow: auto;height: 35vh;">
                      <table class="table flatTable" style="margin: 0px !important; max-width: 100% !important;">
                        <thead class="tableHeader">
                          <tr class="tableRow" style="background: #181818 !important;">
                            <div class="col-1 px-0 px-md-1">
                              <th></th>
                            </div>
                            <div class="col-1 px-0 px-md-1">
                              <th>
                                <p> # </p>
                              </th>
                            </div>
                            <div class="col-4 col-md-5 px-0 px-md-1">
                              <th>
                                <p> Title </p>
                              </th>
                            </div>
                            <div class="col-3 col-md-2 px-0 px-md-1">
                              <th>
                                <p> Catagories </p>
                              </th>
                            </div>
                            <div class="col-3 px-0 px-md-1">
                              <th>
                                <p> Artist </p>
                              </th>
                            </div>
                          </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="dropPlaylistSong($event,playlist.id,i)">
                          <tr class="tableRow" *ngFor="let list of songList[i];let j= index" cdkDrag cdkDragLockAxis="y">
                            <div class="col-1 px-0 px-md-1">
                              <td>
                                <label class="container1">
                                  <input type="checkbox" [checked]="selectAllproList[i]" (click)="selectFromMyPlaylist(list)">
                                  <span class="checkmark"></span>
                                </label>
                              </td>
                            </div>
                            <div class="col-1 px-0 px-md-1">
                              <td>
                                <p> {{j+1}} </p>
                              </td>
                            </div>
                            <div class="col-4 col-md-5 breakLine px-0 px-md-1">
                              <td>
                                <p> {{list.title |titlecase}} </p>
                              </td>
                            </div>
                            <div class="col-3 col-md-2 px-0 px-md-1">
                              <td>
                                {{list.category}}
                              </td>
                            </div>
                            <div class="col-3 breakLine px-0 px-md-1">
                              <td>
                                <p> {{list.artist |titlecase}} </p>
                              </td>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="proTab=='repertoire'" class="col-12 mx-0">
                <div *ngIf="repertoireData.length == 0" class="row my-4">
                  <div class="col-12">
                    <p class="text-center mb-0">No song added in your performed events, Please first add PRO Song to your completed events.</p>
                  </div>
                </div>
                <div *ngIf="repertoireData.length > 0" class="col-12 my-2">
                  <div class="row pl-2">
                    <div class="col-9 d-flex align-items-center pl-0">
                      <p class="mb-0">List of songs that you have played so far in the Events</p>
                    </div>
                    <div class="col-3 pl-0 d-flex align-items-center justify-content-end pr-0 ">
                      <input type="text" placeholder="Search Song Here" (keyup.enter)="repertoire(searchRepertoire)"
                      [(ngModel)]="searchRepertoire" style="background: none; border: 1px solid white; color: white; border-radius: 20px; padding: 5px 15px;">
                      <i class="fa fa-search" style="position: absolute; top: 25%; right: 15%;" (click)="repertoire(searchRepertoire)"></i>
                    </div>
                  </div>
                </div>
                <div *ngIf="repertoireData.length > 0" class="table-responsive" style="overflow: auto;height: 55vh;">
                  <table class="table flatTable">
                    <thead class="tableHeader">
                      <tr class="tableRow">
                        <div class="col-1 px-0 px-md-1">
                          <th>
                            <label class="container1 m-0">
                              <input type="checkbox" (click)="selectAllRepertoire()">
                              <span class="checkmark"></span>
                            </label>
                          </th>
                        </div>
                        <div class="col-1 px-0 px-md-1">
                          <th>
                            <p> # </p>
                          </th>
                        </div>
                        <div class="col-3 px-0 px-md-1">
                          <th>
                            <p> Event Name </p>
                          </th>
                        </div>
                        <div class="col-3 px-0">
                          <th class="pl-0">
                            <p> Title </p>
                          </th>
                        </div>
                        <div class="col-2 col-md-2 px-0 px-md-1">
                          <th>
                            <p> Catagories </p>
                          </th>
                        </div>
                        <div class="col-2 px-0">
                          <th class="d-flex justify-content-end pl-0">
                            <p class="pr-2 mr-5">Artist</p>
                            <i class="fa fa-ellipsis-v px-2 mt-1 ml-5 pointer" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"
                            style="background: #242424; color:white;">
                            <a class="dropdown-item pointer" (click)="completedEventList = true;">Add Song to the Completed Events</a>
                          </div>
                          <div *ngIf="completedEventList" class="existingPlaylist" style="left:-38px !important;">
                              <p class="text-right mb-0" style="position: absolute; right: 0px;"><i class="fa fa-times pointer" (click)="completedEventList = false;"></i></p>
                              <p *ngFor="let event of completedEventListArray; let k = index" (click)="addSongsToEventFromHistory(event.id);completedEventList = false;"
                                class="mb-0 pointer" style="border-bottom: 1px solid white" [ngClass]="k == 0? 'activeEvent':'restEvent' ">
                                {{event.title | titlecase}}
                              </p>
                            </div>
                          </th>
                        </div>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="tableRow" *ngFor="let list of repertoireData;let i= index">
                        <div class="col-1 px-0 px-md-1">
                          <td>
                            <label class="container1">
                              <input type="checkbox" [checked]="selectAllRepertoireSong" (click)="selectRepertoire(list)">
                              <span class="checkmark"></span>
                            </label>
                          </td>
                        </div>
                        <div class="col-1 px-0 px-md-1">
                          <td>
                            <p> {{i+1}} </p>
                          </td>
                        </div>
                        <div class="col-3 breakLine px-0 px-md-1">
                          <td>
                            <p> {{list.eventTitle |titlecase}} </p>
                          </td>
                        </div>
                        <div class="col-3 breakLine px-0">
                          <td class="pl-0">
                            <p> {{list.title |titlecase}} </p>
                          </td>
                        </div>
                        <div class="col-2 col-md-2 px-0 px-md-1">
                          <td>
                            {{list.categories}}
                          </td>
                        </div>
                        <div class="col-2 px-0 justify-content-end ">
                          <td class="pl-0">
                            <p class="pr-2 mr-5"> {{list.artist |titlecase}} </p>
                            <p class="ml-5 px-2"></p>
                          </td>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- create new pro playlist modal -->
<div class="modal fade soundCheck" data-backdrop="false" id="createNewPROList" tabindex="-1" role="dialog"
  aria-labelledby="soundCheckModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modalBg">
      <div class="modal-body" style="color: white;">
        <div class="container mb-1">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" style="color: white;" (click)="playlistDescription = ''; playlistName='';">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="container my-1">
          <div class="row">
            <div class="col-12 ">
              <h5> {{playlistTitle}} </h5>
              <p class="mb-1">Playlist Name</p>
              <input type="text" [(ngModel)]="playlistName" placeholder="Enter Playlist Name" style="color:white; background:none; width:100%; border-radius:20px; border:1px solid
              white" class="py-2 pl-2">
              <p class="mb-1 mt-3">Description</p>
              <textarea cols="30" [(ngModel)]="playlistDescription" rows="3" style="background: none; width:100%; border-radius: 15px; color: white;"
                autocomplete="off" class="pl-2" placeholder="Enter Your Description"></textarea>
            </div>
            <div class="col-12 text-center">
              <button class="btn defrqqa mt-3" (click)="createOrEditPlaylist()" data-dismiss="modal">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
